import { Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import InventoryService from "../InventoryService";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class InvReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      whreportsdata: [],
      filterOptions: ["Item", "Warehouse", "Date"],
    };
  }

  handlefilter(value) {}
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    InventoryService.GetInvReports(currentTenant)
      .then((response) => {
        this.setState({
          reportsdata: response.data.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          ),
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    // InventoryService.GetStockReports(currentTenant).then((response)=>{
    //   this.setState({stockreportsdata:response.data})
    // })
    InventoryService.GetWarehouseReports(currentTenant).then((response) => {
      this.setState({ whreportsdata: response.data });
    });
  }
  render() {
    const columns = [
      {
        name: "name",
        label: "Item name",
        options: { filter: false, sort: true, sortOrder: "asc" },
      },
      {
        name: "itemcode",
        label: "Item Code",
        options: { filter: false, sort: true },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: true, sort: true },
      },
      {
        name: "uomname",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "cost",
        label: "Unit Cost",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "inventoryvalue",
        label: "Inventory Value",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalstock",
        label: "Qty on Hand",
        options: { filter: false, sort: true },
      },
      {
        name: "blockedstock",
        label: "Blocked Qty",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === null || value === undefined ? 0 : value}</>;
          },
        },
      },
      {
        name: "stock",
        label: "Available Qty",
        options: { filter: false, sort: true },
      },
      {
        name: "minnotifier",
        label: "Min/Max",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                {tableMeta.rowData[8] > value ? (
                  <span className="text-green">OK</span>
                ) : (
                  <span className="text-danger">Low</span>
                )}
              </>
            );
          },
          // setCellProps: value => ({ style: { textAlign:'right'}}),
        },
      },
      {
        name: "minnotifier",
        label: "Inv Min Notifier",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === null || value === undefined ? "N/A" : value}</>;
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item active">Inventory Report</li>
            </ol>
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (
                    <Formik initialValues={{}} enableReinitialize={true}>
                      {({ setFieldValue, values }) => (
                        <Form autoComplete="off">
                          {/* <div className="form-row">
                          <fieldset className="form-group">
                     <Button className="btn btn-sm bg-white border-white mt-3 mr-1"><i className="fas fa-filter"/></Button>
                  </fieldset>
                          <fieldset className="form-group col-md-2">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="filter"
                              options={this.state.filterOptions}
                              getOptionLabel={(option) => option}
                              
                              
                              value={values.filter?undefined:undefined}
                              onChange={(e, value) => {
                              setFieldValue("filter", value);
                                }}
                              renderInput={params => (  
                                <FormikTextField
                                    {...params}
                                  className="form-control"
                                  type="text"
                                  name="filter"
                                  onBlur={(e) =>{this.handlefilter(e.target.value)}}
                                  label={`Filter`}
                                  variant="standard"
                                  fullWidth 
                                />
                              )}
                            />
                          </fieldset>
                          </div> */}
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm"
                              title={"Inventory Report"}
                              data={this.state.reportsdata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(InvReport);
