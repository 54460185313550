import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Authentication/Login";
// import AuthenticationRoute from "../Authentication/AuthenticationRoute";
import Users from "./Users";
import Maincontent from "../Component/Maincontent";
import NewUser from "./NewUser";
import Masters from "../Masters/Masters";
import NewTenant from "../Masters/NewTenant";
import ChangePassword from "./ChangePassword";
import Customers from "../Masters/Customers/Customers";
import NewCustomer from "../Masters/Customers/NewCustomer";
import Suppliers from "../Masters/Suppliers/Suppliers";
import NewSupplier from "../Masters/Suppliers/NewSupplier";
import AccTop from "../Masters/acctop/AccTop";
import NewAccTop from "../Masters/acctop/NewAccTop";
import SubAccounts from "../Masters/Subaccounts/SubAccounts";
import NewSubAccount from "../Masters/Subaccounts/NewSubAccount";
import NewLedger from "../Accounts/Ledgers/NewLedger";
import Vouchers from "../Accounts/Vouchers";
import TrialBalance from "../Accounts/reports/TrialBalance";
import NewParty from "../Accounts/Ledgers/NewParty";
import Party from "../Accounts/Ledgers/Party";
import NewAssetsLiability from "../Accounts/Ledgers/NewAssetsLiability";
import NewNominal from "../Accounts/Ledgers/NewNominal";
import Journal from "../Accounts/Journal";
import JournalConfirm from "../Accounts/JournalConfirm";
import ComboJournalConfirm from "../Accounts/ComboJournalConfirm";
import INV from "../inventory/inv";
import NewItem from "../inventory/NewItem";
import StockIssueTable from "../inventory/StockIssue/StockIssueTable";
import AddStockIssue from "../inventory/StockIssue/NewStockIssue";
import Stock from "../inventory/Stocks/Stock";
import AddStock from "../inventory/Stocks/AddStock";
import Cost from "../inventory/Cost/Cost";
import AddCost from "../inventory/Cost/AddCost";
import SalesCalculation from "../Masters/SalesCalculation/SalesCalculation";
import SalesCalculationsMain from "../Masters/SalesCalculation/SalesCalculationsMain";
import SalesOrder from "../Sales/SalesOrder/SlaesOrder";
import NewSalesOrder from "../Sales/SalesOrder/NewSalesOrder";
import ViewOrders from "../Sales/SalesOrder/ViewOrders";
import Purchase from "../Purchase/Purchase/Purchase";
import ViewPurchaseorder from "../Purchase/Purchase/ViewPurchaseorder";
import CustomerTerms from "../Masters/CustomerTerms/CustomerTerms";
import CustomerTermsData from "../Masters/CustomerTerms/CustomerTermsData";
import SalesReturns from "../Sales/SalesReturns/SalesReturns";
import NewSaleOrderReturn from "../Sales/SalesReturns/NewSaleOrderReturn";
import NewPurchase from "../Purchase/Purchase/NewPurchase";
import ViewSalesReturn from "../Sales/SalesReturns/ViewSalesReturn";
// import PdfData from "../Purchase/Purchase/PdfData";
// import Printpdf from "../Purchase/Purchase/Printpdf";
import Invoice from "../Sales/Invoice/Invoice";
import PurchaseReturns from "../Purchase/PurchaseReturns/PurchaseReturns";
import NewPurchaseReturn from "../Purchase/PurchaseReturns/NewPurchaseReturn";
import Invoicetable from "../Sales/Invoice/Invoicetable";
import GRN from "../Purchase/GRN/GRN";
import NewGRN from "../Purchase/GRN/NewGRN";
import ViewGRNItems from "../Purchase/GRN/ViewGRNItems";
import SalesReceipts from "../Sales/Receipts/SalesReceipts";
import ViewInvoice from "../Sales/Invoice/ViewInvoice";
import ViewPurchaseReturns from "../Purchase/PurchaseReturns/ViewPurchaseReturns";
import AdminDashboard from "../DashboardPages/AdminDashboard/AdminDashboard";
import WarehouseTable from "../Masters/Warehouse/WarehouseTable";
import NewWarehouse from "../Masters/Warehouse/NewWarehouse";
import Category from "../Masters/Category/Category";
import NewCategory from "../Masters/Category/NewCategory";
import JournalPayment from "../Accounts/JournalPayment";
import Download from "../Common/upload/download";
import NewUom from "../Masters/Uom/NewUom";
import UomDataTable from "../Masters/Uom/UomDataTable";
import Printpdf from "../Purchase/Purchase/Printpdf";
import Ledgerview from "../Accounts/reports/Ledgerview";
import NewServices from "../inventory/Services/NewServices";
import Settings from "../Settings/Settings";
import SCN from "../Purchase/SCN/scn";
import NewSCN from "../Purchase/SCN/NewSCN";
import ViewSCN from "../Purchase/SCN/ViewSCN";
import MailSettings from "../Settings/MailSettings";
import ReceiptsTable from "../Sales/Receipts/ReceiptsTable";
import Damage from "../inventory/Damage/Damage";
import DamageTable from "../inventory/Damage/DamageTable";
import JournalSaleConfirm from "../Accounts/JournalSaleConfirm";
import DnTable from "../Sales/DeliveryNote/DnTable";
import NewDn from "../Sales/DeliveryNote/newDn";
import SizeTable from "../Masters/Size/SizeTable";
import NewSize from "../Masters/Size/NewSize";
import ColourTable from "../Masters/Colour/ColourTable";
import NewColour from "../Masters/Colour/NewColour";
// import JournalReceipts from "../Accounts/JournalReceipts";
import ViewDn from "../Sales/DeliveryNote/ViewDn";
import DepositeDetails from "../Sales/Receipts/DepositeDetails";
import Twotable from "../Sales/Invoice/Twotable";
// import PandL from "../Accounts/reports/PandL";
import BalanceSheet from "../Accounts/reports/BalanceSheet";
import ServiceDataTable from "../inventory/Services/ServiceDataTable";
import JvDetails from "../Accounts/reports/JvDetails";
import StockReport from "../inventory/InvReports/StockReport";
import DamageReport from "../inventory/InvReports/DamageReport";
import SalesReport from "../Sales/Reports/SalesReport";
import SalesReturnsReport from "../Sales/Reports/SalesReturnsReport";
import PurchaseReport from "../Purchase/Reports/PurchaseReport";
import PurchaseReturnsReport from "../Purchase/Reports/PurchaseReturnsReport";
import NewDepartment from "../Masters/Department/NewDepartment";
import DepartmentDataTable from "../Masters/Department/DepartmentDataTable";
import NewPandL from "../Accounts/reports/NewPandL";
import InvReport from "../inventory/InvReports/InvReport";
import PurchaseReportDetails from "../Purchase/Reports/PurchaseReportDetails";
import PurchaseReportsDetails2 from "../Purchase/Reports/PurchaseReportsDetails2";
import SalesReportDetails from "../Sales/Reports/SalesReportDetails";
import SalesReportDetails2 from "../Sales/Reports/SalesReportDetails2";
import SalesReturnsReportDetails from "../Sales/Reports/SalesReturnsReportDetails";
import SalesReturnsReportDetails2 from "../Sales/Reports/SalesReturnsReportDetails2";
import PurchaseReturnsReportDetails from "../Purchase/Reports/PurchaseReturnsReportDetails";
import PurchaseReturnsReportsDetails2 from "../Purchase/Reports/PurchaseReturnsReportsDetails2";
import TransactionOverdues from "../Sales/TransactionOverdues/TransactionOverdues";
import NewPOS from "../Sales/PointofSales/NewPOS";
import PaymentGateway from "../PaymnentGtaeway/PaymentGateway";
import Acceptingstockissue from "../inventory/StockIssue/acceptingstockissue";
import AcceptingstockissueTable from "../inventory/StockIssue/acceptingstockissueTable";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import Notifications from "../Notifications/Notifications";
import POSTable from "../Sales/PointofSales/POSTable";
import RolesTable from "../Masters/Roles/RolesTable";
import NewRoles from "../Masters/Roles/NewRoles";
import Screens from "../Masters/Screens/Screens";
import ViewRoles from "../Masters/Roles/ViewRoles";
import Products from "../Masters/Products/Products";
// import Logout from "../Authentication/Logout";
import WarehouseReport from "../inventory/InvReports/WarehouseReport";
import QuotationTable from "../Sales/Quotation/QuotationTable";
import ViewQuotation from "../Sales/Quotation/ViewQuotation";
import NewQuotation from "../Sales/Quotation/NewQuotation";
import EstimationTable from "../Production/Estimation/EstimationTable";
import NewEstimation from "../Production/Estimation/NewEstimation";
import EstimationDetails from "../Production/Estimation/EstimationDetails";
import JobOrder from "../Production/Joborder/JobOrder";
import NewJobOrder from "../Production/Joborder/NewJobOrder";
import IndentingTable from "../Production/Indenting/IndentingTable";
import NewIndenting from "../Production/Indenting/NewIndenting";
import IndentingDetails from "../Production/Indenting/IndentingDetails";
import WorkCenterDataTable from "../Masters/WorkCenter/WorkCenterDataTable";
import NewWorkCenter from "../Masters/WorkCenter/NewWorkCenter";
import ProductionStore from "../Production/ProductionStores/ProductionStore";
import NewProductionStore from "../Production/ProductionStores/NewProductionStore";
import ProductionStoreDetails from "../Production/ProductionStores/ProductionStoreDetails";
import AccountsPayable from "../Accounts/AccountsPayable";
import AccountsPayableEntry from "../Accounts/AccountsPayableEntry";
import AccountReceivables from "../Accounts/AccountReceivables";
import AccountsReceivableEntry from "../Accounts/AccountsReceivableEntry";
import IndentIssue from "../Production/ProductionStores/IndentIssue";
import IndentIssuedDetails from "../Production/ProductionStores/IndentIssuedDetails";
import InProcessTable from "../Production/InProcess/InProcessTable";
import NewInProcess from "../Production/InProcess/NewInprocess";
import OutputDetails from "../Production/InProcess/OutputDetails";
import FinishedStoreTable from "../Production/FinishedStore/FinishedStoreTable";
import FinishedStoreItems from "../Production/FinishedStore/FinishedStoreItems";
import NotFound from "../Component/NotFound";
import QATable from "../Production/QA/QATable";
import QADetails from "../Production/QA/QADetails";
import NewQA from "../Production/QA/NewQA";
import BillofEntry from "../Purchase/BillofEntry/BillofEntry";
import NewBillofEntry from "../Purchase/BillofEntry/NewBillofEntry";
import AssetTable from "../AssetManagement/AssetTable";
import NewAsset from "../AssetManagement/NewAsset";
import NewThirdpartyAsset from "../AssetManagement/NewThirdpartyAsset";
import Advances from "../Accounts/Advances";
import AssetDetails from "../AssetManagement/AssetDetails";
import Assetmaintenance from "../AssetManagement/Assetmaintenance";
import NewAssetmaintenance from "../AssetManagement/NewAssetmaintenance";
import StoreLosses from "../Production/ProductionStores/StoreLosses";
import LossesList from "../Accounts/LossesList";
import DepreciationList from "../Accounts/DepreciationList";
import ProductionUsage from "../AssetManagement/ProductionUsage";
import NewProductionUsage from "../AssetManagement/NewProductionUsage";
import Posprice from "../Sales/PointofSales/Posprice";
import PospriceTable from "../Sales/PointofSales/PospriceTable";
import RentAsset from "../AssetManagement/RentAsset";
import RentSoldAssetDetails from "../AssetManagement/RentSoldAssetDetails";
import DTaxes from "../Accounts/DTaxes";
import DtaxesPayable from "../Accounts/DtaxesPayable";
import PosDetails from "../Sales/PointofSales/PosDetails";
import Countrytable from "../Masters/Countries/Countrytable";
import AddCountry from "../Masters/Countries/AddCountry";
import AddCities from "../Masters/Cites/AddCities";
import CitiesTable from "../Masters/Cites/CitiesTable";
import StatesTable from "../Masters/State/StatesTable";
import AddStates from "../Masters/State/AddStates";
import Categorylvl2 from "../Masters/Category/Categorylvl2/Categorylvl2";
import NewCategorylvl2 from "../Masters/Category/Categorylvl2/NewCategorylvl2";
import Categorylvl3 from "../Masters/Category/Categorylvl3/Categorylvl3";
import NewCategorylvl3 from "../Masters/Category/Categorylvl3/NewCategorylvl3";
import BrandsTable from "../Masters/Brands/BrandsTable";
import NewBrand from "../Masters/Brands/NewBrand";
import LossesEntry from "../Accounts/LossesEntry";
import DepreciationEntry from "../Accounts/DepreciationEntry";
import SubHeads from "../Accounts/Subhead/SubHeads";
import NewSubHead from "../Accounts/Subhead/NewSubHead";
import Customerdetails from "../Masters/Customers/Customerdetails";
import AgentTable from "../Masters/Agents/AgentTable";
import NewAgent from "../Masters/Agents/NewAgent";
import SalesvsPurchase from "../Salesvspurchase/SalesvsPurchase";
import Immediatetable from "../Production/Immediate/Immediatetable";
import Newimmediateprocess from "../Production/Immediate/NewImmediateprocess";
import ImmediateDetails from "../Production/Immediate/ImmediateDetails";
import Prodctionreports from "../Production/Reports/Prodctionreports";
import ConsolidateReport from "../inventory/InvReports/ConsolidateReport";
import DirectStocks from "../Accounts/Addjournals/DirectStocks";
import DirectStockEntry from "../Accounts/Addjournals/DirectStockEntry";
import EmployeesTable from "../Masters/Employees/EmployeesTable";
import Newemployee from "../Masters/Employees/Newemployee";
import TransportationTable from "../AssetManagement/Transportation/TransportationTable";
import NewTrip from "../AssetManagement/Transportation/NewTrip";
import NewPlaces from "../AssetManagement/Transportation/NewPlaces";
import Tripdetails from "../AssetManagement/Transportation/Tripdetails";
import TransportationReports from "../AssetManagement/Transportation/TransportationReports";
import TransportationReportdetails from "../AssetManagement/Transportation/TransportationReportdetails";
import IndirectTaxes from "../Accounts/IndirectTaxes";
import IndirectTaxMonthWise from "../Accounts/IndirectTaxMonthWise";
import Viewtenants from "../Masters/Viewtenants";
import Directsales from "../Sales/DirectSales/Directsales";
import Bank from "../Masters/Bank/Bank";
import Addbank from "../Masters/Bank/Addbank";
import RentalSoldInvoice from "../AssetManagement/RentalSoldInvoice";
import Usercustomers from "../Masters/Usercustomers/Usercustomers";
import UsercustomersTable from "../Masters/Usercustomers/UsercustomersTable";
import ExpensesEntry from "../Accounts/ExpensesEntry";
import NewLedGroup from "../Accounts/Subhead/NewLedGroup";
import DirectEntry from "../Accounts/DirectEntry";
import DirectEntryConfirm from "../Accounts/DirectEntryConfirm";
import BankAccountReport from "../Accounts/reports/BankAccountReport";
import DebitCreditnotes from "../Accounts/DebitCreditnotes";
import RectificationRequests from "../Accounts/RectificationRequests";
import DebitNoteEntry from "../Accounts/DebitNoteEntry";
import Debitnotes from "../Purchase/Debitnotes/Debitnotes";
import CreditNoteEntry from "../Accounts/CreditNoteEntry";
import HSNTable from "../Masters/HSN/HSNTable";
import Transporter from "../Masters/Transporter/Transporter";
import Newtransporter from "../Masters/Transporter/Newtransporter";
import SalaryComponent from "../Payroll/SalaryComponent";
import SalaryBreakupComponent from "../Payroll/SalaryBreakupComponent";
import SalaryBreakup from "../Payroll/SalaryBreakup";
import SalaryProgressMonth from "../Payroll/SalaryProgressMonth";
import AddEmployeeComponent from "../Payroll/AddEmployeeComponent";
import EmpStatusDetails from "../Payroll/EmpStatusDetails";
import ProductionLosses from "../Production/Losses/ProductionLosses";
import AddEmployeeAdvance from "../Payroll/AddEmployeeAdvance";
import SalaryRequests from "../Accounts/SalaryRequests";
import EmployeeBankDetails from "../Masters/Employees/EmployeeBankDetails";
import SalaryJournalConfirm from "../Accounts/SalaryJournalConfirm";
import JvEntryDetails from "../Accounts/JvEntryDetails";
import HSNsummary from "../Sales/Reports/HSNsummary";
import AgentsReport from "../Sales/Reports/AgentsReport";
// --------------------------------------------------------------------------------------------
import Ticket from "../Traacs/Invoice/Ticket";
import HotelORCar from "../Traacs/Invoice/HotelORCar";
import Services from "../Traacs/Invoice/Services";
import ARSharing from "../Traacs/Invoice/ARSharing";
import Adjustment from "../Traacs/Invoice/Adjustment";
import Hotel from "../Traacs/Invoice/Hotel";
import Car from "../Traacs/Invoice/Car";
import TourMaster from "../Traacs/Invoice/TourMaster";
import Invoices from "../Traacs/Invoice/Invoices";

import MHotel from "../Traacs/Master/Tours/MHotel";
import MHotelList from "../Traacs/Master/Tours/MHotelList";
import MHotelTax from "../Traacs/Master/Tours/MHotelTax";
import MHotelTaxList from "../Traacs/Master/Tours/MHotelTaxList";
import MHotelChain from "../Traacs/Master/Tours/MHotelChain";
import MHotelChainList from "../Traacs/Master/Tours/MHotelChainList";
// import Mastertour from "../Traacs/Master/Tours/Mastertour";
import MRoomtypeList from "../Traacs/Master/Tours/MRoomtypeList";
import MRoomtype from "../Traacs/Master/Tours/MRoomtype";
import MHotelMealsPlanList from "../Traacs/Master/Tours/MHotelMealsPlanList";
import MHotelMealsPlan from "../Traacs/Master/Tours/MHotelMealsPlan";
import MCar from "../Traacs/Master/Tours/MCar";
import MCarList from "../Traacs/Master/Tours/MCarList";
import MAirline from "../Traacs/Master/Travel/MAirline";
import MAirlineList from "../Traacs/Master/Travel/MAirlineList";
// import Mastertravel from "../Traacs/Master/Travel/Mastertravel";
import ToursAndTravells from "../Traacs/ToursAndTravells";
import MAirport from "../Traacs/Master/Travel/MAirport";
import MAirportList from "../Traacs/Master/Travel/MAirportList";
import Partner from "../Traacs/Master/Partner/Partner";
import PartnerList from "../Traacs/Master/Partner/PartnerList";
import ViewISalesInvoices from "../Traacs/Invoice/ViewISalesInvoices";
import TicketList from "../Traacs/Invoice/TicketList";
import MasterList from "../Traacs/Invoice/MasterList";
import { ViewInvoicePage } from "../Traacs/Invoice/ViewInvoicePage";
import InvoiceMainPage from "../Traacs/Invoice/InvoiceMainPage";
import ConsumptionReport from "../inventory/InvReports/ConsumptionReport";
import ProductionTemplate from "../Production/Masters/ProductionTemplate";
import NewProductionTemplate from "../Production/Masters/NewProductionTemplate";
import ImmediateProduction from "../Production/Immediate/ImmediateProduction";
import ProcesswiseReport from "../Production/Reports/ProcesswiseReport";
import LedgersViewNew from "../Accounts/reports/LedgersViewNew";
import PAgentsReport from "../Purchase/Reports/AgentsReport";
import InventoryMenu from "../inventory/InventoryMenu";
import SalesMenu from "../Sales/SalesMenu";
import PurchaseMenu from "../Purchase/PurchaseMenu";
import ProductionMenu from "../Production/ProductionMenu";
import AssetMenu from "../AssetManagement/AssetMenu";
import MastersMenu from "../Masters/MastersMenu";
import SuperadminMenu from "../Masters/SuperadminMenu";
import AccountsMenu from "../Accounts/AccountsMenu";
import OpeningEntries from "../Accounts/OpeningEntries";
import AutoJREsettingd from "../Settings/AutoJREsettingd";
import CustomerCreditnote from "../Sales/Invoice/CustomerCreditnote";
import Salescreditnote from "../Sales/Invoice/Salescreditnote";
import CustomerDebitnote from "../Sales/Invoice/CustomerDebitnote";
import Transactionreports from "../inventory/InvReports/Transactionreports";
import TransactionReport from "../Production/Reports/TransactionReport";
import Creditnotes from "../Sales/Creditnotes/Creditnotes";
import StoreItemTransactions from "../Production/Reports/StoreItemTransactions";
import LedgersNominal from "../Accounts/reports/LedgersNominal";
import LedgersRealAL from "../Accounts/reports/LedgersRealAL";
import ProjectCreation from "../ProjectManagement/ProjectCreation/ProjectCreation";
import PurchaseAutoJREsetting from "../Settings/PurchaseAutoJREsetting";
import MultiEntryConfirm from "../Accounts/MultiEntryConfirm";
import InvoiceReports from "../Sales/Reports/InvoiceReports";
import ProjectManagement from "../ProjectManagement/ProjectManagement";
import Projects from "../ProjectManagement/ProjectCreation/Projects";
import ProjectEstimation from "../ProjectManagement/ProjectEstimation/ProjectEstimation";
import NewProjectEstimation from "../ProjectManagement/ProjectEstimation/NewProjectEstimation";
import ProjectDetails from "../ProjectManagement/ProjectCreation/ProjectDetails";
import ProjectEstimationDetails from "../ProjectManagement/ProjectEstimation/ProjectEstimationDetails";
import Tax from "../Masters/Tax/Tax";
import AddTax from "../Masters/Tax/AddTax";
import AddSite from "../ProjectManagement/ProjectCreation/SiteMaster/AddSite";
import AddProjectManager from "../ProjectManagement/ProjectCreation/ProjectManager/AddProjectManager";
import ClientAssign from "../ProjectManagement/ProjectCreation/ClientAssign/ClientAssign";
import AddSiteIncharge from "../ProjectManagement/ProjectCreation/SiteMaster/AddSiteIncharge";
import EstimationParameters from "../ProjectManagement/ProjectMasters/EstimationParameters";
import AddEstimationParameters from "../ProjectManagement/ProjectMasters/AddEstimationParameters";
import Internalconsumption from "../inventory/InternalConsumption/Internalconsumption";
import NewInternalconsumption from "../inventory/InternalConsumption/NewInternalconsumption";
import SettlementEntry from "../Accounts/SettlementEntry";
import SettlementSales from "../Accounts/SettlementSales";
import LedgersTaxes from "../Accounts/reports/LedgersTaxes";
import LedgersAdvances from "../Accounts/reports/LedgersAdvances";
import Labours from "../ProjectManagement/ProjectMasters/Labours";
import ProjectIndenting from "../ProjectManagement/ProjectIndenting/ProjectIndenting";
import AddLabour from "../ProjectManagement/ProjectMasters/AddLabour";
import NewProjectIndenting from "../ProjectManagement/ProjectIndenting/NewProjectIndenting";
import ProjectDashboard from "../ProjectManagement/ProjectDashboard";
import AssignType from "../Masters/AssignType/AssignType";
import AddAssignType from "../Masters/AssignType/AddAssignType";
import StockIssueItemsView from "../inventory/StockIssue/StockIssueItemsView";
import AccountsCompleted from "../Accounts/AccountsCompleted";

export default class AppRoute extends Component {
  render() {
    return (
      <div>
        <Router>
          <>
            <Routes>
              <Route path="/" exact element={<Login />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/logout" element={<Logout/>} /> */}
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              {/* <AuthenticationRoute path="/index" element={<Index/>} /> */}
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/mailsettings" element={<MailSettings />} />
              <Route path="/autojresettings" element={<AutoJREsettingd />} />
              <Route
                path="/purchaseautojresettings"
                element={<PurchaseAutoJREsetting />}
              />
              {/* Dashboards */}
              <Route path="/index" element={<AdminDashboard />} />
              <Route path="/masters" element={<MastersMenu />} />
              <Route path="/users" element={<Users />} />
              <Route path="/roles" element={<RolesTable />} />
              <Route path="/newroles" element={<NewRoles />} />
              <Route path="/viewroles" element={<ViewRoles />} />
              <Route path="/maincontent:name" element={<Maincontent />} />
              <Route path="/superadminmasters" element={<SuperadminMenu />} />
              <Route path="/newusers" element={<NewUser />} />
              <Route path="/tenants" element={<Masters />} />
              <Route path="/newtenant" element={<NewTenant />} />
              <Route path="/tenantdetails" element={<Viewtenants />} />
              <Route path="/screens" element={<Screens />} />
              <Route path="/products" element={<Products />} />
              <Route path="/countries" element={<Countrytable />} />
              <Route path="/addcountry" element={<AddCountry />} />
              <Route path="/cities" element={<CitiesTable />} />
              <Route path="/addcity" element={<AddCities />} />
              <Route path="/states" element={<StatesTable />} />
              <Route path="/addstate" element={<AddStates />} />
              <Route path="/hsn" element={<HSNTable />} />
              <Route path="/changepassword" element={<ChangePassword />} />

              <Route path="/employees" element={<EmployeesTable />} />
              <Route path="/employeedetails" element={<Customerdetails />} />
              <Route path="/newemployee" element={<Newemployee />} />

              <Route path="/customers" element={<Customers />} />
              <Route path="/customerdetails" element={<Customerdetails />} />
              <Route path="/newcustomer" element={<NewCustomer />} />
              <Route path="/suppliers" element={<Suppliers />} />
              <Route path="/newsupplier" element={<NewSupplier />} />
              <Route path="/warehouse" element={<WarehouseTable />} />
              <Route path="/newwarehouse" element={<NewWarehouse />} />

              <Route path="/workcenter" element={<WorkCenterDataTable />} />
              <Route path="/newworkcenter" element={<NewWorkCenter />} />

              <Route path="/category" element={<Category />} />
              <Route path="/newcategory" element={<NewCategory />} />
              <Route path="/categorylvl2" element={<Categorylvl2 />} />
              <Route path="/newcategorylvl2" element={<NewCategorylvl2 />} />
              <Route path="/categorylvl3" element={<Categorylvl3 />} />
              <Route path="/newcategorylvl3" element={<NewCategorylvl3 />} />

              <Route path="/agents" element={<AgentTable />} />
              <Route path="/newagent" element={<NewAgent />} />

              <Route path="/brand" element={<BrandsTable />} />
              <Route path="/newbrand" element={<NewBrand />} />
              <Route path="/uom" element={<UomDataTable />} />
              <Route path="/newuom" element={<NewUom />} />
              <Route path="/size" element={<SizeTable />} />
              <Route path="/newsize" element={<NewSize />} />
              <Route path="/colour" element={<ColourTable />} />
              <Route path="/newcolour" element={<NewColour />} />
              <Route path="/bank" element={<Bank />} />
              <Route path="/addbank" element={<Addbank />} />
              <Route path="/newdepartment" element={<NewDepartment />} />
              <Route path="/department" element={<DepartmentDataTable />} />
              <Route path="/transporter" element={<Transporter />} />
              <Route path="/addtransporter" element={<Newtransporter />} />

              <Route path="/addusercustomers" element={<Usercustomers />} />
              <Route path="/usercustomers" element={<UsercustomersTable />} />

              <Route
                path="/assigntype"
                element={<AssignType />}
              />

              <Route
                path="/addassigntype"
                element={<AddAssignType />}
              />


              <Route path="/taxes" element={<Tax />} />
              <Route path="/addtax" element={<AddTax />} />

              {/* File Service */}
              <Route path="/download" element={<Download />} />
              {/* Accounts */}
              <Route path="/accounts" element={<AccountsMenu />} />
              <Route path="/acctop" element={<AccTop />} />
              <Route path="/newacctop" element={<NewAccTop />} />
              <Route path="/subacc" element={<SubAccounts />} />
              <Route path="/newsubacc" element={<NewSubAccount />} />
              <Route path="/subheads" element={<SubHeads />} />
              <Route path="/newsubheads" element={<NewSubHead />} />
              <Route path="/newledgroups" element={<NewLedGroup />} />
              <Route path="/newledgers" element={<NewLedger />} />
              <Route path="/jvdetails" element={<JvDetails />} />
              <Route path="/party" element={<Party />} />
              <Route path="/newparty" element={<NewParty />} />
              <Route path="/journals" element={<Journal />} />
              <Route path="/ap" element={<AccountsPayable />} />
              <Route path="/ar" element={<AccountReceivables />} />
              <Route path="/ape" element={<AccountsPayableEntry />} />
              <Route path="/are" element={<AccountsReceivableEntry />} />
              <Route path="/directadvances" element={<Advances />} />
              <Route path="/dtaxes" element={<DTaxes />} />
              <Route path="/dtp" element={<DtaxesPayable />} />
              <Route path="/depreciationl" element={<DepreciationList />} />
              <Route path="/lossesl" element={<LossesList />} />
              <Route path="/lossesentry" element={<LossesEntry />} />
              <Route path="/deprientry" element={<DepreciationEntry />} />
              <Route path="/dirstock" element={<DirectStocks />} />
              <Route path="/dirstockentry" element={<DirectStockEntry />} />
              <Route path="/indirtaxes" element={<IndirectTaxes />} />
              <Route path="/monthindtax" element={<IndirectTaxMonthWise />} />
              <Route path="/expenseentry" element={<ExpensesEntry />} />
              <Route path="/directentry" element={<DirectEntry />} />
              <Route
                path="/directentryconfirm"
                element={<DirectEntryConfirm />}
              />
              <Route path="/bankstransreport" element={<BankAccountReport />} />
              <Route
                path="/rectifications"
                element={<RectificationRequests />}
              />
              <Route path="/dnentry" element={<DebitNoteEntry />} />
              <Route path="/cnentry" element={<CreditNoteEntry />} />
              <Route path="/salentry" element={<SalaryRequests />} />
              <Route path="/salconfirm" element={<SalaryJournalConfirm />} />
              <Route path="/jventrydetails" element={<JvEntryDetails />} />
              <Route path="/vouchers" element={<Vouchers />} />
              <Route path="/mjournalconfirm" element={<MultiEntryConfirm />} />
              <Route path="/settlement" element={<SettlementEntry/>} />
              <Route path="/settlements" element={<SettlementSales/>} />


              <Route path="/trialbalance" element={<TrialBalance />} />
              {/* <Route path="/p&l" element={<PandL/>} /> */}
              <Route path="/balancesheet" element={<BalanceSheet />} />
              <Route path="/newassets" element={<NewAssetsLiability />} />
              <Route path="/newnominal" element={<NewNominal />} />
              <Route path="/journalconfirm" element={<JournalConfirm />} />
              <Route path="/journalpmt" element={<JournalPayment />} />
              <Route
                path="/cjournalconfirm"
                element={<ComboJournalConfirm />}
              />
              <Route path="/mjournalconfirm" element={<MultiEntryConfirm />} />
              <Route path="/ledgerview" element={<Ledgerview />} />
              <Route path="/newledgerview" element={<LedgersViewNew />} />
              <Route path="/ledgernominal" element={<LedgersNominal />} />
              <Route path="/ledgerreal" element={<LedgersRealAL />} />
              <Route path="/ledgeradv" element={<LedgersAdvances />} />
              <Route path="/completetran" element={<AccountsCompleted/>} />
              <Route path="/ledgertax" element={<LedgersTaxes />} />

              <Route
                path="/journalsaleconfirm"
                element={<JournalSaleConfirm />}
              />
              {/* <Route
                path="/journalrect"
                element={<JournalReceipts/>}
              /> */}
              <Route path="/pandl" element={<NewPandL />} />
              <Route path="/openingentries" element={<OpeningEntries />} />
              <Route path="/debitcreditnotes" element={<DebitCreditnotes />} />

              {/* Inventory */}
              <Route path="inventory" element={<InventoryMenu />} />
              <Route path="/items" element={<INV />} />
              <Route path="/newitems" element={<NewItem />} />
              <Route path="/stocks" element={<Stock />} />
              <Route path="/addstock" element={<AddStock />} />
              <Route path="/stockissue" element={<StockIssueTable />} />
              <Route path="/addstockissue" element={<AddStockIssue />} />
              <Route path="/stockissueitemsview" element={<StockIssueItemsView />}/>
              <Route
                path="/acceptingstockissue"
                element={<Acceptingstockissue />}
              />
              <Route
                path="/acceptingstockissuetable"
                element={<AcceptingstockissueTable />}
              />
              <Route path="/cost" element={<Cost />} />
              <Route path="/addcost" element={<AddCost />} />
              <Route path="/services" element={<ServiceDataTable />} />
              <Route path="/newservices" element={<NewServices />} />
              <Route path="/losses" element={<DamageTable />} />
              <Route path="/addlosses" element={<Damage />} />
              <Route
                path="/consolidatereport"
                element={<ConsolidateReport />}
              />

              <Route
                path="/stocktransactionsreport"
                element={<Transactionreports />}
              />

              <Route
                path="/internalconsumption"
                element={<Internalconsumption />}
              />
               <Route
                path="/addinternalconsumption"
                element={<NewInternalconsumption />}
              />

              {/*SalesCalculation*/}
              <Route path="/salescal" element={<SalesCalculation />} />
              <Route
                path="/salescaltable"
                element={<SalesCalculationsMain />}
              />
              {/*Sales */}

              <Route path="/sales" element={<SalesMenu />} />

              {/* SalesInvoice*/}
              <Route path="/salesinvoice" element={<Invoicetable />} />
              <Route path="/newsalesinvoice" element={<Invoice />} />
              <Route path="/viewsalesinvoice" element={<ViewInvoice />} />

              <Route
                path="/customercreditnote"
                element={<CustomerCreditnote />}
              />
              <Route
                path="/customercreditnotedetails"
                element={<Salescreditnote />}
              />

              <Route
                path="/customerdebitnote"
                element={<CustomerDebitnote />}
              />
              {/* <Route
                path="/customercreditnotedetails"
                element={<Salescreditnote/>}
              /> */}

              {/* SalesOrder */}
              <Route path="/salesorder" element={<SalesOrder />} />
              <Route path="/saleorderdetails" element={<ViewOrders />} />
              <Route path="/newsalesorder" element={<NewSalesOrder />} />
              <Route path="/deliverynote" element={<DnTable />} />
              <Route path="/newdeliverynote" element={<NewDn />} />
              <Route path="/viewdeliverynote" element={<ViewDn />} />
              {/* Sales Quotation */}
              <Route path="/quotation" element={<QuotationTable />} />
              <Route path="/quotationdetails" element={<ViewQuotation />} />
              <Route path="/newquotation" element={<NewQuotation />} />
              {/*SlaesReturn*/}
              <Route path="/salesreturns" element={<SalesReturns />} />
              <Route
                path="/newsaleorderreturn"
                element={<NewSaleOrderReturn />}
              />
              <Route
                path="/salesreturnsorderdetails"
                element={<ViewSalesReturn />}
              />

              {/* DebitNote */}
              <Route path="/creditnotes" element={<Creditnotes />} />
              {/* SalesReceipts */}
              <Route path="/salesreceipts" element={<ReceiptsTable />} />
              <Route path="/newsalesreceipts" element={<SalesReceipts />} />
              <Route path="/depositedetails" element={<DepositeDetails />} />
              <Route path="/directtrade" element={<Directsales />} />
              <Route path="/twotables" element={<Twotable />} />
              {/* POS */}
              <Route path="/newpointofsale" element={<NewPOS />} />
              <Route path="/pointofsale" element={<POSTable />} />
              <Route path="/newposprice" element={<Posprice />} />
              <Route path="/posprice" element={<PospriceTable />} />
              <Route path="/posdetails" element={<PosDetails />} />

              {/* Purchase */}
              <Route path="/purchase" element={<PurchaseMenu />} />
              {/*PurchaseOrder*/}
              <Route path="/purchaseorder" element={<Purchase />} />
              <Route path="/newpurchase" element={<NewPurchase />} />
              <Route
                path="/viewpurchaseorder"
                element={<ViewPurchaseorder />}
              />
              {/* <Route path="/pdf" element={<PdfData/>} /> */}
              <Route path="/print" element={<Printpdf />} />
              <Route path="/grn" element={<GRN />} />
              <Route path="/newgrn" element={<NewGRN />} />
              <Route path="/viewgrnitems" element={<ViewGRNItems />} />
              {/*Purchase Returns*/}
              <Route path="/purchasereturns" element={<PurchaseReturns />} />
              <Route
                path="/newpurchasereturn"
                element={<NewPurchaseReturn />}
              />
              <Route
                path="/purchasereturnsdetails"
                element={<ViewPurchaseReturns />}
              />
              <Route path="/scn" element={<SCN />} />
              <Route path="/newscn" element={<NewSCN />} />
              <Route path="/viewscn" element={<ViewSCN />} />

              <Route path="/billofentry" element={<BillofEntry />} />
              <Route path="/newbillofentry" element={<NewBillofEntry />} />
              <Route path="/debitnotes" element={<Debitnotes />} />

              <Route
                path="/purchaseagentsreports"
                element={<PAgentsReport />}
              />

              {/* Purchse Invoice */}
              {/* <Route
                path="/newpurchaseinvoice"
                element={<NewPurchaseInvoice/>}
              />
              <Route
                path="/purchaseinvoice"
                element={<PurchaseInvoiceTable/>}
              />
              <Route
                path="/purchaseinvoicedetalis"
                element={<ViewPurchaseInvoice/>}
              /> */}
              {/*CustomerTerms*/}
              <Route path="/newcustomerterms" element={<CustomerTerms />} />
              <Route path="/customerterms" element={<CustomerTermsData />} />
              {/* Reports */}
              {/* InvReport */}
              <Route path="/stockreport" element={<StockReport />} />
              <Route path="/inventoryreport" element={<InvReport />} />
              <Route path="/damagereport" element={<DamageReport />} />
              <Route path="/warehousereport" element={<WarehouseReport />} />
              <Route
                path="/consumptionreport"
                element={<ConsumptionReport />}
              />
              {/* SalesReport */}
              <Route path="/salesreport" element={<InvoiceReports />} />
              <Route path="/saleordersreport" element={<SalesReport />} />
              <Route
                path="/salesreportdetails"
                element={<SalesReportDetails />}
              />
              <Route
                path="/salesreportdetails2"
                element={<SalesReportDetails2 />}
              />

              <Route path="/agentsreports" element={<AgentsReport />} />

              {/* SalesReturnsReport */}
              <Route
                path="/salesreturnsreport"
                element={<SalesReturnsReport />}
              />
              <Route
                path="/salesreturnsreportdetails"
                element={<SalesReturnsReportDetails />}
              />
              <Route
                path="/salesreturnsreportdetails2"
                element={<SalesReturnsReportDetails2 />}
              />
              <Route path="/hsnsummary" element={<HSNsummary />} />

              {/* TransactionOverdues */}
              <Route path="/overdues" element={<TransactionOverdues />} />
              {/* PurchaseReport */}
              <Route path="/purchasereport" element={<PurchaseReport />} />
              <Route
                path="/purchasereportdetails"
                element={<PurchaseReportDetails />}
              />
              <Route
                path="/purchasereportdetails2"
                element={<PurchaseReportsDetails2 />}
              />
              {/* PurchaseReturnsReport */}
              <Route
                path="/purchasereturnsreport"
                element={<PurchaseReturnsReport />}
              />
              <Route
                path="/purchasereturnsreportdetails"
                element={<PurchaseReturnsReportDetails />}
              />
              <Route
                path="/purchasereturnsreportdetails2"
                element={<PurchaseReturnsReportsDetails2 />}
              />
              {/* Production */}
              <Route path="/production" element={<ProductionMenu />} />

              {/* Production Template */}

              <Route
                path="/productiontemplates"
                element={<ProductionTemplate />}
              />

              <Route
                path="/newproductiontemplate"
                element={<NewProductionTemplate />}
              />

              {/* Immediate Production */}
              <Route path="/immediateproduction" element={<Immediatetable />} />
              <Route
                path="/newimmediateproduction"
                element={<ImmediateProduction />}
              />

              <Route
                path="/immediateproductiondetails"
                element={<ImmediateDetails />}
              />

              {/* End of Immediate Production */}
              {/* Estimation */}
              <Route path="/estimation" element={<EstimationTable />} />
              <Route path="/newestimation" element={<NewEstimation />} />
              <Route
                path="/estimationdetails"
                element={<EstimationDetails />}
              />
              {/* JobOrder */}
              <Route path="/joborder" element={<JobOrder />} />
              <Route path="/newjoborder" element={<NewJobOrder />} />
              <Route path="/indenting" element={<IndentingTable />} />
              <Route path="/newindenting" element={<NewIndenting />} />

              <Route path="/indentingdetails" element={<IndentingDetails />} />

              <Route path="/indentissue" element={<IndentIssue />} />

              <Route
                path="/indentissueddetails"
                element={<IndentIssuedDetails />}
              />

              <Route path="/store" element={<ProductionStore />} />
              <Route path="/newstore" element={<NewProductionStore />} />
              <Route
                path="/storedetails"
                element={<ProductionStoreDetails />}
              />
              <Route path="/storelosses" element={<StoreLosses />} />

              <Route
                path="/finishedproductsstore"
                element={<FinishedStoreTable />}
              />
              <Route
                path="/finishedproducts"
                element={<FinishedStoreItems />}
              />

              <Route path="/inprocess" element={<InProcessTable />} />
              <Route path="/newinprocess" element={<NewInProcess />} />
              <Route path="/outputitems" element={<OutputDetails />} />

              <Route path="/qa" element={<QATable />} />
              <Route path="/newqa" element={<NewQA />} />
              <Route path="/qadetails" element={<QADetails />} />

              <Route path="/productionlosses" element={<ProductionLosses />} />
              <Route path="/productionreports" element={<Prodctionreports />} />
              <Route
                path="/productionprocessreports"
                element={<ProcesswiseReport />}
              />
              <Route
                path="/productiontransactionreports"
                element={<TransactionReport />}
              />
              <Route
                path="/productionstoreitemtransactionreports"
                element={<StoreItemTransactions />}
              />

              {/* Asset management */}
              <Route path="assetmanagement" element={<AssetMenu />} />
              <Route path="/asset" element={<AssetTable />} />
              <Route path="/newasset" element={<NewAsset />} />
              <Route
                path="/newthirdpartyasset"
                element={<NewThirdpartyAsset />}
              />
              <Route path="/assetdetails" element={<AssetDetails />} />
              <Route path="/assetmaintenance" element={<Assetmaintenance />} />
              <Route
                path="/newassetmaintenance"
                element={<NewAssetmaintenance />}
              />
              <Route path="/productionusage" element={<ProductionUsage />} />
              <Route
                path="/newproductionusage"
                element={<NewProductionUsage />}
              />
              <Route path="/rentasset" element={<RentAsset />} />
              <Route path="/assetdetails2" element={<RentSoldAssetDetails />} />
              <Route path="/transportation" element={<TransportationTable />} />
              <Route path="/newtrip" element={<NewTrip />} />
              <Route path="/tripdetails" element={<Tripdetails />} />
              <Route path="/addplace" element={<NewPlaces />} />
              <Route
                path="/transportationreport"
                element={<TransportationReports />}
              />
              <Route
                path="/transportationreportdetails"
                element={<TransportationReportdetails />}
              />
              <Route
                path="/assetinvoicedetails"
                element={<RentalSoldInvoice />}
              />

              <Route
                path="/salesvspurchasereports"
                element={<SalesvsPurchase />}
              />

              {/* ---------------------Employee----------------- */}

              <Route path="/employeesalary" element={<SalaryComponent />} />
              <Route
                path="/addemployeesalary"
                element={<AddEmployeeComponent />}
              />
              <Route
                path="/employeesalarybreakup"
                element={<SalaryBreakupComponent />}
              />
              <Route
                path="/newemployeesalarybreakup"
                element={<SalaryBreakup />}
              />
              <Route
                path="/empsalprogressmonth"
                element={<SalaryProgressMonth />}
              />
              <Route
                path="/empsalprogressstatus"
                element={<EmpStatusDetails />}
              />
              <Route
                path="/addemployeeadvace"
                element={<AddEmployeeAdvance />}
              />
              <Route
                path="/employeebankdetails"
                element={<EmployeeBankDetails />}
              />

              {/* --------------------------------Traacs Router data start---------------------------------------------------------- */}

              <Route path="/tourmaster" element={<TourMaster />} />
              <Route path="/newtravelinvoice" element={<Invoices />} />

              <Route path="/ticket" element={<Ticket />} />
              <Route path="/arsharing" element={<ARSharing />} />
              <Route path="/service" element={<Services />} />
              <Route path="/hotel" element={<Hotel />} />
              <Route path="/car" element={<Car />} />
              <Route path="/adjustment" element={<Adjustment />} />
              <Route path="/hotelorcar" element={<HotelORCar />} />

              {/* <Route path="/Mastertour" element={<Mastertour />} /> */}
              <Route path="/hotelMaster" element={<MHotel />} />
              <Route path="/hotelMasterList" element={<MHotelList />} />
              <Route path="/hotelMastertax" element={<MHotelTax />} />
              <Route path="/hotelMastertaxList" element={<MHotelTaxList />} />
              <Route path="/hotelMasterchain" element={<MHotelChain />} />
              <Route
                path="/hotelMasterchainlist"
                element={<MHotelChainList />}
              />
              <Route path="/hotelroomtype" element={<MRoomtype />} />
              <Route path="/hotelroomtypelist" element={<MRoomtypeList />} />
              <Route path="/hotelmealsplan" element={<MHotelMealsPlan />} />
              <Route
                path="/hotelmealsplanlist"
                element={<MHotelMealsPlanList />}
              />
              <Route path="/mastercar" element={<MCar />} />
              <Route path="/mastercarlist" element={<MCarList />} />

              {/* <Route path="/Mastertravel" element={<Mastertravel />} /> */}
              <Route path="/masterairline" element={<MAirline />} />
              <Route path="/masterairlinelist" element={<MAirlineList />} />
              <Route path="/masterairport" element={<MAirport />} />
              <Route path="/masterairportlist" element={<MAirportList />} />
              {/* <Route path="/mastertickettypelist" element={<MTicketTypeList />} /> */}
              {/* <Route path="/mastertickettype" element={<MTicketType />} /> */}
              <Route path="/masterpage" element={<ToursAndTravells />} />
              {/* ----------------------------View Sales Invoice ------------------------------------------------- */}

              <Route path="/viewinvoices" element={<ViewISalesInvoices />} />

              <Route path="/viewinvoicespage" element={<ViewInvoicePage />} />

              <Route path="/salestravelinvoice" element={<InvoiceMainPage />} />

              <Route path="/masterlist" element={<MasterList />} />

              <Route path="/ticketlist" element={<TicketList />} />

              {/* ----------------------------Partner------------------------------------------------- */}
              <Route path="/masterpartner" element={<Partner />} />
              <Route path="/masterpartnerlist" element={<PartnerList />} />

              {/* --------------------------------Traacs Router data ends---------------------------------------------------------- */}

              {/* -------------------------Project Management Start--------------------------------- */}

              <Route
                path="/projectmanagement"
                element={<ProjectManagement />}
              />

              <Route
                path="/estimationparameters"
                element={<EstimationParameters />}
              />

              <Route
                path="/addestimationparameters"
                element={<AddEstimationParameters />}
              />

              <Route path="/projects" element={<Projects />} />

              <Route path="/project/:id" element={<ProjectDetails />} />

              <Route path="/newproject" element={<ProjectCreation />} />

              <Route path="/addsite" element={<AddSite />} />
              <Route
                path="/addprojectmanager"
                element={<AddProjectManager />}
              />

              <Route path="/assignclient" element={<ClientAssign />} />

              <Route path="/addsiteincharge" element={<AddSiteIncharge />} />

              <Route
                path="/projectestimation"
                element={<ProjectEstimation />}
              />

              <Route
                path="/newprojectestimation"
                element={<NewProjectEstimation />}
              />
              <Route
                path="/projectestimation/:id"
                element={<ProjectEstimationDetails />}
              />

              <Route
                path="/projectdashboard"
                element={<ProjectDashboard />}

              />

              <Route
                path="/Labours"
                element={<Labours />}
              />
                <Route
                path="/AddLabour"
                element={<AddLabour />}
              />
              
              <Route
                path="/projectindenting"
                element={<ProjectIndenting />}
              />

                <Route
                path="/newprojectindenting"
                element={<NewProjectIndenting/>}
                />



              {/* -------------------------Project Management End--------------------------------- */}

              <Route path="*" exact={true} element={<NotFound />} />
              <Route path="/paymentgateway" element={<PaymentGateway />} />
            </Routes>
          </>

          {/*     <AuthenticationRoute path="/todos" component={ListTodoComponent}/>
                            <AuthenticationRoute path="/logout" component={LogoutComponent}/> */}
          {/* <Route component={ErrorComponent}/> */}
        </Router>
      </div>
    );
  }
}
