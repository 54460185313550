import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete, Backdrop, CircularProgress, TextField} from '@mui/material';
import { NumericFormat } from 'react-number-format';import {ccountry, currentTenant, currentUser, taxtype, thousandsdisplay, userid } from '../Common/Credentials';
import { Typography} from '@mui/material';
import NewJournalService from './NewJournalService';
import { TableHead,TableBody,TableRow,Table, TableCell} from "@mui/material";
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';

// const getTotal = (totamt) => {
//   let total = 0;
//   totamt.forEach(totamt => {
//     total += totamt;
//   });
//   return total;
// };
let totalcombdata=[],pendingdatainv=[],pendingdataae=[],pendingdatats=[]//,pendingdataps=[],entrytype
let selchk=[],debdata,val=[],gsttype

let ppaymennts,ppaymennts2,cusdata


// const Checkbox = props => (
//   <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
// )
class DepreciationEntry extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:this.props.location.state.de,
            assetexpdata:this.props.location.state.ae,
            tripexpdata:this.props.location.state.te,
            pendingdatainv:[],
            pendingdataae:[],
            pendingdataps:[],
            pendingdatats:[],
            invchecked:0,
             gstchecked:"",
            pen:0,sur:0,
            partyid:null,poid:null,pmtid:null,total:'',
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            debselval:'',pmtchecked:'',pnpchecked:'',tcschecked:0,selectedFiles: undefined,serviceamount:null,serviceledger:"",
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],
            fileInfo:"",pendingPayments:[],selectedPP:[],selectedPP2:[],selectedPPO:[],selectedPPO2:[],
            selchk:[],selchk1:[],selamt:[],selamt2:[],displaychecked:false,prevpmtschecked:false,genpayment:[],
            totalcombdata:[],jvinsertiontype:0,branch:null,deptid:null,
            deploss:undefined,assetexp:undefined,tripexp:undefined,
            depid:undefined,assetidae:undefined,assetidte:undefined,
            loader:false,loader1:true,taxamount:0,privdepts:''

             
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.loadData=this.loadData.bind(this)
        this.AddData=this.AddData.bind(this)
    }

    onDebBlur(values){
      this.setState({debselval:values.debit})
      if(values.debit!==undefined && values.debit.length!==0){
        this.setState({partyid:values.debit.id})
      }
    }

    onSubmit(values){
      this.setState({loader:true})
    
      let jvtype,gst,taxval1,cgst,taxval2,igst,taxval,vat,vatval
      if(this.state.assetexpdata!==undefined){
        jvtype=8
      }else if(this.state.tripexpdata!==undefined){
        jvtype=8
      }else{
        jvtype=10
      }

      if(this.state.taxamount!==0){
        if(values.sgst!==undefined){
        gst={id:values.sgst.id,name:values.sgst.name}
        taxval1=values.taxvalue1
        cgst={id:values.cgst.id,name:values.cgst.name}
        taxval2=values.taxvalue2
        }
        if(values.igst!==undefined){
        igst={id:values.igst.id,name:values.igst.name}
        taxval=values.itaxvalue1
        }
        if(values.vat!==undefined){
        vat={id:values.vat.id,name:values.vat.name}
        vatval=values.gtaxvalue1
        }
      }
     
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
        creditid: values.credit.id,creditname: values.credit.name,creditamt:values.creditvalue,
        narration: values.narration,createdBy: userid,tenantId: currentTenant,
        pendingdata:this.state.pendingdatainv,frompage:this.props.location.pathname,
        depid:this.state.depid,pendingdataae:this.state.pendingdataae,assetidae:this.state.assetidae,
        pendingdataps:this.state.pendingdataps,dmgidps:this.state.dmgidps,
        pendingdatats:this.state.pendingdatats,assetidte:this.state.assetidte,
        jvtype:jvtype,supplierid:values.credit.id,supplier:values.credit.name,jvinsertiontype:this.state.jvinsertiontype,
        gst,taxval1,cgst,taxval2,igst,taxval,vat,vatval,branch:this.state.branch,dept:this.state.deptid
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      } 
      else {
        // console.log(jv)
        this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
      }
    }

    componentDidMount(){
      // console.log(this.state.assetexpdata)

      this.loadData()
      // this.AddData()
    
      if(this.state.totalcombdata.length===0){
        this.loadData()
        this.setState({loader1:false})
       }else{
        this.setState({loader1:true})
       }
      // let credvalue,cred1
     // let cred1

       // this.state.pendingdatainv.map(e=>{return ledid=e.itemid})
        debdata=val.filter((e)=> e.th==="TH5")
       // cred1=val.filter((e) => e.type === 2)
       // credvalue=cred1.filter((e)=>e.shid===100007 )
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      if(this.state.data!==undefined){
      this.setState({pendingdatainv:this.state.data,jvinsertiontype:21})
      pendingdatainv=this.state.data
      pendingdatainv.map((e)=>{return this.setState({deploss:e.baseamount*e.quantity*e.depreciation/100})})
      pendingdatainv.map((e)=>{return this.setState({depid:e.id})})
      pendingdatainv.map((e)=>{return this.setState({branch:e.whid})})
      pendingdatainv.map((e)=>{return this.setState({deptid:e.deptid})})
      pendingdatainv.map((e)=>{return this.setState({privdepts:e[0].depreciationamount})})

      }else if(this.state.assetexpdata!==undefined) {
        this.setState({pendingdataae:this.state.assetexpdata,jvinsertiontype:20})
        pendingdataae=this.state.assetexpdata
        pendingdataae.map((e)=>{return this.setState({assetexp:e.amount,taxamount:e.taxamount})})
        pendingdataae.map((e)=>{return this.setState({assetidae:e.id})})
        pendingdataae.map((e)=>{return this.setState({branch:e.whid})})
        pendingdataae.map((e)=>{return this.setState({deptid:e.deptid})})
      }else if(this.state.tripexpdata!==undefined) {
        this.setState({pendingdatats:this.state.tripexpdata,jvinsertiontype:19})
        pendingdatats=this.state.tripexpdata
        pendingdatats.map((e)=>{return this.setState({tripexp:e.totalexp})})
        pendingdatats.map((e)=>{return this.setState({assetidte:e.id})})
        pendingdatats.map((e)=>{return this.setState({branch:e.whid})})
        pendingdataae.map((e)=>{return this.setState({deptid:e.deptid})})
      }
      let gtype ,taxtype
      pendingdataae.map((e)=>{return taxtype=e.taxtype})
      pendingdataae.map((e)=>{return gtype=e.gsttype})
      if(taxtype==="GST" &&  gtype==="INTRASTATE"){
        this.setState({gstchecked: 1})
        gsttype=1;
      }else if(taxtype==="GST" &&  gtype==="INTERSTATE"){
        this.setState({gstchecked: 2})
        gsttype=2;
      }else{
        gsttype=3;
      }

      // this.setState({loading:false})
       }

    loadData(){
      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data})
        totalcombdata=response.data
       },()=>this.AddData())
       .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      })

    }

    AddData(){
      if(totalcombdata!==undefined){
        val=totalcombdata 
        this.setState({loader1:false})
      }else(
        this.loadData()
      )
     
    }
     validate(values){
      let errors = {}
      //,type = totalcombdata

       if (!values.debit || values.debit==='') {errors.debit = "Accounts is required";}
     
       if (!values.credit || values.credit==='') {errors.credit = "Accounts is required"}
       if (!values.narration) {errors.narration = "Narration is required";}
       return errors;
     }
     onTcsChange(option){this.setState({ tcschecked: option.id });}

   

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
    let a 
    this.state.pendingdatainv.map((e)=>{return a=e.pitems})
    this.setState({selchk:a}) 
    selchk=a

 }

    render() {
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      let cred1
      const {isError,message} = this.state;
      let credvalue,selpp,selpp2
    //  entrytype=1
     
        debdata=null
        if(this.state.depid!==undefined){
        debdata=val.filter((e)=> e.th==="TH5")
        }else{
         
          debdata=val.filter((e)=> e.th==="TH2")
  
        }
      // }
    
      
      cred1=val.filter((e) => e.type === 2)
      // credvalue=cred1.filter((e)=>e.shid===100007)
      credvalue=cred1.filter((e)=>e.th==="TH3")

      if(this.state.assetexpdata!==undefined){
        credvalue=val.filter((e)=>e.subheadid==="AH000019")
      }else if(this.state.tripexpdata!==undefined){
        credvalue=val.filter((e)=>e.subheadid==="AH000019")
      }else{
        cred1=val.filter((e) => e.type === 2)
        // credvalue=cred1.filter((e)=>e.shid===100007)
        credvalue=cred1.filter((e)=>e.th==="TH3")   
      }
      
      let tax,taxsecond;
      if (gsttype === 1 ) {
        tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===0) );
        
        taxsecond = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===1) );
       } 
      else if (gsttype === 2) {
        tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===2) );
      } 
      else if (gsttype === 3) {
        tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===1) & (e.indirectsubtaxtype===0) );
       // console.log(tax)
      } 
      else if (gsttype === undefined) {
        tax = val.filter((e) => e.taxtype === 2 & (e.indirecttaxtype === 1));
  
        cusdata=val.filter((e) => e.taxtype === 2 & (e.indirecttaxtype===2))
  
      }
         
// -------------------NEW ----------------



// let otherled
// otherled=val.filter((e)=>e.th==="TH2")


        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">
<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/depreciationl"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    {/* <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/depreciationl', state: {message:''}}} >
    Asset Loses & Exp  
              </Link>
    </li> */}
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/deprientry'}} state= {{message:''}} >
         Entry
              </Link>
    </li>

  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {/* {this.state.loading===true?spin:''} */}
  </div>
              
        
    <Formik
      initialValues={{ debdata,credvalue,narration:'',selpp,selpp2,ppaymennts,ppaymennts2,selchk}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">




<div className="form-row mt-0 mb-0 text-sm sm">
{this.state.depid!==undefined ?<>
  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
    <TableCell className="col-md-2 text-sm">Branch/WH</TableCell>
 <TableCell className="col-md-2 text-sm">Percentage</TableCell>
  <TableCell className="col-md-2 text-sm">Category</TableCell>
 <TableCell className="col-md-2 text-sm">Qty</TableCell>
 <TableCell className="col-md-2 text-sm">Price</TableCell>
 <TableCell className="col-md-2 text-sm">Total Loss</TableCell>
 {/* <TableCell className="col-md-1 text-sm">Penalty</TableCell>
 <TableCell className="col-md-1 text-sm">Total</TableCell> */}
  </TableRow>
    </TableHead>
  
   <TableBody>
   {pendingdatainv.map((e)=>
  <TableRow>
 <TableCell  className="col-md-2 text-sm">{e.itemname}</TableCell>
 <TableCell className="form-group col-md-2 text-sm">{e.warehouse}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.depreciation}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.category}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.quantity}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.baseamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
    </TableCell>
  <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {(e.baseamount*e.quantity)*e.depreciation/100} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  {/* <TableCell className="form-group col-md-1 text-sm">{e.penalty}</TableCell>

  <TableCell className="form-group col-md-1 text-sm">
  <NumericFormat className="text-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalamount}
   thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell> */}
  </TableRow>)}
</TableBody></Table>

</fieldset></>:""}

{ this.state.assetidae!==undefined?<>
  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
    <TableCell className="col-md-2 text-sm">AssetId</TableCell>
 <TableCell className="col-md-2 text-sm">Type</TableCell>
  <TableCell className="col-md-2 text-sm">Reason</TableCell>
 <TableCell className="col-md-2 text-sm">Qty</TableCell>
 <TableCell className="col-md-2 text-sm">Exp Price</TableCell>
 {/* <TableCell className="col-md-2 text-sm">Total Loss</TableCell> */}
 {/* <TableCell className="col-md-1 text-sm">Penalty</TableCell>
 <TableCell className="col-md-1 text-sm">Total</TableCell> */}
  </TableRow>
    </TableHead>
  
   <TableBody>
   {pendingdataae.map((e)=>
  <TableRow>
 <TableCell  className="col-md-2 text-sm">{e.itemname}</TableCell>
 <TableCell className="form-group col-md-2 text-sm">{e.asset}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.type}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.description}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.quantity}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.amount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
    </TableCell>
  {/* <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalloss} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell> */}
 
  </TableRow>)}
</TableBody></Table>

</fieldset></>:""}

{ this.state.assetidte!==undefined?<>
  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Trip Id</TableCell>
    <TableCell className="col-md-2 text-sm">Asset Name</TableCell>
 <TableCell className="col-md-2 text-sm">From</TableCell>
  <TableCell className="col-md-2 text-sm">To</TableCell>
 <TableCell className="col-md-2 text-sm">KMS</TableCell>
 <TableCell className="col-md-2 text-sm">Total Exp</TableCell>
 {/* <TableCell className="col-md-2 text-sm">Total Loss</TableCell> */}
 {/* <TableCell className="col-md-1 text-sm">Penalty</TableCell>
 <TableCell className="col-md-1 text-sm">Total</TableCell> */}
  </TableRow>
    </TableHead>
  
   <TableBody>
   {pendingdatats.map((e)=>
  <TableRow>
 <TableCell  className="col-md-2 text-sm">{e.customid}</TableCell>
 <TableCell className="form-group col-md-2 text-sm">{e.vehicle}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.originname}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.destinationname}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.totalkms}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalexp} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
    </TableCell>
  </TableRow>)}
</TableBody></Table>

</fieldset></>:""}

  </div>
 <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={!debdata?this.loadData():debdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
 value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>this.state.assetexp!==undefined?
   setFieldValue("debitval",this.state.assetexp):
    this.state.deploss!==undefined?setFieldValue("debitval",this.state.deploss):setFieldValue("debitval",this.state.tripexp)
   && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
  </div>     


  {ccountry==="India"  && this.state.gstchecked === 1 ? (
 <> <div className="form-row mt-0 mb-0 ">
 <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="SGST" className="Tax-select" name="sgst" options={tax} getOptionLabel={(option) => option.name}
  value={values.sgst ? undefined : undefined}
onChange={(e, value) =>  {setFieldValue("sgst", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="sgst" label="Select Tax"
onBlur={() => ( setFieldValue("taxvalue1",parseFloat((this.state.taxamount)/2).toFixed(2)) && 
  this.onDebBlur(values))}variant="standard" fullWidth/>)}/></fieldset>

<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="taxvalue1" className="form-control" label= {values.taxvalue1!==undefined?" ":"Value"} type="text"
  variant="standard" ></FormikTextField></fieldset>
  </div>
 
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
 <Autocomplete id="CGST" className="Tax-select" name="CGST" options={taxsecond} getOptionLabel={(option) => option.name}
  value={values.cgst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("cgst", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="cgst" label="Select Tax"
onBlur={() => {
  setFieldValue("taxvalue2",parseFloat((this.state.taxamount)/2).toFixed(2)) && this.onDebBlur(values);}}
 variant="standard" fullWidth/>)}/></fieldset>
  <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="taxvalue2" className="form-control" label={values.taxvalue2!==undefined?" ":"Value"} type="text"
  variant="standard" ></FormikTextField></fieldset>
</div>
</>) :""}

{ccountry==="India"  && this.state.gstchecked === 2 ? (
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="IGST" className="Tax-select" name="igst" options={tax} getOptionLabel={(option) => option.name}
  value={values.igst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("igst", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="igst" label="Select Tax" onBlur={() => { setFieldValue("itaxvalue1",parseFloat((this.state.taxamount)).toFixed(2)) && this.onDebBlur(values);}}
 variant="standard" fullWidth/>)}/>
 </fieldset>

  <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="itaxvalue1" className="form-control" label={values.itaxvalue1!==undefined?" ":"Value"} type="text"
  variant="standard" ></FormikTextField></fieldset>
</div>) : ""} 

{taxtype !== "GST"  ? (
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="vat" className="Tax-select" name="vat" options={tax} getOptionLabel={(option) =>option.name} 
 value={values.tax ? undefined : undefined}
onChange={(e, value) => {setFieldValue("tax", value);}}renderInput={(params) => (
<FormikTextField {...params} type="text" name="vat" label="Select Vat"
onBlur={() => {setFieldValue("gtaxvalue1",parseFloat((this.state.taxamount)).toFixed(2)) && this.onDebBlur(values);}} variant="standard" fullWidth/>)}/></fieldset>
  <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="gtaxvalue1" className="form-control" label={values.gtaxvalue1!==undefined ? " " : "Value"} type="text"
 //onBlur={() =>setFieldValue( "creditvalue",parseFloat(values.gtaxvalue1) +parseFloat(values.debitval))}
  variant="standard" ></FormikTextField></fieldset>
</div>) : ""}


<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={!credvalue?this.loadData():credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
getOptionLabel={(option) => option.name +" ("+option.amount+")"} groupBy={(option) => option.subname}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" 
 onBlur={()=> this.state.assetexp!==undefined?setFieldValue("creditvalue",values.debitval+this.state.taxamount):this.state.deploss!==undefined?setFieldValue("creditvalue",this.state.deploss+this.state.taxamount):setFieldValue("creditvalue",this.state.tripexp+this.state.taxamount)}
  variant="standard" fullWidth />)}/>
</fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0" value={values.creditvalue} 
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
 </fieldset>
 </div>


  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>
  {/* </>:""} */}


<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="btn hovbuttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(DepreciationEntry);              



 
