

import React, { Suspense } from "react";
import { Col } from "react-bootstrap";
import Credentials, {
  currentTenant,
  currentUser,
  financialyearend,
  financialyearstart,
  userid,
} from "../Common/Credentials";
import {  Grid, TextField } from "@mui/material";

import UsercustomerService from "../Masters/Usercustomers/UsercustomerService";

import { spin } from "../Common/NewLoader";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";

import finanzz from "../images/Finanzzi.png";

class ProjectDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      theme: false,
      data: "",
      filters: ["Year", "Month", "Day", "Q1", "Q2", "Q3", "Q4"],
      filter: "Year",
      clientsdata: [],
      buttonClicked: 0,
      inventorydata: [],
      blockedsalesquantity: "",
      blockedpurchasequantity: "",
      saleOrder: [],
      completedso: [],
      soloader: true,
      poloader: true,
      podata: [],
      completedpo: [],
      salesdata: [],
      purchasedata: [],
      top10productsbyquantity: [],
      top10productsbyamount: [],
      top10Customers: [],
      top10Suppliers: [],
      recent5so: [],
      recent5inv: [],
      recent5po: [],
      recent5grns: [],
      type: "Quantity",
      inventory: true,
      sales: true,
      purchase: true,
      accounts: true,
      revenue: true,
      salesvpurchase: true,
      customersstats: true,
      suppliersstats: true,
      inventorystats: true,
      productionstats: true,
      assetsstats: true,
      assetsdata: [],
      assetitemlist: [],
      top10assetitems: [],
      loader: true,
      usercustomers: [],
      invoicedata: [],
      saleorders: [],
      receiptsdata: [],
      startyear: moment(financialyearstart),
      endyear: moment(financialyearend),
      page: 0,
      rowsPerPage: 10,
      showplan: false,
      planamount: "",
      planduration: "",
      selectedtenant: "",
    };
  }

  componentDidMount() {
    Credentials.setSelectedMenu("Home");
    if (
      userid === undefined ||
      currentUser === undefined ||
      currentTenant === undefined
    ) {
    } else {
      UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
        this.setState({
          usercustomers:
            res.data === "" || res.data === undefined
              ? []
              : res.data.customers.split(","),
        })
      );

      this.refresh(moment(financialyearstart), moment(financialyearend));
    }
  }

  loaderupdate = () => {
    this.setState({ loader: false });
  };

  refresh = (start, end) => {
    let datadao = {
      tenantid: currentTenant,
      startdate: start,
      enddate: end,
    };
    
    // -----------------INVENTORY ----------------

    //---------------------SALES----------------------------
 
    // ---------------------PURCHASE---------------------
 
    //------------------------ASSETS---------------------------
  

    this.loaderupdate();
  };

  HandleyearChange = (e) => {

    let diff =
      moment(financialyearstart).format("yyyy") === moment(financialyearend).format("yyyy");
    let smonth = moment(financialyearstart).format("MM");
    let emonth = moment(financialyearend).format("MM");
    let syear = moment(new Date(e)).format("yyyy");
    let eyear = moment(new Date(e))
      .add(diff ? 0 : 1, "y")
      .format("yyyy");
    this.setState({
      startyear: moment(`${syear} ${smonth}`).format("yyyy MM"),
      endyear: moment(`${eyear} ${emonth}`).format("yyyy MM"),
    });
    this.refresh(moment(`${syear} ${smonth}`), moment(`${eyear} ${emonth}`));

  };




  render() {
    return (
      <>
        
        <Suspense
          fallback={
            <div className="centerItem" style={{ height: "70vh" }}>
              <div>
                <img
                  src={finanzz}
                  alt="Logo..."
                  width="100%"
                  height="100"
                  className="finanzzlogo"
                />
              </div>
            </div>
          }
        >
          <div>
            <Header />
            <Menu />
            <div
              className={`content-wrapper ${
                this.state.theme === false ? "" : "bg-black5"
              }`}
            >
              {this.state.loader === true ? (
                <div className="centerItem" style={{ height: "70vh" }}>
                  <div>
                    <img
                      src={finanzz}
                      alt="Logo..."
                      width="100%"
                      height="100"
                      className="finanzzlogo"
                    />
                    <div>{spin}</div>
                  </div>
                </div>
              ) : (
                <div
                  className={`p-3 ${
                    this.state.theme === false ? "" : "bg-black5"
                  }`}
                >
                  <div className="d-flex justify-content-between">
                    <div className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Financial Year{" "}
                          {moment(this.state.startyear).format("yyyy")}{" "}
                          {financialyearstart.substring(0, 4) ===
                          financialyearend.substring(0, 4)
                            ? ""
                            : -moment(this.state.endyear).format("yyyy")}
                        </b>
                      </h4>
                    </div>
                    <Col sm={2} lg={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container justifyContent="space-around">
                          <DatePicker
                            variant="inline"
                            id="date-picker-dialog"
                            label="Financial Start Year"
                            openTo="year"
                            views={["year"]}
                            minDate={"2022"}
                            maxDate={new Date()}
                            value={this.state.startyear}
                            onChange={this.HandleyearChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                className={`${
                                  this.state.theme === false ? "" : "text-white"
                                }`}
                              />
                            )}
                            closeOnSelect={true}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </Col>

                  </div>
                  
           
                </div>
              )}
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

export default ProjectDashboard;
