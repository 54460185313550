import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import MUIDataTable from "mui-datatables";
import {NumericFormat} from "react-number-format";
import { currentTenant, thousandsdisplay, userid } from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Menu from "../Component/Menu";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Backdrop, CircularProgress, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import SalesServices from "../Sales/SalesServices.js";
import PurchseServices from "../Purchase/PurchseServices.js";
import { Spinner } from "react-bootstrap";
import NewLedgerService from "./Ledgers/NewLedgerService.js";
import moment from "moment";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class Debitcreditnotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaserefunds: [],
      salesrefunds:[],
      // openingrefunds:[],
      message: "",
      loading:false,
      loader1:true,
      loader:false
    };
    this.refreshItems = this.refreshItems.bind(this);
  }

  refreshItems() {
    SalesServices.getSalesRefunds(currentTenant).then(res=>
      this.setState({salesrefunds:res.data,})
      )
    // NewLedgerService.getPendingOpenings(currentTenant).then(res=>
    //   this.setState({openingrefunds:res.data,})
    //   )
      PurchseServices.getPurchaseRefunds(currentTenant).then((response) => {
      this.setState({ purchaserefunds: response.data},()=>this.setState({loader1:false}));
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
   
  }

  // submitRecordOpeningCR(value){

  //   let pendingpo
  //   if(value!==undefined){
  //   pendingpo=this.state.openingrefunds.filter(e=>e.id===value.rowData[0])
  //   } 
  //   this.props.navigate({pathname:`/dnentry`},{state:pendingpo});
  
  //  }

  //  submitRecordOpeningDR(value){
  //   let pendingso
  //   if(value!==undefined){
  //   pendingso=this.state.openingrefunds.filter(e=>e.id===value.rowData[0])
  //   } 
  //   this.props.navigate({pathname:`/cnentry`}, {state:pendingso});
  
  //  }

  submitRecord(value){

    let pendingpo
    if(value!==undefined){
    pendingpo=this.state.salesrefunds.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/dnentry`},{state:pendingpo});
  
   }

   submitRecordPurchase(value){
    let pendingso
    if(value!==undefined){
    pendingso=this.state.purchaserefunds.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/cnentry`}, {state:pendingso});
  
   }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    //  this.setState({loading:true})

    this.refreshItems();
  }


  render() {
    const columns = [
      {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "customer",label: "Customer name",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "itemname",label: "Item name",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "quantity",label: "Quantity",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "cntype",label: "cntype",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "invid",label: "invid",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "tax",label: "Tax",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "taxamount",label: "Taxamount",options: {filter: false,sort: true,sortOrder: 'asc',
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
      {name: "totalwtax",label: "Total Refund",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "damagestatus",label: "Reason",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      
      {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
        customBodyRender:(value,tableMeta)=>{
          return (
            value!==null && value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
            onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
            </button>:null
          );      

        }
      
      }},
  
     ];
     
     const options = {
       filterType: 'checkbox',
       selectableRows: "none",
       resizableColumns: true,
     }

     const columnspurchase = [
      {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "supplier",label: "Supplier name",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "item",label: "Item name",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "quantity",label: "Quantity",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "tax",label: "Tax",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "taxamount",label: "Taxamount",options: {filter: false,sort: true,sortOrder: 'asc',
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
      {name: "totalwtax",label: "Total Refund",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "damagestatus",label: "Reason",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      
      {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
        customBodyRender:(value,tableMeta)=>{
          return (
            value!==null && value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
            onClick={() => this.submitRecordPurchase(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
            </button>:null
          );      

        }
      
      }},
  
     ];
     
     const optionspurchase = {
       filterType: 'checkbox',
       selectableRows: "none",
       resizableColumns: true,

     }


    //  const columnsopening = [
    //   {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
    //   {name: "partuname",label: "Party Ledger",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
    //   {name: "entrytype",label: "Type",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
    //   {
    //     name: "createdAt",
    //     label: "Date",
    //     options: {
    //       filter: false,
    //       sort: true,
    //       sortOrder: 'asc',
    //       customBodyRender: (tableMeta) => {
    //         return moment(tableMeta).format("DD-MM-YYYY hh:mm");
    //       },
    //     },
    //   },    
    //   {name: "type",label: "Note Type",options: {filter: false,sort: true,
    //     customBodyRender:(tableMeta)=>{
    //       return(tableMeta==="Debit"?"Debit Note":"Credit Note")
    //     },

    //     setCellProps: () => ({ align: 'center' })}},
    //   {name: "price",label: "Total",options: {filter: false,sort: true,sortOrder: 'asc',
    //     customBodyRender:(tableMeta)=>{
    //       return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //     },
    //     setCellProps: value => ({ style: { textAlign:'right'}}),
    //   }},
    // //   {name: "tax",label: "Tax",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
    // //   {name: "taxamount",label: "Taxamount",options: {filter: false,sort: true,sortOrder: 'asc',
    // //   customBodyRender:(tableMeta)=>{
    // //     return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    // //   },
    // //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // // }},
    // //   {name: "totalwtax",label: "Total Refund",options: {filter: false,sort: true,sortOrder: 'asc',
    // //     customBodyRender:(tableMeta)=>{
    // //       return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    // //     },
    // //     setCellProps: value => ({ style: { textAlign:'right'}}),
    // //   }},
    // //   {name: "damagestatus",label: "Reason",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      
    //   {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
    //     customBodyRender:(value,tableMeta)=>{
    //       return (
    //         value.length!==0 && tableMeta.rowData[4] === "Debit" ?<button className="hovbuttonColour btn btn-sm"
    //         onClick={() => this.submitRecordOpeningDR(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
    //         </button>:<button className="hovbuttonColour btn btn-sm"
    //         onClick={() => this.submitRecordOpeningCR(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pay"}
    //         </button>
    //       );      

    //     }
      
    //   }},
  
    //  ];
     
    //  const optionsopening = {
    //    filterType: 'checkbox',
    //    selectableRows: "none",
    //    resizableColumns: true,

    //  }


    return (
      <div>
      <Header/>
          <Menu/>
          <div className="content-wrapper">
          <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>

            <li className="breadcrumb-item active">Debit & Credit Refunds</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                 

                    <div className="card-body p-0">
                      <div>{this.state.loading === true ? <div className="text-center">{spin}</div> : <>

                      <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Sales Refunds"}
                        data={this.state.salesrefunds}
                        columns={columns}
                       options={options}
                      />
                      </ThemeProvider>
<br/>

                         <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Purchase Refunds"}
                        data={this.state.purchaserefunds}
                        columns={columnspurchase}
                       options={optionspurchase}
                      />
                      </ThemeProvider>

                      <br/>

                         {/* <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Opening Entries"}
                        data={this.state.openingrefunds}
                        columns={columnsopening}
                       options={optionsopening}
                      />
                      </ThemeProvider> */}

                     </> }</div>

                      </div>
                   
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
        </div>
        <Footer/>   
      </div>
    );
  }
}
export default withRouter(Debitcreditnotes);
