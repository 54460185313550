import { createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { appmasterid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import Employeeservices from "./Employeeservices";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class EmployeesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
        employees: [],
        drivers:[],
        contractors:[],
        employeetype:this.props.location.state.employeetype,
        loading:false,
    };
  }
  deleteEmployee(id){
    Employeeservices.deleteEmployee(id).then((resposne) => {
      this.refresh();
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  refresh(){
    Employeeservices.getAllEmployees(currentTenant).then(res=>
      this.setState({employees:res.data.filter(e=>e.employeetype===0),drivers:res.data.filter(e=>e.employeetype===1),contractors:res.data.filter(e=>e.employeetype===2),loading:false})
      )
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({loading:true})
   this.refresh()
  }
  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "asc",
        },
      },
      {
        name: "first",
        label: "First",
        options: { filter: true, sort: true },
      },
      {
        name: "last",
        label: "Last",
        options: { filter: true, sort: true },
      },
      {
        name: "email",
        label: "Email",
        options: { filter: true, sort: true },
      },
      {
        name: "contactnumber",
        label: "Contact no",
        options: { filter: true, sort: true },
      },
      {
        name: "cityname",
        label: "City",
        options: { filter: true, sort: true },
      },
    //   {
    //     name: "role",
    //     label: "Role",
    //     options: { filter: true, sort: true },
    //   },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
            
             <Link as="button"  className="btn btn-sm hovbuttonColour mr-2"
              to={{pathname: "/newemployee"}} state ={{id:tableMeta.rowData.find(e=>e),name:this.state.employeetype===0?"Edit Employee":this.state.employeetype===1?"Edit Drivers":"Edit Contractors",employeetype:this.state.employeetype}}>Edit</Link>
             <Link className="btn btn-sm hovbuttonColour mr-2"
              to={{pathname: "/employeebankdetails"}} state={{empid:tableMeta.rowData.find(e=>e),employeetype:this.state.employeetype}} >Bank Details</Link>
              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete Employee ?`
                    )
                  )
                    this.deleteEmployee(tableMeta.rowData.find((e) => e));
                }}
              >Delete</span>
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newemployee"}}
                  state= {{ id: -1, name: this.state.employeetype===0?"New Employee":this.state.employeetype===0?"New Driver":"New Contractor", employeetype:this.state.employeetype}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
      <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item active">Employees</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (<>
                  <fieldset className={`form-group col-md-3 mr-1 mb-lg-0 shadow-sm text-center btn ${this.state.employeetype === 0?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({employeetype:0})} disabled={this.state.employeetype===0}>
                        Employees 
                      </fieldset>
                      <fieldset className={`form-group col-md-3 mr-1 mb-lg-0 shadow-sm btn text-center ${this.state.employeetype === 1?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({employeetype:1})} disabled={this.state.employeetype===1}>
                        Drivers
                      </fieldset>
                      <fieldset className={`form-group col-md-3 mr-1 mb-lg-0 shadow-sm btn text-center ${this.state.employeetype === 2?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({employeetype:2})} disabled={this.state.employeetype===2}>
                        Project Contractors
                      </fieldset>
                      {this.state.employeetype===0?
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"Employees"}
                        data={this.state.employees}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                    :this.state.employeetype===1?
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"Drivers"}
                        data={this.state.drivers}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>:
                    <ThemeProvider theme={theme}>
                    <MUIDataTable
                      className="table-sm px-3"
                      title={"Project Contractors"}
                      data={this.state.contractors}
                      columns={columns}
                      options={options}
                    />
                  </ThemeProvider>
                  }
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(EmployeesTable);
