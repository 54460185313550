import { Form, Formik } from 'formik'
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete, TextField } from "@mui/material";
import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';//import {TextField} from '@mui/material';
//import { FormikTextField } from '../../CommonComponents/FormField.js';;
import { currentTenant, financialyearend, financialyearstart, thousandsdisplay, transactionFrom, userid} from '../../Common/Credentials.js';
import  IfscFetch, {bankifsc, bankres, branchres } from './IfscFetch.jsx';
import Swal from 'sweetalert2'
import Grid from "@mui/material/Grid";
import NewLedgerService from './NewLedgerService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { FormControlLabel } from '@mui/material';
import moment from "moment";
import IOSSwitch from '../../CommonComponents/IOSSwitch.js';
import SubAccService from '../../Masters/Subaccounts/SubAccService.js';
import { Link } from 'react-router-dom';

const getTotal = (data) => {
  let total = 0;
  // console.log(data)
  data.forEach(data => {
    total += data;
  });
  // console.log(total)
  return total;
};
let ifsccode,branch,name
export default class Bank extends Component {

  constructor(props) {
    super(props)


    this.state = {
      Name:"Bank",
      ODName:'',
      id:'',
      bankdata:[],
      masbanks:[],
      subheads:[],
      opening:'',
      name:'',
      accname:'',
      ifsccode:'',
      branch:'',
      number:'',
      loader:false,
      acctype:false,    
      selectedbank:"",
      fed:'',
      SDdate:false

    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    //this.handleChange=this.handleChange.bind(this);
    this.refreshData=this.refreshData.bind(this)
    this.ifscClick=this.ifscClick.bind(this)
    this.handleAccType=this.handleAccType.bind(this)
  }

  componentDidMount() {

this.refreshData()
this.ifscClick1()
// this.setState({
//   fed: moment(transactionFrom.$d).format("yyyy-MM-DD HH:mm:ss"),
// });

  }

  refreshPage() {

this.setState()
	}

  handleSDDate=()=>{
    this.setState({SDdate:this.state.SDdate===true?false:true})
  }

  handleFsdDateChange = (date) => {
    if(date!==null){
    this.setState({ fsd: date.$d });
    this.setState({
      fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
    });
  }else{
    this.setState({ SDdate:false})
  }
  };

onSubmit(values) {
 // console.log(values)
  this.setState({loader:true})
  let newOrgbank,newOrgodbank
  if(this.state.acctype===false){
  if(this.state.selectedbank!==undefined || this.state.selectedbank!==""){
  newOrgbank = {
    name:this.state.Name,
    createdby:userid,
    opening:values.opening,
    tenantId:currentTenant,
    bank:this.state.selectedbank.bankname,
    accname:this.state.selectedbank.accname,
    accnumber:this.state.selectedbank.accno,
    ifsc:this.state.selectedbank.ifsc,
    date:this.state.fed!==''?this.state.fed:null,
    branch:this.state.selectedbank.branchname,
    ledtype:1
  }
}} else{
   newOrgodbank = {
    name:this.state.ODName,
    createdby:userid,
    opening:0-values.opening,
    tenantId:currentTenant,
    bank:values.name,
    accname:values.accname,
    accnumber:values.number,
    ifsc:bankifsc,
    branch:values.branch,
    date:this.state.fed!==''?this.state.fed:null,
    ledtype:2
  }
}

  // let updateAccTop = {
  //   id:this.state.id,
  //   name: values.name,
  //   createdby:userid

  //    }
  //  MasterService.createTenant(newtenant).then(() => this.props.navigate(`/tenants`))
  if (this.state.id === '') {
    if(this.state.acctype===false){       
    NewLedgerService.addBank(newOrgbank)
     .then((response) => 
     this.setState({bankdata:response.data,loader:false}) , 
     this.setState({})
     )
     .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  }) 
}else{
  NewLedgerService.addBank(newOrgodbank)
  .then((response) => 
  this.setState({bankdata:response.data,loader:false}) , 
  this.setState({})
  )
  .catch((e)=>{
   if(e.response.data.error ==="Unauthorized"){
       alert("Your Session is Lost: Please login Back to resume");
       this.props.navigate(`/`);
   }
}) 
}
     var frm = document.getElementsByName('bankdetails')[0];
     frm.reset();
     return false
     

//     this.props.navigate(`/acctop`,{message:values.name + "   : Created Successfully"}))
    //     .catch(error => this.setState({error:error.message + "   Account Name Already Exists"}))
  }else {
    this.setState({loader:false})
    //console.log("Update Request"+this.state.id)
//     AccTopService.updateAcctop(updateAccTop)
//     .then(() => 
//      this.props.navigate('/acctop',{message:values.name + "   : Updated Successfully"}))

 }   
}

refreshData(){

  NewLedgerService.getMasterBanks(currentTenant)
  .then((response) => {
  this.setState({masbanks:response.data})
})

  SubAccService.retriveSubHead(currentTenant)
  .then(response =>{
    
    this.setState({subheads:response.data})

  })

  NewLedgerService.getBanks(currentTenant)
  .then((response) =>
  {if(response.data==='' || response.data===undefined || response.data===null){
    // console.log("if part"+response)

    //this.setState({bankdata:''})

  }else{
  this.setState({bankdata:response.data})
  // console.log("Else part"+response)
}
}
  )
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

}

// handleChange(value){
// this.setState({ledgername:value})
// }

banksLedgers(value){
this.setState({selectedbank:value})
}

subHeads(values){
  //console.log(values)
  if(values!==undefined){
  this.setState({ODName:values.name})
}
  }
deleteLedger(result){
  let ledban={
    ledgerid:result.ledgerid,
    bankid:result.id
  }
  NewLedgerService.delBA(ledban)
  .then(response=>{
    Swal.fire({
      title: "Response",
      text: response.data,
      icon: "info",
    confirmButtonText: "Ok",
    confirmButtonColor:'#152f5f',
    })
   // alert(response.data)
    this.refreshData()
  })
}

  validate(values) {
    let errors = {};
    var chars = /^[A-Za-z ]+$/
    var num=/^[0-9,.-]+$/

if(this.state.acctype===true){
    if (!values.name) {
      errors.name = "Bank Name is required";
    }else if (!chars.test(values.name)) {
      errors.name = 'Only Characters are allowed';
    }

    if(!values.ifsccode && bankifsc===null){
      errors.ifsccode =" IFSC Code Required"
    }
    if(values.ifsccode!==undefined){
    if(values.ifsccode.length!==11){
      errors.ifsccode =" Invalid"
    }
  }
    if (!values.branch) {
      errors.branch = "Branch is required";
    }
    if (!values.number) {
      errors.number = "Account Number is required";
    }
    if (!values.accname) {
      errors.accname = "Account Name is required";
    }
  }
    if (!values.opening) {
      errors.opening = "Cannot Be Blank !! 0 is alloweed or null";
    }else if (!num.test(values.opening)) {
      errors.opening = 'Only Numbers are allowed';
    }
    
    return errors;

  }
  handleAccType(){
    this.setState({acctype:this.state.acctype===true?false:true})
  }

  ifscClick1(){
    this.setState({name:''})
    this.setState({branch:''})
    branch=""
    name=""
  }



  ifscClick(ifsccode){
   this.ifscClick1()
    if(ifsccode!==undefined){
      if(ifsccode!==null){
        if(ifsccode!==''){
          if(ifsccode.length===11){  
     IfscFetch.setIfsc(ifsccode)
          }else{
            this.ifscClick1()
            ifsccode=""

          }
        }
      }
    }
  
    branch=''
    name=''
      this.setState({name:bankres})
      this.setState({branch:branchres})
      branch=branchres
      name=bankres
    
  }

    render() {

      let {id, opening,accname,number,subheads} = this.state
      let subhead,sh = subheads.filter((e)=>e.sa==="AH000022")
     // name=name
      //ifsccode=ifsccode
     // branch=branch
      const data=this.state.bankdata.map(row=>row.subname==="Bank"?row.opening:row.opening)

        return (
            <div>
                <Formik
              
              initialValues={{id, opening,name,accname, ifsccode, branch,number,subhead}}
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validate={this.validate}
              enableReinitialize={true}>
              {({setFieldValue,values}) => (
                
                <Form name="bankdetails">

                  <div className="form-row">
                
  <fieldset className="form-group col-md-4 text-left ">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.acctype} onChange={this.handleAccType} name="acctype" />}/>
     <span className="text-left textColour">{this.state.acctype===false?"Bank Account":"OD Bank Account"}</span>
       </fieldset>

      {/* <div className="form-group col-md-8 text-left form-switch mt-3"> */}
  <div className="col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} variant="standard" />} autoOk={true} />
    </Grid></LocalizationProvider></div>
     :""}
    {/* </div>   */}


       {this.state.acctype===false?
       <fieldset className="form-group col-md-12 mb-4">
  <Autocomplete id="banks" className="banks-select" name="banks" value={values.banks ? undefined : undefined}
  options={this.state.masbanks} //groupBy={(option) => option.subname}
  getOptionLabel={(option) => option.accname}
  isOptionEqualToValue={(option) => !option.value }
  onChange={(e, value) => {setFieldValue("banks", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="banks" label="Select Bank" className="form-control"
  size='small' 
  onBlur={(e) => this.banksLedgers(values.banks)}
  variant="standard" fullWidth /> )} /> </fieldset>:""}

{this.state.acctype===true?
  <fieldset className="form-group col-md-12 mb-4">
  <Autocomplete id="subhead" className="sub-select" name="subhead" value={values.subhead? undefined : undefined}
  options={sh} //groupBy={(option) => option.subname}
  getOptionLabel={(option) => option.name}  
  isOptionEqualToValue={(option) => !option.value }
  onChange={(e, value) => {setFieldValue("subhead", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="subhead" label="Select Head" className="form-control"
  size='small' 
  onBlur={() => this.subHeads(values.subhead)}
  variant="standard" fullWidth /> )} /> </fieldset>:""}
 

{this.state.acctype===false ?<>
  {this.state.selectedbank!==undefined ?<>
  {this.state.selectedbank!==null?<>
<fieldset className="form-group col-md-3 mb-4 text-blue text-bold" >Account-Name</fieldset>
<fieldset className="form-group col-md-3 mb-4" >{this.state.selectedbank.accname}</fieldset>
<fieldset className="form-group col-md-3 mb-4 text-blue text-bold" >Account-Number</fieldset>
<fieldset className="form-group col-md-3 mb-4" >{this.state.selectedbank.accno}</fieldset>
<fieldset className="form-group col-md-3 mb-4 text-blue text-bold" >Bank-Name</fieldset>
<fieldset className="form-group col-md-3 mb-4" >{this.state.selectedbank.bankname}</fieldset>
<fieldset className="form-group col-md-3 mb-4 text-blue text-bold" >Branch</fieldset>
<fieldset className="form-group col-md-3 mb-4" >{this.state.selectedbank.branchname}</fieldset>
<fieldset className="form-group col-md-3 mb-4 text-blue text-bold" >IFSC</fieldset>
<fieldset className="form-group col-md-3 mb-4" >{this.state.selectedbank.ifsc}</fieldset>
</>:""}
</>:""}
</>:""}

                  {this.state.acctype===true?<>

                   

                  <fieldset className="form-group col-md-5" >
                      <FormikTextField
                        className="form-control"
                        name="ifsccode"
                        placeholder="IFSC Code"
                        variant="standard"
                      //  onBlur= {()=>this.ifscClick1() }
                         fullWidth
   
                      />
                      </fieldset>

                      <fieldset className="form-group col-md-1">
                      <button className="btn buttonColour btn-sm" type="button" onClick={()=>this.ifscClick(values.ifsccode)}>{this.state.bank!==undefined?"Clear":"Check"} </button>
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                      <FormikTextField

                        className="form-control"
                        name="name"
                        //style={{ height }}
                        placeholder="Bank Name"
                        // value={this.state.name}
                        variant="standard"
                        fullWidth
                        
                      />
                      
                     </fieldset>
               
                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="branch"
                        // value={this.state.branch}

                       // style={{ height }}
                       placeholder="Branch"
                        variant="standard"
                        fullWidth
                      />
    
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="number"
                        //style={{ height }}
                        placeholder="Account Number"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="accname"
                        //style={{ height }}
                        placeholder="Account Name"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset></>:""}

                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="opening"
                        //style={{ height }}
                        placeholder="Opening Balance"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset>
                   

                    </div>

                  <div>
                  <div className="form-row">
                    <fieldset className="form-group col-md-12">
                  <button className="btn buttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</>:"Save"}</button>
                  </fieldset>
                  {/* <fieldset className="form-group col-md-1">
                  <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/bankstransreport', state: {message:''}}}>Close</Link>
                  </fieldset> */}
                  
                  </div>

                  <div className="form-row">
                    
                  {/* <fieldset className="form-group col-md-1">
                  <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/newledgers', state: {message:''}}}>Close</Link>
                  </fieldset> */}
                  
                  </div>
                  </div>
                 
                  

                </Form>
              )}


            </Formik>

            <div>
<table 
       id="example1"
       className="table table-bordered table-sm text-sm ">
      
       <thead >
         <tr>
         <th className="form-group col-md-3">Account Name</th>
         <th className="form-group col-md-3">Date</th>
         <th className="form-group col-md-3">Bank</th>
         <th className="form-group col-md-3">Branch</th>
         <th className="form-group col-md-2">Account Number</th>
         <th className="form-group col-md-2">Head</th>
         <th className="form-group col-md-2">Type</th>
         <th className="form-group col-md-2">Opening Balance</th>
         <th className="form-group col-md-1">Action</th>

         {/* <th>Action</th> */}
         </tr>
       </thead>
       
       <tbody>
         
         {
           this.state.bankdata.map(
             result => 
             <tr key={result.id}>
               <td className="form-group col-md-3 text-blue">{result.ledgercode!==null?result.accname+" (" + result.ledgercode + ")":result.accname}</td>
               <td className="form-group col-md-3 text-blue">{moment(result.createdAt).format("DD-MM-YYYY hh:mm")}</td>
               <td className="form-group col-md-3 text-blue">{result.bank}</td>
               <td className="form-group col-md-3 text-blue">{result.branch}</td>
               {/* {result.th.map((id,name)=><td key={id}>{id.name})</td>)} */}
               <td className="form-group col-md-2 text-blue">{result.accnumber}</td>
               <td className="form-group col-md-2 text-blue">{result.subname}</td>
               <td className="form-group col-md-2 text-blue">{result.type}</td>
               <td className="form-group col-md-2 text-right">
                {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} value = {result.subname==="Bank"?result.opening:result.opening} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>}</td>
               <td><span  className="form-group col-md-1 fa fa-trash text-danger " onClick={()=>this.deleteLedger(result)}></span></td>

               {/* <td >{users.roles.map((id, name) => <li key={id}>{name}</li>)}</td> */}
               {/* {tenants.city.map((id, name) => <td key={id}>{id.name}</td>)} */}

               {/* {result.createdBy===0?
               <td className="text-orange">Default Account</td>:
               <td><Link as="button" data-tip data-for="edit" className="fa fa-edit" to={{pathname: '/newsubacc' ,state: {id: result.id,pagename:'Update SubAcc'}}}></Link> | 
                <li data-tip data-for="delete" className="fa fa-trash text-danger" onClick={() => { if (window.confirm('Are you sure you wish to delete SubAccount.? This will delete all attached ledgers. Are you sure?')) this.deleteSubAcc(result.id)}}></li>
                //<Tooltip id="edit" place="top" effect="solid">Edit</Tooltip>
               //<Tooltip id="delete" place="top" effect="solid">Delete</Tooltip></td>} */}
   
               {/* <td><button className="btn btn-success" onClick={() => this.updateUserClicked(users.id)}>Update</button></td> */}
               {/* <td><button className="btn btn-sm btn-danger" onClick={() => this.deleteUser(users.id)}>Delete</button></td> */}
             </tr>
             
           )
         }
              <tr>
                <td></td>                
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              <td className="text-right text-bold">Net Balance</td>
              <td className="text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {getTotal(data)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>}</td>
            </tr>
       </tbody>

     
                                         
     </table> 

     {/* <fieldset className="form-group col-md-1">
                  <Link as="button" type="button" className="btn btn-success" to={{pathname: '/bankstransreport'}} state={{message:''}}>Bank Report</Link>
                  </fieldset>  */}
     </div>
            
                
            </div>
        )
    }
}
