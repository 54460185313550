import { Autocomplete } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import {userid, currentTenant, prefix1, thousandsdisplay, currency, ccountry, userwhid, dataaccess, financialyearend, financialyearstart } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import GRNServices from "./GRNServices";
import PurchaseServices  from '../../Purchase/PurchseServices';
import FileServices from "../../Common/upload/FileServices.js";
import { Button,Typography, Table, TableCell, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import UploadFilesService from "../../Common/upload/UploadFilesService.js";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner'
import { TableHead,TextField } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableContainer } from "@mui/material";
import {NumericFormat} from "react-number-format";
import AgentServices from "../../Masters/Agents/AgentServices.js";
import SettingsServices from "../../Settings/SettingsServices.js";
import PurchseServices from "../../Purchase/PurchseServices";
import Swal from "sweetalert2";

let datalist = [];
// let data2 = [];
let count = 0;

let curmonth,year,day,path
let doctype = "doinvoive"

class NewGRN extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    this.state = {
      grnid: id,
      pagename:this.props.location.state.pagename,
      itemid: "",
      itemname: "",
      hsn: "",
      whid:'',
      categoryid:'',
      uom:'',
      cost: "",
      tax: "",
      taxamount: "",
      quantity: "",
      totalpricewtax:0,
      agent:'',
      agentid:'',
      agentphno:"",
      orderedquantity: "",
      pendingquantity:"",
      status: "0",
      purchaseorderid: "",
      podata:[],
      sellers: [],
      sellerid: "",
      seller: "",
      grandtotal: "",
      poid: [],
      purchaseorder: {},
      purchaseitem: [],
      sellerdata: {},
      description: "",
      accepteditems: [],
      serviceList:[],
      returnquantity:'',
      pending:'',
      prstatus:'0',
      show:0,
      addservice:false,
      indentid:'',
      poitemstatus: 0,
      poitemtype: 0,
      assettype:0,
      // invuploadm:'',
      invoiceid:'',
      invoiceamt:'',
      selectedFiles: undefined,
      currentFile: undefined,
       progress: 0,
       message: "",
       isError: false,
       fileInfos: [],
       fileInfo:"",
       partyid:'',   
       error: false, //<---- here
       errorMessage: {}, //<-----here
       loader:false,
       invupload:false,
       vehicleno:'',
       weightedquantity:'',
       adjustfromdifpo:false,
       mainpo:"",
       settings:"",
       autojre:false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.selectFile=this.selectFile.bind(this);
    this.upload=this.upload.bind(this);
  }


  selectFile=event=> {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];
   

    this.setState({currentFile: currentFile,});

    UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
      // var percent = Math.floor(event.percent);
      // if (percent >= 100) {
      //   this.setState({ progress: 100 });
      // } else {
      //   this.setState({ progress: percent });
      // }


      this.setState({progress: Math.round((100 * event.loaded) / event.total)});
      
    })
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {this.setState({fileInfos: files.data,});
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true
        });
      });

    this.setState({selectedFiles: undefined,});
  }


  handleSeller = (value,values) => {
   
    if(values!==null && typeof values === 'object'  && values!==undefined){
    this.setState({sellerdata:values, sellerid: values.id, seller: values.companyname,partyid:values.id, purchaseorderid: "",accepteditems:[], serviceList:[],  });
    
    const id = values.id;
    count = 0;
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid:userid,
      dataaccess: dataaccess,
      whid:userwhid,
      id:id,
      
    }

    GRNServices.getpoofsellerforgrn(datadao).then((res) =>{
    this.setState({
      podata: res.data,
      poid: res.data.map(e=>e.custompoid),
    })
    if(this.props.location.state.orderid !== undefined && this.props.location.state.orderid !== null){
        this.handlePurchaseorder(null,this.props.location.state.orderid)
    }

    })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

  //   GRNServices.getPorderofSellerid(datadao).then((res) =>
  //     this.setState({
  //       poid: res.data,
  //     })
  //   )
  //   .catch((e)=>{
  //     if(e.response.data.error ==="Unauthorized"){
  //         alert("Your Session is Lost: Please login Back to resume");
  //         this.props.navigate(`/`);
  //     }
  // })
    }else{
      this.setState({purchaseorderid: "",accepteditems:[], serviceList:[]})
    }
  };

  handlePurchaseorder = (value,values) => {
    if(values !==null && values !== undefined && values !=='' && this.state.poid.includes(values) ){

      console.log(this.state.podata.find(p => p.custompoid === values))

      if(this.state.podata.find(p => p.custompoid === values).advancepaymentreq  === "True" && this.state.podata.find(p => p.custompoid === values).advancepaymentledgerstatus === "Pending"){
        Swal.fire({
          title: "",
          text: "You have requested advance payment for this Order",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Wait until advance payment",
          confirmButtonColor: "#152f5f",
          confirmButtonText: "Cancel advance payment and continue",
          reverseButtons: true,
        }).then((result) => {
          console.log(result);
          if (result.value) {
          this.setState({ purchaseorderid: values, mainpo:this.state.mainpo===""||this.state.mainpo==null||this.state.mainpo === undefined || this.state.accepteditems.length===0 || this.state.accepteditems.filter(e=>e.poid === this.state.mainpo).length===0 ?values:this.state.mainpo});
          const id = values;
          count = 0;
      
          let po = this.state.podata.find(p => p.custompoid === id);
          this.setState({
                  purchaseorder: po,
                  purchaseitem: po.purchaseitem,
                  whid:po.whid,
                  serviceList:po.service,
                  indentid:po.indentid,
                  agentid:po.agent,
                  quantity: "", description: "",weightedquantity:"" ,show:0
                })
                if(this.state.agentid !== null && this.state.agentid !=='' && this.state.agentid!==undefined){
                        AgentServices.getAgentById(this.state.agentid).then(res=>{
                          this.setState({agent:res.data.name,agentphno:res.data.contactnumber})
                        })
                      }
                    }
        })

      }
      // else if( this.state.podata.find(p => p.custompoid === values).advancepaymentreq === "True" && 
      //           this.state.podata.find(p => p.custompoid === values).advansepaystatus === "Completed" &&
      //           this.state.podata.find(p => p.custompoid === values).advansepaystatus !== "Paid"){
      //   Swal.fire({
      //     title: "",
      //     text: "You have pending advance payment entry in accouts for this Order",
      //     icon: "Warning",
      //     confirmButtonText: "Ok",
      //     confirmButtonColor: "#152f5f",
      //   })
      // }
      else if((this.state.mainpo!=="" && this.state.mainpo!==null && this.state.mainpo !== undefined) && this.state.podata.find(p => p.custompoid === values).openadjusted !== null){
        Swal.fire({
          title: "",
          text: "This order has amount adjustment can't be mergerd into another order",
          icon: "warning",
          confirmButtonColor: "#152f5f",
          confirmButtonText: "Ok",
        })
      }else{
        this.setState({ purchaseorderid: values, mainpo:this.state.mainpo===""||this.state.mainpo===null||this.state.mainpo === undefined || this.state.accepteditems.length===0 || this.state.accepteditems.filter(e=>e.poid === this.state.mainpo).length===0 ?values:this.state.mainpo});
        const id = values;
        count = 0;
    
        let po = this.state.podata.find(p => p.custompoid === id);
        this.setState({
                purchaseorder: po,
                purchaseitem: po.purchaseitem,
                whid:po.whid,
                serviceList:po.service,
                indentid:po.indentid,
                agentid:po.agent,
                quantity: "", description: "",weightedquantity:"" ,show:0,
                po
              })
              if(this.state.agentid !== null && this.state.agentid !=='' && this.state.agentid!==undefined){
                      AgentServices.getAgentById(this.state.agentid).then(res=>{
                        this.setState({agent:res.data.name,agentphno:res.data.contactnumber})
                      })
                    }
      }

  //   GRNServices.getpendingDataofPOid(id).then((response) =>
  //     this.setState({
  //       purchaseorder: response.data,
  //       purchaseitem: response.data.purchaseitem,
  //       whid:response.data.whid,
  //       serviceList:response.data.service,
  //       // sellerdata: response.data.sellers,
  //       indentid:response.data.indentid,
  //       agentid:response.data.agent,
  //     },()=>{
  //       if(this.state.agentid !== null && this.state.agentid !=='' && this.state.agentid!==undefined){
  //       AgentServices.getAgentById(this.state.agentid).then(res=>{
  //         this.setState({agent:res.data.name,agentphno:res.data.contactnumber})
  //       })
  //     }
  //     })
  //   )
  //   .catch((e)=>{
  //     if(e.response.data.error ==="Unauthorized"){
  //         alert("Your Session is Lost: Please login Back to resume");
  //         this.props.navigate(`/`);
  //     }
  // })
  }else{
    this.setState({ purchaseorderid: '', quantity: "", description: "",weightedquantity:"" ,show:0,purchaseitem:[],mainpo:this.state.accepteditems.length===0 || this.state.accepteditems.filter(e=>e.poid === this.state.mainpo).length===0 ?"":this.state.mainpo});
  }
  };

  removeItemFromList(id) {
    const deleteitem = datalist.filter((item,index)=>item.iid === id);
    // deleteitem.map(data=>this.setState({pendingquantity:this.state.pendingquantity + parseInt(data.quantity)}))
    let delquantity = deleteitem.map(data=>data.quantity)
    const newlist = datalist.filter((item, index) => item.iid !== id);
    
    this.setState({
      accepteditems: newlist, invoiceamt: newlist.reduce((sum, item) => sum = sum + item.totalpricewtax,0).toFixed(2)
    });
    datalist = newlist;
    // let itemsdetails = this.state.purchaseitem.find((data) => data.id === id);
    //   let deletedata={
    //     id: itemsdetails.id,
    //     itemid:itemsdetails.itemid,
    //     name: itemsdetails.name,
    //     hsn: itemsdetails.hsn,
    //     price: itemsdetails.price,
    //     uom: itemsdetails.uom,
    //     categoryid: itemsdetails.categoryid,
    //     tax: itemsdetails.tax,
    //     taxamount:itemsdetails.taxamount,
    //     quantity: itemsdetails.quantity,
    //     pending: itemsdetails.pending + parseInt(delquantity),
    //     totalprice: itemsdetails.totalprice,
    //     totalpricewtax:itemsdetails.totalpricewtax
    //           }
    //        let remlist=this.state.purchaseitem.filter((data) => data.id !==id)
    //           remlist.push(deletedata)
    console.log(this.state.mainpo,deleteitem[0].poid)
              this.setState({purchaseitem:[],purchaseorderid:"",quantity: "", description: "",weightedquantity:"" ,show:0,
              mainpo:this.state.mainpo===deleteitem[0].poid?"":this.state.mainpo,
            })
        //  let allpodata =  this.state.podata.filter((data) => data.custompoid !== this.state.purchaseorderid)
        //  allpodata.push(this.stat)
  }

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleAccept = (id) => {
    let itemsdetails = this.state.purchaseitem.filter((data) => data.id === id);
    this.setState({ quantity: "", description: "",weightedquantity:"" ,show:1 });
    itemsdetails.map((data) =>
      this.setState(
        {
          id: data.id,
          itemid: data.itemid,
          itemname: data.name,
          hsn: data.hsn,
          cost: data.price,
          tax: data.tax,
          categoryid:data.categoryid,
          taxamount: data.taxamount,
          orderedquantity:data.quantity,
          pendingquantity: data.pending,
          uom:data.uom,
          totalprice:data.totalprice,
          totalpricewtax:data.totalpricewtax,
          poitemstatus: data.poitemstatus,
          poitemtype: data.poitemtype,
          assettype:data.assettype,
        })
    );
  };

  handelSubmit = () => {
    
    let filedata= this.state.fileInfos.map((e)=>e.name).toLocaleString()
    path=prefix1+"/"+doctype+"/"+this.state.partyid+"/"+year+"/"+curmonth+"/"+day
    if(this.state.invupload && filedata === '' && this.state.grnid === -1){
      this.setState({message:"Please Upload Delivery note",isError:true})
    }else if(this.state.invoiceid === '' || this.state.invoiceid === undefined){
      this.setState({message:"Please enter invoice id",isError:true})
    }else if(this.state.invoiceamt === '' || this.state.invoiceamt === undefined){
      this.setState({message:"Please enter invoice amount",isError:true})
    }else if(this.state.mainpo === '' || this.state.mainpo === undefined || this.state.mainpo === null || this.state.accepteditems.filter(e=>e.poid===this.state.mainpo).length===0) {
      Swal.fire({
        title:"",
        text:this.state.accepteditems.filter(e=>e.poid===this.state.mainpo).length===0?"Please added atleast one item from main Order in order to adjust":"Please select Purchase order",
        icon:"error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })
    }else{
    this.setState({loader:true,isError:false})
    let submitdata ={
      id:this.state.grnid,
      purchaseorderid: this.state.mainpo,
      sellerid: this.state.sellerid,
      seller: this.state.seller,
      tenantid: currentTenant,
      poItemReturnDAO:datalist,
      whid: this.state.whid,
      path:path,
      file:filedata,
      addservice:this.state.addservice,
      createdby:userid,
      indentid:this.state.indentid,
      invoiceamount:this.state.invoiceamt,
      invoiceid:this.state.invoiceid,
      poitemlist:this.state.purchaseitem,
      autojre:this.state.autojre,
    }
    
    if(this.state.grnid===-1){
    GRNServices.NewGRN(submitdata).then((response) => {
    this.setState({loader:false})
    this.props.navigate(`/viewgrnitems`,{state:{id:response.data,temp:true,goback:false}})
    }
    )
    .catch((e)=>{
      this.setState({loader:false})
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
}else{
  GRNServices.UpdateGRN(submitdata).then((response) => {
    this.setState({loader:false})
    this.props.navigate(`/viewgrnitems`,{state:{id:response?.data,temp:true,goback:false}})
    }
    )
    .catch((e)=>{
      this.setState({loader:false})
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
}
  };
  }

  onSubmit() {
    // window.confirm("Are you sure you wish to delete this item?")
    if (this.state.quantity === "0" || this.state.quantity === 0) {
    } else {
      count = count + 1;
      let da = []
      let halftaxamountoff =  ((parseFloat(this.state.quantity)*this.state.cost)*((this.state.tax/2)/100)).toFixed(2)
      console.log(halftaxamountoff)
      let taxamountroundoff = this.state.purchaseorder.taxtype === "GST" && ccountry ==="India" && this.state.purchaseorder.gsttype === "INTRASTATE" ? parseFloat(halftaxamountoff) + parseFloat(halftaxamountoff) : ((parseFloat(this.state.quantity)*this.state.cost)*(this.state.tax/100))

      
      if(datalist.length >0 && datalist.filter(e=>e.iid === this.state.id).length >0){
        da = datalist.filter(e=>e.iid === this.state.id)
        if(da.length > 0){
         da = da.find(e=>e)
          da.quantity = da.quantity + parseFloat(this.state.quantity)
          da.taxamount = da.taxamount + parseFloat(taxamountroundoff)
          da.totalprice =da.totalprice + parseFloat(this.state.quantity) * this.state.cost
          da.totalpricewtax = da.totalpricewtax + (parseFloat(this.state.quantity) * this.state.cost) + parseFloat(taxamountroundoff)
          da.pending = da.pending +  this.state.prstatus ==='0'? this.state.pendingquantity - this.state.quantity : 0
        }
        datalist = datalist.filter(e=>e.iid !== this.state.id)
      datalist.push(da)
      }else{
      let list = {
        iid: this.state.id,
        poid: this.state.purchaseorderid,
        itemid: this.state.itemid,
        name: this.state.itemname,
        hsn: this.state.hsn,
        categoryid:this.state.categoryid,
        quantity: parseFloat(this.state.quantity),
        price: this.state.cost,
        tax: this.state.tax,
        weightedquantity:this.state.weightedquantity,
        taxamount: parseFloat(taxamountroundoff),
        acceptreturn: parseFloat(this.state.status),
        description: this.state.description,
        totalprice:(parseFloat(this.state.quantity) * this.state.cost),
        totalpricewtax: (parseFloat(this.state.quantity) * this.state.cost) + parseFloat(taxamountroundoff),
        pending:this.state.prstatus ==='0'? this.state.pendingquantity - this.state.quantity : 0,
        returnquantity:this.state.prstatus ==='1'? this.state.pendingquantity:0, 
        poitemstatus: this.state.poitemstatus,
        poitemtype: this.state.poitemtype,
        assettype:this.state.assettype,
      };

      datalist.push(list);

      let checklist  = datalist.filter(e=>e.categoryid !== this.state.categoryid)
            if(checklist.length>0 && this.state.autojre){
              Swal.fire({
                title:'',
                text: "As different ledger are added journal entry cannot be auto inserted, please do the manual entry.",
                icon:"warning",
                confirmButtonColor: "#152f5f",
              }).then(()=>this.setState({autojre:false})
              )
            }else{
              datalist.length === 1?this.setState({autojre:this.state.settings.salesautojre==="YES"?true:false}):""
            }
    }
      
      let data = { 
        id: this.state.id,
        itemid: this.state.itemid,
        name: this.state.itemname,
        hsn: this.state.hsn,
        categoryid:this.state.categoryid,
        price: this.state.cost,
        uom: this.state.uom,
        tax: this.state.tax,
        taxamount:((parseFloat(this.state.orderedquantity)*this.state.cost)*(this.state.tax/100)),
        quantity: this.state.orderedquantity,
        pending: this.state.pendingquantity - this.state.quantity,
        totalprice: this.state.totalprice,
        totalpricewtax:this.state.totalpricewtax,
        poitemstatus: this.state.poitemstatus,
        poitemtype: this.state.poitemtype,
        assettype:this.state.assettype,
      }
      if(this.state.pendingquantity=== 0){
        this.setState({purchaseitem:this.state.purchaseitem.filter((e) => e.id !== this.state.id)})
      }else{
      this.setState({purchaseitem:this.state.purchaseitem.filter((e) => e.id !== this.state.id)},
      ()=>this.state.purchaseitem.push(data)
    
   );
    }
    this.setState({ accepteditems: datalist, quantity: "", description: "",show:0, invoiceamt: datalist.reduce((sum, item) => sum = sum + item.totalpricewtax,0).toFixed(2)});
    }
  }

  refreshData() {
    console.log(this.props.location.state)

    PurchaseServices.POpendingsuppliers(currentTenant).then((res) =>{
      this.setState({
        sellers: res.data,
      });
      if(this.props.location.state.orderid !== undefined && this.props.location.state.orderid !== null){
          this.handleSeller("",res.data.find(e=>e.id === this.props.location.state.supplier))
      }
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  //   PurchaseServices.GetAllSellers(currentTenant).then((res) =>{
  //     this.setState({
  //       sellers: res.data,
  //     });
  //     if(this.props.location.state.orderid !== undefined && this.props.location.state.orderid !== null){
  //         this.handleSeller("",res.data.find(e=>e.id === this.props.location.state.supplier))
  //     }
  //   })
  //   .catch((e)=>{
  //     if(e.response.data.error ==="Unauthorized"){
  //         alert("Your Session is Lost: Please login Back to resume");
  //         this.props.navigate(`/`);
  //     }
  // })
  // SettingsServices.GetSettings(currentTenant).then((response) => this.setState({invuploadm: response.data.invuploadm}))

  if(this.props.location.state.id !== -1){
    GRNServices.GetTempGRNbyId(this.props.location.state.id).then((res) =>{
    this.setState({
      accepteditems:res.data.grnitems,
      invoiceid:res.data.invoiceid,
      invoiceamt:res.data.invoiceamount
    })
    datalist = res.data.grnitems
  })
  GRNServices.getpendingDataofPObyGrnid(this.props.location.state.id).then((response) =>
    this.setState({
      purchaseorder:response.data,
      mainpo:response.data.custompoid,
      seller:response.data.supplier,
      sellerid:response.data.supplierid,
      purchaseitem: response.data.purchaseitem,
      whid:response.data.whid,
      serviceList:response.data.service,
    }))
  }


  SettingsServices.GetSettings(currentTenant).then((response) => 
    this.setState({settings: response.data,autojre:response.data.salesautojre==="YES"?true:false})
  )
  }
  componentDidMount() {
    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.refreshData();
    datalist = [];
    count = 0;

    year = new Date().getFullYear()
    day= new Date().getDate()
    let mn = new Date().getMonth()+1
    curmonth= moment(mn,'M').format('MMMM')
  }

  validate(values) {

   let errors = {}
    let isError = false;


    if(values.seller===undefined || values.seller.length ===0){
      isError = true;
      this.setState({
        error: true,
        errorMessage: { seller: "Please Select Suplier" }
      });} else
      if(isError!==true){
      //add else if for validating other fields (if any)
      this.setState(prevState => ({
        activeStep: prevState.activeStep + 1,
        error: false,
        errorMessage: {}
      }));
    
    }

    if(this.state.purchaseorderid ===''){
      errors.purchaseorder = "Please Select Purchase Order"
    }
    if (this.state.quantity === '' || this.state.quantity === undefined || this.state.quantity ==='0' || parseFloat(this.state.quantity) <=0) {
      errors.quantity = "Quantity is required"
    } 
    if (this.state.weightedquantity === '' || this.state.weightedquantity === undefined || this.state.weightedquantity ==='0' || parseFloat(this.state.weightedquantity) <= 0) {
      errors.weightedquantity = "Weighted quantity is required"
    } else if(parseFloat(this.state.weightedquantity) > (parseFloat(this.state.quantity) + parseFloat(this.state.quantity) * 0.1)){
      errors.weightedquantity = "Cannot be greater +10% of Quantity" 
    }else if(parseFloat(this.state.weightedquantity) < (parseFloat(this.state.quantity) - parseFloat(this.state.quantity) * 0.1)){
      errors.weightedquantity = "Cannot be less -10% of Quantity" 
    }
    if (this.state.quantity > this.state.pendingquantity) {
      errors.quantity = `Avilable quantity is ${this.state.pendingquantity}`
    }
    if ( this.state.pendingquantity-this.state.quantity !== 0 && this.state.description === '') {
      errors.description = "Pelese provide description"
    }
    return errors;
  }


  render() {
    let {
      sellers,
      poid,
      purchaseorderid,
      sellerid,
      seller,
      itemid,
      itemname,
      quantity,
      pendingquantity,
      status,
      description,
      prstatus,
      show,
      selectedFiles,
      message,
      isError,
      vehicleno,
      weightedquantity
    } = this.state;

    return (
      <div>
      <Header/>
        <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/grn" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/grn">GRN </Link>
              </li>
              <li className="breadcrumb-item active">Accept Goods/GRN</li>
            </ol>
            <div className="card-body">
              <Formik
                initialValues={{
                  sellers,
                  poid,
                  purchaseorderid,
                  sellerid,
                  seller,
                  itemid,
                  itemname,
                  quantity,
                  status,
                  pendingquantity,
                  description,
                  prstatus,
                  show,
                  vehicleno,
                  weightedquantity
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form>
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                      {this.state.grnid === -1 ?<>
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="seller"
                            options={sellers}
                            getOptionLabel={(option) => option.companyname}
                            value={values.seller?undefined:undefined}
                            onChange={this.handleSeller}
                            renderInput={params => (  
                              <TextField
                                  {...params}
                                className="form-control"
                                type="text"
                                name="seller"
                                value={this.state.seller}
                                label={this.state.seller ===null || this.state.seller === ""?"Select Supplier":this.state.seller}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                          <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name=""
                            options={poid}
                            getOptionLabel={(option) => option}
                            value={values.purchaseorder?undefined:undefined}
                            onChange={this.handlePurchaseorder}
                            disabled={this.state.accepteditems.filter(e=>e.poid === this.state.mainpo).length>0}
                            renderInput={params => (  
                              <FormikTextField
                                  {...params}
                                className="form-control"
                                type="text"
                                name="purchaseorder"
                                value={this.state.mainpo}
                                label={`Select Purchaseorder (${this.state.mainpo})`}
                                variant="standard"
                                fullWidth
                                disabled={this.state.accepteditems.filter(e=>e.poid === this.state.mainpo).length>0}
                              />
                            )}
                          />
                        </fieldset>
                        </>
                        :<>
                        <fieldset className="form-group col-md-3" disabled>
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="seller"
                            value={this.state.seller}
                            label="Supplier"
                            variant="standard"
                            fullWidth
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-3" disabled>
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="purchseorderid"
                            value={this.state.mainpo}
                            label="Purchse Order Id"
                            variant="standard"
                            fullWidth
                          />
                        </fieldset>
                        </>

                      }
                      
                      {/* {this.state.seller ===''?'': */}
                      
                        {this.state.purchaseorderid === "" || this.state.indentid === null || this.state.indentid === '' || this.state.indentid === undefined?'':
                              <fieldset className="form-group col-md-3" disabled>
                              <TextField
                                className="form-control"
                                type="text"
                                name="indentid"
                                value={this.state.indentid}
                                label="Indent Id"
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>
                            }
                       {/* } */}

                         
                       {this.state.accepteditems.length !== 0?<>
                        <fieldset className="form-group col-md-12 m-0"></fieldset>   
                       <fieldset className="form-group col-md-3 m-0">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.adjustfromdifpo}
                                        onChange={()=>this.setState({adjustfromdifpo:!this.state.adjustfromdifpo})}
                                        name="ordertype"
                                        color="primary"
                                        disabled={this.state.orderfrom === 1}
                                      />
                                    }
                                    label="Adjust from previous Purchase order?"
                                  />
                                </FormGroup>
                              </fieldset>  
                              {this.state.adjustfromdifpo === true?
                                <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name=""
                                  options={poid}
                                  getOptionLabel={(option) => option}
                                  value={values.purchaseorder?undefined:undefined}
                                  onChange={this.handlePurchaseorder}
                                  renderInput={params => (  
                                    <FormikTextField
                                        {...params}
                                      className="form-control"
                                      type="text"
                                      name="poidforadjustment"
                                      value={this.state.purchaseorderid}
                                      label="Select Purchaseorder to adjust"
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                              :""}
                              </>
                        :""}

                      {/* <div className="form-group col-md-12" /> */}
                      {this.state.purchaseorderid === ""?'':<>
                        {/* <div className="form col"> */}
                          {/* <p className="form-group col-md-12 bg-light">Seller Details : </p> */}
                          {/* {this.state.id === -1?
                          <table className="float-left table-sm m-0">
                            <tr><u>Supplier Details</u></tr>
                            <tr>
                              <td>Supplier</td>
                              <td data-tip data-for="supphno">:&nbsp;&nbsp;{this.state.sellerdata.companyname}</td>
                            </tr>
                            {this.state.agentid===null||this.state.agentid=== undefined || this.state.agentid=== ''?'':
                          <tr>
                            <td>Agent</td>
                            <td data-tip data-for="agentphno">:&nbsp;&nbsp;{this.state.agent}</td>
                          </tr>
                          }
                            <tr>
                              <td>Address</td>
                              <td>:&nbsp;{this.state.sellerdata.address2}</td>
                            </tr>
                          </table>:''} */}
                          {/* <fieldset className="form-group col-md-12"/> */}
                          
                          {this.state.purchaseitem.length === 0 || this.state.purchaseitem.filter(f=>!this.state.accepteditems.map(e=>e.iid).includes(f.id)).length === 0 ?'':
                          <TableContainer >
                            <Typography>PO Items</Typography>
                          <Table aria-label="customized table" className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell  className="text-center">Item Name</TableCell>
                                <TableCell  className="text-center">HSN</TableCell>
                                <TableCell  className="text-center">Price</TableCell>
                                <TableCell  className="text-center">UOM</TableCell>
                                <TableCell  className="text-center">Quantity</TableCell>
                                <TableCell  className="text-center">Pending</TableCell>
                                {this.state.purchaseorder.taxrequired === "TRUE"?<>
                                    {this.state.purchaseorder.taxtype === "GST" && ccountry ==="India" && this.state.purchaseorder.importpo === false  ? this.state.purchaseorder.gsttype === "INTRASTATE" ?
                                      (<><TableCell className="text-center">CGST</TableCell><TableCell className="text-center">SGST</TableCell>
                                         {this.state.purchaseorder.currency === currency?'':<> <TableCell className="text-center">CGST({this.state.purchaseorder.currency})</TableCell><TableCell className="text-center">SGST({this.state.purchaseorder.currency})</TableCell></>}
                                      </>) 
                                      : (<><TableCell className="text-center">IGST</TableCell>
                                       {this.state.purchaseorder.currency === currency?'':<TableCell className="text-center">IGST({this.state.purchaseorder.currency})</TableCell>}</>) :
                                      this.state.purchaseorder.taxtype === "GST" ?<><TableCell className="text-center">GST</TableCell>
                                       {this.state.purchaseorder.currency === currency?'':<TableCell className="text-center">GST({this.state.purchaseorder.currency})</TableCell>}</>
                                      :<> <TableCell className="text-center">VAT</TableCell>
                                       {this.state.purchaseorder.currency === currency?'':<TableCell className="text-center">VAT({this.state.purchaseorder.currency})</TableCell>}</>}
                                    </>:''}
                                <TableCell  className="text-center">Total Price</TableCell>
                                <TableCell  className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.purchaseitem.map((data, index) => (
                                this.state.accepteditems.map(e=>e.iid).includes(data.id)?"":
                                <TableRow key={index}>
                                  <TableCell  className="text-center">{data.name}</TableCell>
                                  <TableCell  className="text-center">{data.hsn}</TableCell>
                                  <TableCell  className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></TableCell>
                                  <TableCell  className="text-center">{data.uom}</TableCell>
                                  <TableCell  className="text-center">{data.quantity}</TableCell>
                                  <TableCell  className="text-center">{data.pending}</TableCell>
                                  {this.state.purchaseorder.taxrequired === "TRUE"?<>
                                      {this.state.purchaseorder.taxtype === "GST" && ccountry === "India" && this.state.purchaseorder.importpo === false  &&  this.state.purchaseorder.gsttype === "INTRASTATE"  ?
                                        (<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         {this.state.purchaseorder.currency === currency?'':<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(data.taxamount/2)* this.state.purchaseorder.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(data.taxamount/2)* this.state.purchaseorder.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         </>}
                                        </>) : (<>
                                          <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax)}%)</TableCell>
                                          {this.state.purchaseorder.currency === currency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount*this.state.purchaseorder.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax)}%)</TableCell>}
                                       </> )
                                        }
                                      </>:''}
                                  <TableCell  className="text-right">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.totalpricewtax} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                    </TableCell>
                                  <TableCell  className="text-center">
                                    {/* {this.state.accepteditems.map((data)=>data.iid).includes(data.id)?'': */}
                                    {data.pending <=0?'':
                                    <span
                                      className="text-info cursorPointer"
                                      style={{ paddingLeft: "7px" }}
                                      onClick={() => this.handleAccept(data.id)}
                                    >
                                     Accept item
                                    </span>
                                  }
                                  </TableCell>
                                </TableRow>
                              ))}

                            </TableBody>
                          </Table>
                          </TableContainer>
                          }

                          {this.state.serviceList.length === 0 ?""
                         : 
                          <>
                           <FormGroup row className="mt-3">
                            <FormControlLabel
                              control={<Checkbox
                                checked={this.state.addservice}
                                onChange={(e)=>this.setState({addservice:e.target.checked})}
                                name="parameters" 
                                color="primary"
                              />}
                              label="Include Services"
                            />
                            </FormGroup>
                            {this.state.addservice === true? 
                              <TableContainer>
                                <Typography>Services</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell className="text-center">Sl. No.</TableCell>
                                      <TableCell className="text-center">Service</TableCell>
                                      <TableCell className="text-center">HSN/SAC Code</TableCell>
                                      <TableCell className="text-center">Price</TableCell>
                                      <TableCell className="text-center">Tax (%)</TableCell>
                                      <TableCell className="text-center">TotalPrice</TableCell>
                                      {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <TableCell className="text-center">Recurring Type</TableCell>:''}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className="text-sm">
                                    {this.state.serviceList.map((data, index) => (
                                      <TableRow key={index} className="text-center">
                                        <TableCell className="text-center">{index + 1}</TableCell>
                                        <TableCell className="text-center">{data.service}</TableCell>
                                        <TableCell className="text-center">{data.servicecode}</TableCell>
                                        <TableCell className="text-right"> <NumericFormat className="pr-3" displayType={'text'} value= {data.servicecost} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                        <TableCell className="text-center">{data.servicetax} %</TableCell>
                                        <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.servicetotalwtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                        {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <TableCell className="text-center">{this.state.serviceList.map(e=>e.recurringtype)}</TableCell>:''}
                                      </TableRow>
                                    ))}
                                    <TableRow>
                                      <TableCell />
                                      <TableCell />
                                      <TableCell />
                                      <TableCell />
                                      <TableCell className="text-center" >Total</TableCell>
                                      <TableCell className="text-right">
                                      <NumericFormat className="pr-3" displayType={'text'} value= {this.state.purchaseorder.grandservicetotal} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                      </TableCell>
                                      {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <TableCell></TableCell>:''}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer> :''
                            }
                          </>
                        }
                      </>}
                      <div className="form-group col-md-12" />
                      {this.state.show === 0?"":<>

                        <div className="form-group col-md-12">Generate GRN </div>

                        <fieldset className="form-group col-md-3">
                          <span className="text-dark">Item Name</span>
                          <> : &nbsp; {this.state.itemname}</>
                          
                        </fieldset>
                        <fieldset className="form-group col-md-3"><span>{`Remaining Quantity : ${this.state.pendingquantity-this.state.quantity}`} </span></fieldset>

                        <fieldset className="form-group col-md-6"/>
                        

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="quantity"
                            label="Invoice Quantity"
                            onChange={(e) => this.setState({ quantity: e.target.value })}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="weightedquantity"
                            label="Weighted/Actual Quantity"
                            onChange={(e) => this.setState({ weightedquantity: e.target.value })}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="description"
                            label="Description"
                            onChange={(e) =>this.setState({ description: e.target.value })}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-12 mt-2">
                          <label className="text-red">Note : Weighted Quantity will be added to inventory.
                            </label>
                </fieldset>
                        <div className="form-group col-md-12"/>
                        <button className="btn btn-sm hovbuttonColour" type = "submit">
                          Accept Item
                        </button>
                      </>}

                      <div className="form-group col-md-3" />
                      {this.state.accepteditems.length === 0?'': <>
                        <div style={{ paddingTop: "25px" }} className="form-group col-md-12">
                          {this.state.accepteditems.length === 0?'':<>
                          <span className="form-group col-md-12 ">Accepted Goods/GRN :</span>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                              <TableCell className="text-center">Oorder Id</TableCell>
                                <TableCell className="text-center">Item Name</TableCell>
                                <TableCell  className="text-center">HSN</TableCell>
                                <TableCell  className="text-center">Price</TableCell>
                                <TableCell  className="text-center">Quantity</TableCell>
                                <TableCell  className="text-center">Weighted/Received Quantity</TableCell>
                                <TableCell  className="text-center">Difference Quantity</TableCell>
                                <TableCell  className="text-center">Total Price</TableCell>
                                {this.state.purchaseorder.taxrequired === "TRUE"?<>
                                    {this.state.purchaseorder.taxtype === "GST" && ccountry ==="India" && this.state.purchaseorder.importpo === false  ? this.state.purchaseorder.gsttype === "INTRASTATE" ?
                                      (<><TableCell className="text-center">CGST</TableCell><TableCell className="text-center">SGST</TableCell>
                                         {this.state.purchaseorder.currency === currency?'':<> <TableCell className="text-center">CGST({this.state.purchaseorder.currency})</TableCell><TableCell className="text-center">SGST({this.state.purchaseorder.currency})</TableCell></>}
                                      </>) 
                                      : (<><TableCell className="text-center">IGST</TableCell>
                                       {this.state.purchaseorder.currency === currency?'':<TableCell className="text-center">IGST({this.state.purchaseorder.currency})</TableCell>}</>) :
                                      this.state.purchaseorder.taxtype === "GST" ?<><TableCell className="text-center">GST</TableCell>
                                       {this.state.purchaseorder.currency === currency?'':<TableCell className="text-center">GST({this.state.purchaseorder.currency})</TableCell>}</>
                                      :<> <TableCell className="text-center">VAT</TableCell>
                                       {this.state.purchaseorder.currency === currency?'':<TableCell className="text-center">VAT({this.state.purchaseorder.currency})</TableCell>}</>}
                                    </>:''}
                                <TableCell  className="text-center">Total</TableCell>
                                {/* <TableCell  className="text-center">Exchange or Return</TableCell> */}
                                <TableCell  className="text-center">Description</TableCell>
                                <TableCell  className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.accepteditems.map((data, index) => (
                                <TableRow key={index} style={{ textAlign: "center" }}>
                                   <TableCell  className="text-center">{data.poid}</TableCell>
                                  <TableCell  className="text-center">{data.name}</TableCell>
                                  <TableCell  className="text-center">{data.hsn}</TableCell>
                                  <TableCell  className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></TableCell>
                                  <TableCell  className="text-center">{data.quantity}</TableCell>
                                  <TableCell  className="text-center">{data.weightedquantity}</TableCell>
                                  <TableCell  className={`text-center  ${data.weightedquantity - data.quantity>0?"text-green":data.weightedquantity - data.quantity<0?"text-red":""} `}>{(data.weightedquantity - data.quantity).toFixed(2)}</TableCell>
                                  <TableCell  className="text-right">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.totalprice} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                    </TableCell>
                                    {this.state.purchaseorder.taxrequired === "TRUE"?<>
                                      {this.state.purchaseorder.taxtype === "GST" && ccountry === "India" && this.state.purchaseorder.importpo === false  &&  this.state.purchaseorder.gsttype === "INTRASTATE"  ?
                                        (<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         {this.state.purchaseorder.currency === currency?'':<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(data.taxamount/2)* this.state.purchaseorder.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(data.taxamount/2)* this.state.purchaseorder.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         </>}
                                        </>) : (<>
                                          <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax)}%)</TableCell>
                                          {this.state.purchaseorder.currency === currency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount*this.state.purchaseorder.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax)}%)</TableCell>}
                                       </> )
                                        }
                                      </>:''}
                                  <TableCell  className="text-right">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.totalpricewtax} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                    </TableCell>
                                  {/* <TableCell  className="text-center">
                                    {data.acceptreturn === 0 ? "Accepted" : "Return"}
                                  </TableCell> */}
                                  <TableCell  className="text-center">{data.description}</TableCell>
                                  {!data.id &&
                                  <TableCell  className="text-center">
                                    <span
                                      type="button"
                                      data-tip
                                      data-for="delete"
                                      className="fa fa-trash text-danger"
                                      style={{ paddingLeft: "7px" }}
                                      onClick={() => this.removeItemFromList(data.iid)}
                                    />
                                  </TableCell>}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          </>}
                        </div>

                        {/* <div className="form-row"> */}
                       <div className="form-group col-md-12 m-0 p-0"/> 
                          <div className="form-group col-md-3">
                              <TextField
                                id="invoiceid"
                                className="form-control"
                                type="text"
                                name="invoiceid"
                                label="Invoice/DeliveryNote Id"
                                value={this.state.invoiceid}
                                onChange={(e) =>
                                  this.setState({ invoiceid: e.target.value })
                                }
                                helperText = {(this.state.invoiceid === undefined || this.state.invoiceid === '') && isError? "Please enter Invoice/DeliveryNote Id":''} 
                                error={(this.state.invoiceid === undefined || this.state.invoiceid === '') && isError}
                                variant="standard"
                              />
                              </div>
                            <div className="form-group col-md-3">
                              <TextField
                                id="amount"
                                className="form-control"
                                type="number"
                                name="invoiceamt"
                                label="Invoice Amount"
                                value={this.state.invoiceamt}
                                onChange={(e) =>
                                  this.setState({ invoiceamt: e.target.value })
                                }
                                onFocus={this.onfocus}
                                variant="standard"
                                helperText = {(this.state.invoiceamt === undefined || this.state.invoiceamt === '') && isError? "Please enter Amount":''} 
                                error={(this.state.invoiceamt === undefined || this.state.invoiceamt === '') && isError}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <TextField
                                id="vehicleno"
                                className="form-control"
                                type="text"
                                name="vehicleno"
                                label="Vehicle/Tanker No."
                                value={this.state.vehicleno}
                                onChange={(e) =>
                                  this.setState({ vehicleno: e.target.value })
                                }
                                variant="standard"
                              />
                              </div>

                            <div className="form-group col-md-12 m-0 p-0"/> 

                            <fieldset className="form-group col-md-3 mt-2 m-0">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.invupload}
                                      onChange={()=>this.setState({invupload:!this.state.invupload})}
                                      name="ordertype"
                                      color="primary"
                                    />
                                  }
                                  label="Upload Invoice Document?"
                                />
                              </FormGroup>
                            </fieldset> 
                            {this.state.invupload?
                            <>
                            <fieldset className="form-group col-md-3"> 

<label htmlFor="btn-upload">
  <input
  className="btn-choose"
    id="btn-upload"
    name="btn-upload"
    style={{ display: 'none' }}
    type="file"
    onChange={this.selectFile} />
  <Button
    className="btn-close"
    variant="outlined"
    type="upload"
    component="span" >
    <span className="text-blue">Select Invoice/DeliveryNote File</span>
  </Button>
</label>
<ul className="list-group">
{selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
</ul>
</fieldset>

<fieldset  className="form-group col-md-3"> 
<Button
  className="btn-upload"
  color="primary"
  variant="contained"
  component="span"
  disabled={!selectedFiles}
  onClick={this.upload}>
  Upload
</Button>
</fieldset>

{/* </div> */}
{message === undefined || message === ''?'':
<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "text-red" : "text-green"}`}>
  <span>{message}</span>
</Typography>
</div>
}
                            </>
                          :""}

                        {/* </div> */}

                      </>
                      
                      
                      }
                      <div className="form-group col-md-3" />
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="form-group col-md-12" />
              {this.state.accepteditems.length === 0?'': <>
                <button className="float-center btn btn-sm hovbuttonColour mr-2" onClick={() => this.handelSubmit()} disabled={this.state.loader===true}>
                {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:
                  "Submit"}
                </button>
                <Link as="button" className="btn btn-sm deletebutton" to={{ pathname: "/grn" }}>
                  <b> X </b> Cancel 
                </Link>
              </>}
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewGRN);
