import { Field, FieldArray, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import {
  userid,
  currentTenant,
  taxtype,
  thousandsdisplay,
  ccountry,
  currency,
  cstate,
  financialyearstart,
  financialyearend,
  dataaccess,
  userwhid,
  transactionFrom
} from "../../Common/Credentials.js";
import { FormikSelectField, FormikTextField } from "../../CommonComponents/FormField.js";
import SalesServices from "../SalesServices";
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  styled,
  MenuItem,
  Select,
  Slider,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary, Autocomplete, TextField,TableCell,Table, Backdrop, CircularProgress
} from "@mui/material";
import UserService from "../../users/UserService.js";
import { Button, Card, Spinner } from "react-bootstrap";
import MasterService from "../../Masters/MasterService.js";
import moment from "moment";
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";
import InventoryService from "../../inventory/InventoryService";
import CategoryServices from "../../Masters/Category/CategoryServices";
import {NumericFormat} from "react-number-format";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import SettingsServices from "../../Settings/SettingsServices";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//import { accounts } from "../../Common/Products";
import AgentServices from "../../Masters/Agents/AgentServices";
import { accounts } from "../../Common/Products";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService";
import Swal from "sweetalert2";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { spin } from "../../Common/NewLoader.jsx";
import AdminDashboardServices from "../../DashboardPages/AdminDashboard/AdminDashboardServices.js";
import DepartmentServices from "../../Masters/Department/DepartmentServices.js";


let datalist = [];
let updatedatalist = [];
let list = {};
let count = 0;
let serviceList = [];

const useStyles = styled((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  },
}));

class NewSalesOrder extends Component {
  constructor(props) {
    super(props);
    const pagename =
      this.props.location.state === undefined
        ? this.history.props.push("/saleorder")
        : this.props.location.state.pagename;
    const id =
      this.props.location.state === undefined
        ? this.history.props.push("/saleorder")
        : this.props.location.state.id;

    this.state = {
      pagename: pagename,
      usercustomers:[],
      SDdate:false,
      orderfrom:this.props.location.state.orderfrom,
      quotationIdList:[],
      agentsdata:[],
      agentreq:false,
      agent:'',
      agentid:'',
      quotation:'',
      datatoupdate: "",
      id: id,
      salestype: '0',
      soid: "",
      ordertype: false,
      iid: "",
      custom: [],
      customer: "",
      itemid: "",
      itemList: [],
      saleorderdata: [],
      itemname: "",
      hsnid: "",
      hsn: "",
      custid: "",
      quantity: "",
      avilablequantity: "",
      cost: 0,
      type: "FIFO",
      uom: "",
      uomid: "",
      UOMList: [],
      warehouse: '',
      whid: null,
      WarehouseList: [],
      whdata: [],
      departmentList:[],
      deptid:null,
      department:"",
      CategoryList: [],
      categoryid: '',
      category: '',
      stock: "",
      discount:0,
      discountamount:0,
      taxrequired:true,
      taxtype:taxtype,
      tax: "",
      cgst: "",
      sgst: "",
      additionalparams: 0,
      taxamount: 0,
      totaltaxamount: 0,
      totalprice: 0,
      totaliv: 0,
      totalpricewtax: 0,
      grandtotal: 0,
      listData: [],
      status: 0,
      description: '',
      creditperiod: "",
      creditvalue: "",
      creditstatus: '',
      availabelcredit: "",
      initialCredit: "",
      gsttype: false,
      ledgerid: "",
      UserPermissions: "",
      preitemList: [],
      filteritemlist: [],
      size: "",
      colour: "",
      thickness: "",
      brand: "",
      model:'',
      totalmtrroll: "",
      sizelist: [],
      colourlist: [],
      thicknesslist: [],
      brandslist: [],
      custcity:'',
      custstate:'',
      custcountry:'',
      advance: 0,
      advancecnvt:0,
      advancestatus: false,
      paymentterms: 0,
      filterserv: [],
      ServiceCategoryList: [],
      serviceitemList: [],
      serviceList: [],
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: 0,
      servicecostconv:0,
      servicecgst:'',
      servicesgst:'',
      serviceigst:'',
      servicegst:'',
      servicevat:'',
      servicetaxamount:'',
      servicecostwttaxconv:0,
      servicecostwttax: '',
      servicedescription: "",
      servicetotal: "",
      serviceerr: '',
      grandservicetotal: 0,
      salescalreq:'',
      expecteddelivery:new Date(),
      graceperiod:0,
      days: [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      requestcredit:0,

      milestone:  [{ name: '', expecteddate: '', percentage: 0, totalprice: 0, totalpricewtax: 0 }],
      recurringtype: [],
      contracttypeList: [],
      contracttype: '',
      contracttime: '',
      contractstartdate: new Date(),
      contractenddate: new Date(),
      milestonesavebtn: false,
      percentagemessage: '',

      pro: true,
      serv: false,
      prosev: false,
      sloader:false,
      pricevariance:0,
      open:false,
      soeditprice:"NO",
      currencylist:[],
      currencyid:'',
      currency:'',
      curconversionrate:1,
      basecurrency:'',
      convertionreq:false,

      insurancereq:false,
      insurance:0,
      insuranceamount:0,
      totalinsuranceamount:0,
      totaldiscount:0,
      // partyid: "",
      showfields:true,
      //custcountry:''
      // custcountry:'',
      salesenumtypes:["Average","FIFO","LIFO"],
      closingStock:0,
      settings:'',
      pouploadm:0,
      customcreatedAt:"",
      loading: false,
      inventorydata:[],
      blockedpurchasequantity:0,
      blockedsalesquantity:0,
      deleteditemlist:[],
      fed:'',
      adjustfromprevso:false,
      soidsforadjust:[],
      soidforadjust:'',
      soitemidforadjust:'',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleProductChange = () => {
    this.setState({
      pro: true, serv: false, prosev: false, salestype: '0',
      itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', creditperiod: "", creditvalue: "", creditstatus: '', availabelcredit: "", initialCredit: "", gsttype: false, size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", service: "", serviceid: "", servicecategory: "", servicecatid: "", servicecode: "", servicetax: "", servicecost: 0, servicecostwttax: '',servicetaxamount:'',servicedescription: "", servicetotal: "", serviceerr: '', grandservicetotal: 0, categoryid: '', category: '', stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0,totaldiscount:0, listData: [],insurancereq:false,showfields:true
    })
    datalist = [];
  }
  handleServiceChange = () => {
    this.setState({
      serv: true, pro: false, prosev: false, salestype: '1',itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', creditperiod: "", creditvalue: "", creditstatus: '', availabelcredit: "", initialCredit: "", gsttype: false, size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", service: "", serviceid: "", servicecategory: "", servicecatid: "", servicecode: "", servicetax: "", servicecost: 0, servicecostwttax: '',servicetaxamount:'',servicedescription: "", servicetotal: "", serviceerr: '', grandservicetotal: 0, categoryid: '', category: '', stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0,totaldiscount:0, listData: [],insurancereq:false,showfields:true
    })
    datalist = [];
  }
  handleProSerChange = () => {
    this.setState({
      prosev: true, pro: false, serv: false, salestype: '2',itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', creditperiod: "", creditvalue: "", creditstatus: '', availabelcredit: "", initialCredit: "", gsttype: false, size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", service: "", serviceid: "", servicecategory: "", servicecatid: "", servicecode: "", servicetax: "", servicecost: 0, servicecostwttax: '',servicetaxamount:'',servicedescription: "", servicetotal: "", serviceerr: '', grandservicetotal: 0, categoryid: '', category: '', stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0,totaldiscount:0, listData: [],insurancereq:false,showfields:true
    })
    datalist = [];
  }

  handlemulticurrencyrequired = () => {
    this.setState({ convertionreq: !this.state.convertionreq });
  };

  handleCurrency = (value,values) => {
    if(value !== ''){
      this.setState({currencyid:values.id, currency: values.currency }) 
    }
  }

  handleCurconversionrate =(e)=> {
      this.setState({
        curconversionrate: e.target.value,
      })
  }

  handlesale = (e) => {
    this.setState({ salestype: e.target.value })
  }

  handleadvancestatus = (e) => {
    this.setState({ advancestatus: !this.state.advancestatus, advance: 0 });
  };


  handleList = () => {
    count = count + 1;

    // this.state.itemList.find(e=>e.itemid = this.state.itemid).totalstock = this.state.itemList.find(e=>e.itemid = this.state.itemid).totalstock - this.state.quantity
    // (this.state.whdata.find(e => e.whid === this.state.whid).saleslist).find(e => e.itemid === this.state.itemid).totalstock = (this.state.whdata.find(e => e.whid === this.state.whid).saleslist).find(e => e.itemid === this.state.itemid).totalstock - this.state.quantity
    this.setState(
      {
        totaliv: parseFloat(this.state.totaliv) + parseFloat(this.state.totalprice),
        grandtotal: this.state.grandtotal + this.state.totalpricewtax,
        creditvalue: this.state.availabelcredit,
        totaltaxamount: this.state.totaltaxamount + this.state.taxamount,
        totalinsuranceamount:this.state.totalinsuranceamount + this.state.insuranceamount,
        // stock: this.state.stock - parseInt(this.state.quantity),
        totaldiscount:this.state.totaldiscount + this.state.discountamount,
      },
      () => {
        list = {
          soid: this.state.id,
          soidforadjust: this.state.soidforadjust,
          soitemidforadjust:this.state.soitemidforadjust,
          adjusted:this.state.soitemidforadjust !==null?parseFloat(this.state.quantity):0,
          customer: this.state.customer,
          custid: this.state.custid,
          itemid: parseInt(this.state.itemid),
          name: this.state.itemname,
          hsn: this.state.hsn,
          price: (parseFloat(this.state.cost) + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1))+(((parseFloat(this.state.cost)+parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) *parseFloat(this.state.pricevariance))/100) ,
          markup1:parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1),
          salesenum: this.state.type,
          quantity: parseFloat(this.state.quantity),
          pricevariance:parseFloat(this.state.pricevariance),
          totalprice: parseFloat(this.state.totalprice),
          tax: parseFloat(this.state.tax),
          taxamount: this.state.taxamount,
          totalpricewtax: this.state.totalpricewtax,
          size: this.state.size,
          brand: this.state.brand,
          model:this.state.model,
          uom: this.state.uom,
          colour: this.state.colour,
          thickness: this.state.thickness,
          totalmtrroll: this.state.totalmtrroll,
          description: this.state.description,
          catid: this.state.catid,
          category: this.state.category,
          additionalparams: this.state.additionalparams,
          creditperiod: this.state.creditperiod,
          creditvalue: this.state.availabelcredit === '' ? 0 : this.state.availabelcredit,
          currencyid: this.state.currencyid,
          currency: this.state.currency,
          curconversionrate: parseFloat(this.state.curconversionrate),
          insurancereq:this.state.insurancereq,
          insurance:0,
          insuranceamount:0,
          discount:parseFloat(this.state.discount),
          discountamount:this.state.discountamount,
        };

        datalist.push(list);
        updatedatalist.push(list);
        this.setState({
          listData: datalist,
        });

        let soids = datalist?.map(e=>e.soidforadjust)
        console.log(datalist)
        this.setState({
          soidsforadjust:this.state.soidsforadjust.filter(e=>!soids.includes(e)),
          preitemList:[],
          soidforadjust:'',
          quantity: "",
          totalpricewtax: "",
        });
      }
    );
  };
  

  handleSubmit = () => {
    // let filedata = this.state.fileInfos.map((e) => e.name).toLocaleString();
    // let path = prefix1 +"/" +doctype +"/" +this.state.partyid +"/" +year +"/" +curmonth +"/" +day;
    // if (filedata === "" && this.state.pofile === "") {
    //   this.setState({ message: "Please Upload Purchase Order" });
    // } else {
   
    if (this.state.salestype === '1') {
      serviceList = [{
        service: this.state.service,
        serviceid: this.state.serviceid,
        servicecatid: this.state.servicecatid,
        servicecode: this.state.servicecode,
        servicecategory: this.state.servicecategory,
        servicecost: parseFloat(this.state.servicecost),
        servicetax: this.state.servicetax,
        description: this.state.servicedescription,
        servicetotal: parseFloat(this.state.servicecost) + parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
        recurringtype: this.state.contracttime,
        milestonelist:this.state.contracttype==="Recurring"?[]: this.state.milestone,
        contracttype: this.state.contracttype,
        recurrencetill: this.state.contractstartdate,
        currencyid: this.state.currencyid,
        currency: this.state.currency,
        curconversionrate: parseFloat(this.state.curconversionrate),
        
        // contractenddate: this.state.contractenddate,
        // contractfile:filedata,
        // contractpath:path,
      }]
    }

   let sodata = {
      // pofile: this.state.pofile === "" ? filedata : this.state.pofile,
      // popath: this.state.popath === "" ? path : this.state.popath,
      orderfrom:this.state.orderfrom,
      quotation:this.state.quotation === ""?null:this.state.quotation,
      agent:this.state.agentid===''||this.state.agentid===undefined?null:this.state.agentid ,
      soid: this.state.id,
      sotype: parseInt(this.state.salestype),
      creditstatus: this.state.creditstatus === undefined ? null : this.state.creditstatus,
      customer: this.state.customer,
      custid: this.state.custid,
      status: this.state.status,
      creditperiod: this.state.creditperiod === undefined || this.state.creditperiod === null ? 0 : this.state.creditperiod,
      creditvalue: this.state.availabelcredit === null || this.state.availabelcredit === undefined ? 0 : this.state.availabelcredit,
      gsttype: this.state.gsttype === false ? 0 : 1,
      grandtotal: this.state.grandtotal,
      whid: this.state.whid,
      deptid:this.state.deptid,
      createdby: userid,
      updatedby: userid,
      tenantid: currentTenant,
      totaltaxamount: this.state.totaltaxamount,
      paymentterms: this.state.paymentterms,
      itemlist: this.state.pagename === "Edit Sale Order" ? updatedatalist : datalist,
      deleteditemlist:this.state.deleteditemlist, 
      servicel: serviceList,
      grandservicetotal: this.state.grandservicetotal,
      advancepaymentreq: this.state.advancestatus === false ? 0 : 1,
      advanceamountreq: parseFloat(this.state.advance),
      pouploadreq:this.state.pouploadm==="YES"?0:1,

      currencyid: this.state.currencyid,
      currency: this.state.currency === undefined?currency:this.state.currency,
      curconversionrate: this.state.curconversionrate === undefined || this.state.curconversionrate === ''?1: parseFloat(this.state.curconversionrate),

      taxrequired:this.state.taxrequired,
      taxtype:this.state.taxtype ==="GST"?0:1,
      totalinsuranceamount:this.state.totalinsuranceamount,
      totaldiscount:this.state.totaldiscount,
      // date:moment(new Date(this.state.customcreatedAt)).format("yyyy-MM-DD HH:mm:ss"),
      date:this.state.fed!==''?this.state.fed:null,
      sddate:this.state.SDdate,
      expecteddelivery:this.state.expecteddelivery,
      graceperiod:this.state.graceperiod,
      directso:false,
    };

    if(datalist.filter(e=>e.soidforadjust !== null && e.soidforadjust!== undefined && e.soidforadjust !== "").length >0){
      Swal.fire({
        title: "",
        text: "Once items Adjusted from previous orders cannot be readjusted back to previous Order, Do you still want to continue",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#152f5f",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if(result.value){
          this.setState({ sloader: true });
          SalesServices.NewOrdersssssssss(sodata).then((response) =>
          this.props.navigate({pathname:`/saleorderdetails`},{state:{id:response.data,goback:false}})
        )
          .catch((e) => {
            this.setState({sloader:false})
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          })
        }
      })
    }else{
      this.setState({ sloader: true });
    SalesServices.NewOrdersssssssss(sodata).then((response) =>
    this.props.navigate({pathname:`/saleorderdetails`},{state:{id:response.data,goback:false}})
    )
      .catch((e) => {
        this.setState({sloader:false})
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      })
  }
  }



  handleordertype = (e) => {
    this.setState({ ordertype: !this.state.ordertype }, () =>
      this.state.ordertype === true
        ? SalesServices.GetallSaleOrderOfCustomer(this.state.custid).then(
          (res) => this.setState({ saleorderdata: res.data })
        )
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          })
        : ""
    );
    //  SalesServices.GetPreviousOrder(this.state.custid).then(res=>
    //   this.setState(
    //     {
    //       listData: res.data.prevsoitemlist,
    //       creditvalue: res.data.creditvalue,
    //       grandtotal: res.data.grandtotal,
    //       taxamount: res.data.taxamount,
    //       totaltaxamount: res.data.totaltaxamount,
    //       totaliv: res.data.totaliv,
    //       gsttype:res.data.gsttype=== "INTERSTATE"?'0':'1',
    //     }, () => {datalist = res.data.prevsoitemlist})
    //   )
  };

AdjustfromPreviousSaleorder= () =>{
  this.setState({adjustfromprevso:!this.state.adjustfromprevso})
  if(this.state.adjustfromprevso === false){
    SalesServices.GetSoforadjustment(this.state.custid).then((res)=>this.setState({soidsforadjust:res.data}))
  }
}

SelectSO = (value,values) =>{
  if (values !== '' && this.state.soidsforadjust.includes(values)) {
    this.setState({ soidforadjust: values });
    const id = values;
    SalesServices.GetSoitemsforadjustment(id).then((response) =>
      this.setState({
        preitemList: response.data,
      })
    )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      })
  }else{
    this.setState({ soidforadjust:"",preitemList:[] });
  }
}


SelectItemFromSO = (idx) =>{

 let data =  this.state.preitemList.find((item,i) => idx===i)
 console.log(data)
this.setState({
 itemid: data.itemid,
 itemname: data.name,
 soitemidforadjust:data.id,
 hsn: data.hsn,
 cost: data.price,
 type: data.salesenum,
 quantity: parseFloat(data.pending),
 stock:parseFloat(data.pending),
 avilablequantity:parseFloat(data.pending),
//  pricevariance:parseFloat(data.pricevariance),
//  totalprice: parseFloat(data.totalprice),
 tax: parseFloat(data.tax),
 cgst: parseFloat(data.tax/2),
 sgst:parseFloat(data.tax/2),
 igst:parseFloat(data.tax),
 taxamount: data.taxamount,
//  totalpricewtax: data.totalpricewtax,
 size: data.size,
 brand: data.brand,
 model:data.model,
 uom: data.uom,
 colour: data.colour,
 thickness: data.thickness,
 totalmtrroll: data.totalmtrroll,
 description: data.description,
 catid: data.categoryid,
 category: this.state.CategoryList.find(e=>e.id === data.categoryid).category,
 additionalparams: data.additionalparams,
 insurancereq:data.insurancereq,
 insurance:0,
 insuranceamount:0,
 discount:parseFloat(data.discount),
//  discountamount:data.discountamount,
})

}


  handlegsttype = (e) => {
    this.setState({ gsttype: !this.state.gsttype });
  };

  handleSaleOrder = (value) => {
    if (value !== '' && this.state.saleorderdata.includes(value)) {
      this.setState({ saleorderid: value });
      const id = value;
      SalesServices.GetDataToUpdate(id).then((response) =>
        this.setState({
          preitemList: response.data.saleorderitems,
        })
      )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        })
    }
  };

  // handlepreitem = (value,values)=>{
  //   this.setState({ itemid: values.preitemname.id, itemname: value,quantity: '', totalpricewtax: '' });
  // }

  // handleConfirm = () => {
  //   this.setState({ status: 1 });
  //   SalesServices.ConfirmOrders(datalist).then(() =>
  //     this.props.navigate(`/salesorder`)
  //   );
  // };

  deleteSaleOrderItem(id) {
    let deleteditem = this.state.listData.filter((data) => data.id === id);
    let tp = deleteditem.map((data) => data.totalpricewtax);
    let tx = deleteditem.map((data) => data.taxamount);
    let ti = deleteditem.map((data) => data.totalprice);
    let newitemlist = this.state.listData.filter((data) => data.id !== id);
    this.setState({
      listData: newitemlist,
      grandtotal: this.state.grandtotal - tp,
      totaltaxamount: this.state.totaltaxamount - tx,
      totaliv: this.state.totaliv - ti,
      availabelcredit: this.state.availabelcredit - parseFloat(tp),
    });
    datalist = newitemlist;
    SalesServices.deleteSaleOrderItem(id)
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      })
  }

  handleRequestCredit = () => {
    if(this.state.requestcredit>0){
    let customer = {
      status: 0,
      custid:this.state.custid,
	    customer:this.state.customer,
	    reqvalue:this.state.requestcredit,
	    tenantid:currentTenant,
	    requestedby:userid
    };

    Swal.fire({
      title: "Request for credit?",
      text: "Are you sure you want to request?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        SalesServices.RequestCridet(customer)
          .then((response) => {
            Swal.fire({
              title: response.data,
              text: ".",
              icon: response.data ==="Customer credit request has been sent"?"success":"info",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>{
              this.setState({
                availabelcredit: this.state.availabelcredit + this.state.totalpricewtax,
                totalpricewtax:""
              });
            })
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  }
  };

  handleQuotation=(value,values)=>{
    if (values !== '' && typeof values === 'object' && values !== null) {
      this.setState({ quotation: values.customqtid});
      SalesServices.GetQuotationbyId(values.id).then(res=>{
        this.setState(
          {
            listData: res.data.itemquotation.map((e) =>
            Object.assign(e, { discount: 0 ,discountamount:0,insurance:0,insuranceamount:0,insurancereq:false })
          ),
            soid: res.data.id,
            salestype: res.data.sotype==="Products"?'0':res.data.sotype ==="Services"?'1':'2',
            custid: res.data.custid,
            customer: res.data.customer,
            warehouse: res.data.warehouse,
            whid: res.data.whid,
            deptid:res.data.deptid,
            department:res.data.department,
            agent:res.data.agent,
            agentid:res.data.agentid,
            creditvalue: res.data.creditvalue,
            initialCredit: res.data.creditvalue,
            creditperiod: res.data.creditperiod,
            availabelcredit: res.data.creditvalue,
            grandtotal: res.data.grandproducttotal,
            grandservicetotal: res.data.grandservicetotal,
            taxamount: res.data.taxamount,
            totaltaxamount: res.data.totaltaxamount,
            totaliv: res.data.totaliv,
            taxrequired:res.data.taxrequired,
            taxtype:res.data.taxtype,
            gsttype: res.data.gsttype === "INTERSTATE" ? true : false,
            currency:res.data.currency,
            curconversionrate:res.data.curconversionrate,
            advance: res.data.advanceamountreq,
            advancestatus: res.data.advancepaymentreq === "True" ? true : false,
            paymentterms: res.data.paymentterms,
            advancecnvt: res.data.advanceamountreq*res.data.curconversionrate,
            showfields:false,
          },
          () => {
            datalist=[]
            serviceList=[]
            datalist = res.data.sotype ==="Services"?res.data.itemquotation : res.data.itemquotation.map(item=>{return {...item,catid:item.categoryid}});
            const id = values.id;
            console.log(values,datalist)
            SalesServices.GetCustomerTerms(id).then((res) =>
              this.setState({
                creditstatus: res.data.creditstatus,
                creditvalue: res.data.creditleft-res.data.grandproducttotal,
                creditperiod: res.data.creditperiod,
                availabelcredit: res.data.creditleft-res.data.grandproducttotal,
                initialCredit: res.data.creditleft-res.data.grandproducttotal,
              }))
            if(res.data.sotype!=="Products"){
            let serviceListA = res.data.servicequotation.find(e=>e)
            let val = res.data.servicequotation.find(e=>e).servicetotalwtax
           serviceListA["servicetotal"]=val
           serviceList.push(serviceListA)
            this.setState({serviceList:serviceList})
            serviceList.map(e=>
              this.setState({
                service:e.service,
                servicecode:e.servicecode,
                servicecost:e.servicecost,
                servicetax:e.servicetax,
                servicetotalwtax:e.servicetotalwtax,
                recurringtype:e.recurringtype,
                // contractstartdate:e.recurrencetill,
                contractenddate:e.contractenddate,
                contractstartdate:e.contractstartdate,
                contracttype:e.contracttype,
                servicedescription:e.description,
                milestone:e.milestonequotation
              })
            )
            }
          }
        )
      })

    }else{
    this.setState({
      quotation:'',custid:'', customer: '',currency:'',currencyid:'',gsttype:'',custcountry:'',convertionreq:'',ordertype: false,partyid: '',itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0, listData: [],agent:'',agentid:''
    })
    }

  }

  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === 'object' && values !==null) {
      let lv = "Personal";
      if (values.ledgerid === null|| values.ledgerid === undefined) {
        if(accounts==="YES"){
        Swal.fire({
          text:`Ledger account is not created for Customer ${values.companyname}, so no payment or receipt can be done`,
          icon:"warning",
          confirmButtonColor: "#152f5f",
        })
      }
    }
      this.setState({ custid: values.id, customer: values.companyname,ledgerid:values.ledgerid, currency:values.currency,currencyid:this.state.currencylist.find(e=>e.currency===values.currency).id, taxtype:values.taxtype,gsttype:values.statename=== cstate && values.countryname===ccountry?false:true,custcountry:values.countryname,convertionreq:values.currency===this.state.basecurrency?false:true,ordertype: false,partyid: values.id,itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0, listData: [], preitemList:[],
      soidforadjust:'',soidslist:[], adjustfromprevso:false,
      })

      const id = values.id;

      SalesServices.GetCustomerTerms(id).then((res) =>{
        this.setState({
          creditstatus: res.data.creditstatus,
          creditvalue: res.data.creditleft,
          creditperiod: res.data.creditperiod,
          availabelcredit: res.data.creditleft,
          initialCredit: res.data.creditleft,
        })
        SalesServices.IsPaymentPending(id).then((response)=>
        response.data && res.data !==null && res.data.creditstatus === "Required"?
        Swal.fire({
          title:'Pending payment from customer!',
          text: "Payment is due from the customer, please settle it before continuing",
          icon:"warning",
          confirmButtonColor: "#152f5f",
        }).then(()=> this.setState({custid: '', customer: '',ledgerid:'', currency:'',currencyid:'', taxtype:'',gsttype:false,custcountry:'',convertionreq:false,ordertype: false,partyid: '',itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0, listData: [], preitemList:[],
        soidforadjust:'',soidslist:[], adjustfromprevso:false,})) 
        :""
        )
    })
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        })
    }else{
      this.setState({custid:'',customer:'',preitemList:[],soidforadjust:'',soidslist:[], adjustfromprevso:false,})
    }
  }

  handleQuantityChange = (e) => {
    this.setState({ quantity: e.target.value });
    this.setState({ totalpricewtax: "" });
    if (this.state.creditstatus !== "NotRequired") {
      if (this.state.availabelcredit < 0) {
        this.setState({ availabelcredit: this.state.creditvalue });
      } else {
      }
    }
  };


  handleSize = (value, values) => {
    if (value !== '' && this.state.sizelist.includes(values) && values !==null) {
      this.setState({ size: values });
    } else {
      this.setState({ size: '' });
    }
  };

  handleColour = (value, values) => {
    if (values !== '' && this.state.colourlist.includes(values)&& values !==null) {
      this.setState({ colour: values });
    } else {
      this.setState({ colour: '' });
    }
  };

  onSubmit = () => {
    let cost = (parseFloat(this.state.cost) + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) + ((parseFloat(this.state.cost) + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1))*parseFloat(this.state.pricevariance)/100)
    let a = 0
    let d = (parseFloat(cost)*parseFloat(this.state.discount))/100
    let halftaxroundoff = ((parseFloat(this.state.tax/2) * ((cost-d) * parseFloat(this.state.quantity))) /100).toFixed(2)
    let taxroundoff =  this.state.taxtype === "GST" && ccountry ==="India" && this.state.gsttype === false?   parseFloat(halftaxroundoff) + parseFloat(halftaxroundoff): 
    ((parseFloat(this.state.tax) * ((cost-d) * parseFloat(this.state.quantity))) /100).toFixed(2)
    if(this.state.insurancereq === true){
    a = ((parseFloat(this.state.quantity)*cost-d)*parseFloat(this.state.insurance))/100
    }
    this.setState(
      {
        insuranceamount:a,
        discountamount:d*this.state.quantity,
        totalpricewtax:
          ((cost-d) * parseFloat(this.state.quantity)  + a +
          parseFloat(taxroundoff) ) ,
          
        taxamount:parseFloat(taxroundoff),
        totalprice:((cost-d) * parseFloat(this.state.quantity)) ,

      },
      () => {
        if (this.state.creditstatus !== "NotRequired") {
          this.setState({
            availabelcredit: this.state.availabelcredit -  this.state.totalpricewtax,
          });
        }
      }
    );
    //  }
    //  }
  };

  handlepreitem = (value, values) => {
    if (this.state.whid === '' || this.state.warehouse === '') { alert("Please Select Org Unit") }
    else {
      if (value !== "" && typeof values.itemname === 'object') {
        this.setState({
          itemid: values.preitemname.itemid,
          itemname: value,
          category: values.preitemname.category,
          categoryid: values.preitemname.categoryid,
          // cost: values.preitemname.price,
          uom: values.preitemname.uom,
          quantity: values.preitemname.quantity,
          pricevariance: values.preitemname.pricevariance,
          brand: values.preitemname.brand,
          model:values.preitemname.model,
          size: values.preitemname.size,
          colour: values.preitemname.colour,
          thickness: values.preitemname.thickness,
          totalmtrroll: values.preitemname.totalmtrroll,
          totalpricewtax: "",
        });
        if (this.state.creditstatus !== "NotRequired") {
          if (this.state.availabelcredit < 0) {
            this.setState({ availabelcredit: this.state.creditvalue });
          }
        }
        const id = values.preitemname.itemid;
        let itemdata = this.state.itemList.filter(e => e.itemid === id)
        itemdata.map(data =>
          this.setState({
            type: data.type,
            cost: data.price,
            hsn: data.hsn,
            tax: data.tax,
            cgst: data.tax / 2,
            sgst: data.tax / 2,
            additionalparams: data.additionalparams,
          })
        )
        let warehousedata = this.state.whdata.filter(e => e.whid === this.state.whid)
        let saleslist = warehousedata.find(e => e).saleslist
        let a = saleslist.filter(e => e.itemid === id)
        this.setState({ stock: a.map(e => e.totalstock)[0] })
      };
    }
  }

  handleWarehouse = (value, values) => {
    if (values !== "" && typeof values === 'object' && values !==null) {
      this.setState({
        warehouse: values.name,
        whid: values.id, ordertype: false,
        itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",
        description: ''
      })
      DepartmentServices.deptbywarehouse(values.id).then((response) =>{
        this.setState({
          departmentList: response.data
        })
        if(response.data.length ===1){
          this.setState({
            department: response.data[0].name,
            deptid: response.data[0].id
          })
        }
    });
    }else{
      this.setState({warehouse:'',whid:'',department:'',deptid:''})
    }
  }

  handleDepartment = (value, values) => {
    if (values !== "" && typeof values === 'object' && values !==null) {
      this.setState({
        department: values.name,
        deptid: values.id
      })
    }else{
      this.setState({department:'',deptid:''})
    }
  }

  handleAgent = (value, values) => {
    if (values !== "" && typeof values === 'object' && values !==null) {
      this.setState({
        agent: values.name,
        agentid: values.id,
      })
    }else{
      this.setState({agent:'',agentid:''})
    }
  }

  

  handleCategory = (value, values) => {
    if (values !== "" && typeof values === 'object' && values !==null) {
      this.setState({
        catid: values.id,
        category: values.category,
        filteritemlist: this.state.itemList.filter(
          (e) => e.catid === values.id
        ),
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        cost: 0,
        quantity: "",
        preitemList:[],
          soidforadjust:'',
      });
    }else{
      this.setState({
        catid: '',
        category: '',
        filteritemlist:[],
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        cost: 0,
        quantity: "",
        preitemList:[],
          soidforadjust:'',
      });
    }
  };

  handleitem = (value, values) => {
    if (this.state.whid === '' || this.state.warehouse === '') { alert("Please Select Org Unit") }
    else {
      if (values !== "" && typeof values === 'object' && values!==null) {
        this.setState({
          itemid: values.itemid,
          itemname: values.name,
          quantity: "",
          totalpricewtax: 0,
        });
        if (this.state.creditstatus !== "NotRequired") {
          if (this.state.availabelcredit < 0) {
            this.setState({ availabelcredit: this.state.creditvalue });
          }
        }
        const id = values.itemid;
        // SalesServices.getCost(id).then((res) =>
        let itemdata = this.state.itemList.filter(e => e.itemid === id)
        itemdata.map(data =>
          this.setState({
            type: this.state.salescalreq==="YES"?"FIFO":data.type===null||data.type===undefined?"FIFO":data.type,
            cost: this.state.salescalreq==="YES"?0:data.price,
            hsn: data.hsn,
            uom:data.uom,
            uomid:data.uomid,
            brand:data.brand,
            model:data.model,
            tax: this.state.taxrequired===true?data.tax:0,
            cgst: this.state.taxrequired===true?data.tax/2:0,
            sgst: this.state.taxrequired===true?data.tax/2:0,
            additionalparams: data.additionalparams,
            description:data.description,
            preitemList:[],
          soidforadjust:'',
          })
        )
        let warehousedata = this.state.whdata.filter(e => e.whid === this.state.whid)
        let saleslist = warehousedata.find(e => e).saleslist
        let a = saleslist.filter(e => e.itemid === id)
        // let stock = a.map(e => e.currentstock)[0] === undefined ? 0 : a.map(e => e.currentstock)
        let stock = a.reduce((total, currentValue) => total = total + currentValue.currentstock,0);
        this.setState({ stock: stock })
      }else{
        this.setState({itemname:'',itemid:'',preitemList:[],
          soidforadjust:''})
      }
    }
  };

  handleTypeChange=(e)=>{
    this.setState({type:e.target.value})
  }

  removeItemFromList(id) {
    let deleteditem = datalist.filter((person, index) => index === id);
    let ditem =  deleteditem.find((data) => data.itemid);
    if(ditem.id !== undefined && ditem.id !== '' && ditem.id !== null){
      this.state.deleteditemlist.push(ditem?.id)
    }
    console.log(ditem)
    // let dquantity = deleteditem.map((data) => data.quantity);
    let tp = deleteditem.map((data) => data.totalpricewtax);
    let tx = deleteditem.map((data) => data.taxamount);
    let ti = deleteditem.map((data) => data.totalprice);
    let tins =  deleteditem.map((data) => data.insuranceamount);
    let disc = deleteditem.map((data) => data.discountamount);
    const newlist = datalist.filter((person, index) => index !== id);

    if (this.state.creditstatus !== "NotRequired") {
      this.setState({
        availabelcredit: this.state.availabelcredit + (ditem.totalpricewtax),
        creditvalue: this.state.availabelcredit + (ditem.totalpricewtax),
      });
    }
    datalist = newlist;
    this.setState({
      listData: newlist,
      grandtotal: this.state.grandtotal - tp,
      totaltaxamount: this.state.totaltaxamount - tx,
      totaliv: this.state.totaliv - ti,
      totalinsuranceamount: this.state.totalinsuranceamount - tins,
      totaldiscount: this.state.totaldiscount - disc
    })
    //  this.state.whdata.find(e=>e.whid===this.state.whid).saleslist.find(e=>e.itemid===ditem.itemid).totalstock = this.state.whdata.find(e=>e.whid===this.state.whid).saleslist.find(e=>e.itemid===ditem.itemid).totalstock + ditem.quantity
    //  this.setState({stock:this.state.whdata.find(e=>e.whid===this.state.whid).saleslist.find(e=>e.itemid===ditem.itemid).totalstock })
  }


  EditItemFromList(id){
    let edititem = datalist.filter((person, index) => index === id);
    this.setState({showfields:true})
    let ditem =  edititem.find((data) => data.itemid);
    // let dquantity = edititem.map((data) => data.quantity);
    let tp = edititem.map((data) => data.totalpricewtax);
    let tx = edititem.map((data) => data.taxamount);
    let ti = edititem.map((data) => data.totalprice);
    let tins =  edititem.map((data) => data.insuranceamount);
    let disc = edititem.map((data) => data.discountamount);
    const newlist = datalist.filter((person, index) => index !== id);
    
    if (this.state.creditstatus !== "NotRequired") {
      this.setState({
        availabelcredit: this.state.availabelcredit + (ditem.totalpricewtax),
        creditvalue: this.state.availabelcredit + (ditem.totalpricewtax),
      });
    }

    datalist = newlist;
    this.setState({
      listData: newlist,
      grandtotal: this.state.grandtotal - tp,
      totaltaxamount: this.state.totaltaxamount - tx,
      totaliv: this.state.totaliv - ti,
      totalinsuranceamount: this.state.totalinsuranceamount - tins,
      totaldiscount: this.state.totaldiscount - disc
    })
    edititem.map(e=>
      this.setState({
        categoryid:this.state.itemList.find(i => i.itemid === e.itemid).catid,
        category:this.state.CategoryList.find(c=>c.id === this.state.itemList.find(i => i.itemid === e.itemid).catid).category,
        itemname:e.name,
        itemid:e.itemid,
        hsn:e.hsn,
        cost:e.price,
        uom:e.uom,
        tax:e.tax,
        sgst:e.tax/2,
        cgst:e.tax/2,
        quantity:e.quantity,
        stock:e.quantity,
        pricevariance:e.pricevariance,
        description:e.description,
        type:e.salesenum,
        brand:e.brand,
        model:e.model,
        size:e.size,
        colour:e.colour,
        thickness:e.thickness,
        totalmtrroll:e.totalmtrroll,
        // totalprice: e.totalprice,
        // taxamount: e.taxamount,
        // totalpricewtax: e.totalpricewtax,
        additionalparams:this.state.itemList.find(i => i.itemid === e.itemid).additionalparams,
      })
    )
  }

  refreshData() {
    const { id } = this.props.location.state;
    datalist = [];
    list = {};
    updatedatalist = [];
    count = 0;
    serviceList = [];

    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid:userid,
      dataaccess: dataaccess,
      whid:userwhid
    }

    if(this.props.location.state.orderfrom === 1){
      SalesServices.GetQuotationbyId(this.props.location.state.qid).then(res=>{
        this.setState(
          {
            listData: res.data.itemquotation.map((e) =>
            Object.assign(e, { discount: 0 ,discountamount:0,insurance:0,insuranceamount:0,insurancereq:false })
          ),
            soid: res.data.id,
            quotation:res.data.customqtid,
            salestype: res.data.sotype==="Products"?'0':res.data.sotype ==="Services"?'1':'2',
            custid: res.data.custid,
            customer: res.data.customer,
            warehouse: res.data.warehouse,
            whid: res.data.whid,
            deptid:res.data.deptid,
            agent:res.data.agent,
            agentid:res.data.agentid,
            creditvalue: res.data.creditvalue,
            initialCredit: res.data.creditvalue,
            creditperiod: res.data.creditperiod,
            availabelcredit: res.data.creditvalue,
            grandtotal: res.data.grandproducttotal,
            grandservicetotal: res.data.grandservicetotal,
            taxamount: res.data.taxamount,
            totaltaxamount: res.data.totaltaxamount,
            totaliv: res.data.totaliv,
            taxrequired:res.data.taxrequired,
            taxtype:res.data.taxtype,
            gsttype: res.data.gsttype === "INTERSTATE" ? true : false,
            currency:res.data.currency,
            curconversionrate:res.data.curconversionrate,
            advance: res.data.advanceamountreq,
            advancestatus: res.data.advancepaymentreq === "True" ? true : false,
            paymentterms: res.data.paymentterms,
            advancecnvt: res.data.advanceamountreq*res.data.curconversionrate,
            showfields:false
          },
          () => {
            datalist=[]
            serviceList=[]
            datalist = res.data.sotype ==="Services"?res.data.itemquotation : res.data.itemquotation.map(item=>{return {...item,catid:item.categoryid}});
            const id = this.props.location.state.qid;
            SalesServices.GetCustomerTerms(id).then((res) =>
              this.setState({
                creditstatus: res.data.creditstatus,
                creditvalue: res.data.creditleft-res.data.grandproducttotal,
                creditperiod: res.data.creditperiod,
                availabelcredit: res.data.creditleft-res.data.grandproducttotal,
                initialCredit: res.data.creditleft-res.data.grandproducttotal,
              }))
            if(res.data.sotype!=="Products"){
            let serviceListA = res.data.servicequotation.find(e=>e)
            let val = res.data.servicequotation.find(e=>e).servicetotalwtax
           serviceListA["servicetotal"]=val
           serviceList.push(serviceListA)
            this.setState({serviceList:serviceList})

            serviceList.map(e=>
              
              this.setState({
                service:e.service,
                servicecode:e.servicecode,
                servicecost:e.servicecost,
                servicetax:e.servicetax,
                servicetotalwtax:e.servicetotalwtax,
                recurringtype:e.recurringtype,
                // contractstartdate:e.recurrencetill,
                contractenddate:e.contractenddate,
                contractstartdate:e.contractstartdate,
                contracttype:e.contracttype,
                servicedescription:e.description,
                milestone:e.milestonequotation && e.milestonequotation.length>0?
                e.milestonequotation.map(m=>{return {...m,
                  name:m.name,
                  expecteddate:m.expecteddate,
                  percentage:m.percentage,
                  taxamount:m.taxamount,
                  totalprice:m.amount,
                  totalpricewtax:m.amountwttax
                }})
                :[]
              })
            )
            }
          }
        )
      })

    }

    MasterService.GetAllCurrency().then((response) => {
      this.setState({currencylist: response.data})
    });

    MasterService.GetCurrencyOfTenant(currentTenant).then((response) => {
      this.setState({basecurrency: response.data.currency, currencyid: response.data.id, currency: response.data.currency, curconversionrate: 1})
    });
    

    SalesServices.GetActiveQuotationId(datadao).then((response)=>{
      this.setState({quotationIdList:response.data})
    })
    InventoryService.GetWHdata(currentTenant).then((response) => {
      this.setState({ whdata: response.data })
    })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      })

    InventoryService.GetItems(currentTenant).then((response) =>
      this.setState({
        itemList: response.data,
        closingStock:response.data.length===0?0:response.data.find(e=>e.closingstock!==null).closingstock
      })
    )

    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      this.setState({
        custom: res.data.filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.companyname)),loading:false
      })
    );

    MasterService.getParameters(currentTenant).then((res) => {
      this.setState({
        parameters: res.data,
        sizelist: res.data.size,
        colourlist: res.data.colour,
        thicknesslist: res.data.thickness,
        brandslist: res.data.brands,
      });
    });

    // UomServices.getUOM(currentTenant).then((response) =>
    //   this.setState({
    //     UOMList: response.data,
    //   })
    // );

    SalesServices.GetContrctType().then(response => {
      this.setState({ contracttypeList: response.data })
    })
    SalesServices.GetRecurringduration().then((response) => {
      this.setState({ recurringtype: response.data })
    })

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
        ServiceCategoryList: response.data.filter(
          (e) => e.categorytype === "Service"
        ),
      })
    );
    WarehouseServices.getWarehouses(currentTenant).then((response) =>
    this.setState({
      WarehouseList: response.data,
    },()=>{
      if(this.state.WarehouseList.length === 1){
        this.setState({warehouse:this.state.WarehouseList[0].name,whid:this.state.WarehouseList[0].id})
        DepartmentServices.deptbywarehouse(this.state.WarehouseList[0].id).then((response) =>{
          this.setState({
            departmentList: response.data
          })
          if(response.data.length ===1){
            this.setState({
              department: response.data[0].name,
              deptid: response.data[0].id
            })
          }
      });
      }else if(dataaccess === "Organization" || dataaccess === "User"){
        this.setState({warehouse:this.state.WarehouseList.find(e=>e.id === userwhid).name,whid:this.state.WarehouseList.find(e=>e.id === userwhid).id,WarehouseList:this.state.WarehouseList.filter(e=>e.id===userwhid)})
        DepartmentServices.deptbywarehouse(this.state.WarehouseList.find(e=>e.id === userwhid).id).then((response) =>{
          this.setState({
            departmentList: response.data
          })
          if(response.data.length ===1){
            this.setState({
              department: response.data[0].name,
              deptid: response.data[0].id
            })
          }
      });
      }
    })
  );
    InventoryService.getServices(currentTenant).then((res) => {
      this.setState({ serviceitemList: res.data });
    });

    AgentServices.getAgents(currentTenant).then(res=>{
      this.setState({agentsdata:res.data})
    })

    SettingsServices.GetSettings(currentTenant).then((response)=>{
      this.setState({
        settings:response.data,
        soeditprice:response.data.soeditprice,
        salescalreq:response.data.salescalreq,
        pouploadm: response.data.pouploadm
       })
     })

     InventoryService.retriveStock(currentTenant).then(res=>{
      this.setState({inventorydata:res.data})
    })
    AdminDashboardServices.blockedpurchasequantity(currentTenant).then((res) =>
    this.setState({blockedpurchasequantity:res.data})
    )
    AdminDashboardServices.blockedsalesquantity(currentTenant).then((res) =>
    this.setState({blockedsalesquantity:res.data})
    )

    if (id < 1) {
      return;
    }

    SalesServices.GetDataToUpdate(id).then((res) =>{
      datalist = res.data.saleorderitems;
      this.setState(
        {
          listData: res.data.saleorderitems,
          serviceList: res.data.servicelist,
          soid: res.data.id,
          agent:res.data.agent,
          agentid:res.data.agentid,
          agentreq:res.data.agentid===undefined||res.data.agentid===null?false:true,
          salestype: res.data.sotype.toLocaleString(),
          custid: res.data.custid,
          customer: res.data.customer,
          warehouse: res.data.warehouse,
          whid: res.data.whid,
          deptid:res.data.deptid,
          creditvalue: res.data.creditvalue,
          initialCredit: res.data.creditvalue,
          creditperiod: res.data.creditperiod,
          availabelcredit: res.data.creditvalue,
          grandtotal: res.data.grandproducttotal,
          grandservicetotal: res.data.grandservicetotal,
          taxamount: res.data.taxamount,
          totaltaxamount: res.data.totaltaxamount,
          totaliv: res.data.totaliv,
          pofile: res.data.pofile,
          popath: res.data.popath,
          gsttype: res.data.gsttype === "INTERSTATE" ? true : false,
          advance: res.data.advanceamountreq,
          advancestatus: res.data.advancepaymentreq === "True" ? true : false,
          paymentterms: res.data.paymentterms,
          loading:false
        },
        () => {
          SalesServices.GetCustomerTerms(this.state.custid).then((res) =>
          this.setState({
            creditstatus: res.data.creditstatus,
            creditvalue: res.data.creditleft,
            creditperiod: res.data.creditperiod,
            availabelcredit: res.data.creditleft,
            initialCredit: res.data.creditleft,
          })
        )
        }
      )
      if (res.data.sotype === 1) {
        this.setState({
          service: res.data.servicelist[0].service,
          serviceid: res.data.servicelist[0].serviceid,
          servicecatid: res.data.servicelist[0].servicecatid,
          servicecode: res.data.servicelist[0].servicecode,
          servicecategory: res.data.servicelist[0].servicecategory,
          servicecost: res.data.servicelist[0].servicecost,
          servicetax: res.data.servicelist[0].servicetax,
          description: res.data.servicelist[0].servicedescription,
          servicecostwttax: res.data.servicelist[0].servicetotalwtax,
          servicecgst:res.data.servicelist[0].servicetax/2,
          servicesgst:res.data.servicelist[0].servicetax/2,
          serviceigst:res.data.servicelist[0].servicetax,
          servicevat:res.data.servicelist[0].servicetax,
          contracttime: res.data.servicelist[0].recurringtype,
          milestone: res.data.servicelist[0].milestones,
          contracttype: res.data.servicelist[0].milestones.length === 0? 'Recurring':'Milestone' ,
          contractstartdate: res.data.servicelist[0].recurrencetill,
        },()=> {
        this.state.milestone.map((m,i) =>this.state.milestone[i].expecteddate=moment(m.expecteddate).format('YYYY-MM-DD'))
        this.setState({milestone:this.state.milestone})
        }
        )
      }
       });
  }

  handleServicecategory = (value, values) => {
    if (values !== '' && values !== undefined && typeof values === 'object'){
      this.setState({
        servicecatid: values.id,
        servicecategory: values.category,
        filterserv: this.state.serviceitemList.filter(
          (e) => e.catid === values.id
        ),
        servicecost: '',
        servicecostwttax: '',
        servicetaxamount:'',
        milestonelist: [],
        percentagemessage: '',
        milestonesavebtn: false
      });
    }else{
      this.setState({
        servicecatid: '',
        servicecategory: '',
        filterserv: [],
        servicecost: '',
        servicecostwttax: '',
        servicetaxamount:'',
        milestonelist: [],
        percentagemessage: '',
        milestonesavebtn: false
      });
    }
  };

  handleService = (value, values) => {
    if (values !== '' && values !== undefined && typeof values === 'object') {
      this.setState({
        serviceid: values.id,
        service: values.service,
        servicetax: this.state.taxrequired ===true?values.servicetax:0,
        servicecode: values.servicecode,
        servicedescription:values.description,
        servicecost: '',
        servicecostwttax: '',
        servicetaxamount:'',
        milestonelist: [],
        percentagemessage: '',
        milestonesavebtn: false
      });
    }else{
      this.setState({
        serviceid: '',
        service: '',
        servicetax: 0,
        servicecode: '',
        servicedescription:'',
        servicecost: '',
        servicecostwttax: '',
        servicetaxamount:'',
        milestonelist: [],
        percentagemessage: '',
        milestonesavebtn: false
      
      })
    }
  };

  handleServiceAmountChange=(e)=>{
    this.setState({
      servicecost: e.target.value,
      servicecostconv:parseFloat(e.target.value)* this.state.curconversionrate,
      servicetaxamount:((this.state.servicetax / 100) *parseFloat(e.target.value)),
      servicecgst:((this.state.servicetax / 100)*parseFloat(e.target.value))/2,
      servicesgst:((this.state.servicetax / 100)*parseFloat(e.target.value))/2,
      serviceigst:((this.state.servicetax / 100)*parseFloat(e.target.value)),
      servicegst:((this.state.servicetax / 100)*parseFloat(e.target.value)),
      servicevat:((this.state.servicetax / 100)*parseFloat(e.target.value)),
      servicecostwttax:
        parseFloat(e.target.value) +
        (this.state.servicetax / 100) *
          parseFloat(e.target.value),
          servicecostwttaxconv:(parseFloat(e.target.value) +(this.state.servicetax / 100) *parseFloat(e.target.value))* this.state.curconversionrate,
    })
  }

  handleStartDateChange = (date) => {
    this.setState({contractstartdate:date})
  }
  handleEndDateChange = (date) => {
    this.setState({contractenddate:date})
  }

  validatepercentage = (values) => {
    let totalper = 0
    values.map(e => totalper = e.percentage + totalper)

  }

  handlemilestonevalues = (values) => {
    let totalpercentage = 0
    values.map(e => { return totalpercentage = e.percentage + totalpercentage })
    if (totalpercentage > 100) {
      this.setState({ percentagemessage: 'Percentage is Greaterthan 100%' })
    } else if (totalpercentage < 100) {
      this.setState({ percentagemessage: 'Percentage is Lessthan 100%' })
    } else {
      this.setState({ milestone: values, milestonesavebtn: true, percentagemessage: 'Milestones Saved Scussesfully' });
    }
  };

  removeServiceFromList(id) {
    let deleteservice = serviceList.filter((person, index) => index === id);
    let tp = deleteservice.map((data) => data.servicetotal);
    const newlist = serviceList.filter((person, index) => index !== id);
    this.setState({
      serviceList: newlist,
      grandservicetotal: this.state.grandservicetotal - tp,
    });
    serviceList = newlist;
  }

  handleaddService = () => {
    if (
      this.state.supplier === "" || this.state.service === "" ||
      this.state.servicedescription === "" ||
      this.state.servicecost === "" ||
      this.state.servicecategory === ""
    ) {
      this.setState({ serviceerr: "Please fill all the Fields" });
    } else {
      let services = {
        service: this.state.service,
        serviceid: this.state.serviceid,
        servicecatid: this.state.servicecatid,
        servicecode: this.state.servicecode,
        servicecategory: this.state.servicecategory,
        servicecost: parseFloat(this.state.servicecost),
        servicetax: this.state.servicetax,
        servicedescription: this.state.servicedescription,
        servicetotal: (parseFloat(this.state.servicecost) + parseFloat(this.state.servicecost) * (this.state.servicetax / 100)),
      };
      serviceList.push(services);
      this.setState({
        serviceList: serviceList,
        grandservicetotal: this.state.grandservicetotal + (parseFloat(this.state.servicecost) + (parseFloat(this.state.servicecost) * (this.state.servicetax / 100))),
        servicecost: '',
        serviceerr: '',
        servicetax:0,
        servicedescription: '',
        servicecostconv:0,
        servicetaxamount:0,
        servicecgst:0,
        servicesgst:0,
        serviceigst:0,
        servicegst:0,
        servicevat:0,
        servicecostwttax:0,
            servicecostwttaxconv:0,
      });
    }
  };

  handletaxrequired=()=>{
    this.setState({taxrequired: !this.state.taxrequired})
  }
  handleTaxTypeChange=(e)=>{
    this.setState({taxtype:e.target.value}) 
  }

  handleSoRecurringTimeChange=(e)=>{
    this.setState({sorecurringtime:e.target.value})
  }

  // handleinsurancereq = (e) => {
  //   this.setState({ insurancereq: !this.state.insurancereq })
  // }
  // handleInsuranceChange = (e)=>{
  //   this.setState({insurance:e.target.value===''?0:e.target.value,
  //     totalpricewtax: "",
  //   availabelcredit: this.state.creditvalue,
  // })

  // }

  handleDiscountChange=e=>{
    this.setState({
      discount: e.target.value,
      totalpricewtax: 0,
    })
    if (this.state.creditstatus !== "NotRequired") {
      if (this.state.availabelcredit < 0) {
        this.setState({ availabelcredit: this.state.creditvalue });
      } else {
      }
    }
  }
  handlemarkup1Change = (e)=>{
    this.setState({
      markup1: e.target.value,
      totalpricewtax: 0,
    })
    if (this.state.creditstatus !== "NotRequired") {
      if (this.state.availabelcredit < 0) {
        this.setState({ availabelcredit: this.state.creditvalue });
      } else {
      }
    }
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({loading:true})
    UserService.UserPermissions(userid).then((response) =>
      this.setState({ UserPermissions: response.data })
    );

    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>this.setState({usercustomers:res.data===''||res.data===undefined?[]:res.data.customers.split(",")}))

    this.refreshData()

  }


  handlecreatedAtDateChange = (date) => {
    this.setState({ customcreatedAt: date});
//alert(date)
    // alert(moment(date).format("yyyy-MM-DD HH:mm:ss"))
    this.setState({
      fed: date //moment(date).format("yyyy-MM-DD hh:mm:ss"),
    });
   

  };
  handleSDDate=()=>{
    this.setState({SDdate:this.state.SDdate===true?false:true})
    // customcreatedAt:new Date()
  }
  handleexpecteddeliveryChange = (date) => {
    this.setState({ expecteddelivery: date });
  };


  handleSliderChange = (event, newValue) => {
    this.setState({paymentterms:newValue})
  };

  handlepaymentterms = (event) => {
    this.setState({paymentterms: Number(event.target.value)})
  };

  handleBlur = () => {
    if (this.state.paymentterms < 0) {
     this.setState({paymentterms:0})
    } else if (this.state.paymentterms > 120) {
      this.setState({paymentterms:120})
    }
  };

  
  handleAdvanceAmountChange =(e)=>{
    this.setState({ advance: parseFloat(e.target.value)>(this.state.salestype ==='1'?this.state.servicecostwttax:this.state.grandtotal)?(this.state.salestype ==='1'?this.state.servicecostwttax:this.state.grandtotal):parseFloat(e.target.value), advancecnvt: (parseFloat(e.target.value)>(this.state.salestype ==='1'?this.state.servicecostwttax:this.state.grandtotal)) *this.state.curconversionrate });
  }

  handleAdvanceAmountcnvtChange=(e)=>{
    this.setState({ advancecnvt: parseFloat(e.target.value)>((this.state.salestype ==='1'?this.state.servicecostwttax:this.state.grandtotal)*this.state.curconversionrate)?(this.state.salestype ==='1'?this.state.servicecostwttax:this.state.grandtotal)*this.state.curconversionrate: parseFloat(e.target.value) ,advance:(parseFloat(e.target.value)>(this.state.salestype ==='1'?this.state.servicecostwttax:this.state.grandtotal))/this.state.curconversionrate });
  }


  handleSaleOrderFrom=(value)=>{
    this.setState({orderfrom:value,showfields:value===0?true:false,itemname: "", hsnid: "", hsn: "", quantity: "", cost: 0, type: "", uom: "", uomid: "",description: '', creditperiod: "", creditvalue: "", creditstatus: '', availabelcredit: "", initialCredit: "", gsttype: false, size: "",colour: "", thicknes: "", brand: "",model:"", totalmtrroll: "", service: "", serviceid: "", servicecategory: "", servicecatid: "", servicecode: "", servicetax: "", servicecost: 0, servicecostwttax: '',servicetaxamount:'',servicedescription: "", servicetotal: "", serviceerr: '', grandservicetotal: 0, categoryid: '', category: '', stock: "", tax: "", cgst: "", sgst: "",additionalparams: 0, taxamount: 0, totaltaxamount: 0, totalprice: 0, totaliv: 0, totalpricewtax: 0, grandtotal: 0,totaldiscount:0, listData: [],insurancereq:false,agent:'',agentid:'',quotation:""})
  }


  validate(values) {
    let errors = {};
    if (this.state.quantity === "") {
      errors.quantity = "Please Enter Quantity";
    }

    if ((this.state.soidforadjust !==undefined || this.state.soidforadjust !=="" || this.state.soidforadjust !== null) && this.state.preitemList.length>0  &&  this.state.avilablequantity < this.state.quantity) {
      errors.quantity = "Exceeds avilable quantity";
    }


    if (this.state.price === "") {
      errors.price = "Please Enter Price";
    }

    if(this.state.price<=0){
      errors.price = "Price Should be greater than 0";
    }

    if (this.state.type === "") {
      errors.type = "Please Select Type";
    }
    // if (this.state.quantity > this.state.stock) {
    //   errors.quantity = `Value Should be Lesserthan ${this.state.stock}`;
    // }

    if (this.state.customer === "") {
      errors.customer = "Please Select Customer";
    }

    if (this.state.whid === "") {
      errors.warehouse = "Please Select Org Unit";
    }

    if (this.state.category === "") {
      errors.category = "Please Select Category";
    }
    if (this.state.description === "" || this.state.description.length <3 ) {
      errors.description = "Please Enter minimum 3 Letters";
    }
    if (this.state.itemname === "" || this.state.itemname === undefined) {
      errors.itemname = "Please Select Item";
    }

    return errors;
  }

  onfocus() {
    document.getElementById('quantity').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('quantity').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  oncostfocus() {
    document.getElementById('cost').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('cost').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  onamountfocus() {
    document.getElementById('amount').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('amount').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  oncurrencyconvtfocus() {
    document.getElementById('standard-adornment-amount').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('standard-adornment-amount').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  onpricevariancefocus() {
    document.getElementById('pricevariance').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('pricevariance').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  ondiscountfocus(){
    document.getElementById('discount').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('discount').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  oninsurancefocus(){
    document.getElementById('insurance').addEventListener('mousewheel', function (event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('insurance').addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  render() {
    let {
      ordertype,
      quotation,
      agentsdata,
      agentreq,
      agent,
      agentid,
      id,
      soid,
      salestype,
      itemid,
      itemname,
      cost,
      type,
      hsn,
      hsnid,
      stock,
      quantity,
      totalpricewtax,
      tax,
      uom,
      uomid,
      customer,
      custom,
      custid,
      gsttype,
      itemList,
      cgst,
      sgst,
      UOMList,
      sizelist,
      colourlist,
      thicknesslist,
      thickness,
      brandslist,
      saleorderdata,
      preitemList,
      additionalparams,
      size,
      brand,
      model,
      colour,
      totalmtrroll,
      thicknes,
      description,
      filteritemlist,
      WarehouseList,
      warehouse,
      whid,
      departmentList,
      department,
      deptid,
      CategoryList,
      catid,
      category,
      discount,
      advance,
      advancecnvt,
      advancestatus,
      paymentterms,
      filterserv,
      serviceitemList,
      serviceList,
      service,
      serviceid,
      ServiceCategoryList,
      servicecategory,
      servicecatid,
      servicetax,
      servicecode,
      servicecost,
      servicecostconv,
      servicecgst,
      servicesgst,
      serviceigst,
      servicegst,
      servicevat,
      servicecostwttax,
      servicecostwttaxconv,
      servicetaxamount,
      servicedescription,
      servicetotal,
      grandservicetotal,
      serviceerr,
      contracttype,
      contracttypeList,
      recurringtype,
      contracttime,
      pricevariance,
      currencylist,
      convertionreq,
      currencyid,currency,curconversionrate,basecurrency,
      requestcredit,
      soidsforadjust,
      soidforadjust

    } = this.state;

    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.sloader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={-1}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/salesorder">Sales Order</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
           
            <div className="card-body">

            <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
              <Formik
                initialValues={{
                  ordertype,
                  quotation,
                  agentsdata,
                  agentreq,
                  agent,
                  agentid,
                  id,
                  salestype,
                  soid,
                  itemid,
                  itemname,
                  filteritemlist,
                  WarehouseList,
                  warehouse,
                  whid,
                  departmentList,
                  department,
                  deptid,
                  CategoryList,
                  category,
                  catid,
                  hsn,
                  hsnid,
                  cost,
                  type,
                  stock,
                  quantity,
                  uom,
                  uomid,
                  tax,
                  cgst,
                  sgst,
                  totalpricewtax,
                  custid,
                  customer,
                  gsttype,
                  custom,
                  itemList,
                  UOMList,
                  sizelist,
                  colourlist,
                  thicknesslist,
                  thickness,
                  brandslist,
                  saleorderdata,
                  preitemList,
                  additionalparams,
                  size,
                  brand,
                  model,
                  colour,
                  totalmtrroll,
                  thicknes,
                  description,
                  discount,
                  advance,
                  advancecnvt,
                  advancestatus,
                  paymentterms,
                  filterserv,
                  serviceitemList,
                  serviceList,
                  service,
                  serviceid,
                  ServiceCategoryList,
                  servicecategory,
                  servicecatid,
                  servicetax,
                  servicecode,
                  servicecost,
                  servicecostconv,
                  servicecgst,
                  servicesgst,
                  serviceigst,
                  servicegst,
                  servicevat,
                  servicecostwttax,
                  servicecostwttaxconv,
                  servicetaxamount,
                  servicedescription,
                  servicetotal,
                  grandservicetotal,
                  serviceerr,
                  contracttype,
                  contracttypeList,
                  recurringtype,
                  contracttime,
                  pricevariance,
                  currencylist,
                  convertionreq,
                  currencyid,currency,curconversionrate,basecurrency,
                  milestone:this.state.milestone,
                  requestcredit,
                  soidsforadjust,
                  soidforadjust
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                      {this.state.id === -1 && (custom===undefined || custom.length===0 || CategoryList===undefined || CategoryList.length===0)?
                        <fieldset className="form-group col-md-12 deletebg">
                          <ul className="py-3">
                            <li style={{listStyle: 'none'}}>Note:</li>
                            {custom===undefined || custom.length===0?
                            <li>No Customers are avilable</li>
                            :""}
                             {CategoryList===undefined || CategoryList.length===0?
                            <li>No Items are avilable</li>
                            :""} 
                          </ul>
                        </fieldset>
                        :''}

                      {this.state.pagename === "Edit Sale Order" ?'':<>
                      <fieldset className={`form-group col-md-3  shadow-sm text-center btn ${this.state.orderfrom === 0?'':' hovbuttonColour'}`} onClick={()=>this.handleSaleOrderFrom(0)} disabled={this.state.orderfrom === 0}>
                        New Sale Order   
                      </fieldset>
                      <fieldset className={`form-group col-md-3 shadow-sm btn text-center ${this.state.orderfrom === 1?'':' hovbuttonColour'}`} onClick={()=>this.handleSaleOrderFrom(1)} disabled={this.state.orderfrom === 1}>
                        Order From Quotation
                      </fieldset>
                      <fieldset className="form-group col-md-12 p-0 m-0"/>
                      {this.state.orderfrom === 1?<>
                      <fieldset className="form-group col-md-4 mb-4">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="quotation"
                                  options={this.state.quotationIdList}
                                  getOptionLabel={(option) => `${option.customqtid}`}
                                  value={values.quotation ? undefined : undefined}
                                  onChange={this.handleQuotation}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="quotation"
                                      value={this.state.quotation}
                                      label={`Select Quotation No. (${this.state.quotation})`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-12 p-0 m-0"/>
                              </>:''}


                      </>}
                      
                
                      {this.state.pagename === "Edit Sale Order" || this.state.orderfrom === 1  ?"": (
                        <>
                        {(this.state.settings.salesservice ==="NO" && this.state.settings.salespands==="NO")?"":
                          <fieldset className="form-group col-md-12 p-0 m-0">
                          <div className="form-row p-0 m-0">
                            <fieldset className="form-group col-md-4 p-0 m-0">
                              <span className="mx-3">Products</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.pro} onChange={this.handleProductChange} name="checkedB" />}
                              />
                            </fieldset>
                            {this.state.settings.salesservice ==="YES"?
                            <fieldset className="form-group col-md-4 p-0 m-0">
                              <span className="mx-3">Services</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.serv} onChange={this.handleServiceChange} name="checkedB" />}
                              />
                            </fieldset>:""}
                            {this.state.settings.salespands==="YES"?
                            <fieldset className="form-group col-md-4 p-0 m-0">
                              <span className="mx-3"> Products & Services</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.prosev} onChange={this.handleProSerChange} name="checkedB" />}
                              />
                            </fieldset>:""}
                          </div>
                        </fieldset>
                        }                              
                        </>
                      )}

{this.state.orderfrom === 1 && this.state.quotation===''?'':<>
                            <div className="form-group col-md-12 m-0 p-0" />


                            <div className="form-group col-md-12">
                              <Accordion >
                            
                              <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{backgroundColor: '#e8ecef'}}
                                >
                                  <Typography>Advance options</Typography>
                                </AccordionSummary>
                                   <AccordionDetails>
                                  
                                      <FieldArray
                                        name="addsettings"
                                        render={arrayHelpers => (
                                          <div className="form-group col-md-12">
                                            <div className="form-row">
                            {this.state.salestype === '1' ? '' : <>
                        
                            {this.state.pagename === "Edit Sale Order" || this.state.orderfrom === 1  ? (
                          ""
                        ) : (
                          <>
                            <fieldset className="form-group col-md-3 mt-2 m-0">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.ordertype}
                                      onChange={this.handleordertype}
                                      name="ordertype"
                                      color="primary"
                                    />
                                  }
                                  label="Order from previous Order?"
                                />
                              </FormGroup>
                            </fieldset>
                            {this.state.ordertype === true ? (
                              <>
                                <fieldset className="form-group col-md-3 m-0">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="saleorder"
                                    options={saleorderdata}
                                    getOptionLabel={(option) => option}
                                    
                                    
                                    value={values.saleorder ? undefined : undefined}
                                    onChange={(e, value) => {
                                      setFieldValue("saleorder", value);
                                    }}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="saleorder"
                                        onBlur={(e) => {
                                          this.handleSaleOrder(e.target.value);
                                        }}
                                        label="Select SaleOrder"
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3 m-0">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="preitemname"
                                    options={preitemList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    
                                    value={values.preitemname ? undefined : undefined}
                                    onChange={(e, value) => {
                                      setFieldValue("preitemname", value);
                                    }}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="preitemname"
                                        onBlur={(e) => {
                                          this.handlepreitem(e.target.value, values);
                                        }}
                                        label="Previous Order Item"
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        </>}
                        </div>
                        <div className="form-group col-md-12 m-0 p-0" />
                        <div className="form-row">
                        <fieldset
                            className="form-group col-md-3 mt-2 m-0"
                          >
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.convertionreq}
                                    onChange={this.handlemulticurrencyrequired}
                                    name="taxrequired"
                                    color="primary"
                                    disabled = {this.state.grandtotal !== 0 || this.state.grandservicetotal !== 0 || this.state.orderfrom ===1}
                                  />
                                }
                                label="Multi Currency Required?"
                              />
                            </FormGroup>
                          </fieldset>

                    {this.state.convertionreq === true?<>
                        <fieldset className="form-group col-md-2" >
                           <FormikTextField
                              className="form-control"
                              type="text"
                              value={this.state.basecurrency}
                              name="basecurrency"
                              label= "Base Currency"
                              variant="standard"
                              fullWidth
                              disabled
                            /> </fieldset>

                          <fieldset className="form-group col-md-2">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="currency"
                          options={currencylist}
                          getOptionLabel={(option) => option.currency}
                          
                          
                          value={values.currency?undefined:undefined}
                          onChange={this.handleCurrency}
                          disabled = {this.state.grandtotal !== 0 || this.state.grandservicetotal !== 0 || this.state.orderfrom===1 }
                          renderInput={params => (  
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="currency"
                              value={this.state.currency}
                              // onBlur={(e) =>{this.handleCurrency(e.target.value,values)}}
                              label={`Select Currency (${this.state.currency})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      
                      {this.state.currency === this.state.basecurrency|| this.state.currency === undefined || this.state.currency === ''?'':
                            <fieldset className="form-group col-md-2" >

                              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-amount">{`Enter Conversion rate`}</InputLabel>
                                        <Input
                                          id="standard-adornment-amount"
                                          value={this.state.curconversionrate}
                                          type="number"
                                          onChange={this.handleCurconversionrate}
                                          onFocus={this.oncurrencyconvtfocus}
                                          startAdornment={<InputAdornment position="start">1 {this.state.basecurrency} =</InputAdornment>}
                                          endAdornment={<InputAdornment position="end"> {this.state.currency}</InputAdornment>}
                                          disabled = {this.state.grandtotal !== 0 || this.state.grandservicetotal !== 0 || this.state.orderfrom===1 }
                                        />
                              </FormControl>
                            </fieldset>
                      }
                      </>:""}
                     </div>
                        <fieldset className="form-group col-md-12 m-0"/>
                       {/*  {(this.state.grandtotal === 0 && (this.state.itemname ==='' || this.state.service === '')) && this.state.orderfrom === 0?<> */}

                        </div>
                                        )}
                                      />
                                   
                                  </ AccordionDetails>
                              </Accordion>
                            </div>

                            

                        <fieldset className="form-group col-md-12 m-0" />
                        {this.state.currency === this.state.basecurrency|| this.state.currency === undefined || this.state.currency === ''?'':
                        <fieldset className="form-group col-md-12 m-0">
                          <label className="text-red">Note : Please Enter/Check Convertion rate in advance options.
                            </label>
                        </fieldset>}

                        <fieldset className="form-group col-md-12 m-0">
                        <div className="form-row">
                        <fieldset className="form-group col-md-3 mt-2 m-0">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.taxrequired}
                                      onChange={this.handletaxrequired}
                                      name="taxrequired"
                                      color="primary"
                                      disabled={(this.state.grandtotal !== 0 || (this.state.itemname !=='' || this.state.service !== '')) || this.state.orderfrom !== 0}
                                    />
                                  }
                                  label="Tax is Applicable?"
                                />
                              </FormGroup>
                            </fieldset>
                            {this.state.taxrequired === true?
                            <fieldset className="form-group col-md-3 m-0">
                                <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Tax Type :&nbsp; {this.state.taxtype}</InputLabel>
                                  {/* <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.taxtype}
                                    onChange={this.handleTaxTypeChange}
                                    label="Tax Type"
                                  >
                                    <MenuItem value={"GST"}>GST</MenuItem>
                                    <MenuItem value={"VAT"}>VAT</MenuItem>
                                  </Select> */}
                                </FormControl>
                                </fieldset>
                                :''
                              }

                          
                        {this.state.taxrequired=== true && this.state.taxtype === "GST" && ccountry === "India" && this.state.custid !==''  && this.state.custcountry==="India"? (
                              <fieldset className="form-group col-md-6 m-0">
                              <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">GST Type :&nbsp; {this.state.gsttype === false?'Intra State (With in the State)':'Inter State (Outside the State)'}</InputLabel>
                                  </FormControl>
                                {/* GST Type
                                {this.state.gsttype === false ? <span className="text-info">Intra State</span> : 'Intra State'} &nbsp; &nbsp;
                                {this.state.gsttype === true ? <span className="text-info"> Inter State</span> : 'Inter State'} */}
                                
                              </fieldset>
                        ) : (
                         ''
                        )}

                          </div>
                          </fieldset>
                       

                        <fieldset className="form-group col-md-3 m-0">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.agentreq}
                                        onChange={()=>this.setState({agentreq:!this.state.agentreq,agent:'',agentid:''})}
                                        name="ordertype"
                                        color="primary"
                                        disabled={this.state.orderfrom === 1}
                                      />
                                    }
                                    label="Through Agent?"
                                  />
                                </FormGroup>
                              </fieldset>
                              {this.state.agentreq === true?
                               <fieldset className="form-group col-md-3 mb-4 mr-4">
                               <Autocomplete
                                 id="subaccount"
                                 className="subacc-select"
                                 name="agent"
                                 options={agentsdata}
                                 getOptionLabel={(option) => option.name}
                                 
                                 
                                 value={values.agent ? undefined : undefined}
                                 onChange={ this.handleAgent}
                                 renderInput={(params) => (
                                   <FormikTextField
                                     {...params}
                                     className="form-control"
                                     type="text"
                                     name="agent"
                                     value={this.state.agent}
                                     label={`Select Agent (${this.state.agent})`}
                                     variant="standard"
                                     fullWidth
                                   />
                                 )}
                               />
                             </fieldset>
                              :''}
                               <fieldset className="form-group col-md-3">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Closing Stock:&nbsp;
                            {(this.state.inventorydata.length === 0? 0: this.state.inventorydata.reduce((a, v) => (a = a + v.currentStock), 0) - this.state.blockedsalesquantity + this.state.blockedpurchasequantity).toFixed(3)}
                          </InputLabel>
                        </FormControl>
                      </fieldset>
                            <div className="form-group col-md-12 m-0 p-0" />

                            {this.state.custid === "" || this.state.custid === undefined || this.state.custid === null || this.state.salestype !== '0'?'':<>

                            <fieldset className="form-group col-md-3 m-0">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.adjustfromprevso}
                                        onChange={()=>this.AdjustfromPreviousSaleorder()}
                                        name="ordertype"
                                        color="primary"
                                        disabled={this.state.orderfrom === 1}
                                      />
                                    }
                                    label="Adjust from previous sale order?"
                                  />
                                </FormGroup>
                              </fieldset>

                              {this.state.adjustfromprevso === true?<>
                               <fieldset className="form-group col-md-3 mb-4 mr-4">
                               <Autocomplete
                                 id="subaccount"
                                 className="subacc-select"
                                 name="soidsforadjust"
                                 options={soidsforadjust}
                                 getOptionLabel={(option) => option}
                                 
                                 
                                 value={values.soidforadjust ? undefined : undefined}
                                 onChange={this.SelectSO}
                                 renderInput={(params) => (
                                   <FormikTextField
                                     {...params}
                                     className="form-control"
                                     type="text"
                                     name="saleorder"
                                     value={this.state.soidforadjust}
                                     label={`Select sale order (${this.state.soidforadjust})`}
                                     variant="standard"
                                     fullWidth
                                   />
                                 )}
                               />
                              </fieldset>
                             
                             <div className="col-md-12"> </div>
                             {this.state.preitemList.length>0?
                             <>
                            <TableContainer>
                              <Typography>Previous sale order items</Typography>
                              <Table className="table-sm my-3">
                                <TableHead>
                                  <TableRow className="text-center">
                                  <TableCell></TableCell>
                                    <TableCell className="text-center">Itemname</TableCell>
                                    {this.state.taxrequired === true?<TableCell className="text-center">HSN Code</TableCell>:''}
                                    <TableCell className="text-center">Price</TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Price({this.state.currency})</TableCell>}
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Pricevariance (%)</TableCell>}
                                    <TableCell className="text-center">Sales Type</TableCell>
                                    <TableCell className="text-center">Quantity</TableCell>
                                    <TableCell className="text-center">UOM</TableCell>
                                    <TableCell className="text-center">Discount</TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Discount ({this.state.currency})</TableCell>}
                                    {this.state.taxrequired === true?<>
                                    {this.state.taxtype === "GST" && ccountry ==="India" ? this.state.gsttype === false ?
                                      (<><TableCell className="text-center">CGST</TableCell><TableCell className="text-center">SGST</TableCell>
                                      </>) 
                                      : (<><TableCell className="text-center">IGST</TableCell></>) :
                                      this.state.taxtype === "GST" ?<><TableCell className="text-center">GST</TableCell></>
                                      :<> <TableCell className="text-center">VAT</TableCell></>}
                                    </>:''}
                                    <TableCell className="text-center">Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.preitemList.map((data, index) => (
                                    <React.Fragment key={index}>
                                    <TableRow >
                                      
                                      <TableCell className="text-center">
                                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({open:!this.state.open})}>
                                          {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                      </TableCell>
                                      <TableCell className="text-center">{data.name}</TableCell>
                                       {this.state.taxrequired === true?<TableCell className="text-center">{data.hsn}</TableCell>:''}
                                       <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.price * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">{data.pricevariance}%</TableCell>}
                                       <TableCell className="text-center">{data.salesenum}</TableCell>
                                       <TableCell className="text-center">{data.pending}</TableCell>
                                       <TableCell className="text-center">{data.uom}</TableCell>
                                       <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.discountamount=== undefined||data.discountamount ===''?0:data.discountamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({data.discount === undefined||data.discount ===''?0:data.discount}%)</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.discountamount=== undefined||data.discountamount ===''?0:data.discountamount * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({data.discount === undefined||data.discount ===''?0:data.discount}%)</TableCell>}
                                       {this.state.taxrequired === true?<>
                                      {this.state.taxtype === "GST" &&  this.state.gsttype === false  ?
                                        (<>
                                         <TableCell className="text-right">{(data.tax / 2)}%</TableCell>
                                         <TableCell className="text-right">{(data.tax / 2)}%</TableCell>
                                        </>) : (<>
                                          <TableCell className="text-right">{(data.tax)}%</TableCell>
                                         
                                       </> )
                                        }
                                      </>:''}

                                       <TableCell className="text-center">
                                      
                                        <span
                                        className="text-info cursorPointer"
                                        style={{ paddingLeft: "7px" }}
                                        onClick={() => this.SelectItemFromSO(index)}
                                      >Select</span>
                                       
                                      </TableCell>
                                     
                                    </TableRow>  
                                     <TableRow>
                                     <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                       <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                         <Box margin={1}>
                                         <Table size="small" aria-label="purchases">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="center">Brand</TableCell>
                                              <TableCell align="center">Model</TableCell>
                                              <TableCell align="center">Colour</TableCell>
                                              <TableCell align="center">Size</TableCell>
                                              <TableCell align="center">Thickness</TableCell>
                                              <TableCell align="center">TotalMtrRoll</TableCell> 
                                              <TableCell align="center">Description</TableCell>
                                              
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              <TableRow>
                                                <TableCell component="th" scope="row" align="center">
                                                  {data.brand}
                                                </TableCell>
                                                <TableCell  align="center">{data.model===""?"NA":data.model}</TableCell>
                                                <TableCell  align="center">{data.colour===""?"NA":data.colour}</TableCell>
                                                <TableCell  align="center">{data.size===""?"NA":data.size}</TableCell>
                                                <TableCell  align="center">{data.thickness===""?"NA":data.thickness}</TableCell>  
                                                <TableCell  align="center">{data.totalmtrroll===""?"NA":data.totalmtrroll}</TableCell>
                                                <TableCell  align="center">{data.description===""?"NA":data.description}</TableCell>
                                              </TableRow>
                                          </TableBody>
                                        </Table>
                                         </Box>
                                       </Collapse>
                                     </TableCell>
                                   </TableRow>
                                   </React.Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>

                          </>
                                    :""}

                            </> :''}
                            </>}
                              <div className="form-group col-md-12 m-0 p-0" />



                        {this.state.salestype === '1' ? '' :<>
                                <fieldset className="form-group col-md-3">
                                  {WarehouseList.length===1?
                                  <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="warehouse"
                                  value={this.state.warehouse}
                                  label={`Select Org Unit`}
                                  variant="standard"
                                  fullwhidth="true"
                                  disabled
                                />:
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={WarehouseList}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={this.handleWarehouse}
                                    disabled={this.state.grandtotal!==0}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={this.state.warehouse}
                                        label={`Select Org Unit (${this.state.warehouse})`}
                                        variant="standard"
                                        fullwhidth="true"
                                        disabled={this.state.grandtotal!==0}
                                      />
                                    )}
                                  />
                                  }
                                </fieldset>
 <fieldset className="form-group col-md-3">
                                  {departmentList.length===1?
                                  <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="department"
                                  value={this.state.department}
                                  label={`Select Department`}
                                  variant="standard"
                                  fullwhidth="true"
                                  disabled
                                />:
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="department"
                                    options={departmentList}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                      values.department ? undefined : undefined
                                    }
                                    onChange={this.handleDepartment}
                                    disabled={this.state.grandtotal!==0}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="department"
                                        value={this.state.department}
                                        label={`Select Department (${this.state.department})`}
                                        variant="standard"
                                        fullwhidth="true"
                                        disabled={this.state.grandtotal!==0}
                                      />
                                    )}
                                  />
                                  }
                                </fieldset>
                              </>}
                        <fieldset className="form-group col-md-3 mb-4">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="customer"
                                  options={custom}
                                  getOptionLabel={(option) => option.companyname}
                                  value={values.customer ? undefined : undefined}
                                  onChange={ this.handleCustomer}
                                  disabled={this.state.grandtotal!==0}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="customer"
                                      value={this.state.customer}
                                      label={`Select Customer (${this.state.customer})`}
                                      variant="standard"
                                      disabled={this.state.grandtotal!==0}
                                      fullwhidth="true"
                                    />
                                  )}
                                />
                              </fieldset>

                              </>}
                     
                      {this.state.salestype === '1' || this.state.showfields === false  ? 
                      <>{this.state.orderfrom === 1 && (this.state.quotation !== null || this.state.quotation !== '' ) && this.state.salestype !== '1' ?<fieldset className="col-md-12"><button type="button" className="btn btn-sm hovbuttonColour my-auto" onClick={()=>this.setState({showfields:true})}>Add more items</button></fieldset>:""}</>
                      : <>
                        {this.state.ordertype === true ? (<>
                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="category"
                              value={this.state.category}
                              label="Category"
                              variant="standard"
                              fullWidth
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="itemname"
                              value={this.state.itemname}
                              label="Item"
                              variant="standard"
                              fullWidth
                              disabled
                            />
                          </fieldset>
                        </>
                        ) : (<>
                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="category"
                              options={CategoryList}
                              getOptionLabel={(option) => option.category}
                              value={values.category ? undefined : undefined}
                              onChange={this.handleCategory}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="category"
                                  value= {this.state.category}
                                  label={`Select Category (${this.state.category})`}
                                  variant="standard"
                                  fullwhidth="true"
                                />
                              )}
                            />
                          </fieldset>
                           <fieldset className="form-group col-md-3 ">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="itemname"
                              options={filteritemlist}
                              getOptionLabel={(option) => option.name}
                              
                              
                              value={values.itemname ? undefined : undefined}
                              onChange={this.handleitem}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="itemname"
                                  value={this.state.itemname}
                                  label={`Select Item (${this.state.itemname})`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>

                        </>
                        )}

                      <fieldset className="form-group col-md-3">
                      <FormikTextField
                        id="cost"
                        className="form-control"
                        type="text"
                        name="cost"
                        value={this.state.cost === "" ? 0 : this.state.cost}
                        onChange={(e) => this.setState({ cost:e.target.value === undefined || e.target.value === null || e.target.value<0 ?0:e.target.value, totalpricewtax: "", })}
                        label={`Price (Type: ${this.state.type})`}
                        variant="standard"
                        fullWidth
                        disabled ={this.state.settings.soeditprice === "NO"}
                      />
                    </fieldset>
                    {this.state.settings.allowextramakup ==="YES"?
                    <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="markup1"
                            className="form-control"
                            type="number"
                            name="markup1"
                            label={"Extra markup"}
                            min="0"
                            value={this.state.markup1}
                            onChange={this.handlemarkup1Change}
                          />
                        </fieldset>
                        :""}

                    {this.state.salescalreq==="YES" && this.state.settings.soeditprice === "YES"?

                    <fieldset className="form-group col-md-3">

                      <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.type}
                    onChange={this.handleTypeChange}
                    label="Type"
                    >
                    <MenuItem value={"Average"}>Average</MenuItem>
                    <MenuItem value={"FIFO"}>FIFO</MenuItem>
                    <MenuItem value={"LIFO"}>LIFO</MenuItem>
                    </Select>
                    </FormControl>
                    </fieldset>
                    :''}

                    <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="quantity"
                            className="form-control"
                            type="number"
                            name="quantity"
                            label={`Quantity (Available: ${this.state.stock === undefined || this.state.stock === null ? 0 : this.state.stock})`}
                            min="0"
                            onChange={(e) =>
                              this.setState({
                                quantity: e.target.value === undefined?0:e.target.value,
                                totalpricewtax: "",
                                availabelcredit: this.state.creditvalue,
                              })
                            }
                            onFocus={this.onfocus}
                          />
                        </fieldset>
                       
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="discount"
                            className="form-control"
                            type="number"
                            name="discount"
                            label={"Discount (%)"}
                            min="0"
                            value={this.state.discount}
                            onChange={this.handleDiscountChange}
                            onFocus={this.ondiscountfocus}
                          />
                        </fieldset>
                        {this.state.currency === this.state.basecurrency?'':
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="pricevariance"
                            className="form-control"
                            type="number"
                            name="pricevariance"
                            label={"Price variance in %"}
                            min="0"
                            onChange={(e) =>
                              this.setState({
                                pricevariance: e.target.value,
                                totalpricewtax: "",
                                availabelcredit: this.state.creditvalue,
                              })
                            }
                            onFocus={this.onpricevariancefocus}
                          />
                        </fieldset>
                        }
                        
                        {this.state.taxrequired === true?<>
                         <fieldset className="form-group col-md-3" >
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="hsn"
                                  value={this.state.hsn}
                                  label={`${taxtype==="VAT"?"Item Code":" HSN Code"}`}
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>
                              
                        {this.state.taxtype === "GST" && ccountry === "India" ? (
                          this.state.gsttype === false ? (
                            <>
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="cgst"
                                  value={this.state.cgst}
                                  label="CGST (%)"
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="sgst"
                                  value={this.state.sgst}
                                  label="SGST (%)"
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>
                            </>
                          ) : (
                            <>
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="tax"
                                  value={this.state.tax}
                                  label="IGST (%)"
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>
                            </>
                          )
                        ) : (
                          <>{this.state.taxtype === "GST"?
                          <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="tax"
                            value={this.state.tax}
                            label="GST (%)"
                            variant="standard"
                            fullWidth
                            disabled
                          />
                        </fieldset>:
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="number"
                                name="tax"
                                label="VAT (%)"
                                value={this.state.tax}
                                min="0"
                                disabled
                              />
                            </fieldset>
                            }
                           
                          </>
                        )}
                       </> :''}

                        <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="uom"
                                value={this.state.uom}
                                label="UOM"
                                variant="standard"
                                fullWidth
                                disabled
                              />
                        </fieldset>
                        <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="brand"
                              label="Brand"
                              value={this.state.brand}
                              disabled
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="model"
                              label="Model"
                              value={this.state.model}
                              disabled
                            />
                          </fieldset>
                        {this.state.additionalparams === 0 ? '' : <>
                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="size"
                              options={sizelist}
                              getOptionLabel={(option) => option}
                              
                              
                              value={values.size ? undefined : undefined}
                              onChange={this.handleSize}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="size"
                                  value={this.state.size}
                                  label={`Select Size (${this.state.size === null ||
                                    this.state.size === undefined
                                    ? ""
                                    : this.state.size
                                    })`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="colour"
                              options={colourlist}
                              getOptionLabel={(option) => option}
                              
                              
                              value={values.colour ? undefined : undefined}
                              onChange={this.handleColour}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="colour"
                                  value={this.state.colour}
                                  label={`Select Colour (${this.state.colour === null ||
                                    this.state.colour === undefined
                                    ? ""
                                    : this.state.colour
                                    })`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="thikness"
                              value={this.state.thickness}
                              onChange={(e) =>
                                this.setState({ thickness: e.target.value })
                              }
                              label={`Thickness (${this.state.thickness})`}
                              variant="standard"
                              fullWidth
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="totalmtrroll"
                              value={this.state.totalmtrroll}
                              onChange={(e) =>
                                this.setState({ totalmtrroll: e.target.value })
                              }
                              label="TotalMtrRoll"
                              variant="standard"
                              fullWidth
                            />
                          </fieldset>
                        </>}
                        <fieldset className="form-group col-md-12  mt-3 mb-5">
                          <FormikTextField name="description" className="form-control" multiline rows={1} label="Description/Specifications" variant="outlined" value={this.state.description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                          />
                        </fieldset>
                        {/* </div> */}

                        {/* <div className="form-group col-md-12 m-0 p-0" />
                            <fieldset className="form-group col-md-4 m-0">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.insurancereq}
                                      onChange={this.handleinsurancereq}
                                      name="ordertype"
                                      color="primary"
                                    />
                                  }
                                  label="Insurance Required?"
                                />
                              </FormGroup>
                            </fieldset>
                            {this.state.insurancereq === true?
                            <fieldset className="form-group col-md-2" >
                           <FormikTextField
                              className="form-control"
                              type="number"
                              id="insurance"
                              name="insurance"
                              onChange={this.handleInsuranceChange}
                              label={`Insurance (%)`}
                              value= {this.state.insurance}
                              variant="standard"
                              fullwhidth="true"
                              onFocus={this.oninsurancefocus}
                            />
                         </fieldset>
                         :""} */}
                        <div className="form-group col-md-12 m-0 p-0" />


                        <fieldset className="form-group col-md-3">
                          <>Total Price</>
                          <>
                            &nbsp; : &nbsp;
                            <NumericFormat  displayType={'text'} value= {this.state.totalpricewtax === "" ? 0 : parseFloat(this.state.totalpricewtax)} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                           
                          </>
                        </fieldset>
                        {this.state.creditstatus === "Required" ?<>
                          <fieldset className="form-group col-md-3">
                            <>Available Credit</>
                            <>
                              &nbsp; : &nbsp;{" "}
                              <NumericFormat  displayType={'text'} value={ this.state.availabelcredit === "" ||this.state.availabelcredit === undefined? 0: this.state.availabelcredit} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                            </>
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <>Credit Value</>
                            <>
                              &nbsp; : &nbsp;{" "}
                              <NumericFormat  displayType={'text'} value={ this.state.initialCredit === "" ||this.state.initialCredit === undefined? 0: this.state.initialCredit} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                            </>
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <>Credit Period</>
                            <>
                              &nbsp; : &nbsp;{" "}
                              {this.state.creditperiod === ""
                                  ? "0"
                                  : this.state.creditperiod}{" "}
                                days
                            </>
                          </fieldset>
                          </>
                          : ''}

                        <div className="form-group col-md-12 m-0 p-0" />


                        {this.state.availabelcredit < 0 ? (
                          <>
                            <p className="text-red col-md-12  m-0 p-0">
                            Execeded Credit Value, Requsest for credit
                            </p>

                            <fieldset className="form-group col-md-3" >
                           <FormikTextField
                              className="form-control"
                              type="number"
                              id="requestrridet"
                              name="requestrridet"
                              onChange={(e)=>this.setState({requestcredit:parseFloat(e.target.value)})}
                              label={`Enter Request Credit Amount`}
                              value= {this.state.requestcredit}
                              variant="standard"
                              fullwhidth="true"
                              error={this.state.requestcredit<=0}
                            />
                         </fieldset>
                         <div className="form-group col-md-12 m-0 p-0" />
                            <button
                              className="btn btn-sm hovbuttonColour"
                              onClick={this.handleRequestCredit}
                              type="button"
                            >
                              Request Credit Value
                            </button>
                          </>
                        ) : (
                          <>
                            {this.state.totalpricewtax === 0 ||
                              this.state.totalpricewtax === "" ? (
                              <button className="btn btn-sm hovbuttonColour" type="submit">
                                Calculate Total Price
                              </button>
                            ) : (
                              <Button
                                as="button"
                                name="addlist"
                                onClick={this.handleList}
                                // type="button"
                                className="btn btn-sm hovbuttonColour"
                              >
                                Add Item to Order
                              </Button>
                            )} 
                          </>
                        )}
                          </>}
                        <div className="form-group col-md-12" />
                        {this.state.grandtotal === 0 ||this.state.grandtotal === "" || this.state.salestype === '1'? (
                          ''
                        ) : (
                          <>
                            <TableContainer>
                              <Typography>Sale Order Items List</Typography>
                              <Table className="table-sm">
                                <TableHead>
                                  <TableRow className="text-center">
                                  <TableCell></TableCell>
                                  {this.state.listData.filter(e=>e.soidforadjust !==null && e.soidforadjust !==undefined && e.soidforadjust !=="").length>0?
                                  <TableCell className="text-center">SO Id</TableCell>
                                  :""}
                                    <TableCell className="text-center">Itemname</TableCell>
                                    {this.state.taxrequired === true?<TableCell className="text-center">HSN Code</TableCell>:''}
                                    <TableCell className="text-center">Price</TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Price({this.state.currency})</TableCell>}
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Pricevariance (%)</TableCell>}
                                    <TableCell className="text-center">Sales Type</TableCell>
                                    <TableCell className="text-center">Quantity</TableCell>
                                    <TableCell className="text-center">UOM</TableCell>
                                    <TableCell className="text-center">Discount</TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Discount ({this.state.currency})</TableCell>}
                                    {/* <TableCell className="text-center">Insurance</TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Insurance ({this.state.currency})</TableCell>} */}
                                    <TableCell className="text-center">Total </TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Total ({this.state.currency})</TableCell>}
                                    {this.state.taxrequired === true?<>
                                    {this.state.taxtype === "GST" && ccountry ==="India" ? this.state.gsttype === false ?
                                      (<><TableCell className="text-center">CGST</TableCell><TableCell className="text-center">SGST</TableCell>
                                         {this.state.currency === this.state.basecurrency?'':<> <TableCell className="text-center">CGST ({this.state.currency})</TableCell><TableCell className="text-center">SGST({this.state.currency})</TableCell></>}
                                      </>) 
                                      : (<><TableCell className="text-center">IGST</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">IGST({this.state.currency})</TableCell>}</>) :
                                      this.state.taxtype === "GST" ?<><TableCell className="text-center">GST</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">GST({this.state.currency})</TableCell>}</>
                                      :<> <TableCell className="text-center">VAT</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">VAT({this.state.currency})</TableCell>}</>}
                                    <TableCell className="text-center">Total Price</TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Total Price({this.state.currency})</TableCell>}
                                    </>:''}
                                    {/* {this.state.orderfrom === 1?'': */}
                                    <TableCell className="text-center">Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.listData.map((data, index) => (
                                    <React.Fragment key={index}>
                                    <TableRow >
                                      
                                      <TableCell className="text-center">
                                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({open:!this.state.open})}>
                                          {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                      </TableCell>
                                      {this.state.listData.filter(e=>e.soidforadjust !==null && e.soidforadjust !==undefined && e.soidforadjust !=="").length>0?
                                      <TableCell>{data?.soidforadjust===null|| data?.soidforadjust===""||data?.soidforadjust===undefined?"":data?.soidforadjust}</TableCell>
                                      :""}
                                      <TableCell className="text-center">{data.name}</TableCell>
                                       {this.state.taxrequired === true?<TableCell className="text-center">{data.hsn}</TableCell>:''}
                                       <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.price * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">{data.pricevariance}%</TableCell>}
                                       <TableCell className="text-center">{data.salesenum}</TableCell>
                                       <TableCell className="text-center">{data.quantity}</TableCell>
                                       <TableCell className="text-center">{data.uom}</TableCell>
                                       <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.discountamount=== undefined||data.discountamount ===''?0:data.discountamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({data.discount === undefined||data.discount ===''?0:data.discount}%)</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.discountamount=== undefined||data.discountamount ===''?0:data.discountamount * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({data.discount === undefined||data.discount ===''?0:data.discount}%)</TableCell>}
                                       {/* <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.insuranceamount === undefined||data.insuranceamount ===''?0:data.insuranceamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({data.insurance === undefined||data.insurance ===''?0:data.insurance}%)</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.insuranceamount === undefined||data.insuranceamount ===''?0:data.insuranceamount * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({data.insurance === undefined||data.insurance ===''?0:data.insurance}%)</TableCell>} */}
                                       <TableCell className="text-right"><NumericFormat  displayType={'text'} value={parseFloat(data.totalprice)} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalprice * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                       {this.state.taxrequired === true?<>
                                      {this.state.taxtype === "GST" &&  this.state.gsttype === false  ?
                                        (<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount*0.5} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         {this.state.currency === this.state.basecurrency?'':<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(data.taxamount/2)* this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(data.taxamount/2)* this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax / 2)}%)</TableCell>
                                         </>}
                                        </>) : (<>
                                          <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax)}%)</TableCell>
                                          {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.taxamount*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(data.tax)}%)</TableCell>}
                                       </> )
                                        }
                                      <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalpricewtax * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                      </>:''}

                                       <TableCell className="text-center">
                                       {this.state.orderfrom === 1?
                                        <span
                                        className="fa fa-edit textColour cursorPointer"
                                        style={{ paddingLeft: "7px" }}
                                        onClick={() => this.EditItemFromList(index)}
                                      ></span>:""}
                                       {data.id !== undefined && data.id !== null?"":
                                        <span
                                          className="fa fa-trash text-danger cursorPointer"
                                          style={{ paddingLeft: "7px" }}
                                          onClick={() => this.removeItemFromList(index)}
                                        ></span>}
                                      </TableCell>
                                     
                                    </TableRow>  
                                     <TableRow>
                                     <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                       <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                         <Box margin={1}>
                                         <Table size="small" aria-label="purchases">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="center">Brand</TableCell>
                                              <TableCell align="center">Model</TableCell>
                                              <TableCell align="center">Colour</TableCell>
                                              <TableCell align="center">Size</TableCell>
                                              <TableCell align="center">Thickness</TableCell>
                                              <TableCell align="center">TotalMtrRoll</TableCell> 
                                              <TableCell align="center">Description</TableCell>
                                              
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              <TableRow>
                                                <TableCell component="th" scope="row" align="center">
                                                  {data.brand}
                                                </TableCell>
                                                <TableCell  align="center">{data.model===""?"NA":data.model}</TableCell>
                                                <TableCell  align="center">{data.colour===""?"NA":data.colour}</TableCell>
                                                <TableCell  align="center">{data.size===""?"NA":data.size}</TableCell>
                                                <TableCell  align="center">{data.thickness===""?"NA":data.thickness}</TableCell>  
                                                <TableCell  align="center">{data.totalmtrroll===""?"NA":data.totalmtrroll}</TableCell>
                                                <TableCell  align="center">{data.description===""?"NA":data.description}</TableCell>
                                              </TableRow>
                                          </TableBody>
                                        </Table>
                                         </Box>
                                       </Collapse>
                                     </TableCell>
                                   </TableRow>
                                   </React.Fragment>
                                  ))}
                                  <TableRow
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <TableCell className="text-center">
                                      Total
                                    </TableCell>
                                    {this.state.listData.filter(e=>e.soidforadjust !==null && e.soidforadjust !==undefined && e.soidforadjust !=="").length>0?
                                    <TableCell></TableCell>
                                    :""}
                                    {this.state.taxrequired === true?<TableCell></TableCell>:''}
                                    {this.state.currency === this.state.basecurrency?'':<><TableCell/><TableCell/></>}
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                   
                                    <TableCell className="text-right"><NumericFormat  displayType={'text'} value={this.state.totaldiscount=== undefined||this.state.totaldiscount ===''?0:this.state.totaldiscount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={this.state.totaldiscount=== undefined||this.state.totaldiscount ===''?0:this.state.totaldiscount*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                    {/* <TableCell className="text-right"><NumericFormat  displayType={'text'} value={this.state.totalinsuranceamount === undefined||this.state.totalinsuranceamount ===''?0:this.state.totalinsuranceamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                    {this.state.currency === this.state.basecurrency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={this.state.totalinsuranceamount === undefined||this.state.totalinsuranceamount ===''?0:this.state.totalinsuranceamount*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>} */}
                                    <TableCell className="text-right">
                                    <NumericFormat  displayType={'text'} value={this.state.grandtotal - this.state.totaltaxamount-this.state.totalinsuranceamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                    </TableCell>
                                    {this.state.currency === this.state.basecurrency?'':
                                    <TableCell className="text-right">
                                    <NumericFormat  displayType={'text'} value={(this.state.grandtotal - this.state.totaltaxamount)*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                    </TableCell>
                                    }
                                    {this.state.taxrequired === true?<>
                                    {this.state.taxtype === "GST" && this.state.gsttype === false ?<>
                                    <TableCell className="text-right">
                                    <NumericFormat  displayType={'text'} value={this.state.totaltaxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                    </TableCell>
                                     <TableCell className="text-right">
                                     <NumericFormat  displayType={'text'} value={this.state.totaltaxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                     </TableCell>
                                     </>
                                    :
                                    <TableCell className="text-right">
                                    <NumericFormat  displayType={'text'} value={this.state.totaltaxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                    </TableCell>
                                    }
                                    {this.state.currency === this.state.basecurrency?'':<>
                                     {this.state.taxtype === "GST" && this.state.gsttype === false ?<>
                                     <TableCell className="text-right">
                                     <NumericFormat  displayType={'text'} value={(this.state.totaltaxamount/2)*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                     </TableCell>
                                      <TableCell className="text-right">
                                      <NumericFormat  displayType={'text'} value={(this.state.totaltaxamount/2)*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                      </TableCell>
                                     </>:
                                      <TableCell className="text-right">
                                      <NumericFormat  displayType={'text'} value={(this.state.totaltaxamount)*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                      </TableCell>
                                     }
                                   </> }
                                    <TableCell className="text-right">
                                      {this.state.grandtotal === ""
                                        ? "0"
                                        :  <NumericFormat  displayType={'text'} value={this.state.grandtotal} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>}
                                    </TableCell>
                                    {this.state.currency === this.state.basecurrency?'':
                                    <TableCell className="text-right">
                                      {this.state.grandtotal === ""
                                        ? "0"
                                        :  <NumericFormat  displayType={'text'} value={this.state.grandtotal*this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>}
                                    </TableCell>
                                    }
                                    </>:''}
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>

                          </>
                        )}
                    </div>
                    <div className="form-group col-md-12" />

                    {this.state.salestype !== '0' && this.state.orderfrom ===0 ?
                      <div className="form-row">
                        { this.state.orderfrom === 1?'':<>
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="servicecategory"
                            options={ServiceCategoryList}
                            getOptionLabel={(option) => option.category}
                            value={
                              values.servicecategory ? undefined : undefined
                            }
                            onChange={this.handleServicecategory}
                           
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="servicecategory"
                                value={this.state.servicecategory}
                                label={`${ this.state.servicecategory==='' || this.state.servicecategory===undefined ?`Select Service Category (${this.state.servicecategory})`:this.state.servicecategory}`}
                                variant="standard"
                                fullwhidth="true"
                              />
                            )}
                          />
                        </fieldset>
                       
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="service"
                            options={filterserv}
                            getOptionLabel={(option) => option.service}
                            value={values.service ? undefined : undefined}
                            onChange={this.handleService}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="service"
                                value={this.state.service}
                                label={`${ this.state.service==='' || this.state.service===undefined ?`Select Service (${this.state.service})`:this.state.service}`}
                                variant="standard"
                                fullwhidth="true"
                              />
                            )}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-3" >
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="servicecode"
                            label="Code"
                            value={this.state.servicecode}
                            disabled
                          />
                        </fieldset>
                          
                        
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="amount"
                            className="form-control"
                            type="number"
                            name="servicecost"
                            label="Amount"
                            onChange={this.handleServiceAmountChange}
                            onFocus={this.onamountfocus}
                          />
                        </fieldset>
                        {this.state.currency === this.state.basecurrency?'':
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="amount"
                            className="form-control"
                            type="number"
                            name="servicecostconv"
                            label={`Amount in ${this.state.currency}`}
                            value={this.state.servicecostconv}
                            disabled
                          />
                        </fieldset>
                          }
                        {this.state.taxrequired === true?<>
                        {this.state.taxtype === "GST" && ccountry ==="India"?<>

                        {this.state.gsttype === false ?<>
                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="servicecgst"
                                              label={`CGST (${this.state.servicetax===''?0:this.state.servicetax/2}%)`}
                                              value={this.state.servicecgst}
                                              disabled
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="servicesgst"
                                              label={`SGST (${this.state.servicetax===''?0:this.state.servicetax/2}%)`}
                                              value={this.state.servicesgst}
                                              disabled
                                            />
                                          </fieldset>
                        </>
                        :
                        <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="serviceigst"
                          label={`IGST (${this.state.servicetax===''?0:this.state.servicetax}%)`}
                          value={this.state.serviceigst}
                          disabled
                        />
                      </fieldset>
                         }
                        </>
                        :
                        this.state.taxtype === "GST"?
                        <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="servicegst"
                          label={`GST (${this.state.servicetax===''?0:this.state.servicetax}%)`}
                          value={this.state.servicegst}
                          disabled
                        />
                      </fieldset>
                      :
                      <fieldset className="form-group col-md-3">
                      <FormikTextField
                        className="form-control"
                        type="number"
                        name="servicevat"
                        label={`VAT (${this.state.servicetax===''?0:this.state.servicetax}%)`}
                        value={this.state.servicevat}
                        disabled
                      />
                    </fieldset>
                       }


                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="servicecostwttax"
                            label="Total Amount"
                            value={this.state.servicecostwttax}
                            disabled
                          />
                        </fieldset>
                        
                        {this.state.currency === this.state.basecurrency?'':
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="servicecostwttaxconv"
                            label={`Total Amount in ${this.state.currency}`}
                            value={this.state.servicecostwttaxconv}
                            disabled
                          />
                        </fieldset>
                        }
                        </>:''}
                        </>}
                        {this.state.salestype === '1' ? <>

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="contracttype"
                              options={contracttypeList}
                              getOptionLabel={(option) => option}
                              value={values.contracttype ? undefined : undefined}
                              onChange={(e, value) => {
                                if(value !== null && value !== undefined && contracttypeList.includes(value)){
                                setFieldValue("contracttype", value)
                                this.setState({ contracttype: value })
                              }else{
                                setFieldValue("contracttype", '')
                                this.setState({ contracttype: '' })
                              }
                              }}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="contracttype"
                                  value={this.state.contracttype}
                                  label={`${ this.state.contracttype==='' || this.state.contracttype===undefined ?`Select Contract Type (${this.state.contracttype})`:this.state.contracttype}`}
                                  variant="standard"
                                  fullwhidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          {this.state.contracttype === 'Recurring' ? <>

                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="contracttime"
                                options={recurringtype}
                                getOptionLabel={(option) => option}
                                
                                
                                value={values.contracttime ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("contracttime", value);
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="contracttime"
                                    onBlur={(e) => {
                                      this.setState({ contracttime: e.target.value })
                                    }}
                                    label="Select Contract Time"
                                    variant="standard"
                                    fullwhidth="true"
                                  />
                                )}
                              />
                            </fieldset>
                            <fieldset className="mr-4">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container justifyContent="space-around">
                                  <DatePicker
                                    variant="inline"
                                    margin="none ml-2"
                                    id="date-picker-dialog"
                                    label="Recurring Till"
                                    format="dd/MM/yyyy"
                                    minDate={new Date()}
                                    value={this.state.contractstartdate}
                                    onChange={this.handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset>
                          </> : ''}
                          <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField name="servicedescription" className="form-control" multiline rows={2} label="Description" variant="outlined" value={this.state.servicedescription}
                              onChange={(e) => this.setState({ servicedescription: e.target.value })}
                            />
                          </fieldset>
                          {this.state.contracttype === 'Milestone' ?
                            <div className="form-group col-md-12">
                              <Accordion expanded={true}>
                                    <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{backgroundColor: '#e8ecef'}}
                                >
                                  <Typography>Milestones</Typography>
                                </AccordionSummary>
                                   <AccordionDetails>
                                  
                                      <FieldArray
                                        name="milestone"
                                        render={arrayHelpers => (
                                          <div className="form-group col-md-12">
                                            {values.milestone.map((data, index) => (
                                              <div key={index} className="form-row">
                                                {/** both these conventions do the same */}
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField className="form-control" name={`milestone[${index}].name`} label="Name" variant="standard"  fullwhidth="true" />
                                                </fieldset>
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField className="form-control" id="totalprice" type="number" min={1} max={100} name={`milestone.${index}.percentage`} label="Percentage (%)" variant="standard" 
                                                    onBlur={() => setFieldValue(`milestone.${index}.totalpricewtax`, (data.percentage / 100) * (parseFloat(this.state.servicecost) + ((servicetax / 100) * parseFloat(this.state.servicecost)))) &&
                                                      setFieldValue(`milestone.${index}.totalprice`, (data.percentage / 100) * (parseFloat(this.state.servicecost)))} fullwhidth="true" />
                                                </fieldset>
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField className="form-control" type="number" name={`milestone.${index}.totalprice`} label="Amount" variant="standard" fullwhidth="true" disabled></FormikTextField>
                                                </fieldset>
                                                {this.state.taxrequired === true?
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField className="form-control" type="number" name={`milestone.${index}.totalpricewtax`} label="Amount inc.Tax" variant="standard" fullwhidth="true" disabled></FormikTextField>
                                                </fieldset>:''}
                                                <fieldset className="form-group col-md-3">
                                                  <FormikTextField
                                                    id="date"
                                                    label="Expected Date"
                                                    name={`milestone.${index}.expecteddate`}
                                                    type="date"
                                                    minDate={new Date()}
                                                    className={useStyles.textField}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                                </fieldset>
                                                {values.milestone.length !== 1 ?
                                                  <span  className="fa fa-minus-square text-danger mt-4 cursorPointer" onClick={() => arrayHelpers.remove(index)}></span>
                                                  : ''
                                                }
                                                <span  className="fa fa-plus-square text-info mt-4 ml-3 cursorPointer" onClick={() => arrayHelpers.push({ name: '', expecteddate: '', percentage: 0, totalprice: 0, totalpricewtax: 0 })}></span>
                                              </div>
                                            ))}
                                            <p className={`${this.state.percentagemessage === 'Milestones Saved Scussesfully' ? 'text-green' : 'text-danger'}`}>{this.state.percentagemessage}</p>
                                            <span as="button" className="btn btn-sm float-right cursorPointer" onClick={() => this.handlemilestonevalues(values.milestone)} ><i className={`${this.state.milestonesavebtn === false ? 'fa fa-save' : 'fa fa-check text-green'} mr-1`} />Save</span>

                                          </div>
                                        )}
                                      />
                                </AccordionDetails>
                              </Accordion>
                              {this.state.milestone.length === 0 ? '' :
                                 <TableContainer>
                                 <Table className="table-sm mt-4">
                                   <TableHead><TableRow>MileStones</TableRow></TableHead>
                                   <TableHead className="text-sm">
                                     <TableRow style={{ textAlign: "center" }}>
                                       <TableCell className="text-center">Name</TableCell>
                                       <TableCell className="text-center">Expected Date</TableCell>
                                       <TableCell className="text-center">Percentage (%)</TableCell>
                                       <TableCell className="text-center">Amount</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Amount ({this.state.currency})</TableCell>}
                                       <TableCell className="text-center">Amount Inc.Tax</TableCell>
                                       {this.state.currency === this.state.basecurrency?'':<TableCell className="text-center">Amount Inc.Tax ({this.state.currency})</TableCell>}
                                     </TableRow>
                                   </TableHead>
                                   <TableBody>
                                     {this.state.milestone.map((data, index) => (
                                       <TableRow key={index}>
                                         <TableCell className="text-center">{data.name}</TableCell>
                                         <TableCell className="text-center">{moment(data.expecteddate).format('DD-MM-YYYY')}</TableCell>
                                         <TableCell className="text-center">{data.percentage} %</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalprice} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                         {this.state.currency === this.state.basecurrency?'': <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalprice * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                         {this.state.currency === this.state.basecurrency?'': <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.totalpricewtax * this.state.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                       </TableRow>
                                     ))}
                                   </TableBody>
                                 </Table>
                               </TableContainer>
                              }
                            </div>
                            : ''}
                          <fieldset className="form-group col-md-12" />
                        </>
                          : ''}

                        {this.state.salestype === '1' ? '' : <>
                          <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField name="servicedescription" className="form-control" multiline rows={2} label="Description" variant="outlined" value={this.state.servicedescription}
                              onChange={(e) => this.setState({ servicedescription: e.target.value })}
                            />
                          </fieldset>
                          <span
                            className="btn btn-sm hovbuttonColour m-0 cursorPointer"
                            onClick={this.handleaddService}
                          >
                            Add Service
                          </span>

                          <div className="form-group col-md-12 text-red text-sm">
                            {this.state.serviceerr}
                          </div>
                          {this.state.serviceList.length === 0 ? '' :
                            <TableContainer>
                              <Table className="table-sm">
                                <TableHead>
                                  <TableRow style={{ textAlign: "center" }}>
                                    <TableCell>Service</TableCell>
                                    {/* <TableCell>Service Category</TableCell> */}
                                    <TableCell>Service Code</TableCell>
                                    <TableCell>Service Description</TableCell>
                                    <TableCell>Service Cost</TableCell>
                                    {this.state.taxrequired===true?<>
                                    {this.state.taxtype === "GST" && ccountry ==="India" ? this.state.gsttype === false ?
                                      (<TableCell>CGST(%)</TableCell>) : (<TableCell>IGST</TableCell>) :
                                      this.state.taxtype === "GST" ?<TableCell>GST</TableCell>: <TableCell>VAT</TableCell>}
                                    {this.state.gsttype === false ? (<TableCell>SGST(%)</TableCell>) : ""}
                                    </>:''}
                                    <TableCell>Service Total</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.serviceList.map((data, index) => (
                                    <TableRow
                                      key={index}
                                      style={{ textAlign: "center" }}
                                    >
                                      <TableCell>{data.service}</TableCell>
                                      {/* <TableCell>{data.servicecategory}</TableCell> */}
                                      <TableCell>{data.servicecode}</TableCell>
                                      <TableCell>
                                        {data.servicedescription}
                                      </TableCell>
                                      <TableCell><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled={true} value={data.servicecost} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      {this.state.taxrequired===true?<>
                                      {this.state.taxtype === "GST" && ccountry ==="India" && this.state.gsttype === false ?<><TableCell>{data.servicetax/2} %</TableCell><TableCell>{data.servicetax/2} %</TableCell></>:<TableCell>{data.servicetax} %</TableCell>}
                                      </>:''}
                                      
                                      <TableCell><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled={true} value={data.servicetotal} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      <TableCell>
                                        <span
                                          className="fa fa-trash text-danger cursorPointer"
                                          style={{ paddingLeft: "7px" }}
                                          onClick={() =>
                                            this.removeServiceFromList(index)
                                          }
                                        ></span>
                                      </TableCell>
                                    
                                    </TableRow>
                                  ))}
                                  <TableRow style={{ textAlign: "center" }}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      {this.state.grandservicetotal === ""
                                        ? 0
                                        : <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled={true} value={this.state.grandservicetotal} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>}
                                    </TableCell>
                                   <TableCell></TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          }
                        </>}
                      </div>
                      :<>
                      {this.state.serviceList.length === 0 ? '' :
                      <TableContainer>
                      <Table className="table-sm">
                        <TableHead>
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell>Service</TableCell>
                            {/* <TableCell>Service Category</TableCell> */}
                            <TableCell>Service Code</TableCell>
                            <TableCell>Service Description</TableCell>
                            <TableCell>Service Cost</TableCell>
                            {this.state.taxrequired===true?<>
                            {this.state.taxtype === "GST" && ccountry ==="India" ? this.state.gsttype === false ?
                              (<TableCell>CGST(%)</TableCell>) : (<TableCell>IGST</TableCell>) :
                              this.state.taxtype === "GST" ?<TableCell>GST</TableCell>: <TableCell>VAT</TableCell>}
                            {this.state.gsttype === false ? (<TableCell>SGST(%)</TableCell>) : ""}
                            </>:''}
                            <TableCell>Service Total</TableCell>
                             {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"?<>
                           <TableCell className="text-center">Recurring Type</TableCell>
                           <TableCell className="text-center">Recurring End Date</TableCell>
                           </>:''}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.serviceList.map((data, index) => (
                            <TableRow
                              key={index}
                              style={{ textAlign: "center" }}
                            >
                              <TableCell>{data.service}</TableCell>
                              {/* <TableCell>{data.servicecategory}</TableCell> */}
                              <TableCell>{data.servicecode}</TableCell>
                              <TableCell>
                                {data.description}
                              </TableCell>
                              <TableCell><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled={true} value={data.servicecost} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                              {this.state.taxrequired===true?<>
                              {this.state.taxtype === "GST" && ccountry ==="India" && this.state.gsttype === false ?<><TableCell>{data.servicetax/2} %</TableCell><TableCell>{data.servicetax/2} %</TableCell></>:<TableCell>{data.servicetax} %</TableCell>}
                              </>:''}
                              
                              <TableCell><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled={true} value={data.servicetotal} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                             
                             {data.contracttype === "Recurring"?<>
                             <TableCell className="text-center">{data.recurringtype}</TableCell>
                             <TableCell className="text-center">{moment(data.recurrencetill).format('DD-MM-YYYY')}</TableCell>
                             </>:''}
                            </TableRow>
                          ))}
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {this.state.grandservicetotal === ""
                                ? 0
                                : <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled={true} value={this.state.grandservicetotal} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>}
                            </TableCell>
                            {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <><TableCell/><TableCell/></>:''}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>        
                  }
                  { this.state.contracttype === "Milestone" ?
                   this.state.milestone.length === 0?'':
                                 <TableContainer>
                                 <Table className="table-sm mt-4">
                                   <TableHead><TableRow>MileStones</TableRow></TableHead>
                                   <TableHead className="text-sm">
                                     <TableRow style={{ textAlign: "center" }}>
                                       <TableCell className="text-center">Name</TableCell>
                                       <TableCell className="text-center">Expected Date</TableCell>
                                       <TableCell className="text-center">Percentage (%)</TableCell>
                                       <TableCell className="text-center">Amount</TableCell>
                                     </TableRow>
                                   </TableHead>
                                   <TableBody>
                                     {this.state.milestone.map((data, index) => (
                                       <TableRow key={index}>
                                         <TableCell className="text-center">{data.name}</TableCell>
                                         <TableCell className="text-center">{moment(data.expecteddate).format('DD-MM-YYYY')}</TableCell>
                                         <TableCell className="text-center">{data.percentage} %</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={data.amount} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                       </TableRow>
                                     ))}
                                   </TableBody>
                                 </Table>
                               </TableContainer>
                              :''}
                  </>}

                  {this.state.orderfrom === 1 && this.state.quotation===''?'':
                    <div className="form-row mt-2">

<fieldset className="form-group col-md-3 col-sm-12">
          {/* <span className="mx-3"></span> */}
          {/* <FormControlLabel
            control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}
          /> */}
                          <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.SDdate}
                                        onChange={this.handleSDDate}
                                        name="ordertype"
                                        color="primary"
                                      />
                                    }
                                    label="SO creation date"
                                  />
                                </FormGroup>
        </fieldset>
   {this.state.SDdate?
<fieldset className="form-group col-md-3 col-sm-12" >
  {/* <div className="form-row mr-4 mb-4"> */}
    {/* <fieldset style={{ marginLeft: "30px" }}> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid  justifyContent="space-around">
          <DateTimePicker
           disableFuture
           showtodaybutton
           showcancelbutton
          //  views={["year", "month","date"]}
            variant="inline"
            id="date-picker-dialog"
           // label="Financial Year Start Date"
            // inputVariant="outlined"
            minDate={new Date(moment(transactionFrom).format("YYYY-MM-DD"))}
            maxDate={new Date(moment().format("YYYY-MM-DD"))}
            
            label={`SO Creation Date : ${this.state.SDdate?"Custom":"Default"}`}
            format="yyyy-MM-DD HH:mm:ss"
            value={this.state.customcreatedAt}
             InputAdornmentProps={{ position: "start" }}
            onChange={this.handlecreatedAtDateChange}
            renderInput={(params) => <TextField {...params} variant="standard"/>}
            autoOk={true}
            fullWidth
            disabled={this.state.SDdate===false}
          />
        </Grid>
      </LocalizationProvider>
    </fieldset>
    :''}
                      {/* Payment Terms : */}
                      <fieldset className="form-group col-md-3 col-sm-12">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Grid container>
                                        <DatePicker
                                          variant="inline"
                                          id="date-picker-dialog"
                                          label="Expected Delivery Date"
                                          format="dd/MM/yyyy"
                                          minDate={new Date()}
                                          value={this.state.expecteddelivery}
                                          onChange={this.handleexpecteddeliveryChange}
                                          renderInput={(params) => <TextField {...params} variant="standard"/>}
                                          InputAdornmentProps={{ position: "start" }}
                                          autoOk={true}
                                          fullWidth
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <FormControl
                                      sx={{ m: 1, minWidth: 120 }}
                                      className="col-md-12"
                                    >
                                      <InputLabel id="demo-simple-select-filled-label" className="ml-0">
                                        Select grace period
                                      </InputLabel>
                                      <Select
                                        value={this.state.graceperiod}
                                        onChange={(e) =>
                                          this.setState({
                                            graceperiod: e.target.value,
                                          })
                                        }
                                        variant="standard"
                                      >
                                        {this.state.days.map((option) => {
                                          return (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </fieldset>

                      <fieldset className="form-group col-md-4 text-md">
                        <Box sx={{ width: 250 }}>
                        <Typography id="input-slider" gutterBottom className="d-flex">
                          <fieldset className="form-group col-md-6 text-md"> Payment Terms : </fieldset>
                          <fieldset className="form-group col-md-6 text-md">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="paymentterms"
                              onChange={(e)=>this.setState({paymentterms:parseInt(e.target.value)>120?120:parseInt(e.target.value)})}
                              label="Days"
                              variant="standard"
                            />
                            </fieldset>
                          </Typography>
                          <Grid container spacing={2} alignItems="center">
                            {/* <Grid item>
                              <VolumeUp />
                            </Grid> */}
                            <Grid item xs>
                              <Slider
                                value={typeof this.state.paymentterms === 'number' ? this.state.paymentterms : 0}
                                onChange={this.handleSliderChange}
                                aria-labelledby="input-slider"
                                min={0}
                                max={120}
                                style={{ color: 'darkcyan'}}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </fieldset>
                     
                      {this.state.paymentterms === "0" ? (
                        ""
                      ) : <>
                        <fieldset className="form-group col-md-3">
                          <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.advancestatus}
                                        onChange={this.handleadvancestatus}
                                        name="ordertype"
                                        color="primary"
                                      />
                                    }
                                    label="Advance Payment Required?"
                                  />
                                </FormGroup>
                        </fieldset>
                        {this.state.advancestatus === true ?<>
                          <fieldset className="form-group col-md-2">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="advance"
                              onChange={(e)=>this.handleAdvanceAmountChange(e)}
                              label="Advance Amount"
                              variant="standard"
                              fullwhidth="true"
                            />
                          </fieldset>
                           {this.state.currency === this.state.basecurrency?'':
                           <fieldset className="form-group col-md-2" >
                           <FormikTextField
                              className="form-control"
                              type="number"
                              name="advancecnvt"
                              onChange={(e)=>this.handleAdvanceAmountcnvtChange(e)}
                              label={`Advance Amount in (${this.state.currency})`}
                              value= {this.state.advancecnvt}
                              variant="standard"
                              fullwhidth="true"
                            />
                         </fieldset>
                        }
                         </> : ""
                        }
                      </>
                      }
                    </div>
                      }

                    {this.state.salestype === '1' ?
                      
                        <button className="btn btn-sm hovbuttonColour col-md-auto col-sm-12 mr-1" 
                          disabled = {(this.state.customer === '' || this.state.servicecategory === '' || this.state.service === '' || this.state.servicecost === ''|| this.state.sloader === true || (this.state.contracttype === 'Milestone' && this.state.milestone.length === 0)) && this.state.orderfrom === 0}
                          onClick={this.handleSubmit}
                          type="button"
                        >
                           {this.state.sloader === true ? <><Spinner animation="border" size="sm" className="mr-1" />{"Saving..."} </> : <><i className="fa fa-save mr-1" /> {"Save"}</>}
                        </button>
                    
                      : <>
                        {this.state.salestype === '2' ?
                          
                                <button
                                  className="btn btn-sm hovbuttonColour col-md-auto col-sm-12 mr-1"
                                  onClick={this.handleSubmit}
                                  type="button"
                                  disabled = {this.state.grandtotal === 0 || this.state.sloader === true }
                                >
                                  {this.state.sloader === true ? <><Spinner animation="border" size="sm" className="mr-1" />{"Saving..."} </> : <><i className="fa fa-save mr-1" /> {"Save"}</>}
                                </button>
                         
                          : 
                           
                                <button
                                  className="btn btn-sm hovbuttonColour col-md-auto col-sm-12 mr-1"
                                  onClick={this.handleSubmit}
                                  type="button"
                                  disabled = {this.state.grandtotal === 0 || this.state.sloader === true}
                                >
                                  {this.state.sloader === true ? <><Spinner animation="border" size="sm" className="mr-1" />{"Saving..."} </> : <><i className="fa fa-save mr-1" /> {"Save"}</>}
                                </button>
                              
                            
                          }
                      </>}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton col-md-auto col-sm-12"
                      to={{ pathname: "/salesorder" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                    <fieldset className="form-group col-md-12"/>
                    <fieldset className="form-group col-md-12">
                          <label className="text-red">Note : If any selection is wrong or with 
                          invalid characters, please cancel the selection with "x" button in selection and reselect the Field.
                            </label>
                </fieldset>

                  </Form>
                )}
              </Formik>
                        )}
                        </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(NewSalesOrder);
