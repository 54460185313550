import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { NumericFormat } from "react-number-format";
import {
  currentTenant,
  currentUser,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  transactionFrom,
  userid,
} from "../Common/Credentials";
import moment from "moment";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Grid,
  FormLabel,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import NewJournalService from "./NewJournalService";
import AuthenticationService from "../Authentication/AuthenticationService";
import { Spinner } from "react-bootstrap";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import { spin } from "../Common/NewLoader";
import Footer from "../Component/Footer";
import { FormikTextField } from "../CommonComponents/FormField";
import { Autocomplete } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { assets, combdata, directtax, expcombdata, indirecttax, reserveasset, typeofentry } from "./Journal.jsx";
import Swal from "sweetalert2";

let jvtype = 9,taxbehav = 0,indtaxtype,taxreqchecked = 0,taxchecked=false
class MultiEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabledata: [],
      debdata:[],
      taxdata:[],
      creditdata:[],
      ResEntry:0,
      loading: false,
      fsd:"",
      fed: "",
      SDdate:false,
      taxingtype: [
        { id: 1, name: "Direct" },
        { id: 2, name: "Indirect" },
      ],
      taxreq: [
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ],
      tdsreq: [
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ],
      tcschecked:0,
      taxchecked: false,
      taxreqchecked:0,
      showtax2:false,
      debit:"",
      debitval:0,
      tax1:"",
      tax1val:0,
      tax1per:0,
      tax2:"",
      tax2val:0,
      tax2per:0,
      tds:"",
      tdsper:0,
      tdsval:0,
      credit:"",
      creditval:0,
      narration:"",
      jvinsertiontype:25
    };
  }
  componentDidMount() {
    taxbehav=0
    let debval1=expcombdata.concat(reserveasset)
    let  val2 = combdata.filter((e)=>e.subheadid!=="AH000020" || e.subheadid!=="AH000021" || e.subheadid!=="AH000022" && e.taxtype!==2 && e.taxtype!==1);
    this.setState({debdata:debval1,creditdata:val2,taxdata:indirecttax})

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);
    }
  }

  handleSDDate = () =>{
    this.setState({SDdate:!this.state.SDdate})
  }
  handleRESEntry= () => {
    let debval1=expcombdata.concat(reserveasset)
    this.setState({ ResEntry: this.state.ResEntry === 0 ? 1 : 0 },()=>
      this.state.ResEntry===0?
      this.setState({debdata:debval1}):this.setState({debdata:assets.filter((e)=>e.type===2)})
    );
  };
  
  onAddData = (values) =>{
    if(this.state.debit === "" || (this.state.taxreqchecked === 1 && this.state.taxchecked && this.state.tax1==="") || 
    (this.state.taxreqchecked === 1 && this.state.taxchecked && this.state.showtax2 && this.state.tax2==="")
    ){}else{
    let total =  parseFloat(this.state.debitval)
       +  (this.state.taxreqchecked === 1 && this.state.taxchecked? (parseFloat(this.state.tax1val) + (this.state.showtax2?parseFloat(this.state.tax2val):0)):0)
    let data = {
      debit:this.state.debit,
      debitval:this.state.debitval,
      tax1:this.state.tax1,
      tax1per:this.state.tax1per,
      tax1val:this.state.tax1val,
      tax2:this.state.tax2,
      tax2per:this.state.tax2per,
      tax2val:this.state.tax2val,
      tdsper:0,
      tdsval:0,
    }
    this.state.tabledata.push(data)
    this.setState({creditval:this.state.creditval + total})
  }
  }

  onTaxChange = (option) =>{
    this.setState({ taxreqchecked: option.id });
    taxreqchecked = option.id;
  }

  onTcsChange = (option) =>{
    this.setState({tcschecked:option.id})
  }

  handleCheckboxChange = () =>{
    this.setState({taxchecked:!this.state.taxchecked})
    taxchecked = event.target.checked;
    taxbehav = 2;
  }

  onTax1perChange = (e)=>{
    if(this.state.showtax2){
      this.setState({tax1per:e.target.value, tax1val:(((this.state.debitval * parseFloat(e.target.value)) / 100)).toFixed(3) })
    }else{
     this.setState({tax1per:e.target.value, tax1val:((this.state.debitval * parseFloat(e.target.value)) / 100).toFixed(3) })
    }
  }

  onTax2perChange = (e)=>{
    this.setState({tax2per:e.target.value, tax2val:(((this.state.debitval * parseFloat(e.target.value)) / 100)).toFixed(3) })
  }

  Showtax2 = () =>{
    this.setState({showtax2:true,tax2val:(((this.state.debitval * parseFloat(this.state.tax2per)) / 100)).toFixed(3)})

  }
  Hidetax2 = () =>{
    this.setState({
      showtax2:false, 
      tax1val:((this.state.debitval * parseFloat(this.state.tax1per)) / 100).toFixed(3),
      tax2val:0,
      tax2per:0,
      tax2:""
    })
  }

  handleRemove = (e,i) =>{
      
    let total =  parseFloat(e.debitval)
    +  (this.state.taxreqchecked === 1 && this.state.taxchecked? (parseFloat(e.tax1val) 
    + (this.state.showtax2?parseFloat(e.tax2val):0)):0)
    console.log(total)
    this.setState({
      tabledata:this.state.tabledata.filter((a,idx)=>idx!==i),
      creditval:this.state.creditval - total + this.state.tdsval,
      tdsper:0,
      tdsval:0,
    })
  }

  onTDSperChange = (e) =>{
    let total = this.state.tabledata.reduce((a,b)=>a = a + (parseFloat(b.debitval) + (this.state.taxreqchecked === 1 && this.state.taxchecked? (parseFloat(b.tax1val) 
    + (this.state.showtax2?parseFloat(b.tax2val):0)):0) ),0 )
    let total1 = this.state.tabledata.reduce((a,b)=>a = a  + (parseFloat(b.debitval) ),0 )
    let tdsval = (total1 * parseFloat(e.target.value === null || e.target.value ===''?0:e.target.value)) /100
    this.setState({tdsper:e.target.value, creditval : total-tdsval, tdsval:tdsval })
  }

  onSubmit=(values)=>{
    let dijv = {
      debit:this.state.tabledata,
      credit:this.state.credit,
      creditval:this.state.creditval,
      tds:this.state.tds,
      tdsper:this.state.tdsper,
      tdsval:this.state.tdsval,
      narration: this.state.narration,
      taxchecked:this.state.taxchecked,
      taxreqchecked:this.state.taxreqchecked,
      showtax2:this.state.showtax2,
      tcschecked:this.state.tcschecked,
      taxbehav: taxbehav,
      typeofentry: typeofentry,
      jvtype:jvtype,jvinsertiontype:this.state.jvinsertiontype,
      ncentry:0,
    }

//      if(this.state.fed!=="" && this.state.fed<this.state.credit.map(e=>{return moment(e.debit.createdAt).format("yyyy-MM-DD HH:mm:ss")})){
//       Swal.fire({
//         title: "Please Check !!!",
//         text: "Date cannot be before Debit Ledger Creation Date !!",
//         icon: "warning",
//         confirmButtonText: "Ok",
//         confirmButtonColor: "#152f5f",
//       });
    
//    }else if(this.state.fed!=="" && this.state.fed<this.state.credit.createdAt.format("yyyy-MM-DD HH:mm:ss")){
//     Swal.fire({
//       title: "Please Check !!!",
//       text: "Date cannot be before Credit Ledger Creation Date !!",
//       icon: "warning",
//       confirmButtonText: "Ok",
//       confirmButtonColor: "#152f5f",
//     });
  
//  }else{

    this.props.navigate({ pathname: `/mjournalconfirm` }, { state: dijv });
// }
  }

  validate = (values) =>{
    let errors = {};
    let deb = this.state.tabledata.reduce((a,b)=>a = a + (parseFloat(b.debitval) + (this.state.taxreqchecked === 1 && this.state.taxchecked? (parseFloat(b.tax1val) 
    + (this.state.showtax2?parseFloat(b.tax2val):0)):0) ),0 )
    let cre = this.state.creditval + this.state.tdsval

    if (deb !== cre) {
      errors.creditvalue = "Total Debit value and Credit value should be equal";
    }

    if (this.state.tabledata.length === 0) {
      errors.debit = "Add Debit";
    }

    if (this.state.credit === "" || this.state.credit === null || this.state.credit === undefined) {
      errors.credit= "Credit should be empty";
    }

    if (
      this.state.narration === null ||
      this.state.narration === undefined ||
      this.state.narration === ""
    ) {
      errors.narration = "Narration cannot be blank";
    }
    return errors
  }

  render() {
   let  tcsdata = directtax;
    return (
      <>
            <div className="card card-info">
            {this.state.loading === true ?
              <div className="text-center">
                {spin}
              </div>
              :<>
              <div className="text-center text-danger">{this.state.error}</div>
              <div className="card-body">
                
                <Formik
                  initialValues={{

                  }}
                  onSubmit={this.onSubmit}
                  validateOnChange={true}
                  validate={this.validate}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => (
                    <Form autoComplete="off">
                     
                       
                        <div className="form-row col-md-12 text-left form-switch mt-3">
                    {/* <div className="col-md-3 text-left">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            className="sm"
                            checked={this.state.SDdate}
                            onChange={this.handleSDDate}
                            name="checkedB"
                          />
                        }
                      />
                      <span className="text-left textColour">
                        {this.state.SDdate === false
                          ? "System Date"
                          : "Custom Date"}
                      </span>
                    </div> */}
                    {this.state.SDdate === true ? (
                      <div className="col-md-3 text-left">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid justifyContent="space-around">
                            <DateTimePicker
                              disableFuture
                              showTodayButton
                              showCancelButton
                              variant="standard"
                              id="date-picker-dialog"
                              inputVariant="standard"
                              minDate={
                                new Date(
                                  moment(transactionFrom.$d).format(
                                    "yyyy-MM-DD"
                                  )
                                )
                              }
                              maxDate={
                                new Date(
                                  moment(financialyearend).format("yyyy-MM-DD")
                                )
                              }
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.fsd}
                              // label="Custom Date"
                              InputAdornmentProps={{ position: "start" }}
                              onChange={this.handleFsdDateChange}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" />
                              )}
                              autoOk={true}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </div>
                    ) : null}
                  </div>
                  {this.state.tabledata.length<5?
                  <>
                  

                  <div className="form-row col-md-12   mt-0 mb-0">
                 {/* form-check form-switch text-left */}
                  <fieldset className="form-group col-md-4 text-sm mt-0 "> 
                  {/* float-left */}
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            className="sm"
                            checked={this.state.ResEntry}
                            onChange={this.handleRESEntry}
                            name="checkedB"
                          />
                        }
                      />
                      <span className="text-left textColour">
                        {this.state.ResEntry === 0
                          ? "Normal Entry"
                          : "Reserves Entry"}
                      </span>
                    </fieldset>


                  <fieldset className="form-group col-md-2 text-sm mt-0 float-right">
                        <>
                          <FormLabel>Taxes :</FormLabel>
                          {this.state.taxreq.map((option,i) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-12 mt-0 mb-0">
                                  <div className="radio-item mt-0 mb-0 text-sm">
                                    <input
                                      type="radio"
                                      checked={
                                        this.state.taxreqchecked === option.id
                                          ? true
                                          : false
                                      }
                                      key={i}
                                      onChange={()=>this.onTaxChange(option)}
                                      style={{ marginRight: 8, marginTop: 25 }}
                                      value={this.state.taxreqchecked}
                                    />
                                    {option.name}
                                  </div>
                                </div>
                              </label>
                            );
                          })}
                        </>
                    </fieldset>
              

                    {this.state.taxreqchecked===1?
                    <fieldset className="form-group col-md-4 text-sm mt-6 float-right">
                        <>
                          <FormLabel>Direct Taxes :</FormLabel>
                          {this.state.tdsreq.map((option,i) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-12 mt-0 mb-0">
                                  <div className="radio-item mt-0 mb-0 text-sm">
                                    <input
                                      type="radio"
                                      checked={
                                        this.state.tcschecked === option.id
                                          ? true
                                          : false
                                      }
                                      key={i}
                                      onChange={()=>this.onTcsChange(option)}
                                      style={{ marginRight: 8, marginTop: 25 }}
                                      value={this.state.tcschecked}
                                    />
                                    {option.name}
                                  </div>
                                </div>
                              </label>
                            );
                          })}
                        </>
                  
                        <label className="text-sm">
                          <Checkbox
                            checked={this.state.taxchecked}
                            onChange={this.handleCheckboxChange}
                          />
                          Indirect Taxes
                        </label>
                    </fieldset>
                    :""}
                  </div>
                  {/* ----------------------Top Banner Section --------------------------------               */}
                  <table className="table table-sm table-borderless">
                    <thead className="" style={{ backgroundColor: "#e9ecef" }}>
                      <tr className="form-group col-md-12">
                        <th>Ledger</th>
                        <th></th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                  </table>
                  <div className="row">
                            <fieldset className="form-group col-md-6 sm mt-0 mb-0">
                              <Autocomplete
                                id="debit"
                                className="debit-select text-sm sm"
                                name="debit"
                                options={
                                   this.state.debdata
                                }
                                getOptionLabel={(option) => option.ledgercode!=null?option.name +" [" + option.ledgercode + "]":option.name}
                                groupBy={(option) => option.subname}
                                value={values.debit ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("debit", value);
                                  this.setState({debit:value})
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    type="text"
                                    name="debit"
                                    label="Debit"
                                    className="text-sm"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-6">
                              <FormikTextField
                                name="debitval"
                                className="form-control text-sm sm mt-0 mb-0"
                                value={this.state.debitval}
                                onChange={(e)=>this.setState({debitval:e.target.value})}
                                label={values.debitval ? " " : "Value"}
                                type="number"
                                variant="standard"
                              >
                                {/* {" "} */}
                              </FormikTextField>
                            </fieldset>

                            {this.state.taxreqchecked ===1 && this.state.taxchecked?<>
                            <fieldset className="form-group col-md-6 sm mt-0 mb-0">
                              <Autocomplete
                                id="tax1"
                                className="debit-select text-sm sm"
                                name="tax1"
                                options={
                                   this.state.taxdata
                                }
                                getOptionLabel={(option) => option.ledgercode!=null?option.name +" [" + option.ledgercode + "]":option.name}
                                groupBy={(option) => option.subname}
                                value={values.debit ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("tax1", value);
                                  this.setState({tax1:value})
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    type="text"
                                    name="tax1"
                                    label="Tax1"
                                    className="text-sm"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-2">
                              <FormikTextField
                                name="tax1per"
                                className="form-control text-sm sm mt-0 mb-0"
                                value={this.state.tax1per}
                                onChange = {this.onTax1perChange}
                                label={values.tax1per ? " " : "Percentage"}
                                type="number"
                                variant="standard"
                              >
                              </FormikTextField>
                            </fieldset>

                            <fieldset className="form-group col-md-4 d-flex">
                              <FormikTextField
                                name="tax1val"
                                className="form-control text-sm sm mt-0 mb-0"
                               value={this.state.tax1val}
                                label={values.tax1val ? " " : "Value"}
                                type="number"
                                variant="standard"
                              >
                              </FormikTextField>
                              {this.state.tabledata.length===0?
                              this.state.showtax2?
                            <button
                              className="btn btn-sm ml-2 "
                              onClick={this.Hidetax2}
                              type="button"
                            >
                              <i className="fa fa-minus text-red"/>
                            </button>:
                              <button
                              className="btn btn-sm ml-2 "
                              onClick={this.Showtax2}
                              type="button"
                            >
                              <i className="fa fa-plus text-green"/>
                              </button>:""
                              }
                            </fieldset>
                           
                                {this.state.showtax2 ?<>
                            <fieldset className="form-group col-md-6 sm mt-0 mb-0">
                              <Autocomplete
                                id="tax2"
                                className="debit-select text-sm sm"
                                name="tax2"
                                options={
                                   this.state.taxdata
                                }
                                getOptionLabel={(option) => option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name}
                                groupBy={(option) => option.subname}
                                value={values.debit ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("tax2", value);
                                  this.setState({tax2:value})
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    type="text"
                                    name="tax2"
                                    label="Tax2"
                                    className="text-sm"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-2">
                              <FormikTextField
                                name="tax2per"
                                className="form-control text-sm sm mt-0 mb-0"
                               value={this.state.tax2per}
                               onChange = {this.onTax2perChange}
                                label={values.tax2per ? " " : "Percentage"}
                                type="number"
                                variant="standard"
                              >
                              </FormikTextField>
                            </fieldset>
                            <fieldset className="form-group col-md-4 d-flex">
                              <FormikTextField
                                name="tax2val"
                                className="form-control text-sm sm mt-0 mb-0"
                               value={this.state.tax2val}
                                label={values.tax2val ? " " : "Value"}
                                type="number"
                                variant="standard"
                              >
                              </FormikTextField>
                              {this.state.tabledata.length>0?"":
                              <button
                              className="btn btn-sm ml-2 "
                              onClick={this.Hidetax2}
                              type="button"
                            >
                              <i className="fa fa-minus text-red"/>
                            </button>}
                            </fieldset>
                                </>:""}
                            </>:""}

                            <div className="form-group col-md-2">
                            <button
                              className="hovbuttonColour btn btn-sm col-md-12"
                              onClick={() => this.onAddData(values)}
                              type="button"
                            >
                              Add
                            </button>
                          </div>

                          
                            </div>
                        </>:""}
                      
                      <div className="col-12 m-0 p-0 mb-2">
                        <Table size="small" className="border">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Debit</TableCell>
                                    <TableCell>Debit value</TableCell>
                                    {this.state.taxreqchecked ===1 && this.state.taxchecked?<>
                                    <TableCell>Tax1</TableCell>
                                    <TableCell>Tax1 percentage</TableCell>
                                    <TableCell>Tax1 value</TableCell>
                                    {this.state.showtax2?<>
                                    <TableCell>Tax2</TableCell>
                                    <TableCell>Tax2 percentage</TableCell>
                                    <TableCell>Tax2 value</TableCell>
                                    </>:""}
                                    </>:""}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tabledata.map((e,i)=>
                                <TableRow key={i}>
                                    <TableCell>{e.debit.name}</TableCell>
                                    <TableCell>{e.debitval}</TableCell>
                                    {this.state.taxreqchecked ===1 && this.state.taxchecked?<>
                                    <TableCell>{e.tax1!==undefined?e.tax1.name:''}</TableCell>
                                    <TableCell>{e.tax1per}</TableCell>
                                    <TableCell>{e.tax1val}</TableCell>
                                    {this.state.showtax2?<>
                                    <TableCell>{e.tax2!==undefined && e.tax2 !== "" && e.tax2!==null?e.tax2.name:''}</TableCell>
                                    <TableCell>{e.tax2per}</TableCell>
                                    <TableCell>{e.tax2val}</TableCell>
                                    </>:""}
                                    </>:""}
                                    <TableCell><i className="fa fa-trash text-red cursorPointer" onClick={()=>this.handleRemove(e,i)}/></TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                        </Table>
                      </div>

                      <>
                        <div className="form-row mb-5 mt-0 mb-0">
                        {this.state.tcschecked === 1?<>
                              
                        <fieldset className="form-group col-md-6 sm mt-0 mb-0">
                              <Autocomplete
                                id="tds"
                                className="debit-select text-sm sm"
                                name="tds"
                                options={
                                  tcsdata
                                }
                                getOptionLabel={(option) => option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name}
                                groupBy={(option) => option.subname}
                                value={values.tds ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("tds", value);
                                  this.setState({tds:value})
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    type="text"
                                    name="tds"
                                    label="TDS"
                                    className="text-sm"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-2">
                              <FormikTextField
                                name="tdsper"
                                className="form-control text-sm sm mt-0 mb-0"
                                value={this.state.tdsper}
                                onChange = {this.onTDSperChange}
                                label={values.tdsper ? " " : "Percentage"}
                                type="number"
                                variant="standard"
                              >
                              </FormikTextField>
                            </fieldset>

                            <fieldset className="form-group col-md-4 d-flex">
                              <FormikTextField
                                name="tdsval"
                                className="form-control text-sm sm mt-0 mb-0"
                               value={this.state.tdsval}
                                label={values.tdsval ? " " : "Value"}
                                type="number"
                                variant="standard"
                              >
                              </FormikTextField>
                              </fieldset>

                                 </> :""}
                        <fieldset className="form-group col-md-6 mt-0 mb-4">
                              <Autocomplete
                                id="credit"
                                className="credit-select text-sm"
                                name="acredit"
                                options={
                                    this.state.creditdata
                                }
                                getOptionLabel={(option) =>
                                  option.ledgercode!=null?option.name + " (" + option.amount + ")" + " [" + option.ledgercode + "]":option.name + " (" + option.amount + ")"
                                  
                                }
                                value={values.credit ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("credit", value);
                                  this.setState({credit:value})
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    type="text"
                                    name="credit"
                                    className="text-sm"
                                    label="Credit"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <FormikTextField
                                type="number"
                                name="creditvalue"
                                className="text-sm"
                                label={values.creditval ? " " : "Value"}
                                variant="standard"
                               value={this.state.creditval}
                                fullWidth
                              />
                            </fieldset>
                          {" "}
                          <fieldset className="form-group col-md-12">
                            <FormikTextField
                              name="narration"
                              className="form-control"
                              multiline
                              rows={2}
                              onChange={(e)=>this.setState({narration:e.target.value})}
                              label="Narration"
                              type="text"
                              variant="outlined"
                            />
                          </fieldset>
                        </div>

                        <div className="form-group col-md-12">
                          <button
                            className="hovbuttonColour btn btn-sm"
                            // onClick={() => this.onSubmit()}
                            type="submit"
                          >
                            {" "}
                            {this.state.loader === true ? (
                              <>
                                <Spinner animation="border" size="sm" />
                                &nbsp;{"Submitting"}
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </>
                    </Form>
                  )}
                </Formik>
              </div>
              </>}
            </div>
      </>
    );
  }
}

export default withRouter(MultiEntry);
