import React, { useEffect, useState } from 'react'
import Footer from '../../../Component/Footer'
import ProjectManagementServices from '../../ProjectManagementServices';
import { currentTenant, userid } from '../../../Common/Credentials';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import withRouter from '../../../withRouter';
import { Form, Formik } from 'formik';
import { FormikTextField } from '../../../CommonComponents/FormField';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
// import UserService from '../../../users/UserService';

import Employeeservices from "../../../Masters/Employees/Employeeservices";

const AddSiteIncharge = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loader,setLoader] = useState(false)
    const [projectId,setProjectId] = useState("")
    const [project,setProject] = useState("")
    const [siteId,setSiteId] = useState("")
    const [siteName,setSiteName] = useState("")
    // const [userList, setUserList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [siteInchargeId, setSiteInchargeId] = useState("");
    const [siteInchargeName, setSiteInchargeName] = useState("");
    const [siteInchargeContactNo, setSiteInchargeContactNo] = useState("");
    const [siteInchargeEmail, setSiteInchargeEmail] = useState("");
    const [remark,setRemark] = useState("")

    useEffect(()=>{
        setProjectId(location.state.projectId)
        setProject(location.state.projectName +" ("+location.state.projectCode+")")
        setSiteId(location.state.siteId)
        setSiteName(location.state.siteName)
        // UserService.retriveAllUsers(currentTenant).then((response) =>
        // setUserList(response.data)

        
        Employeeservices.getAllEmployees(currentTenant).then((response) =>
          setEmployeeList(response.data)

      );
    },[])

    const handlesiteInchargeChange = (value, values) => {
        if (values !== "" && typeof values === "object" && values !== null) {
          setSiteInchargeId(values.id);
          setSiteInchargeName(values.first + " " + values.last);
          setSiteInchargeContactNo(values.contactnumber);
          setSiteInchargeEmail(values.email);
        } else {
            setSiteInchargeId("");
          setSiteInchargeName(" ");
          setSiteInchargeContactNo("");
          setSiteInchargeEmail("");
        }
      };

    const onSubmit = () => {
        setLoader(true)
        let data = {
            site: siteId,
            inchargeId: siteInchargeId,
            inchargeName: siteInchargeName,
            inchargeContactNo: siteInchargeContactNo,
            inchargeEmail: siteInchargeEmail,
            tenantId: currentTenant,
            remark: remark,
            createdby: userid,
            updatedby: userid,
        }
        ProjectManagementServices.AddSiteIncharge(data).then(()=>navigate(-1)).catch(()=>setLoader(false))
    }

  return (
    <div>
         <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to={-1}>Project Details</Link>
            </li>
            <li className="breadcrumb-item active">Add Site Incharge</li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                      <div>
                      <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    // validate={validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className='row'>
                        <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="project"
                              label="Project "
                              value={project}
                              disabled
                            //   onChange={(e) => setSiteName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteName"
                              label="Site"
                              value={siteName}
                              disabled
                            //   onChange={(e) => setSiteName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12 m-0 p-0"/>
                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="siteInchargeName"
                              options={employeeList}
                              getOptionLabel={(option) =>
                                option.first + " " + option.last
                              }
                              value={values.customer ? undefined : undefined}
                              onChange={handlesiteInchargeChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="siteInchargeName"
                                  value={siteInchargeName}
                                  label={`${
                                    siteInchargeName === "" ||
                                    siteInchargeName === undefined ||
                                    siteInchargeName === null
                                      ? "Site Incharge"
                                      : siteInchargeName
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteInchargeContactNo"
                              label="Site Incharge Contact No."
                              value={siteInchargeContactNo}
                              onChange={(e) =>
                                setSiteInchargeContactNo(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteInchargeEmail"
                              label="Site Incharge Email"
                              value={siteInchargeEmail}
                              onChange={(e) =>
                                setSiteInchargeEmail(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField
                              name="description"
                              className="form-control"
                              multiline
                              rows={2}
                              label="Remarks"
                              variant="outlined"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                          </fieldset>
                                                  <button
                                                    className="btn btn-sm hovbuttonColour mr-2"
                                                    disabled = {loader}
                                                  >
                                                    {loader?
                                                    <Spinner
                                                      animation="border"
                                                      size="sm"
                                                      className="mr-1"
                                                    />:""}
                                                    Submit
                                                  </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={-1}
                          >
                            <b className="text-danger"> X </b>Cancel
                          </Link>
                        </Form>
                    )}
                    </Formik>
                    </div>
                    </div>
                    </div>
                    </div>
                    </section>
                    </div>
                    </div>
                    <Footer/>  
    </div>
  )
}

export default withRouter(AddSiteIncharge)