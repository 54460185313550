import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {FormikTextField} from '../CommonComponents/FormField.js'
import {Autocomplete, Backdrop, CircularProgress} from '@mui/material';
import { NumericFormat } from 'react-number-format';import {currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import { Typography} from '@mui/material';
import NewJournalService from './NewJournalService';
import { TableHead,TableBody,TableRow,Table, TableCell} from "@mui/material";
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';

// const getTotal = (totamt) => {
//   let total = 0;
//   totamt.forEach(totamt => {
//     total += totamt;
//   });
//   return total;
// };
let totalcombdata=[],pendingdatainv=[],pendingdatapos=[],pendingdataps=[]//,entrytype
let selchk=[],debdata,val=[]

let ppaymennts,ppaymennts2,jvtype=7


// const Checkbox = props => (
//   <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
// )
class LossesEntry extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:this.props.location.state.le,
            posdata:this.props.location.state.lepos,
            psdata:this.props.location.state.leps,
            pendingdatainv:[],
            pendingdatapos:[],
            pendingdataps:[],
            pen:0,sur:0,
            partyid:null,poid:null,pmtid:null,total:'',
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            debselval:'',pmtchecked:'',pnpchecked:'',tcschecked:0,selectedFiles: undefined,serviceamount:null,serviceledger:"",
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],
            fileInfo:"",pendingPayments:[],selectedPP:[],selectedPP2:[],selectedPPO:[],selectedPPO2:[],
            selchk:[],selchk1:[],selamt:[],selamt2:[],displaychecked:false,prevpmtschecked:false,genpayment:[],
            totalcombdata:[],branch:null,deptid:null,
            totloss:undefined,totlosspos:undefined,totlossps:undefined,
            dmgid:undefined,dmgidpos:undefined,dmgidps:undefined,jvinsertiontype:0,
            loader:true
             
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.loadData=this.loadData.bind(this)
        this.AddData=this.AddData.bind(this)
    }

    onDebBlur(values){
      this.setState({debselval:values.debit})
      if(values.debit!==undefined && values.debit.length!==0){
        this.setState({partyid:values.debit.id})
      }
    }

    onSubmit(values){
      this.setState({loader:true})
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
        creditid: values.credit.id,creditname: values.credit.name,creditamt:values.creditvalue,
        narration: values.narration,jvtype:jvtype,createdBy: userid,tenantId: currentTenant,
        pendingdata:this.state.pendingdatainv,frompage:this.props.location.pathname,
        dmgid:this.state.dmgid,pendingdatapos:this.state.pendingdatapos,dmgidpos:this.state.dmgidpos,
        pendingdataps:this.state.pendingdataps,dmgidps:this.state.dmgidps,jvinsertiontype:this.state.jvinsertiontype,
        branch:this.state.branch,dept:this.state.deptid
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      } 
      else {
        this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
      }
    }

    componentDidMount(){
     // console.log(this.state.data)

      this.loadData()
    
      if(this.state.totalcombdata.length===0){
        this.loadData()
        // this.AddData()
        this.setState({loader:false})
       }else{
        this.setState({loader:true})
       }
     // let cred1//,ledid,credvalue
       // this.state.pendingdatainv.map(e=>{return ledid=e.itemid})
        debdata=val.filter((e)=> e.th==="TH5")
       // cred1=val.filter((e) => e.type === 2)
      // credvalue=cred1.filter((e)=>e.shid===100007 )
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      if(this.state.data!==undefined){
      this.setState({pendingdatainv:this.state.data,jvinsertiontype:16})
      pendingdatainv=this.state.data
      pendingdatainv.map((e)=>{return this.setState({totloss:e.totalloss})})
      pendingdatainv.map((e)=>{return this.setState({dmgid:e.id})})
      pendingdatainv.map((e)=>{return this.setState({branch:e.whid})})
      pendingdatainv.map((e)=>{return this.setState({deptid:e.deptid})})

      }else if(this.state.posdata!==undefined){
        this.setState({pendingdatapos:this.state.posdata,jvinsertiontype:18})
        pendingdatapos=this.state.posdata
        pendingdatapos.map((e)=>{return this.setState({totlosspos:e.totalloss})})
        pendingdatapos.map((e)=>{return this.setState({dmgidpos:e.id})})
        pendingdatapos.map((e)=>{return this.setState({branch:e.whid})})
        pendingdatapos.map((e)=>{return this.setState({deptid:e.deptid})})
      }else if(this.state.psdata!==undefined){
        this.setState({pendingdataps:this.state.psdata,jvinsertiontype:17})
        pendingdataps=this.state.psdata
        pendingdataps.map((e)=>{return this.setState({totlossps:e.totalloss})})
        pendingdataps.map((e)=>{return this.setState({dmgidps:e.id})})
        pendingdataps.map((e)=>{return this.setState({branch:e.whid})})
        pendingdataps.map((e)=>{return this.setState({deptid:e.deptid})})
      }
      // this.setState({loading:false})
       }

    loadData(){
      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data})
        totalcombdata=response.data
       },()=>this.AddData())
       .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      })

    }

    AddData(){
      if(totalcombdata!==undefined){
        val=totalcombdata 
        this.setState({loader:false})
      }else(
        this.loadData()
      )
     
    }
     validate(values){
      let errors = {}//type = totalcombdata//deb = values.debitval,cre = values.creditvalue
     // let ledgers= type.filter((e) => e.type === 1)

       if (!values.debit || values.debit==='') {errors.debit = "Accounts is required";}
     
       if (!values.credit || values.credit==='') {errors.credit = "Accounts is required"}
       if (!values.narration) {errors.narration = "Narration is required";}
       return errors;
     }
     onTcsChange(option){this.setState({ tcschecked: option.id });}

   

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
    let a 
    this.state.pendingdatainv.map((e)=>{return a=e.pitems})
    this.setState({selchk:a}) 
    selchk=a

 }

    render() {
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      let cred1
      const {isError,message} = this.state;
      let credvalue,selpp,selpp2
     // entrytype=1
     
        debdata=null
        //let ledid
        debdata=val.filter((e)=> e.th==="TH5")
      // }
    
      
      cred1=val.filter((e) => e.type === 2)
      credvalue=cred1.filter((e)=>e.subheadid==="AH000026")
      
      
         
// -------------------NEW ----------------



// let otherled
// otherled=val.filter((e)=>e.th==="TH2")


        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">

  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/lossesl'}} state={{message:''}} >
                Losses 
              </Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/lossesentry'}} state= {{message:''}} >
                Loss Entry
              </Link>
    </li>

  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{ debdata,credvalue,narration:'',selpp,selpp2,ppaymennts,ppaymennts2,selchk}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">




<div className="form-row mt-0 mb-0 text-sm sm">
{this.state.dmgid!==undefined ?<>
  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
    <TableCell className="col-md-2 text-sm">Branch/WH</TableCell>
 <TableCell className="col-md-2 text-sm">Batch</TableCell>
  <TableCell className="col-md-2 text-sm">Category</TableCell>
 <TableCell className="col-md-2 text-sm">Qty</TableCell>
 <TableCell className="col-md-2 text-sm">Price</TableCell>
 <TableCell className="col-md-2 text-sm">Total Loss</TableCell>
 {/* <TableCell className="col-md-1 text-sm">Penalty</TableCell>
 <TableCell className="col-md-1 text-sm">Total</TableCell> */}
  </TableRow>
    </TableHead>
  
   <TableBody>
   {pendingdatainv.map((e,i)=>
  <TableRow key={i}>
 <TableCell  className="col-md-2 text-sm">{e.itemname}</TableCell>
 <TableCell className="form-group col-md-2 text-sm">{e.whname}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.batchid}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.category}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.quantity}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
    </TableCell>
  <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalloss} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  {/* <TableCell className="form-group col-md-1 text-sm">{e.penalty}</TableCell>

  <TableCell className="form-group col-md-1 text-sm">
  <NumericFormat className="text-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalamount}
   thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell> */}
  </TableRow>)}
</TableBody></Table>

</fieldset></>:""}

{ this.state.dmgidpos!==undefined?<>
  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
    <TableCell className="col-md-2 text-sm">Branch/WH</TableCell>
 <TableCell className="col-md-2 text-sm">Dept</TableCell>
  <TableCell className="col-md-2 text-sm">Status</TableCell>
 <TableCell className="col-md-2 text-sm">Qty</TableCell>
 <TableCell className="col-md-2 text-sm">Price</TableCell>
 <TableCell className="col-md-2 text-sm">Total Loss</TableCell>
 {/* <TableCell className="col-md-1 text-sm">Penalty</TableCell>
 <TableCell className="col-md-1 text-sm">Total</TableCell> */}
  </TableRow>
    </TableHead>
  
   <TableBody>
   {pendingdatapos.map((e,i)=>
  <TableRow key={i}>
 <TableCell  className="col-md-2 text-sm">{e.itemname}</TableCell>
 <TableCell className="form-group col-md-2 text-sm">{e.warehouse}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.dept}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.comments}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.quantity}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
    </TableCell>
  <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalloss} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
 
  </TableRow>)}
</TableBody></Table>

</fieldset></>:""}

{this.state.dmgidps!==undefined ?<>
  <fieldset className="form-group col-md-12"> 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
 <TableCell className="col-md-2 text-sm">Store</TableCell>
  <TableCell className="col-md-2 text-sm">Status</TableCell>
 <TableCell className="col-md-2 text-sm">Qty</TableCell>
 <TableCell className="col-md-2 text-sm">Price</TableCell>
 <TableCell className="col-md-2 text-sm">Total Loss</TableCell>
 
  </TableRow>
    </TableHead>
  
   <TableBody>
   {pendingdataps.map((e,i)=>
  <TableRow key={i}>
 <TableCell  className="col-md-2 text-sm">{e.itemname}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.storename}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.comments}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.quantity}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
    </TableCell>
  <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalloss} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
 
  </TableRow>)}
</TableBody></Table>

</fieldset></>:""}

  </div>
 <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={!debdata?this.loadData():debdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
  value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>this.state.totlosspos!==undefined?setFieldValue("debitval",this.state.totlosspos):this.state.totloss!==undefined?setFieldValue("debitval",this.state.totloss):setFieldValue("debitval",this.state.totlossps) 
   && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
  </div>     


<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={!credvalue?this.loadData():credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
getOptionLabel={(option) => option.name +" ("+option.amount+")"} groupBy={(option) => option.subname}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" onBlur={()=> this.state.totlosspos!==undefined?setFieldValue("creditvalue",this.state.totlosspos):this.state.totloss!==undefined?setFieldValue("creditvalue",this.state.totloss):setFieldValue("creditvalue",this.state.totlossps) }
  variant="standard" fullWidth />)}/>
</fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0" value={values.debitval} 
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
 </fieldset>
 </div>


  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>
  {/* </>:""} */}


<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="btn hovbuttonColour mr-1 btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(LossesEntry);              



 
