import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EmployeeService from "./EmployeeService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import FormGroup from "@mui/material/FormGroup";
import Footer from "../Component/Footer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, Grid, TextField } from "@mui/material";
import withRouter from "../withRouter";

// import MUIDataTable from "mui-datatables";
// import { Button } from "bootstrap";
// import { SettingsSystemDaydream } from "@material-ui/icons";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

const SalaryProgressMonth = () => {
  // Function start

  const [employee, setEmployee] = useState([]);
  const [overall, setOverall] = useState(0);
  const [month, setMonth] = useState(new Date());
  const [tanentid, setTanentid] = useState("");

  // UseEffects
  useEffect(() => {
    apicall();
    tenantidHamdler();
    // getAllData();
  }, []);

  const CalcOverAllAmt = () => {
    let total = employee.reduce((a, b) => (a = a + parseFloat(b.totalVal)), 0);
    setOverall(total);
  };

  const tenantidHamdler = () => {
    EmployeeService.getEmployee().then((e) => {
      setTanentid(e.data[0].salary.tenantid);
      console.log(e.data[0].salary.tenantid, "Tan");
    });
  };

  // const nevigate = useNavigate();
  const handleChange = (event, i, e) => {
    console.log(employee, event);
    if (event === "Full") {
      employee.find((e, index) => index === i).radio = event;

      employee.find((emp, index) => index === i).totalVal = (
        parseFloat(e.salary.basicSalary / 12 / 30) * 30
      ).toFixed(2);
      setEmployee([...employee]);
      CalcOverAllAmt();
    } else {
      let data = employee;

      data.find((e, index) => index === i).radio = event;

      setEmployee([...data]);

      // employee.map((i)=>{
      //   i.overallAmount = (i.overallAmount) + i.totalVal
      // });
      // setEmployee([...employee]);
    }
  };

  // MANUALLY CHANGED FUNCTION
  const ManualChange = (event, i, e) => {
    let emp = employee.find((emp, index) => index === i)
    employee.find((emp, index) => index === i).attendance = event;
    employee.find((emp, index) => index === i).totalVal = (
      (parseFloat(e.salary.basicSalary / 12 / 30) * event) - emp.epf - emp.incometax - emp.professionalTax - emp.healthInsurance 
    ).toFixed(2);
    console.log(employee)
    setEmployee([...employee]);
    CalcOverAllAmt();
  };

  const monthHandler = (e) => {
    setMonth(e);

    console.log(moment(e).format("MM"), moment(e).format("YYYY"));

    const updatedEmployees = employee.map((emp) => {
      return {
        ...emp,
        month: parseInt(moment(e).format("MM")),
        year: parseInt(moment(e).format("YYYY")),
      };
    });

    setEmployee(updatedEmployees);
    console.log(updatedEmployees);
  };

  console.log(employee, "NEW");
  // PAY HANDLER
  const payHandler = () => {
    setEmployee(employee);
    console.log(employee, "eeee");
    employee.map((e) => {
      console.log(e, "huh");
      EmployeeService.addingSalaryProgress(e).then((r) => {
        console.log(r.data, "PAY DATA");
      });
    });
  };

  const apicall = () => {
    EmployeeService.getEmployee()
      .then((response) => {
        setEmployee(
          response.data.map((e) =>
            Object.assign(e, {
              radio: "Full",
              attendance: 30,
              month: 0,
              year: 0,
              status: "pending",
              totalVal: (
                parseFloat(e.salary.basicSalary / 12 / 30) * 30
              ).toFixed(2),
              overallAmount: 0,
            })
          )
        );
      })
      .catch((err) => console.log(err));
  };


  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item active">
              Employees Details Breakup
            </li>
          </ol>
          <div className="card-body">
            <fieldset className="col-md-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container justifyContent="space-around">
                  <DatePicker
                    variant="inline"
                    id="date-picker-dialog"
                    label="Select Month and Year"
                    openTo="month"
                    views={["year", "month"]}
                    minDate={new Date()}
                    value={month}
                    onChange={monthHandler}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            </fieldset>
            <div className="card mt-3">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID </TableCell>
                      <TableCell align="center">FULL_NAME</TableCell>
                      <TableCell align="center">BASIC_SALARY</TableCell>
                      <TableCell align="cebter">EPF</TableCell>
                      <TableCell align="center">SALARY_DEDUCTION</TableCell>
                      {/* <TableCell align="center">Tax</TableCell> */}
                      <TableCell align="center">NETPAY</TableCell>
                      <TableCell align="center">PRESENT DAYS</TableCell>
                      <TableCell align="center">CALCULATION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employee.length === 0?
                    <TableRow>
                      <TableCell colSpan={12} className="text-center">Sorry, no matching records found</TableCell>
                    </TableRow>:
                    employee.map((e, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {e.empid}
                        </TableCell>
                        <TableCell align="center">{e.empFullName}</TableCell>
                        <TableCell align="center">
                          {(parseFloat(e.salary.basicSalary) / 12).toFixed(3)}
                        </TableCell>
                        <TableCell align="center">
                          {(parseFloat(e.salary.epf) / 12).toFixed(3)}
                        </TableCell>
                        <TableCell align="center">
                          {(parseFloat(e.salary.totalDeduction) / 12).toFixed(
                            3
                          )}
                        </TableCell>
                        {/* <TableCell align="center">{(parseFloat(e.salary.professionalTax)/12).toFixed(3)}</TableCell> */}
                        <TableCell align="center">
                          {(parseFloat(e.salary.netpay) / 12).toFixed(3)}
                        </TableCell>

                        <TableCell align="center">
                          <div className="m-0 d-flex">
                            <div className="col-md-6">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked
                                    checked={e.radio === "Full"}
                                    onChange={(t) => handleChange("Full", i, e)}
                                  />
                                }
                                size="small"
                                label="Full"
                                className="m-0"
                              />
                            </div>
                            {e.radio === "Manually" ? (
                              <div className="col-md-6">
                                <TextField
                                  type="number"
                                  name={`days${i}`}
                                  label="Days"
                                  value={e.attendance}
                                  inputProps={{ min: 0, max: 30 }}
                                  onChange={(x) =>
                                    ManualChange(x.target.value, i, e)
                                  }
                                />
                              </div>
                            ) : (
                              <div className="col-md-6">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={e.radio === "Manually"}
                                      onChange={(t) =>
                                        handleChange("Manually", i, e)
                                      }
                                    />
                                  }
                                  size="small"
                                  label="No. of Days"
                                  className="m-0"
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>

                        <TableCell align="right">
                          <div>
                            {/* <label className="mx-4 my-2 " value={e.totalVal}> */}
                            {e.totalVal}
                            {/* </label> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex justify-content-end me-4 my-2">
                <span className="text-center">Total Amount : </span>
                <span className="mx-2 text-center">{overall}</span>

                <button
                  className="btn btn-sm hovbuttonColour mr-2"
                  onClick={payHandler}
                >
                 Sent to Payment
                </button>
                {/* <button
                  className="btn btn-sm hovbuttonColour mr-2"
                  onClick={viewHandler}
                >
                  View
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(SalaryProgressMonth);
