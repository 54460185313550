import React, { Component } from 'react'
import {Link } from 'react-router-dom';
import { currentTenant, userid} from '../../Common/Credentials.js';
import NewLedgerService from './NewLedgerService.js';
import Swal from 'sweetalert2'
import { accountsid } from "../../Common/Permissions.js";
import MUIDataTable from 'mui-datatables';
import { spin } from "../../Common/NewLoader.jsx";
import {createTheme} from '@mui/material';
import { ThemeProvider } from "@mui/material";
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import withRouter from '../../withRouter.js';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
// const getTotal = (data) => {
//   let total=0 ;
//   //  console.log(data)
//   data.forEach(data => {
//     total += data;
//   });
//   // console.log(total)
//   return total;
// };
// const getTotal1 = (data1) => {
//   let total=0 ;
//   // console.log(data)
//   data1.forEach(data1 => {
//     total+= data1;
//   });
//   // console.log(total)
//   return total;
// };
export let nominalledgers

class Nominal extends Component {

  constructor(props) {
    super(props)


    this.state = {
   
    nledgers:[]
     

    }
    this.addData=this.addData.bind(this)

  }

  addData(){
    NewLedgerService.getnominalledgers(currentTenant)
  .then(response =>{
  this.setState({nledgers:response.data})
  nominalledgers=response.data
    const script = document.createElement("script");
     script.src= '../../js/content.js';
     script.async= true;
    document.body.appendChild(script);
   })
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

  }
  componentDidMount() {
    this.addData()
   if(userid===undefined){
    window.confirm('Your Session is Lost: Please login Back to resume')
    this.props.navigate(`/`)
  }

}
deleteLedger(value){
  NewLedgerService.delNom(value)
  .then(response=>{
    Swal.fire({
      title: "Response",
      text: response.data,
      icon: "info",
    confirmButtonText: "Ok",
    confirmButtonColor:'#152f5f',
    })
   // alert(response.data)
    this.addData()
  })
}



    render() {
      // const data= this.state.nledgers.map(row=> {return row.entrytype!==0?0:row.amount} ) 
      // const data1= this.state.nledgers.map(row=> {return row.entrytype!==1?0:row.amount} )  
 
      const columns = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "name",label: "Account Name",options: {filter: true,sort: true,
          customBodyRender: (value,tableMeta) => {
            return (
            (tableMeta.rowData[4]!==null)?value+ " (" + tableMeta.rowData[4] + ")":value
            )
          }
        }},
        {name: "shname",label: "Head",options: {filter: true,sort: true,}},       
          {name: "Action", options: { filter: false,sort: false,empty: true,
              display: accountsid === "2" || accountsid === "3" ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (<>
                 
                   <span
                    as="button"
                    data-tip
                    data-for="delete"
                    className="fa fa-trash text-danger"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you wish to delete Customer ${tableMeta.rowData.find(
                            (e) => e
                          )}?`
                        )
                      )
                        this.deleteLedger(tableMeta.rowData.find((e) => e));

                    }}
                  ></span>
                  </>
                );
              },
              setCellProps: value => ({ style: { textAlign:'center'}}),
            },
          },
          {name: "ledgercode",label: "Ledgercode",options: {filter: false,sort: false,display:false,viewColumns:false}},

       
      ]
      const options = {
        filterType: 'checkbox',
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {accountsid === "2" || accountsid === "3" ?  (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{
                    pathname: "/newnominal"}}
                    state={{id: -1, name: "New Ledger" }}
                >
                  <CustomToolbar />
                </Link>
                ) : (
                 ""
              )} 
            </>
          );
        },
        resizableColumns: true,
      }
        return (

<div>

<div>
                  {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Ledgers`}
                            data={this.state.nledgers}
                            columns={columns}
                            options={options}
                          />
                        </ThemeProvider>
                        )}
                      </div>
</div>
         
        )
    }
}
export default withRouter(Nominal)
