import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { NumericFormat } from 'react-number-format';import { currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import moment from 'moment';
import {Table, TableCell, TableBody, TableHead, TableRow, Backdrop, CircularProgress} from '@mui/material';
import NewJournalService from './NewJournalService';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import { FormikTextField } from '../CommonComponents/FormField';
import { Autocomplete } from '@mui/material';



class ExpensesEntry extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
        tripexpdata:this.props.location.state.te,
         totalcombdata:[],
         loader:false, 
         tripexpdatastrip:'',wages1:'',wages2:'',toll:'',othercharges:'',fuel:'',initotamt:'',
         wages1name:'',wages2name:'',tollname:'',otherchargesname:'',fuelname:'',asocamt:'',asocamtname:'',
         selectval:'',totselval:[],seltotval:'',bal:'',
         selectedname:'',assetidte:'',loader1:true

          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.onAdd=this.onAdd.bind(this);this.recordAdd=this.recordAdd.bind(this);

        
    }

    balcalc(){
      this.setState({bal:(parseInt(this.state.initotamt-this.state.seltotval))})
    }

    onAdd(jv){
      this.state.totselval.push(jv)
      this.setState({seltotval:(parseInt(this.state.seltotval+this.state.selectval))},()=>this.balcalc())
      this.setState({selectval:''})
        // console.log(this.state.totselval)
    }
    onSubmit(values){
      let name1=this.state.selectedname
      // alert("hi")
      let jvtype=8
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,subacc:values.debit.shname,
        th:values.debit.th,debitamt: this.state.selectval,type:values.debit.type,
        creditid: values.credit.id,creditname: values.credit.name,crsubacc:values.debit.shname,
        crth:values.debit.th,crtype:values.debit.type,creditamt:this.state.selectval,
        narration: values.narration,createdBy: userid,tenantId: currentTenant,
        jvtype:jvtype,supplierid:values.credit.id,supplier:values.credit.name,expname:name1,ncentry:0,
       totalexpval:this.state.tripexpdatastrip.totalexp,assetidte:this.state.assetidte}
    
      this.onAdd(jv)
         // if (userid === undefined) {
      //   window.confirm("Your Session is Lost: Please login Back to resume");
      //   AuthenticationService.logout(currentUser);
      // } 
      // else {
      //   this.props.navigate(`/journalconfirm`, { jv });
      // }

    }
    componentDidMount(){
      // console.log(this.props)
      this.state.tripexpdata.map((e)=>{return this.setState({tripexpdatastrip:e},()=>this.setValues())})

     this.setState({loading: true })
      

      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.loadData()
      
       }

    setValues(){
     // console.log(this.state.tripexpdatastrip)
        this.setState({fuel:parseInt(this.state.tripexpdatastrip.dieselinamt)})
        this.setState({fuelname:"fuel"})
        this.setState({wages1:parseInt(this.state.tripexpdatastrip.wages1)})
        this.setState({wages1name:"wages1"})
        this.setState({wages2:parseInt(this.state.tripexpdatastrip.wages2)})
        this.setState({wages2name:"wages2"})
        this.setState({toll:parseInt(this.state.tripexpdatastrip.tollamt)})
        this.setState({tollname:"tollname"})
        this.setState({asocamt:parseInt(this.state.tripexpdatastrip.asocamt)})
        this.setState({asocamtname:"asocamtname"})
       // this.setState({loading:parseInt(this.state.tripexpdatastrip.loading)})
        this.setState({othercharges:parseInt(this.state.tripexpdatastrip.otherexpenses)})
        this.setState({otherchargesname:"otherchargesname"})
        this.setState({initotamt:parseInt(this.state.tripexpdatastrip.totalexp)})
        this.setState({assetidte:this.state.tripexpdatastrip.id})

     }
   
    loadData(){
        NewJournalService.getAllLedgers(currentTenant)
        .then(response=>{
          this.setState({totalcombdata:response.data},()=>this.setState({loader1:false}))
          this.setState({loading:false})
         })
         .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
        })


    }

    submitRecord(val){
      if(val!==undefined){
      if(val==="fuel"){
        this.setState({selectval:this.state.fuel})
        this.setState({selectedname:this.state.fuelname})
        this.setState({fuel:0})
        this.setState({fuelname:''})
      }else
      if(val==="wages1"){
        this.setState({selectval:this.state.wages1})
        this.setState({selectedname:this.state.wages1name})
        this.setState({wages1:0})
        this.setState({wages1name:''})
      }else
      if(val==="wages2"){
        this.setState({selectval:this.state.wages2})
        this.setState({selectedname:this.state.wages2name})
        this.setState({wages2:0})
        this.setState({wages2name:''})
      }else
      if(val==="toll"){
        this.setState({selectval:this.state.toll})
        this.setState({selectedname:this.state.tollname})
        this.setState({toll:0})
        this.setState({tollname:''})
      }else
      if(val==="asocamtname"){
        this.setState({selectval:this.state.asocamt})
        this.setState({selectedname:this.state.asocamtname})
        this.setState({asocamt:0})
        this.setState({asocamtname:''})
      }else
      if(val==="othercharges"){
        this.setState({selectval:this.state.othercharges})
        this.setState({selectedname:this.state.otherchargesname})
        this.setState({othercharges:0})
        this.setState({otherchargesname:''})
      }
    }
    }

    recordAdd(){
     // console.log(this.state.totselval)
      NewJournalService.insertexpJv(this.state.totselval)
      .then(
        () =>this.props.navigate('/ap'))
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
      
    }
    validate(values){
      let errors = {}
       if (!values.narration) {errors.narration = "Narration is required";}
       return errors;
     }


    render() {
    
    //  let creddata=this.state.totalcombdata.filter(e=>e.th==="TH2")

      // input=this.state.monthsindtax.input
      // output=this.state.monthsindtax.output
      //  let pendinginput=null,pendingout=null
      //  console.log(input)
      //  console.log(output)

       
      //   pendinginput=output.filter((e)=>e.poid!==null && e.transpendingamount!==0)
      //   pendingout=input.filter((e)=>e.poid===null && e.transpendingamount!==0)
      let credvalue,debdata  //cred1    
      debdata=this.state.totalcombdata.filter((e)=>e.th=== "TH2")
      //cred1=this.state.totalcombdata.filter((e) => e.type === 2)
      credvalue=(this.state.totalcombdata.filter(e=>e.subheadid==="AH000019"))

        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/depreciationl"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    {/* <li className="breadcrumb-item text-secondary"> */}
    <li className="breadcrumb-item active">Expense Entry</li>

    {/* <Link to={{pathname: '/indirtaxes', state: {message:''}}} >
                Indirect Taxes
              </Link> */}
    {/* </li> */}
  </ol>
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  
              
        
    <Formik
      initialValues={{ credit:'',debit:'',debitval:'',creditvalue:'',narration:'',paymentref:''}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">

<div>
<Table className="table table-sm table-bordered">
  <TableHead className="thead-light thead-bordered mt-0 mb-0">
    <TableRow>
<TableCell className='text-center'>Trip ID</TableCell>
<TableCell className='text-center'>From</TableCell>
<TableCell className='text-center'>To</TableCell>
<TableCell className='text-center'>Date</TableCell>
<TableCell className='text-center'>Total</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  <TableRow>
<TableCell className='text-center'>{this.state.tripexpdatastrip.customid}</TableCell>
<TableCell className='text-center'>{this.state.tripexpdatastrip.originname}</TableCell>
<TableCell className='text-center'>{this.state.tripexpdatastrip.destinationname}</TableCell>
<TableCell className='text-center'>{moment(this.state.tripexpdatastrip.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.tripexpdatastrip.totalexp} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat></TableCell>
 </TableRow>
  </TableBody>
</Table>
</div>
 {/* <div className="mb-12">




                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Output Tax  `+" - "+"("+this.state.monthname+")"}
                            data={output}
                            columns={pendingout}
                            options={ppoptions}
                          />
                        </ThemeProvider>


              
                      </div>
<br/> */}
{/* <div className="mb-12">
<Table className="table table-sm table-bordered">
<TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'><span className="text-left font-weight-bold">{`Output Tax - ( `+this.state.monthname + " )"}</span></TableCell>
<TableCell className='text-center'><span className="text-left font-weight-bold">{`Input Tax  - ( `+this.state.monthname + " )"}</span>
</TableCell>
</TableRow>
</TableHead>
<TableBody>
<TableRow>
<TableCell>
<Table className="table table-sm table-bordered">
<TableHead className="thead-light thead-bordered mt-0 mb-0" >
<TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'>ITEM</TableCell>
{ccountry==="India"?<>
<TableCell className='text-center'>SGST</TableCell>
<TableCell className='text-center'>CGST</TableCell>
<TableCell className='text-center'>IGST</TableCell></>:
<TableCell className='text-center'>VAT</TableCell>}
</TableRow>
</TableHead>
<TableBody>
{output.map((e)=>(
<TableRow>
<TableCell>{e.outitemname} </TableCell>
{ccountry==="India"?<>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outstatetaxamount!==null?e.outstatetaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outcentraltaxamount!==null?e.outcentraltaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outintegratedtaxamount!==null?e.outintegratedtaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outvattaxamount!==null?e.outvattaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>))}
<TableRow>
<TableCell className='text-left font-weight-bold'>Total</TableCell>
{ccountry==="India"?<>
<TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outsgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outcgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outigsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outvattot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>
</TableBody>
</Table>
</TableCell>
<TableCell>
<Table className="table table-sm table-bordered">
                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'>ITEM</TableCell>
{ccountry==="India"?<>
<TableCell className='text-center'>SGST</TableCell>
<TableCell className='text-center'>CGST</TableCell>
<TableCell className='text-center'>IGST</TableCell></>:
<TableCell className='text-center'>VAT</TableCell>}
</TableRow>
</TableHead>
<TableBody>
{input.length!==0?input.map((e)=>(
<TableRow>
<TableCell>{e.itemname} </TableCell>
{ccountry==="India"?<>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.statetaxamount!==null?e.statetaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.centraltaxamount!==null?e.centraltaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.integratedtaxamount!==null?e.integratedtaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.vattaxamount!==null?e.vattaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>)):""}
<TableRow>
<TableCell className='text-left font-weight-bold'>Total</TableCell>
{ccountry==="India"?<>
<TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={insgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={incgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={inigsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={invattot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>
</TableBody>
</Table>
</TableCell>
</TableRow>
</TableBody>
</Table>
</div> */}

{/* <br/> */}
<div className="mb-12">

{/* <span className="text-center font-weight-bold">{`Tax Liability  - ( `+this.state.monthname+ " )"}</span> */}
<Table className="table table-sm table-bordered">
                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'>Expenses</TableCell>
<TableCell className='text-center'>Amount</TableCell>
<TableCell className='text-center'>Action</TableCell>

</TableRow>
</TableHead>
<TableBody>
<TableRow>
<TableCell className='text-left'>Fuel</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.fuel} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
</TableCell>
<TableCell className='text-center'>
  {this.state.fuel>0?
<button className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("fuel")}>pay</button>:"NA"}
</TableCell>
</TableRow>
<TableRow>
<TableCell className='text-left'>Wages1</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.wages1} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>         
</TableCell>
<TableCell className='text-center'>
  {this.state.wages1>0?
<button className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("wages1")}>pay</button>:"NA"}
</TableCell>
</TableRow>
<TableRow>
<TableCell className='text-left'>Wages2</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.wages2} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>        
</TableCell>
<TableCell className='text-center'>
  {this.state.wages2>0?
<button className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("wages2")}>pay</button>:"NA"}
</TableCell>
</TableRow>

<TableRow>
<TableCell className='text-left'>Toll Charges</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.toll} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
            
</TableCell>
<TableCell className='text-center'>
{this.state.toll>0?
<button className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("toll")}>pay</button>:"NA"}
</TableCell>
</TableRow>
<TableRow>
<TableCell className='text-left'>Asoc/Greas</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.asocamt} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
            
</TableCell>
<TableCell className='text-center'>
{this.state.asocamt>0?
<button className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("asocamtname")}>pay</button>:"NA"}
</TableCell>
</TableRow>
<TableRow>
<TableCell className='text-left'>Other Charges</TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={this.state.othercharges} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
</TableCell>
<TableCell className='text-center'>
{this.state.othercharges>0?
<button className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("othercharges")}>pay</button>:"NA"}
</TableCell>
</TableRow>
</TableBody>
</Table>
</div>
{  this.state.selectval!==''?<>

<div className="form-row" > 
<fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={!debdata?this.loadData():debdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name }  groupBy={(option) => option.subname}
  value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  // onBlur={()=>this.state.selectval!==null?setFieldValue("debitval",this.state.selectval):""} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" value={this.state.selectval}
  label={values.debitval?" ":"Value"}  type="text" 
  variant="standard"> </FormikTextField></fieldset>
 
  </div>     

<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={!credvalue?this.loadData():credvalue}
getOptionLabel={(option) => option.name +" ("+option.amount+")"}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit"
 //onBlur={()=>this.state.selectval!==null?setFieldValue("creditvalue",this.state.selectval):""}  
 variant="standard" fullWidth />)}
 /></fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 
 <FormikTextField  type="text" name="creditvalue" className="text-sm" label={values.debitval?" ":"Value"}
 variant="standard" value={this.state.selectval}
 fullWidth />
 </fieldset>
 </div>


 {/* <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/></fieldset></div> */}

<div className="form-group col-md-12">
<button className="hovbuttonColour btn btn-sm" onClick={()=>this.onSubmit(values)}  type="submit">Add</button>
 </div>
  

 </> :""}

{this.state.totselval!==''?
 <Table className="table table-sm table-bordered">
                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'>Debit</TableCell>
<TableCell className='text-center'>Credit</TableCell>
{/* <TableCell className='text-center'>Narration</TableCell> */}
<TableCell className='text-center'>Amount</TableCell>
</TableRow>
</TableHead>

<TableBody>
  {this.state.totselval.map((val,i)=>
  <TableRow key={i}>
    <TableCell className='text-center'>{val.debitname}</TableCell>
    <TableCell className='text-center'>{val.creditname}</TableCell>
    {/* <TableCell className='text-center'>{val.narration}</TableCell> */}
    <TableCell className='text-right'>{val.creditamt}</TableCell>
  </TableRow>)}
  <TableRow>
  {/* <TableCell></TableCell> */}
  <TableCell></TableCell>
  <TableCell className='text-right'>Total</TableCell>
   <TableCell className='text-right'>{this.state.seltotval}</TableCell>
  </TableRow>
  <TableRow>
  {/* <TableCell></TableCell> */}
  <TableCell></TableCell>
  <TableCell className='text-right'>Balance</TableCell>
   <TableCell className='text-right'>{this.state.bal}</TableCell>
  </TableRow>
</TableBody>
</Table>:""}

{this.state.bal===0?
 <><div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
 <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration" type="text" 
 variant="outlined"/></fieldset></div>

<div className="form-group col-md-12">
 <button className="hovbuttonColour btn btn-sm" onClick={()=>this.recordAdd()}  type="button"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}
 </button></div></>:""}
</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     
 </div>
 </>
)
}
}

export default withRouter(ExpensesEntry);              



 
