import {
  FormGroup,
  FormLabel,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ccountry, cstate, currentTenant, userid } from "../Common/Credentials.js";
import { Autocomplete } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";
import AssetsService from "./AssetsService.js";
import { spin } from "../Common/NewLoader.jsx";
import { Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PurchseServices from "../Purchase/PurchseServices.js";
// import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { accounts } from "../Common/Products.js";
import InventoryService from "../inventory/InventoryService.js";

const NewAssetmaintenance = (props) => {
  // const history = useLocation();
  const navigate = useNavigate();
  const [assetidList,setAssetIdList] = useState([]);
  const [assetid,setAssetId] = useState('')
  const [customid,setCustomId] = useState('')
  // const [assetdetails,setAssetDetails]= useState('')
  const [itemid, setitemid] = useState("");
  const [itemname, setitemname] = useState("");
  // const [quantity, setQuantity] = useState(0);
  // const [avilablequantity,setAvilablequantity] = useState(0)
  const [type,setType] = useState('');
  const [types,setTypes] = useState([]);
  const [amount,setAmount] = useState(0)
  const [description,setDescription] = useState('')
  const [loader,setLoader] = useState(false)
  const [supplier,setSupplier] = useState("")
  const [supplierId,setSupplierId] = useState("")
  const [suppliers,setSuppliers] = useState([])
  const [supcountry,setSupcountry] = useState("")
  const [taxtype,setTaxtype] = useState("")
  const [gsttype,setGsttype] = useState("")
  const [taxrequired,setTaxrequired] = useState(true)
  const [cgst,setCgst] = useState("");
  const [sgst,setSgst] = useState("");
  const [taxamount,setTaxamount] = useState("")
  const [tax,setTax] = useState("");
  const [totalamount,setTotalamount] = useState(0)

  useEffect(() => {
    // if (userid === undefined) {
    //   window.confirm("Your Session is Lost: Please login Back to resume");
    //   history.push(`/`);
    // }
    PurchseServices.GetAllSellers(currentTenant).then((res) =>
      setSuppliers(res.data)
    );
    AssetsService.GetMaintenanceTypes().then(response=>
      setTypes(response.data)
      )
    AssetsService.AssetIds(currentTenant)
      .then((response) => 
      setAssetIdList(response.data)
      )
      .catch((e) => {
        if(e.response!==undefined){
          if (e.response.data.error === "Unauthorized") {
            Swal.fire("Session is Lost!", "Please login Back to resume", "error").then(()=>
            navigate("/")
            )
          }
        }
      });
  },[]);

  const handleAssetChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setAssetId("")
      setCustomId("")
      setTax("")
      setTaxamount(0)
      setCgst(0)
      setSgst(0)
      setTotalamount(0)
      setAmount(0)
    } else {
      setCustomId(newValue);
      AssetsService.GetAssetById(newValue).then(response=>{
        setAssetId(response.data.id)
        setitemid(response.data.itemid)
        setitemname(response.data.itemname)
      setTaxamount(0)
      setCgst(0)
      setSgst(0)
      setTotalamount(0)
      setAmount(0)
        // setAvilablequantity(response.data.quantity)
        InventoryService.retriveItem(response.data.itemid).then((res)=>{
          setTax(res.data.tax)
        }).catch((err)=>console.log(err))
      })
      
    }
  };


  const handleTypeChange = (e) => {
    setType(e.target.value);
  };
  const handleamountChange =(e)=>{
    let value = e.target.value?parseFloat(e.target.value):0
    setAmount(value)
    let taxamt = 0;
    if(taxtype === "GST" &&
      ccountry === "India" &&
        gsttype === false){
        taxamt = ( value * tax/2) /100
        setCgst(taxamt)
        setSgst(taxamt)
        taxamt = taxamt * 2
        }else{
          taxamt = ( value * tax) /100
        }  
        setTaxamount(taxamt)
    setTotalamount(value + taxamt)
  }

  const handlesupplier = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      let lv = "Personal";
      if (values.ledgerid === null || values.ledgerid === undefined) {
        if (accounts === "YES") {
          alert(
            `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done`
          );
        }
      }
      setSupplierId(values.id)
        setSupplier(values.companyname)
        setSupcountry(values.countryname)
        setTaxtype(values.taxtype)
        setGsttype(values.statename === cstate && values.countryname === ccountry? false: true)
    }else{
      setSupplierId("")
      setSupplier("")
      setSupcountry("")
      setTaxtype("")
      setGsttype(false)
    }
  };
  

  const onSubmit =()=>{
    setLoader(true)
    let data = {
      itemid:itemid,
      itemname:itemname,
      tenantid:currentTenant,
      quantity:1,
      type:type,
      createdby:userid,
      updatedby:userid,
      description:description,
      amount:amount,
      asset:assetid,
      totalamount:totalamount,
	    taxamount:taxamount,
	    tax:tax,
      taxrequired:taxrequired?"TRUE":"FALSE",
	    taxtype: !taxrequired?"NA":taxtype,
      gsttype:!taxrequired?"NA":gsttype?"INTERSTATE":"INTRASTATE",
	    supplier:supplier,
	    supplierid:supplierId
    }
    AssetsService.NewAssetMaintenance(data).then(()=>
    navigate('/assetmaintenance')
    ).catch(()=>{
      setLoader(false)
    })
  }
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/assetmaintenance" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmaintenance">Asset Maintenance / Expenses</Link>
            </li>
            <li className="breadcrumb-item active">New Assets Maintenance / Expenses</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">New Assets Maintenance  / Expenses</h3>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{}}
                          // onSubmit={onSubmit}
                          validateOnChange={false}
                          // validate={validate}
                          enableReinitialize={true}
                        >
                          {({ setFieldValue, values }) => (
                            <Form
                              autoComplete="off"
                              style={{ paddingLeft: "30px" }}
                            >
                              <div className="form-row">
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="asset"
                                    options={assetidList}
                                    getOptionLabel={(option) => option}
                                    
                                    
                                    value={
                                      values.asset ? undefined : undefined
                                    }
                                    onChange={handleAssetChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="assetid"
                                        value={assetid}
                                        label={`Select AssetId (${customid})`}
                                        variant="standard"
                                        error={assetid === ""}
                                        helperText={
                                          assetid === "" ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="itemname"
                                        label={`Itemname `}
                                        value={itemname}
                                        variant="standard"
                                        // onChange={handleQuantityChange}
                                        fullWidth

                                      />
                                    </fieldset>
                                {/* <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="quantity"
                                        label={`Quantity (Avilable: ${avilablequantity})`}
                                        value={quantity}
                                        onChange={handleQuantityChange}
                                        error={
                                          quantity === "" ||
                                          quantity === undefined ||
                                          quantity > avilablequantity ||
                                          quantity === 0
                                        }
                                        helperText={
                                          quantity === "" ||
                                          quantity === 0 ||
                                          quantity === undefined
                                            ? "Enter Quantity"
                                            : quantity > avilablequantity
                                            ? `Quantity Exceeds`
                                            : " "
                                        }
                                        fullWidth

                                      />
                                    </fieldset> */}
                                    <fieldset className="form-group col-md-3">
                                          <FormControl
                                            sx={{minWidth: 120 }}
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-filled-label">
                                              Select Type
                                            </InputLabel>
                                            <Select
                                              value={type}
                                              onChange={handleTypeChange}
                                              variant="standard"

                                            >
                                              {types.map(
                                                (option) => {
                                                  return (
                                                    <MenuItem
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </FormControl>
                                        </fieldset>
                                    
                                        <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="supplier"
                                    options={suppliers}
                                    getOptionLabel={(option) =>
                                      option.companyname
                                    }
                                    value={
                                      values.supplier ? undefined : undefined
                                    }
                                    onChange={handlesupplier}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="supplier"
                                        value={supplier}
                                        label={`Select Supplier(${supplier})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                {supplierId!==""?<>
<fieldset
  className="form-group col-md-3 mt-2 m-0"
  disabled={itemname !== ""}
>
  <FormGroup row>
    <FormControlLabel
      control={
        <Checkbox
          checked={taxrequired}
          onChange={()=>setTaxrequired(!taxrequired)}
          name="taxrequired"
          color="primary"
        />
      }
      label="Tax Required?"
    />
  </FormGroup>
</fieldset>
{taxrequired === true ? (
  <>
    <fieldset className="form-group col-md-2 m-0">
      <FormControl
        variant="standard"
        className="w-100"
      >
        <InputLabel id="demo-simple-select-standard-label">
          Tax Type :&nbsp; {taxtype}
        </InputLabel>
      </FormControl>
    </fieldset>
    {taxtype === "GST" &&
    ccountry === "India" &&
    supplierId !== "" &&
    supcountry === "India" ? (
      <fieldset className="form-group col-md-4 m-0">
        <FormControl
          variant="standard"
          className="w-100"
        >
          <InputLabel id="demo-simple-select-standard-label">
            GST Type :&nbsp;{" "}
            {gsttype?"Inter State (Outside the State)": "Intra State (With in the State)"}
          </InputLabel>
        </FormControl>
      </fieldset>
    ) : (
      ""
    )}
  </>
) : (
  ""
)}
</>:""}

<fieldset className="form-group col-md-12 m-0 p-0"></fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="amount"
                                        label={`Amount`}
                                        value={amount}
                                        onChange={handleamountChange}
                                        variant="standard"
                                        error={
                                          amount === "" ||
                                          amount === undefined ||
                                          amount === 0
                                        }
                                        helperText={
                                          amount === "" ||
                                          amount === 0 ||
                                          amount === undefined
                                            ? "Enter amount"
                                            : " "
                                        }
                                        disabled={supplierId==="" || assetid === ""}
                                        fullWidth

                                      />
                                    </fieldset>
                                {taxrequired && supplierId!==""?<>
                                    {taxtype === "GST" ? (
                                ccountry === "India" ? (
                                  gsttype === false ? (
                                    <>
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="cgst"
                                          label={`CGST (${tax/2}%)`}
                                          value={cgst}
                                          variant="standard"
                                          disabled
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="sgst"
                                          label={`SGST (${tax/2}%)`}
                                          value={sgst}
                                          variant="standard"
                                          disabled
                                        />
                                      </fieldset>
                                    </>
                                  ) : (
                                    <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="number"
                                        name="tax"
                                        label={`IGST (${tax}%)`}
                                        value={tax}
                                        variant="standard"
                                        disabled
                                      />
                                    </fieldset>
                                  )
                                ) : (
                                  <fieldset className="form-group col-md-3">
                                    <TextField
                                      className="form-control"
                                      type="number"
                                      name="tax"
                                      label={`GST (${tax}%)`}
                                      value={tax}
                                      disabled
                                    />
                                  </fieldset>
                                )
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="number"
                                    name="tax"
                                    label={`VAT (${tax}%)`}
                                    value={tax}
                                    variant="standard"
                                    disabled
                                  />
                                </fieldset>
                              )}

<fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="amount"
                                        label={`Total Amount`}
                                        value={totalamount}
                                        variant="standard"
                                        disabled
                                        fullWidth

                                      />
                                    </fieldset>

                                    </>:""}


                                    <fieldset className="form-group col-md-12  mt-3 mb-5">
                                      <TextField name="description" className="form-control" multiline rows={2} label="Description" variant="outlined" value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                      />
                                    </fieldset>
                                    <button className="btn btn-sm hovbuttonColour mr-1" type="button" onClick={onSubmit} disabled={loader===true}>  {loader===true? <><Spinner animation="border" size="sm" /> &nbsp;{"Saving"}</>:<><i className="fa fa-save mr-1"/>{"Save"}</>}</button>
                                    <Link as="button" className="btn btn-sm deletebutton" to={{pathname: '/assetmaintenance'}} ><b className="text-danger"> X</b> Cancel</Link> 
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(NewAssetmaintenance);
