import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

class ProjectManagementServices {

   AddEstimationParameters(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addestimationparameters`, data)
   }
   GetEstimationParameters(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getestimationparameters/${currentTenant}`,)
   }
   GetEstimationParametersById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getestimationparametersbyid/${id}`,)
   }
   DeleteEstimationParameters(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deleteestimationparameters/${id}`,)
   }

   NewProject(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/newproject`, data)
   }
   GetProjectById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getprojectbyid/${id}`,)
   }
   UpdateProjectById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updateprojectbyid/${id}`,)
   }
   DeleteTheProject(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deletetheproject/${id}`,)
   }
   FindPendingProjects(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findpendingprojects/${currentTenant}`,)
   }
   GetActiveProjects(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getactiveprojects/${currentTenant}`,)
   }
   GetCompletedProjects(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getcompletedprojects/${currentTenant}`,)
   }
   GetCancelledProjects(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getcancelledprojects/${currentTenant}`,)
   }

   AddSite(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addsite`, data)
   }
   DeleteSite(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deletesite/${id}`,)
   }

   AddSiteIncharge(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addsiteincharge`, data)
   }
   DeleteSiteIncharge(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deletesiteincharge/${id}`,)
   }

   AddClientAssign(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addclientassign`, data)
   }
   DeleteClientAssign(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deleteclientassign/${id}`,)
   }

   AddProjectManager(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addprojectmanager`, data)
   }
   DeleteProjectManager(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deleteprojectmanager/${id}`,)
   }

   UpdateSiteStatus(data) {
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updatesitestatus`, data)
   }

   GetSitesOfProject(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getsitesofproject/${id}`)
   }


   //   ------------------------------ Project Estimations --------------------------------

   AddNewProjectEstimation(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/newprojectestimation`, data)
   }

   GetProjectEstimationById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getestimationbyid/${id}`,)
   }

   GetActiveEstimations(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getactiveestimations/${currentTenant}`,)
   }

   GetCompletedEstimations(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getcompletedestimations/${currentTenant}`,)
   }

   UpdatedEstimation(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updatedestimation`, data)
   }

   DeleteByEstimatedId(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deletebyestimatedId/${id}`,)
   }

   //   ------------------------------ Project Indenting --------------------------------

   SaveProjectIndenting(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/newindent`, data)
   }

   FindIndentByIndentId(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findindent`, id)
   }

   FindIndentByProjectId(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findindentbyprojectid/${id}`,)
   }

   UpdateTheProjectIndent(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updateindent/${id}`,)
   }

   DeleteTheProjectIndent(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deleteintent/${id}`,)
   }

   //   ------------------------------ Labour Type --------------------------------

   SaveLabourType(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/savelabour`, data)
   }

   FindLabourTypeByTenantId(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findlabourbytenantid/${currentTenant}`,)
   }

   UpdateLabourType(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updatelabourtype/${id}`,)
   }

   DeleteTheLabourType(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deletelabourtype/${id}`,)
   }

   //   ------------------------------ Consumption -------------------------------------

   SaveConsumption(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/saveconsumption`, data)
   }


   FindConsumptionById(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findconsumption/${id}`,)
   }

   FindConsumptionByProjectId(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findconsumptionbyprojectid/${id}`,)
   }

   UpdateConsumption(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updateconsumption/${id}`,)
   }

   DeleteTheConsumption(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deleteconsumption/${id}`,)
   }

   //   ------------------------------ Store --------------------------------

   SaveStoreData(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/savestore`, data)
   }

   FindStoreById(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findstore/${id}`,)
   }

   FindAllStore(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/showallstore`, data)
   }

   UpdateTheStore(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updatestore/${id}`,)
   }

   DeleteTheStore(id) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deletestore/${id}`,)
   }

}
export default new ProjectManagementServices();