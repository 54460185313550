import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, ccountry, einvoice, } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';

import "date-fns";

import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import InventoryService from "../../inventory/InventoryService.js";
import { Spinner } from "react-bootstrap";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices.js";
import DepartmentServices from "../../Masters/Department/DepartmentServices.js";
import CategoryServices from "../../Masters/Category/CategoryServices.js";
import UomServices from "../../Masters/Uom/UomServices.js";
import UserService from "../../users/UserService.js";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SalesServices from "../../Sales/SalesServices.js";
import TransporterServices from "../../Masters/Transporter/TransporterServices.js";
import Swal from "sweetalert2";

class AddStockIssue extends Component {
  constructor(props) {
    super(props);

    const { pagename } = this.props===undefined || this.props.location.state===undefined?'':this.props.location.state;
    this.state = {
      pagename: pagename,
      stockissuetype:"Store",
      stockissuetypelist:["Store","OrgUnit"],
      id: "",
      WarehouseList:[],
      whdata:'',
      whid:'',
      warehouse:'',
      towarehouse:"",
      towhid:"",
      departmentList:[],
      department:'',
      deptid:'',
      userList:[],
      reciver:'',
      reciverid:'',
      CategoryList:[],
      category:'',
      catid:'',
      itemsList:[],
      itemid:'',
      itemname:'',
      filteritemlist:[],
      additemsList:[],
      cost:'',
      hsn:'',
      quantity:'',
      UOMList:'',
      avlquantity:"",
      uom:'',
      uomid:"",
      brand:'',
      model:'',
      discription:'',
      to:'',
      loader: false,
      filteritems:[],
      selectbutton:false,
      transportername: null,
      deliverymethods: [
        { value: "1", name: "Own Vehicle" },
        { value: "2", name: "Hired Vehicle" },
        { value: "3", name: "GTA" },
        { value: "4", name: "Others" },
      ],
      transmode: 1,
      vehicletypes: [
        { value: "O", name: "ODC" },
        { value: "R", name: "Regular" },
      ],
      vehicletype: "R",
      vehiclenum: null,
      distance: 0,
      transporterselectiontype: false,
      transporterid: null,
      QRcode: "",
      ewaybillQRcode: "",
      ewaybilldata: "",
      supplytype: "I",
      transportationmode: "1",
      transactiontype: 1,
      ewaybillrequired:false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handlereciver=this.handlereciver.bind(this);
  }

  handlewarehouse = (value, values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
      if(values.id === this.state.towhid){
        Swal.fire('','Cannot transfer to the same Org Unit')
        // this.setState({ whid: null, warehouse: null })
      }else{
    this.setState({ whid: values.id, warehouse: values.name })
    const id = values.id;
    if(this.state.stockissuetype === "Store"){
    DepartmentServices.deptbywarehouse(id).then((response) =>
      this.setState({
        departmentList: response.data
      })
    );
  }
  }
  // else{
  //   this.setState({userList:this.state.userdetails.filter((e)=>(e.whid===values.id))})
  // }
  }else{
    this.setState({ whid: null, warehouse: null })
  }
  };

  handleTowarehouse = (value,values)=>{
    if(values !== '' && typeof values === 'object' && values!==null){
      if(values.id === this.state.whid){
        Swal.fire('','Cannot transfer to the same Org Unit')
        // this.setState({ towhid: null, towarehouse: null })
      }else{
      this.setState({ towhid: values.id, towarehouse: values.name })
      const id = values.id;
      this.setState({userList:this.state.userdetails.filter((e)=>(e.whid===values.id))})
    }
  }else{
    this.setState({ towhid: null, towarehouse: null })
  }
  }
  
  handledept=(value,values)=>{
    if(values !== '' && typeof values === 'object' && values!==null){
      this.setState({
        department:values.name,
        deptid: values.id,
      })
    this.setState({userList:this.state.userdetails.filter((e)=>(e.whid===this.state.whid) && (e.deptid===values.id))})
    }
  }

  handlereciver(value,values){
    if(values !== '' && typeof values === 'object' && values!==null){
      this.setState({
        reciver:(values.first + values.last),
        reciverid: values.id,
      })
    }else{
      this.setState({
        reciver:'',
        reciverid:''
      })
    }
  }
  handleCategory = (value, values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({
      itemid: "",
      itemname: "",
      hsn: "",
      tax: "",
      cost: "",
      quantity: "",
    });
    this.setState({
      catid: values.id,
      category: values.category,
      filteritemlist: this.state.itemsList.filter(
        (e) => e.categoryid === values.id
      ),
    });
  }else{
    this.setState({
      itemid: "",
      itemname: "",
      hsn: "",
      tax: "",
      cost: "",
      quantity: "",
      catid: '',
      category: '',
      filteritemlist:[]
    });
  }
  };

  
  handleitem = (value, values) => {
    // console.log(values)
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({
      itemid: values.id,
      itemname: values.name,
      quantity: "",
      totalpricewtax: "",
      filteritems: this.state.whdata.filter(e=>e.whid===this.state.whid && e.itemid === values.id),
      selectbutton:true
    });
    const id = values.id;
   
    let itemdata = this.state.itemsList.filter(e=>e.id === id)
    itemdata.map(data=>
      this.setState({
        type: data.type,
        // cost: data.price === undefined || data.price === null?0:data.price,
        // stock: data.totalstock,
        hsn: data.hsn,
        tax: data.tax,
        cgst: data.tax / 2,
        sgst: data.tax / 2,
      })
    )
    // let whitems = this.state.whdata.find(e=>e.whid===this.state.whid).saleslist
    //   let quant = whitems.find(e=>e.itemid===id).whstock
    //   this.setState({avlquantity:quant})
    // // );
     }else{
      this.setState({
        itemid: '',
      itemname: '',
      quantity: "",
      totalpricewtax: "",
      filteritems: [],
      selectbutton:true,
        type:'',
        // cost: '', undefined || '', null?0:'',
        // stock: '',
        hsn: '',
        tax: '',
        cgst: '', 
        sgst: ''
      })
     }
  };

  handleSelect = (id) => {
    if (id !== "") {
      // setFilteritems(this.state.filteritems.filter((e) => e.id === id));
      let data = this.state.filteritems.find((e) => e.id === id)

      this.setState({avlquantity:this.state.filteritems.find((e) => e.id === id).currentStock,
      cost:this.state.filteritems.find((e) => e.id === id).price,selectbutton:false, uom:data.uomname,
      brand:data.brand,
      model:data.model,
      });
      // setSelectbutton(false);
      // setmanufacturedate(
      //   filteritems.find((e) => e.id === id).manufacturingdate
      // );
      // setbaseamount();
      // setSid(id);
      // setBid(id);
    }
  };

  handleUOM(value,values){
    if(value !== ""){
      this.setState({
        uom:value,
        uomid: values.id,
      })
    }
  }

  onSubmit(values) {
    let addstockissue = {
      id: this.state.id,
      whid: this.state.whid,
      warehouse:this.state.warehouse,
      deptid:this.state.deptid,
      department:this.state.department,
      catid:this.state.catid,
      category:this.state.category,
      itemid:this.state.itemid,
      itemname:this.state.itemname,
      // hsn:this.state.hsn,
      cost:this.state.cost,
      totalquantity:parseFloat(this.state.quantity),
      quantity:parseFloat(this.state.quantity),
      uom:this.state.uom,
      uomid:this.state.uomid,
      description:this.state.discription,
      receivedby:this.state.reciverid,
      createdby: userid,
      tenantid: currentTenant,
    };
    this.setState({additemsList:[...this.state.additemsList,addstockissue]})
  }

  handleRemoveItem = (idx) =>{
    let filtereddata = this.state.additemsList.filter((e,i)=>i!==idx)
    this.setState({additemsList:[...filtereddata]})
  }

  TransporteridChange = (val) => {
    this.setState({
      transporterid: val,
    });
    if (val.length === 15) {
      let data = {
        tenantId: currentTenant,
        reqparam: val,
      };
      SalesServices.validateGSTIN(data).then((response) => {
        if (
          response.data.body === null ||
          response.data.body.statusCodeValue === 400
        ) {
          this.setState({ err: true, validtrnsid: false });
          Swal.fire({
            title: `Invalid Transporter ID`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
        } else {
          this.setState({ validtrnsid: true });
        }
      });
    } else {
      this.setState({
        err: false,
        validtrnsid: false,
      });
    }
  };

  handleTransporterChange = (value, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      this.setState({
        transportername: "",
        transporterid: "",
        validtrnsid: false,
      });
    } else {
      this.setState({
        transportername: newValue.transname,
        transporterid: newValue.transid,
        validtrnsid: true,
      });
    }
  };

  onfocus(){

    document.getElementById('quantity').addEventListener('mousewheel', function(e) {
     if (window.document.activeElement.type === 'number') {
       e.preventDefault()
     }
   })
   document.getElementById('quantity').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }

  handleSubmit = () =>{

    this.setState({ loader: true });
    let addstockissue = {
      id: this.state.id,
      whid: this.state.whid,
      warehouse:this.state.warehouse,
      deptid:this.state.deptid,
      department:this.state.department,
      catid:this.state.catid,
      category:this.state.category,
      itemid:this.state.itemid,
      itemname:this.state.itemname,
      // hsn:this.state.hsn,
      cost:this.state.cost,
      totalquantity:parseFloat(this.state.quantity),
      uom:this.state.uom,
      uomid:this.state.uomid,
      description:this.state.discription,
      receivedby:this.state.reciverid,
      createdby: userid,
      tenantid: currentTenant,
    };

    let data = {
      fromwhid:this.state.whid,
      deptid:this.state.stockissuetype==="Store"?this.state.deptid:null,
      stocktransfertype:this.state.stockissuetype,
      towhid:this.state.stockissuetype==="Store"?null:this.state.towhid,
      createdby:userid,
      updatedby:userid,
      receivedby:this.state.reciverid,
      description:"",
      tenantid:currentTenant,
      vehiclenum:this.state.vehiclenum,
      transporterid:this.state.transporterid,
      transportername:this.state.transportername,
      distance:this.state.distance,
      vehicletype:this.state.vehicletype,
      supplytype:this.state.supplytype,
      transportationmode:this.state.transportationmode,
      transactiontype:this.state.transactiontype,
      stocktransferitem:this.state.additemsList,
      ewaybillrequired:this.state.ewaybillrequired
    }
      InventoryService.AddStockTransfer(data).then(() =>
        this.props.navigate(-1)
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    
    
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    // const sid = this.props.location.state.id;
    WarehouseServices.getWarehouses(currentTenant).then((response) =>
      this.setState({
        WarehouseList: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
      })
    );

    UomServices.getUOM(currentTenant).then((response) =>
      this.setState({
        UOMList: response.data,
      })
    );

    InventoryService.GetDataToAddStock(currentTenant).then((res) =>
      this.setState({ itemsList: res.data })
    );
    InventoryService.GetItemsforStockissue(currentTenant).then(res=>
      this.setState({whdata:res.data})
      )
    
    UserService.Userwhanddpt(currentTenant).then((res)=>{
      this.setState({userdetails:res.data,})
    })

    TransporterServices.getTransporters(currentTenant).then((response) =>
      this.setState({ transporterslist: response.data })
    );


    // if (sid < 1) {
    //   return;
    // }
  //   InventoryService.stockDataToUpdate(sid).then((response) =>
  //     this.setState({
  //       sid: sid,
  //       id: response.data.id,
  //       name: response.data.name,
  //       warehouse: response.data.waddress,
  //       stock: response.data.currentStock,
  //       cost: response.data.rate,
  //       mfdate: response.data.manufacturingdate,
  //       expdate: response.data.expirydate,
  //       cid: response.data.cid,
  //       wid: response.data.wid,
  //     })
  //   );
  }

  validate(values) {
    let errors = {};
    if (this.state.warehouse === "") {
      errors.warehouse = "Please Select Org Unit";
    }

    if ( this.state.stockissuetype ==="Store" && this.state.department === "") {
      errors.department = "Please Select Department/Store";
    }

    if ( this.state.stockissuetype ==="Org Unit" && (this.state.towhid === "" || this.state.towhid=== null)) {
      errors.towarehouse = "Please Select Receving Org Unit";
    }
    if (this.state.reciver === "") {
      errors.reciver = "Please Select User";
    }
    if (this.state.itemname === "") {
      errors.itemname = "Please Select An Item";
    }
    if (this.state.quantity === "") {
      errors.quantity = "Please Enter Quantity";
    }else if(this.state.quantity>this.state.avlquantity){
      errors.quantity = `Quantity Exceeds (avilable:${this.state.avlquantity})`;
    }
    if (this.state.category === "") {
      errors.category = "Please Select An Category";
    }
    if (this.state.uom === "") {
      errors.uom = "Please Select An UOM";
    }
    if (this.state.discription === "") {
      errors.discription = "Please Enter Discription";
    }
    
    return errors;
  }

  handleEwayBillRequiredStatue = (e) =>{
    this.setState({ewaybillrequired:e.target.checked})
  }

  render() {
    // let WarehouseList = this.state.WarehouseList;
    // let itemsList = this.state.itemsList;
    let { id,WarehouseList, warehouse, whid,departmentList,deptid,department,CategoryList,category,catid, itemsList,itemid,itemname,filteritemlist,brand,model,cost,hsn,quantity,UOMList,uom,discription,reciver,reciverid,userList
    } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/stockissue"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/stockissue">Stock Issue</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{ id,WarehouseList, warehouse, whid,departmentList,deptid,department,CategoryList,category,catid,itemsList,itemid,itemname,filteritemlist,brand,model,
                  cost,hsn,quantity,UOMList,uom,discription,reciver,reciverid,userList
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                           Select Issue type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.stockissuetype}
                                            disabled={this.state.additemsList.length>0}
                                            onChange={(e) =>
                                              this.setState({
                                                stockissuetype: e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.stockissuetypelist.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="warehouse"
                              options={WarehouseList}
                              getOptionLabel={(option) => option.name}
                              
                              disabled={this.state.additemsList.length>0}
                              value={this.state.whid?this.state.WarehouseList.find(e=>e.id === this.state.whid):null}
                              onChange={this.handlewarehouse}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="warehouse"
                                  value = {this.state.warehouse}
                                  label={`Select Org Unit (${this.state.warehouse})`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>
                          {this.state.stockissuetype === "Store"?
                          <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="department"
                                    options={departmentList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    getOptionSelected={(option) =>
                                      option.name
                                    }
                                    value={
                                      values.department ? undefined : undefined
                                    }
                                    onChange={this.handledept}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="department"
                                        value={this.state.department}
                                        label={`Select Department/Store (${this.state.department})`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>:
                                
                                <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="towarehouse"
                                  options={WarehouseList}
                                  getOptionLabel={(option) => option.name}
                                  value={this.state.towhid?this.state.WarehouseList.find(e=>e.id === this.state.towhid):null}
                                  onChange={this.handleTowarehouse}
                                  disabled={this.state.additemsList.length>0}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="towarehouse"
                                      value = {this.state.towarehouse}
                                      label={`Select Receiving Org Unit (${this.state.towarehouse})`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                                </fieldset>}
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="reciver"
                                    options={userList}
                                    getOptionLabel={(option) => option.first +" "+  option.last }
                                    value={values.reciver?undefined:undefined}
                                    onChange={this.handlereciver}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="reciver"
                                        value = {this.state.reciver}
                                        label={`Select Receiver (${this.state.reciver})`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="category"
                              options={CategoryList}
                              getOptionLabel={(option) => option.category}                              
                              value={values.category ? undefined : undefined}
                              onChange={this.handleCategory}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="category"
                                  value={this.state.category}
                                  label={`Select Category (${this.state.category})`}
                                  variant="standard"
                                  fullwhidth="true"
                                />
                              )}
                            />
                          </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="itemname"
                                    options={filteritemlist}
                                    getOptionLabel={(option) => option.name}
                                    
                                    
                                    value={values.itemname ? undefined : undefined}
                                    onChange={this.handleitem}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="itemname"
                                        value={this.state.itemname}
                                        label={`Select Item (${this.state.itemname})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>

                                {this.state.selectbutton === true ? (
                                  this.state.filteritems.length === 0 ? (
                                    <div className="col-md-12">
                                      Sorry, no matching records found
                                    </div>
                                  ) : (
                                    <TableContainer>
                                      <Table className="table-sm">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell className="text-center">
                                              Itemname
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Price
                                            </TableCell>
                                            <TableCell className="text-center">
                                              CurrentStock
                                            </TableCell>
                                            {this.state.selectbutton === true ? (
                                              <TableCell className="text-center">
                                                Actions
                                              </TableCell>
                                            ) : (
                                              ""
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.filteritems.map((e, index) => (
                                            e.currentStock !==0?
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {e.itemname}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.price}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.currentStock}
                                              </TableCell>

                                              <TableCell className="text-center">
                                                <span
                                                  as="buttom"
                                                  className="btn btn-sm hovbuttonColour"
                                                  onClick={() =>
                                                    this.handleSelect(e.id)
                                                  }
                                                >
                                                  Select
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                            :''
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )
                                ) : (
                                  ""
                                )}
                                {this.state.itemname && this.state.selectbutton === false && <>
                                <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="cost"
                                value={this.state.cost === "" ? 0 : this.state.cost}
                                // onBlur={(e) => this.setState({ cost: e.target.value })}
                                label="Price"
                                variant="standard"
                                fullWidth
                                disabled
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="brand"
                              value={this.state.brand}
                              label="Brand"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="model"
                              value={this.state.model}
                              label="Model"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="uom"
                              value={this.state.uom}
                              label= "UOM"
                              disabled
                            />
                          </fieldset>

                  <fieldset className="form-group col-md-3">
                        <FormikTextField
                          id="quantity"
                          className="form-control"
                          type="number"
                          name="quantity"
                          label={`Quantity (Avilable:${this.state.avlquantity})`}
                          min="0"
                          onChange={(e) =>
                            this.setState({
                              quantity: e.target.value,
                              totalpricewtax: "",
                              availabelcredit: this.state.creditvalue,
                            })
                          }
                          onFocus={this.onfocus}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField name="discription" className="form-control" multiline rows={2} label="Description" variant="outlined"  value={this.state.discription}
                              onChange={(e) => this.setState({discription: e.target.value })}
                            />
                          </fieldset>
                          </>}
                    </div>
                    <button className="btn btn-sm hovbuttonColour mt-1"  type="submit" handleSubmit>
                       Add to list
                    </button>
                    

                    {this.state.additemsList && this.state.additemsList.length>0 && <>

                            <div className="col-md-12 mt-2">
                            <TableContainer>
                                      <Table className="table-sm">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell className="text-center">
                                              Itemname
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Category
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Price
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Quantity
                                            </TableCell>
                                              <TableCell className="text-center">
                                                Action
                                              </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.additemsList.map((e, index) => (
                                            e.currentStock !==0?
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {e.itemname}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.category}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.cost}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.totalquantity} {e.uom}
                                              </TableCell>

                                              <TableCell className="text-center">
                                                <span
                                                  as="buttom"
                                                  className="btn btn-sm deletebutton"
                                                  onClick={() =>
                                                    this.handleSelect(index)
                                                  }
                                                >
                                                  Remove
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                            :''
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                            </div>

                    {ccountry === "India" && einvoice && <>

                      <div className="form-group col-md-12 py-2 my-2">
                   <FormGroup row className="mt-3">
                    <FormControlLabel
                      control={<Checkbox
                        checked={this.state.ewaybillrequired}
                        onChange={this.handleEwayBillRequiredStatue}
                        name="parameters" 
                        color="primary"
                      />}
                      label="Eway-Bill"
                    />
                    </FormGroup>
                    </div>
                        {this.state.ewaybillrequired && <>
                    <h3 className="card-title col-md-12 border-top border-bottom py-2 my-2">E Way bill details</h3>

                    <div className="col-md-12"> 

                    <Form className="form-group row">
                                      <fieldset className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="vehiclenum"
                                          label="Vehicel No."
                                          onChange={(e) =>
                                            this.setState({
                                              vehiclenum: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="distance"
                                          label="Approximate Distance(Km)"
                                          onChange={(e) =>
                                            this.setState({
                                              distance: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.distance === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.distance === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Mode of Transportation
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.transmode}
                                            onChange={(e) =>
                                              this.setState({
                                                transmode: e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.deliverymethods.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Vehicle Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.vehicletype}
                                            onChange={(e) =>
                                              this.setState({
                                                vehicletype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value="R">
                                              Regular
                                            </MenuItem>
                                            <MenuItem value="O">ODC</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transaction Types{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.transactiontype}
                                            onChange={(e) =>
                                              this.setState({
                                                transactiontype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={1}>
                                              Regular
                                            </MenuItem>
                                            <MenuItem value={2}>
                                              Bill To - Ship To
                                            </MenuItem>
                                            <MenuItem value={3}>
                                              Bill From - Dispatch From
                                            </MenuItem>
                                            <MenuItem value={4}>
                                              Combination of 2 and 3
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transportation Mode{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              this.state.transportationmode
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                transportationmode:
                                                  e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"1"}>
                                              Road
                                            </MenuItem>
                                            <MenuItem value={"2"}>
                                              Rail
                                            </MenuItem>
                                            <MenuItem value={"3"}>Air</MenuItem>
                                            <MenuItem value={"4"}>
                                              Ship
                                            </MenuItem>
                                            <MenuItem value={"5"}>
                                              inTransit
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Supply Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.supplytype}
                                            onChange={(e) =>
                                              this.setState({
                                                supplytype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"I"}>
                                              Inward
                                            </MenuItem>
                                            <MenuItem value={"O"}>
                                              Outward
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      <fieldset className="form-group col-md-12" />

                                      <fieldset className="form-group col-md-12 mt-2 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.transidreq}
                                                onChange={() =>
                                                  this.setState({
                                                    transidreq:
                                                      !this.state.transidreq,
                                                    transporterid: null,
                                                    transportername: null,
                                                  })
                                                }
                                                name="transidreq"
                                                color="primary"
                                              />
                                            }
                                            label="Add transporter details for Part A?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      {this.state.transidreq ? (
                                        <>
                                          <div className="col-md-12">
                                            <fieldset
                                              className={`form-group col-md-4  btn shadow-sm text-center ${
                                                this.state
                                                  .transporterselectiontype ===
                                                false
                                                  ? "borderedbutton2"
                                                  : " hovbuttonColour"
                                              }`}
                                              disabled={
                                                this.state
                                                  .transporterselectiontype ===
                                                false
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  transporterselectiontype: false,
                                                  transportername: "",
                                                  transporterid: "",
                                                })
                                              }
                                            >
                                              Enter Manually
                                            </fieldset>
                                            <fieldset
                                              className={`form-group col-md-4 shadow-sm  text-center btn ${
                                                this.state
                                                  .transporterselectiontype ===
                                                true
                                                  ? "borderedbutton2"
                                                  : " hovbuttonColour"
                                              }`}
                                              disabled={
                                                this.state
                                                  .transporterselectiontype ===
                                                true
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  transporterselectiontype: true,
                                                  transportername: "",
                                                  transporterid: "",
                                                })
                                              }
                                            >
                                              Select
                                            </fieldset>
                                          </div>
                                          {this.state
                                            .transporterselectiontype ===
                                          true ? (
                                            <>
                                              <fieldset className="form-group col-md-4">
                                                <Autocomplete
                                                  id="subaccount"
                                                  className="subacc-select"
                                                  name="transportername"
                                                  options={
                                                    this.state.transporterslist
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.transname
                                                  }
                                                  value={
                                                    values.transname
                                                      ? undefined
                                                      : undefined
                                                  }
                                                  onChange={
                                                    this.handleTransporterChange
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      className="form-control"
                                                      type="text"
                                                      name="transportername"
                                                      value={
                                                        this.state
                                                          .transportername
                                                      }
                                                      label={`Select Transporter (${this.state.transportername})`}
                                                      variant="standard"
                                                      error={
                                                        this.state
                                                          .transportername ===
                                                          "" && this.state.err
                                                      }
                                                      helperText={
                                                        this.state
                                                          .transportername ===
                                                          "" && this.state.err
                                                          ? "Empty field!"
                                                          : " "
                                                      }
                                                      fullWidth
                                                    />
                                                  )}
                                                />
                                              </fieldset>
                                              <fieldset className="form-group col-md-4 ">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transporterid"
                                                  label={`${
                                                    this.state.transporterid ===
                                                      "" ||
                                                    this.state.transporterid ===
                                                      null
                                                      ? "Transporter Id"
                                                      : " "
                                                  }`}
                                                  value={
                                                    this.state.transporterid
                                                  }
                                                  variant="standard"
                                                  disabled
                                                />
                                              </fieldset>
                                            </>
                                          ) : (
                                            <>
                                              <fieldset className="form-group col-md-4">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transportername"
                                                  label="Transporter Name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      transportername:
                                                        e.target.value,
                                                      err: false,
                                                    })
                                                  }
                                                  error={
                                                    this.state
                                                      .transportername === "" &&
                                                    this.state.err
                                                  }
                                                  helperText={
                                                    this.state
                                                      .transportername === "" &&
                                                    this.state.err
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  variant="standard"
                                                />
                                              </fieldset>
                                              <fieldset className="form-group col-md-4 ">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transporterid"
                                                  label="Transporter Id"
                                                  onChange={(e) =>
                                                    this.TransporteridChange(
                                                      e.target.value
                                                    )
                                                  }
                                                  error={
                                                    (this.state
                                                      .transporterid === "" ||
                                                      this.state.validtrnsid ===
                                                        false) &&
                                                    this.state.err
                                                  }
                                                  helperText={
                                                    this.state.transporterid ===
                                                      "" && this.state.err
                                                      ? "Empty field!"
                                                      : this.state
                                                          .validtrnsid === false
                                                      ? "Invalid Transportor Id"
                                                      : " "
                                                  }
                                                  variant="standard"
                                                />
                                              </fieldset>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Form>

</div>
</>}
</>}
                    <div className="form-group col-md-12" />
                   
                      {  this.state.loader === true ? (
                         <button className="btn btn-sm hovbuttonColour mt-1" disabled>
                        <>
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving..."}
                        </>
                        </button>
                      ) : (
                        <button className="btn btn-sm hovbuttonColour mt-1"  type="button" onClick={this.handleSubmit}>Save</button>
                     )}
                     </>}

                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1 mt-1"
                      to={{ pathname: "/stockissue" }}
                    >Cancel
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(AddStockIssue);
