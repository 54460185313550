import { Form, Formik } from 'formik'
import React, { Component } from 'react'
import {Link } from 'react-router-dom';
import {Autocomplete, TextField} from '@mui/material';
import { currentTenant, financialyearend, thousandsdisplay, transactionFrom, userid} from '../../Common/Credentials.js';
import {noliaincomeexp, ruleid} from '../Ledgers/NewLedger'
import SubAccService from '../../Masters/Subaccounts/SubAccService.js';
import Header from '../../Component/Header.js';
import Footer from '../../Component/Footer.js';
import Menu from '../../Component/Menu.js';
import NewLedgerService from './NewLedgerService.js';
import { createTheme, FormControlLabel, FormLabel, ThemeProvider } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { assetledgers } from '../Ledgers/AssetsLiability.jsx';
import InventoryService from '../../inventory/InventoryService.js';
import IOSSwitch from '../../CommonComponents/IOSSwitch.js';
import MUIDataTable from 'mui-datatables';
import { NumericFormat } from 'react-number-format';import { FormikTextField } from '../../CommonComponents/FormField.js';
import  withRouter  from '../../withRouter.js';
import moment from 'moment';
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


let taxtype,indirecttaxtype,indirectsubtaxtype, subheads=[],subhead=null,typeid,subheadinv //ledgertype
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
 class NewAssetsLiability extends Component {

  constructor(props) {
    super(props)


    this.state = {
      taxtypes:[{id:1,name:"Direct Tax"},{id:2,name:"Indirect Tax"}],
      indirecttypes:[{id:0,name:"GST"},{id:1,name:"VAT"},{id:2,name:"Customs Duty"},{id:3,name:"Cess"}],
      indirectsubtypes:[{id:0,name:"State Tax"},{id:1,name:"Central Tax"},{id:2,name:"Integrated Tax"}],
      //inttaxtypes:[{id:0,name:"No"},{id:3,name:"Vat"}],
      // directtaxtypes:[{id:0,name:"No"},{id:1,name:"Yes"}],

      // ledgertypes:[{id:2,name:"MOVABLE-IMMOVABLE-ASSET"},{id:3,name:"CONSUMABLES"},{id:4,name:"RAW-MATERIALS"},{id:5,name:"LIQUID-ASSETS"}],
      // {id:5,name:"Deposits"},{id:8,name:"Capital Liability"},{id:9,name:"Org Liability"},
      // {id:10,name:"Statutory Liability"},{id:11,name:"Statutory Asset"}],
    id:'',
      subaccdata:[],
      displaychecked:false,
    error:'',
    invdata:[],
    ledgername:'',
    taxchecked:0,
    indirecttaxchecked:null,
    indirectsubtaxchecked:null,
    loader:false,
    invitems:[],
    ledtype:false,
    catid:null,
    fed:'',
    catname:null,
    itemid:null,
    itemname:null,
    invtotamt:null,
    addled:false,
    SDdate:false,
    invledger:0,
    sath:'',
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.goBack=this.goBack.bind(this);
    this.getData=this.getData.bind(this);
    this.onCheckClick=this.onCheckClick.bind(this);
  }

  componentDidMount() {
    typeid=null
    taxtype=null
    indirecttaxtype=null
    indirectsubtaxtype=null
    // this.setState({
    //   fed: moment(transactionFrom.$d).format("yyyy-MM-DD HH:mm:ss"),
    // });
    //let a1,b1,c1
    // b1=a1.concat(ledgertypes.filter((e)=> e.name!=="EXPENSE"))
    // c1=b1.concat(ledgertypes.filter((e)=> e.name!=="GAINS"))
    // ledgertype=c1.concat(ledgertypes.filter((e)=> e.name!=="LOSES"))


// NewLedgerService.getLedgerBehaviour()
// .then(response=>{
//   this.setState({ledgertypes:response.data})
// })
this.getData()
  subhead=null
}

handleSDDate=()=>{
  this.setState({SDdate:this.state.SDdate===true?false:true})
}
handleFsdDateChange = (date) => {
  if(date!==null){
  this.setState({ fsd: date.$d });
  this.setState({
    fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
  });
}else{
  this.setState({ SDdate:false})
}
};

getData(){
  InventoryService.invItems(currentTenant)
.then(response =>{
  this.setState({invitems:response.data})
})
  SubAccService.retriveSubIdName(currentTenant,ruleid)
  .then(response =>{
    this.setState({subaccdata:response.data})
  })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
}



goBack(){
  this.props.navigate(-1);

}

onSubmit(values) {
  // console.log(values.name)
  // console.log(values.name1)
  // console.log(values.openingbal1)
  // console.log(values.type1)

  

  this.setState({loader:false})
  // let itemid,itemname,categoryid,category
  // if(this.state.displaychecked!==false){
  //   itemid=values.invtype.id
  //   itemname=values.invtype.name
  //   categoryid=values.invtype.catid
  //   category=values.invtype.category
  // }
  let newLA = {
    createdby:userid,
    subacc:values.subacc!==undefined?values.subacc.id:null,
    itemid:this.state.itemid,
    itemname:this.state.itemname,
    categoryid:this.state.catid,
    category:this.state.catname,
    amount:this.state.sath==="TH4" && taxtype!==null?values.openingbal!==undefined?0-values.openingbal:0-this.state.invtotamt:values.openingbal!==undefined?values.openingbal:this.state.invtotamt,
    tenantId:currentTenant,
    rule:ruleid,
    taxtype:taxtype,
    date:this.state.fed!==''?this.state.fed:null,
    indirecttaxtype:indirecttaxtype,
    indirectsubtaxtype:indirectsubtaxtype,
    subhead:values.type!==undefined?values.type.id:values.type1.id,
    invtotamt:this.state.invtotamt,
    invledger:this.state.itemid!==null?1:0,
    name:values.name!==undefined?values.name:this.state.itemname,


  }


  if(typeid===100009 && taxtype===null){
      alert("Please Select Tax Type")
  // }else if(taxtype===0){
  //   alert("Please Select Tax Type")
  }
  else if(taxtype===2 && indirecttaxtype===null){
    alert("Please Select Indirect Tax Type")
  }else if(taxtype===2 && indirecttaxtype===0 && indirectsubtaxtype===null ){
    alert("Please Select GST Type")
  }else{

  if (this.state.id === '') {
    
    NewLedgerService.createLA(newLA)
    
    .then(() =>      
        this.props.navigate(`/newledgers`,{message:values.name + "   : Created Successfully"}))
        .catch(error => this.setState({error:error.message + "   Account Name Already Exists"}))
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
    
  }else {

}  
  } 
}



  validate(values) {
    let errors = {};
    // var chars = /^[A-Za-z ]+$/
    var num = /^[-,0-9,.]+$/
 
    if(this.state.ledtype===false){
    if (!values.subacc || values.subacc==='' || values.subacc==='Select  Account') {
      errors.subacc = "Accounts is required";
    }

    if(this.state.displaychecked!==false){
    if (!values.invtype || values.invtype==='' || values.invtype==='Select  Item') {
      errors.invtype = "Item is required";
    }
  }

    let a = assetledgers.map((e)=>e.name)
    let b =assetledgers.map((e)=>e.itemid)

    
    if(a.find(e=>e===values.name)){
      errors.name = 'Ledger already exists !! Please use a different name for Ledger';
    }
  //   if(values.name!==undefined && typeid===100007){
  //   if(b.find(e=>e===values.invtype.id)){
  //     errors.name = 'Inventory Ledger already Created ';
  //   }
  // }
    if (values.type===undefined || values.type==='' ) {
      errors.type = "Type is required";
    }
    if (!values.name) {
      errors.name = <span className="text-right">"Account Name is required"</span>;
    }
    // else if (!chars.test(values.name)) {
    //   errors.name = 'Only Characters are allowed';
    // }
 
    if (values.openingbal==='' || values.openingbal===undefined) {
      errors.openingbal = 'Opening Balance is required';
    }else if (!num.test(values.openingbal)) {
      errors.openingbal = 'Only Numbers are allowed';
    }
  }if(this.state.ledtype===true){
    // if (values.name1===undefined) {
    //   errors.name1 = <span className="text-right">"Account Name is required"</span>;
    // }
    if (!values.type1) {
      errors.type1 = "Type is required";
    }
  }
    return errors;

  }

  onTaxChange(option){
    this.setState({taxchecked: option.id});

    taxtype=option.id
  
 }

 onIndictTaxChange(option){
  this.setState({indirecttaxchecked: option.id});
  indirecttaxtype=option.id
 }

 onIndictSubTaxChange(option){
  this.setState({indirectsubtaxchecked: option.id});
  indirectsubtaxtype=option.id
 }


onAccountChange(subacc){
  //console.log(subacc)
  let val1= this.state.subaccdata.filter((e) => e.rule === 1 )
   if(subacc!==undefined){
  let val3 = val1.filter((e)=>e.id===subacc.id);
  let val4=val3.filter(e=>e.id!=="AH000020")
  let val2=val4.filter(e=> e.id!=="AH000021")

  this.setState({sath:subacc.th})
  val2.map((e) => {return subheads = e.subheads})
  if(subheads!==undefined){
  subhead = subheads.filter(e=>e.rule!==0 )
   }
 }
}
addInvData(){
InventoryService.getItemsWithCatAndAmt(currentTenant).then((response)=>
{
this.setState({invdata:response.data})
})
}

onCheckClick=event=>{
  this.setState({ displaychecked: event.target.checked })
  this.addInvData()
}
handleLedLype=()=>{
  this.setState({ledtype:this.state.ledtype===true?false:true})
}

onTypeChange(type){
  if(type!==undefined && subhead!==undefined){
  //   console.log(this.state.subaccdata)

  //   let val1
  //   this.state.subaccdata.filter((e) => 
  //   {
  //     return e.subheads.map(f=>f.id===type.id)
  //   }
  // )
  //   console.log(val1)

    typeid=type.id

    if(typeid===100007){ 
     // if(!this.state.invdata){
    //  }
    //   Swal.fire({
    //    title: "Inventory",text: "Do you want to add Inventory Ledger",icon: "question",confirmButtonText: "Yes",
    //    showCancelButton: true,cancelButtonText:"No",confirmButtonColor: "#152f5f",
    //  })
    //  .then((result)=>{
    //    if(result.value===true){

    //     this.setState({invled:result.value})
    //     this.addInvData()

 
    //          }
             
    //      })
    } 
     
 
   // alert(typeid)

  }
}
addLedger(result){
  // console.log(result)
 this.setState({addled:true,catid:result[0],catname:result[1],itemid:result[2],
                  itemname:result[3],invtotamt:result[7]})
let val1= this.state.subaccdata.filter((e) => e.rule === 1 )
let val3 = val1.filter((e)=>e.id==="AH000003" ||e.id==="AH000026");
let val2=val3.filter(e=>e.id!=="AH000020" && e.id!=="AH000021")
 val2.map((e) => {return subheads = e.subheads})
 if(subheads!==undefined){
 subheadinv = subheads.filter(e=> e.rule!==0 )
 }
}
    render() {

      // ledgertype=this.state.ledgertypes;
      let {name} = this.state
      let subaccdata=null,val=null,taxtypedata
      if(this.state.subaccdata!==undefined){
        subaccdata = this.state.subaccdata.filter(e=>e.id!=="AH000020" && e.id!=="AH000021" && e.id!=="AH000022")
        if(subaccdata!==null){
        val=subaccdata.filter((e) => e.rule === 1  )
        }
      }else{
        this.getData()
      }
     
        taxtypedata=this.state.taxtypes

        let assets =[...new Set(this.state.invitems.filter((e) => {return (e.stocktype==="RESALE" || e.stocktype==="RAWMATERIAL" || 
         e.stocktype==="FINISHEDPRODUCT" || (e.stocktype==="INTERNALUSE" && e.substocktype!==null ))})),]
        let assetitems
        if(assets.length!==0){
        assetitems=assets.filter((ast)=>!noliaincomeexp.find((sec)=>ast.itemid===sec.itemid))
        // console.log(assetitems)
        }


        
        const INTERASSETS = [
          {name: "catid",label: "catid",options: {filter: false,sort: false,display:false,viewColumns:false}},
          {name: "category",label: "category",options: {filter: false,sort: false,display:false,viewColumns:false}},
          {name: "itemid",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
          {name: "item",label:"Item",options: {filter: false,sort: false}},
          {name: "stock",label:"Stock",options: {filter: true,sort: true}},
          {name: "stocktype",label:"Stock Type",options: {filter: false,sort: true,viewColumns:false}},
          {name: "substocktype",label: "Sub Stock",options: {filter: false,sort:true,viewColumns:false}},
          {name: "totalvalue",label:"Amount",options: {filter: true,sort: true,
            customBodyRender: (value) => {
              return (
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: value => ({ style: { textAlign:'right'}}),
          }},         
         
        {name: "itemid",label:"Action",options: {filter: false,sort: false,display:true,viewColumns:false,
              customBodyRender:(value,tableMeta)=>{
                return (
                <button className="hovbuttonColour btn btn-sm"
                onClick={() => this.addLedger(tableMeta.rowData)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Add"}
                </button>
                );      
    
              },
              setCellProps: value => ({ style: { textAlign:'center'}}),

            }},
           
        ]
        const assetoptions = {
          filterType: 'checkbox',
          selectableRows: "none",
         // responsive: "scroll",
          resizableColumns: true,          
        }





        
    //   invitems=[...new Set(this.state.invitems.filter((e) =>{return e.itemid})),]
    //   //invitems=[...new Set(this.state.invitems.filter((e)=>e)),]

    //   //  invitems=[...new Set(this.state.invitems.filter((v, i, a)=>a.indexOf(v) === i)),]
    //   //  invitems= this.state.invitems.filter((v, i, a)=>a.indexOf(v) === i)
    //   // (v, i, a) => a.indexOf(v) === i
    //   // invitems.map((e)=>{ return invfilter=
    //  // invfilter=this.state.invitems.map(f=>f)
    //     console.log(invitems)
    //     console.log(invfilter)


        return (
          <div>
            <Header />
            <Menu/>
          <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">{this.state.name}</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/newledgers">New Assets-Liabilities Ledger</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>

            <div className="card-body">


                <Formik
              
              initialValues={{name,val,taxtypedata}}
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validate={this.validate}
              enableReinitialize={true}>
              {({setFieldValue,values}) => (
                
                <Form autoComplete="off">
                {/* <span className="text-center text-red ">{"Note : Please use Current Liabilities --> Duties and Taxes for tax ledgers" }<br/>
                {this.state.sath==="TH4"?"     For Liability Ledgers Opening: For Surplus(Receivable) please use (-) ex:-0000 before value else without (-) ex:0000":""}</span> */}

                  <div className="form-row mt-3">
                  <fieldset className="form-group col-md-3">
                              <span className="mx-3">{this.state.ledtype===false?"General":"Inventory"}</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.ledtype} onChange={this.handleLedLype} name="checkedB" />}
                              />
                    <span >Ledgers</span>
                            </fieldset>

                            <div className="form-group col-md-3 text-left">
                  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="form-group col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} variant="standard" />} autoOk={true} />
    </Grid></LocalizationProvider></div>
     :""}
          {/* <div className="form-group col-md-6 text-left"></div> */}


{this.state.ledtype===false?<>
        <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="subaccount"
                      className="subacc-select"
                      name="subacc"
                      options={val}
                      getOptionLabel={(option) => option.name +"(" +option.thname +")"}
                      
                      
                      value={values.subacc?undefined:undefined}
                      onChange={(e, value) => {
                       setFieldValue("subacc", value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        onBlur={()=>this.onAccountChange(values.subacc)}
                        onChange={(e, value) => {
                          setFieldValue("type", "");
                           }}
                        name="subacc"
                        // style={{ height }}
                        placeholder="Select  Account"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                      </fieldset>

      
            {subhead!==null?
                      <fieldset className="form-group col-md-8">
                      <Autocomplete
                      id="ledgertype"
                      className="type-select"
                      name="type"
                      options={subhead}
                      getOptionLabel={(option) => option.name}
                      
                      
                      value={values.type?undefined:undefined}
                      onChange={(e, value) => {
                       setFieldValue("type", value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="type"
                        onBlur={()=>this.onTypeChange(values.type)}

                        // style={{ height }}
                        placeholder="Select Ledger Type"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                   
                      </fieldset>:""
                      // :  <div className="form-group col-md-6 form-check form-switch mt-0 mb-0"></div>  
}

{/* { typeid===100007? 
  <div className="form-group col-md-4 form-check form-switch mt-0 mb-0">  
  <label className="text-sm">
 <Checkbox
   checked={this.state.displaychecked}
   onChange={this.onCheckClick}
 />
 {this.state.displaychecked===true?"Show Invoice Items":"Show Invoice Items"}
</label>
  </div>
:<div className="form-group col-md-4 form-check form-switch mt-0 mb-0 ">  
<label className="text-sm">
<Checkbox disabled
 checked={this.state.displaychecked}
 onChange={this.handleCheckboxChange}
/>
{this.state.displaychecked===true?"Hide Invoice Items":"Show Invoice Items"}
</label>
</div>}

                      {this.state.displaychecked==true?
                      <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="invitem"
                      className="type-select"
                      name="invtype"
                      options={this.state.invdata}
                      groupBy={(option) => option.category}
                      getOptionLabel={(option) => option.name}
                      
                      
                      value={values.type?undefined:undefined}
                      onChange={(e, value) => {
                      setFieldValue("name", value.name) && 
                      setFieldValue("openingbal", value.invvalue) && 
                       setFieldValue("invtype", value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="invtype"
                        onBlur={()=>this.onTypeChange(values.type)}

                        // style={{ height }}
                        placeholder="Select Inventory Item"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                      </fieldset>:""} */}

                      <fieldset className="form-group col-md-6">
                      <FormikTextField

                        className="form-control"
                        name="name"
                        // style={{ height }}
                        placeholder="Ledger Name"
                        variant="standard"
                        onBlur={()=>setFieldValue("openingbal",0)}
                        fullWidth
                        
                      />
                     </fieldset>

                     <fieldset className="form-group col-md-6">
                    <FormikTextField
                      className="form-control"
                      name="openingbal"
                      // style={{ height }}
                      placeholder="Enter Opening Balance"
                      variant="standard"
                      fullWidth
                    />
                    </fieldset> 
                    

   

{typeid===100009?<>
<fieldset  className="form-group col-md-12 mb-0 pb-0 "> 


<FormLabel> Tax Ledger Type ?</FormLabel>

{taxtypedata.map((option)=>{

return <label key={option.id} >

    <div className="form-group col-md-12">

      <div className="radio-item">

             <input 
                type="radio" 
                checked={this.state.taxchecked === option.id ? true : false} 
                key={option.id}
                onChange={this.onTaxChange.bind(this,option)} 
                style={{marginRight:8}}
                value={option.id} 
                />
            {option.name}
        
            </div>
            </div>
        </label>
       

})}

   </fieldset>
   
{this.state.taxchecked===2?

   <fieldset  className="form-group col-md-12 mb-0 pb-0 "> 

<FormLabel>Indirect Tax Type ?</FormLabel>

{this.state.indirecttypes.map((option)=>{

return <label key={option.id} >

    <div className="form-group col-md-12">

      <div className="radio-item">

             <input 
                type="radio" 
                checked={this.state.indirecttaxchecked === option.id ? true : false} 
                key={option.id}
                onChange={this.onIndictTaxChange.bind(this,option)} 
                style={{marginRight:8}}
                value={option.id} 
                />
            {option.name}
        
            </div>
            </div>
        </label>
       

})}

   </fieldset>:""}
{this.state.indirecttaxchecked===0 && this.state.taxchecked===2?
   <fieldset  className="form-group col-md-12 mb-0 pb-0 "> 

<FormLabel>GST ACCOUNTS</FormLabel>

{this.state.indirectsubtypes.map((option)=>{

return <label key={option.id} >

    <div className="form-group col-md-12">

      <div className="radio-item">

             <input 
                type="radio" 
                checked={this.state.indirectsubtaxchecked === option.id ? true : false} 
                key={option.id}
                onChange={this.onIndictSubTaxChange.bind(this,option)} 
                style={{marginRight:8}}
                value={option.id} 
                />
            {option.name}
        
            </div>
            </div>
        </label>
})}
</fieldset>:""}                    
</> :""}                  
</>:<>
 <div className="form-group col-md-12">
 <ThemeProvider theme={theme}>
<MUIDataTable className="table-sm px-3" title={`Asset Items`} data={assetitems} columns={INTERASSETS} options={assetoptions} 
                 />
 </ThemeProvider> 
  {/* <div className="form-group col-md-12">
      <FormLabel>Ledger  : &nbsp; {this.state.catname}</FormLabel> 

      <table 
        id="example1"
        className="table table-borderless table-sm text-sm ">
       
        <thead >
          <tr>
          <th className="form-group col-md-5">Item Name</th>
          <th className="form-group col-md-5">Category</th>
          <th className="form-group col-md-5">Amount</th>
 
          </tr>
        </thead>
        
        <tbody>
          
         
              <tr>
                <td className="form-group col-md-5 text-blue">{this.state.itemname}</td>
                <td className="form-group col-md-5 text-blue">{this.state.catname}</td>
                <td className="form-group col-md-5 text-blue">{this.state.invtotamt}</td>
       

              </tr>
              
               
        </tbody>                                
      </table> 
 
 </div> */}
 </div>

 <div className="form-row col-md-12">
 {this.state.addled===true?<>
  <fieldset className="form-group col-md-4">
   <FormikTextField className="form-control" name="name1" placeholder="Ledger Name" variant="standard" value={this.state.itemname} fullWidth/>
  </fieldset>
  {/* helperText={this.state.name1 === '' ? "Empty field!" : ''} */}

  
 <fieldset className="form-group col-md-4">
  <FormikTextField className="form-control text-right" name="openingbal1" placeholder="Enter Opening Balance"  variant="standard" defaultValue={this.state.invtotamt} fullWidth/>
  </fieldset> 
  <fieldset className="form-group col-md-4">
  <Autocomplete id="ledgertype1" className="type-select" name="type01" options={subheadinv} getOptionLabel={(option) => option.name}
     value={values.type?undefined:undefined}
   onChange={(e, value) => { setFieldValue("type1", value); }}
    renderInput={params => (
    <FormikTextField {...params}
    className="form-control" type="text" placeholder="Select Ledger Type" variant="standard" fullWidth name="type1"
    // onBlur={()=>this.onTypeChange(values.type)} 
    />)} /> </fieldset>
</>:""}</div>
</>}

</div>
{/* <br></br> */}

                  <div>
                  <div className="form-row">
                    <fieldset className="form-group col-md-12">
                  <button className="btn hovbuttonColour mr-1 btn-sm" type="submit"><i className="fas fa-pen"/>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</>:"Save"}</button>
                  {/* </fieldset>
                  <fieldset className="form-group col-md-1">  */}
                  <button className="btn deletebutton btn-sm" type="button" onClick={this.goBack}><i className="fas fa-times"/>Close</button>
                  </fieldset>
                  </div>
                  </div>
                 
                  

                </Form>


              )}
            </Formik>
            </div>
            </div>
            </div>
            <Footer/>
            </div>
        )
    }
}
export default withRouter(NewAssetsLiability)
