import React, { useEffect, useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import UsercustomerService from "../Masters/Usercustomers/UsercustomerService.js";
import SettingsServices from "../Settings/SettingsServices.js";
import Credentials, { currentTenant, transactionFrom, userid } from "../Common/Credentials.js";
import dashboardIcon from "../images/Icons/dashboard.png"
import wagesIcon from "../images/Icons/wages.png"
import transactionsIcon from "../images/Icons/transaction.png"
import reportsIcon from "../images/Icons/annual-report.png"
import { Backdrop } from "@mui/material";

const AccountsMenu = () => {
  const [dnrequired,setDnrequired] = useState(true)
  const [usercustomers,setUsercustomers] = useState(false)
  useEffect(()=>{
      Credentials.setSelectedMenu("Accounts")
    SettingsServices.GetSettings(currentTenant).then((response) =>
    setDnrequired(response.data.dnreq)
    );
    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>setUsercustomers(res.data===''||res.data===undefined|| res.data===null?false:true))
  },[])
  return (
    <div>
       <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={transactionFrom === null}
      // onClick={handleClose}
    >
       <div className="flex-column ">
      <div className="d-flex justify-content-center">
      <i className="text-red">Please set transaction start date in order to continue.</i>
      </div>
      <div className="d-flex justify-content-center">
      <Link to="/settings" className="btn btn-sm hovbuttonColour">Set</Link>
      </div>
      </div>
    
    </Backdrop>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/accounts">Home</Link>
          </li>
          <li className="breadcrumb-item active">Accounts</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
        <ul className="d-flex flex-row list-unstyled">
              <li className="p-1 mr-4 ">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                   <img className="nav-icon mr-2" width="25" src={dashboardIcon}/>

                  Dashboard
                </Link>
              </li>
            </ul>
          <div className="row gap-x-8 gap-y-6 mt-0">
            <div className="col-sm-12 col-md-6 col-lg-4 w-full mt-2">
              <span className="font-weight-bold my-3">
                {/* <i className="nav-icon fas fas fa-th textColour" /> */}
                <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2">Transactions</span>
              </span>
              <ul className="menuul pl-4 ml-3">
              {screens.includes("Sub Heads / Groups") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{pathname: "/subheads"}}
                      state= {{ message: ""}}
                  >Sub Heads / Groups
                  </Link>
                </li>:""}
                {screens.includes("Create Ledger") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/newledgers" }}
                    state={{ message: "" }}
                  >Create Ledger
                  </Link>
                </li>
                :""}
                {screens.includes("Journal Entry") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/journals" }}
                    state={{ message: "" }}
                  >Journal Entry
                  </Link>
                </li>:""}
                {screens.includes("Salary Entry") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/salentry" }}
                    state={{ message: "" }}
                  >
                    Salary Entry
                  </Link>
                </li>:""}
                {screens.includes("Direct Stocks") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/dirstock" }}
                    state={{ message: "" }}
                  >
                    Direct Stocks
                  </Link>
                </li>:""}
                {screens.includes("Accounts Payable")?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/ap" }}
                    state={{ message: "" }}
                  >
                    Accounts Payable
                  </Link>
                </li>:""}
                {screens.includes("Accounts Receivable") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/ar" }}
                    state={{ message: "" }}
                  >
                    Accounts Receivable
                  </Link>
                </li>:""}
                {screens.includes("Direct Taxes") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/dtaxes" }}
                    state={{ message: "" }}
                  >
                    Direct Taxes
                  </Link>
                </li>:""}
                {screens.includes("Indirect Taxes") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/indirtaxes" }}
                    state={{ message: "" }}
                  >
                    Indirect Taxes
                  </Link>
                </li>:""}

                {screens.includes("Losses Entry") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/lossesl" }}
                    state={{ message: "" }}
                  >
                    Losses Entry
                  </Link>
                </li>:""}
                {screens.includes("Assets Loses/Expenses") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/depreciationl" }}
                    state={{ message: "" }}
                  >
                    Assets Loses/Expenses
                  </Link>
                </li>:""}
                {screens.includes("Rectification Entries") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/rectifications" }}
                    state={{ message: "" }}
                  >
                    Rectification Entries
                  </Link>
                </li>:""}
                {screens.includes("Debit Credit Notes") ?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/debitcreditnotes" }}
                    state={{ message: "" }}
                  >
                    Debit Credit Notes
                  </Link>
                </li>:""}
              </ul>
            </div>
            {/* {screens.includes("Employees Salary")? */}
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={wagesIcon}/>
                <span className="pl-2"> Payroll</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/employeesalary" }}
                    state={{ message: "" }}
                  >
                   Employees Salary
                  </Link>
                </li>
              </ul>
            </div>
            {/* :""} */}
            {screens.includes("Accounts Reports")?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
                {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                <img className="nav-icon" width="25" src={reportsIcon}/>
                <span className="pl-2"> Reports</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour"
                    to={{ pathname: "/trialbalance" }}
                    state={{ message: "" }}
                  >
                    Trial Balance
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour"
                    to={{ pathname: "/pandl" }}
                    state={{ message: "" }}
                  >
                    P&L
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour"
                    to={{ pathname: "/balancesheet" }}
                    state={{ message: "" }}
                  >
                    Balance Sheet
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour"
                    // to={{ pathname: "/ledgerview" }}
                    to={{ pathname: "/newledgerview" }}
                    state={{ message: "" }}
                  >
                    Party Ledgers View
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour"
                    to={{ pathname: "/bankstransreport" }}
                    state={{ message: "" }}
                  >
                    Bank/Cash Leders
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour"
                    to={{ pathname: "/ledgernominal" }}
                    state={{ message: "" }}
                  >
                    Income/Exp Ledgers
                  </Link>
                </li>
                <li>
                
                  <Link
                    className="textColour"
                    to={{ pathname: "/ledgerreal" }}
                   // onClick={alert("Coming Shortly")}
                    state={{ message: "" }}
                  >
                    Asset/Liabilities Ledgers 
                    {/* <span className="text-red">(Coming Shortly)</span> */}
                  </Link>
                </li>
                <li>
                
                <Link
                  className="textColour"
                  to={{ pathname: "/completetran" }}
                 // onClick={alert("Coming Shortly")}
                  state={{ message: "" }}
                >
                  Completed Pmt/Rects 
                  {/* <span className="text-red">(Coming Shortly)</span> */}
                </Link>
              </li>
                
                <li>
                <Link
                className="textColour"
                to={{ pathname: "/ledgeradv" }}
                 // onClick={alert("Coming Shortly")}
                  state={{ message: "" }}
                >
                  Advances 
                  {/* <span className="text-red">(Coming Shortly)</span> */}
                </Link>
              </li>
              </ul>
            </div>
            :""}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(AccountsMenu);
