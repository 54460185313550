import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import { appmasterid } from "../../Common/Permissions.js";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";
import CustomerService from "./CustomerService.js";
import { ThemeProvider, Tooltip } from "@mui/material";
import { createTheme } from "@mui/material";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class CustomerDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      customers: [],
      loading: false,
      // UserPermissions:''
    };
    this.refreshCustomer = this.refreshCustomer.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
  }

  deleteCustomer(id) {
    Swal.fire({
      title: "Delete Customer?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
         CustomerService.deleteCustomer(id).then(() => {
            this.setState({
              message: "Customer Deleted Successfully",
              loading: true,
            });
            Swal.fire({
              title: "Customer Deleted Successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshCustomer())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }else{
              Swal.fire({
                title: "An error occurred",
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          });
      }
    });

  }

  setAsSupplier(id){
    let data = {
      id: id,
      tenantid:currentTenant,
      createdby: userid
    }
    Swal.fire({
      title: "Create supplier with this customer details?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        CustomerService.setAsSupplier(data).then(() => {
            Swal.fire({
              title: "Supplier Added Successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshCustomer())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }else{
              Swal.fire({
                title: `${e.response.data}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          });
      }
    });
  }

  updateCustomer(id) {
    CustomerService.retriveCustomer(id).then((response) => {
      this.setState({ customers: response.data });
    });
  }


  returnOpeningBalance = (id)=>{
    console.log(id)
    let data = {
      partyid:id,
      ledgername:"",
      ledgerid:"",
      amount:0,
      tenantId:currentTenant,
      createdBy:userid
    }
    Swal.fire({
      title: "Return opening balance?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        CustomerService.addSalesBalRev(data).then(() => {
            Swal.fire({
              title: "Opening balance returned successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshCustomer())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }else{
              Swal.fire({
                title: `${e.response.data}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          });
      }
    });
  }
  

  refreshCustomer() {
    CustomerService.retriveAllCustomers(currentTenant).then((response) => {
      this.setState({ customers: response.data, loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))

    this.setState({ loading: true });

    if (this.props.location.state.message === null) {
      this.setState({ message: "" });
    } else {
      this.setState({ message: this.props.location.state.message });
    }

    this.refreshCustomer();
  }

  componentDidUpdate() {}

  render() {
    let confirm;
    if (this.state.message === "Customer Deleted Successfully") {
      confirm = (
        <div className="text-center text-danger text-uppercase">
          {this.state.message}
        </div>
      );
    } else {
      confirm = (
        <div className="text-center text-success text-uppercase">
          {this.state.message}
        </div>
      );
    }
    const columns = [
      {
        name: "id",
        label: "Id",
        options: { filter: true, sort: true, sortOrder: "asc", display: false },
      },
      {
        name:"customcustomerid",
        label:"Custom Customer Id",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "companyname",
        label: "Company",
        options: { filter: true, sort: true },
      },
      {
        name: "contactname",
        label: "Contact",
        options: { filter: false, sort: true },
      },
      {
        name: "contactnumber",
        label: "Contact Number",
        options: { filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div>+{this.state.customers.find((e) => e.id === tableMeta.rowData.find((e) => e)).phonecode} {value}</div>
            )
          }
        },
      },
      { name: "email", label: "Email", options: { filter: false, sort: true } },
      // {name: "active",label: "Status",options: {filter: true,sort: true,sortOrder: 'asc',}},
      {
        name: "cityname",
        label: "City",
        options: {
          filter: true,
          sort: true,
        //   customBodyRender: (value, tableMeta, updateValue) => {
        //     return this.state.customers.find(
        //       (e) => e.id === tableMeta.rowData.find((e) => e)
        //     ).cities.name;
        //   },
        },
      },
      {
        name: "openingbalance",
        label: "Opening",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div><NumericFormat className="pr-3" displayType={'text'} value={value=== undefined||value=== null?0:value} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></div>
            )
          }
        },
      },
      {
        name: "inuse",
        label:"Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
            <Tooltip title="Edit">
              <Link
                as="button"
                data-tip
                data-for="edit"
                className="fa fa-edit mr-2 cursorPointer"
                to={{pathname: "/newcustomer"}}
                  state= {{
                    id: tableMeta.rowData.find((e) => e),
                    name: "Update Customer"
                }}
              ></Link>
              </Tooltip>
              {value==="NO" && (tableMeta.rowData[7] ===undefined || tableMeta.rowData[7] ===0 || tableMeta.rowData[7] ===null)?
               <Tooltip title="Delete">
               <span
                as="button"
                data-tip
                data-for="delete"
                className="fa fa-trash text-danger cursorPointer mr-2"
                onClick={() => {
                    this.deleteCustomer(tableMeta.rowData.find((e) => e));
                }}
              ></span>
              </Tooltip>:''}
              <Tooltip title="Create Supplier">
                <span
                as="button"
                data-tip
                data-for="view"
                className="fa fa-user-plus mr-1 cursorPointer"
                onClick={()=>this.setAsSupplier(tableMeta.rowData.find((e) => e))}
              ></span>
              </Tooltip>
              {tableMeta.rowData[7] !==undefined && tableMeta.rowData[7] !==0 && tableMeta.rowData[7] !==null?
            <Tooltip title="Return Opening Balance">
              <span
              as="button"
              data-tip
              data-for="view"
              className="mr-1 cursorPointer"
              onClick={()=>this.returnOpeningBalance(tableMeta.rowData.find((e) => e))}
            >Return</span>
            </Tooltip>:""}
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newcustomer"}}
                  state= {{ id: -1, name: "New Customer" }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    return (
      <div>
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/masters">Home</Link>
            </li>

            <li className="breadcrumb-item active">Customers </li>
          </ol>
          {confirm}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* <div className="card"> */}
            

                    <div className="card-body">
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <ThemeProvider theme={theme}> 
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Customers"}
                            data={this.state.customers}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                    {/* /.card-body */}
                  {/* </div> */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
}
export default withRouter(CustomerDataTable);
