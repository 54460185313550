import React, { useState, useEffect } from "react";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Link, useLocation } from "react-router-dom";
import { TextField, FormControl,InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, Autocomplete} from "@mui/material";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import TransporterServices from "../../Masters/Transporter/TransporterServices";
import { currentTenant } from "../../Common/Credentials";
import SalesServices from "../../Sales/SalesServices";
import InventoryService from "../InventoryService";

const StockIssueItemsView = () => {
  const location = useLocation();
  const [data, setData] = useState(location.state.data);
  const [showfields, setShowfields] = useState(false);
  const [vehiclenum,setVehiclenum] = useState("")
  const [err,setErr] = useState(false)
  const [distance,setDistance] = useState(0)
  const [transmode,setTransmode] = useState(1)
  const [vehicletype,setVehicletype] = useState("R")
  const [transactiontype,setTransactiontype] = useState(1)
  const [transportationmode,setTransportationmode] = useState("1")
  const [supplytype,setSupplytype] = useState("I")
  const [transidreq,setTransidreq] = useState(false)
  const [transporterid,setTransporterid] = useState("")
  const [transportername,setTransportername] = useState(null)
  const [transporterselectiontype,setTransporterselectiontype] = useState(false)
  const [transporterslist,setTransporterslist] = useState([])
  const [validtrnsid,setValidtrnsid] = useState(false)
  const [loader,setLoader] = useState(false)
  const [errormsg,setErrormsg] = useState("")
  const deliverymethods =[
    { value: "1", name: "Own Vehicle" },
    { value: "2", name: "Hired Vehicle" },
    { value: "3", name: "GTA" },
    { value: "4", name: "Others" },
  ]

  const Refresh = () =>{
    InventoryService.GetStockTransferById(location.state.data.id).then((res)=>{
        setData({...location.state.data,...res.data.item});
    })
  }

  useEffect(() => {
    console.log(location);
    // Refresh()
    setData(location.state.data)
    if(location.state.data.ewaybillrequired === "True"){
    TransporterServices.getTransporters(currentTenant).then((response) =>
        setTransporterslist(response.data)
      );
    }
  }, []);

  const handleTransporterChange = (value, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setTransportername("")
      setTransporterid("")
      setValidtrnsid(false)
    } else {
      setTransportername(newValue.transname)
      setTransporterid(newValue.transid)
      setValidtrnsid(true)
    }
  };

  const TransporteridChange = (val) => {
    setTransporterid(val)
    if (val.length === 15) {
      let data = {
        tenantId: currentTenant,
        reqparam: val,
      };
      SalesServices.validateGSTIN(data).then((response) => {
        if (
          response.data.body === null ||
          response.data.body.statusCodeValue === 400
        ) {
          setErr(true)
          setValidtrnsid(false)
          Swal.fire({
            title: `Invalid Transporter ID`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
        } else {
          setValidtrnsid(true)
        }
      });
    } else {
      setErr(false)
      setValidtrnsid(false)
    }
  };

  const handleEWaybillGenration = () => {
    if (
      vehiclenum === "" ||
      distance === "" ||
      distance === null ||
      distance === 0 ||
      distance === undefined ||
      transporterid === ""
      // || this.state.validtrnsid === false
    ) {
      setErr(true);
    } else {
    //   if (this.state.Invoicedata.einvoicestatus === "NA") {
        let reqdata = {
          tenantId: currentTenant,
          TransId: transporterid,
          TransName: transportername,
          Distance: distance,
          TransDocNo: data.id,
          TransDocDt: data.createdAt,
          VehNo: vehiclenum,
          VehType: vehicletype,
          TransMode: transportationmode,
          supplytype: supplytype,
          transactiontype: transactiontype,
        };

        Swal.fire({
          title: "Generate E-Way bill?",
          text: "Are you sure?",
          icon: "question",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            setLoader(true)
            SalesServices.GenerateEwaybillAPI(reqdata)
              .then((response) => {
                setLoader(false)
                Swal.fire({
                  title: "Eway bill generated successfully!",
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() =>{
                  let reqdata = {
                    id:data.id,
                    dataaccess:"Completed"
                  }
                  InventoryService.updatetransferstockewaybillstatus(reqdata).then(()=>Refresh())
              });
              })
              .catch((e) => {
                setLoader(false)
                setErrormsg(e.response.data.body.errormessage);
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
                Swal.fire({
                  title: `${e.response.data.body.errormessage}`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              });
          }
        });
    //   } else if (this.state.Invoicedata.einvoicestatus === "Pending") {
    //     let data = {
    //       tenantId: currentTenant,
    //       Irn: this.state.Invoicedata.invid,
    //       TransId: this.state.transporterid,
    //       TransName: this.state.transportername,
    //       Distance: this.state.distance,
    //       VehNo: this.state.vehiclenum,
    //       VehType: this.state.vehicletype,
    //       TransMode: this.state.transportationmode,
    //       supplytype: this.state.supplytype,
    //       transactiontype: this.state.transactiontype,
    //     };
    //     Swal.fire({
    //       title: "Update Transportation Details?",
    //       text: "Are you sure?",
    //       icon: "question",
    //       allowOutsideClick: false,
    //       showCancelButton: true,
    //       confirmButtonText: "Yes",
    //       confirmButtonColor: "#152f5f",
    //       cancelButtonText: "No",
    //       reverseButtons: true,
    //     }).then((result) => {
    //       if (result.value) {
    //         this.setState({ loader: true });
    //         SalesServices.UpdateTransportation(data)
    //           .then((response) => {
    //             this.setState({ loader: false });
    //             Swal.fire({
    //               title: "Transportation details updated successfully",
    //               text: ".",
    //               icon: "success",
    //               confirmButtonText: "Ok",
    //               confirmButtonColor: "#152f5f",
    //             }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
    //           })
    //           .catch((e) => {
    //             this.setState({ loader: false });
    //             this.setState({ errormsg: e.response.data.body.errormessage });
    //             if (e.response.data.error === "Unauthorized") {
    //               alert("Your Session is Lost: Please login Back to resume");
    //               this.props.navigate(`/`);
    //             }
    //             Swal.fire({
    //               title: `${e.response.data.body.errormessage}`,
    //               text: ".",
    //               icon: "error",
    //               confirmButtonText: "Ok",
    //               confirmButtonColor: "#152f5f",
    //             });
    //           });
    //       }
    //     });
    //   } else {
    //     let data = {
    //       tenantId: currentTenant,
    //       Irn: this.state.einvoicedata.irn,
    //       TransId: this.state.transporterid,
    //       TransName: this.state.transportername,
    //       Distance: this.state.distance,
    //       TransDocNo: this.state.TransDocNo,
    //       TransDocDt: this.state.TransDocDt,
    //       VehNo: this.state.vehiclenum,
    //       VehType: this.state.vehicletype,
    //       TransMode: this.state.transportationmode,

    //       // Ttansmode:this.state.samedaydelivery?this.state.transmode:null,
    //       supplytype: this.state.supplytype,
    //       transactiontype: this.state.transactiontype,
    //     };

    //     Swal.fire({
    //       title: "Generate E-Way bill?",
    //       text: "Are you sure?",
    //       icon: "question",
    //       allowOutsideClick: false,
    //       showCancelButton: true,
    //       confirmButtonText: "Yes",
    //       confirmButtonColor: "#152f5f",
    //       cancelButtonText: "No",
    //       reverseButtons: true,
    //     }).then((result) => {
    //       if (result.value) {
    //         this.setState({ loader: true });
    //         SalesServices.GenerateEwaybill(data)
    //           .then((response) => {
    //             this.setState({ loader: false });
    //             Swal.fire({
    //               title: "Eway bill generated successfully!",
    //               text: ".",
    //               icon: "success",
    //               confirmButtonText: "Ok",
    //               confirmButtonColor: "#152f5f",
    //             }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
    //           })
    //           .catch((e) => {
    //             this.setState({ loader: false });
    //             this.setState({ errormsg: e.response.data.body.errormessage });
    //             if (e.response.data.error === "Unauthorized") {
    //               alert("Your Session is Lost: Please login Back to resume");
    //               this.props.navigate(`/`);
    //             }
    //             Swal.fire({
    //               title: `${e.response.data.body.errormessage}`,
    //               text: ".",
    //               icon: "error",
    //               confirmButtonText: "Ok",
    //               confirmButtonColor: "#152f5f",
    //             });
    //           });
    //       }
    //     });
    //   }
    }
  };

  const handleEwaybillClose = () => {
    setShowfields(false)
  };

  const handleEwaybillOpen = () => {
    setShowfields(true)
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/inventory">Home</Link>
            </li>

            <li className="breadcrumb-item active">Stock Issue/Transfer</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <table className="float-left">
                          <tbody>
                            <tr>
                              <td>Stock Issue Type</td>
                              <td>{data.stocktransfertype}</td>
                            </tr>
                            <tr>
                              <td>Issued by</td>
                              <td>{data.createdbyname}</td>
                            </tr>
                            <tr>
                              <td>From Org unit/Branch</td>
                              <td>{data.fromwhname}</td>
                            </tr>
                            <tr>
                              <td>Issued To</td>
                              <td>{data.receivedbyname}</td>
                            </tr>
                            {data.stocktransfertype === "OrgUnit" ? (
                              <tr>
                                <td>To Org unit/Branch</td>
                                <td>{data.towhname}</td>
                              </tr>
                            ) : (
                              <tr>
                                <td>To Department/Store</td>
                                <td>{data.deptname}</td>
                              </tr>
                            )}
                            <tr>
                              <td>E-way bill</td>
                              <td>{data.ewaybillrequired}</td>
                            </tr>
                            <tr>
                              <td>Status</td>
                              <td>{data.status}</td>
                            </tr>
                            <tr>
                              <td>Date</td>
                              <td>{data.createdAt}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {data.ewaybillrequired === "True" && data.ewaybillstatus === "Pending" && 
                      <div className="col-12">
                        <button
                          className="btn btn-sm hovbuttonColour mr-1 my-1"
                          onClick={() => setShowfields(true)}
                        >
                          {/* <i className="fas fa-file-invoice m-0"></i> */}
                           Generate E-way bill
                        </button>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
                            show={showfields}
                            onHide={handleEwaybillClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              <b>Transportation Details :</b>
                            </Modal.Header>
                            <Modal.Body>
                              <>
                                <Formik
                                  initialValues={{ transporterid }}
                                  validateOnChange={false}
                                  enableReinitialize={true}
                                >
                                  {({ setFieldValue, values }) => (
                                    <Form className="form-group row">
                                      <fieldset className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="vehiclenum"
                                          label="Vehicel No."
                                          onChange={(e) =>{
                                            setVehiclenum(e.target.value)
                                            setErr(false)
                                          }}
                                          error={
                                            vehiclenum === "" &&
                                            err
                                          }
                                          helperText={
                                            vehiclenum === "" &&
                                            err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="distance"
                                          label="Approximate Distance(Km)"
                                          onChange={(e) =>{
                                            setDistance(e.target.value)
                                            setErr(false)
                                          }}
                                          error={
                                            distance === "" &&
                                            err
                                          }
                                          helperText={
                                            distance === "" &&
                                            err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Mode of Transportation
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={transmode}
                                            onChange={(e) =>
                                                setTransmode(e.target.value)
                                            }
                                          >
                                            {deliverymethods.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Vehicle Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={vehicletype}
                                            onChange={(e) =>
                                                setVehicletype(e.target.value)
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value="R">
                                              Regular
                                            </MenuItem>
                                            <MenuItem value="O">ODC</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transaction Types{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={transactiontype}
                                            onChange={(e) =>setTransactiontype(e.target.value)}
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={1}>
                                              Regular
                                            </MenuItem>
                                            <MenuItem value={2}>
                                              Bill To - Ship To
                                            </MenuItem>
                                            <MenuItem value={3}>
                                              Bill From - Dispatch From
                                            </MenuItem>
                                            <MenuItem value={4}>
                                              Combination of 2 and 3
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transportation Mode{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              transportationmode
                                            }
                                            onChange={(e) =>setTransportationmode(e.target.value)}
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"1"}>
                                              Road
                                            </MenuItem>
                                            <MenuItem value={"2"}>
                                              Rail
                                            </MenuItem>
                                            <MenuItem value={"3"}>Air</MenuItem>
                                            <MenuItem value={"4"}>
                                              Ship
                                            </MenuItem>
                                            <MenuItem value={"5"}>
                                              inTransit
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Supply Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={supplytype}
                                            onChange={(e) =>setSupplytype(e.target.value)}
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"I"}>
                                              Inward
                                            </MenuItem>
                                            <MenuItem value={"O"}>
                                              Outward
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      <fieldset className="form-group col-md-12" />

                                      <fieldset className="form-group col-md-12 mt-2 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={transidreq}
                                                onChange={() =>{
                                                    setTransidreq(!transidreq)
                                                    setTransporterid(null)
                                                    setTransportername(null)
                                                  }}
                                                name="transidreq"
                                                color="primary"
                                              />
                                            }
                                            label="Add transporter details for Part A?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      {transidreq ? (
                                        <>
                                          <div className="col-md-12">
                                            <fieldset
                                              className={`form-group col-md-4  btn shadow-sm text-center ${
                                                transporterselectiontype ===
                                                false
                                                  ? "borderedbutton2"
                                                  : " hovbuttonColour"
                                              }`}
                                              disabled={transporterselectiontype ===false}
                                              onClick={() =>{
                                                    setTransporterselectiontype(false),
                                                    setTransporterid("")
                                                    setTransportername("")
                                                }}
                                            >
                                              Enter Manually
                                            </fieldset>
                                            <fieldset
                                              className={`form-group col-md-4 shadow-sm  text-center btn ${
                                                transporterselectiontype ===
                                                true
                                                  ? "borderedbutton2"
                                                  : " hovbuttonColour"
                                              }`}
                                              disabled={transporterselectiontype ===true}
                                              onClick={() =>{
                                                setTransporterselectiontype(true),
                                                    setTransporterid("")
                                                    setTransportername("")
                                                }}
                                            >
                                              Select
                                            </fieldset>
                                          </div>
                                          {transporterselectiontype ===
                                          true ? (
                                            <>
                                              <fieldset className="form-group col-md-4">
                                                <Autocomplete
                                                  id="subaccount"
                                                  className="subacc-select"
                                                  name="transportername"
                                                  options={
                                                    transporterslist
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.transname
                                                  }
                                                  value={
                                                    values.transname
                                                      ? undefined
                                                      : undefined
                                                  }
                                                  onChange={
                                                    handleTransporterChange
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      className="form-control"
                                                      type="text"
                                                      name="transportername"
                                                      value={transportername}
                                                      label={`Select Transporter (${transportername})`}
                                                      variant="standard"
                                                      error={transportername === "" && err}
                                                      helperText={transportername ===
                                                          "" && err
                                                          ? "Empty field!"
                                                          : " "
                                                      }
                                                      fullWidth
                                                    />
                                                  )}
                                                />
                                              </fieldset>
                                              <fieldset className="form-group col-md-4 ">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transporterid"
                                                  label={`${
                                                    transporterid ===
                                                      "" ||
                                                    transporterid ===
                                                      null
                                                      ? "Transporter Id"
                                                      : " "
                                                  }`}
                                                  value={
                                                    transporterid
                                                  }
                                                  variant="standard"
                                                  disabled
                                                />
                                              </fieldset>
                                            </>
                                          ) : (
                                            <>
                                              <fieldset className="form-group col-md-4">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transportername"
                                                  label="Transporter Name"
                                                  onChange={(e) =>{
                                                    setTransportername(e.target.value)
                                                      setErr(false)
                                                    }}
                                                  error={transportername === "" && err}
                                                  helperText={transportername === "" && err
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  variant="standard"
                                                />
                                              </fieldset>
                                              <fieldset className="form-group col-md-4 ">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transporterid"
                                                  label="Transporter Id"
                                                  onChange={(e) =>
                                                    TransporteridChange(
                                                      e.target.value
                                                    )
                                                  }
                                                  error={
                                                    (transporterid === "" ||
                                                      validtrnsid ===
                                                        false) &&
                                                    err
                                                  }
                                                  helperText={
                                                    transporterid ===
                                                      "" && err
                                                      ? "Empty field!"
                                                      : validtrnsid === false
                                                      ? "Invalid Transportor Id"
                                                      : " "
                                                  }
                                                  variant="standard"
                                                />
                                              </fieldset>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Form>
                                  )}
                                </Formik>
                                <button
                                  className="btn btn-sm hovbuttonColour float-center"
                                  onClick={handleEWaybillGenration}
                                >
                                    Generate Ewaybill
                                </button>
                                <button
                                className="btn btn-sm deletebutton ml-1 mt-1"
                                onClick={handleEwaybillClose}
                                >Cancel
                                </button>
                              </>
                            </Modal.Body>
                          </Modal>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StockIssueItemsView;
