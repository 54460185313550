import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import SalesServices from "../SalesServices";
import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  currency,
  currentTenant,
  czip,
  gstin,
  logo,
  prefix1,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
// import UserService from "../../users/UserService";
import { salesid } from "../../Common/Permissions";
import downloadfiles from "../../Common/upload/downloadfiles";
// import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NumericFormat } from "react-number-format";
import { Modal } from "react-bootstrap";
import UploadFilesService from "../../Common/upload/UploadFilesService";
import FileServices from "../../Common/upload/FileServices";

import { ToWords } from "to-words";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import SettingsServices from "../../Settings/SettingsServices";
import Swal from "sweetalert2";
import BankServices from "../../Masters/Bank/BankServices";
import AgentServices from "../../Masters/Agents/AgentServices.js";
import { screens } from "../../Common/Products.js";
import DataRows from "./DataRows.jsx";

let curmonth, year, day, path;
let doctype;

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    // const customer = this.props.location.state.customer;
    // const date = this.props.location.state.date;
    // const status = this.props.location.state.status;
    // const grandtotal = this.props.location.state.grandtotal;
    this.state = {
      Orders: "",
      saleorderitems: [],
      serviceList: [],
      milestones: [],
      id: id,
      sostatus: "",
      status: "",
      stat: 0,
      message: "",
      loading: false,
      showModal: false,
      desc: "",
      poid: "",
      amount: "",

      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false, //<---- here
      errorMessage: {}, //<-----here

      pofile: "",
      popath: "",
      filedata: "",
      path: "",
      templete: "",
      pouploadm: "",
      show: false,
      bankdetails: {},
      loader: false,
      preclosestatus: 1,
      imgheight: 0,
      imgwidth: 0,
      mailbehavior: "NO",
      showAdvance: false,
      advance: "",
      agentDetails: "",
      ispaymentpending:false,
    };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handledownload = this.handledownload.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handlepdfDownload = (sodata, type) => {
    let logowidth = 0;
    logowidth =
      ((this.state.imgwidth - this.state.imgheight) / this.state.imgwidth) *
      100;
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let data = [];
    let servdata = [];
    let servicetotal = 0;
    let servicetaxamt = 0;
    if (sodata.sotype === 0 || sodata.sotype === 2) {
      data = sodata.saleorderitems;
      data = data.map((v) => ({ ...v, cgstamt: (v.taxamount / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, cgst: v.tax / 2 }));
      data = data.map((v) => ({ ...v, sgstamt: (v.taxamount / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, sgst: v.tax / 2 }));
      data.map((e) => (e.price = e.price.toFixed(2)));
      data.map((e) => (e.totalpricewtax = e.totalpricewtax.toFixed(2)));
      data.map((e) => (e.discountamount = e.discountamount.toFixed(2)));
      data.map((e) => (e.insuranceamount = e.insuranceamount.toFixed(2)));

      if (sodata.currency !== currency) {
        data.find((e) => e).price = (
          data.find((e) => e).price * this.state.Orders.curconversionrate
        ).toFixed(2);
        data.find((e) => e).taxamount = (
          data.find((e) => e).taxamount * this.state.Orders.curconversionrate
        ).toFixed(2);
        data.find((e) => e).cgstamt = (
          data.find((e) => e).cgstamt * this.state.Orders.curconversionrate
        ).toFixed(2);
        data.find((e) => e).sgstamt = (
          data.find((e) => e).sgstamt * this.state.Orders.curconversionrate
        ).toFixed(2);
        data.find((e) => e).totalpricewtax = (
          data.find((e) => e).totalpricewtax *
          this.state.Orders.curconversionrate
        ).toFixed(2);
        data.find((e) => e).discountamount = (
          data.find((e) => e).discountamount *
          this.state.Orders.curconversionrate
        ).toFixed(2);
        data.find((e) => e).insuranceamount = (
          data.find((e) => e).insuranceamount *
          this.state.Orders.curconversionrate
        ).toFixed(2);
      }
    }

    if (sodata.sotype === 1 || sodata.sotype === 2) {
      servdata = sodata.servicelist;
      servdata = servdata.map((v) => ({ ...v, cgst: v.servicetax / 2 }));
      servdata = servdata.map((v) => ({ ...v, sgst: v.servicetax / 2 }));
      servicetaxamt = servdata.reduce(
        (a, v) => (a = a + (v.servicetotalwtax - v.servicecost)),
        0
      );
      servicetotal = servdata.reduce((a, v) => (a = a + v.servicecost), 0);

      if (sodata.currency !== currency) {
        servdata.find((e) => e).servicecost = (
          servdata.find((e) => e).servicecost *
          this.state.Orders.curconversionrate
        ).toFixed(2);
        servdata.find((e) => e).servicetotalwtax = (
          servdata.find((e) => e).servicetotalwtax *
          this.state.Orders.curconversionrate
        ).toFixed(2);
      }
    }

    let date = moment(this.state.createdAt).format("DD - MM - yyyy");

    let tablecolp, tablecols;

    let total = (
      sodata.grandproducttotal -
      sodata.totaltaxamount -
      sodata.totalinsuranceamount
    ).toFixed(2);
    let totaltaxamount = sodata.totaltaxamount.toFixed(2);
    let sototalwtax = sodata.sototalwtax.toFixed(2);
    let advance = sodata.advanceamountreq.toFixed(2);

    if (sodata.currency !== currency) {
      total = (total * sodata.curconversionrate).toFixed(2);
      totaltaxamount = (totaltaxamount * sodata.curconversionrate).toFixed(2);
      sototalwtax = (sototalwtax * sodata.curconversionrate).toFixed(2);
      advance = (advance * sodata.curconversionrate).toFixed(2);
    }

    if (sodata.taxtype !== "NA") {
      if (sodata.taxtype === "GST" && ccountry === "India") {
        if (sodata.gsttype === "INTRASTATE") {
          tablecolp = [
            { header: "Name of Product / Service", dataKey: "name" },
            { header: "HSN/SCA", dataKey: "hsn" },
            { header: `Unit Price (${sodata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "Discount", dataKey: "discountamount" },
            { header: "Insurance", dataKey: "insuranceamount" },
            { header: "CGST (%)", dataKey: "cgst" },
            { header: "SGST (%)", dataKey: "sgst" },
            { header: `CGST Amt. (${sodata.currency})`, dataKey: "cgstamt" },
            { header: `SGST Amt. (${sodata.currency})`, dataKey: "sgstamt" },
            { header: `Total (${sodata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "CGST (%)", dataKey: "cgst" },
            { header: "SGST (%)", dataKey: "sgst" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${sodata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        } else {
          tablecolp = [
            { header: "Name of Product / Service", dataKey: "name" },
            { header: "HSN/SCA", dataKey: "hsn" },
            { header: `Unit Price (${sodata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "Discount", dataKey: "discountamount" },
            { header: "Insurance", dataKey: "insuranceamount" },
            { header: "IGST (%)", dataKey: "tax" },
            { header: `IGST Amt. (${sodata.currency})`, dataKey: "taxamount" },
            { header: `Total (${sodata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "IGST (%)", dataKey: "servicetax" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${sodata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        }
      } else {
        if (sodata.taxtype === "GST") {
          tablecolp = [
            { header: "Name of Product / Service", dataKey: "name" },
            { header: "HSN/SCA", dataKey: "hsn" },
            { header: `Unit Price (${sodata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "Discount", dataKey: "discountamount" },
            { header: "Insurance", dataKey: "insuranceamount" },
            { header: "GST (%)", dataKey: "tax" },
            { header: `GST Amt. (${sodata.currency})`, dataKey: "taxamount" },
            { header: `Total (${sodata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "GST (%)", dataKey: "servicetax" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${sodata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        } else {
          tablecolp = [
            { header: "Name of Product / Service", dataKey: "name" },
            { header: "HSN/SCA", dataKey: "hsn" },
            { header: `Unit Price (${sodata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "Discount", dataKey: "discountamount" },
            { header: "Insurance", dataKey: "insuranceamount" },
            { header: "VAT (%)", dataKey: "tax" },
            { header: `VAT Amt. (${sodata.currency})`, dataKey: "taxamount" },
            { header: `Total (${sodata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "VAT (%)", dataKey: "servicetax" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${sodata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        }
      }
    } else {
      tablecolp = [
        { header: "Name of Product / Service", dataKey: "name" },
        { header: "HSN/SCA", dataKey: "hsn" },
        { header: `Unit Price (${sodata.currency})`, dataKey: "price" },
        { header: "Quantity", dataKey: "quantity" },
        { header: "UOM", dataKey: "uom" },
        { header: "Discount", dataKey: "discountamount" },
        { header: "Insurance", dataKey: "insuranceamount" },
        { header: `Total (${sodata.currency})`, dataKey: "totalpricewtax" },
      ];
      tablecols = [
        { header: "Service", dataKey: "service" },
        { header: "HSN/SAC", dataKey: "servicecode" },
        { header: `Total (${sodata.currency})`, dataKey: "servicetotalwtax" },
      ];
    }

    const toWords = new ToWords();
    var totalinwords = toWords.convert(sototalwtax);
    var advanceinwords = toWords.convert(advance);
    const doc = new jsPDF("p", "pt", [842, 595], true);
    let y = 0;
    doc.setFontSize(130);
    // doc.setFont("bold", "bold");
    doc.setTextColor("#EEECEC");
    doc.text(`Sale Order`, 100, 700, { angle: 45 });
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");
    doc.setFontSize(8);
    doc.setDrawColor(192, 192, 192);
    doc.setLineWidth(1.0);
    doc.line(10, 180, 585, 180);
    doc.setFontSize(8);
    doc.text(`Order ID/No`, 305, 195);
    doc.text(`-`, 370, 195);
    doc.text(`${sodata.customsoid}`, 380, 195);
    doc.text(`Date`, 305, 205);
    doc.text(`-`, 370, 205);
    doc.text(`${date}`, 380, 205);
    doc.text(`Tax type`, 305, 215);
    doc.text(`-`, 370, 215);
    doc.text(`${sodata.taxtype}`, 380, 215);
    if (sodata.taxtype === "GST" && ccountry === "India") {
      doc.text(`Supply Type`, 305, 225);
      doc.text(`-`, 370, 225);
      doc.text(`${sodata.gsttype}`, 380, 225);
    }
    if (sodata.agent !== null && sodata.agent !== "") {
      doc.text(`Agent`, 305, 235);
      doc.text(`-`, 370, 235);
      doc.text(`${sodata.agent}`, 380, 235);
      doc.text(`Address`, 305, 245);
      doc.text(`-`, 370, 245);
      doc.text(`${this.state.agentDetails.address}`, 380, 245);
      doc.text(`${this.state.agentDetails.address2}`, 380, 255);
      doc.text(
        `${this.state.agentDetails.cityname}, ${this.state.agentDetails.statename} -${this.state.agentDetails.zip}`,
        380,
        265
      );
      // doc.text(`${this.state.agentDetails.countryname} - `, 380, 275);
      doc.text(`Phone No.`, 305, 275);
      doc.text(`-`, 370, 275);
      doc.text(`${this.state.agentDetails.contactnumber}`, 380, 275);
    }
    doc.setLineWidth(1.0);
    doc.line(300, 180, 300, 280);
    // doc.text(`Shipment ID : ${sodata.dnid}`,560,220,'right')
    doc.text("CUSTOMER DETAILS,", 20, 195);
    doc.text("M/S", 20, 210);
    doc.text(`-`, 65, 210);
    doc.text(`${sodata.customer}`, 80, 210);
    doc.text(`Address`, 20, 220);
    doc.text(`-`, 65, 220);
    doc.text(`${sodata.address}`, 80, 220);
    doc.text(`${sodata.address2}`, 80, 230);
    doc.text(`${sodata.cityname}, ${sodata.statename} `, 80, 240);
    doc.text(`${sodata.countryname} - ${sodata.zip}`, 80, 250);
    doc.text(`Phone No.`, 20, 260);
    doc.text(`-`, 65, 260);
    doc.text(`${sodata.contactnumber}`, 80, 260);
    if (sodata.custgstin !== "" && sodata.custgstin !== undefined) {
      doc.text(`GSTIN`, 20, 270);
      doc.text(`-`, 65, 270);
      doc.text(`${sodata.gstin}`, 80, 270);
    }
    doc.setLineWidth(1.0);
    doc.line(10, 280, 585, 280);
    if (sodata.sotype === 0 || sodata.sotype === 2) {
      doc.text(`Products`, 20, 290);
      autoTable(doc, {
        theme: "grid",
        startY: 290,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        // columnStyles: {
        //   2: { halign: "right" },
        //   6: {
        //     halign: `${
        //       sodata.taxtype === "GST" &&
        //       ccountry === "India" &&
        //       sodata.gsttype === "INTRASTATE"
        //         ? "left"
        //         : "right"
        //     }`,
        //   },
        //   7: { halign: "right" },
        // },
        columnStyles: {
          2: { halign: "right" },
          5: { halign: "right" },
          6: { halign: "right" },
          8: {
            halign:
              sodata.taxtype === "GST" &&
              ccountry === "India" &&
              sodata.gsttype === "INTRASTATE"
                ? "left"
                : "right",
          },
          9: { halign: "right" },
          10: { halign: "right" },
          11: { halign: "right" },
        },
        body: data,
        columns: tablecolp,
      });
    }
    if (sodata.sotype === 2) {
      y = doc.autoTable.previous.finalY + 200;
      if (y > 841) {
        doc.addPage([595.28, 841.89]);
        y = 195;
        doc.setFontSize(150);
        doc.setFont("bold", "bold");
        doc.setTextColor("#EEECEC");
        doc.text(`Sale Order`, 100, 700, { angle: 45 });
        doc.setFont("helvetica", "normal");
        doc.setTextColor("#000");
        doc.setFontSize(8);
      }
      doc.line(
        10,
        doc.autoTable.previous.finalY + 10,
        585,
        doc.autoTable.previous.finalY + 10
      );
      doc.text(`Services`, 20, doc.autoTable.previous.finalY + 20);
      autoTable(doc, {
        theme: "grid",
        startY: doc.autoTable.previous.finalY + 20,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        columnStyles: {
          3: {
            halign:
              sodata.taxtype === "GST" &&
              ccountry === "India" &&
              sodata.gsttype === "INTRASTATE"
                ? "left"
                : "right",
          },
          4: { halign: "right" },
          5: { halign: "right" },
        },
        body: servdata,
        columns: tablecols,
      });
    }

    if (sodata.sotype === 1) {
      doc.text(`Services`, 20, 280);
      autoTable(doc, {
        theme: "grid",
        startY: 280,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        columnStyles: {
          3: {
            halign:
              sodata.taxtype === "GST" &&
              ccountry === "India" &&
              sodata.gsttype === "INTRASTATE"
                ? "left"
                : "right",
          },
          4: { halign: "right" },
          5: { halign: "right" },
        },
        body: servdata,
        columns: tablecols,
      });
    }

    y = doc.autoTable.previous.finalY + 10;
    if (y + 180 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 195;
      doc.setFontSize(150);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`Sale Order`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }

    doc.setLineWidth(1.0);
    doc.line(10, y, 585, y);
    if (sodata.sotype === 2) {
      doc.text("Services", 480, y + 13, "right");
      doc.text(`Products`, 570, y + 13, "right");
      doc.line(10, y + 20, 585, y + 20);
      y = y + 20;
    }
    doc.line(
      350,
      y,
      350,
      sodata.taxtype === "GST" &&
        ccountry === "India" &&
        sodata.gsttype === "INTRASTATE"
        ? y + 180
        : y + 160
    );
    doc.text("Total in words", 150, y + 13);
    doc.text(`${totalinwords}`, 20, y + 33);
    doc.line(10, y + 40, 350, y + 40);
    doc.text(`Advance amount - ${advance} (${advanceinwords})`, 20, y + 53);
    doc.line(10, y + 60, 350, y + 60);
    doc.text("Bank details", 150, y + 73);
    doc.line(10, y + 80, 350, y + 80);
    doc.text("Bank Name", 20, y + 93);
    doc.text("-", 75, y + 93);
    doc.text(`${this.state.bankdetails.bankname}`, 85, y + 93);
    doc.text("Bank Acc No.", 20, y + 113);
    doc.text("-", 75, y + 113);
    doc.text(`${this.state.bankdetails.accno}`, 85, y + 113);
    doc.text("Branch Name", 20, y + 133);
    doc.text("-", 75, y + 133);
    doc.text(`${this.state.bankdetails.branchname}`, 85, y + 133);
    doc.text("IFSC", 20, y + 153);
    doc.text("-", 75, y + 153);
    doc.text(`${this.state.bankdetails.ifsc}`, 85, y + 153);
    doc.line(
      10,
      sodata.taxtype === "GST" &&
        ccountry === "India" &&
        sodata.gsttype === "INTRASTATE"
        ? y + 180
        : y + 160,
      350,
      sodata.taxtype === "GST" &&
        ccountry === "India" &&
        sodata.gsttype === "INTRASTATE"
        ? y + 180
        : y + 160
    );

    doc.text("Taxable Amount", 360, y + 13);
    if (sodata.sotype === 2 || sodata.sotype === 1) {
      doc.text(
        `${servicetotal.toFixed(2)}`,
        sodata.sotype === 2 ? 480 : 570,
        y + 13,
        "right"
      );
    }
    if (sodata.sotype === 2 || sodata.sotype === 0) {
      doc.text(`${total}`, 570, y + 13, "right");
    }
    doc.line(10, y + 20, 585, y + 20);
    if (
      sodata.taxtype === "GST" &&
      ccountry === "India" &&
      sodata.gsttype === "INTRASTATE"
    ) {
      doc.text("CGST", 360, y + 33);
      if (sodata.sotype === 2 || sodata.sotype === 1) {
        doc.text(
          `${(servicetaxamt / 2).toFixed(2)}`,
          sodata.sotype === 2 ? 480 : 570,
          y + 33,
          "right"
        );
      }
      if (sodata.sotype === 2 || sodata.sotype === 0) {
        doc.text(`${(totaltaxamount / 2).toFixed(2)}`, 570, y + 33, "right");
      }
      doc.line(350, y + 40, 585, y + 40);
      doc.text("SGST", 360, y + 53);
      if (sodata.sotype === 2 || sodata.sotype === 1) {
        doc.text(
          `${(servicetaxamt / 2).toFixed(2)}`,
          sodata.sotype === 2 ? 480 : 570,
          y + 53,
          "right"
        );
      }
      if (sodata.sotype === 2 || sodata.sotype === 0) {
        doc.text(`${(totaltaxamount / 2).toFixed(2)}`, 570, y + 53, "right");
      }
      y = y + 20;
    } else {
      doc.text(
        sodata.taxtype === "GST" && ccountry === "India"
          ? "IGST"
          : sodata.taxtype === "GST"
          ? "GST"
          : "Vat",
        360,
        y + 33
      );
      if (sodata.sotype === 2 || sodata.sotype === 1) {
        doc.text(
          `${servicetaxamt.toFixed(2)}`,
          sodata.sotype === 2 ? 480 : 570,
          y + 33,
          "right"
        );
      }
      if (sodata.sotype === 2 || sodata.sotype === 0) {
        doc.text(`${totaltaxamount}`, 570, y + 33, "right");
      }
    }
    doc.line(350, y + 40, 585, y + 40);
    doc.text("Total Amount", 360, y + 53);
    if (sodata.sotype === 2 || sodata.sotype === 1) {
      doc.text(
        `${sodata.grandservicetotal.toFixed(2)}`,
        sodata.sotype === 2 ? 480 : 570,
        y + 53,
        "right"
      );
    }
    if (sodata.sotype === 2 || sodata.sotype === 0) {
      doc.text(`${sodata.grandproducttotal.toFixed(2)}`, 570, y + 53, "right");
    }
    doc.line(350, y + 60, 585, y + 60);
    doc.text("Total", 360, y + 73);
    doc.text(`${sototalwtax}`, 570, y + 73, "right");
    doc.line(350, y + 80, 585, y + 80);
    doc.setFontSize(8);
    doc.text(
      "certified that particulars given above are true and correct",
      370,
      y + 88
    );
    doc.setFontSize(8);
    doc.text(`For ${clientname}`, 400, y + 100);
    doc.line(350, y + 105, 585, y + 105);
    doc.line(350, y + 145, 585, y + 145);
    doc.text("Authorised Signatory", 425, y + 155);
    doc.line(350, y + 160, 585, y + 160);
    y = y + 160;
    if (y + 50 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 195;
      doc.setFontSize(150);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`Sale Order`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }
    doc.line(10, y, 585, y);
    doc.text("Terms and Conditions", 275, y + 15);
    doc.line(10, y + 20, 585, y + 20);
    var splitTitle = doc.splitTextToSize(this.state.templete.terms, 500);
    doc.text(20, y + 40, splitTitle);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.line(10, 50, 585, 50);
      doc.line(10, pageHeight - 40, 585, pageHeight - 40);
      doc.line(10, 50, 10, pageHeight - 40);
      doc.line(pageWidth - 10, 50, pageWidth - 10, pageHeight - 40);
      doc.setFontSize(8);
      doc.text(`${curdate}`, 20, 60);
      doc.addImage(
        logo,
        "JPEG",
        pageWidth - 100 - logowidth,
        60,
        80 + logowidth,
        80
      );
      doc.setFontSize(12);
      doc.text(`${clientname}`, 20, 80);
      doc.setFontSize(8);
      doc.text(`${address}`, 20, 90);
      doc.text(`${address2}`, 20, 100);
      doc.text(`${ccity}, ${cstate}`, 20, 110);
      doc.text(`${ccountry} - ${czip}`, 20, 120);
      doc.text(`${companyemail}`, 20, 130);
      doc.text(`${contactnumber}`, 20, 140);
      doc.setLineWidth(1.0);
      doc.line(10, 150, 585, 150);
      doc.setFontSize(10);
      doc.text(`GSTIN | ${gstin}`, 20, 167);
      doc.setFontSize(12);
      doc.text(`SALE ORDER`, 270, 167);
      doc.setLineWidth(1.0);
      doc.line(10, 175, 585, 175);
      doc.setFontSize(8);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 50,
        { baseline: "bottom" }
      );
    }
    if (type === "pdf") {
      doc.autoPrint({ variant: "non-conform" });
      doc.save(`${sodata.customsoid}.pdf`);
    } else {
      const blob = doc.output("blob");
      const file = new File([blob], `${sodata.customsoid}.pdf`);
      // var fileURL = URL.createObjectURL(blob);
      // window.open(fileURL);
      let formData = new FormData();

      formData.append("file", file);
      formData.append("id", this.state.id);

      SalesServices.SendSOmail(formData)
        .then((response) =>
          this.setState({ loader: false }, () =>
            Swal.fire({
              title: `Mail Sent Successfully`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          )
        )
        .catch((e) =>
          this.setState(
            { message: e.response.data.message, loader: false },
            () => {
              if (e.response.data.error === "Unauthorized") {
                Swal.fire({
                  title: `Your Session is Lost: Please login Back to resume`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() => this.props.navigate("/"));
              } else {
                Swal.fire({
                  title: `Server Error, please try again later`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              }
            }
          )
        );
    }
  };

  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
      partyid: this.state.Orders.custid,
    });
  };

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.partyid,
      day
      // (event) => {
      //   this.setState({
      //     progress: Math.round((100 * event.loaded) / event.total),
      //   });
      //   progress = Math.round((100 * event.loaded) / event.total);
      // }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    this.setState({ selectedFiles: undefined });
  }

  handleSendMail = () => {
    if (this.state.fileInfos.length === 0) {
      alert("Please Upload File");
    } else {
      let filedata = this.state.fileInfos.map((e) => e.name);
      path =
        prefix1 +
        "/" +
        doctype +
        "/" +
        this.state.partyid +
        "/" +
        year +
        "/" +
        curmonth +
        "/" +
        day;
      let data = {
        file: filedata.toLocaleString(),
        path: path,
        soid: this.state.id,
      };
      SalesServices.UploadPOfile(data)
        .then(() =>
          this.setState({
            show: false,
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
            fileInfo: "",
            partyid: "",
            error: false,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  handleShowModal = () => {
    doctype = "pofile";
    this.setState({ showModal: true });
  };
  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handlesale = (e) => {
    this.setState({ salestype: e.target.value });
  };

  handleShow = () => {
    doctype = "somail";
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleCancelModalShow = () => {
    this.setState({ showCancelModal: true });
  };
  handleCancelModalClose = () => {
    this.setState({ showCancelModal: false });
  };

  handleAdvanceShow = () => {
    this.setState({ showAdvance: true });
  };

  handleAdvancehide = () => {
    this.setState({ showAdvance: false });
  };

  handleAddAdvance = () => {
    if (
      this.state.Orders.sototalwtax <
        parseFloat(this.state.advance) + this.state.Orders.advanceamountreq ||
      this.state.advance === 0 ||
      this.state.advance === "" ||
      this.state.advance === undefined
    ) {
    } else {
      let data = {
        id: this.state.Orders.id,
        amount: parseFloat(this.state.advance),
        tenantid: currentTenant,
      };

      Swal.fire({
        title: "Update advance in sale order?",
        text: "Are you sure you want to update?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.setState({ loader: true });
          SalesServices.AddAdvance(data)
            .then((resposne) => {
              this.setState(
                {
                  message: `Advance Updated Successfull`,
                  loader: false,
                  showAdvance: false,
                },
                () =>
                  Swal.fire({
                    title: "Advance Updated Successfull",
                    text: ".",
                    icon: "success",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.refreshSalesorder())
              );
            })
            .catch((e) => {
              this.setState({ loader: false });
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      });
    }
  };

  handleDelete = () => {
    Swal.fire({
      title: "Delete Sale Order?",
      text: "Are you sure you want to Delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        SalesServices.DeleteSaleOrder(this.state.id)
          .then((resposne) => {
            this.setState({
              message: `Delete of Todo ${this.state.id} Successfull`,
              loader: false,
            });
          })
          .then(() => this.props.navigate(`/salesorder`))
          .catch((e) => {
            this.setState({ loader: false });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  };

  handleCancel = () => {
    this.setState(
      { stat: this.state.Orders.invoices.length === 0 ? 2 : 3 },
      () => {
        let statusdata = {
          id: this.state.id,
          status: this.state.stat,
          canceldesc: this.state.desc,
          updatedby: userid,
        };
        Swal.fire({
          title: `${
            this.state.Orders.invoices.length === 0
              ? "Cancel Sale Order"
              : "Preclose Sale Order"
          }?`,
          text: `Are you sure you want to ${
            this.state.Orders.invoices.length === 0 ? "Cancel" : "Preclose"
          } Sale Order?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({ loader: true });
            SalesServices.statusData(statusdata)
              .then(() => this.props.navigate(`/salesorder`))
              .catch((e) => {
                this.setState({ loader: false });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
              });
          }
        });
      }
    );
  };

  handleCloseOrder = ()=>{
    let data = {
      id: this.state.id,
      updatedby: userid,
    };
    Swal.fire({
      title: "Close Order",
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
          SalesServices.CloseSaleOrder(data)
            .then((res) => {
              this.setState({ loader: false });
              // if (status === 0 && this.state.mailbehavior === "YES") {
              //   this.handleDownload(this.state.Invoicedata, "mail");
              // }
              Swal.fire({
                title: "Orders Closed successfully",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then((res) =>this.refreshSalesorder())
              // .then(()=> this.props.navigate("/salesinvoice",{state:{ message: "", type: "Tax Invoice" }}))
              
              // this.Refresh(this.state.Invoicedata.invid,"Tax Invoice Details",true)
              // );
            })
            .catch((e) => {
              this.setState({ loader: false });
              Swal.fire({
                title: e?.response?.data?.body?.errormessage,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
              if (e?.response?.data?.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      })
  }
  handleConfirm = () => {
    let statusdata = {
      id: this.state.id,
      status: 1,
      updatedby: userid,
    };

    if(this.state.ispaymentpending){
      Swal.fire({
        title:'Pending payment from customer!',
        text: "Payment is due from the customer, please settle it before continuing",
        icon:"warning",
        confirmButtonColor: "#152f5f",
      })
    }else{
    Swal.fire({
      title: "Approve/Confirm Sale Order?",
      text: "Are you sure you want to approve?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        SalesServices.statusData(statusdata)
          .then((response) => {
            if (this.state.mailbehavior === "YES") {
              this.handlepdfDownload(this.state.Orders, "mail");
            }
            this.setState({ stat: 1, sostatus: "Confirmed", loader: false });
            Swal.fire({
              title: "Sale order approved/Confirmed successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          })
          .catch((e) => {
            this.setState({ loader: false });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  }

    //   if(window.confirm("Are you sure you want to confirm Order?")){
    //   this.setState({ stat: 1,sostatus:"Confirmed" }, () => {
    //     let statusdata = {
    //       id: this.state.id,
    //       status: this.state.stat,
    //       updatedby: userid

    //     };
    //     SalesServices.statusData(statusdata).then(()=>
    //     this.refreshSalesorder()
    //     )
    //     .catch((e)=>{
    //       if(e.response.data.error ==="Unauthorized"){
    //           alert("Your Session is Lost: Please login Back to resume");
    //           this.props.navigate(`/`);
    //       }
    //   })
    //   });
    //  }
  };

  deleteSaleOrder(id) {
    SalesServices.DeleteSaleOrder(id, userid)
      .then((resposne) => {
        this.setState({ message: `Delete of Todo ${id} Successfull` });
        this.refreshSalesorder();
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  handleFinalSubmit = () => {
    if (this.state.fileInfos.length === 0) {
      this.setState({ message: "Please Upload PO file" });
      alert("Please Upload PO file");
    } else {
      let filedata = this.state.fileInfos.map((e) => e.name);
      path =
        prefix1 +
        "/" +
        doctype +
        "/" +
        this.state.partyid +
        "/" +
        this.state.id +
        "/" +
        year +
        "/" +
        curmonth +
        "/" +
        day;
      let uploaddata = {
        file: filedata.toLocaleString(),
        path: path,
        soid: this.state.id,
        poid: this.state.poid,
        amount: parseFloat(this.state.amount),
      };
      SalesServices.UploadPOfile(uploaddata)
        .then((res) =>
          this.setState(
            {
              pofile: res.data.file,
              popath: res.data.path,
              selectedFiles: undefined,
              currentFile: undefined,
              progress: 0,
              message: "",
              isError: false,
              fileInfos: [],
              fileInfo: "",
              error: false,
              errorMessage: {},
            },
            () => this.handleModalClose()
          )
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  refreshSalesorder() {
    const { id } = this.props.location.state;

    SalesServices.GetAllSaleorderById(id)
      .then((resp) => {
        this.setState({
          Orders: resp.data,
          saleorderitems: resp.data.saleorderitems,
          serviceList: resp.data.servicelist,
          sostatus: resp.data.sostatus,
          status: resp.data.status,
          milestones:
            resp.data.servicelist.length !== 0
              ? resp.data.servicelist.find((e) => e).milestones
              : [],
          pofile: resp.data.pofile,
          popath: resp.data.popath,
          amount: resp.data.sototalwtax,
          advance: resp.data.advanceamountreq,
          preclosestatus:
            resp.data.sotype === 0
              ? resp.data.saleorderitems.filter(
                  (e) => e.accepted + e.returnquantity === e.delivered
                ).length
              : 1,
        });
        if (resp.data.agentid !== null && resp.data.agentid !== "") {
          AgentServices.getAgentById(resp.data.agentid).then((res) =>
            this.setState({ agentDetails: res.data })
          );
        }
        SalesServices.GetCustomerTerms(resp.data.custid).then((res) =>
        SalesServices.IsPaymentPending(resp.data.custid).then((response)=>
        this.setState({ispaymentpending:response.data && res.data !==null && res.data.creditstatus === "Required"?true:false}) 
        )
      )

        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    SettingsServices.GetActivePdfTemplate("Sales", currentTenant).then(
      (response) => this.setState({ templete: response.data })
    );

    SettingsServices.GetSettings(currentTenant).then((response) =>
      this.setState({
        pouploadm: response.data.pouploadm,
        mailbehavior: response.data.mailbehavior,
      })
    );

    BankServices.getActivebank(currentTenant).then((res) =>
      this.setState({ bankdetails: res.data })
    );
  }

  onfocus() {
    document
      .getElementById("amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("amount").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    let i = new Image();
    i.src = logo;

    setTimeout(() => {
      this.setState({ imgwidth: i.naturalWidth, imgheight: i.naturalHeight });
    }, 100);

    this.refreshSalesorder();

    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");
    // if (this.state.status === "Confirmed") {
    //   this.setState({ stat: 1 });
    // } else {
    //   this.setState({ stat: 0 });
    // }
  }

  handledownload = () => {
    downloadfiles.download(this.state.popath, this.state.pofile);
  };

  render() {
    let displaybutton;
    let displaydeletebutton;
    let { selectedFiles, message, isError } = this.state;
    if (
      this.state.sostatus === "Cancelled" ||
      this.state.sostatus === "Preclosed" ||
      this.state.sostatus === "Completed"
    ) {
    } else {
      if (this.state.sostatus === "Confirmed") {
        if (salesid === "3") {
          displaydeletebutton = this.state.showAdvance ? (
            ""
          ) : (
            <>
              {this.state.Orders.invoices.length === 0 &&
              this.state.Orders.proformainvoicestatus === "Pending" ? (
                <button
                  className="btn btn-sm hovbuttonColour mr-3"
                  onClick={() => this.handleAdvanceShow()}
                >
                  {/* <i className="fa fa-times mr-1"></i> */}
                  Advance
                </button>
              ) : (
                ""
              )}

              {this.state.Orders.invoices.length === 0 ? (
                <button
                  className="btn btn-sm deletebutton mr-3"
                  onClick={() => this.handleCancelModalShow()}
                >
                  <i className="fa fa-times mr-1"></i>
                  Cancel Order
                </button>
              ) : this.state.Orders.precloseable ? (
                ""
              ) : (
                <button
                  className="btn btn-sm deletebutton mr-3"
                  onClick={() => this.handleCancelModalShow()}
                >
                  <i className="fa fa-times mr-1"></i>
                  Order Preclose
                </button>
              )}
              {this.state.Orders.pouploadreq === "Pending" ? (
                <>
                  <button
                    className="btn btn-sm hovbuttonColour m-0"
                    data-tipdata-for="upload"
                    onClick={() => this.handleShowModal()}
                  >
                    <i className="fa fa-upload mr-2" />
                    PO Upload
                  </button>
                  {/* //<Tooltip id="upload" place="top" effect="solid">
                    Upload
                  </Tooltip> */}
                </>
              ) : (
                ""
              )}
            </>
          );
        }
      } else {
        if (salesid === "3" || salesid === "2") {
          displaybutton = (
            <>
              {userid === this.state.Orders.createdby ? (
                <Link
                  as="button"
                  data-tip
                  data-for="edit"
                  className="btn btn-sm hovbuttonColour mx-2"
                  to={{ pathname: `/newsalesorder` }}
                  state={{ pagename: "Edit Sale Order", id: this.state.id }}
                >
                  <i className="fa fa-edit mr-1" /> Edit Order
                </Link>
              ) : (
                ""
              )}
              {salesid === "3" ? (
                <button
                  className="btn btn-sm hovbuttonColour mr-2"
                  onClick={this.handleConfirm}
                >
                  <i className="fa fa-check mr-1" />
                  Confirm Order
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-sm deletebutton"
                onClick={this.handleDelete}
              >
                <i className="fa fa-trash  m-0" /> Delete Order
              </button>
            </>
          );
        }
      }
    }
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.loader}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary" key="back">
                <span
                  as="button"
                  className="text-secondary cursorpointer"
                  onClick={() => this.props.location.state.goback === undefined?this.props.navigate(-1):this.props.navigate("/salesorder")}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales" key="home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary" key="pre">
                <Link to="/salesorder">Sales Order</Link>
              </li>
              <li className="breadcrumb-item active" key="active">
                Sale Order Details
              </li>
            </ol>
            {this.state.loading === true ? (
              <div className="text-center">{spin}</div>
            ) : (
              <>
                <div className="mb-2">
                  {this.state.loading === true ? (
                    ""
                  ) : (
                    <>
                      {this.state.sostatus === "Cancelled" ||
                      this.state.sostatus === "Preclosed" ||
                      this.state.sostatus === "Completed" ? (
                        ""
                      ) : (
                        <div className="form-row mt-2 pl-3 align-center">
                          <span className="mr-3"> Process Status :</span>
                          <span
                            className="text-green"
                            style={{ borderBottom: "1px solid #00ff00" }}
                          >
                            {"Sale Order"}&nbsp; (
                            <i className="fa fa-check text-green text-sm" />
                            )&nbsp; <i className="fas fa-long-arrow-alt-right"></i> &nbsp;
                          </span>
                          {this.state.sostatus === "Confirmed" ? (
                            <span
                              className="text-green"
                              style={{ borderBottom: "1px solid #00ff00" }}
                            >
                              {"Confirm Order"}&nbsp; (
                              <i className="fa fa-check text-green text-sm" />
                              )&nbsp; <i className="fas fa-long-arrow-alt-right"></i> &nbsp;
                            </span>
                          ) : (
                            <span
                              className="text-red"
                              style={{ borderBottom: "1px solid red" }}
                            >
                              <span className="text-red">
                                {"Confirm Order (P)"} <i className="fas fa-long-arrow-alt-right"></i>
                              </span>
                              &nbsp;&nbsp;
                            </span>
                          )}

                          {this.state.pouploadm === "YES" ? (
                            <>
                              {this.state.pofile === null ||
                              this.state.pofile === "" ? (
                                <span
                                  className="text-red"
                                  style={{ borderBottom: "1px solid red" }}
                                >
                                  {"Upload PO (P)"}&nbsp;<i className="fas fa-long-arrow-alt-right"></i>&nbsp;
                                </span>
                              ) : (
                                <span
                                  className="text-green"
                                  style={{ borderBottom: "1px solid #00ff00" }}
                                >
                                  {"Upload PO"}&nbsp; (
                                  <i className="fa fa-check text-green text-sm" />
                                  )&nbsp; <i className="fas fa-long-arrow-alt-right"></i> &nbsp;
                                </span>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.Orders.advancepaymentreq === "True" ? (
                            this.state.Orders.proformainvoicestatus ===
                            "Completed" ? (
                              <span
                                className="text-green"
                                style={{ borderBottom: "1px solid #00ff00" }}
                              >
                                {"Proforma Invoice"}&nbsp; (
                                <i className="fa fa-check text-green text-sm" />
                                )&nbsp; <i className="fas fa-long-arrow-alt-right"></i> &nbsp;
                              </span>
                            ) : this.state.Orders.proformainvoicestatus ===
                              "Partial" ? (
                              <span
                                style={{ borderBottom: "1px solid yellow" }}
                              >
                                <span as="button" className="text-yellow">
                                  {"Proforma Invoice (P)"}
                                  {this.state.Orders.sotype === 1 ? (
                                    ""
                                  ) : (
                                    <>&nbsp;<i className="fas fa-long-arrow-alt-right"></i></>
                                  )}
                                </span>
                                &nbsp;
                              </span>
                            ) : (
                              <span style={{ borderBottom: "1px solid red" }}>
                                {this.state.sostatus === "Confirmed" && screens.includes("Invoice") ? (
                                  <Link
                                    as="button"
                                    className="text-red"
                                    to={{ pathname: "/newsalesinvoice" }}
                                    state={{
                                      id: -1,
                                      pagename: "New Proforma Invoice",
                                      soid: this.state.Orders.customsoid,
                                    }}
                                  >
                                    {"Proforma Invoice (P)"}&nbsp;<i className="fas fa-long-arrow-alt-right"></i>
                                  </Link>
                                ) : (
                                  <span className="text-red">
                                    {"Proforma Invoice (P)"}&nbsp;<i className="fas fa-long-arrow-alt-right"></i>
                                  </span>
                                )}
                                &nbsp;&nbsp;
                              </span>
                            )
                          ) : (
                            ""
                          )}
                          {this.state.Orders.invoicestatus === "Completed" ? (
                            <span
                              className="text-green"
                              style={{ borderBottom: "1px solid #00ff00" }}
                            >
                              {"Invoice"}&nbsp; (
                              <i className="fa fa-check text-green text-sm" />
                              )&nbsp;
                              {this.state.Orders.sotype === 1 ? (
                                ""
                              ) : (
                                <><i className="fas fa-long-arrow-alt-right"></i></>
                              )}{" "}
                              &nbsp;
                            </span>
                          ) : this.state.Orders.invoicestatus === "Partial" ? (
                            <span style={{ borderBottom: "1px solid yellow" }}>
                              {screens.includes("Invoice") && (salesid === '2' || salesid === '3')?<Link
                                as="button"
                                className="text-yellow"
                                to={{ pathname: "/newsalesinvoice" }}
                                state={{
                                  id: -1,
                                  pagename: "New Invoice",
                                  soid: this.state.Orders.customsoid,
                                }}
                              >
                                {"Invoice (P)"}
                                {this.state.Orders.sotype === 1 ? (
                                  ""
                                ) : (
                                  <>&nbsp;<i className="fas fa-long-arrow-alt-right"></i></>
                                )}
                              </Link>:<span className="text-yellow">
                              {"Invoice (P)"}
                                {this.state.Orders.sotype === 1 ? (
                                  ""
                                ) : (
                                  <>&nbsp;<i className="fas fa-long-arrow-alt-right"></i></>
                                )}
                              </span>}
                              &nbsp;
                            </span>
                          ) : (
                            <span style={{ borderBottom: "1px solid red" }}>
                              {this.state.sostatus === "Confirmed" &&
                              (this.state.Orders.advancepaymentreq ===
                                "False" ||
                                (this.state.Orders.advancepaymentreq ===
                                  "True" &&
                                  this.state.Orders.proformainvoicestatus ===
                                    "Completed")) ? (
                                      screens.includes("Invoice") && (salesid === '2' || salesid === '3')?
                                <Link
                                  as="button"
                                  className="text-red"
                                  to={{ pathname: "/newsalesinvoice" }}
                                  state={{
                                    id: -1,
                                    pagename: "New Invoice",
                                    soid: this.state.Orders.customsoid,
                                  }}
                                >
                                  {"Invoice (P)"}
                                  {this.state.Orders.sotype === 1 ? (
                                    ""
                                  ) : (
                                    <>&nbsp;<i className="fas fa-long-arrow-alt-right"></i></>
                                  )}
                                </Link>:
                                <span  className="text-red">
                                 {"Invoice (P)"}
                                  {this.state.Orders.sotype === 1 ? (
                                    ""
                                  ) : (
                                    <>&nbsp;<i className="fas fa-long-arrow-alt-right"></i></>
                                  )}
                                </span>
                              ) : (
                                <span className="text-red">
                                  {"Invoice (P)"}&nbsp;<i className="fas fa-long-arrow-alt-right"></i>
                                </span>
                              )}
                              &nbsp;
                            </span>
                          )}
                          {/* </>} */}
                          {this.state.Orders.sotype === 1 ? (
                            ""
                          ) : (
                            <>
                              {this.state.Orders.deliverystatus ===
                              "Completed" ? (
                                <span
                                  className="text-green"
                                  style={{ borderBottom: "1px solid #00ff00" }}
                                >
                                  {"DN"}&nbsp; (
                                  <i className="fa fa-check text-green text-sm" />
                                  )&nbsp; &nbsp;
                                </span>
                              ) : this.state.Orders.deliverystatus ===
                                "Partial" ? (
                                <span
                                  style={{ borderBottom: "1px solid yellow" }}
                                >
                                  <span
                                    className="text-yellow"
                                  >
                                    {"DN (P)"}
                                  </span>
                                  &nbsp;&nbsp;
                                </span>
                              ) : (
                                <span style={{ borderBottom: "1px solid red" }}>
                                  <span
                                    className="text-red"
                                  >
                                    {"DN (P)"}
                                  </span>
                                  &nbsp;&nbsp;
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div>
                            <div className="card-header">
                              <h3 className="card-title">Sale Order Details</h3>
                              {this.state.sostatus === "Cancelled" ||
                              this.state.sostatus === "Completed" ||
                              this.state.sostatus === "Pending" ? (
                                ""
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                                    data-tip
                                    data-for="mail"
                                    // onClick={() => this.handleShow()}
                                    onClick={() =>
                                      this.handlepdfDownload(
                                        this.state.Orders,
                                        "mail"
                                      )
                                    }
                                  ></button>
                                  <button
                                    className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                                    data-tip
                                    data-for="pdf"
                                    onClick={() =>
                                      this.handlepdfDownload(
                                        this.state.Orders,
                                        "pdf"
                                      )
                                    }
                                  ></button>
                                  {/* //<Tooltip
                                    id="mail"
                                    place="top"
                                    effect="solid"
                                  >
                                    Send Mail
                                  </Tooltip>
                                  //<Tooltip
                                    id="pdf"
                                    place="top"
                                    effect="solid"
                                  >
                                    Download pdf
                                  </Tooltip> */}
                                </>
                              )}
                            </div>
                            <div className="card-body">
                              <div className="form-row">
                                <div className="form-column col-md-4">
                                  <table className="float-left m-0">
                                    <tbody>
                                      <tr>
                                        <td>Order ID</td>
                                        <td>
                                          :&nbsp; {this.state.Orders.customsoid}
                                        </td>
                                      </tr>
                                      {/* <tr>
                            <td>User Id</td>
                            <td>:&nbsp; {this.state.Orders.createdby}</td>
                          </tr> */}
                                      <tr>
                                        <td>Created By</td>
                                        <td>
                                          :&nbsp;{" "}
                                          {this.state.Orders.createdbyname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Customer</td>
                                        <td>
                                          :&nbsp; {this.state.Orders.customer}
                                        </td>
                                      </tr>
                                      {this.state.Orders.agent === null ||
                                      this.state.Orders.agent === undefined ||
                                      this.state.Orders.agent === "" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Agent</td>
                                          <td>
                                            :&nbsp; {this.state.Orders.agent}
                                          </td>
                                        </tr>
                                      )}
                                      {this.state.Orders.whid === null ||
                                      this.state.Orders.whid === undefined ||
                                      this.state.Orders.whid === "" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Warehouse</td>
                                          <td>
                                            :&nbsp;{" "}
                                            {this.state.Orders.warehouse}
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Order Status</td>
                                        <td>
                                          :&nbsp; {this.state.Orders.sostatus}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Order Type</td>
                                        <td>
                                          :&nbsp;{" "}
                                          {this.state.Orders.sotype === 0
                                            ? "Products"
                                            : this.state.Orders.sotype === 1
                                            ? "Services"
                                            : "Products - Services"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Date</td>
                                        <td>
                                          :&nbsp;{" "}
                                          {moment(
                                            this.state.Orders.createdAt
                                          ).format("DD-MM-YYYY HH:mm:ss")}
                                        </td>
                                      </tr>
                                      {this.state.status === "Cancelled" ? (
                                        ""
                                      ) : (
                                        <>
                                          {this.state.sostatus ===
                                          "Confirmed" ? (
                                            <>
                                              {this.state.pofile === null ||
                                              this.state.pofile === "" ? (
                                                ""
                                              ) : (
                                                <tr>
                                                  <td>PO file &nbsp;</td>
                                                  <td>
                                                    : &nbsp;{" "}
                                                    <>
                                                      <span
                                                        className="m-0"
                                                        data-tip
                                                        data-for="download"
                                                        onClick={() =>
                                                          this.handledownload()
                                                        }
                                                      >
                                                        <i className="fas fa-file-pdf mr-1" />
                                                        {this.state.pofile}
                                                      </span>
                                                      <button
                                                        className="text-red btn btn-link m-0"
                                                        onClick={() =>
                                                          this.handleDeleteProforma(
                                                            this.state.id
                                                          )
                                                        }
                                                        data-tip
                                                        data-for="delete"
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </button>

                                                      {/* //<Tooltip
                                                        id="delete"
                                                        place="top"
                                                        effect="solid"
                                                      >
                                                        Delete
                                                      </Tooltip>
                                                      //<Tooltip
                                                        id="download"
                                                        place="top"
                                                        effect="solid"
                                                      >
                                                        Download pdf
                                                      </Tooltip> */}
                                                    </>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-column col-md-4">
                                  <table className="float-left  m-0">
                                    <tbody>
                                      {this.state.Orders.currency ===
                                      currency ? (
                                        ""
                                      ) : (
                                        <>
                                          <tr>
                                            <td>Currency</td>
                                            <td>
                                              :&nbsp;{" "}
                                              {this.state.Orders.currency}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Currency Conversion Rate</td>
                                            <td>
                                              :&nbsp;{" "}
                                              {
                                                this.state.Orders
                                                  .curconversionrate
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                      <tr>
                                        <td>Tax Type</td>
                                        <td>
                                          :&nbsp; {this.state.Orders.taxtype}
                                        </td>
                                      </tr>
                                      {this.state.Orders.taxtype === "GST" ? (
                                        <tr>
                                          <td>GST Type</td>
                                          <td>
                                            :&nbsp; {this.state.Orders.gsttype}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      {(this.state.Orders
                                        .totaladdtitionalcharges === 0 ||
                                        this.state.Orders
                                          .totaladdtitionalcharges === null) &&
                                      (this.state.Orders
                                        .totalinsuranceamount === 0 ||
                                        this.state.Orders
                                          .totalinsuranceamount === null) ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Order Amount</td>
                                          <td>
                                            :&nbsp;{" "}
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders.sototalwtax -
                                                this.state.Orders
                                                  .totaladdtitionalcharges -
                                                this.state.Orders
                                                  .totalinsuranceamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      )}
                                      {this.state.Orders
                                        .totaladdtitionalcharges === 0 ||
                                      this.state.Orders
                                        .totaladdtitionalcharges === null ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Additional Charges</td>
                                          <td>
                                            :&nbsp;{" "}
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .totaladdtitionalcharges
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      )}
                                      {this.state.Orders
                                        .totalinsuranceamount === 0 ||
                                      this.state.Orders.totalinsuranceamount ===
                                        null ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Insurance Amount</td>
                                          <td>
                                            :&nbsp;{" "}
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .totalinsuranceamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Total Amount</td>
                                        <td>
                                          :&nbsp;{" "}
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              this.state.Orders.sototalwtax
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      {this.state.Orders.adjustedamount !== null?
                                      <tr>
                                        <td>Adjusted Amount</td>
                                        <td>
                                          :&nbsp;{" "}
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              this.state.Orders.adjustedamount
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>:""}
                                      <tr>
                                        <td>Received</td>
                                        <td>
                                          :&nbsp;{" "}
                                          <NumericFormat
                                            displayType={"text"}
                                            className="text-success"
                                            value={this.state.Orders.paid}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Balance</td>
                                        <td>
                                          :&nbsp;{" "}
                                          <NumericFormat
                                            displayType={"text"}
                                            className="text-danger"
                                            value={this.state.Orders.balance}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      {this.state.Orders.advancepaymentreq ===
                                      "True" ? (
                                        <>
                                          <tr>
                                            <td>
                                              Advance Amount Req.&nbsp;&nbsp;
                                            </td>
                                            <td>
                                              :&nbsp;{" "}
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .advanceamountreq
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Advance Amount Received</td>
                                            <td>
                                              :&nbsp;{" "}
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .advanceamountpaid
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <tr>
                                        <td>Payment Terms</td>
                                        <td>
                                          :&nbsp;{" "}
                                          {this.state.Orders.paymentterms}
                                          &nbsp;days
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-column col-md-4">
                                  <table className="float-left table-sm m-0">
                                    <tbody>
                                      {this.state.Orders.invoices !== null && this.state.Orders.invoices !== undefined &&
                                      this.state.Orders.invoices.length > 0 ? (
                                        <tr>
                                          <td>Invoices</td>
                                          <td>
                                            :&nbsp;
                                            {this.state.Orders.invoices.map((e,idx)=><>
                                            <Link
                                            to={{pathname: "/viewsalesinvoice"}}
                                              state={ {
                                                id: this.state.Orders.invoices,
                                                pagename: "Tax Invoice Details",
                                                type: "Tax Invoice"
                                            }}
                                          >
                                            {e + (idx===this.state.Orders.invoices.length-1?"":', ')}
                                          </Link>
                                          <br />
                                          </>
                                            )}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.Orders.dns !== null && this.state.Orders.dns !== undefined &&
                                      this.state.Orders.dns.length !== 0 ? (
                                        <tr>
                                          <td>Delivery Notes</td>
                                          <td>
                                            :&nbsp;
                                            {this.state.Orders.dns.map((e,idx)=><>
                                            <Link as="button" to={{pathname: "/viewdeliverynote"}} state ={{id:e,soid:this.state.Orders.soid}}
                                              >{e + (idx===this.state.Orders.dns.length-1?"":", ")}</Link>
                                              <br/>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {this.state.saleorderitems.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  <TableContainer>
                                    <Typography>Products</Typography>
                                    <Table
                                      aria-label="collapsible table"
                                      className="table-sm border"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell />
                                          <TableCell className="text-center">
                                            SO Id
                                          </TableCell>

                                          <TableCell className="text-center">
                                            Item Name
                                          </TableCell>
                                          {this.state.Orders.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              HSN
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            Price
                                          </TableCell>
                                          {currency ===
                                          this.state.Orders.currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              Price({this.state.Orders.currency}
                                              )
                                            </TableCell>
                                          )}
                                          {currency ===
                                          this.state.Orders.currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              Price Variance (%)
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            Type
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Quantity
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Pending Quantity
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Discount
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              Discount (
                                              {this.state.Orders.currency})
                                            </TableCell>
                                          )}
                                          {/* <TableCell className="text-center">
                                            Insurance
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              Insurance (
                                              {this.state.Orders.currency})
                                            </TableCell>
                                          )} */}
                                          <TableCell className="text-center">
                                            Total{" "}
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              Total (
                                              {this.state.Orders.currency})
                                            </TableCell>
                                          )}
                                          {this.state.Orders.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <>
                                              {this.state.Orders.taxtype ===
                                                "GST" &&
                                              ccountry === "India" ? (
                                                this.state.Orders.gsttype ===
                                                "INTRASTATE" ? (
                                                  <>
                                                    <TableCell className="text-center">
                                                      CGST
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      SGST
                                                    </TableCell>
                                                    {this.state.Orders
                                                      .currency === currency ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <TableCell className="text-center">
                                                          CGST(
                                                          {
                                                            this.state.Orders
                                                              .currency
                                                          }
                                                          )
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          SGST(
                                                          {
                                                            this.state.Orders
                                                              .currency
                                                          }
                                                          )
                                                        </TableCell>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <TableCell className="text-center">
                                                      IGST
                                                    </TableCell>
                                                    {this.state.Orders
                                                      .currency === currency ? (
                                                      ""
                                                    ) : (
                                                      <TableCell className="text-center">
                                                        IGST Amount(
                                                        {
                                                          this.state.Orders
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                    )}
                                                  </>
                                                )
                                              ) : this.state.Orders.taxtype ===
                                                "GST" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    GST
                                                  </TableCell>
                                                  {this.state.Orders
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      GST(
                                                      {
                                                        this.state.Orders
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <TableCell className="text-center">
                                                    VAT
                                                  </TableCell>
                                                  {this.state.Orders
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      VAT(
                                                      {
                                                        this.state.Orders
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                              <TableCell className="text-center">
                                                Total Price
                                              </TableCell>
                                              {this.state.Orders.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  Total Price(
                                                  {this.state.Orders.currency})
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.saleorderitems.map(
                                          (row, index) => (
                                            <DataRows
                                            Orders={this.state.Orders}
                                            row={row}
                                            index={index}
                                          />
                                          )
                                        )}
                                        <TableRow className="text-center">
                                          <TableCell className="text-center">
                                            Total
                                          </TableCell>
                                          {this.state.Orders.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <TableCell />
                                          )}
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <>
                                              <TableCell />
                                              <TableCell />
                                            </>
                                          )}
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders.totaldiscount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .totaldiscount *
                                                  this.state.Orders
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {/* <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .totalinsuranceamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>{" "}
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .totalinsuranceamount *
                                                  this.state.Orders
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )} */}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .grandproducttotal -
                                                this.state.Orders
                                                  .totaltaxamount -
                                                this.state.Orders
                                                  .totalinsuranceamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>{" "}
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  (this.state.Orders
                                                    .grandproducttotal -
                                                    this.state.Orders
                                                      .totaltaxamount -
                                                    this.state.Orders
                                                      .totalinsuranceamount) *
                                                  this.state.Orders
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.Orders.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <>
                                              {this.state.Orders.taxtype ===
                                                "GST" &&
                                              this.state.Orders.gsttype ===
                                                "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        this.state.Orders
                                                          .totaltaxamount / 2
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>

                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        this.state.Orders
                                                          .totaltaxamount / 2
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      this.state.Orders
                                                        .totaltaxamount
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {currency ===
                                              this.state.Orders.currency ? (
                                                ""
                                              ) : (
                                                <>
                                                  {this.state.Orders.taxtype ===
                                                    "GST" &&
                                                  this.state.Orders.gsttype ===
                                                    "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (this.state.Orders
                                                              .totaltaxamount /
                                                              2) *
                                                            this.state.Orders
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>

                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (this.state.Orders
                                                              .totaltaxamount /
                                                              2) *
                                                            this.state.Orders
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                    </>
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          this.state.Orders
                                                            .totaltaxamount *
                                                          this.state.Orders
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                              <TableCell className="text-right">
                                                {this.state.grandtotal ===
                                                "" ? (
                                                  "0"
                                                ) : (
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      this.state.Orders
                                                        .grandproducttotal
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                )}
                                              </TableCell>
                                              {currency ===
                                              this.state.Orders.currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  {this.state.grandtotal ===
                                                  "" ? (
                                                    "0"
                                                  ) : (
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        this.state.Orders
                                                          .grandproducttotal *
                                                        this.state.Orders
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  )}
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              )}
                              {this.state.serviceList.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  <TableContainer>
                                    <Typography>Services</Typography>
                                    <Table className="table-sm border">
                                      <TableHead className="text-sm">
                                        <TableRow
                                          style={{ textAlign: "center" }}
                                        >
                                          <TableCell className="text-center">
                                            Sl. No.
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Service
                                          </TableCell>
                                          <TableCell className="text-center">
                                            HSN/SAC Code
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Price
                                          </TableCell>
                                          {this.state.Orders.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.Orders.taxtype ===
                                            "GST" ? (
                                            ccountry === "India" ? (
                                              this.state.Orders.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST (%)
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST (%)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center">
                                                  IGST (%)
                                                </TableCell>
                                              )
                                            ) : (
                                              <TableCell className="text-center">
                                                GST (%)
                                              </TableCell>
                                            )
                                          ) : (
                                            <TableCell className="text-center">
                                              VAT (%)
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            TotalPrice
                                          </TableCell>
                                          {this.state.serviceList
                                            .map((e) => e.contracttype)
                                            .toLocaleString() ===
                                          "Recurring" ? (
                                            <TableCell className="text-center">
                                              Recurring Type
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody className="text-sm">
                                        {this.state.serviceList.map(
                                          (data, index) => (
                                            <TableRow
                                              key={index}
                                              className="text-center"
                                            >
                                              <TableCell className="text-center">
                                                {index + 1}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.service}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.servicecode}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                {" "}
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.servicecost}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.Orders.taxtype ===
                                              "NA" ? (
                                                ""
                                              ) : this.state.Orders.taxtype ===
                                                  "GST" &&
                                                ccountry === "India" &&
                                                this.state.Orders.gsttype ===
                                                  "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (data.servicetotalwtax -
                                                          data.servicecost) /
                                                        2
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    ({data.servicetax / 2}%)
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (data.servicetotalwtax -
                                                          data.servicecost) /
                                                        2
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    ({data.servicetax / 2}%)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      data.servicetotalwtax -
                                                      data.servicecost
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  ({data.servicetax}%)
                                                </TableCell>
                                              )}

                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.servicetotalwtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.serviceList
                                                .map((e) => e.contracttype)
                                                .toLocaleString() ===
                                              "Recurring" ? (
                                                <TableCell className="text-center">
                                                  {this.state.serviceList.map(
                                                    (e) => e.recurringtype
                                                  )}
                                                </TableCell>
                                              ) : (
                                                ""
                                              )}
                                            </TableRow>
                                          )
                                        )}
                                        <TableRow>
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          {this.state.Orders.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.Orders.taxtype ===
                                              "GST" &&
                                            ccountry === "India" &&
                                            this.state.Orders.gsttype ===
                                              "INTRASTATE" ? (
                                            <>
                                              <TableCell />
                                              <TableCell />
                                            </>
                                          ) : (
                                            <TableCell />
                                          )}
                                          <TableCell className="text-center">
                                            Total
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .grandservicetotal
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.serviceList
                                            .map((e) => e.contracttype)
                                            .toLocaleString() ===
                                          "Recurring" ? (
                                            <TableCell></TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  {this.state.milestones.length === 0 ? (
                                    ""
                                  ) : (
                                    <TableContainer>
                                      <Typography>Milestones</Typography>
                                      <Table className="table-sm border">
                                        <TableHead className="text-sm">
                                          <TableRow
                                            style={{ textAlign: "center" }}
                                          >
                                            <TableCell className="text-center">
                                              Name
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Expected Date
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Percentage
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Amount
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.milestones.map(
                                            (data, index) => (
                                              <TableRow key={index}>
                                                <TableCell className="text-center">
                                                  {data.name}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {moment(
                                                    data.expecteddate
                                                  ).format("DD-MM-YYYY")}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {data.percentage} %
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={data.totalpricewtax}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                </>
                              )}

                                    {this.state.Orders.balance<0.5 && this.state.Orders.balance !== 0?<button
                                              className="btn btn-sm btn-warning mr-1 text-white"
                                              onClick={() =>
                                                this.handleCloseOrder()
                                              }
                                            >Close Order</button>:""}

                              {this.state.showAdvance ? (
                                <>
                                  <fieldset className="col-md-3">
                                    <TextField
                                      name="advance"
                                      label="Advance amount"
                                      value={this.state.advance}
                                      onChange={(e) =>
                                        this.setState({
                                          advance: e.target.value,
                                        })
                                      }
                                      fullWidth
                                      variant="standard"
                                      error={
                                        this.state.Orders.sototalwtax <
                                        parseFloat(this.state.advance) +
                                          this.state.Orders.advanceamountreq
                                      }
                                      helperText={
                                        this.state.Orders.sototalwtax <
                                        parseFloat(this.state.advance) +
                                          this.state.Orders.advanceamountreq
                                          ? "Advance amount exceeds"
                                          : ""
                                      }
                                    />
                                  </fieldset>
                                  <fieldset className="col-md-12 my-2" />

                                  <button
                                    className="btn btn-sm hovbuttonColour mr-3"
                                    onClick={() => this.handleAddAdvance()}
                                  >
                                    <i className="fa fa-save mr-1"></i>
                                    save
                                  </button>
                                  <button
                                    className="btn btn-sm deletebutton"
                                    onClick={() => this.handleAdvancehide()}
                                  >
                                    <i className="fa fa-times mr-1"></i>
                                    Close
                                  </button>
                                </>
                              ) : (
                                ""
                              )}

                              {/* E-mail modal */}
                              <Modal
                                show={this.state.show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                size="lg"
                              >
                                <Modal.Header closeButton>
                                  <b>Mail</b>
                                </Modal.Header>
                                <div className="form-row mt-5 ml-5">
                                  <fieldset className="form-group col-md-4">
                                    <label htmlFor="btn-upload">
                                      <input
                                        className="btn-choose"
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.selectFile}
                                      />
                                      <Button
                                        className="btn-close"
                                        variant="outlined"
                                        type="upload"
                                        component="span"
                                      >
                                        <span className="text-blue">
                                          Select File to Upload
                                        </span>
                                      </Button>
                                    </label>
                                  </fieldset>

                                  <fieldset className="form-group col-md-3">
                                    <ul className="list-group">
                                      {selectedFiles && selectedFiles.length > 0
                                        ? selectedFiles[0].name
                                        : null}
                                    </ul>
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <Button
                                      className="btn-upload"
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                      disabled={!selectedFiles}
                                      onClick={this.upload}
                                    >
                                      Upload
                                    </Button>
                                  </fieldset>

                                  <div className="form-group col-md-9">
                                    <Typography
                                      variant="subtitle2"
                                      className={`upload-message ${
                                        isError ? "error" : ""
                                      }`}
                                    >
                                      <span className="text-green">
                                        {message}
                                      </span>
                                    </Typography>
                                  </div>
                                  <div className="form-group col-md-9">
                                    <button
                                      className="btn btn-sm hovbuttonColour float-center"
                                      onClick={this.handleSendMail}
                                    >
                                      Send{" "}
                                      <i
                                        className="fa fa-paper-plane"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>

                                <Modal.Body></Modal.Body>
                              </Modal>

                              {/* Upload po modal */}
                              <Modal
                                show={this.state.showModal}
                                onHide={this.handleModalClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                size="lg"
                              >
                                <Modal.Header closeButton>PO File</Modal.Header>
                                <div className="form-row mt-5 ml-5">
                                  <fieldset className="form-group col-md-4">
                                    <label htmlFor="btn-upload">
                                      <input
                                        className="btn-choose"
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.selectFile}
                                      />
                                      <Button
                                        className="btn-close"
                                        variant="outlined"
                                        type="upload"
                                        component="span"
                                      >
                                        <span className="text-blue">
                                          Select File to Upload
                                        </span>
                                      </Button>
                                    </label>
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <ul className="list-group">
                                      {selectedFiles && selectedFiles.length > 0
                                        ? selectedFiles[0].name
                                        : null}
                                    </ul>
                                  </fieldset>

                                  <fieldset className="form-group col-md-3">
                                    <Button
                                      className="btn-upload"
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                      disabled={!selectedFiles}
                                      onClick={this.upload}
                                    >
                                      Upload
                                    </Button>
                                  </fieldset>

                                  {/* </div> */}
                                  <div className="form-group col-md-9">
                                    <Typography
                                      variant="subtitle2"
                                      className={`upload-message ${
                                        isError ? "error" : ""
                                      }`}
                                    >
                                      <span className="text-green">
                                        {message}
                                      </span>
                                    </Typography>
                                  </div>
                                  {this.state.Orders.potype === 1 ? (
                                    ""
                                  ) : (
                                    <>
                                      <div className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="poid"
                                          onChange={(e) =>
                                            this.setState({
                                              poid: e.target.value,
                                            })
                                          }
                                          label="PO Id."
                                        />
                                      </div>
                                      <div className="form-group col-md-4">
                                        <TextField
                                          id="amount"
                                          className="fAmountorm-control"
                                          type="number"
                                          name="amount"
                                          value={this.state.amount}
                                          label="PO Amount"
                                          onChange={(e) =>
                                            this.setState({
                                              amount: e.target.value,
                                            })
                                          }
                                          onFocus={this.onfocus}
                                        />
                                      </div>
                                    </>
                                  )}

                                  {this.state.amount === "" ||
                                  this.state.fileInfos.length === 0 ||
                                  this.state.poid === "" ? (
                                    ""
                                  ) : (
                                    <div className="form-group col-md-9">
                                      <button
                                        className={`btn btn-sm hovbuttonColour float-center`}
                                        onClick={this.handleFinalSubmit}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </div>

                                <Modal.Body></Modal.Body>
                              </Modal>
                              {/* Order Cancellation Modal */}
                              <Modal
                                show={this.state.showCancelModal}
                                onHide={this.handleCancelModalClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                size="lg"
                              >
                                <Modal.Header closeButton>
                                  Order{" "}
                                  {this.state.Orders === ""
                                    ? ""
                                    : this.state.Orders.invoices.length === 0
                                    ? "Cancellation"
                                    : "Preclose"}
                                </Modal.Header>
                                <div className="form-row mt-3 ml-3">
                                  <fieldset className="form-group col-md-8">
                                    <TextField
                                      name="desc"
                                      className="form-control"
                                      multiline
                                      rows={2}
                                      label={`Reason For ${
                                        this.state.Orders === ""
                                          ? ""
                                          : this.state.Orders.invoices
                                              .length === 0
                                          ? "Cancellation"
                                          : "Preclose"
                                      } `}
                                      variant="outlined"
                                      value={this.state.desc}
                                      onChange={(e) =>
                                        this.setState({ desc: e.target.value })
                                      }
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-12" />
                                  <fieldset className="form-group col-md-12" />
                                  {this.state.desc === "" ? (
                                    <button
                                      className="btn btn-sm hovbuttonColour float-center ml-2"
                                      onClick={this.handleCancel}
                                      disabled
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm hovbuttonColour float-center ml-2"
                                      onClick={this.handleCancel}
                                    >
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <Modal.Body> </Modal.Body>
                              </Modal>

                              <div style={{ marginTop: "10px" }}>
                                {salesid === "3" || salesid === "2" ? (
                                  <>
                                    {this.state.status === "Cancelled" ? (
                                      // || this.state.status === "Completed"
                                      ""
                                    ) : (
                                      <>
                                        {displaybutton}
                                        {displaydeletebutton}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          {this.state.Orders.canceldesc !== null &&
                          this.state.Orders.canceldesc !== undefined &&
                          this.state.Orders.canceldesc !== "" ? (
                            <fieldset className="form-group col-md-12">
                              <label className="text-red">
                                {this.state.Orders.sostatus} Description :{" "}
                                {this.state.Orders.canceldesc}
                              </label>
                            </fieldset>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* /.card */}
                      </div>
                      {/* /.col */}
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
                </section>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ViewOrder);
