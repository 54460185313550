import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../withRouter";
import { FormikTextField } from "../../CommonComponents/FormField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { currentTenant, userid } from "../../Common/Credentials";
import InventoryService from "../InventoryService";
import { Spinner } from "react-bootstrap";

const NewInternalconsumption = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [BatchList,setBatchList] = useState([])
    const [batchid,setBatchid] = useState("")
    const [customid,setCustomId] = useState("")
    const [stock,setStock] = useState("")
    const [item,setItem] = useState("")
    const [date,setDate] = useState(new Date())
    const [avlquantity,setAvlquantity] = useState(0)
    const [description,setDescription] = useState('')
    const [wid,setwid] = useState("")


    useEffect(() => {
      InventoryService.getinternalconsumptionstock(currentTenant).then((res)=>setBatchList(res.data.sort((a, b) => (a.id < b.id ? 1 : -1))))
    }, [])
    


    const handlebatchchange = (value, values) => {
        if (values !== "" && typeof values === "object" && values !== null) {
          setBatchid(values.id)
          setCustomId(values.customid)
          setAvlquantity(values.totalstock)
          setItem(values.item)
          setwid(item.wid)
        } else {
            setBatchid("");
            setCustomId("")
            setAvlquantity(0)
            setItem("")
        }
    }

    const handleDateChange = (d) =>{
        setDate(d)
    }

    const onSubmit = () => {
      if(stock<=avlquantity){
        let data = {
            batchid:batchid,
            stock:stock,
            date:date,
            wid:wid,
            description:description,
            createdby:userid,
            updatedby:userid,
            tenantid:currentTenant
        }
        setLoading(true)
        InventoryService.AddInternalConsumption(data).then(()=>navigate(-1)).catch(()=>setLoading(false))
      }
    }

    const validate = () => {
      let errors ={}
          if(batchid === "" || batchid === null){
            errors.batchid = 'Please select a Batch'
          }
          if(stock === undefined || stock === "" || stock === null){
            errors.stock = 'Please enter quantity'
          }else
          if(stock>avlquantity){
            errors.stock = 'Quantity Excceds'
          }
          if(stock<=0){
            errors.stock = 'Please Enter Positive Quantity'
          }
      return errors
    }

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div>
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary cursorPointer"
                  to={-1}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item active">Internal Consumption</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div className="card-body">
                        <div>
                          <Formik
                            initialValues={{}}
                            onSubmit={onSubmit}
                            validateOnChange={false}
                            validate={validate}
                            enableReinitialize={true}
                          >
                            {({ setFieldValue, values }) => (
                              <Form autoComplete="off">
                                <div className="form-row">
                                <fieldset className="form-group col-md-3 pb-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="batchid"
                              options={BatchList}
                              getOptionLabel={(option) => `${option.customid} (${option.item})`}
                              value={values.customid ? undefined : undefined}
                              onChange={handlebatchchange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="batchid"
                                  value={customid}
                                  label={`Select Batch (${customid})`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                             {batchid !== null && batchid !==""?<p className="text-sm">(Batch stock Avilable: {avlquantity})</p>:""}
                          </fieldset>
                            
                          <fieldset className="form-group col-md-3 pb-3">
                            <FormikTextField
                            className="form-control"
                            type="text"
                            name="item"
                            value={item}
                            label="Item/Product"
                            variant="standard"
                            fullWidth
                            disabled
                            />
                          </fieldset>
                          
                          <fieldset className="form-group col-md-3 pb-3">
                            <FormikTextField
                            className="form-control"
                            type="text"
                            name="stock"
                            value={stock}
                            onChange={((e)=>setStock(e.target.value))}
                            label={`Enter consumed quantity`}
                            variant="standard"
                            fullWidth
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container>
                              <DatePicker
                                id="date-picker-dialog"
                                label="Consumption Date"
                                format="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={date}
                                onChange={handleDateChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                                autoOk={true}
                                fullWidth
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>
                        <fieldset className="form-group col-md-12  mt-3 mb-5">
                          <FormikTextField name="description" className="form-control" multiline rows={1} label="Description/Specifications" variant="outlined" value={description}
                            onChange={(e) =>setDescription(e.target.value)}
                          />
                        </fieldset>
                      <button
                        className="btn btn-sm hovbuttonColour"
                        type="submit"
                        disabled={loading}
                      >
                        {loading?<Spinner animation="border" size="sm" />:<span className="fa fa-save" />}
                         Save
                      </button>
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={-1}
                    >
                      <i className="fa fa-times "/> Cancel
                    </Link>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default withRouter(NewInternalconsumption);
