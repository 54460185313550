import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Form, Formik } from "formik";
import { FormikTextField } from "../../CommonComponents/FormField";
import withRouter from "../../withRouter";
import { Link } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Footer from "../../Component/Footer";
import SalesServices from "../SalesServices";
import {
  ccountry,
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";

const CustomerCreditnote = (props) => {
  const [invdata, setInvdata] = useState("");
  const [customer, setCustomer] = useState("");
  const [custid, setCustid] = useState("");
  const [cnlist, setcnlist] = useState([]);
  const [cn, setCn] = useState("");
  const [tax, setTax] = useState("");
  const [sgst, setSgst] = useState("");
  const [cgst, setCgst] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [types] = useState(["Pricediff", "Returns"]);
  const [type, setType] = useState("Pricediff");
  const [refund,setRefund] = useState(0)
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState();
  const [rowitemid, setRowitemid] = useState("");
  const [additionalrowid, setAdditionalrowid] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [totalAmountwtax, setTotalAmountwtax] = useState(0);
  const [creditnoteitems, setCreditnoteitems] = useState([]);
  const [producttype, setProducttype] = useState(0)
  const [insuranceamount,setInsuranceamount] = useState(0)
  const [tcsamt,setTcsamt] = useState(0)

  useEffect(() => {
    console.log(props.location.state)
    setInvdata(props.location.state.invdata);
    setCustomer(props.location.state.customer);
    setCustid(props.location.state.custid);
    setcnlist(
      props.location.state.cnlist === null ||
        props.location.state.cnlist === undefined 
        ? []
        : props.location.state.cnlist
    );
  }, []);

  const handleCNChange = (val, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setCn(values.id);
      SalesServices.getCreditNoteitemsbyId(
        values.id
      ).then((response) =>{
        setCreditnoteitems(response.data)
      });
    } else {
      setCn("");
      setCreditnoteitems([]);
    }
  };
  const handleSelect = (val,ptype) => {
    if(ptype==="products"){
    setSelected(val);
    setSgst(val.tax / 2);
    setCgst(val.tax / 2);
    setTax(val.tax);
    setRowitemid(val.id);
    setProducttype(0)
    }else if(ptype === "Addcharges"){
      setSelected(val);
      setSgst(val.additionalchargestax / 2);
      setCgst(val.additionalchargestax / 2);
      setTax(val.additionalchargestax);
      setAdditionalrowid(val.id);
      setProducttype(1)
      setRowitemid()
    }else if(ptype === "Insurance"){
      setSelected(val);
      setSgst(val.insurancetax / 2);
      setCgst(val.insurancetax / 2);
      setTax(val.insurancetax);
      setRowitemid()
      setAdditionalrowid()
      setProducttype(2)
    }
  };

  const onSubmit = () => {
    let data = {
      invid: invdata.custominvid,
      updatedby: userid,
      amount: amount,
      tax: tax,
      totalamount: totalAmount,
      totalAmountwtax: totalAmountwtax,
      tenanid: currentTenant,
      id: type === "Pricediff" ? null : cn,
      type: type,
      description: description,
      rowitemid: rowitemid,
      additionalrowid: additionalrowid,
      quantity: 0,
      producttype:producttype,
      insuranceamount:insuranceamount,
      tcsamt:tcsamt,
      refund:type === "Pricediff" && invdata.payment === "Pending"?3:refund
    };
    Swal.fire({
      title: `Generate Credit Note?`,
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setLoader(true);
        SalesServices.GenerateCreditNote(data)
          .then(() => props.navigate(-1))
          .catch((error) => setLoader(false));
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (type === "Pricediff") {
      if (amount === "" || amount === 0) {
        errors.amount = "Please Enter amount";
      }
      if (description === "") {
        errors.description = "Please Enter Reason/Narration";
      }
    } else if (type === "Returns") {
      if (cn === "") {
        errors.cn = "Please Select Returns";
      }
    }

    return errors;
  };
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <span
                as="button"
                className=" text-secondary cursorPointer"
                onClick={() => props.navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/inventory">Home</Link>
            </li>
            {/* <li className="breadcrumb-item text-secondary">
              <Link to="/customercreditnote">Customer Credit Note</Link>
            </li> */}
            <li className="breadcrumb-item active">Customer Credit Note</li>
          </ol>
          <div className="card-header">
            <h3 className="card-title">Customer Credit Note</h3>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <div className="card-body">
            <Formik
              initialValues={{
                customer,
                amount,
                cgst,
                sgst,
                tax,
                totalAmount,
                description,
              }}
              onSubmit={onSubmit}
              validateOnChange={false}
              validate={validate}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form autoComplete="off">
                  <div className="form-row">
                    <fieldset className="col-md-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="customer"
                        value={customer}
                        label={`Customer`}
                        variant="standard"
                        fullWidth
                        disabled
                      />
                    </fieldset>

                    <fieldset className="col-md-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="invoice"
                        value={invdata.custominvid}
                        label={`Invoice`}
                        variant="standard"
                        fullWidth
                        disabled
                      />
                    </fieldset>

                    <fieldset className="form-group col-md-3">
                      <FormControl variant="standard" className="col-md-12">
                        <InputLabel id="demo-simple-select-standard-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setSelected("");
                            setSgst("");
                            setCgst("");
                            setTax("");
                            setRowitemid("");
                            setQuantity(0);
                            setCreditnoteitems([]);
                          }}
                          label="Type"
                          fullWidth
                        >
                          {types.map((e, i) => (
                            <MenuItem value={e} key={i}>
                              {e}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </fieldset>

                    {type === "Returns" ? (
                      <>
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="cn"
                            options={cnlist}
                            getOptionLabel={(option) => option.id}
                            value={values.cn ? undefined : undefined}
                            onChange={handleCNChange}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="cn"
                                value={cn}
                                label={`Select Returns (${cn})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        {creditnoteitems.length > 0 ? (
                          <TableContainer>
                            <Table className="table-sm">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Id</TableCell>
                                  <TableCell>Item Name</TableCell>
                                  <TableCell>HSN</TableCell>
                                  <TableCell>Price</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>UOM</TableCell>
                                  <TableCell>TotalPrice</TableCell>
                                  <TableCell>Tax</TableCell>
                                  <TableCell>TotalPrice(Inc tax)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {creditnoteitems.map((data, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{data.id}</TableCell>
                                    <TableCell>{data.name}</TableCell>
                                    <TableCell>{data.hsn}</TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={data.price}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>{data.returnquantity}</TableCell>
                                    <TableCell>{data.uom}</TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={data.totalprice}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      {data.taxamount}({data.tax}%)
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={data.totalpricewtax}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        {selected === null ||
                        selected === "" ||
                        selected === undefined ? (
                          <>
                            <TableContainer className="mt-5">
                              <Typography>Products</Typography>
                              <Table
                                aria-label="collapsible table"
                                className="table-sm border"
                              >
                                <TableHead>
                                  <TableRow>
                                    {/* <TableCell /> */}
                                    <TableCell className="text-center">
                                      Item Name
                                    </TableCell>
                                    {invdata.taxtype === "NA" ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        HSN
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Price
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Quantity
                                    </TableCell>

                                    <TableCell className="text-center">
                                      Total{" "}
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Grand Total{" "}
                                    </TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invdata?.invoiceitemlist?.map((row, index) => (
                                    <React.Fragment key={index}>
                                      <TableRow>
                                        <TableCell className="text-center">
                                          {row.name}
                                        </TableCell>
                                        {invdata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            {row.hsn}
                                          </TableCell>
                                        )}
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={row.price}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {row.quantity}
                                          {row.uom}
                                        </TableCell>

                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={row.totalprice}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>

                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={row.totalpricewtax}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            className="btn btn-sm hovbuttonColour cursorPointer"
                                            onClick={() =>
                                              handleSelect(row, "products")
                                            }
                                          >
                                            Select
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {invdata.additionalchares && invdata.additionalchares.length > 0 ||
                            invdata.insurancereq ? (
                              <TableContainer className="my-2">
                                <Typography>
                                  Additional Charges / Insurance
                                </Typography>
                                <Table className="table-sm border">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Nature of charge</TableCell>
                                      <TableCell className="text-center">
                                        Additional charge
                                      </TableCell>
                                      {invdata.taxtype === "NA" ? (
                                        ""
                                      ) : invdata.taxtype === "GST" &&
                                        ccountry === "India" ? (
                                        invdata.gsttype === "INTRASTATE" ? (
                                          <>
                                            <TableCell className="text-center">
                                              CGST
                                            </TableCell>
                                            <TableCell className="text-center">
                                              SGST
                                            </TableCell>
                                          </>
                                        ) : (
                                          <>
                                            <TableCell className="text-center">
                                              IGST
                                            </TableCell>
                                          </>
                                        )
                                      ) : invdata.taxtype === "GST" ? (
                                        <>
                                          <TableCell className="text-center">
                                            GST
                                          </TableCell>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <TableCell className="text-center">
                                            VAT
                                          </TableCell>
                                        </>
                                      )}

                                      <TableCell className="text-center">
                                        Total Additional charge
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {invdata.additionalchares.map((e, i) => (
                                      <TableRow key={i}>
                                        <TableCell>
                                          {e.natureofcharge}
                                        </TableCell>
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={e.additionalcharges}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        {invdata.taxtype === "NA" ? (
                                          ""
                                        ) : invdata.taxtype === "GST" &&
                                          ccountry === "India" &&
                                          invdata.gsttype === "INTRASTATE" ? (
                                          <>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={(
                                                  e.additionalchargestaxamount /
                                                  2
                                                ).toFixed(2)}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              ({e.additionalchargestax / 2}
                                              %)
                                            </TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={(
                                                  e.additionalchargestaxamount /
                                                  2
                                                ).toFixed(2)}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              ({e.additionalchargestax / 2}
                                              %)
                                            </TableCell>
                                          </>
                                        ) : (
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                e.additionalchargestaxamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                            ({e.additionalchargestax.toFixed(2)}
                                            %)
                                          </TableCell>
                                        )}
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={e.totaladditionalcharges}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell className="text-center">
                                          <span
                                            className="btn btn-sm hovbuttonColour cursorPointer"
                                            onClick={() =>
                                              handleSelect(e, "Addcharges")
                                            }
                                          >
                                            Select
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    {invdata.insurancereq ? (
                                      <TableRow>
                                        <TableCell>Insurance</TableCell>
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={invdata.insuranceamount}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>

                                        {invdata.taxtype === "NA" ? (
                                          ""
                                        ) : invdata.taxtype === "GST" &&
                                          ccountry === "India" &&
                                          invdata.gsttype === "INTRASTATE" ? (
                                          <>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  (invdata.totalinsurance -
                                                    invdata.insuranceamount) /
                                                  2
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              (
                                              {(
                                                invdata.insurancetax / 2
                                              ).toFixed(2)}
                                              %)
                                            </TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  (invdata.totalinsurance -
                                                    invdata.insuranceamount) /
                                                  2
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              (
                                              {(
                                                invdata.insurancetax / 2
                                              ).toFixed(2)}
                                              %)
                                            </TableCell>
                                          </>
                                        ) : (
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                invdata.totalinsurance -
                                                invdata.insuranceamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                            ({invdata.insurancetax.toFixed(2)}
                                            %)
                                          </TableCell>
                                        )}

                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={invdata.totalinsurance}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        {/* <TableCell className="text-center">
                                          <span
                                            className="btn btn-sm hovbuttonColour cursorPointer"
                                            onClick={() =>
                                              handleSelect(invdata, "Insurance")
                                            }
                                          >
                                            Select
                                          </span>
                                        </TableCell> */}
                                      </TableRow>
                                    ) : (
                                      ""
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <fieldset className="form-group col-md-12" />
                        <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="amount"
                            value={amount}
                            label={`Diff Amount`}
                            onChange={(e) => {
                              setAmount(e.target.value);
                              setTotalAmount(
                                parseFloat(
                                  e.target.value === null ||
                                    e.target.value === ""
                                    ? 0
                                    : e.target.value
                                ).toFixed(2)
                              );
                              setTotalAmountwtax(
                                (
                                  parseFloat(
                                    e.target.value === null ||
                                      e.target.value === ""
                                      ? 0
                                      : e.target.value
                                  ) +
                                  (parseFloat(
                                    tax === null ||
                                      tax === "" ||
                                      tax === undefined
                                      ? 0
                                      : tax
                                  ) *
                                    parseFloat(
                                      e.target.value === null ||
                                        e.target.value === ""
                                        ? 0
                                        : e.target.value
                                    )) /
                                    100
                                ).toFixed(2)
                              );
                            }}
                            variant="standard"
                            fullWidth
                          />
                        </fieldset>
                        {invdata.gsttype === "INTRASTATE" ? (
                          <>
                            <fieldset className="col-md-2">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="cgst"
                                onChange={(e) => {
                                  setCgst(e.target.value);
                                  setSgst(e.target.value);
                                  setTax(parseFloat(e.target.value * 2));
                                  setTotalAmount(
                                    parseFloat(
                                      amount === null || amount === ""
                                        ? 0
                                        : amount
                                    ).toFixed(2)
                                  );
                                  setTotalAmountwtax(
                                    (
                                      parseFloat(
                                        amount === null || amount === ""
                                          ? 0
                                          : amount
                                      ) +
                                      (parseFloat(
                                        e.target.value === null ||
                                          e.target.value === ""
                                          ? 0
                                          : e.target.value
                                      ) *
                                        2 *
                                        parseFloat(
                                          amount === null || amount === ""
                                            ? 0
                                            : amount
                                        )) /
                                        100
                                    ).toFixed(2)
                                  );
                                }}
                                value={cgst}
                                label={`CGST`}
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>
                            <fieldset className="col-md-2">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="sgst"
                                onChange={(e) => {
                                  setCgst(e.target.value);
                                  setSgst(e.target.value);
                                  setTax(parseFloat(e.target.value * 2));
                                  setTotalAmount(
                                    parseFloat(
                                      amount === null || amount === ""
                                        ? 0
                                        : amount
                                    ).toFixed(2)
                                  );
                                  setTotalAmountwtax(
                                    (
                                      parseFloat(
                                        amount === null || amount === ""
                                          ? 0
                                          : amount
                                      ) +
                                      (parseFloat(
                                        e.target.value === null ||
                                          e.target.value === ""
                                          ? 0
                                          : e.target.value
                                      ) *
                                        2 *
                                        parseFloat(
                                          amount === null || amount === ""
                                            ? 0
                                            : amount
                                        )) /
                                        100
                                    ).toFixed(2)
                                  );
                                }}
                                value={sgst}
                                label={`SGST`}
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>
                          </>
                        ) : (
                          <fieldset className="col-md-2">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="tax"
                              onChange={(e) => {
                                setCgst(parseFloat(e.target.value) / 2);
                                setSgst(parseFloat(e.target.value) / 2);
                                setTax(e.target.value);
                                setTotalAmount(
                                  parseFloat(
                                    amount === null || amount === ""
                                      ? 0
                                      : amount
                                  ).toFixed(2)
                                );
                                setTotalAmountwtax(
                                  (
                                    parseFloat(
                                      amount === null || amount === ""
                                        ? 0
                                        : amount
                                    ) +
                                    (parseFloat(
                                      e.target.value === null ||
                                        e.target.value === ""
                                        ? 0
                                        : e.target.value
                                    ) *
                                      parseFloat(
                                        amount === null || amount === ""
                                          ? 0
                                          : amount
                                      )) /
                                      100
                                  ).toFixed(2)
                                );
                              }}
                              value={tax}
                              label={`IGST`}
                              variant="standard"
                              fullWidth
                            />
                          </fieldset>
                        )}
                        <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="totalAmountwtax"
                            value={totalAmountwtax}
                            label={`Total`}
                            variant="standard"
                            fullWidth
                            disabled
                          />
                        </fieldset>
                        <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="tcsamt"
                            value={tcsamt}
                            label={`TCS Amount`}
                            onChange={(e)=>setTcsamt(e.target.value)}
                            variant="standard"
                            fullWidth
                            
                          />
                        </fieldset>
                        <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="insuranceamount"
                            value={insuranceamount}
                            onChange={(e)=>setInsuranceamount(e.target.value)}
                            label={`Insurance Amount`}
                            variant="standard"
                            fullWidth
                            
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-12  mt-3 mb-5">
                          <FormikTextField
                            name="description"
                            className="form-control"
                            multiline
                            rows={1}
                            label="Reason/Narration"
                            variant="outlined"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </fieldset>
                        
                        {type === "Returns"?"": invdata.payment !== "Pending" &&  <div className="col-md-12">
                        <FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
                                <FormControlLabel control={<Checkbox checked={refund===0} onChange={()=>setRefund(0)}/>} label="Refund" />
                                <FormControlLabel control={<Checkbox checked={refund===1} onChange={()=>setRefund(1)}/>} label="Adjust amount in next Invoice" />
                              </div>}
                      </>
                    )}
                    <button type="submit" className="btn btn-sm hovbuttonColour">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(CustomerCreditnote);
