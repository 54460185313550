import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
  financialyearstart,financialyearend, plloss, plprofit
} from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import NewJournalService from "../NewJournalService.js";
import "./table.css";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import PlComponent from "./PlComponent.js";


let liab=[]
let asst=[]
let liabilityamt=[]
let assetamt=[]
let privyears=[]



const asstTotal = (asset) => {
  let total = 0;
  //  console.log(data)
  asset.forEach((asset) => {
    total += asset;
  });
  // console.log(total)

  return total;
};
const liaTotal = (liability) => {
  let total = 0;
  //  console.log(data)
  liability.forEach((liability) => {
    total += liability;
  });
  // console.log(total)
  return total;
};

export let debitexcess, creditexcess;
class BalanceSheet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subaccdata: [],
      liabval:0,
      liadif:0,
      asstval:0,
      astdif:0,
      combdata: [],
      liabilities:[],
      assets:[],
      fylist:[],
      liabilityamt:[],
      assetamt:[],
      loading: false,
      loader1:true,
      fyfrom:null,
      fyto:null
    };
  }
  
  onFySelect(value){
    let year
    if(value.from!==financialyearstart){
      year={
        from:value.from,
        to:value.to,
        tenantid:currentTenant
          }
      this.setState({fyfrom:value.from,fyto:value.to})
    }else{
      year={
    from:'Cur',
    to:'year',
    tenantid:currentTenant
      }
      this.setState({fyfrom:financialyearstart,fyto:financialyearend})

    }
    NewJournalService.getAssets(year).then((response) => {
      this.setState({ assets: response.data },()=>this.assetsubheads())
      this.setState({ loading: false });
    })
    NewJournalService.getLiabilities(year).then((response) => {
      this.setState({ liabilities: response.data },()=>this.liabsubheads())
      this.setState({ loading: false });
    })
   // if(plprofit===undefined || plloss===undefined ){
    PlComponent.onFySelect(value) 
   // NewPandL.onFySelect(value) 
    //  <NewPandL/>     // 
   // }
  }
 
   
  componentDidMount() {
   
    this.setState({ loading: true });
// console.log(financialyearstart + " And "+ financialyearend)
   

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

  
 
    // NewJournalService.getAllLedgers(currentTenant).then((response) => {
    //   this.setState({ combdata: response.data });

      //  // this.setState({combdata:response.data})
  //  })

  NewJournalService.getFinancialYears(currentTenant).then((response) => {
    this.setState({ fylist: response.data },()=>this.fyyears())
    this.setState({ loading: false,loader1:false });
  })

 
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }

  })
  }
  liabsubheads(){
    let valliab=[]
    liab=[...new Set(this.state.liabilities.map((e) => { return e.shname})),]
    valliab=[...new Set(this.state.liabilities.map((e) => { return e.amount})),]
    liabilityamt=valliab.map((val,i)=>{return Math.abs(val)})
    this.setState({liabilityamt:liabilityamt})
    this.setState({liabval:liaTotal(liabilityamt)+plprofit})
    this.setState({loader1:false})
  }
  assetsubheads(){
    let valasset=[]
    asst=[...new Set(this.state.assets.map((e) => { return e.shname})),]
    valasset=[...new Set(this.state.assets.map((e) => { return e.amount})),]
    assetamt=valasset.filter((val,i)=>{return Math.abs(val)})
   // alert(asstTotal(valasset))
   this.setState({assetamt:assetamt})
   this.setState({asstval:asstTotal(assetamt)-plloss},()=>this.addDiff())
    this.setState({loader1:false})
  }
  addDiff(){
    if(this.state.liabval-this.state.asstval>0){
      this.setState({liadif:this.state.liabval-this.state.asstval})
    }else if(this.state.asstval-this.state.liabval>0){
      this.setState({astdif:this.state.asstval-this.state.liabval})
    }

  }
  fyyears(){
   // let fyyears=[]
   //let def={from:'Current',to:'year'}
   let def={from:financialyearstart,to:financialyearend}

  
this.setState({ 
  fylist: this.state.fylist.concat(def)
})   // val=this.state.fylist.filter((e,i)=>e.from &&)
   
    //this.setState({loader1:false})
  }
  // calcSubTotal(name1){
   
  
  //   let a =this.state.liabilities.filter((e)=>{return e.shname===name1})
  //   subheads=a.filter((e)=>{return e.shname})

  //  // console.log(subheads)

  // }

  render() {
   

    

  //  let shname1
  // let liab=[...new Set(this.state.liabilities.map((e) => { return e.shname})),];
  //         shname1=''
  //           if(liab.find((e,i)=>e!==null)){
  //              shname1=liab.find((e,i)=>e.valueOf(i));
  //           }
  // console.log(liab)

    return (
      <div>
        
        <Header />
        <Menu />
<Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">Balance Sheet</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>

              <li className="breadcrumb-item active text-backgroundColour">Balance Sheet</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
                <div className="text-center">
                  {this.state.loading === true ? spin : ""}
                </div>

                <Formik  initialValues={{ }}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>
                  {({ values, setFieldValue }) => (
                    <Form>
                        <fieldset className="form-group col-md-4 mt-0 mb-3">
<Autocomplete id="fylist1" className="fy-select " name="fylist" options={this.state.fylist} getOptionLabel={(option) => option.from.substring(0,4) +"-"+  option.to.substring(0,4)}
 // isOptionEqualToValue ={(option) => option.id}
value={values.fylist? undefined: undefined} onChange={(e, value) => {setFieldValue("fylist", value) && this.onFySelect(value)}}
renderInput={(params) => (
<FormikTextField {...params} className="form-control mb-3" type="text" name="fylist" label="Select Financial year" 
  // onBlur={(e) => {this.onAeChange(e.target.value, values)}}
//  onBlur={(e) => {this.onSoSelect(values)}} 
  variant="standard" fullWidth/>)}/></fieldset>
                      <div><h5 className="text-center">Balance Sheet</h5></div>
                      <div><p className="text-center">For the Financial Year - {this.state.fyfrom!==null?moment(this.state.fyfrom).format('DD-MM-YYYY'):'DD-MM-YYYY'} --- {this.state.fyto!==null?moment(this.state.fyto).format('DD-MM-YYYY'):'DD-MM-YYYY'}
                     </p></div>

 <Table id="tb" className="table table-sm table-borderless col-md-12">
  <TableBody><TableRow>

 {/* --------------------------------------------------Left Side------------------------------------------------- */}

  <TableCell className="m-0 p-0 col-md-6">
  <Table id="tbliability" className="m-0 p-0 " style={{border:'0.5px solid #f3eaea'}}>
  <TableHead> <TableRow><TableCell className="text-center text-white backgroundColour">Liabilities</TableCell></TableRow>
  </TableHead>
  
  <TableBody>
   {liab.map((e,i) => (
  <TableRow>

   {/* {resultsub.totamount !== null ? ( */}
  <TableCell className="m-0 p-0">
  <Table id="tbi1i" className="table table-sm table-borderless">
  <TableHead className="thead-light">
  {/* {resultsub.shname === shname ?<> */}

  <TableCell className="text-sm text-left text-black">{liab[i]}</TableCell>
  {/* <TableCell hidden className="text-sm text-right text-black mt-0 mb-0" >{this.calcSubTotal(resultsub.shname)}</TableCell> */}

   <TableCell className="text-sm text-left text-black"></TableCell>
   {/* </>:""} */}

  </TableHead>
  

  {/* <TableBody> */}
    
 {this.state.liabilities.map((resultsub) => ( 
 <TableRow key={resultsub.id}> 
 {resultsub.shname === liab[i]  ?<>

  {/* {resultsub.shname === shname ? ( <> */}
 <TableCell className="text-sm text-left text-blue">
 <Link to={{pathname: "/ledgerview"}} state={resultsub.name}>{resultsub.name}</Link></TableCell>
 <TableCell className="text-sm text-right text-blue">
 {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay} value={Math.abs(resultsub.amount)}>
 </NumericFormat>}</TableCell>
 
  {/* </> ) : ("")} */}

  </>:""}
  
   </TableRow>

  ))}
 
</Table>
</TableCell>
</TableRow>
 ))}

</TableBody>
{plprofit!==0?
<Table>
  <TableHead className="thead-light">
  <TableCell className="text-sm text-left text-black">Profit / Loss</TableCell>
   <TableCell className="text-sm text-left text-black"></TableCell>
  </TableHead>
  <TableRow className="col-md-12"> 
  <TableCell className="text-sm text-left text-blue">
   Profit</TableCell>
   <TableCell className="text-sm text-right text-blue">
   {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay} value={plprofit}>
   </NumericFormat>}</TableCell>
  </TableRow></Table>:""}
  <Table>
  <TableHead className="thead-light">
  <TableCell className="text-sm text-left text-black">B/S Difference</TableCell>
   <TableCell className="text-sm text-left text-black"></TableCell>
  </TableHead>
  <TableRow className="col-md-12"> 
  <TableCell className="text-sm text-left text-blue">
   Diff</TableCell>
   <TableCell className="text-sm text-right text-blue">
   {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay} value={this.state.liadif}>
   </NumericFormat>}</TableCell>
  </TableRow></Table>
 </Table>
 </TableCell>

 {/* --------------------------------------------------Right Side------------------------------------------------- */}

<TableCell className="m-0 p-0 col-md-6">
  <Table id="tblassets" className="m-0 p-0 " style={{border:'0.5px solid #f3eaea'}}>
  <TableHead> <TableRow><TableCell className="text-center text-white backgroundColour">Assets</TableCell></TableRow>
  </TableHead>
  
  <TableBody>
   {asst.map((e,i) => (
  <TableRow>

  <TableCell className="m-0 p-0">
  <Table id="tbi1i" className="table table-sm table-borderless">
  <TableHead className="thead-light">
  <TableCell className="text-sm text-left text-black">
  {asst[i]}</TableCell>
   <TableCell className="text-sm text-left text-black"></TableCell>
  </TableHead>    
 {this.state.assets.map((rasset) => ( 
 <TableRow key={rasset.id}> 
 {rasset.shname === asst[i]  ?<>
 <TableCell className="text-sm text-left text-blue">{rasset.rule!==2?
 <Link to={{pathname: "/ledgerview"}} state={rasset.name}>{rasset.name}</Link>:rasset.name}</TableCell>
 <TableCell className="text-sm text-right text-blue">
 {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay} value={rasset.amount}>
 </NumericFormat>}</TableCell>
  </>:""}
   </TableRow>
  ))}

</Table>
</TableCell>
 </TableRow>
 ))}
</TableBody>
{plloss!==0?
<Table>
  <TableHead className="thead-light">
  <TableCell className="text-sm text-left text-black">Profit / Loss</TableCell>
   <TableCell className="text-sm text-left text-black"></TableCell>
  </TableHead>
  <TableRow className="col-md-12"> 
  <TableCell className="text-sm text-left text-blue">
   Loss</TableCell>
   <TableCell className="text-sm text-right text-blue">
   {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay} value={plloss}>
   </NumericFormat>}</TableCell>
  </TableRow></Table>:""}
  <Table>
  <TableHead className="thead-light">
  <TableCell className="text-sm text-left text-black">B/S Difference</TableCell>
   <TableCell className="text-sm text-left text-black"></TableCell>
  </TableHead>
  <TableRow className="col-md-12"> 
  <TableCell className="text-sm text-left text-blue">
   Diff</TableCell>
   <TableCell className="text-sm text-right text-blue">
   {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay} value={this.state.astdif}>
   </NumericFormat>}</TableCell>
  </TableRow></Table>
 </Table>
 </TableCell>
 </TableRow>
 <TableRow >
<TableCell className="text-sm bg-light text-black">
<TableRow className="m-0 p-0 col-md-12 ">
<TableCell className="text-sm font-weight-bold text-left text-blue text-bold mt-0 mb-0 col-md-12">Liabilities Total</TableCell>
<TableCell className="text-sm font-weight-bold text-right text-blue text-bold mt-0 mb-0 col-md-12">
  {<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay} value={(liaTotal(liabilityamt)+plprofit)+this.state.astdif}></NumericFormat>}</TableCell>
</TableRow>
</TableCell>
<TableCell className="text-sm bg-light text-black">
<TableRow className="m-0 p-0 col-md-12 ">
<TableCell className="text-sm font-weight-bold text-left text-blue text-bold mt-0 mb-0 col-md-12">Asset Total</TableCell>
<TableCell className="text-sm font-weight-bold text-right text-blue text-bold mt-0 mb-0 col-md-12">
{<NumericFormat displayType={ "text" } decimalScale={2} fixedDecimalScale={true}  disabled={true} thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay} value={(asstTotal(assetamt)-plloss)+this.state.liadif}></NumericFormat>}</TableCell>
</TableRow>
</TableCell>
 </TableRow>
</TableBody>
</Table>

                     

                      {/* <table id="table" hidden>
                        <thead>
                          <tr>
                            <th>Table</th>
                            <th>Table2</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td/>
                          </tr>
                        </tbody>
                      </table> */}
                    </Form>
                  )}
                </Formik>
                {/* <button onClick={() => this.pdf()}>Pdf</button> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(BalanceSheet);
