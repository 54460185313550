import Credentials, { currentTenant, financialyearend, financialyearstart } from "../../Common/Credentials"
import NewReportService from "../NewReportService"

let fyfrom,fyto,inputindirecttax,dirtaxpaid,subaccdata=[],debit,credit,creditexcess,debitexcess
const debTotal = (debit) => {
    let total=0 ;
    //  console.log(data)
    debit.forEach(debit => {
      total += debit;
    });
    // console.log(total)
    
    return total;
  };
  const creTotal = (credit) => {
    let total=0 ;
    //  console.log(data)
    credit.forEach(credit => {
      total += credit;
    });
    // console.log(total)
    return total;
  };
 const setProfit=(amt)=>{
    Credentials.setPlprofit(amt)
    }
const setLoss=(amt)=>{
      Credentials.setPlloss(amt)
    }
  
class PlComponent{
    onFySelect(value){
        let year
        if(value.from!==financialyearstart){
          year={
            from:value.from,
            to:value.to,
            tenantid:currentTenant
              }
              fyfrom =value.from
              fyto=value.to
          //this.setState({fyfrom:value.from,fyto:value.to})
        }else{
          year={
        from:'Cur',
        to:'year',
        tenantid:currentTenant
          }
          fyfrom =financialyearstart
          fyto=financialyearend
          //this.setState({fyfrom:financialyearstart,fyto:financialyearend})
    
        }
  
        NewReportService.getDirtaxPaid(currentTenant).then((res)=>{
          dirtaxpaid=res.data
        });
        NewReportService.getInputIndirecttax(currentTenant).then((res)=>{
          inputindirecttax=res.data
        });
       
        NewReportService.retrivePLLedgers(year).then((response) => {
          subaccdata=response.data.sort((a, b)=>{
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        }) });
        
        //   const script = document.createElement("script");
        //   script.src = "../../js/content.js";
        //   script.async = true;
        //   document.body.appendChild(script);

           debit = subaccdata.map(e => {return (e.th==="TH1"?e.totamount:0)})
          credit = subaccdata.map(e => {return (e.th==="TH2"?e.totamount:0)})

        creditexcess = creTotal(credit) > debTotal(debit)? creTotal(credit)-debTotal(debit):null
        debitexcess= debTotal(debit)> creTotal(credit)?debTotal(debit) - creTotal(credit):null

          setProfit(debitexcess)
          setLoss(creditexcess)

          //this.setState({ loading: false });
      //  })
        //NewJournalService.getAssets(year).then((response) => {
        //   this.setState({ assets: response.data },()=>this.assetsubheads())
        //   this.setState({ loading: false });
        // })
        // NewJournalService.getLiabilities(year).then((response) => {
        //   this.setState({ liabilities: response.data },()=>this.liabsubheads())
        //   this.setState({ loading: false });
        // })
      }
  
      
      fyyears(){
        // let fyyears=[]
        //let def={from:'Current',to:'year'}
        let def={from:financialyearstart,to:financialyearend}
  
    //  this.setState({ 
    //    fylist: this.state.fylist.concat(def)
    //  }) 

}

calculateLedgerExpences(totalamt,name){
    let a
        Object.entries(totalamt).forEach(([key, value]) => {
          if(key === name){
           a = {
            name: key,
            expenses: value.reduce((a, v) => 
            (a = a +(v.totamount === null? 0 : ((v.rule===(0||1) && v.entrytype===0 && v.name === name)?(v.rule===2  && v.entrytype===1 && v.name === name)?v.totamount:v.totamount:0))
            ), 0),
            // income: value.reduce((a, v) => 
            // (a = a +(v.totamount === null? 0 : ((v.rule===0,1 && v.entrytype===1 && v.name === name) ?(v.rule===2  && v.entrytype===0 && v.name === name )?v.totamount:v.totamount:0))
            // ), 0),
          };
        }
        });
        return a.expenses === 0?null: a.expenses
  }
  calculateLedgerIncome(totalamt,name){
    let a
        Object.entries(totalamt).forEach(([key, value]) => {
          if(key === name){
           a = {
            name: key,
            // expenses: value.reduce((a, v) => 
            // (a = a +(v.totamount === null? 0 : ((v.rule===0,1 && v.entrytype===0 && v.name === name)?(v.rule===2  && v.entrytype===1 && v.name === name)?v.totamount:v.totamount:0))
            // ), 0),
            income: value.reduce((a, v) => 
            (a = a +(v.totamount === null? 0 : ((v.rule===(0||1) && v.entrytype===1 && v.name === name) ?(v.rule===2  && v.entrytype===0 && v.name === name )?v.totamount:v.totamount:0))
            ), 0),
          };
        }
        });
        return a.income === 0 ?null:a.income
  }
 calcSubTotal(name1){
  cre1=subaccdata.map((e) => { return e.th==="TH2" && e.name===name1 ? e.totamount:0})
  deb1=subaccdata.map((e) => { return e.th==="TH1" && e.name===name1 ? e.totamount:0})

}



}
export default new PlComponent();