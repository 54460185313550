import React, { Component } from 'react'
import { Formik, Form, FastField } from "formik";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { currentTenant, currentUser, financialyearend, financialyearstart, thousandsdisplay, userid } from '../Common/Credentials';
import {Autocomplete, Backdrop, CircularProgress, createTheme, TableCell, TableContainer, TableRow, TextField, ThemeProvider,TableBody, TableHead ,Table, FormControlLabel, Switch} from '@mui/material';
import moment from 'moment';
import NewJournalService from './NewJournalService';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Button, Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import MUIDataTable from 'mui-datatables';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import withRouter from '../withRouter';
import Swal from "sweetalert2";
import { FormikTextField } from '../CommonComponents/FormField';

let actualData = [];

const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach(totamt => {
    total += totamt;
  });
  return total;
};


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
let ppaymennts,val=[]
class AccountsCompleted extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          completedPayments:[],
          completedReceipts:[],
          totalcombdata:[],
          partyadvances:[],
          actualData:[],
          totalval:'',pftot:'',pttot:'',insuranceTot:'',
          pendingInsurance:[],
            loader:false,            
            loader1:true,
             ppayments:[],genppayments:[],
            selectbtn:[
              {id:1,name:"Receipts"},
              {id:2,name:"Payments"}],
              selectedpmtoption:'',
              selectedoption:'',
              abcde:12345,
              loading:false
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.loadData=this.loadData.bind(this);this.addData=this.addData.bind(this)
    }

    onSubmit(values){



      this.setState({loader:true})

      let pendings, pmtledgerid,pmtledgername,creth
      // if(this.state.selectedpmtoption===1){
      // pendings = this.state.actualData

      // }else if(this.state.selectedpmtoption===2){
      //   pendings = this.state.pendingPf
      // }else if(this.state.selectedpmtoption===3){
      //   pendings = this.state.pendingInsurance
      // }else if(this.state.selectedpmtoption===4){
      //   pendings = this.state.pendingPt
      // }else if(this.state.selectedpmtoption===5){
      //   pendings = this.state.pendingIt
      // }

      if(values.credit!==undefined){
        pmtledgerid=values.credit.id
        pmtledgername=values.credit.name
        creth=values.credit.th
      }
     // console.log(values)
       let jv ={
        pendingval:this.state.actualData,
        pmtledgerid:pmtledgerid,
        pmtledgername:pmtledgername,
        tenantid:currentTenant,
        createdBy:currentUser,
        amount:this.state.totalval,
        creth:values.credit.th,
        paymentref:values.paymentref,
        type:this.state.selectedpmtoption
       }
      
       if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
  
      }else  if(!values.creditvalue){
        alert("Please select records")
        this.setState({loader:false})
       }
      else {
       // console.log(jv)
      this.props.navigate({ pathname: `/salconfirm` }, { state: jv });
      }
    // }
    }

    componentDidMount(){
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }else{ 
        this.loadData()
      }
      
     
       }

      

    loadData(){
      let data={
        tenantId:currentTenant,
        fromDate:null,//fsd,
        toDate:null//fed
      }
     // console.log("loadData")
     this.setState({loading:true})

      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data})
       })
    
       NewJournalService.getCompletedReceipts(data)
       .then(response =>{
         this.setState({completedReceipts:response.data})  
       }) 
      NewJournalService.getCompletedPayments(data)
      .then(response =>{
        this.setState({completedPayments:response.data,loading:false},()=>this.addData())  
      })  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    }

    onChange(option){
      this.setState({selectedoption: option.id});
    }

    // onSalChange(option){
    //   this.onUpdateVal([])
    //   console.log("onSalChange",option)
    //   this.setState({selectedpmtoption: option.id,actualData:[],totalval:0});
    //       // if(option.id===1 && this.state.salpendingData.salary!==null){
    //       // //  salval =this.state.salpendingData.salary.map((e)=>e.amount) 
    //       // //  if(this.state.actualData!=undefined && this.state.actualData.length!==0 ){
    //       // //   this.setState({totalval:parseFloat(getTotal(this.state.actualData.map(e=>e.amount))).toFixed(2)})
    //       // //  }else{
    //       // //  this.setState({totalval:parseFloat(getTotal(salval)).toFixed(2)})
    //       // //  }
    //       // // console.log(this.state.actualData)
    //       //  //console.log(this.state.actualData[6])

    //       // }else if(option.id===2 && this.state.salpendingData.pf!==null){
    //       // //  salval =this.state.salpendingData.pf.map((e)=>e.amount)
    //       // //  this.setState({totalval:getTotal(salval)})
    //       // }else if(option.id===3 && this.state.salpendingData.insurance!==null){
    //       // //  salval =this.state.salpendingData.insurance.map((e)=>e.amount)
    //       // //  this.setState({totalval:getTotal(salval)})
    //       // }else if(option.id===4 && this.state.salpendingData.pt!==undefined){
    //       // //  salval =this.state.salpendingData.pt.map((e)=>e.amount)
    //       // //  this.setState({totalval:getTotal(salval)})
    //       // }
    // }

    // onUpdateVal(rowState){
    //   console.log("onUpdateVal",rowState,this.state.salpendingData)
    //     let totval = []
    //     if(this.state.selectedpmtoption===1){
    //       if(this.state.salpendingData.salary!==null){
    //         let totval1=this.state.salpendingData.salary.filter(e=>e.amount!==0)
    //         totval  =  rowState.map(f=>totval1[f.index])
    //        if(totval!==undefined && totval.length!==0){
    //       this.setState({actualData:totval})
    //       this.setState({totalval:parseFloat(getTotal(totval.map(e=>e.amount))).toFixed(2)})
    //        }else{
    //         this.setState({actualData:[],totalval:0})
    //       }
    //       }
    //     }else if (this.state.selectedpmtoption===2){
    //       if(this.state.salpendingData.pf!==null){
    //         let totval1=this.state.salpendingData.pf.filter(e=>e.amount!==0)
    //         totval  =  rowState.map(f=>totval1[f.index])
    //         if(totval!==undefined && totval.length!==0){
    //        this.setState({actualData:totval})
    //        this.setState({totalval:parseFloat(getTotal(totval.map(e=>e.amount))).toFixed(2)})
    //         }else{
    //          this.setState({actualData:[]})
    //          this.setState({totalval:getTotal([])})
    //        }
    //        }
    //     }else if (this.state.selectedpmtoption===3){
    //       if(this.state.salpendingData.insurance!==null){
    //         let totval1=this.state.salpendingData.insurance.filter(e=>e.amount!==0)
    //         totval  =  rowState.map(f=>totval1[f.index])
    //         if(totval!==undefined && totval.length!==0){
    //        this.setState({actualData:totval})
    //        this.setState({totalval:parseFloat(getTotal(totval.map(e=>e.amount))).toFixed(2)})
    //         }else{
    //          this.setState({actualData:[]})
    //          this.setState({totalval:getTotal([])})
    //        }
    //        }
    //     }else if (this.state.selectedpmtoption===4){
    //       if(this.state.salpendingData.pt!==null){
    //         let totval1=this.state.salpendingData.pt.filter(e=>e.amount!==0)
    //         totval  =  rowState.map(f=>totval1[f.index])
    //         if(totval!==undefined && totval.length!==0){
    //        this.setState({actualData:totval})
    //        this.setState({totalval:parseFloat(getTotal(totval.map(e=>e.amount))).toFixed(2)})
    //         }else{
    //          this.setState({actualData:[]})
    //          this.setState({totalval:getTotal([])})
    //        }
    //        }
    //     }else if (this.state.selectedpmtoption===5){
    //       if(this.state.salpendingData.it){
    //         let totval1=this.state.salpendingData.it.filter(e=>e.amount!==0)
    //         totval  =  rowState.map(f=>totval1[f.index])
    //         if(totval!==undefined && totval.length!==0){
    //        this.setState({actualData:totval})
    //        this.setState({totalval:parseFloat(getTotal(totval.map(e=>e.amount))).toFixed(2)})
    //         }else{
    //          this.setState({actualData:[]})
    //          this.setState({totalval:getTotal([])})
    //        }
    //        }
    //     }
        
    // }

    addData(){
      this.setState({loader1:false})

      //console.log("addData")
      // this.setState({pendingSalaries:this.state.salpendingData.salary!==undefined?this.state.salpendingData.salary.filter(e=>e.amount>0):[],pendingPf:this.state.salpendingData.pf!==undefined?this.state.salpendingData.pf.filter(e=>e.amount>0):[],
      //   pendingInsurance:this.state.salpendingData.insurance!==undefined?this.state.salpendingData.insurance.filter(e=>e.amount>0):[],pendingPt:this.state.salpendingData.pt!==undefined?this.state.salpendingData.pt.filter(e=>e.amount>0):[],
      //   pendingIt:this.state.salpendingData.it!==undefined?this.state.salpendingData.it.filter(e=>e.amount>0):[]})


       
      this.setState({
        // ppayments:this.state.completedPayments.filter((e)=>e.poid!==null && e.transpendingamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1),
        genreceipts:this.state.completedReceipts.filter((e)=> e.receiptamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1),
        genppayments:this.state.completedPayments.filter((e)=> e.paymentamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1)},
        ()=>this.setState({loader1:false}))
      }
    
        validate(values){
      
     }

  
    
     submitpnpRecord(value){

      let pendingpo
      if(value!==undefined){
      pendingpo=this.state.completedPayments.filter(e=>e.id===value)
      } 
      this.props.navigate({pathname:`/ape`},{state:pendingpo});
     }

     submitadvRecord(value){

      let pendingpo
      if(value!==undefined){
      pendingpo=this.state.partyadvances.filter(e=>e.id===value)
      pendingpo[0].selectedoption=this.state.selectedoption
      } 
      this.props.navigate({pathname:`/ape`},{state:pendingpo});
     }

     submitRevRecord(value){
     // console.log(value)
      let pendingso
      let a={
        abcde:12345,
      }
      if(value!==undefined){
      pendingso=this.state.partyadvances.filter(e=>e.id===value)     
      pendingso[0].rectselectedoption=4

      
     // pendingso[1].selectedoption=this.state.selectedoption

      } 
     // pendingso=

      this.props.navigate({pathname:`/are`}, {state: pendingso});


      // if(id!==undefined ){
      // let pendingso 
      // pendingso= this.state.pendingReceipts.filter((e)=>e.id===id)
      // this.props.history.push(`/are`, {pendingso});

      // }
     }

     loaderFunction(){
      let a = this.state.loader
     }

     onClickData(values){
     }
     submitRecordClose(value){
      Swal.fire({
        title: "Information",
        text: "This action is for final close of differences and not for regular transactions.Do you want to Continue ?",
        icon: "info",
        confirmButtonText: "Ok",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          let pendingpo,frompath
          if(value!==undefined){
          pendingpo=this.state.completedPayments.filter(e=>e.id===value)
          pendingpo=pendingpo.map((e)=> Object.assign(e,{frompath:this.props.location.pathname}))
          
         // pendingpo.concat(frompath=this.props.location.pathname)
          } 
          this.props.navigate({pathname:`/settlement`},{state:pendingpo});
          }else{
          this.setState({loader:false})
         // this.goBack()
          }
        })
   
     }

     submitRecord(value){
      let pendingpo
      if(value!==undefined){
      pendingpo=this.state.completedPayments.filter(e=>e.id===value)
      } 

      let pitems =[]
      pendingpo.map(e=>{return pitems=e.pitems});
      pitems.map(e=>
       (e.balance!==0)?//e.status!=="NORMAL" ||
        NewJournalService.getGrnStatus(e.customgrnid)
        .then(response=>{
          if(response.data===true){
            Swal.fire({
              title: "Information",
              text: "The GRN NO " + e.customgrnid + "  is cancelled, This will update the Entry and Payment pending ",
              icon: "info",
              confirmButtonText: "Ok",
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonColor: "#152f5f",
            }).then((result) => {
              if (result.value === true) {
                let  tmp,tmp1
                pendingpo.map(e=>{return tmp=e.custompoid})
                pendingpo.map(e=>{return tmp1=e.tenantId})

                let a ={
                  customegrnid:e.customgrnid,
                  custompoid:tmp,
                  tenantid:tmp1,
                  createdBy:currentUser
                }
                NewJournalService.cancelEntry(a)
                .then(response =>{
                  if(response.status===200){
                    Swal.fire({
                      title: "Information",
                      text: "The GRN Entry for " + e.customgrnid + "  is cancelled and update.",
                      icon: "info",
                      confirmButtonText: "Ok",
                      showCancelButton: true,
                     // cancelButtonText: "No",
                      confirmButtonColor: "#152f5f",
                    }).then((result) => {
                      if (result.value === true) {
                        this.props.navigate({pathname:`/accounts`});
                      }
                    })
                  }
                 // console.log(response)
                //   if(response!==null){
                  
                // }
                 })  
                }else{
                this.setState({loader:false})
                //this.goBack()
                }
              })
          }else{
            this.props.navigate({pathname:`/ape`},{state:pendingpo});
          }
         // alert(response.data)  
       })
      :""//this.props.navigate({pathname:`/ape`},{state:pendingpo})
      )
     // console.log(pitems);
      //
     }
    
    render() {
 
      let credvalue,cred1
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      cred1=val.filter((e) => e)
      // console.log(cred1)
// e.subheadid==="AH000020" || e.subheadid==="AH000021" ||
      credvalue=cred1.filter((e)=> e.subheadid==="AH000020" || e.subheadid==="AH000021" || e.subheadid==="AH000022")
     // console.log(credvalue)
      // const pendingpmts = [
      //   {name: 'id',label: 'Id',options: {filter: false,sort: false,display:false,viewColumns:false}},
      //   {name: "supname",label: "Supplier",options: {filter: true,sort: true}},
      //   {name: "custompoid",label: "PO ID",options: {filter: false,sort: true,}},
      //   {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc',
      //     customBodyRender:(tableMeta)=>{
      //       return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
      //     }}},
      //     {name: "poamount",label:"PO Amt",options: {filter: false,sort: true,
      //       customBodyRender: (value) => {
      //         return (
      //           <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
      //           disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
      //         );
      //       },
      //       setCellProps: () => ({ style: { textAlign:'right'}}),
      //     }},
      //     // {name: "transpendingamount",label:"Invoice Amt",options: {filter: false,sort: true,
      //     //   customBodyRender: (value) => {
      //     //     return (
      //     //       <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
      //     // disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
      //     //     );
      //     //   },
      //     //   setCellProps: () => ({ style: { textAlign:'right'}}),
      //     // }},
      //     {name: "poadvance",label: "Type",options: {filter: false,sort: false,empty: true,
      //         customBodyRender: (value) => {
      //      return (
      //     value!==0 ?"Purchase Advance":"Purchase"
      //           )},
      //         setCellProps: () => ({ style: { textAlign: "center" } } ),
      //       }},
      // {name: "poadvance",label: "Adv Pending",
      //       options: {filter: false,sort: false,empty: true,
             
      //         customBodyRender: (value) => {
      //   return (
      //     value!==0 ?
      //     <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
      //     disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:0
      //    );},
      //         setCellProps: () => ({ style: { textAlign: "right" } } ),
      //       }},
      // {name: "advancepaid",label: "Adv Paid",
      //       options: {filter: false,sort: false,empty: true,
      //         customBodyRender: (value) => {
      //   return (
      //     value!==0 ? 
      //     <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
      //     disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:0
      //     );},
      //         setCellProps: () => ({ style: { textAlign: "right" } } ),
      //       }},
      // {name: "transpendingamount",label: "Tranaction Pending",options: {filter: false,sort: false,empty: true,
      //         customBodyRender: (value) => {
      //           return (
      //             <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
      //             disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
      //           );
      //         },
      //         setCellProps: () => ({ style: { textAlign:'right'}}),
      //       }},
      // {name: "poadvance",label: "Adv-Action",options: {filter: false,sort: false,empty: true,
      //         customBodyRender: (value,tableMeta) => {
      //   return (
      //     value!==0 ?<button className="hovbuttonColour btn btn-sm"
      //     onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
      //     </button>:<span className="text-red text-bold">No Pending</span>
      //           );},
      //         setCellProps: () => ({ style: { textAlign: "center" } } ),
      //       }},
      
            
      
      // {name: "pitems",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
      //         customBodyRender:(value,tableMeta)=>{
      //           return (
      //             value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
      //             onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border"  size="sm" />&nbsp;{"Submitting"}</>:"pay"}
      //             </button>:<span className="text-red text-bold">No Invoices</span>
      //           );      
      
      //         }
            
      //       }},

        

      //       {name: "savailed",label: "Serv-Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
      //         customBodyRender:(value,tableMeta)=>{
      //           return (
      //             value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
      //             onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
      //             </button>:<span className="text-red text-bold">No Invoices</span>
      //           );      
      
      //         }
            
      //       }},
      //       {name: "pitems",label: "Close",options: {filter: false,sort: false,display:true,viewColumns:false,
        
      //         customBodyRender:(value,tableMeta)=>{
      //           return (
      //             value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
      //             onClick={() => this.submitRecordClose(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Settle"}
      //             </button>:<span className="text-red text-bold">No Invoices</span>
      //           );      
      
      //         }
            
      //       }},
      
      // ]
      // const ppoptions = {
      //   filterType: 'checkbox',
      //   selectableRows: "none",
      //  // responsive: "scroll",
        
      // }
      
      const pendingnonrects = [
        {name: "createdAt",label:"Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
          {name: "transid",label:"Rect ID" ,options: {filter: false,sort: false}},
          {name: "receiptamount",label:"Amount",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
          {name: "custominvid",label:"Invoice",options: {filter: false,sort: true,viewColumns:false}},
        {name: "customer",label:"Recd Ledger",options: {filter: true,sort: true}},
        {name: "paymentfor",label:"Expense For",options: {filter: false,sort: true,viewColumns:false}},
        {name: "mode",label:"Mode",options: {filter: false,sort: true,viewColumns:false}},
        {name: "accountnumber",label:"A/C",options: {filter: false,sort: true,viewColumns:false}},
        {
          name: "id",
          label: "Transaction",
          options: {
            filter: true,
            sort: true,
    
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                // <>
                //   {value !== null ? (
                    <Link
                      as="button"
                      data-tip
                      data-for="view"
                      to={{ pathname: "/jvdetails"}} state= {{ id: value}}
                    >
                      {value.id}
                    </Link>
              );
            },
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      ]
      const pnroptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        // responsive: "scroll",
        resizableColumns: true,
        
      }



      const pendingnonpmts = [
        {name: "createdAt",label:"Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
          {name: "transid",label:"PMT ID" ,options: {filter: false,sort: false}},
          {name: "paymentamount",label:"Amount",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
          {name: "custompoid",label:"POID",options: {filter: false,sort: true,viewColumns:false}},
        {name: "supname",label:"Paid Ledger",options: {filter: true,sort: true}},
        {name: "paymentfor",label:"Expense For",options: {filter: false,sort: true,viewColumns:false}},
        {name: "mode",label:"Mode",options: {filter: false,sort: true,viewColumns:false}},
        {name: "accountnumber",label:"A/C",options: {filter: false,sort: true,viewColumns:false}},
        {
          name: "id",
          label: "Transaction",
          options: {
            filter: true,
            sort: true,
    
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                // <>
                //   {value !== null ? (
                    <Link
                      as="button"
                      data-tip
                      data-for="view"
                      to={{ pathname: "/jvdetails"}} state= {{ id: value}}
                    >
                      {value.id}
                    </Link>
              );
            },
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      
      ]
      const pnpoptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        // responsive: "scroll",
        resizableColumns: true,
        
      }



        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1 || this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/journals'}} state= {{message:''}} >
                Completed Transactions
              </Link>
    </li>
  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {/* {this.state.loading===true?spin:''} */}
  </div>
              
        
    <Formik
      initialValues={{ ppaymennts}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({values,setFieldValue}) => (

        <Form autoComplete="off">


{this.state.selectbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onChange.bind(this,option)} 
               style={{marginRight: 4,width:250}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
}

{this.state.selectedoption===1?
<div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Receipts"}
                            data={this.state.genreceipts.map(oth=>{
                              return[oth.createdAt,
                                oth.transid,
                                oth.receiptamount,
                                oth.custominvid,
                                oth.customer,
                                oth.paymentfor, 
                                oth.mode,
                                oth.accountnumber,
                                oth.jvid!==null?oth.jvid:oth.cjvid!==null?oth.cjvid:"NA",oth.entryType]
                            })}
                            columns={pendingnonrects}
                            options={pnroptions}
                          />
                        </ThemeProvider>
                      </div>
                    :this.state.selectedoption===2?
 <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Payments"}
                            data={this.state.genppayments.map(oth=>{
                                return[oth.createdAt,
                                  oth.transid,
                                  oth.paymentamount,
                                  oth.custompoid,
                                  oth.supname,
                                  oth.paymentfor, 
                                  oth.mode,
                                  oth.accountnumber,
                                  oth.jvid!==null?oth.jvid:oth.cjvid!==null?oth.cjvid:"NA",oth.entryType]
                              })


                            }
                            columns={pendingnonpmts}
                            options={pnpoptions}
                          />
                        </ThemeProvider>
                      </div>
                     // :
//                       this.state.selectedoption===3?<div>
// {this.state.paymentbtn.map((option)=>{
// return <label key={option.id} >

//     <div className="text-sm col-md-12">
//       <div className="form-group col-md-12">
//       {/* <div className="form-control bg-light border-light"> */}

//              <input 
//                 className={
//                   this.state.selectedpmtoption === option.id
//                     ? "btn buttonColour text-yellow text-bold col-md-12"
//                     : "btn hovbuttonColour text-white col-md-12"
//                 }
//                 type="button" 
//                 // checked={this.state.checked === option.id ? true : false} 
//                 key={option.id}
//                 onClick={this.onSalChange.bind(this,option)} 
//                style={{marginRight: 4,width:250}}
//                 value={option.name} 
//                 />
//             {/* {option.name} */}
        
//             </div></div>
//             {/* </div> */}
//         </label>
       

// })
// } </div>
// :this.state.selectedoption===4?
//  <div className="mb-12">
//                       <ThemeProvider theme={theme}>
//                           <MUIDataTable
//                             className="table-sm px-3"
//                             title={`Party Direct Advances`}
//                             data={this.state.partyadvances}
//                             columns={partyadvances1}
//                             options={advoptions}
//                           />
//                         </ThemeProvider>
//                       </div>
                      :
                      ""}
{this.state.selectedoption===3?<>
{this.state.selectedpmtoption===1?


<div className="form-row mb-12">
{/* <fieldset className="form-group col-md-12 mt-0 mb-0">
<ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"Salary Payments"}
      data={this.state.selectedpmtoption === 1?this.state.pendingSalaries:[]}
      columns={pendingSal}
      options={saloptions}
    />
  </ThemeProvider>
</fieldset> */}

 <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval) }
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
{/* <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" || value === 0
        ? "Please Enter Value" : undefined } >
                    {({ meta }) => ( */}
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  value={this.state.totalval}
  variant="standard"> </FormikTextField></fieldset>
                    {/* )}
</FastField> */}
  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
{/* onClicl={this.onClickData(values)} */}
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>:this.state.selectedpmtoption===2?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
<ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"PF Payments"}
      data={this.state.selectedpmtoption===2?this.state.pendingPf:[]}
      columns={pendingOthers}
      options={saloptions}
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  value={this.state.totalval}
  variant="standard"> </FormikTextField></fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
{/* onClicl={this.onClickData(values)} */}
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>:this.state.selectedpmtoption===3?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
  <ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"Insurance Payments"}
      data={this.state.selectedpmtoption===3?this.state.pendingInsurance:[]}
      columns={pendingOthers}
      options={saloptions}
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
  
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>

  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  value={this.state.totalval}
  variant="standard"> </FormikTextField>
  </fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm" type="submit"> 
{/* onCliclk={this.onClickData(values)}  */}
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>:this.state.selectedpmtoption===4?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
  <ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"PT Payments"}
      data={this.state.selectedpmtoption===4?this.state.pendingPt:[]}
      columns={pendingOthers}
      options={saloptions}
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  value={this.state.totalval}
  variant="standard"> </FormikTextField></fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>: this.state.selectedpmtoption === 5?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
  <ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"IT Payments"}
      data={this.state.selectedpmtoption===5?this.state.pendingIt:[]}
      columns={pendingOthers}
      options={saloptions}
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  value={this.state.totalval}
  variant="standard"> </FormikTextField></fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>
:""}
</>:""}


                    
</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     
 </div>
 </>
)
  }
}

export default withRouter(AccountsCompleted);              



 
