import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, ccountry, gstin } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import WarehouseServices from './WarehouseServices.js';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import MasterService from '../MasterService.js';


class NewWarehouse extends Component {
  constructor(props) {
    super(props)
    console.log(this.props)
    this.state = {
      pagename:this.props.location.state.pagename,
      id:this.props===undefined||this.props.location.state===undefined||this.props.location.state.id===undefined?'':this.props.location.state.id,
      name: '',
      address:'',
      loader:false,
      gstin:gstin,
      CountryData: [],
      StateData: [],
      CityData: [],
      country:null,
      state:null,
      city:null,
      zip:""
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
     this.setState({loader:true})
    if(this.state.pagename==='New Org Unit'){
      let addwarehouse = { 
        name:values.name,
        address:values.address,

        tenantid: currentTenant
      }
    WarehouseServices.addwarehouse(addwarehouse).then(() => 
    this.props.navigate('/warehouse'))
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }else{
    let addwarehouse = { 
      id:values.id,
      name:values.name,
      address:values.address,
      tenantid: currentTenant
    }
  WarehouseServices.EditWarehouse(addwarehouse).then(() => 
    this.props.navigate('/warehouse'))
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.setState({gstin:gstin})

    if(this.props.location.state.id!==-1){
      WarehouseServices.getWarehouse(this.props.location.state.id).then(res=>{
        this.setState({name:res.data.name,address:res.data.address})
      })
    }
    MasterService.getCountries().then((response) =>
      this.setState({
        CountryData: response.data,
      }))
 }

 ChangeteCountry = (value, values) => {
  if(values!=='' && typeof values === 'object' && values!==null){
  this.setState({country:values.name, CountryId: values.id,phonecode:values.phonecode,state :'', StateId: '',city:'',CityId:'',CityData:[] });
  MasterService.getStates(values.id).then((response) => {
    this.setState({
      StateData: response.data,
    });
  });
}else{
  this.setState({
    country:'', CountryId:'', state :'',StateData:[],CityData:[], StateId: '',city:'',CityId:''
  })
}
};
ChangeState = (value, values) => {
  if(values!=='' && typeof values === 'object' && values !== null){
  this.setState({state:values.name, StateId: values.id,statecode:values.code });
  MasterService.getCities(values.id).then((response) => {
    this.setState({
      CityData: response.data,city:'',CityId:''
    });
  });
 }else{
   this.setState({
  state:'', StateId: '',city:'',CityId:''
   })
 }
};

handleCity=(value,values)=>{
  if(values!=='' && typeof values === 'object' && values !== null){
  this.setState({city: values.name,CityId: values.id})
}else{
  this.setState({city:'',CityId:''})
}
}


  validate(values) {

    let errors = {}

     if (!values.name|| values.name==='') {
     errors.name = "Org Unit Name is Required";
      }
    if (!values.address || values.address === '') {
      errors.address = 'Org Unit Address is Required';
    }
    return errors;
  }

  render() {
     let {id,name,address} = this.state;
 
    return (
      
      <div>
      <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            
            
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/warehouse"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/warehouse">Org Unit</Link>
              </li>
              <li className="breadcrumb-item active"> {this.state.pagename} </li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>

            <div className="card-body">
            
              <Formik
                initialValues={{id,name,address}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id"/>
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                      <FormikTextField className="form-control"  type="text" name="name" label="Org Unit Name" onChange={(e)=>this.setState({name:e.target.value})}/>
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                      <FormikTextField className="form-control"  type="text" name="address" label="Org Unit Address" onChange={(e)=>this.setState({address:e.target.value})}/>
                      </fieldset>

                      {/* <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address2"
                          label="Area/Place"
                          value={this.state.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                        />
                      </fieldset> */}

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={this.state.CountryData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={this.state.country?this.state.CountryData.find(e=>e.name === this.state.country):null}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="state"
                          options={this.state.StateData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={this.state.state?this.state.StateData.find(e=>e.name === this.state.state):null}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={this.state.CityData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={this.state.city?this.state.CityData.find(e=>e.name === this.state.city):null}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={this.state.country==="India"?"Zip/Pincode":"PO BOX No."}
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>
                      {ccountry==="India" && 
                      <fieldset className="form-group col-md-4">
                      <FormikTextField className="form-control"  type="text" name="gstin" label="GSTIN" value={this.state.gstin} onChange={(e)=>this.setState({gstin:e.target.value})}/>
                      </fieldset>
                        }
                    </div>
                    <div className="form-group col-md-12"/>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1" to={{pathname: '/warehouse'}} ><b style={{color:"#FA5733"}}> X</b> Cancel </Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewWarehouse)