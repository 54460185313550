import React, { useState } from "react";
import Footer from "../../Component/Footer";
import { Form, Formik } from "formik";
import { FormikTextField } from "../../CommonComponents/FormField";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { currentTenant, userid } from "../../Common/Credentials";
import ProjectManagementServices from "../ProjectManagementServices";
import { Spinner } from "react-bootstrap";

const AddLabour = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [contractor, setContractor] = useState("");
  const [dailyWage, setDailyWage] = useState("");
  const [loader,setLoader] = useState(false)

  const onSubmit = () => {
    let data = {
      type: type,
      contractor: contractor,
      dailyWage: dailyWage,
      createdBy: userid,
      updatedBy: userid,
      tenantId: currentTenant,
    };
    setLoader(false)
    ProjectManagementServices.SaveLabourType(data).then(() =>{
      navigate("/Labours")
      // setLoader(false)
    }).catch((err)=>console.error("Error Adding Save Labour: ", err))
  };

  const validate = () => {
    let errors = {};
    if (type === "") {
      errors.type = "This field is required.";
    }
    return errors;
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link as="button" className=" text-secondary" to={-1}>
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/Labours">Labours</Link>
            </li>
            <li className="breadcrumb-item active">
              Add Labour
            </li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                  <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    validate={validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <div className="form-row">
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="type"
                              label="Type"
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                            />
                            </fieldset>

                            <fieldset className="form-group col-md-4">  
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="Contractor"
                              label="Contractor"
                              value={contractor}
                              onChange={(e) => setContractor(e.target.value)}
                            />
                          </fieldset>
                          
                          <fieldset className="form-group col-md-4">  
                          <FormikTextField
                              className="form-control"
                              type="text"
                              name="Daily Wages"
                              label="Daily Wages"
                              value={dailyWage}
                              onChange={(e) => setDailyWage(e.target.value)}
                            />
                            </fieldset>
                            
                          <fieldset className="form-group col-md-12 mt-3">
                          <button
                            type="submit"
                            className="btn btn-sm hovbuttonColour mr-2"
                            disabled={loader}
                          >
                            {loader ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="mr-1"
                              />
                            ) : (
                              ""
                            )}
                            Submit
                          </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={-1}
                            state={{ message: "" }}
                          >
                            <b className="text-danger"> X </b>Close
                          </Link>
                          </fieldset>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddLabour;