import React, { Component } from "react";
import UserService from "../users/UserService.js";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { currentTenant, noofusers, userid } from "../Common/Credentials.js";
import { appmasterid } from "../Common/Permissions";
import { spin } from "../Common/NewLoader.jsx";
import CustomToolbar from "../CommonComponents/CustomToolbar.js";
import MUIDataTable from "mui-datatables";
import moment from "moment";
// import AuthenticationService from '../Authentication/AuthenticationService.jsx';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import Swal from "sweetalert2";
import MasterService from "../Masters/MasterService.js";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class UsersDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      passwordchanged: "",
      loading: false,
      noofusers:noofusers,
    };
    this.refreshUsers = this.refreshUsers.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    // this.addNewUser=this.addNewUser.bind(this)
    // this.updateUserClicked=this.updateUserClicked.bind(this)
  }

  // addNewUser(){
  //    this.props.navigate('/newusers')

  // }

  // updateUserClicked(id){

  //   this.props.navigate({
  //     pathname: `/newusers`,
  //     state: {
  //       id: {id},
  //     }})

  // }

  deleteUser(id) {
    // let username = AuthenticationService.getLoggedInUser()
    Swal.fire({
      title: "Delete User?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
    UserService.deleteUser(id)
      .then((resposne) => {
        this.setState({ message: `Delete of ${id} Successfull` });
        Swal.fire({
          title: "User Deleted Successfully!",
          text: ".",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then(() => this.refreshUsers());
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    }})
  }

  ActiveorInactive(id){
    let userdetails = this.state.users.find(e=>e.id === id)
    Swal.fire({
      title: `Make user as ${userdetails.active==="INACTIVE"?"Active":"Inactive"}?`,
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        UserService.ActiveInActiveUser(id)
          .then((response) => {
            Swal.fire({
              title: `User ${userdetails.active==="INACTIVE"?"Activated":"Inactivated"} Successfully!`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.refreshUsers());
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
  }
})
}

  Resetpassword(id){
    // rnnMFDJ2L6XZWxa6Ptre
    Swal.fire({
      title: "Reset Password",
      text: "Do you want to reset password?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
  }).then((result)=>{
      if (result.value) {
        UserService.Resetpassword(id).then
        ((response) =>{
          this.setState({ message: `Password of UserId ${id} has been reset Successfully` });
          Swal.fire({
            title: "Password Reset Successfull",
            // text: "All session Cleared Successfully.",
            icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          // cancelButtonText: "No",
          }).then(()=>{
            this.refreshUsers();
          })
        })
      }
  })
  }

  refreshUsers() {
    // let username = AuthenticationService.getLoggedInUser()

    UserService.retriveAllUsers()
      .then((response) => {
        this.setState({ users: response.data });
        // const script = document.createElement("script");
        // script.src= '../../js/content.js';
        // script.async= true;
        // document.body.appendChild(script);

        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    //  MasterService.GetNoofUsersLinit(currentTenant).then((res) =>
    //     this.setState({noofusers:res.data || noofusers})
    //   )

  }

  componentDidMount() {
    if (userid === undefined) {
      alert("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshUsers();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: true,
          sort: true,
          // sortOrder: "asc",
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "username",
        label: "User Name",
        options: { filter: false, sort: true },
      },
      {
        name: "first",
        label: "First Name",
        options: { filter: false, sort: true },
      },
      {
        name: "last",
        label: "Last Name",
        options: { filter: false, sort: true },
      },
      { name: "email", label: "Email", options: { filter: false, sort: true } },
      {
        name: "roles",
        label: "Role",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return this.state.users
              .find((e) => e.id === tableMeta.rowData.find((e) => e))
              .roles.map((role, index) => (
                <div key={index}>{role.name.toLocaleString()}</div>
              ));
          },
        },
      },
      {
        name: "active",
        label: "Status",
        options: { filter: true, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowData.find((e) => e) !== userid ? <span
            as="button"
            data-tip
            data-for="delete"
            className={`btn btn-sm ${value === "ACTIVE"?"btn-success":"btn-warning"} `}
            onClick={() => this.ActiveorInactive(tableMeta.rowData.find((e) => e))}
          > {value}</span> :'NA'
          },
        },
      },
    
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: false,
          sortOrder: "asc",
          display: false,
          viewColumns: false,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY");
          },
        },
      },
      // {
      //   name: "Edit",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     display: appmasterid === "2" || appmasterid === "3" ? true : false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return tableMeta.rowData.find((e) => e) !== userid ? (
      //         <Link
      //           as="button"
      //           data-tip
      //           data-for="edit"
      //           className="btn btn-sm buttonColour"
      //           to={{
      //             pathname: "/newusers",
      //             state: {
      //               id: tableMeta.rowData.find((e) => e),
      //               name: "Update User",
      //             },
      //           }}
      //         ><i className="fas fa-pen"/> Edit</Link>
      //       ) : (
      //         "NA"
      //       );
      //     },
      //   },
      // },
      {
        name: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowData.find((e) => e) !== userid ? (<>
             <Link
                as="button"
                data-tip
                data-for="edit"
                className="btn btn-sm hovbuttonColour mr-1"
                to={{
                  pathname: "/newusers"}}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    name: "Update User",
                }}
              ><i className="fas fa-pen"/> Edit</Link>
             
              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton mr-1"
                onClick={()=> this.deleteUser(tableMeta.rowData.find((e) => e))}
              ><i className="fa fa-trash"/> Delete</span>
               <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm btn-warning"
                onClick={() => this.Resetpassword(tableMeta.rowData.find((e) => e))}
              ><i className="fas fa-key"></i> Reset</span>


           </> ) : (
              "NA"
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
          {this.state.noofusers === null || this.state.noofusers === 0 || this.state.users.length<this.state.noofusers?
            appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newusers"}}
                  state={{ id: -1, name: "New User" }}              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )
            :''}
          </>
        );
      },
      // setRowProps: row => {
      //   // if (row[7] === moment().format('DD-MM-YYYY')){
      //   //   return {
      //   //     style: { background: "#ecf8fb" }
      //   //   };
      //   // }
      //   if (row[5] === "ACTIVE") {
      //     return {
      //       style: { background:  "#ecf8fb" }
      //     };
      //   }else{
      //     return {
      //       style: { background: "#f7dddd" }
      //     };
      //   }
      // }
    };

    return (
      <div>
        {/* <div className="content-wrapper"> */}
        {/* Content Header (Page header) */}

        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link className="textColour " to="/masters">
                Home
              </Link>
            </li>

            <li className="breadcrumb-item active">Users</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.card-header */}

                  <div className="card-body">
                    <div>
                      {this.state.loading === true ? <div className="text-center">{spin}</div> :

                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"Users"}
                        data={this.state.users}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  }
                    </div>
                    {noofusers === null || noofusers === 0 || this.state.users.length<noofusers?'':
                    <fieldset className="form-group col-md-12">
                          <label className="text-red"> Note: Users limit reached
                            </label>
                        </fieldset>
                      }
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
}
export default withRouter(UsersDataTable);
