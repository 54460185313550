import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SalesServices from "../SalesServices";
import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  currency,
  currentTenant,
  czip,
  gstin,
  logo,
  pan,
  prefix1,
  taxtype,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
// import pdf from "../../pdf/pdf";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { ToWords } from "to-words";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NumericFormat } from "react-number-format";

import { Modal } from "react-bootstrap";
import UploadFilesService from "../../Common/upload/UploadFilesService";
import FileServices from "../../Common/upload/FileServices";
// import PurchaseService from "../../Purchase/PurchseServices"
import SettingsServices from "../../Settings/SettingsServices";
import BankServices from "../../Masters/Bank/BankServices";
import Swal from "sweetalert2";
import QRCode from "qrcode";
import ewbbutton from "../../images/Eway final.png";
import einvbutton from "../../images/E-invoice.png";
import { Form, Formik } from "formik";
import MasterService from "../../Masters/MasterService.js";
import TransporterServices from "../../Masters/Transporter/TransporterServices.js";
import { salesid } from "../../Common/Permissions.js";
import { screens } from "../../Common/Products.js";
import pdficon from "../../images/Icons/pdf.png"
import mailIcon from "../../images/Icons/email1.png"
import whatsappIcon from "../../images/Icons/whatsapp.png"
import smsIcon from "../../images/Icons/chat1.png"
import receiptIcon from "../../images/Icons/bill.png"

let curmonth, year, day, path;
let doctype = "invoice";

class ViewInvoice extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;

    this.state = {
      pagename:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.pagename,
      type:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.type,
        temp:this.props.location.state === undefined
        ? false
        : this.props.location.state.temp,
      Invoicedata: "",
      saleorderitems: [],
      invoiceservicelist: [],
      milestones: [],
      id: id,
      stat: 0,
      loading: false,
      loader: false,
      show: false,
      templete: "",

      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false, //<---- here
      bankdetails: {},
      additionalchareslist: [],
      einvoicedata: "",
      remarks: "",
      showrem: false,
      showfields: false,
      errormsg: "",
      err: false,
      msg: "",
      transportername: null,
      deliverymethods: [
        { value: "1", name: "Own Vehicle" },
        { value: "2", name: "Hired Vehicle" },
        { value: "3", name: "GTA" },
        { value: "4", name: "Others" },
      ],
      transmode: 1,
      vehicletypes: [
        { value: "O", name: "ODC" },
        { value: "R", name: "Regular" },
      ],
      vehicletype: "R",
      vehiclenum: null,
      distance: 0,
      transporterid: null,
      QRcode: "",
      ewaybillQRcode: "",
      ewaybilldata: "",
      supplytype: "I",
      transportationmode: "1",
      transactiontype: 1,

      fromPlace: "",
      fromState: "",
      fromCountry: "",
      fromStatecode: "",
      remainingDistance: "",
      transDocNo: "",
      transDocDate: "",
      extnRsnCode: "",
      extnRemarks: "",
      fromPincode: "",
      consignmentStatus: "",
      transitType: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",

      CountryData: [],
      StatesData: [],
      CitiesData: [],
      showtrans: false,
      showupdtveh: false,
      ResforVehChange: [
        { value: "1", name: "Due to Break Down" },
        { value: "2", name: "Due to Transshipment" },
        { value: "3", name: "Others (Pls. Specify)" },
        { value: "4", name: "First Time" },
      ],
      ResforCancellation: [
        { value: "1", name: "Duplicate" },
        { value: "2", name: "Order Cancelled" },
        { value: "3", name: "Data Entry mistake" },
        { value: "4", name: "Others" },
      ],
      ResforEWBExt: [
        { value: 1, name: "Natural Calamity" },
        { value: 2, name: "Law and Order Situation" },
        { value: 3, name: "Transshipment" },
        { value: 4, name: "Accident" },
        { value: 99, name: "Others" },
      ],
      reasonCodeforcnl: "1",
      reasonCodeforvehchg: "1",
      reasonCodeforewbext: 1,
      transporterslist: [],
      transporterselectiontype: false,
      validtrnsid: false,
      imgwidth: 0,
      imgheight: 0,
      mailbehavior: "NO",
      creditnoteslist: [],
      showCancelModal: false,
      transidreq: false,
      licenses: [],
      ispaymentpending:false,
      edebitnoteslist:[],
      opendropdown:""

    };
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
  }

  TransporteridChange = (val) => {
    this.setState({
      transporterid: val,
    });
    if (val.length === 15) {
      let data = {
        tenantId: currentTenant,
        reqparam: val,
      };
      SalesServices.validateGSTIN(data).then((response) => {
        if (
          response.data.body === null ||
          response.data.body.statusCodeValue === 400
        ) {
          this.setState({ err: true, validtrnsid: false });
          Swal.fire({
            title: `Invalid Transporter ID`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
        } else {
          this.setState({ validtrnsid: true });
        }
      });
    } else {
      this.setState({
        err: false,
        validtrnsid: false,
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true, partyid: this.state.Invoicedata.customer });
  };
  handleClose = () => {
    this.setState({
      show: false,
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: this.state.sellerid,
      error: false,
    });
  };

  handleDownload = (Invoicedata, type) => {
    let pagename = this.state.pagename;

    let logowidth = 0;
    logowidth =
      ((this.state.imgwidth - this.state.imgheight) / this.state.imgwidth) *
      100;
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let data = []
    let servdata = [];
    let additionalchares = [];
    let hsn = [];
    let supplytype= Invoicedata.gsttype.substring(0,1).toUpperCase() + Invoicedata.gsttype.substring(1,Invoicedata.gsttype.length).toLowerCase();
    
    let addchargeslist =
      Invoicedata.additionalchares === null ? [] : Invoicedata.additionalchares;
    

    if ( Invoicedata.invoiceitemlist !== null && Invoicedata.invoiceitemlist.length > 0) {
      Invoicedata.invoiceitemlist.map(e=>
          data.push({
            name:e.name,
            hsn:e.hsn,
            price:e.price.toFixed(2),
            quantity:e.quantity,
            uom:e.uom,
            discountamount:e.discountamount.toFixed(2),
            totalprice:e.totalprice.toFixed(2),
            taxamount:e.taxamount,
            tax:e.tax
          })
        )
    if(Invoicedata.invoiceservicelist !== null && Invoicedata.invoiceservicelist.length>0){
      Invoicedata.invoiceservicelist.map(e=>
        data.push({
          name:e.service,
          hsn:e.servicecode,
          price:e.servicecost.toFixed(2),
          quantity:1,
          uom:"OTH",
          discountamount:(0).toFixed(2),
          totalprice:e.servicecost.toFixed(2),
          taxamount:e.servicetaxamt,
          tax:e.servicetax
        })
      )
    }

      // data = Invoicedata.invoiceitemlist;
      // data = data.map((v) => ({ ...v, cgstamt: (v.taxamount / 2).toFixed(2) }));
      // data = data.map((v) => ({ ...v, cgst: (v.tax / 2).toFixed(2) }));
      // data = data.map((v) => ({ ...v, sgstamt: (v.taxamount / 2).toFixed(2) }));
      // data = data.map((v) => ({ ...v, sgst: (v.tax / 2).toFixed(2) }));
      // data.map((e) => (e.tax = e.tax.toFixed(2)));
      // data.map((e) => (e.price = e.price.toFixed(2)));
      // data.map((e) => (e.taxamount = e.taxamount.toFixed(2)));
      // data.map((e) => (e.totalpricewtax = e.totalpricewtax?.toFixed(2)));
      // data.map((e) => (e.discountamount = e.discountamount?.toFixed(2)));
      // data.map((e) => (e.insuranceamount = e?.insuranceamount?.toFixed(2)));
      // data.map((e) => (e.totalprice = e?.totalprice?.toFixed(2)));

      if (pagename === "Tax Invoice Details") {
        additionalchares = Invoicedata.additionalchares;
        if(Invoicedata.additionalchares !== null && Invoicedata.additionalchares !== undefined && Invoicedata.additionalchares !== '' && Invoicedata.additionalchares.length !==0){
        Invoicedata.additionalchares.map(e=>
          data.push({
            name:e.natureofcharge,
            hsn:e.hsn,
            price:e.additionalcharges.toFixed(2),
            quantity:'',
            uom:"",
            discountamount:(0).toFixed(2),
            totalprice:e.additionalcharges?.toFixed(2),
            taxamount:e.additionalchargestaxamount,
            tax:e.additionalchargestax
          })
          )
        }
        
          if (Invoicedata.insurancereq) {
            data.push({
              name:"Insurance",
              hsn:9917,
              price:Invoicedata.insuranceamount.toFixed(2),
              quantity:'',
              uom:"",
              discountamount:(0).toFixed(2),
              totalprice:Invoicedata.insuranceamount.toFixed(2),
              taxamount: Invoicedata.totalinsurance - Invoicedata.insuranceamount,
              tax:Invoicedata.insurancetax
            })
          }

        additionalchares.map(
          (e) => (e.additionalcharges = e?.additionalcharges?.toFixed(2))
        );
        additionalchares.map(
          (e) =>
            (e.additionalchargestaxamount =
              e?.additionalchargestaxamount?.toFixed(2))
        );
        additionalchares.map(
          (e) =>
            (e.totaladditionalcharges = e?.totaladditionalcharges?.toFixed(2))
        );
      }
      if (Invoicedata.currency !== currency) {
        data.find((e) => e).price = (
          data.find((e) => e).price * Invoicedata.curconversionrate
        ).toFixed(2);
        data.find((e) => e).taxamount = (
          data.find((e) => e).taxamount * Invoicedata.curconversionrate
        ).toFixed(2);
        data.find((e) => e).cgstamt = (
          data.find((e) => e).cgstamt * Invoicedata.curconversionrate
        ).toFixed(2);
        data.find((e) => e).sgstamt = (
          data.find((e) => e).sgstamt * Invoicedata.curconversionrate
        ).toFixed(2);
        data.find((e) => e).totalpricewtax = (
          data.find((e) => e).totalpricewtax * Invoicedata.curconversionrate
        ).toFixed(2);
        data.find((e) => e).discountamount = (
          data.find((e) => e).discountamount * Invoicedata.curconversionrate
        ).toFixed(2);
        data.find((e) => e).insuranceamount = (
          data.find((e) => e).insuranceamount * Invoicedata.curconversionrate
        )?.toFixed(2);
      }

      hsn = data.map((e) => e.hsn);
    }

    if (
      Invoicedata.sotype === "Services" ||
      Invoicedata.sotype === "ProductsServices"
    ) {
      
      servdata = Invoicedata.invoiceservicelist;
      servdata = servdata.map((v) => ({
        ...v,
        cgst: (v.servicetax / 2).toFixed(2),
      }));
      servdata = servdata.map((v) => ({
        ...v,
        sgst: (v.servicetax / 2).toFixed(2),
      }));
      servdata = servdata.map((v) => ({
        ...v,
        servicetaxamt: (v.servicetotalwtax - v.servicecost).toFixed(2),
      }));
      servdata = servdata.map((v) => ({
        ...v,
        cgstamt: ((v.servicetotalwtax - v.servicecost) / 2).toFixed(2),
      }));
      servdata = servdata.map((v) => ({
        ...v,
        sgstamt: ((v.servicetotalwtax - v.servicecost) / 2).toFixed(2),
      }));
      servdata.map((e) => (e.servicecost = e.servicecost.toFixed(2)));
      servdata.map((e) => (e.servicetotalwtax = e.servicetotalwtax.toFixed(2)));
      if (Invoicedata.currency !== currency) {
        servdata.find((e) => e).servicecost = (
          servdata.find((e) => e).servicecost * Invoicedata.curconversionrate
        ).toFixed(2);
        servdata.find((e) => e).servicetotalwtax = (
          servdata.find((e) => e).servicetotalwtax *
          Invoicedata.curconversionrate
        ).toFixed(2);
      }
      if (servdata.length !== 0) {
        hsn = hsn.concat(servdata.map((e) => e.servicecode));
      }
    }

    let tablecolp, tablecols, tablecolm;
    // Total Invoice Amount
    let totalinvoice = (
      Invoicedata.totalinvoice +
      (Invoicedata.insuranceamount === null || Invoicedata.insuranceamount === undefined ||
      Invoicedata.insuranceamount === ""? 0 : Invoicedata.insuranceamount)+
    (Invoicedata.tcsreq? Invoicedata.tcsamount:0) +
    ( pagename === "Tax Invoice Details" && Invoicedata.additionalchares !== undefined &&
    Invoicedata.additionalchares !== null && Invoicedata.additionalchares.length>0?
    Invoicedata.additionalchares.reduce((a,b)=>a+parseFloat(b.additionalcharges),0):0)
    ).toFixed(2)

    // Total invoice tax amount
    let taxamount = (
      Invoicedata.taxamount +
      (Invoicedata.insuranceamount === null || Invoicedata.insuranceamount === undefined ||
      Invoicedata.insuranceamount === "" ? 0 : Invoicedata.totalinsurance - Invoicedata.insuranceamount) +
      ( pagename === "Tax Invoice Details" && Invoicedata.additionalchares !== undefined &&
      Invoicedata.additionalchares !== null && Invoicedata.additionalchares.length>0?
      Invoicedata.additionalchares.reduce((a,b)=>a+parseFloat(b.additionalchargestaxamount),0):0)
      ).toFixed(2);

    //Total invoice amount with tax
    let totalinvoicewtax = Invoicedata.totalinvoicewtax.toFixed(2);
    let advance =
      Invoicedata.advanceamountreq === null ||
      Invoicedata.advanceamountreq === undefined
        ? 0.0
        : Invoicedata.advanceamountreq.toFixed(2);
    let totaladditionalchares = 0;
    let insuranceamount = 0;
    let totaladdincinsu = 0;
    let tcsamount = 0;
    let totalexctcs = 0;
    if (pagename === "Tax Invoice Details") {
      totaladditionalchares =
        Invoicedata.totaladditionalchares !== undefined &&
        Invoicedata.totaladditionalchares !== null
          ? Invoicedata.totaladditionalchares.toFixed(2)
          : (0).toFixed(2);
      insuranceamount = Invoicedata.insurancereq
        ? Invoicedata.totalinsurance.toFixed(2)
        : 0;
      totaladdincinsu = (
        (Invoicedata.totaladditionalchares !== undefined &&
        Invoicedata.totaladditionalchares !== null
          ? Invoicedata.totaladditionalchares
          : 0) + (Invoicedata.insurancereq ? Invoicedata.totalinsurance : 0)
      ).toFixed(2);
    }

    if (Invoicedata.tcsreq) {
      tcsamount = Invoicedata.tcsamount.toFixed(2);
      totalexctcs = parseFloat(totalinvoicewtax).toFixed(2);
      totalinvoicewtax = (
        parseFloat(totalinvoicewtax) + Invoicedata.tcsamount
      ).toFixed(2);
    }

    if (Invoicedata.currency !== currency) {
      totalinvoice = (totalinvoice * Invoicedata.curconversionrate).toFixed(2);
      taxamount = (taxamount * Invoicedata.curconversionrate).toFixed(2);
      totalinvoicewtax = (
        totalinvoicewtax * Invoicedata.curconversionrate
      ).toFixed(2);
      advance = (advance * Invoicedata.curconversionrate).toFixed(2);
      totaladditionalchares = (
        totaladditionalchares * Invoicedata.curconversionrate
      ).toFixed(2);
      insuranceamount = (
        insuranceamount * Invoicedata.curconversionrate
      ).toFixed(2);
      // totaladdincinsu = (
      //   totaladditionalchares * Invoicedata.curconversionrate +
      //   insuranceamount * Invoicedata.curconversionrate
      // ).toFixed(2);
      if (Invoicedata.tcsreq) {
        tcsamount = (
          totaladditionalchares * Invoicedata.curconversionrate
        ).toFixed(2);
      }
    }

          tablecolp = [
            { header: "Name of Product / Service", dataKey: "name" },
            { header: "HSN/SCA", dataKey: "hsn" },
            { header: "Quantity", dataKey: "quantity" },
            {
              header:"Rate",
              dataKey: "price",
            },
            { header: "UOM", dataKey: "uom" },
            { header: "Discount", dataKey: "discountamount" },
            {
              header: `Amount`,
              dataKey: "totalprice",
            },
          ];
          // tablecols = [
          //   { header: "Name of Product / Service", dataKey: "service" },
          //   { header: "HSN/SAC", dataKey: "servicecode" },
          //   { header: "Service Cost", dataKey: "servicecost" },
          //   { header: "CGST (%)", dataKey: "cgst" },
          //   {
          //     header: `CGST Amount (${Invoicedata.currency})`,
          //     dataKey: "cgstamt",
          //   },
          //   { header: "SGST (%)", dataKey: "sgst" },
          //   {
          //     header: `SGST Amount (${Invoicedata.currency})`,
          //     dataKey: "sgstamt",
          //   },
          //   {
          //     header: `Total (${Invoicedata.currency})`,
          //     dataKey: "servicetotalwtax",
          //   },
          // ];

          // tablecolm = [
          //   { header: "Nature of charge", dataKey: "natureofcharge" },
          //   { header: "Amount", dataKey: "additionalcharges" },
          //   { header: "CGST (%)", dataKey: "cgst" },
          //   {
          //     header: `CGST Amount (${Invoicedata.currency})`,
          //     dataKey: "cgstamt",
          //   },
          //   { header: "SGST (%)", dataKey: "sgst" },
          //   {
          //     header: `SGST Amount (${Invoicedata.currency})`,
          //     dataKey: "sgstamt",
          //   },
          //   { header: "Total", dataKey: "totaladditionalcharges" },
          // ];

    // if (
    //   addchargeslist !== null &&
    //   addchargeslist !== undefined &&
    //   addchargeslist !== "" &&
    //   addchargeslist.length > 0
    // ) {
    //   addchargeslist = addchargeslist.map((v) => ({
    //     ...v,
    //     cgstamt: (parseFloat(v.additionalchargestaxamount) / 2).toFixed(2),
    //   }));
    //   addchargeslist = addchargeslist.map((v) => ({
    //     ...v,
    //     cgst: (parseFloat(v.additionalchargestax) / 2).toFixed(2),
    //   }));
    //   addchargeslist = addchargeslist.map((v) => ({
    //     ...v,
    //     sgstamt: (parseFloat(v.additionalchargestaxamount) / 2).toFixed(2),
    //   }));
    //   addchargeslist = addchargeslist.map((v) => ({
    //     ...v,
    //     sgst: (parseFloat(v.additionalchargestax) / 2).toFixed(2),
    //   }));
    // }

    let order =
      pagename === "Tax Invoice Details" ? "TAX INVOICE" : "PROFORMA INVOICE";
    let invdate = moment(Invoicedata.createdAt).format("DD-MM-yyyy");
    let ackDt = moment(this.state.einvoicedata.ackDt).format(
      "DD-MM-yyyy HH:mm"
    );

    // let milestones =
    //   Invoicedata.invoiceservicelist.length === 0
    //     ? []
    //     : Invoicedata.invoiceservicelist.find((e) => e).invoicemilestones ===
    //       null
    //     ? []
    //     : Invoicedata.invoiceservicelist.map((e) => e.invoicemilestones);
    // let declaration = "Declaration: We declare that the invoice shows the acctual price of the goods/services described and the particulers are true and correct."
    console.log(data)
    let temphsnlist = data.reduce((
      hsns,
      {
        hsn,
        name,
        totalprice,
        taxamount,
        tax,
        tota
      }
    ) => {
      if (!hsns[hsn]) hsns[hsn] = [];
      hsns[hsn].push({
        name: name,
        hsn: hsn,
        totalprice:totalprice,
        taxamount:taxamount,
        tax:tax
      });
      return hsns;
    },
    {}
  )

  let hsnlist = []

  Object.entries(temphsnlist).forEach(([key, value]) => {
    let a = {
        name: value.map((e) => e.name)[0],
        hsn: key,
        totalprice:value.reduce((a, v) => (a = a + parseFloat(v.totalprice)), 0).toFixed(2),
        taxamount:value.reduce((a, v) => (a = a + parseFloat(v.taxamount)), 0),
        tax:value.map((e) => e.tax)[0],
        cgst:value.reduce((a, v) => (a = a + parseFloat(v.taxamount)), 0)/2,
        cgstrate:value.map((e) => e.tax)[0]/2,
        sgst:value.reduce((a, v) => (a = a + parseFloat(v.taxamount)), 0)/2,
        sgstrate:value.map((e) => e.tax)[0]/2,
        totalwtax:parseFloat(value.reduce((a, v) => (a = a + parseFloat(v.totalprice)), 0).toFixed(2))+ value.reduce((a, v) => (a = a + parseFloat(v.taxamount)), 0)
    }
    hsnlist.push(a)
  })
  console.log(hsnlist)

  let hsncolumns = [
    { header: "HSN/SAC", dataKey: "hsn" },
    { header: "Taxable Amount", dataKey: "totalprice" },
    { header: Invoicedata.taxtype === "GST" && ccountry === "India" && Invoicedata.gsttype === "INTRASTATE"?"CGST":Invoicedata.taxtype === "GST" && ccountry === "India"? "IGST": Invoicedata.taxtype === "GST"? "GST": "VAT", dataKey: Invoicedata.taxtype === "GST" && ccountry === "India" && Invoicedata.gsttype === "INTRASTATE"?"cgst":"taxamount" },
    { header: "SGST", dataKey: "sgst", },
    { header: "IGST", dataKey: "igst", },
    { heder:"Total",dataKey:"totalwtax"}
  ]


    const toWords = new ToWords();
    var totalinwords = toWords.convert(totalinvoicewtax);
    var advanceinwords = toWords.convert(advance);
    const doc = new jsPDF("p", "pt", [842, 595], true);

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    let y = 0;
    y = 10 * this.state.licenses.length;
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");

    // doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    // doc.line(30, yax + 5, 580, yax + 5);
    doc.setFontSize(10);
    doc.text(`${order}`, 250, 40);
    doc.setFontSize(7);
    if (order === "TAX INVOICE") {
    doc.setFontSize(7);
    doc.text(`(ORIGINAL FOR RECIPIENT)`, 480,40);
    }

    doc.setDrawColor(192, 192, 192);
    doc.setFontSize(7);
    doc.text(`${curdate}`, 40, 40);
    doc.addImage(
      logo,
      "GIF",
      pageWidth - 100 - logowidth,
      50,
      80 + logowidth,
      80
    );
    doc.setFontSize(10);
    doc.text(`${clientname.toUpperCase()}`, 40, 62);
    doc.setFontSize(7);
    doc.text(`${address}`, 40, 75);
    doc.text(`${address2}`, 40, 85);
    doc.text(`${ccity}, ${cstate},${ccountry} - ${czip}`, 40, 95);
    doc.text(`${companyemail}, ${contactnumber}`, 40, 105);
    if(gstin!==null && gstin!== undefined && ccountry === "India"){
      doc.text(`GSTIN : ${gstin}`, 40, 115);
      }
      if(pan!==null && pan!== undefined && ccountry === "India") {
      doc.text(`PAN : ${pan}`, 40, 125);
      }
    let yax = 125;
    if (
      this.state.licenses !== null &&
      this.state.licenses !== undefined &&
      this.state.licenses !== ""
    ) {
      this.state.licenses.map((e, idx) => {
        yax = yax + 10;
        doc.text(`${e.name} - ${e.number}`, 40, yax);
      });
    }

    doc.setFontSize(7);
    doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    doc.line(30, yax + 10, 580, yax + 10);
    y = yax + 20;
    console.log(yax)
    if (
      Invoicedata.einvoicestatus === "Generated" &&
      this.state.einvoicedata.irn !== undefined &&
      pagename === "Tax Invoice Details"
    ) {
      doc.addImage(this.state.QRcode, "GIF", 495, y-5, 75, 75);
      let irnno = doc.splitTextToSize(this.state.einvoicedata.irn, 300);
      doc.text("IRN", 40, y);
      doc.text(`:`, 120, y);
      doc.text(`${irnno}`, 130, y);
      doc.text(`Acknowledgement No.`, 40, y + 10);
      doc.text(`:`, 120, y + 10);
      doc.text(`${this.state.einvoicedata.ackno}`, 130, y + 10);
      doc.text(`Acknowledgement Date`, 40, y + 20);
      doc.text(`:`, 120, y + 20);
      doc.text(`${ackDt}`, 130, y + 20);
      // doc.text(`e-invoice`, 540, y);
      y = y + 80;
      doc.line(30, y, 580, y);
    }
  
    doc.text(`${Invoicedata.customer.toUpperCase()}`, 40, y + 10);
    doc.text(`${Invoicedata.custadress}`, 40, y + 20);
    doc.text(`${Invoicedata.custaddress2}`, 40, y + 30);
    doc.text(
      `${Invoicedata.cityname}, ${Invoicedata.state}, ${Invoicedata.country} - ${Invoicedata.zip}`,
      40,
      y + 40
    );
    doc.text(
      `${Invoicedata.custphonecode}-${Invoicedata.custphno}`,
      40,
      y + 50
    );
    if (Invoicedata.custgstin !== "" && Invoicedata.custgstin !== undefined) {
      doc.text(`GSTIN : ${Invoicedata.custgstin}`, 40, y + 60);
      doc.text(
        `PAN : ${Invoicedata.custgstin?.substring(2, 12)}`,
        180,
        y + 60
      );
    }
    doc.text(`Vehicle no:${Invoicedata.vehiclenum!==undefined && Invoicedata.vehiclenum !== null?Invoicedata.vehiclenum:""}`, 40,Invoicedata.custgstin !== "" && Invoicedata.custgstin !== undefined? y + 70:y+60);


    doc.line(320, y, 320, y + 80);
    doc.line(415, y, 415, y+80);
    doc.text(`INVOICE ID`, 330, y + 10);
    doc.text(`${Invoicedata.einvid}`, 430, y + 10);
    doc.line(320, y+15, 580, y+15);
    doc.text(`DETAILED INVOICE ID`, 330, y + 25);
    doc.text(
      `${
        pagename === "Tax Invoice Details"
          ? Invoicedata.custominvid
          : Invoicedata.invid
      }`, 430, y + 25);
    doc.line(320, y+30, 580, y+30);
    doc.text(`INVOICE DATE`, 330, y + 40);
    doc.text(`${invdate}`, 430, y + 40);
    doc.line(320, y+45, 580, y+45);
    doc.text(`ORDER ID`, 330, y + 55);
    doc.text(
      `${Invoicedata.customsoid === null?'':Invoicedata.customsoid}`,
      430,
      y + 55
    );
    doc.line(320, y+60, 580, y+60);
    if (Invoicedata.taxtype === "GST" && ccountry === "India") {
      doc.text(`SUPPLY TYPE`, 330, y + 70);
      doc.text(`${supplytype.toUpperCase()}`, 430, y + 70);
    }
    y = y + 75;
      doc.setDrawColor("#808080")
    doc.setLineWidth(0.1);
    doc.line(30, y, 580, y);

    //AutoTable

      autoTable(doc, {
        theme: "grid",
        startY: y,
        margin:{top: 0, bottom: 0,right:15,left:30},
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1 && data.section==="body") {
              data.cell.styles.minCellHeight = 70;
          }
      },
        styles: { textColor: [0, 0, 0], fontSize: "7", cellPadding: 2 },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
          halign: "center",
          lineWidth: 0.1,
          // lineColor: [128, 128, 128],
        },
        bodyStyles: {
          halign: "center",
          // lineColor: [128, 128, 128],
          lineWidth:0.1
        },
        columnStyles: {
          0:{cellWidth: 225},
          3: { halign: "right" },
          5: { halign: "right" },
          6: { halign: "right" },
        },
        body: data,
        columns: tablecolp,
      });
     
    if(Invoicedata.tcsreq){
    doc.text(`TCS On Sales @${Invoicedata.tcs}%`, 180, doc.autoTable.previous.finalY - 25);
    doc.text(`${(Invoicedata.tcsamount).toFixed(2)}`, 578, doc.autoTable.previous.finalY - 25, "right");
    }

    doc.setDrawColor(192, 192, 192);
    y = doc.autoTable.previous.finalY + 10;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }

    doc.text(`AMOUNT CHARGEABLE (in words) :`, 40, y);
    doc.text(`${totalinwords} Only`, 40, y+10);

    // doc.text(`ADVANCE : ${advance}`, 40, y+20);
    // doc.text(`${advanceinwords} Only`, 40, y+30);

    doc.text(`SUB TOTAL`, 490, y , "right");
    doc.text(`${totalinvoice}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    y = y + 15
    doc.line(430, y-25, 430, Invoicedata.taxtype === "GST" &&  ccountry === "India" &&
    Invoicedata.gsttype === "INTRASTATE"? y+35:y+20);

    doc.line(500, y-25, 500, Invoicedata.taxtype === "GST" && ccountry === "India" &&
    Invoicedata.gsttype === "INTRASTATE"? y+35:y+20);
    
    if (
      Invoicedata.taxtype === "GST" &&
      ccountry === "India" &&
      Invoicedata.gsttype === "INTRASTATE"
    ) {
      
    doc.text(`CGST`, 490, y , "right");
    doc.text(`${(taxamount / 2).toFixed(2)}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    doc.text(`SGST`, 490, y + 15, "right");
    doc.text(`${(taxamount / 2).toFixed(2)}`, 578, y + 15, "right");
    doc.line(430, y+20, 580, y+20);
    y = y+15
    }else if(Invoicedata.taxtype === "GST" && ccountry === "India"){
    doc.text(`IGST`, 490, y, "right");
    doc.text(`${taxamount}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    }else{

    doc.text(`${Invoicedata.taxtype} :`, 490, y, "right");
    doc.text(`${taxamount}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    }

    doc.text(`TOTAL`, 490, y + 15,"right");
    doc.text(`${totalinvoicewtax}`, 578, y + 15, "right");
    doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    doc.line(30, y + 20, 580, y + 20);

    y = y + 10;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }
    // doc.text(`Advance Amount : ${advance} (${advanceinwords})`, 40, y + 10);
    if (
      (pagename === "Tax Invoice Details" &&
        Invoicedata.openadjusted !== null &&
        Invoicedata.openadjusted !== undefined) ||
      (pagename === "Tax Invoice Details" &&
        Invoicedata.debitadjusted !== null &&
        Invoicedata.debitadjusted !== undefined)
    ) {
      y = y + 20;
      doc.setLineWidth(0.1);
      // doc.setDrawColor("#808080")
      doc.line(30, y, 580, y);
      doc.text(`Adjustments`, 40, y + 15);
      y = y + 25;
      doc.setLineWidth(0.1);
      // doc.setDrawColor("#808080")
      doc.line(30, y, 580, y);
      if (
        pagename === "Tax Invoice Details" &&
        Invoicedata.openadjusted !== null &&
        Invoicedata.openadjusted !== undefined
      ) {
        doc.text(
          `Opening balance adjusted : ${Invoicedata.openadjusted}`,
          40,
          y + 15
        );
      }

      if (
        pagename === "Tax Invoice Details" &&
        Invoicedata.debitadjusted !== null &&
        Invoicedata.debitadjusted !== undefined
      ) {
        y = y + 15;
        doc.text(`Debit Adjusted : ${Invoicedata.debitadjusted}`, 40, y + 15);
      }
    }
    y = y + 10;
    // doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    // doc.line(30, y, 580, y);

    

    doc.text("HSN / SAC", 100, y + 10);
    var splithsn = doc.splitTextToSize(hsn.join(", ").toString(), 245);
    doc.text(`${splithsn}`, 40, y + 25);
    doc.text("Total", 220, y + 40);
    doc.line(250, y, 250, y + 45);

    if (
      Invoicedata.taxtype === "GST" &&
      ccountry === "India" &&
      Invoicedata.gsttype === "INTRASTATE"
    ) {
      doc.text("Taxable Amount", 260, y + 10);
      doc.text(`${totalinvoice}`, 315, y + 25, "right");
      doc.text(`${totalinvoice}`, 315, y + 40, "right");
      doc.line(320, y, 320, y + 45);
      doc.text("CGST", 340, y + 10);
      // doc.text("%", 330, y + 40);
      // doc.text(`${((((taxamount/(totalinvoicewtax-taxamount-totaladditionalchares))*100)/2)).toFixed(2)}`, 330, y + 25);
      // doc.line(350, y + 20, 350, y + 45);
      // doc.text("Amount", 365, y + 40);
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0.00}`, 370, y + 25, "right");
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0.00}`, 370, y + 40, "right");
      doc.line(380, y, 380, y + 45);
      doc.text("SGST", 400, y + 10);
      // doc.text("%", 430, y + 40);
      // doc.text(`${((((taxamount/(totalinvoicewtax-taxamount-totaladditionalchares))*100)/2)).toFixed(2)}`, 430, y + 25);
      // doc.line(450, y + 20, 450, y + 45);
      // doc.text("Amount", 470, y + 40);
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0}`, 430, y + 25, "right");
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0}`, 430, y + 40, "right");
      doc.line(440, y, 440, y + 45);
      doc.text(`IGST`, 470, y + 10);
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?0.00:taxamount}`, 510, y + 25, "right");
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?0.00:taxamount}`, 510, y + 40, "right");
      // doc.line(460, y + 20, 460, y + 20);
      doc.text("Total Tax Amount", 525, y + 10);
      doc.text(`${taxamount}`, 578, y + 25, "right");
      doc.text(`${taxamount}`, 578, y + 40, "right");
      doc.line(520, y, 520, y + 45);
    } else {
      doc.text("Taxable Amount", 260, y + 10);
      doc.text(`${totalinvoice}`, 320, y + 25, "right");
      doc.text(`${totalinvoice}`, 320, y + 40, "right");
      doc.line(330, y, 330, y + 45);
      doc.text(
        Invoicedata.taxtype === "GST" && ccountry === "India"
          ? "IGST"
          : Invoicedata.taxtype === "GST"
          ? "GST"
          : "Vat",
        360,
        y + 10
      );
      // doc.line(500, y + 20, 350, y + 20);
      // // doc.text("%", 385, y + 40);
      // doc.text(`${((taxamount/(totalinvoicewtax-taxamount-totaladditionalchares))*100).toFixed(2)}`, 385, y + 25);
      // doc.line(425, y + 20, 425, y + 45);
      // doc.text("Amount", 442, y + 40);
      doc.text(`${taxamount}`, 390, y + 25, "right");
      doc.text(`${taxamount}`, 390, y + 40, "right");

      doc.line(400, y, 400, y + 45);
      doc.text(`Additional Charges`, 410, y + 10);
      doc.text(`${totaladdincinsu}`, 480, y + 25, "right");
      doc.text(`${totaladdincinsu}`, 480, y + 40, "right");

      doc.line(490, y, 490, y + 45);
      doc.text(`Total (${Invoicedata.currency})`, 520, y + 10);
      doc.text(`${totalinvoicewtax}`, 578, y + 25, "right");
      doc.text(`${totalinvoicewtax}`, 578, y + 40, "right");
    }

    doc.line(30, y + 15, 580, y + 15);
    doc.line(30, y + 30, 580, y + 30);
    doc.line(30, y + 45, 580, y + 45);










    y = y + 45;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }
    // doc.line(30, y, 580, y);
    doc.text("Bank Details", 150, y + 15);
    doc.line(30, y + 25, 350, y + 25);
    doc.text("Bank Name", 40, y + 35);
    doc.text(":", 100, y + 35);
    doc.text(
      `${
        this.state.bankdetails.bankname === undefined
          ? ""
          : this.state.bankdetails.bankname
      }`,
      110,
      y + 35
    );
    doc.text("Bank Acc No.", 40, y + 45);
    doc.text(":", 100, y + 45);
    doc.text(
      `${
        this.state.bankdetails.accno === undefined
          ? ""
          : this.state.bankdetails.accno
      }`,
      110,
      y + 45
    );

    doc.text("Branch Name", 40, y + 55);
    doc.text(":", 100, y + 55);
    doc.text(
      `${
        this.state.bankdetails.branchname === undefined
          ? ""
          : this.state.bankdetails.branchname
      }`,
      110,
      y + 55
    );
    doc.text("IFSC", 40, y + 65);
    doc.text(":", 100, y + 65);
    doc.text(
      `${
        this.state.bankdetails.ifsc === undefined
          ? ""
          : this.state.bankdetails.ifsc
      }`,
      110,
      y + 65
    );

    doc.line(350, y, 350, y + 70);
    doc.text(
      "We certified that particulars given above are true and correct",
      380,
      y + 10
    );
    doc.text(`for ${clientname}`, 410, y + 20);
    doc.line(580, y + 25, 350, y + 25);
    doc.text("Authorised Signatory", 425, y + 65);
    doc.line(30, y + 70, 580, y + 70);
    y = y + 90;
    if (y + 30 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }
    doc.text("Remarks: "+Invoicedata.invoiceremarks, 40, y + 15);
    y=y+30;
    if (
      this.state.templete.terms !== undefined &&
      this.state.templete.terms !== null &&
      this.state.templete.terms.length > 0
    ) {
      if (y + 150 > 841) {
        doc.addPage([595.28, 841.89]);
        y = 50;

        doc.setFont("helvetica", "normal");
        doc.setTextColor("#000");
        doc.setFontSize(7);
      }
      doc.text("Terms and Conditions", 275, y + 15);
      doc.line(30, y + 20, 580, y + 20);
      var splitTitle = doc.splitTextToSize(this.state.templete.terms, 500);
      doc.text(
        40,
        y + 30,
        splitTitle === undefined || splitTitle === "undefined" ? "" : splitTitle
      );
    }

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const footer = `Page ${i} of ${pageCount}`;
      doc.line(30, 45, 580, 45); // top line
      doc.line(30, pageHeight - 45, 580, pageHeight - 45); //bottom line
      doc.line(30, 45, 30, pageHeight - 45); // left
      doc.line(pageWidth - 15, 45, pageWidth - 15, pageHeight - 45);

      doc.setFontSize(7);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 25,
        { baseline: "bottom" }
      );
    }
    if (type === "pdf") {
      doc.autoPrint({ variant: "non-conform" });
      doc.save(
        `${
          pagename === "Tax Invoice Details"
            ? Invoicedata.custominvid
            : Invoicedata.invid
        }.pdf`
      );
    } else {
      const blob = doc.output("blob");
      let file = null;
      if (pagename === "Tax Invoice Details") {
        file = new File([blob], `${Invoicedata.custominvid}.pdf`);
      } else {
        file = new File([blob], `${Invoicedata.invid}.pdf`);
      }
      //   var fileURL = URL.createObjectURL(blob);
      // window.open(fileURL);
      let formData = new FormData();

      formData.append("file", file);
      formData.append("id", this.state.id);

      if (pagename === "Tax Invoice Details") {
        SalesServices.SendInvoicemail(formData)
          .then((response) =>
            this.setState({ loader: false }, () => {
              if (Invoicedata.approvestatus !== "Pending") {
                Swal.fire({
                  title: `Mail Sent Successfully`,
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              }
            })
          )
          .catch((e) =>
            this.setState(
              { message: e.response.data.message, loader: false },
              () => {
                if (e.response.data.error === "Unauthorized") {
                  Swal.fire({
                    title: `Your Session is Lost: Please login Back to resume`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
                } else {
                  Swal.fire({
                    title: `Server Error, please try again later`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  });
                }
              }
            )
          );
      } else {
        SalesServices.SendProformaInvoicemail(formData)
          .then((response) =>
            this.setState({ loader: false }, () =>
              Swal.fire({
                title: `Mail Sent Successfully`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            )
          )
          .catch((e) =>
            this.setState(
              { message: e.response.data.message, loader: false },
              () => {
                if (e.response.data.error === "Unauthorized") {
                  Swal.fire({
                    title: `Your Session is Lost: Please login Back to resume`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
                } else {
                  Swal.fire({
                    title: `Server Error, please try again later`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  });
                }
              }
            )
          );
      }
    }
  };

  handleDownloadewaybill = (type) => {
    // ewaybillpdf(this.state.einvoicedata,this.state.ewaybilldata,type)
    let invoice = this.state.einvoicedata;
    let ewaybilldata = this.state.ewaybilldata;
    let Invoicedata = this.state.Invoicedata;

    let totalinvoice = (
      Invoicedata.totalinvoice +
      (Invoicedata.insuranceamount === null ||
      Invoicedata.insuranceamount === undefined ||
      Invoicedata.insuranceamount === ""
        ? 0
        : Invoicedata.insuranceamount)
    ).toFixed(2);
    let taxamount = (
      Invoicedata.taxamount +
      (Invoicedata.insuranceamount === null ||
      Invoicedata.insuranceamount === undefined ||
      Invoicedata.insuranceamount === ""
        ? 0
        : Invoicedata.totalinsurance - Invoicedata.insuranceamount)
    ).toFixed(2);
    let totalinvoicewtax = Invoicedata.totalinvoicewtax.toFixed(2);
    let advance =
      Invoicedata.advanceamountreq === null ||
      Invoicedata.advanceamountreq === undefined
        ? 0.0
        : Invoicedata.advanceamountreq.toFixed(2);
    let totaladditionalchares = 0;
    let insuranceamount = 0;
    let totaladdincinsu = 0;
    let tcsamount = 0;
    let totalexctcs = 0;

    totaladditionalchares =
      Invoicedata.totaladditionalchares !== undefined &&
      Invoicedata.totaladditionalchares !== null
        ? Invoicedata.totaladditionalchares.toFixed(2)
        : (0).toFixed(2);
    insuranceamount = Invoicedata.insurancereq
      ? Invoicedata.totalinsurance.toFixed(2)
      : 0;
    totaladdincinsu = (
      (Invoicedata.totaladditionalchares !== undefined &&
      Invoicedata.totaladditionalchares !== null
        ? Invoicedata.totaladditionalchares
        : 0) + (Invoicedata.insurancereq ? Invoicedata.totalinsurance : 0)
    ).toFixed(2);

    if (Invoicedata.tcsreq) {
      tcsamount = Invoicedata.tcsamount.toFixed(2);
      totalexctcs = parseFloat(totalinvoicewtax).toFixed(2);
      totalinvoicewtax = (
        parseFloat(totalinvoicewtax) + Invoicedata.tcsamount
      ).toFixed(2);
    }

    if (Invoicedata.currency !== currency) {
      totalinvoice = (totalinvoice * Invoicedata.curconversionrate).toFixed(2);
      taxamount = (taxamount * Invoicedata.curconversionrate).toFixed(2);
      totalinvoicewtax = (
        totalinvoicewtax * Invoicedata.curconversionrate
      ).toFixed(2);
      advance = (advance * Invoicedata.curconversionrate).toFixed(2);
      totaladditionalchares = (
        totaladditionalchares * Invoicedata.curconversionrate
      ).toFixed(2);
      insuranceamount = (
        insuranceamount * Invoicedata.curconversionrate
      ).toFixed(2);
      // totaladdincinsu = (
      //   totaladditionalchares * Invoicedata.curconversionrate +
      //   insuranceamount * Invoicedata.curconversionrate
      // ).toFixed(2);
      if (Invoicedata.tcsreq) {
        tcsamount = (
          totaladditionalchares * Invoicedata.curconversionrate
        ).toFixed(2);
      }
    }

    let tabledata = [
      {
        mode: invoice.invoice.transportationmode,
        vehicle: `${invoice.invoice.vehiclenum} ${moment(
          ewaybilldata.ewbdt
        ).format("DD-MM-yyyy")}`,
        from: invoice.invoice.discity,
        enterddate: moment(ewaybilldata.ewbdt).format("DD-MM-yyyy"),
        enterdby: gstin,
        cewb: "",
        multiveh: "",
      },
    ];
    if (invoice.invoice.updtvehiclenum !== null) {
      tabledata.push({
        mode: invoice.invoice.updttransportationmode,
        vehicle: `${invoice.invoice.updtvehiclenum} ${moment(
          invoice.invoice.updtvehdt
        ).format("DD-MM-yyyy")}`,
        from: invoice.invoice.updtfrom,
        enterddate: moment(invoice.invoice.updtvehdt).format("DD-MM-yyyy"),
        enterdby: gstin,
        cewb: "",
        multiveh: "",
      });
    }

    let tablecol = [
      { header: "Mode", dataKey: "mode" },
      { header: "Vehicle / Trans DocNo & Dt", dataKey: "vehicle" },
      { header: "From", dataKey: "from" },
      { header: "Entered Date", dataKey: "enterddate" },
      { header: "Entered By", dataKey: "enterdby" },
      { header: "CEWB No.(If Any)", dataKey: "cewb" },
      { header: "Multi Veh.Info (If Any)", dataKey: "multiveh" },
    ];

    let supplytype = invoice.invoice.supplytype === "I" ? "Inward" : "Outward";
    let transactiontype = invoice.invoice.transactiontype === undefined || invoice.invoice.transactiontype === null?"":invoice.invoice.transactiontype;
    let transportername = invoice.invoice.transportername;
    let amount = this.state.Invoicedata.totalinvoicewtax.toFixed(2);

    let hsnlist = this.state.saleorderitems.map((e) => e.hsn);
    hsnlist = hsnlist.toString();

    const doc = new jsPDF("p", "pt", [842, 595], true);

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.setLineWidth(0.1)
    doc.line(30, 40, 580, 40); // top line
    doc.line(30, pageHeight - 40, 580, pageHeight - 40); //bottom line
    doc.line(30, 40, 30, pageHeight - 40); // left
    doc.line(pageWidth - 15, 40, pageWidth - 15, pageHeight - 40);

    let y = 0;
    doc.setFontSize(12);
    doc.text("E-Way Bill", 280, 65);
    doc.line(30, 75, 580, 75);
    doc.addImage(this.state.ewaybillQRcode, "GIF", 480, 80, 90, 90);
    doc.line(475, 75, 475, 170);
    doc.setFontSize(8);
    doc.text("E-Way Bill No : " + ewaybilldata.ewbno, 40, 90);
    doc.text(
      "Generated Date : " +
        moment(ewaybilldata.ewbdt).format("DD-MM-yyyy HH:mm"),
      250,
      90
    );
    doc.text(`Generated By : ${gstin}`, 40, 105);
    doc.text(
      "Valid Until : " +
        moment(ewaybilldata.ewbvalidtill).format("DD-MM-yyyy HH:mm"),
      250,
      105
    );
    doc.line(30, 110, 475, 110);
    doc.text(`Type : ${supplytype} - Supply`, 40, 120);
    doc.text("Transaction Type : " + transactiontype, 250, 120);
    doc.line(30, 125, 475, 125);
    doc.text(
      `Document : ${invoice.invoice.einvid} ${moment(
        this.state.Invoicedata.createdAt
      ).format("DD-MM-yyyy")}`,
      40,
      135
    );
    doc.text(`Approx Distance :  ${invoice.invoice.distance} kms`, 250, 135);
    doc.line(30, 140, 475, 140);
    if (this.state.Invoicedata.einvoicestatus !== "NA") {
      doc.text("IRN Details", 40, 150);
      doc.text("IRN : " + invoice.irn, 40, 165);
    }
    doc.line(30, 170, 580, 170);
    y = y - 130;
    doc.text("Address Details", 40, 180);
    doc.line(30, 185, 580, 185);
    doc.text("From", 40, 195);
    doc.line(30, 200, 580, 200);
    doc.text("GSTIN : " + gstin, 40, 210);
    doc.text(`${clientname} `, 40, 220);
    doc.text(`${cstate}`, 40, 230);
    doc.text(":: Dispatch From :: ", 40, 250);
    doc.text(`${this.state.Invoicedata.dishouseno}, ${this.state.Invoicedata.disareaname}`,40,260);
    doc.text(`${this.state.Invoicedata.discity}, ${this.state.Invoicedata.disstate} -${this.state.Invoicedata.dispincode}`,40,270);
    doc.line(290, 185, 290, 280);
    doc.text("To", 300, 195);
    doc.text("GSTIN : " + this.state.Invoicedata.custgstin, 300, 210);
    doc.text(`${this.state.Invoicedata.customer}`,300, 220);
    doc.text(`${this.state.Invoicedata.state}`,300,230);
    doc.text(":: Ship To :: ", 300, 250);
    doc.text(`${this.state.Invoicedata.dhouseno}, ${this.state.Invoicedata.dareaname}`,300,260);
    doc.text(`${this.state.Invoicedata.dcity}, ${this.state.Invoicedata.dstate} - ${this.state.Invoicedata.dpincode}`,300,270);
    doc.line(30, 280, 580, 280);

    doc.text("Goods Details", 40, 290);
    doc.text("Please Refer IRN Print to view Goods Details.", 40, 300);
    y = 310;
    doc.line(40, y, 555, y);

    // doc.text("Total", 220, y + 65);
    doc.line(40, y, 40, y + 50);
    doc.text("Taxable value", 45, y + 15);
    doc.text(`${totalinvoice}`, 45, y + 40);
    doc.line(115, y, 115, y + 50);
    doc.text("CGST", 120, y + 15);
    doc.text(
      `${
        Invoicedata.gsttype === "INTRASTATE" ? (taxamount / 2)?.toFixed(2) : 0.0
      }`,
      120,
      y + 40
    );
    doc.line(190, y, 190, y + 50);
    doc.text("SGST", 195, y + 15);
    doc.text(
      `${
        Invoicedata.gsttype === "INTRASTATE" ? (taxamount / 2)?.toFixed(2) : 0.0
      }`,
      195,
      y + 40
    );
    doc.line(265, y, 265, y + 50);
    doc.text("IGST", 270, y + 15);
    doc.text(
      `${Invoicedata.gsttype === "INTRASTATE" ? 0.0 : taxamount}`,
      270,
      y + 40
    );
    doc.line(340, y, 340, y + 50);
    doc.text(`Addnlcharges/Insur`, 342, y + 15);
    doc.text(`${totaladdincinsu}`, 345, y + 40);
    doc.line(415, y, 415, y + 50);
    doc.text(`TCS`, 420, y + 15);
    doc.text(`${tcsamount}`, 420, y + 40);
    doc.line(490, y, 490, y + 50);
    doc.text("Total", 495, y + 15);
    doc.text(`${totalinvoicewtax}`, 495, y + 40);
    doc.line(555, y, 555, y + 50);

    doc.line(40, y + 25, 555, y + 25);
    doc.line(40, y + 50, 555, y + 50);
      y = y+50
    doc.line(30, y+10, 580, y+10);
    doc.text("Transportation Details", 40, y+20);
    doc.text(
      `Transporter ID & Name : ${
        Invoicedata.transporterid === null ? "" : Invoicedata.transporterid
      } ${transportername === null ? "" : transportername}`,
      40,
      y+35
    );
    doc.text(
      "Transporter Doc. No & Date : " +
        moment(ewaybilldata.ewbdt).format("DD-MM-yyyy"),
      300,
      y+35
    );
    doc.line(30, y+40, 580, y+40);
    doc.text("Vehicle Details", 40, y+50);

    autoTable(doc, {
      theme: "grid",
      startY: y+65,
      margin:{top: 0, bottom: 0,right:25,left:40},
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8", cellPadding: 2 },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          lineWidth: 0.1,
          lineColor: [211, 211, 211],
          fontStyle: "default",
          halign: "center",
        },
        bodyStyles: {
          halign: "center",
          lineColor: [211, 211, 211],
        },
      body: tabledata,
      columns: tablecol,
    });

    if (type === "pdf") {
      doc.autoPrint({ variant: "non-conform" });
      doc.save(`${ewaybilldata.ewbno}.pdf`);
    } else {
      const blob = doc.output("blob");
      const file = new File([blob], `${ewaybilldata.ewbno}.pdf`);

      // var fileURL = URL.createObjectURL(blob);
      // window.open(fileURL);
      let formData = new FormData();

      formData.append("file", file);
      formData.append("id", ewaybilldata.ewbno);

      SalesServices.SendEwaybillMail(formData)
        .then((response) =>
          this.setState({ loader: false }, () =>
            Swal.fire({
              title: `Mail Sent Successfully`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          )
        )
        .catch((e) =>
          this.setState(
            { message: e.response.data.message, loader: false },
            () => {
              if (e.response.data.error === "Unauthorized") {
                Swal.fire({
                  title: `Your Session is Lost: Please login Back to resume`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() => this.props.navigate("/"));
              } else {
                Swal.fire({
                  title: `Server Error, please try again later`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              }
            }
          )
        );
    }
  };

  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
    });
  };

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.partyid,
      day
      // (event) => {
      //   this.setState({
      //     progress: Math.round((100 * event.loaded) / event.total),
      //   });
      //   progress = Math.round((100 * event.loaded) / event.total);
      // }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    this.setState({ selectedFiles: undefined });
  }

  handleSendMail = () => {
    if (this.state.fileInfos.length === 0) {
      alert("Please Upload File");
    } else {
      let filedata = this.state.fileInfos.map((e) => e.name);
      path =
        prefix1 +
        "/" +
        doctype +
        "/" +
        this.state.partyid +
        "/" +
        year +
        "/" +
        curmonth +
        "/" +
        day;
      let data = {
        file: filedata.toLocaleString(),
        path: path,
        invid: this.state.id,
        amount: this.state.Invoicedata.totalinvoicewtax,
      };
      SalesServices.SendInvoicemail(data).then(() =>
        this.setState({
          show: false,
          selectedFiles: undefined,
          currentFile: undefined,
          progress: 0,
          message: "",
          isError: false,
          fileInfos: [],
          fileInfo: "",
          partyid: "",
          error: false,
        })
      );
    }
  };

  handleTransporterChange = (value, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      this.setState({
        transportername: "",
        transporterid: "",
        validtrnsid: false,
      });
    } else {
      this.setState({
        transportername: newValue.transname,
        transporterid: newValue.transid,
        validtrnsid: true,
      });
    }
  };

  handleDeleteInvoice = () => {
    let data = {
      id: this.state.id,
      updatedby: userid,
    };
    Swal.fire({
      title: "Delete Invoice?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        if (this.state.pagename === "Tax Invoice Details") {
          SalesServices.TerminateTempInvoice(data)
            .then((response) => {
              this.setState({ loader: false });
              Swal.fire({
                title: "Invoice Deleted Successfully!",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate(-1));
            })
            .catch((e) => {
              this.setState({ loader: false });
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        } else {
          SalesServices.DeleteProformaInvoice(data)
            .then((response) => {
              this.setState({ loader: false });
              Swal.fire({
                title: "Invoice Deleted Successfully!",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate(-1));
            })
            .catch((e) => {
              this.setState({ loader: false });
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      }
    });
  };

  handleTerminateInvoice = () => {
    if (this.state.remarks === "") {
      this.setState({ showCancelModal: true });
    } else {
      this.setState({ showCancelModal: false });
      let data = {
        id: this.state.id,
        updatedby: userid,
        remarks: this.state.remarks,
      };
      Swal.fire({
        title: "Terminate/Cancel Invoice?",
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.setState({ loader: true });
          SalesServices.TerminateInvoice(data)
            .then((response) => {
              this.setState({ loader: false });
              Swal.fire({
                title: "Invoice Terminated Successfully!",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate(-1));
            })
            .catch((e) => {
              this.setState({ loader: false });
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      });
    }
  };

  handleTerminateEInvoice = () => {
    if (this.state.remarks === "") {
      this.setState({ showrem: true });
    } else {
      let data = {
        tenantId: currentTenant,
        Irn: this.state.einvoicedata.irn,
        CnlRsn: parseInt(this.state.reasonCodeforcnl),
        CnlRem: this.state.remarks,
      };

      Swal.fire({
        title: "Terminate/Cancel E-Invoice?",
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.setState({ loader: true });
          SalesServices.CancelEinvoice(data)
            .then((res) => {
              this.setState({ showrem: false, msg: res.data, loader: false });
              Swal.fire({
                title: `${res.data}`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
            })
            .catch((e) => {
              this.setState({
                loader: false,
                errormsg:
                  e.response.data.body === undefined
                    ? e.response.data
                    : JSON.parse(e.response.data.body),
              });
              if (
                e.response === undefined ||
                e.response.data.error === "Unauthorized"
              ) {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      });
    }
  };

  handleTerminateEwaybill = () => {
    if (this.state.remarks === "") {
      this.setState({ showrem: true });
    } else {
      let data = {
        tenantId: currentTenant,
        ewbNo: this.state.ewaybilldata.ewbno,
        cancelRsnCode: this.state.reasonCodeforcnl,
        cancelRmrk: this.state.remarks,
      };

      Swal.fire({
        title: "Terminate/Cancel E-Way bill?",
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.setState({ loader: true });
          SalesServices.CancelEwaybill(data)
            .then((response) => {
              this.setState({ loader: false });
              Swal.fire({
                title: "EWay-bill Terminated Successfully!",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
            })
            .catch((e) => {
              this.setState({ loader: false });
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
              Swal.fire({
                title: `${
                  e.response.data.body === undefined
                    ? e.response.data
                    : e.response.data.body?.map((e, i) => e.ErrorMessage)
                }`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
            });
        }
      });
    }
  };
  // <div className="col-md-12 text-danger">{typeof this.state.errormsg === 'string'?this.state.errormsg:this.state.errormsg?.map((e,i)=>e.ErrorMessage)}</div>

  handleEWaybillGenration = () => {
    if (
      this.state.vehiclenum === "" ||
      this.state.distance === "" ||
      this.state.distance === null ||
      this.state.distance === 0 ||
      this.state.distance === undefined ||
      this.state.transporterid === ""
      // || this.state.validtrnsid === false
    ) {
      this.setState({ err: true });
    } else {
      if (this.state.Invoicedata.einvoicestatus === "NA") {
        let data = {
          tenantId: currentTenant,
          TransId: this.state.transporterid,
          TransName: this.state.transportername,
          Distance: this.state.distance,
          TransDocNo: this.state.Invoicedata.invid,
          TransDocDt: this.state.Invoicedata.createdAt,
          VehNo: this.state.vehiclenum,
          VehType: this.state.vehicletype,
          TransMode: this.state.transportationmode,
          supplytype: this.state.supplytype,
          transactiontype: this.state.transactiontype,
        };

        Swal.fire({
          title: "Generate E-Way bill?",
          text: "Are you sure?",
          icon: "question",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({ loader: true });
            SalesServices.GenerateEwaybillAPI(data)
              .then((response) => {
                this.setState({ loader: false });
                Swal.fire({
                  title: "Eway bill generated successfully!",
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
              })
              .catch((e) => {
                this.setState({ loader: false });
                this.setState({ errormsg: e.response.data.body.errormessage });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
                Swal.fire({
                  title: `${e.response.data.body.errormessage}`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              });
          }
        });
      } else if (this.state.Invoicedata.einvoicestatus === "Pending") {
        let data = {
          tenantId: currentTenant,
          Irn: this.state.Invoicedata.invid,
          TransId: this.state.transporterid,
          TransName: this.state.transportername,
          Distance: this.state.distance,
          VehNo: this.state.vehiclenum,
          VehType: this.state.vehicletype,
          TransMode: this.state.transportationmode,
          supplytype: this.state.supplytype,
          transactiontype: this.state.transactiontype,
        };
        Swal.fire({
          title: "Update Transportation Details?",
          text: "Are you sure?",
          icon: "question",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({ loader: true });
            SalesServices.UpdateTransportation(data)
              .then((response) => {
                this.setState({ loader: false });
                Swal.fire({
                  title: "Transportation details updated successfully",
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
              })
              .catch((e) => {
                this.setState({ loader: false });
                this.setState({ errormsg: e.response.data.body.errormessage });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
                Swal.fire({
                  title: `${e.response.data.body.errormessage}`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              });
          }
        });
      } else {
        let data = {
          tenantId: currentTenant,
          Irn: this.state.einvoicedata.irn,
          TransId: this.state.transporterid,
          TransName: this.state.transportername,
          Distance: this.state.distance,
          TransDocNo: this.state.TransDocNo,
          TransDocDt: this.state.TransDocDt,
          VehNo: this.state.vehiclenum,
          VehType: this.state.vehicletype,
          TransMode: this.state.transportationmode,

          // Ttansmode:this.state.samedaydelivery?this.state.transmode:null,
          supplytype: this.state.supplytype,
          transactiontype: this.state.transactiontype,
        };

        Swal.fire({
          title: "Generate E-Way bill?",
          text: "Are you sure?",
          icon: "question",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({ loader: true });
            SalesServices.GenerateEwaybill(data)
              .then((response) => {
                this.setState({ loader: false });
                Swal.fire({
                  title: "Eway bill generated successfully!",
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
              })
              .catch((e) => {
                this.setState({ loader: false });
                this.setState({ errormsg: e.response.data.body.errormessage });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
                Swal.fire({
                  title: `${e.response.data.body.errormessage}`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              });
          }
        });
      }
    }
  };

  ExtendEwaybill = () => {
    let data = {
      tenantId: currentTenant,
      ewbNo: this.state.ewaybilldata.ewbno,
      vehicleNo: this.state.vehiclenum,
      fromPlace: this.state.fromPlace,
      fromState: parseInt(this.state.fromStatecode),
      remainingDistance: this.state.remainingDistance,
      transDocNo: this.state.ewaybilldata.ewbno,
      transDocDate: moment(this.state.ewaybilldata.ewbdt).format("DD/MM/yyyy"),
      transMode: this.state.transportationmode,
      extnRsnCode: this.state.reasonCodeforewbext,
      extnRemarks: this.state.extnRemarks,
      fromPincode: parseInt(this.state.fromPincode),
      consignmentStatus: this.state.transportationmode === "5" ? "T" : "M",
      transitType:
        this.state.transportationmode === "5" ? this.state.transitType : "",
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      addressLine3: this.state.addressLine3,
    };

    Swal.fire({
      title: "Extend E-Way bill?",
      text: "Are you sure you want to ?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        SalesServices.ExtendEwaybill(data).then((re) => {
          this.setState({ loader: false });
          if (res.statusCodeValue === 200) {
            Swal.fire({
              title: "Eway bill Date extended successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
          } else {
            this.setState({ loader: false });
            Swal.fire({
              title: res.data.body,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          }
        });
      }
    });
  };
  handlesChangeTRNS = () => {
    let data = {
      tenantId: currentTenant,
      ewbNo: this.state.ewaybilldata.ewbno,
      transporterId: this.state.transporterid,
    };

    Swal.fire({
      title: "Change Transporter?",
      text: "Are you sure you want to change?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        SalesServices.UpdateTransporter(data).then((res) => {
          this.setState({ loader: false });
          if (res.data.statusCodeValue === 400) {
            Swal.fire({
              title: res.data.body,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          } else {
            this.setState({ showtrans: false, loader: false });
            Swal.fire({
              title: "Transporter Updated successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
          }
        });
      }
    });
  };

  UpdateVehicel = () => {
    let data = {
      tenantId: currentTenant,
      ewbNo: this.state.ewaybilldata.ewbno,
      vehicleNo: this.state.vehiclenum,
      fromPlace: this.state.fromPlace,
      fromState: parseInt(this.state.fromStatecode),
      transDocNo: this.state.ewaybilldata.ewbno,
      transDocDate: moment(this.state.ewaybilldata.ewbdt).format("DD/MM/yyyy"),
      transMode: this.state.transportationmode,
      reasonCode: this.state.reasonCodeforvehchg,
      reasonRem: this.state.extnRemarks,
      vehicleType: this.state.vehicletype,
    };

    Swal.fire({
      title: "Update vehicle?",
      text: "Are you sure you want to update vehicle ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        SalesServices.ChangeVehBefDept(data)
          .then((res) => {
            this.setState({ loader: false });
            if (res.data.statusCodeValue === 400) {
              Swal.fire({
                title: res.data.body,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
            } else {
              Swal.fire({
                title: "Vehicle Details Updated successfully!",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
            }
          })
          .catch(() => {
            this.setState({ loader: false });
            Swal.fire({
              title: "Error occured while updating vehicle",
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          });
      }
    });
  };

  handleCountryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        fromCountry: newValue.name,
        StatesData: newValue.states,
        err: false,
      });
    }
  };

  handleStateChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        fromState: newValue.name,
        fromStatecode: newValue.code,
        CitiesData: newValue.cities,
        err: false,
      });
    }
  };
  handleCityChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({ fromPlace: newValue.name, err: false });
    }
  };

  handleEwaybillClose = () => {
    this.setState({ showfields: false });
  };

  handleEwaybillOpen = () => {
    this.setState({ showfields: true });
  };

  handleshowEWBExtnClose = () => {
    this.setState({ showEWBExtn: false });
  };

  handleshowEWBExtnOpen = () => {
    this.setState({ showEWBExtn: true });
  };
  handleshowchangeTRNS = () => {
    this.setState({ showtrans: true });
  };
  handleclosechangeTRNS = () => {
    this.setState({ showtrans: false });
  };

  handleshowChgVel = () => {
    this.setState({ showupdtveh: true });
  };

  handlecloseChgVel = () => {
    this.setState({ showupdtveh: false });
  };

  GenerateCreditNote = (e) => {
    let data = {
      id: e.id,
      updatedby: userid,
      status: 0,
    };
    Swal.fire({
      title: `Generate Credit Note?`,
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        // this.setState({loading:true})
        SalesServices.GenerateCreditNote(data)
          .then(() => {
            this.setState({ loader: false });
            if (status === 0 && this.state.mailbehavior === "YES") {
              this.handleDownload(this.state.Invoicedata, "mail");
            }
            Swal.fire({
              title:
                status === 0
                  ? "Invoice Approved successfully"
                  : "Invoice sent to Revise successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp));
          })
          .catch((e) => {
            this.setState({ loader: false });
            Swal.fire({
              title: "Error occurred, please try again",
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  };

  handleApprove = (status) => {
    let data = {
      id: this.state.Invoicedata.invid,
      updatedby: userid,
      status: status,
    };
    if(this.state.ispaymentpending && status === 0 ){
      Swal.fire({
        title:'Pending payment from customer!',
        text: "Payment is due from the customer, please settle it before continuing",
        icon:"warning",
        confirmButtonColor: "#152f5f",
      })
    }else{
    Swal.fire({
      title: `${status === 0 ? "Approve Invoice" : "Send to revise"}?`,
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        // this.setState({loading:true})
        if (this.state.pagename === "Tax Invoice Details") {
          SalesServices.ApproveInvoice(data)
            .then((res) => {
              this.setState({ loader: false });
              // if (status === 0 && this.state.mailbehavior === "YES") {
              //   this.handleDownload(this.state.Invoicedata, "mail");
              // }
              Swal.fire({
                title:
                  status === 0
                    ? "Invoice Approved successfully"
                    : "Invoice sent to Revise successfully",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(()=> this.props.navigate("/salesinvoice",{state:{ message: "", type: "Tax Invoice" }}))
              // .then((res) =>status === 0? this.Refresh(res?.data,"Tax Invoice Details",false):
              // this.Refresh(this.state.Invoicedata.invid,"Tax Invoice Details",true)
              // );
            })
            .catch((e) => {
              this.setState({ loader: false });
              Swal.fire({
                title: e?.response?.data?.body?.errormessage,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
              if (e?.response?.data?.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        } else {
          SalesServices.ApproveProformainvoice(data)
            .then(() => {
              this.setState({ loader: false });
              if (status === 0 && this.state.mailbehavior === "YES") {
                this.handleDownload(this.state.Invoicedata, "mail");
              }
              Swal.fire({
                title: "Proforma Invoice Approved successfully",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
              this.Refresh(this.state.Invoicedata.invid,"Proforma Invoice Details",true);
            })
            .catch(() => {
              this.setState({ loader: false });
              Swal.fire({
                title: "Error occurred, please try again",
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      }
    });
  }
  };

  Featcheinvoice = () => {
    this.setState({ loader: true });
    SalesServices.Featcheinvoice(this.state.Invoicedata.invid)
      .then((res) => this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp))
      .catch((err) => {
        this.setState({ loader: false });
        Swal.fire({
          title: ``,
          text: `${err.response.data?.errormessage}`,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then(() => console.log(err, err.response, err.response.data));
      });
  };


  handleJournalEntry = () =>{
    Swal.fire({
      title: `Make Journal Entry?`,
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
        SalesServices.AutoJre(this.state.Invoicedata.invid).then((res)=> 
        this.Refresh(this.state.Invoicedata.invid,this.state.pagename,this.state.temp)
        ).catch(()=>this.setState({ loader: false}))
      }})
  }

  handleCloseInvoice = ()=>{
    let data = {
      id: this.state.Invoicedata.invid,
      updatedby: userid,
    };

    Swal.fire({
      title: "Close Invoice",
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loader: true });
          SalesServices.CloseInvoice(data)
            .then((res) => {
              this.setState({ loader: false });
              // if (status === 0 && this.state.mailbehavior === "YES") {
              //   this.handleDownload(this.state.Invoicedata, "mail");
              // }
              Swal.fire({
                title: "Invoice Closed successfully",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then((res) =>this.Refresh(this.state.Invoicedata.invid,"Tax Invoice Details",false))
              // .then(()=> this.props.navigate("/salesinvoice",{state:{ message: "", type: "Tax Invoice" }}))
              
              // this.Refresh(this.state.Invoicedata.invid,"Tax Invoice Details",true)
              // );
            })
            .catch((e) => {
              this.setState({ loader: false });
              Swal.fire({
                title: e?.response?.data?.body?.errormessage,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
              if (e?.response?.data?.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      })

  }

  Refresh = (invid,pn,tem) => {
    let id = invid === undefined || invid === null?this.state.id:invid
    let pagename =  pn === undefined || pn === null?this.state.pagename:pn
    let temp =  tem === undefined || tem === null?this.state.temp:tem
    this.setState({pagename:pagename,id:invid,temp:temp});
    if (pagename === "Tax Invoice Details") {
      if (temp === true) {
        SalesServices.GetTempInvoice(id).then((response) => {
          this.setState({
            Invoicedata: response.data,
            saleorderitems: response.data.invoiceitemlist,
            invoiceservicelist: response.data.invoiceservicelist,
            additionalchareslist: response.data.additionalchares,
            vehicletype: response.data.vehicletype,
            vehiclenum: response.data.vehiclenum,
            distance: response.data.distance,
            transporterid: response.data.transporterid,
            transportername: response.data.transportername,
            transmode: response.data.transmode,
            showfields: false,
            showrem: false,
            showEWBExtn: false,
            showtrans: false,
            showupdtveh: false,
            // milestones:response.data.invoiceservicelist.map(e=>e.invoicemilestones)
          });

          SalesServices.GetCustomerTerms(response.data.custid).then((res) =>
          SalesServices.IsPaymentPending(response.data.custid).then((response)=>
          this.setState({ispaymentpending:response.data && res.data !==null && res.data.creditstatus === "Required"?true:false,loading: false,
          loader: false,}) 
          )
        )

          //   if (response.data.einvoicestatus === "Generated" || response.data.ewaybillstatus === "Generated") {
          //     SalesServices.GetEinvoice(id).then((response) => {
          //       this.setState({
          //         einvoicedata: response.data,
          //         showrem: false,
          //         remarks: "",
          //       });
          //       if (response.data.signedQRcode !== undefined) {
          //         QRCode.toDataURL(response.data.signedQRcode).then((url) => {
          //           this.setState({ QRcode: url });
          //         });
          //         SalesServices.GetEwayBill(response.data.id).then((response) => {
          //           this.setState({
          //             ewaybilldata: response.data,
          //             showrem: false,
          //           });
          //           QRCode.toDataURL(
          //             `${response.data.ewbno}/${gstin}/${moment(
          //               response.data.ewbdt
          //             ).format("DD-MM-yyyy HH:mm")}`
          //           ).then((url) => {
          //             this.setState({ ewaybillQRcode: url });
          //           });
          //         });
          //       }
          //     });
          //   }
          // })
          // .catch((e) => {
          //   this.setState({ loading: false, loader: false });
          //   if (e.response.data.error === "Unauthorized") {
          //     alert("Your Session is Lost: Please login Back to resume");
          //     this.props.navigate(`/`);
          //   }
        }).catch(()=>this.props.navigate("salesinvoice",{state:{type: this.state.type,temp:false}}))
      } else {
        SalesServices.GetInvoice(id)
          .then((response) => {
            this.setState({
              Invoicedata: response.data,
              saleorderitems: response.data.invoiceitemlist,
              invoiceservicelist: response.data.invoiceservicelist,
              additionalchareslist: response.data.additionalchares,
              loading: false,
              loader: false,
              vehicletype: response.data.vehicletype,
              vehiclenum: response.data.vehiclenum,
              distance: response.data.distance,
              transporterid: response.data.transporterid,
              transportername: response.data.transportername,
              transmode: response.data.transmode,
              showfields: false,
              showrem: false,
              showEWBExtn: false,
              showtrans: false,
              showupdtveh: false,
              // milestones:response.data.invoiceservicelist.map(e=>e.invoicemilestones)
            });
            SalesServices.getAllCreditnotesbyINV(id).then((res) => 
              this.setState({ creditnoteslist: res.data })
            );
            SalesServices.geteDebitNotesofinvoice(id).then((res) =>
              this.setState({ edebitnoteslist: res.data })
            )
            if (
              response.data.einvoicestatus === "Generated" ||
              response.data.ewaybillstatus === "Generated"
            ) {
              SalesServices.GetEinvoice(id).then((response) => {
                this.setState({
                  einvoicedata: response.data,
                  showrem: false,
                  remarks: "",
                });
                if (response.data.signedQRcode !== undefined) {
                  QRCode.toDataURL(response.data.signedQRcode).then((url) => {
                    this.setState({ QRcode: url });
                  });
                  SalesServices.GetEwayBill(response.data.id).then(
                    (response) => {
                      this.setState({
                        ewaybilldata: response.data,
                        showrem: false,
                      });
                      QRCode.toDataURL(
                        `${response.data.ewbno}/${gstin}/${moment(
                          response.data.ewbdt
                        ).format("DD-MM-yyyy HH:mm")}`
                      ).then((url) => {
                        this.setState({ ewaybillQRcode: url });
                      });
                    }
                  );
                }
              });
            }
          })
          .catch((e) => {
            this.setState({ loading: false, loader: false });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
      TransporterServices.getTransporters(currentTenant).then((response) =>
        this.setState({ transporterslist: response.data })
      );
      MasterService.getCountries()
        .then((response) =>
          this.setState({
            CountryData: response.data,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    } else {
      SalesServices.GetProformaInvoice(id)
        .then((response) => {
          this.setState({
            Invoicedata: response.data,
            saleorderitems: response.data.invoiceitemlist,
            invoiceservicelist: response.data.invoiceservicelist,
            loading: false,
            // milestones:response.data.invoiceservicelist.map(e=>e.invoicemilestones)
          });
        })
        .catch((e) => {
          this.setState({ loading: false });
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }

    let data = "Invoice";
    SettingsServices.GetActivePdfTemplate(data, currentTenant).then(
      (response) => this.setState({ templete: response.data })
    );

    SettingsServices.GetSettings(currentTenant).then((response) =>
      this.setState({ mailbehavior: response.data.mailbehavior })
    );

    SettingsServices.Getlicencesoftenant(currentTenant).then((res) =>
      this.setState({ licenses: res.data })
    );

    BankServices.getActivebank(currentTenant).then((res) =>
      this.setState({ bankdetails: res.data })
    );
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    this.setState({ loading: true });

    let i = new Image();
    i.src = logo;

    setTimeout(() => {
      this.setState({ imgwidth: i.naturalWidth, imgheight: i.naturalHeight });
    }, 100);

    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");
    this.Refresh(this.props.location.state.id,this.props.location.state.pagename,this.props.location.state.temp);
  }
  handleOpendropdown = (val) => {
    if (this.state.opendropdown === val) {
        this.setState({opendropdown:""})
    }else{
      this.setState({ opendropdown: val });
    }
    
  };

  handleClose = () => {
    this.setState({ opendropdown: "" });
  };

  render() {
    let { selectedFiles, message, isError, transporterid, transportername } =
      this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <span
                className=" text-secondary cursorPointer"
                onClick={() => this.props.location.state.goback === undefined?this.props.navigate(-1):this.props.navigate({pathname:"/salesinvoice"},{state:{type: this.state.type,temp:this.state.temp}})}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link
                  to={{ pathname: "/salesinvoice" }}
                  state={{ type: this.state.type }}
                >
                  Sales Invoice
                </Link>
              </li>
              <li className="breadcrumb-item active"> {this.state.pagename}</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={this.state.loader}
                        // onClick={handleClose}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      <div className="card-header">
                        <h3 className="card-title">{this.state.pagename} - {this.state.pagename ===
                                          "Tax Invoice Details"
                                            ? this.state.Invoicedata?.custominvid
                                            : this.state.Invoicedata?.invid}</h3>

                        {this.state.loading === true ||
                        this.state.Invoicedata.approvestatus !== "Approved" ? (
                          ""
                        ) : (
                          <>
                  
                          
                          <div className="btn-group float-right">
                            <button type="button" className="btn btn-sm pdf-bgcolor dropdown-toggle button-shadow items-center text-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>this.handleOpendropdown("pdf")}>
                            <img src={pdficon} width={"15px"} height={"15px"} /> PDF
                            </button>
                            {this.state.opendropdown === "pdf"?
                            <ul className="drop-down-menu d-block" >
                              <li><button className="dropdown-item" type="button" onClick={() =>{
                                this.handleDownload(this.state.Invoicedata,"pdf")
                                this.setState({opendropdown:""})
                                }}>Download PDF</button></li>
                                 <li><button className="dropdown-item" type="button" onClick={() =>{
                                this.handleDownload(this.state.Invoicedata,"pdf")
                             this.setState({opendropdown:""})}}>Print PDF</button></li>
                               
                               {this.state.pagename === "Tax Invoice Details" &&
                            this.state.Invoicedata.ewaybillstatus ===
                              "Generated" &&
                            (this.state.ewaybilldata.ewbno !== null ||
                              this.state.ewaybilldata.ewbno !== undefined) ? ( <>
                                 <li><button className="dropdown-item" type="button" onClick={() =>{
                                this.handleDownloadewaybill("pdf")
                                this.setState({opendropdown:""})
                                }}>Download Ewaybill PDF</button></li>
                                 <li><button className="dropdown-item" type="button" onClick={() =>{
                                  this.handleDownloadewaybill("pdf")
                                  this.setState({opendropdown:""})
                                  }}>Print Ewaybill PDF</button></li>
                               </> ):""}
                             
                            </ul>:""}
                          </div>
                         
                        
                          <div className="btn-group float-right">
                            <button type="button" className="btn btn-sm recipt-bgcolor dropdown-toggle button-shadow text-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>this.handleOpendropdown("receipt")}>
                            <img src={receiptIcon} width={"15px"} height={"15px"} /> RECEIPT
                            </button>
                          </div>  
                          <div className="btn-group float-right">
                            <button type="button" className="btn btn-sm sms-bgcolor dropdown-toggle button-shadow text-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>this.handleOpendropdown("sms")}>
                            <img src={smsIcon} width={"15px"} height={"15px"} />SEND SMS
                            </button>
                          </div> 
                          <div className="btn-group float-right">
                            <button type="button" className="btn btn-sm whatsapp-bgcolor dropdown-toggle button-shadow text-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>this.handleOpendropdown("whatsapp")}>
                            <img src={whatsappIcon} width={"15px"} height={"15px"} /> WHATSAPP
                            </button>
                          </div> 
                          <div className="btn-group float-right">
                            <button type="button" className="btn btn-sm mail-bgcolor dropdown-toggle button-shadow text-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>this.handleOpendropdown("mail")}>
                            <img src={mailIcon} width={"15px"} height={"15px"} /> SEND EMAIL
                            </button>
                            {this.state.opendropdown === "mail"?
                            <ul className="drop-down-menu d-block" >
                              <li><button className="dropdown-item" type="button" onClick={() =>{this.handleDownload(this.state.Invoicedata,"mail")  
                              this.setState({opendropdown:""})}}>Invoice Created</button></li>
                              <li><button className="dropdown-item" type="button" onClick={() =>{this.handleDownload(this.state.Invoicedata,"mail")  
                              this.setState({opendropdown:""})}}>Invoice Payment Reminder</button></li>
                              <li><button className="dropdown-item" type="button" onClick={() =>{this.handleDownload(this.state.Invoicedata,"mail")  
                              this.setState({opendropdown:""})}}>Invoice Overdue Notice</button></li>
                              <li><button className="dropdown-item" type="button" onClick={() =>{this.handleDownload(this.state.Invoicedata,"mail")  
                              this.setState({opendropdown:""})}}>Invoice Payment Confirmation</button></li>
                              <li><button className="dropdown-item" type="button" onClick={() =>{this.handleDownload(this.state.Invoicedata,"mail")  
                              this.setState({opendropdown:""})}}>Invoice Refund Confirmation</button></li>
                               {this.state.pagename === "Tax Invoice Details" &&
                            this.state.Invoicedata.ewaybillstatus ===
                              "Generated" &&
                            (this.state.ewaybilldata.ewbno !== null ||
                              this.state.ewaybilldata.ewbno !== undefined) ? ( <>
                                 <li><button className="dropdown-item" type="button" onClick={() =>{
                                this.handleDownloadewaybill("mail")
                                this.setState({opendropdown:""})
                                }}>Ewaybill</button></li>
                               </> ):""}
                            </ul>:""}
                          </div>

{/* 
                            <button
                              className="btn btn-sm hovbuttonColour shadow-sm float-right text-md ml-1"
                              onClick={() =>
                                this.handleDownload(
                                  this.state.Invoicedata,
                                  "mail"
                                )
                              }
                            >
                              <i className="fa fa-envelope "></i>&nbsp;
                              {this.state.pagename ===
                              "Proforma Invoice Details"
                                ? "Proforma Invoice"
                                : this.state.Invoicedata.einvoicestatus ===
                                    "NA" ||
                                  this.state.Invoicedata.einvoicestatus ===
                                    "Pending"
                                ? "Invoice"
                                : "e-Invoice"}
                            </button>

                            <button
                              className="btn btn-sm hovbuttonColour shadow-sm float-right text-md ml-1"
                              onClick={() =>
                                this.handleDownload(
                                  this.state.Invoicedata,
                                  "pdf"
                                )
                              }
                            >
                              <i className="fa fa-file-invoice-dollar"></i>
                              &nbsp;
                              {this.state.pagename ===
                              "Proforma Invoice Details"
                                ? "Proforma Invoice"
                                : this.state.Invoicedata.einvoicestatus ===
                                    "NA" ||
                                  this.state.Invoicedata.einvoicestatus ===
                                    "Pending"
                                ? "Invoice"
                                : "e-Invoice"}
                            </button>

                            {this.state.pagename === "Tax Invoice Details" &&
                            this.state.Invoicedata.ewaybillstatus ===
                              "Generated" &&
                            (this.state.ewaybilldata.ewbno !== null ||
                              this.state.ewaybilldata.ewbno !== undefined) ? (
                              <button
                                className="btn btn-sm hovbuttonColour shadow-sm float-right text-md ml-1"
                                onClick={() =>
                                  this.handleDownloadewaybill("mail")
                                }
                              >
                                <i className="fa fa-envelope "></i>
                                &nbsp;EWay-bill
                              </button>
                            ) : (
                              ""
                            )}
                            {this.state.pagename === "Tax Invoice Details" &&
                            this.state.Invoicedata.ewaybillstatus ===
                              "Generated" &&
                            (this.state.ewaybilldata.ewbno !== null ||
                              this.state.ewaybilldata.ewbno !== undefined) ? (
                              <button
                                className="btn btn-sm hovbuttonColour shadow-sm float-right text-md ml-1"
                                onClick={() =>
                                  this.handleDownloadewaybill("pdf")
                                }
                              >
                                <i className="fa fa-receipt "></i>
                                &nbsp;EWay-bill
                              </button>
                            ) : (
                              ""
                            )} */}
                          </>
                        )}
                      </div>

                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <>
                              <div className="form-row">
                                {this.state.pagename ===
                                  "Tax Invoice Details" &&
                                (this.state.Invoicedata.einvoicestatus ===
                                  "Generated" ||
                                  this.state.Invoicedata.einvoicestatus ===
                                    "Cancelled") &&
                                this.state.einvoicedata.irn !== undefined ? (
                                  <div
                                    className="border-bottom row m-0 p-0"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="form-column col-md-8">
                                      <table className="float-left pb-3 col-md-12 border-bottom">
                                        <tbody>
                                          <tr>
                                            <td>IRN</td>
                                            <td>
                                              :&nbsp;
                                              {this.state.einvoicedata.irn}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Ack no</td>
                                            <td>
                                              :&nbsp;
                                              {this.state.einvoicedata.ackno}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Ack Date</td>
                                            <td>
                                              :&nbsp;
                                              {moment(
                                                this.state.einvoicedata.ackDt
                                              ).format("DD-MM-yyyy HH:mm")}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                      {(this.state.Invoicedata
                                        .ewaybillstatus === "Generated" ||
                                        this.state.Invoicedata
                                          .ewaybillstatus === "Cancelled") &&
                                      this.state.ewaybilldata.ewbno !==
                                        undefined ? (
                                        <table className="float-left pb-3">
                                          <tbody>
                                            <tr>
                                              <td>E-Way bill no</td>
                                              <td>
                                                :&nbsp;
                                                {this.state.ewaybilldata.ewbno}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>E-Way bill Date</td>
                                              <td>
                                                :&nbsp;
                                                {moment(
                                                  this.state.ewaybilldata.ewbdt
                                                ).format("DD-MM-yyyy HH:mm")}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>E-Way bill valid till</td>
                                              <td>
                                                :&nbsp;
                                                {moment(
                                                  this.state.ewaybilldata
                                                    .ewbvalidtill
                                                ).format("DD-MM-yyyy HH:mm")}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="form-column col-md-4">
                                      <img
                                        src={this.state.QRcode}
                                        alt="qrcode"
                                        className="d-block mx-auto"
                                        width="180"
                                        height="100%"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {(this.state.Invoicedata.ewaybillstatus ===
                                  "Generated" ||
                                  this.state.Invoicedata.ewaybillstatus ===
                                    "Cancelled") &&
                                this.state.ewaybilldata.ewbno !== undefined &&
                                this.state.Invoicedata.einvoicestatus ===
                                  "NA" ? (
                                  <div className="pb-3 col-md-12">
                                    <table className="float-left">
                                      <tbody>
                                        <tr>
                                          <td>E-Way bill no</td>
                                          <td>
                                            :&nbsp;
                                            {this.state.ewaybilldata.ewbno}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>E-Way bill Date</td>
                                          <td>
                                            :&nbsp;
                                            {moment(
                                              this.state.ewaybilldata.ewbdt
                                            ).format("DD-MM-yyyy HH:mm")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>E-Way bill valid till</td>
                                          <td>
                                            :&nbsp;
                                            {moment(
                                              this.state.ewaybilldata
                                                .ewbvalidtill
                                            ).format("DD-MM-yyyy HH:mm")}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="form-column col-md-6">
                                  <table className="float-left mb-3">
                                    <tbody>
                                      {this.state.pagename ===
                                      "Tax Invoice Details" ? (
                                        <tr>
                                          <td>Invoice Id</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            {this.state.Invoicedata.einvid ===
                                              null ||
                                            this.state.Invoicedata.einvid ===
                                              undefined
                                              ? this.state.Invoicedata.invid
                                              : this.state.Invoicedata.einvid}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      <tr>
                                        <td>Detl. Invoice Id</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.pagename ===
                                          "Tax Invoice Details"
                                            ? this.state.Invoicedata.custominvid
                                            : this.state.Invoicedata.invid}
                                        </td>
                                      </tr>
                                      {this.state.Invoicedata.customsoid ===
                                        null ||
                                      this.state.Invoicedata.customsoid ===
                                        undefined ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Order&nbsp;Id</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <Link
                                              to={{
                                                pathname: "/saleorderdetails",
                                              }}
                                              state={{
                                                id: this.state.Invoicedata.soid,
                                                pagename:
                                                  this.state.type ===
                                                  "Tax Invoice"
                                                    ? "Tax Invoice Details"
                                                    : "Proforma Invoice Details",
                                                type: this.state.type,
                                              }}
                                            >
                                              {
                                                this.state.Invoicedata
                                                  .customsoid
                                              }
                                            </Link>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Created By</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.createdbyname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Customer</td>
                                        <td data-tip data-for="custphno">
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.customer}
                                        </td>
                                      </tr>
                                      {this.state.Invoicedata.agent === null ||
                                      this.state.Invoicedata.agent ===
                                        undefined ||
                                      this.state.Invoicedata.agent === "" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Agent</td>
                                          <td data-tip data-for="agentphno">
                                            :&nbsp;&nbsp;
                                            {this.state.Invoicedata.agent}
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Invoice&nbsp;Date</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {moment(
                                            this.state.Invoicedata.createdAt
                                          ).format("DD-MM-yyyy")}
                                        </td>
                                      </tr>
                                      {this.state.Invoicedata.sotype ===
                                      "Services" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Org Unit</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            {this.state.Invoicedata.warehouse}
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>
                                          Total&nbsp;Invoice&nbsp;Amount&nbsp;
                                        </td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={
                                              this.state.Invoicedata
                                                .totalinvoicewtax
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      {this.state.pagename ===
                                        "Tax Invoice Details" &&
                                      this.state.Invoicedata.advanceadjusted !==
                                        null &&
                                      this.state.Invoicedata.advanceadjusted !==
                                        undefined ? (
                                        <tr>
                                          <td>Advance Adjusted Amount&nbsp;</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .advanceadjusted
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.pagename ===
                                        "Tax Invoice Details" &&
                                      this.state.Invoicedata.debitadjusted !==
                                        null &&
                                      this.state.Invoicedata.debitadjusted !==
                                        undefined ? (
                                        <tr>
                                          <td>Debit Adjusted &nbsp;</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .debitadjusted
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}

                                      {this.state.pagename ===
                                        "Tax Invoice Details" &&
                                      this.state.Invoicedata.openadjusted !==
                                        null &&
                                      this.state.Invoicedata.openadjusted !==
                                        undefined ? (
                                        <tr>
                                          <td>
                                            Opening balance adjusted &nbsp;
                                          </td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .openadjusted
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}

                                      {this.state.pagename ===
                                        "Tax Invoice Details" &&
                                      ((this.state.Invoicedata.debitadjusted !==
                                        null &&
                                        this.state.Invoicedata.debitadjusted !==
                                          undefined) ||
                                        (this.state.Invoicedata
                                          .advanceadjusted !== null &&
                                          this.state.Invoicedata
                                            .advanceadjusted !== undefined)) ? (
                                        <>
                                          <tr>
                                            <td>Payable&nbsp;Amount&nbsp;</td>
                                            <td>
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Invoicedata.payable
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.pagename ===
                                      "Tax Invoice Details" ? (
                                        <>
                                          <tr>
                                            <td>Received&nbsp;Amount&nbsp;</td>
                                            <td className="text-green">
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Invoicedata.paid
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Due&nbsp;Amount&nbsp;</td>
                                            <td className="text-red">
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Invoicedata.due +(this.state.Invoicedata.tcsreq?this.state.Invoicedata.tcsamount:0)
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        <tr>
                                          <td>Advance&nbsp;Amount&nbsp;</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .advanceamountreq
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      )}

                                      <tr>
                                        <td>Tax&nbsp;Type</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.taxtype}
                                          {this.state.Invoicedata.taxtype ===
                                          "GST"
                                            ? ` (${this.state.Invoicedata.gsttype})`
                                            : ""}
                                        </td>
                                      </tr>
                                      {this.state.Invoicedata.insurancereq ? (
                                        <tr>
                                          <td>Insurance</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .totalinsurance
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}

                                      {this.state.Invoicedata.tcsreq ? (<>
                                        <tr>
                                          <td>TCS</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata.tcsamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                            ({this.state.Invoicedata.tcs}% of 
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .tcsableamount
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                            )
                                          </td>
                                          </tr>
                                          <tr>
                                          <td>Total Inc TCS</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              displayType={"text"}
                                              value={this.state.Invoicedata.totalinvoicewtax + this.state.Invoicedata.tcsamount}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                        </> ) : (
                                        ""
                                      )}
                                      {this.state.pagename ===
                                        "Tax Invoice Details" &&
                                      this.state.Invoicedata.sotype !==
                                        "Services" &&
                                      this.state.Invoicedata.approvestatus ===
                                        "Approved" ? (
                                        <tr>
                                          <td>Delivery Status</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            {this.state.Invoicedata
                                              .ledgerentry === "Pending" ? (
                                              "Pending JE"
                                            ) : this.state.Invoicedata
                                                .deliverystatus ===
                                              "Pending" && (salesid === '2' || salesid === '3') && screens.includes("Delivery/Shipment Note") ? (
                                              <Link
                                                className="m-0 p-0"
                                                to={{
                                                  pathname: "/newdeliverynote",
                                                }}
                                                state={{
                                                  id: -1,
                                                  pagename: "New Delivery Note",
                                                  invid:
                                                    this.state.Invoicedata
                                                      .custominvid,
                                                }}
                                              >
                                                {
                                                  this.state.Invoicedata
                                                    .deliverystatus
                                                }
                                              </Link>
                                            ) : (
                                              this.state.Invoicedata
                                                .deliverystatus
                                            )}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.pagename ===
                                        "Tax Invoice Details" &&
                                      this.state.Invoicedata.approvestatus ===
                                        "Approved" ? (
                                        <tr>
                                          <td>Payment Status</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            {this.state.Invoicedata.payment}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group col-md-3">
                                  <table className="float-left">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Customer Details{" "}
                                          {this.state.pagename ===
                                          "Tax Invoice Details"
                                            ? "(Bill to)"
                                            : ""}
                                          ,
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.Invoicedata.customer}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.Invoicedata.custadress}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.Invoicedata.cityname}
                                          ,&nbsp;{this.state.Invoicedata.state}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;{this.state.Invoicedata.country}{" "}
                                          - {this.state.Invoicedata.zip}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.Invoicedata.custmail}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.Invoicedata.custphonecode}
                                          -{this.state.Invoicedata.custphno}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                {this.state.pagename ===
                                  "Tax Invoice Details" &&
                                this.state.Invoicedata.sotype !== "Services" &&
                                this.state.Invoicedata.dname !== "" &&
                                this.state.Invoicedata.dname !== undefined &&
                                this.state.Invoicedata.dname !== null ? (
                                  <div className="form-group col-md-3">
                                    <table className="float-left mb-3">
                                      <tbody>
                                        <tr>
                                          <td>Ship to,</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            &nbsp;
                                            {this.state.Invoicedata.dname}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            &nbsp;
                                            {this.state.Invoicedata.dhouseno},
                                            {this.state.Invoicedata.dareaname}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            &nbsp;
                                            {this.state.Invoicedata.dcity}
                                            ,&nbsp;
                                            {this.state.Invoicedata.dstate}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            &nbsp;
                                            {
                                              this.state.Invoicedata.dcountry
                                            } -{" "}
                                            {this.state.Invoicedata.dpincode}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            &nbsp;
                                            {this.state.Invoicedata.dphoneno}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-md-12">
                                {this.state.creditnoteslist.length > 0 ? (
                                  <TableContainer>
                                    <Typography>Credit Notes</Typography>
                                    <Table
                                      size="small"
                                      className="table-sm border"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className="text-center">
                                            ID
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Type
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Status
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.creditnoteslist.map(
                                          (e, idx) => (
                                            <TableRow key={idx}>
                                              <TableCell className="text-info cursorPointer text-center">
                                                {e.einvoicestatus !==
                                                "Pending" ? (
                                                  <Link
                                                    to={{
                                                      pathname:
                                                        "/customercreditnotedetails",
                                                    }}
                                                    state={{
                                                      creditnote: e,
                                                      invdata:
                                                        this.state.Invoicedata,
                                                      pagename:
                                                        "Customer Creditnote",
                                                    }}
                                                  >
                                                    {e.customid}
                                                  </Link>
                                                ) : (
                                                  e.customid
                                                )}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.type}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.einvoicestatus}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="form-group col-md-12">
                                {this.state.edebitnoteslist.length > 0 ? (
                                  <TableContainer>
                                    <Typography>Debit Notes</Typography>
                                    <Table
                                      size="small"
                                      className="table-sm border"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className="text-center">
                                            ID
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Type
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Status
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.edebitnoteslist.map(
                                          (e, idx) => (
                                            <TableRow key={idx}>
                                              <TableCell className="text-info cursorPointer text-center">
                                                {/* {e.einvoicestatus !==
                                                "Pending" ? (
                                                  <Link
                                                    to={{
                                                      pathname:
                                                        "/customercreditnotedetails",
                                                    }}
                                                    state={{
                                                      creditnote: e,
                                                      invdata:
                                                        this.state.Invoicedata,
                                                      pagename:
                                                        "Customer Creditnote",
                                                    }}
                                                  >
                                                    {e.id}
                                                  </Link>
                                                ) : (
                                                  e.customid
                                                )} */}
                                                {e.customid}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.type}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.einvoicestatus}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="form-group col-md-12"/>
                                {this.state.additionalchareslist.length > 0 ||
                                this.state.Invoicedata.insurancereq ? (
                                  <TableContainer className="my-2">
                                    <Typography>
                                      Additional Charges / Insurance
                                    </Typography>
                                    <Table
                                      className="table-sm border"
                                      size="small"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>
                                            Nature of charge
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Additional charge
                                          </TableCell>
                                          {this.state.Invoicedata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.Invoicedata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                            this.state.Invoicedata.gsttype ===
                                            "INTRASTATE" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  CGST
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  SGST
                                                </TableCell>
                                              </>
                                            ) : (
                                              <>
                                                <TableCell className="text-center">
                                                  IGST
                                                </TableCell>
                                              </>
                                            )
                                          ) : this.state.Invoicedata.taxtype ===
                                            "GST" ? (
                                            <>
                                              <TableCell className="text-center">
                                                GST
                                              </TableCell>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <TableCell className="text-center">
                                                VAT
                                              </TableCell>
                                            </>
                                          )}

                                          <TableCell className="text-center">
                                            Total Additional charge
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.additionalchareslist.map(
                                          (e, i) => (
                                            <TableRow key={i}>
                                              <TableCell>
                                                {e.natureofcharge}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={e.additionalcharges}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .taxtype === "NA" ? (
                                                ""
                                              ) : this.state.Invoicedata
                                                  .taxtype === "GST" &&
                                                ccountry === "India" &&
                                                this.state.Invoicedata
                                                  .gsttype === "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={(
                                                        e.additionalchargestaxamount /
                                                        2
                                                      ).toFixed(2)}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    (
                                                    {e.additionalchargestax / 2}
                                                    %)
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={(
                                                        e.additionalchargestaxamount /
                                                        2
                                                      ).toFixed(2)}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    (
                                                    {e.additionalchargestax / 2}
                                                    %)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      e.additionalchargestaxamount
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  (
                                                  {e.additionalchargestax.toFixed(
                                                    2
                                                  )}
                                                  %)
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    e.totaladditionalcharges
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                        {this.state.Invoicedata.insurancereq ? (
                                          <TableRow>
                                            <TableCell>Insurance</TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.Invoicedata
                                                    .insuranceamount
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>

                                            {this.state.Invoicedata.taxtype ===
                                            "NA" ? (
                                              ""
                                            ) : this.state.Invoicedata
                                                .taxtype === "GST" &&
                                              ccountry === "India" &&
                                              this.state.Invoicedata.gsttype ===
                                                "INTRASTATE" ? (
                                              <>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      (this.state.Invoicedata
                                                        .totalinsurance -
                                                        this.state.Invoicedata
                                                          .insuranceamount) /
                                                      2
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  (
                                                  {(
                                                    this.state.Invoicedata
                                                      .insurancetax / 2
                                                  ).toFixed(2)}
                                                  %)
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      (this.state.Invoicedata
                                                        .totalinsurance -
                                                        this.state.Invoicedata
                                                          .insuranceamount) /
                                                      2
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  (
                                                  {(
                                                    this.state.Invoicedata
                                                      .insurancetax / 2
                                                  ).toFixed(2)}
                                                  %)
                                                </TableCell>
                                              </>
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.Invoicedata
                                                      .totalinsurance -
                                                    this.state.Invoicedata
                                                      .insuranceamount
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                (
                                                {this.state.Invoicedata.insurancetax.toFixed(
                                                  2
                                                )}
                                                %)
                                              </TableCell>
                                            )}

                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.Invoicedata
                                                    .totalinsurance
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          </TableRow>
                                        ) : (
                                          ""
                                        )}
                                        <TableRow>
                                          <TableCell>Total</TableCell>
                                          {this.state.Invoicedata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.Invoicedata.taxtype ===
                                              "GST" &&
                                            ccountry === "India" &&
                                            this.state.Invoicedata.gsttype ===
                                              "INTRASTATE" ? (
                                            <TableCell></TableCell>
                                          ) : (
                                            ""
                                          )}
                                          <TableCell className="text-right"></TableCell>

                                          <TableCell className="text-right"></TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Invoicedata
                                                  .totaladditionalchares +
                                                this.state.Invoicedata
                                                  .totalinsurance
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  ""
                                )}
                              
                              <div className="form-group col-md-12"></div>
                              {this.state.saleorderitems.length === 0 ? (
                                ""
                              ) : (
                                <TableContainer className="mt-2">
                                  <Typography>Products</Typography>
                                  <Table
                                    aria-label="collapsible table"
                                    className="table-sm border"
                                    size="small"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell />
                                        <TableCell className="text-center">
                                          Item Name
                                        </TableCell>
                                        {this.state.Invoicedata.taxtype ===
                                        "NA" ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            HSN
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        {currency ===
                                        this.state.Invoicedata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price(
                                            {this.state.Invoicedata.currency})
                                          </TableCell>
                                        )}
                                        {currency ===
                                        this.state.Invoicedata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price Variance (%)
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Type
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Discount
                                        </TableCell>
                                        {this.state.Invoicedata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Discount (
                                            {this.state.Invoicedata.currency})
                                          </TableCell>
                                        )}
                                        {/* <TableCell className="text-center">
                                          Insurance
                                        </TableCell>
                                        {this.state.Invoicedata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Insurance (
                                            {this.state.Invoicedata.currency})
                                          </TableCell>
                                        )} */}
                                        <TableCell className="text-center">
                                          Total{" "}
                                        </TableCell>
                                        {this.state.Invoicedata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total (
                                            {this.state.Invoicedata.currency})
                                          </TableCell>
                                        )}
                                        {this.state.Invoicedata.taxtype ===
                                        "NA" ? (
                                          ""
                                        ) : (
                                          <>
                                            {this.state.Invoicedata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                              this.state.Invoicedata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST
                                                  </TableCell>
                                                  {this.state.Invoicedata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      <TableCell className="text-center">
                                                        CGST(
                                                        {
                                                          this.state.Invoicedata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        SGST(
                                                        {
                                                          this.state.Invoicedata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    IGST
                                                  </TableCell>
                                                  {this.state.Invoicedata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      IGST(
                                                      {
                                                        this.state.Invoicedata
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              )
                                            ) : this.state.Invoicedata
                                                .taxtype === "GST" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  GST
                                                </TableCell>
                                                {this.state.Invoicedata
                                                  .currency === currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    GST(
                                                    {
                                                      this.state.Invoicedata
                                                        .currency
                                                    }
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <TableCell className="text-center">
                                                  VAT
                                                </TableCell>
                                                {this.state.Invoicedata
                                                  .currency === currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    VAT(
                                                    {
                                                      this.state.Invoicedata
                                                        .currency
                                                    }
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                            <TableCell className="text-center">
                                              Total Price
                                            </TableCell>
                                            {this.state.Invoicedata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                Total Price(
                                                {
                                                  this.state.Invoicedata
                                                    .currency
                                                }
                                                )
                                              </TableCell>
                                            )}
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.saleorderitems.map(
                                        (row, index) => (
                                          <React.Fragment key={index}>
                                            <TableRow>
                                              <TableCell>
                                                <IconButton
                                                  aria-label="expand row"
                                                  size="small"
                                                  onClick={() =>
                                                    this.setState({
                                                      open: !this.state.open,
                                                    })
                                                  }
                                                >
                                                  {this.state.open ? (
                                                    <KeyboardArrowUpIcon />
                                                  ) : (
                                                    <KeyboardArrowDownIcon />
                                                  )}
                                                </IconButton>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.name}
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .taxtype === "NA" ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.hsn}
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.price}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {currency ===
                                              this.state.Invoicedata
                                                .currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.price *
                                                      this.state.Invoicedata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {currency ===
                                              this.state.Invoicedata
                                                .currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.pricevariance}%
                                                </TableCell>
                                              )}
                                              <TableCell className="text-center">
                                                {row.salesenum}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.quantity}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.uom}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.discountamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.discount}%)
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .currency === currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.discountamount *
                                                      this.state.Invoicedata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  &nbsp;({row.discount}%)
                                                </TableCell>
                                              )}

                                              {/* <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.insuranceamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>&nbsp;({row.insurance}%)
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .currency === currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.insuranceamount *
                                                      this.state.Invoicedata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>&nbsp;({row.insurance}%)
                                                </TableCell>
                                              )} */}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.totalprice}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .currency === currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.totalprice *
                                                      this.state.Invoicedata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {this.state.Invoicedata
                                                .taxtype === "NA" ? (
                                                ""
                                              ) : (
                                                <>
                                                  {this.state.Invoicedata
                                                    .taxtype === "GST" &&
                                                  this.state.Invoicedata
                                                    .gsttype ===
                                                    "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount * 0.5
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax / 2}%)
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount / 2
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax / 2}%)
                                                      </TableCell>
                                                      {this.state.Invoicedata
                                                        .currency ===
                                                      currency ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <TableCell className="text-right">
                                                            <NumericFormat
                                                              displayType={
                                                                "text"
                                                              }
                                                              value={
                                                                (row.taxamount /
                                                                  2) *
                                                                this.state
                                                                  .Invoicedata
                                                                  .curconversionrate
                                                              }
                                                              decimalScale={2}
                                                              fixedDecimalScale={
                                                                true
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                true
                                                              }
                                                              thousandsGroupStyle={
                                                                thousandsdisplay
                                                              }
                                                            ></NumericFormat>
                                                            &nbsp;({row.tax / 2}
                                                            %)
                                                          </TableCell>
                                                          <TableCell className="text-right">
                                                            <NumericFormat
                                                              displayType={
                                                                "text"
                                                              }
                                                              value={
                                                                (row.taxamount /
                                                                  2) *
                                                                this.state
                                                                  .Invoicedata
                                                                  .curconversionrate
                                                              }
                                                              decimalScale={2}
                                                              fixedDecimalScale={
                                                                true
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                true
                                                              }
                                                              thousandsGroupStyle={
                                                                thousandsdisplay
                                                              }
                                                            ></NumericFormat>
                                                            &nbsp;({row.tax / 2}
                                                            %)
                                                          </TableCell>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={row.taxamount}
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax}%)
                                                      </TableCell>
                                                      {this.state.Invoicedata
                                                        .currency ===
                                                      currency ? (
                                                        ""
                                                      ) : (
                                                        <TableCell className="text-right">
                                                          <NumericFormat
                                                            displayType={"text"}
                                                            value={
                                                              row.taxamount *
                                                              this.state
                                                                .Invoicedata
                                                                .curconversionrate
                                                            }
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                              true
                                                            }
                                                            disabled={true}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            thousandsGroupStyle={
                                                              thousandsdisplay
                                                            }
                                                          ></NumericFormat>
                                                          &nbsp;({row.tax}%)
                                                        </TableCell>
                                                      )}
                                                    </>
                                                  )}
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.totalpricewtax}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.Invoicedata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.totalpricewtax *
                                                          this.state.Invoicedata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                            </TableRow>
                                            <TableRow>
                                              <TableCell
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingTop: 0,
                                                }}
                                                colSpan={12}
                                              >
                                                <Collapse
                                                  in={this.state.open}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <Box margin={1}>
                                                    <Table size="small">
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center">
                                                            Brand
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Colour
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Size
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Thickness
                                                          </TableCell>
                                                          {/* <TableCell align="center">UOM</TableCell> */}
                                                          <TableCell align="center">
                                                            TotalMtrRoll
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Description
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        <TableRow>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                          >
                                                            {row.brand}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.colour ===
                                                              undefined ||
                                                            row.colour === ""
                                                              ? "NA"
                                                              : row.colour}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.size ===
                                                              undefined ||
                                                            row.size === ""
                                                              ? "NA"
                                                              : row.size}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.thickness ===
                                                              undefined ||
                                                            row.thickness === ""
                                                              ? "NA"
                                                              : row.thickness}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.totalmtrroll ===
                                                              undefined ||
                                                            row.totalmtrroll ===
                                                              "" ||
                                                            row.totalmtrroll ===
                                                              null
                                                              ? "NA"
                                                              : row.totalmtrroll}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.description ===
                                                              undefined ||
                                                            row.description ===
                                                              ""
                                                              ? "NA"
                                                              : row.description}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </Box>
                                                </Collapse>
                                              </TableCell>
                                            </TableRow>
                                          </React.Fragment>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                              <div className="form-group col-md-12"></div>
                              {this.state.invoiceservicelist.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  <TableContainer>
                                    <Typography>Services</Typography>
                                    <Table className="table-sm border mb-2">
                                      <TableHead className="text-sm">
                                        <TableRow
                                          style={{ textAlign: "center" }}
                                        >
                                          <TableCell className="text-center">
                                            Sl. No.
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Service
                                          </TableCell>
                                          <TableCell className="text-center">
                                            HSN/SAC Code
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Price
                                          </TableCell>
                                          {this.state.Invoicedata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.Invoicedata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                            this.state.Invoicedata.gsttype ===
                                            "INTRASTATE" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  CGST
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  SGST
                                                </TableCell>
                                              </>
                                            ) : (
                                              <>
                                                <TableCell className="text-center">
                                                  IGST
                                                </TableCell>
                                              </>
                                            )
                                          ) : this.state.Invoicedata.taxtype ===
                                            "GST" ? (
                                            <>
                                              <TableCell className="text-center">
                                                GST
                                              </TableCell>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <TableCell className="text-center">
                                                VAT
                                              </TableCell>
                                            </>
                                          )}
                                          <TableCell className="text-center">
                                            TotalPrice
                                          </TableCell>
                                          {this.state.invoiceservicelist.find(
                                            (e) => e
                                          ).contracttype === null ? (
                                            ""
                                          ) : (
                                            <>
                                              {" "}
                                              <TableCell className="text-center">
                                                Contract type
                                              </TableCell>
                                              {this.state.invoiceservicelist
                                                .map((e) => e.contracttype)
                                                .toLocaleString() ===
                                              "Recurring" ? (
                                                <TableCell className="text-center">
                                                  Recurring Type
                                                </TableCell>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody className="text-sm">
                                        {this.state.invoiceservicelist.map(
                                          (data, index) => (
                                            <TableRow
                                              key={index}
                                              className="text-center"
                                            >
                                              <TableCell className="text-center">
                                                {index + 1}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.service}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.servicecode}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                {" "}
                                                <NumericFormat
                                                  className="pr-3"
                                                  displayType={"text"}
                                                  value={data.servicecost}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>

                                              {this.state.Invoicedata
                                                .taxtype === "NA" ? (
                                                ""
                                              ) : this.state.Invoicedata
                                                  .taxtype === "GST" &&
                                                ccountry === "India" &&
                                                this.state.Invoicedata
                                                  .gsttype === "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (data.servicetotalwtax -
                                                          data.servicecost) /
                                                        2
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    (
                                                    {(
                                                      (((data.servicetotalwtax -
                                                        data.servicecost) /
                                                        data.servicecost) *
                                                        100) /
                                                      2
                                                    ).toFixed(2)}
                                                    %)
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (data.servicetotalwtax -
                                                          data.servicecost) /
                                                        2
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    (
                                                    {(
                                                      (((data.servicetotalwtax -
                                                        data.servicecost) /
                                                        data.servicecost) *
                                                        100) /
                                                      2
                                                    ).toFixed(2)}
                                                    %)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      data.servicetotalwtax -
                                                      data.servicecost
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  (
                                                  {(
                                                    ((data.servicetotalwtax -
                                                      data.servicecost) /
                                                      data.servicecost) *
                                                    100
                                                  ).toFixed(2)}
                                                  %)
                                                </TableCell>
                                              )}

                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  className="pr-3"
                                                  displayType={"text"}
                                                  value={data.servicetotalwtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.invoiceservicelist.find(
                                                (e) => e
                                              ).contracttype === null ? (
                                                ""
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    {data.contracttype}
                                                  </TableCell>
                                                  {this.state.invoiceservicelist.find(
                                                    (e) => e
                                                  ).contracttype ===
                                                  "Recurring" ? (
                                                    <TableCell className="text-center">
                                                      {this.state.invoiceservicelist.map(
                                                        (e) => e.recurringtype
                                                      )}
                                                    </TableCell>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              )}
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  {this.state.invoiceservicelist.find((e) => e)
                                    .contracttype === "Recurring" ||
                                  this.state.invoiceservicelist.find((e) => e)
                                    .contracttype === null ||
                                  this.state.Invoicedata.invoiceservicelist.find(
                                    (e) => e
                                  ).invoicemilestones === null ? (
                                    ""
                                  ) : (
                                    <>
                                      {this.state.Invoicedata.invoiceservicelist.map(
                                        (e) => e.invoicemilestones
                                      ).length === 0 ? (
                                        ""
                                      ) : (
                                        <TableContainer>
                                          <Typography>Milestones</Typography>
                                          <Table className="table-sm mt-4">
                                            <TableHead className="text-sm">
                                              <TableRow
                                                style={{ textAlign: "center" }}
                                              >
                                                <TableCell className="text-center">
                                                  Name
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Expected Date
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Percentage
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Amount
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {this.state.Invoicedata.invoiceservicelist
                                                .map((e) => e.invoicemilestones)
                                                .map((data, index) => (
                                                  <TableRow key={index}>
                                                    <TableCell className="text-center">
                                                      {data.name}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      {moment(
                                                        data.expecteddate
                                                      ).format("DD-MM-YYYY")}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      {data.percentage} %
                                                    </TableCell>
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        className="pr-3"
                                                        displayType={"text"}
                                                        value={
                                                          data.totalpricewtax
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  </TableRow>
                                                ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {this.state.pagename === "Tax Invoice Details" &&
                              (this.state.Invoicedata.samedaydelivery ===
                                "True" ||
                                this.state.Invoicedata.ewaybillstatus !==
                                  "Pending") &&
                              this.state.Invoicedata.sotype !== "Services" ? (
                                <>
                                  <TableContainer>
                                    <Typography>
                                      Transportation Details
                                    </Typography>
                                    <Table className="table-sm mb-4 border">
                                      <TableHead className="text-sm">
                                        <TableRow
                                          style={{ textAlign: "center" }}
                                        >
                                          <TableCell className="text-center">
                                            Transporter Name
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Transporter Id
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Vehicel No.
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Approximate Distance(Km)
                                          </TableCell>
                                          {/* <TableCell className="text-center">
                                          Mode of Transportation
                                        </TableCell> */}
                                          <TableCell className="text-center">
                                            Vehicle Type
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Transaction Type
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Transportation Mode
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Supply Type
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell className="text-center">
                                            {this.state.transportername}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {
                                              this.state.Invoicedata
                                                .transporterid
                                            }
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {this.state.Invoicedata.vehiclenum}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {this.state.Invoicedata.distance}
                                          </TableCell>
                                          {/* <TableCell className="text-center">
                                          {this.state.Invoicedata.transmode}
                                          // Mode of Transportation
                                        </TableCell> */}
                                          <TableCell className="text-center">
                                            {this.state.Invoicedata
                                              .vehicletype === "R"
                                              ? "Regular"
                                              : "ODC"}
                                            {/* Vehicle Type */}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {
                                              this.state.Invoicedata
                                                .transactiontype
                                            }
                                            {/* Transaction Type */}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {
                                              this.state.Invoicedata
                                                .transportationmode
                                            }
                                            {/* Transportation Mode */}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {this.state.Invoicedata
                                              .supplytype === "I"
                                              ? "Inward"
                                              : "Outward"}
                                            {/* Supply Type */}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  {this.state.einvoicedata !== undefined &&
                                  this.state.einvoicedata !== "" &&
                                  this.state.einvoicedata !== null &&
                                  this.state.Invoicedata.updtvehiclenum !==
                                    null ? (
                                    <TableContainer>
                                      <Typography>
                                        Updated Transportation Details
                                      </Typography>
                                      <Table className="table-sm mb-4">
                                        <TableHead className="text-sm">
                                          <TableRow
                                            style={{ textAlign: "center" }}
                                          >
                                            <TableCell className="text-center">
                                              From
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Vehicel No.
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Vehicle Type
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Transaction Type
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Transportation Mode
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Supply Type
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell className="text-center">
                                              {
                                                this.state.einvoicedata.invoice
                                                  .updtfrom
                                              }
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {
                                                this.state.einvoicedata.invoice
                                                  .updtvehiclenum
                                              }
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {this.state.einvoicedata.invoice
                                                .updtvehicletype === "R"
                                                ? "Regular"
                                                : "ODC"}
                                              {/* Vehicle Type */}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {
                                                this.state.einvoicedata.invoice
                                                  .transactiontype
                                              }
                                              {/* Transaction Type */}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {
                                                this.state.einvoicedata.invoice
                                                  .updttransportationmode
                                              }
                                              {/* Transportation Mode */}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {this.state.einvoicedata.invoice
                                                .supplytype === "I"
                                                ? "Inward"
                                                : "Outward"}
                                              {/* Supply Type */}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}

                              <div className="form-group col-md-12">
                                  <label>Remarks : </label>
                                  <span>&nbsp;{this.state.Invoicedata.invoiceremarks}</span>
                              </div>

                              {this.state.showrem ? (
                                <>
                                  <fieldset className="form-group col-md-3">
                                    <FormControl
                                      className="col-md-12"
                                      variant="standard"
                                    >
                                      <InputLabel id="demo-simple-select-standard-label">
                                        Reason for Cancellation
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.reasonCodeforcnl}
                                        onChange={(e) =>
                                          this.setState({
                                            reasonCodeforcnl: e.target.value,
                                          })
                                        }
                                      >
                                        {this.state.ResforCancellation.map(
                                          (option, i) => {
                                            return (
                                              <MenuItem
                                                key={i}
                                                value={option.value}
                                              >
                                                {option.name}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </FormControl>
                                  </fieldset>
                                  <fieldset className="form-group col-md-12  mt-3 mb-5">
                                    <TextField
                                      name="remarks"
                                      className="form-control"
                                      multiline
                                      rows={1}
                                      label="Add Remarks"
                                      variant="outlined"
                                      value={this.state.remarks}
                                      error={this.state.remarks === ""}
                                      onChange={(e) =>
                                        this.setState({
                                          remarks: e.target.value,
                                        })
                                      }
                                    />
                                  </fieldset>
                                </>
                              ) : (
                                ""
                              )}

                              {this.state.showtrans ? (
                                <>
                                  <fieldset className="form-group col-md-3 ">
                                    <TextField
                                      className="form-control"
                                      type="text"
                                      name="transporterid"
                                      label="Transporter Id"
                                      onChange={(e) =>
                                        this.setState({
                                          transporterid: e.target.value,
                                          err: false,
                                        })
                                      }
                                      error={
                                        this.state.transporterid === "" &&
                                        this.state.err
                                      }
                                      helperText={
                                        this.state.transporterid === "" &&
                                        this.state.err
                                          ? "Empty field!"
                                          : " "
                                      }
                                      variant="standard"
                                    />
                                  </fieldset>
                                  <button
                                    className="btn btn-sm hovbuttonColour mr-1"
                                    onClick={this.handlesChangeTRNS}
                                  >
                                    Submit
                                  </button>
                                  <button
                                    className="btn btn-sm deletebutton mr-1"
                                    onClick={this.handleclosechangeTRNS}
                                  >
                                    <i className="fa fa-times m-0" /> Cancel
                                  </button>
                                </>
                              ) : (
                                ""
                              )}

                              {salesid === "3" || salesid === "2" ? (
                                  <>
                                      
                                      {this.state.pagename ==="Tax Invoice Details" && this.state.Invoicedata.approvestatus ==="Approved" && this.state.Invoicedata.ledgerentry ==="Pending" && this.state.Invoicedata.autojre ==="TRUE"
                                  && !this.state.Invoicedata.openadjusted && !this.state.Invoicedata.debitadjusted? 
                                    <button className="btn btn-sm hovbuttonColour mr-1" onClick={() =>this.handleJournalEntry()}>
                                                  <i className="fa fa-check  m-0" />{" "}
                                                  JournalEntry
                                                </button>:""}


                                  {this.state.pagename ===
                                  "Tax Invoice Details" ? (
                                    <>
                                      {this.state.Invoicedata.approvestatus !==
                                        "Approved" ||
                                      this.state.Invoicedata.einvoicestatus ===
                                        "Pending" ? (
                                        this.state.Invoicedata.einvid ===
                                        null ? (
                                          <>
                                            {this.state.Invoicedata
                                              .approvestatus === "Pending" ? (
                                              <>
                                              {salesid === "3"?
                                                <button
                                                  className="btn btn-sm hovbuttonColour mr-1"
                                                  onClick={() =>
                                                    this.handleApprove(0)
                                                  }
                                                >
                                                  <i className="fa fa-check  m-0" />{" "}
                                                  Approve
                                                </button>
                                                :""}

                                                {this.state.Invoicedata
                                                  .sotype === "Services" ||
                                                this.state.pagename !==
                                                  "Tax Invoice Details" ? (
                                                  ""
                                                ) : (
                                                  <button
                                                    className="btn btn-sm btn-warning mr-1 text-white"
                                                    onClick={() =>
                                                      this.handleApprove(1)
                                                    }
                                                  >
                                                    <i className="fa fa-rotate-right  m-0" />{" "}
                                                    Revise Invocie
                                                  </button>
                                                )}
                                              </>
                                            ) : this.state.pagename ===
                                              "Tax Invoice Details" ? (
                                              <Link
                                                className="btn btn-sm hovbuttonColour mr-1"
                                                onClick={() =>
                                                  this.setState({
                                                    showfields: true,
                                                  })
                                                }
                                                to={{
                                                  pathname: "/newsalesinvoice",
                                                }}
                                                state={{
                                                  id: this.state.Invoicedata
                                                    .invid,
                                                  pagename: "New Invoice",
                                                }}
                                              >
                                                <i className="fa fa-edit"></i>{" "}
                                                Edit Invoice
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                            <button
                                              className="btn btn-sm deletebutton mr-1"
                                              onClick={this.handleDeleteInvoice}
                                            >
                                              <i className="fa fa-times  m-0" />{" "}
                                              Delete Invoice
                                            </button>
                                          </>
                                        ) : (
                                          <button
                                            className="btn btn-sm hovbuttonColour mr-1"
                                            onClick={this.Featcheinvoice}
                                          >
                                            <i className="fas fa-file-invoice m-0"></i>{" "}
                                            Fetch E-invoice
                                          </button>
                                        )
                                      ) : this.state.pagename ===
                                          "Tax Invoice Details" &&
                                        this.state.Invoicedata.status ===
                                          "Processing" &&
                                        this.state.Invoicedata
                                          .deliverystatus === "Pending" ? (
                                        <>
                                          {(this.state.Invoicedata
                                            .ewaybillstatus === "Pending" ||
                                            this.state.Invoicedata
                                              .ewaybillstatus ===
                                              "Cancelled") &&
                                          (this.state.Invoicedata
                                            .einvoicestatus === "Generated" ||
                                            this.state.Invoicedata
                                              .einvoicestatus === "NA") ? (
                                            // && (this.state.ewaybilldata.ewbno === null|| this.state.ewaybilldata.ewbno === undefined)
                                            <button
                                              className="btn btn-sm hovbuttonColour mr-1"
                                              onClick={() =>
                                                this.setState({
                                                  showfields: true,
                                                })
                                              }
                                            >
                                              <i className="fas fa-file-invoice m-0"></i>{" "}
                                              Generate E-way bill
                                            </button>
                                          ) : this.state.Invoicedata
                                              .ewaybillstatus === "Generated" &&
                                            this.state.ewaybilldata.ewbno !==
                                              null &&
                                            this.state.ewaybilldata.ewbno !==
                                              undefined ? (
                                            <>
                                              {this.state.showtrans ? (
                                                ""
                                              ) : (
                                                <>
                                                  {moment().isAfter(
                                                    moment(
                                                      this.state.ewaybilldata
                                                        .ewbvalidtill
                                                    ).subtract(8, "hours")
                                                  ) &&
                                                  moment().isBefore(
                                                    moment(
                                                      this.state.ewaybilldata
                                                        .ewbvalidtill
                                                    ).add(8, "hours")
                                                  ) ? (
                                                    <button
                                                      className="btn btn-sm hovbuttonColour mr-1"
                                                      onClick={
                                                        this
                                                          .handleshowEWBExtnOpen
                                                      }
                                                    >
                                                      <i className="fas fa-file-invoice m-0"></i>{" "}
                                                      Extend E-way bill
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <button
                                                    className="btn btn-sm hovbuttonColour mr-1"
                                                    onClick={
                                                      this.handleshowChgVel
                                                    }
                                                  >
                                                    <i className="fas fa-truck m-0"></i>{" "}
                                                    Change Vehicle
                                                  </button>

                                                  <button
                                                    className="btn btn-sm hovbuttonColour mr-1"
                                                    onClick={
                                                      this.handleshowchangeTRNS
                                                    }
                                                  >
                                                    <i className="fa fa-truck-moving"></i>{" "}
                                                    Update Transporter
                                                  </button>

                                                  <button
                                                    className="btn btn-sm deletebutton mr-1"
                                                    onClick={
                                                      this
                                                        .handleTerminateEwaybill
                                                    }
                                                  >
                                                    <i className="fa fa-times  m-0" />{" "}
                                                    Terminate/Cancel E-way bill
                                                  </button>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.Invoicedata
                                            .einvoicestatus === "Generated" &&
                                          (this.state.Invoicedata
                                            .ewaybillstatus === "Pending" ||
                                            this.state.Invoicedata
                                              .ewaybillstatus ===
                                              "Cancelled") &&
                                          moment().isBefore(
                                            moment(
                                              this.state.einvoicedata.ackDt
                                            ).add(1, "d")
                                          ) ? (
                                            <button
                                              className="btn btn-sm deletebutton mr-1"
                                              onClick={
                                                this.handleTerminateEInvoice
                                              }
                                            >
                                              <i className="fa fa-times  m-0" />{" "}
                                              Terminate/Cancel E-invoice
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.Invoicedata.payment ===
                                            "Pending" &&
                                          (this.state.Invoicedata
                                            .einvoicestatus === "Cancelled" ||
                                            this.state.Invoicedata
                                              .einvoicestatus === "NA") ? (
                                            <button
                                              className="btn btn-sm deletebutton mr-1"
                                              onClick={
                                                this.handleTerminateInvoice
                                              }
                                            >
                                              <i className="fa fa-times  m-0" />{" "}
                                              Terminate/Cancel Invoice
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {this.state.Invoicedata.approvestatus ===
                                      "Pending" ? (
                                        <>
                                        {salesid === "3"?
                                          <button
                                            className="btn btn-sm hovbuttonColour mr-1"
                                            onClick={() =>
                                              this.handleApprove(0)
                                            }
                                          >
                                            <i className="fa fa-check  m-0" />{" "}
                                            Approve
                                          </button>
                                          :""}
                                          <button
                                            className="btn btn-sm deletebutton mr-1"
                                            onClick={this.handleDeleteInvoice}
                                          >
                                            <i className="fa fa-times  m-0" />{" "}
                                            Delete Invoice
                                          </button>

                                          {this.state.Invoicedata.sotype ===
                                            "Services" ||
                                          this.state.pagename !==
                                            "Tax Invoice Details" ? (
                                            ""
                                          ) : (
                                            <button
                                              className="btn btn-sm btn-warning mr-1 text-white"
                                              onClick={() =>
                                                this.handleApprove(1)
                                              }
                                            >
                                              <i className="fa fa-rotate-right  m-0" />{" "}
                                              Revise Invocie
                                            </button>
                                          )}
                                        </>
                                      ) : this.state.pagename ===
                                        "Tax Invoice Details" ? (
                                        <Link
                                          className="btn btn-sm hovbuttonColour mr-1"
                                          onClick={() =>
                                            this.setState({ showfields: true })
                                          }
                                          to={{ pathname: "/newsalesinvoice" }}
                                          state={{
                                            id: this.state.Invoicedata.invid,
                                            pagename: "New Invoice",
                                          }}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                          Invoice
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}

                                  {(this.state.Invoicedata.einvoicestatus ===
                                    "NA" ||
                                    this.state.Invoicedata.einvoicestatus ===
                                      "Generated") &&
                                  this.state.pagename ===
                                    "Tax Invoice Details" &&
                                  this.state.Invoicedata.approvestatus ===
                                    "Approved" ? (
                                    <>
                                      <Link
                                        className="btn btn-sm hovbuttonColour mr-1"
                                        to={{ pathname: "/customercreditnote" }}
                                        state={{
                                          id: this.state.Invoicedata.invid,
                                          customer:
                                            this.state.Invoicedata.customer,
                                          custid: this.state.Invoicedata.custid,
                                          cnlist:
                                            this.state.creditnoteslist.filter(
                                              (e) =>
                                                e.ackno === null ||
                                                e.ackno === undefined
                                            ),
                                          invdata: this.state.Invoicedata,
                                          pagename: "Customer Creditnote",
                                        }}
                                      >
                                        New Credit Note
                                      </Link>

                                      <Link
                                        className="btn btn-sm hovbuttonColour mr-1"
                                        to={{ pathname: "/customerdebitnote" }}
                                        state={{
                                          id: this.state.Invoicedata.invid,
                                          customer:
                                            this.state.Invoicedata.customer,
                                          custid: this.state.Invoicedata.custid,
                                          invdata: this.state.Invoicedata,
                                          pagename: "Customer Debitnote",
                                        }}
                                      >
                                        New Debit Note
                                      </Link>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                                          {this.state.Invoicedata.due<0.09 && this.state.Invoicedata.due !== 0 ?<button
                                              className="btn btn-sm btn-warning mr-1 text-white"
                                              onClick={() =>
                                                this.handleCloseInvoice()
                                              }
                                            >Close Invoice</button>:""}

                          {/* E-mail modal */}
                          <Modal
                            show={this.state.show}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              <b>Mail</b>
                            </Modal.Header>
                            <div className="form-row mt-5 ml-5">
                              <fieldset className="form-group col-md-4">
                                <label htmlFor="btn-upload">
                                  <input
                                    className="btn-choose"
                                    id="btn-upload"
                                    name="btn-upload"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={this.selectFile}
                                  />
                                  <Button
                                    className="btn-close"
                                    variant="outlined"
                                    type="upload"
                                    component="span"
                                  >
                                    <span className="text-blue">
                                      Select File to Upload
                                    </span>
                                  </Button>
                                </label>
                              </fieldset>
                              <fieldset className="form-group col-md-3">
                                <ul className="list-group">
                                  {selectedFiles && selectedFiles.length > 0
                                    ? selectedFiles[0].name
                                    : null}
                                </ul>
                              </fieldset>

                              <fieldset className="form-group col-md-3">
                                <Button
                                  className="btn-upload"
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                  disabled={!selectedFiles}
                                  onClick={this.upload}
                                >
                                  Upload
                                </Button>
                              </fieldset>

                              <div className="form-group col-md-9">
                                <Typography
                                  variant="subtitle2"
                                  className={`upload-message ${
                                    isError ? "error" : ""
                                  }`}
                                >
                                  {message === "Could not upload the file!" ? (
                                    <span className="text-red">{message}</span>
                                  ) : (
                                    <span className="text-green">
                                      {message}
                                    </span>
                                  )}
                                  {/* <span className="text-red">{message}</span> } */}
                                </Typography>
                              </div>
                              <div className="form-group col-md-9">
                                <button
                                  className="btn btn-sm hovbuttonColour float-center"
                                  onClick={this.handleSendMail}
                                >
                                  Send{" "}
                                  <i
                                    className="fa fa-paper-plane"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>

                            <Modal.Body></Modal.Body>
                          </Modal>

                          {/* E-way bill modal */}
                          <Modal
                            show={this.state.showfields}
                            onHide={this.handleEwaybillClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              <b>Transportation Details :</b>
                            </Modal.Header>
                            <Modal.Body>
                              <>
                                <Formik
                                  initialValues={{ transporterid }}
                                  validateOnChange={false}
                                  validate={this.validate}
                                  onSubmit={this.onSubmit}
                                  enableReinitialize={true}
                                >
                                  {({ setFieldValue, values }) => (
                                    <Form className="form-group row">
                                      <fieldset className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="vehiclenum"
                                          label="Vehicel No."
                                          onChange={(e) =>
                                            this.setState({
                                              vehiclenum: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="distance"
                                          label="Approximate Distance(Km)"
                                          onChange={(e) =>
                                            this.setState({
                                              distance: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.distance === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.distance === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Mode of Transportation
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.transmode}
                                            onChange={(e) =>
                                              this.setState({
                                                transmode: e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.deliverymethods.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Vehicle Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.vehicletype}
                                            onChange={(e) =>
                                              this.setState({
                                                vehicletype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value="R">
                                              Regular
                                            </MenuItem>
                                            <MenuItem value="O">ODC</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transaction Types{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.transactiontype}
                                            onChange={(e) =>
                                              this.setState({
                                                transactiontype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={1}>
                                              Regular
                                            </MenuItem>
                                            <MenuItem value={2}>
                                              Bill To - Ship To
                                            </MenuItem>
                                            <MenuItem value={3}>
                                              Bill From - Dispatch From
                                            </MenuItem>
                                            <MenuItem value={4}>
                                              Combination of 2 and 3
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transportation Mode{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              this.state.transportationmode
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                transportationmode:
                                                  e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"1"}>
                                              Road
                                            </MenuItem>
                                            <MenuItem value={"2"}>
                                              Rail
                                            </MenuItem>
                                            <MenuItem value={"3"}>Air</MenuItem>
                                            <MenuItem value={"4"}>
                                              Ship
                                            </MenuItem>
                                            <MenuItem value={"5"}>
                                              inTransit
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-4">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Supply Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.supplytype}
                                            onChange={(e) =>
                                              this.setState({
                                                supplytype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"I"}>
                                              Inward
                                            </MenuItem>
                                            <MenuItem value={"O"}>
                                              Outward
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      <fieldset className="form-group col-md-12" />

                                      <fieldset className="form-group col-md-12 mt-2 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.transidreq}
                                                onChange={() =>
                                                  this.setState({
                                                    transidreq:
                                                      !this.state.transidreq,
                                                    transporterid: null,
                                                    transportername: null,
                                                  })
                                                }
                                                name="transidreq"
                                                color="primary"
                                              />
                                            }
                                            label="Add transporter details for Part A?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      {this.state.transidreq ? (
                                        <>
                                          <div className="col-md-12">
                                            <fieldset
                                              className={`form-group col-md-4  btn shadow-sm text-center ${
                                                this.state
                                                  .transporterselectiontype ===
                                                false
                                                  ? "borderedbutton2"
                                                  : " hovbuttonColour"
                                              }`}
                                              disabled={
                                                this.state
                                                  .transporterselectiontype ===
                                                false
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  transporterselectiontype: false,
                                                  transportername: "",
                                                  transporterid: "",
                                                })
                                              }
                                            >
                                              Enter Manually
                                            </fieldset>
                                            <fieldset
                                              className={`form-group col-md-4 shadow-sm  text-center btn ${
                                                this.state
                                                  .transporterselectiontype ===
                                                true
                                                  ? "borderedbutton2"
                                                  : " hovbuttonColour"
                                              }`}
                                              disabled={
                                                this.state
                                                  .transporterselectiontype ===
                                                true
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  transporterselectiontype: true,
                                                  transportername: "",
                                                  transporterid: "",
                                                })
                                              }
                                            >
                                              Select
                                            </fieldset>
                                          </div>
                                          {this.state
                                            .transporterselectiontype ===
                                          true ? (
                                            <>
                                              <fieldset className="form-group col-md-4">
                                                <Autocomplete
                                                  id="subaccount"
                                                  className="subacc-select"
                                                  name="transportername"
                                                  options={
                                                    this.state.transporterslist
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.transname
                                                  }
                                                  value={
                                                    values.transname
                                                      ? undefined
                                                      : undefined
                                                  }
                                                  onChange={
                                                    this.handleTransporterChange
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      className="form-control"
                                                      type="text"
                                                      name="transportername"
                                                      value={
                                                        this.state
                                                          .transportername
                                                      }
                                                      label={`Select Transporter (${this.state.transportername})`}
                                                      variant="standard"
                                                      error={
                                                        this.state
                                                          .transportername ===
                                                          "" && this.state.err
                                                      }
                                                      helperText={
                                                        this.state
                                                          .transportername ===
                                                          "" && this.state.err
                                                          ? "Empty field!"
                                                          : " "
                                                      }
                                                      fullWidth
                                                    />
                                                  )}
                                                />
                                              </fieldset>
                                              <fieldset className="form-group col-md-4 ">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transporterid"
                                                  label={`${
                                                    this.state.transporterid ===
                                                      "" ||
                                                    this.state.transporterid ===
                                                      null
                                                      ? "Transporter Id"
                                                      : " "
                                                  }`}
                                                  value={
                                                    this.state.transporterid
                                                  }
                                                  variant="standard"
                                                  disabled
                                                />
                                              </fieldset>
                                            </>
                                          ) : (
                                            <>
                                              <fieldset className="form-group col-md-4">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transportername"
                                                  label="Transporter Name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      transportername:
                                                        e.target.value,
                                                      err: false,
                                                    })
                                                  }
                                                  error={
                                                    this.state
                                                      .transportername === "" &&
                                                    this.state.err
                                                  }
                                                  helperText={
                                                    this.state
                                                      .transportername === "" &&
                                                    this.state.err
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  variant="standard"
                                                />
                                              </fieldset>
                                              <fieldset className="form-group col-md-4 ">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name="transporterid"
                                                  label="Transporter Id"
                                                  onChange={(e) =>
                                                    this.TransporteridChange(
                                                      e.target.value
                                                    )
                                                  }
                                                  error={
                                                    (this.state
                                                      .transporterid === "" ||
                                                      this.state.validtrnsid ===
                                                        false) &&
                                                    this.state.err
                                                  }
                                                  helperText={
                                                    this.state.transporterid ===
                                                      "" && this.state.err
                                                      ? "Empty field!"
                                                      : this.state
                                                          .validtrnsid === false
                                                      ? "Invalid Transportor Id"
                                                      : " "
                                                  }
                                                  variant="standard"
                                                />
                                              </fieldset>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Form>
                                  )}
                                </Formik>
                                <button
                                  className="btn btn-sm hovbuttonColour float-center"
                                  onClick={this.handleEWaybillGenration}
                                >
                                  {this.state.Invoicedata.einvoicestatus ===
                                  "Pending"
                                    ? "Update Transportation"
                                    : "Generate Ewaybill"}
                                </button>
                              </>
                            </Modal.Body>
                          </Modal>

                          {/* E-way bill Date Extention modal */}
                          <Modal
                            show={this.state.showEWBExtn}
                            onHide={this.handleshowEWBExtnClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="xl"
                          >
                            <Modal.Header closeButton>
                              <b>E-way bill Date Extention:</b>
                            </Modal.Header>
                            <Modal.Body>
                              <>
                                <Formik
                                  initialValues={{}}
                                  validateOnChange={false}
                                  validate={this.validate}
                                  onSubmit={this.onSubmit}
                                  enableReinitialize={true}
                                >
                                  {({ setFieldValue, values }) => (
                                    <div className="row">
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="vehiclenum"
                                          label="Vehicel No."
                                          onChange={(e) =>
                                            this.setState({
                                              vehiclenum: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="remainingDistance"
                                          label="Approximate Remaining Distance(Km)"
                                          onChange={(e) =>
                                            this.setState({
                                              remainingDistance: parseInt(
                                                e.target.value
                                              ),
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.remainingDistance ===
                                              "" && this.state.err
                                          }
                                          helperText={
                                            this.state.remainingDistance ===
                                              "" && this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Mode of Transportation
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.transmode}
                                            onChange={(e) =>
                                              this.setState({
                                                transmode: e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.deliverymethods.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transportation Mode{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              this.state.transportationmode
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                transportationmode:
                                                  e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"1"}>
                                              Road
                                            </MenuItem>
                                            <MenuItem value={"2"}>
                                              Rail
                                            </MenuItem>
                                            <MenuItem value={"3"}>Air</MenuItem>
                                            <MenuItem value={"4"}>
                                              Ship
                                            </MenuItem>
                                            <MenuItem value={"5"}>
                                              inTransit
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="fromCountry"
                                          options={this.state.CountryData}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.country
                                              ? undefined
                                              : undefined
                                          }
                                          onChange={this.handleCountryChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="fromCountry"
                                              value={this.state.fromCountry}
                                              label={`Select Country (${this.state.fromCountry})`}
                                              variant="standard"
                                              error={
                                                this.state.fromCountry === "" &&
                                                this.state.err
                                              }
                                              helperText={
                                                this.state.fromCountry === "" &&
                                                this.state.err
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="fromState"
                                          options={this.state.StatesData}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.state ? undefined : undefined
                                          }
                                          onChange={this.handleStateChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="fromState"
                                              value={this.state.fromState}
                                              label={`Select State (${this.state.fromState})`}
                                              variant="standard"
                                              error={
                                                this.state.fromState === "" &&
                                                this.state.err
                                              }
                                              helperText={
                                                this.state.fromState === "" &&
                                                this.state.err
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="fromPlace"
                                          options={this.state.CitiesData}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.city ? undefined : undefined
                                          }
                                          onChange={this.handleCityChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="fromPlace"
                                              value={this.state.fromPlace}
                                              label={`Select City (${this.state.fromPlace})`}
                                              variant="standard"
                                              error={
                                                this.state.fromPlace === "" &&
                                                this.state.err
                                              }
                                              helperText={
                                                this.state.fromPlace === "" &&
                                                this.state.err
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="fromPincode"
                                          label="From Pincode"
                                          onChange={(e) =>
                                            this.setState({
                                              fromPincode: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.fromPincode === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.fromPincode === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="addressLine1"
                                          label="Address1"
                                          onChange={(e) =>
                                            this.setState({
                                              addressLine1: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.addressLine1 === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.addressLine1 === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="addressLine2"
                                          label="Address2"
                                          onChange={(e) =>
                                            this.setState({
                                              addressLine2: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.addressLine2 === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.addressLine2 === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="addressLine3"
                                          label="Address3"
                                          onChange={(e) =>
                                            this.setState({
                                              addressLine3: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.addressLine3 === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.addressLine3 === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Reason for Extending Validity
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              this.state.reasonCodeforewbext
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                reasonCodeforewbext:
                                                  e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.ResforEWBExt.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-12">
                                        <TextField
                                          name="extnRemarks"
                                          className="form-control"
                                          multiline
                                          rows={1}
                                          label="Add Remarks"
                                          variant="outlined"
                                          value={this.state.extnRemarks}
                                          error={this.state.extnRemarks === ""}
                                          onChange={(e) =>
                                            this.setState({
                                              extnRemarks: e.target.value,
                                            })
                                          }
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-12">
                                        <button
                                          className="btn btn-sm hovbuttonColour float-center mt-2"
                                          onClick={this.ExtendEwaybill}
                                        >
                                          Submit{" "}
                                        </button>
                                      </fieldset>
                                    </div>
                                  )}
                                </Formik>
                              </>
                            </Modal.Body>
                          </Modal>

                          {/* E-way bill Vehicle Update modal */}
                          <Modal
                            show={this.state.showupdtveh}
                            onHide={this.handlecloseChgVel}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="xl"
                          >
                            <Modal.Header closeButton>
                              <b>Vehicle Update Details :</b>
                            </Modal.Header>
                            <Modal.Body>
                              <>
                                <Formik
                                  initialValues={{}}
                                  validateOnChange={false}
                                  validate={this.validate}
                                  onSubmit={this.onSubmit}
                                  enableReinitialize={true}
                                >
                                  {({ setFieldValue, values }) => (
                                    <div className="row">
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="vehiclenum"
                                          label="Vehicel No."
                                          onChange={(e) =>
                                            this.setState({
                                              vehiclenum: e.target.value,
                                              err: false,
                                            })
                                          }
                                          error={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                          }
                                          helperText={
                                            this.state.vehiclenum === "" &&
                                            this.state.err
                                              ? "Empty field!"
                                              : " "
                                          }
                                          variant="standard"
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Vehicle Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.vehicletype}
                                            onChange={(e) =>
                                              this.setState({
                                                vehicletype: e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value="R">
                                              Regular
                                            </MenuItem>
                                            <MenuItem value="O">ODC</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          variant="standard"
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Transportation Mode{" "}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              this.state.transportationmode
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                transportationmode:
                                                  e.target.value,
                                              })
                                            }
                                            label="Vehicle Type"
                                            fullWidth
                                          >
                                            <MenuItem value={"1"}>
                                              Road
                                            </MenuItem>
                                            <MenuItem value={"2"}>
                                              Rail
                                            </MenuItem>
                                            <MenuItem value={"3"}>Air</MenuItem>
                                            <MenuItem value={"4"}>
                                              Ship
                                            </MenuItem>
                                            <MenuItem value={"5"}>
                                              inTransit
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="fromCountry"
                                          options={this.state.CountryData}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.country
                                              ? undefined
                                              : undefined
                                          }
                                          onChange={this.handleCountryChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="fromCountry"
                                              value={this.state.fromCountry}
                                              label={`Select Country (${this.state.fromCountry})`}
                                              variant="standard"
                                              error={
                                                this.state.fromCountry === "" &&
                                                this.state.err
                                              }
                                              helperText={
                                                this.state.fromCountry === "" &&
                                                this.state.err
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="fromState"
                                          options={this.state.StatesData}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.state ? undefined : undefined
                                          }
                                          onChange={this.handleStateChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="fromState"
                                              value={this.state.fromState}
                                              label={`Select State (${this.state.fromState})`}
                                              variant="standard"
                                              error={
                                                this.state.fromState === "" &&
                                                this.state.err
                                              }
                                              helperText={
                                                this.state.fromState === "" &&
                                                this.state.err
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="fromPlace"
                                          options={this.state.CitiesData}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.city ? undefined : undefined
                                          }
                                          onChange={this.handleCityChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="fromPlace"
                                              value={this.state.fromPlace}
                                              label={`Select City (${this.state.fromPlace})`}
                                              variant="standard"
                                              error={
                                                this.state.fromPlace === "" &&
                                                this.state.err
                                              }
                                              helperText={
                                                this.state.fromPlace === "" &&
                                                this.state.err
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          className="col-md-12"
                                          variant="standard"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Reason
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={
                                              this.state.reasonCodeforvehchg
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                reasonCodeforvehchg:
                                                  e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.ResforVehChange.map(
                                              (option, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </fieldset>

                                      <fieldset className="form-group col-md-12">
                                        <TextField
                                          name="extnRemarks"
                                          className="form-control"
                                          multiline
                                          rows={1}
                                          label="Add Remarks"
                                          variant="outlined"
                                          value={this.state.extnRemarks}
                                          error={this.state.extnRemarks === ""}
                                          onChange={(e) =>
                                            this.setState({
                                              extnRemarks: e.target.value,
                                            })
                                          }
                                        />
                                      </fieldset>

                                      <fieldset className="form-group col-md-12">
                                        <button
                                          className="btn btn-sm hovbuttonColour float-center mt-2"
                                          onClick={this.UpdateVehicel}
                                        >
                                          Submit{" "}
                                        </button>
                                      </fieldset>
                                    </div>
                                  )}
                                </Formik>
                              </>
                            </Modal.Body>
                          </Modal>

                          <Modal
                            show={this.state.showCancelModal}
                            onHide={() =>
                              this.setState({
                                showCancelModal: false,
                                remarks: "",
                              })
                            }
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              Invoice Cancellation
                            </Modal.Header>
                            <div className="form-row mt-3 ml-3">
                              <fieldset className="form-group col-md-8">
                                <TextField
                                  name="remarks"
                                  className="form-control"
                                  multiline
                                  rows={2}
                                  label={`Reason For Cancellation`}
                                  variant="outlined"
                                  value={this.state.remarks}
                                  onChange={(e) =>
                                    this.setState({ remarks: e.target.value })
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-12" />
                              <fieldset className="form-group col-md-12" />
                              {this.state.remarks === "" ? (
                                <button
                                  className="btn btn-sm hovbuttonColour float-center ml-2"
                                  disabled
                                >
                                  Submit
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm hovbuttonColour float-center ml-2"
                                  onClick={this.handleTerminateInvoice}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                            <Modal.Body> </Modal.Body>
                          </Modal>
                        </div>
                      </div>

                      {this.state.Invoicedata.canceldesc !== null &&
                      this.state.Invoicedata.canceldesc !== undefined &&
                      this.state.Invoicedata.canceldesc !== "" ? (
                        <fieldset className="form-group col-md-12">
                          <label className="text-red">
                            Invoice cancellation description :{" "}
                            {this.state.Invoicedata.canceldesc}
                          </label>
                        </fieldset>
                      ) : (
                        ""
                      )}
                      {this.state.Invoicedata.remarks !== null &&
                      this.state.Invoicedata.remarks !== undefined &&
                      this.state.Invoicedata.remarks !== "" ? (
                        <fieldset className="form-group col-md-12">
                          <label className="text-red">
                            E-Invoice cancellation description :{" "}
                            {this.state.Invoicedata.remarks}
                          </label>
                        </fieldset>
                      ) : (
                        ""
                      )}

                     
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ViewInvoice);
