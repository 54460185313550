import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { clientname, currentTenant, dataaccess, logo, userid, userwhid } from '../../Common/Credentials'
import moment from 'moment'
import { spin } from "../../Common/NewLoader.jsx";
import CustomToolbar from '../../CommonComponents/CustomToolbar'
import MUIDataTable from "mui-datatables";
import { inventoryid } from '../../Common/Permissions';
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu";
import InventoryService from "../InventoryService.js";
import { ThemeProvider } from '@mui/material';
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class StockIssueTable extends Component {

  constructor(props) {
    super(props) 
      this.state = {
        stockissue:[],
        loading:false
    }
    this.refreshStocks=this.refreshStocks.bind(this)
  }
  
  pdf() {
    this.state.stockissue.map(e=>
      this.state.stockissue.find(i=>i.id===e.id).createdAt=moment(this.state.stockissue.find(i=>i.id===e.id).createdAt).format('DD-MM-YYYY hh:mm')
       )
       const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    const columns = [{header:"Item Name",dataKey:'itemname'},{header:"Issued From",dataKey:'createdbyname'},{header:"Issued To",dataKey:'receivedbyname'},{header:"Warehouse",dataKey:'whaddress'},{header:"Department",dataKey:'deptname'},{header:"Quantity",dataKey:'totalquantity'},{header:"Description",dataKey:"description"},{header:"Date",dataKey:"createdAt"}]
    var doc = new jsPDF("p", "pt", [842, 595]);
    autoTable(doc,{
      theme:'grid',
      startY:120,
      columnStyles: { 7: { halign: 'right'} },
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body: this.state.stockissue,
      columns:columns,
    });
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`Stock Issues`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save("StockIssues.pdf");
  }
  refreshStocks(){
      
    let data ={
       tenantid: currentTenant,
       startdate: "",
       enddate: "",
      userid: userid,
      dataaccess:dataaccess,
      whid:userwhid,
      id:"",
      itemid:null,
      poupload:false
    }
    InventoryService.gettransfredstock(data)
    .then(
        response => {
            this.setState({stockissue:response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),loading:false})
        } 
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    
    if (userid === undefined) {
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.setState({loading:true})
     this.refreshStocks()
    }
    render() {

      const columns = [
        {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "stocktransfertype",label: "Transfer Type",options: {filter: true,sort: true,}},
        {name: "createdbyname",label: "Issued By",options: {filter: true,sort: true,}},
        {name: "receivedbyname",label: "Issued To",options: {filter: true,sort: true,}},
        {name: "fromwhname",label: "From Org Unit/Branch",options: {filter: false,sort: true,}},
        {name: "towhname",label: "To Org Unit/Branch",options: {filter: false,sort: true,}},
        {name: "deptname",label: "Department/Store",options: {filter: false,sort: true,}},
        {name: "ewaybillrequired",label: "Eway-bill",options: {filter: true,sort: true,}},
        {name: "status",label: "Status",options: {filter: false,sort: true,}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY hh:mm'))
          }
        }},
        {
          name: "Actions",
          options: {
            filter: false,
            sort: false,
            empty: true,
            display: inventoryid === "2" || inventoryid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<>
               {/* <Link
                  as="button"
                  data-tip
                  data-for="edit"
                  className=" mx-1"
                  to={{
                    pathname: "/addstockissue"}}
                    state={{
                      id: tableMeta.rowData.find((e) => e),
                      name: "Update Stock Issue",
                  }}
                ><i className="fas fa-pen"/></Link>
                | */}
                <span
                  as="button"
                  data-tip
                  data-for="delete"
                  className=" mx-1"
                  onClick={()=> this.delete(tableMeta.rowData.find((e) => e))}
                ><i className="fa fa-trash"/> </span>
                | <Link
                  as="button"
                  data-tip
                  data-for="view"
                  className=" mx-1"
                  to={{
                    pathname: "/stockissueitemsview"}}
                    state={{
                      data:this.state.stockissue.find(e=>e.id === tableMeta.rowData.find((e) => e))
                  }}
                ><i className="fa fa-list"/> </Link>
  
             </> )
            },
          },
        },
      
      ]

      const options = {
        filterType: 'checkbox',
        selectableRows: "none",
        customToolbar: () => {
         return (<>
         { inventoryid === '2' || inventoryid === '3'? 
         <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addstockissue"}} state ={ { id: -1, pagename: "New Stock Issue" }}>
           <CustomToolbar />
         </Link>
         :''
        }
         <span as="button" onClick={this.pdf.bind(this)}>
             <React.Fragment>
        <Tooltip title={"pdf"}>
          <IconButton>
          <i className="fa fa-file-pdf"></i>
          </IconButton>
        </Tooltip>
      </React.Fragment>

             </span>
         </>
         );
       }
      }
      return (
        <div>
          <Header />
        <Menu/>
              <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Stock Issue/Transfer</li>
            </ol>
            <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">                 
                <div className="card-body">
                  <div>{this.state.loading === true ?<div className="text-center"> {spin}</div> : 
                  <ThemeProvider theme={theme}> 
                  <MUIDataTable
                       className="table-sm"
                        title={"Stock Issue/Transfer"}
                        data={this.state.stockissue}
                        columns={columns}
                       options={options}
                      />
                      </ThemeProvider>
                        }
                      </div>
                          </div>
                          {/* /.card-body */}
                      </div>
                      {/* /.col */}
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
                </section>


                  
              </div>
                     </div>
                     <Footer/>     
                      </div>
                    
                  )
                
                }
              
}
export default withRouter(StockIssueTable)