import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";

import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { spin } from "../../Common/NewLoader";
import { NumericFormat } from "react-number-format";
import { currentTenant, thousandsdisplay } from "../../Common/Credentials.js";
import moment from "moment";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";
import { inventoryid } from "../../Common/Permissions.js";
import InventoryService from "../InventoryService.js";


const theme = createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e9ecef !important",
          border: [[1, "solid", "#ece6e6"]],
        },
        body: {
          border: [[1, "solid", "#ece6e6"]],
        },
      },
    },
  });
const Internalconsumption = () => {
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])


    useEffect(() => {
      InventoryService.GetAllInternalusebyTenantId(currentTenant).then((response)=>setData(response.data.sort((a, b) => (a.id < b.id ? 1 : -1))))
    }, [])
    


    const columns = [
        {
          name: "id",
          label: "id",
          options: {
            filter: false,
            sort: false,
            display: false,
            viewColumns: false,
          },
        },
        {
          name: "item",
          label: "Item name",
          options: { filter: true, sort: true },
        },
        // {
        //   name: "supplier",
        //   label: "Supplier",
        //   options: { filter: true, sort: true, customBodyRender: (value,tableMeta) => 
        //       tableMeta.rowData[8] === "Production"?"Production": value===undefined||value === null || value===""?"Opening":value
        //    },
        // },
        // {
        //   name: "waddress",
        //   label: "Org unit",
        //   options: { filter: true, sort: true },
        // },
        {
          name: "batchid",
          label: "Batch Id",
          options: { filter: false, sort: true },
        },
        {
          name: "stock",
          label: "Consumed Stock",
          options: { filter: false, sort: true },
        },
        {
          name: "uom",
          label: "UOM",
          options: { filter: false, sort: true },
        },
        {
          name: "createdAt",
          label: "Date",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (tableMeta) => {
              return moment(tableMeta).format("DD-MM-YYYY HH:mm");
            },
          },
        },
        {
          name: "cost",
          label: "Price",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (tableMeta) => {
              return (
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={tableMeta}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              );
            },
            setCellProps: (value) => ({ style: { textAlign: "right" } }),
          },
        },
        {
          name: "totalamount",
          label: "Total",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (tableMeta) => {
              return (
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={tableMeta}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              );
            },
            setCellProps: (value) => ({ style: { textAlign: "right" } }),
          },
        },

        // {
        //   name: "ledgerstatus",
        //   label: "Accounts entry",
        //   options: { filter: false, sort: true },
        // },
      ];

      const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {inventoryid === "2" || inventoryid === "3" ? (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{
                    pathname: "/addinternalconsumption"}}
                    state= {{ id: -1}}
                >
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div>
      <div className="card">
        <ol className="breadcrumb float-sm-right">
          <li className="breadcrumb-item">
            <Link to="/inventory">Home</Link>
          </li>
            <li className="breadcrumb-item active">Internal Consumption</li>
        </ol>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <div className="card-body">
                    <div>
                      {loading === true ? (
                        <div className="text-center"> {spin}</div>
                      ) : (
                        <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm"
                              title={"Internal Consumption"}
                              data={data}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Internalconsumption);
