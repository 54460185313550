import React, { Component } from 'react'
import { Formik, Form } from "formik";
import withRouter from '../withRouter.js';
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete,TextField} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { ccountry, currentTenant, currentUser, thousandsdisplay, userid,financialyearend } from '../Common/Credentials.js';
import { FormLabel } from '@mui/material';
import { Typography} from '@mui/material';
import moment from 'moment';
import FileServices from '../Common/upload/FileServices.js';
import UploadFilesService from '../Common/upload/UploadFilesService.js';
import NewJournalService from './NewJournalService.js';
import { TableHead,TableBody,TableRow,TableContainer,Table, TableCell,Grid,FormControlLabel,Backdrop ,CircularProgress} from "@mui/material";
import { Link } from 'react-router-dom';
import AuthenticationService from '../Authentication/AuthenticationService.jsx';
import Header from '../Component/Header.js';
import Menu from '../Component/Menu.js';
import { Spinner } from 'react-bootstrap';
import { spin } from '../Common/NewLoader.jsx';
import Footer from '../Component/Footer.js';
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';
import SalesServices from '../Sales/SalesServices.js';
import { directtaxasset } from './Journal.jsx';


// const getTotal = (totamt) => {
//   let total = 0;
//   totamt.forEach(totamt => {
//     total += totamt;
//   });
//   return total;
// };
let curmonth,year,day,rectcheck,advstatus=0,tcsso='',totamt=0,totalpending=0,invcrnotes=[],pendingcn,cnpendingamt=null
// let entrytype,advsoamt
let doctype = "supplierinvoices"//,selchk
let advancerecd,advcustled,val=[]
let preceipts=null,preceipts2,jvtype=4,tcsdata,selrects=[]
// const BorderLinearProgress = styled((theme) => ({
// root: {height: 15,borderRadius: 5,},
// colorPrimary: {backgroundColor: "#EEEEEE",},
// bar: {borderRadius: 5,backgroundColor: '#1a90ff',},
// }))(LinearProgress);

const Checkbox = props => (
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
)
class SettlementSales extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          pendingdata:this.props.location.state,
            partyid:'',soid:null,customsoid:null,rectid:'',pan:'',ieexpid:'',invcrnotes:[],custominvid:null,tdsdata1:null,
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            // paymenttype: [{ id: 1, name: "From Invoice" },{ id: 2, name: "Agent Receipt" },{ id: 3, name: "Advance" }],
            pmttype:[{ id: 0, name: "Sales" }],SDdate:false,CTDS:false,fed:'',Genadv:false,
            debselval:'',rectchecked:'',pnpchecked:'',tcschecked:1,selectedFiles: undefined,
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],jvid:null,cjvid:null,
            fileInfo:"",pendingReceipts:[],selectedPR:[],selectedPR2:[],selectedPSO:[],selectedPSO2:[],newinvamt:'',
            selchk:[],selchk1:[],selamt:null,selamt2:[],displaychecked:false,prevrectschecked:false,advancepo:[],selectedadv:[],selamt3:[],
            selserv:[],selrects:[],serviceamount:null,serviceledger:"",totalcombdata:[],advsoamt:0,tcsfromso:0,
            loader:true,invid:null,jvinsertiontype:0,custominvid:null,pendingcn:null,cnpendingamt:null,selectedoption:null,
            crdate:null,totamt:null,debvalue:null,credvalue:null,totalpending:null,gst:null,custname:null,transpendingamount:null,genadvancecurrentamt:null,expval:[],
            genadvance:[{"id":"genadv","name":"General Advance","amount":0.00,"entrytype":0,"subacc":"","tenantId":"","rule":0,"behaviour":0,"subname":"z - NA","totamount":null,"th":"","taxtype":0}],
            rectreftype:""
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.selectFile=this.selectFile.bind(this);this.addLedvalues=this.addLedvalues.bind(this);
        this.upload=this.upload.bind(this);
        this.addlDataAdd=this.addlDataAdd.bind(this);
        // this.CheckBoxSelect=this.CheckBoxSelect.bind(this);
        this.handleAdvrecd=this.handleAdvrecd.bind(this)
        this.loadData=this.loadData.bind(this)
        this.AddData=this.AddData.bind(this)
    }

    selectFile=event=> {
        this.setState({selectedFiles: event.target.files,});
      }

      loadData(){
        NewJournalService.getAllLedgers(currentTenant)
        .then(response=>{
          this.setState({totalcombdata:response.data},()=>this.AddData())
          // totalcombdata=response.data
         })
         .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
        })
        
      }

       goBack() {
    this.props.navigate(-1);
  }

      AddData(){
        let ssold=null,soitems=null
          val=this.state.totalcombdata 
      this.state.pendingdata.map((e)=>{return soitems=e.sitems})
      this.state.pendingdata.map((e)=>{return ssold=e.ssold})
       this.setState({selchk:ssold},()=>this.addlDataAdd()) 
       if(soitems!==undefined && soitems.length!==0 ){
      this.setState({selchk:soitems,invid:soitems.map((e)=>e.invid)})  
      }else if(ssold!==undefined && ssold.length!==0 ){
      this.setState({selchk:ssold,invid:ssold.map((e)=>e.invid)}) 
      }
      }
      upload() {
        let currentFile = this.state.selectedFiles[0];
         this.setState({progress: 100,currentFile: currentFile,});
        UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
          this.setState({progress: Math.round((100 * event.loaded) / event.total)});
        }).then((response) => {
         this.setState({message: response.data.message,isError: false});
            return FileServices.getFiles1(currentFile.name);
          }).then((files) => {this.setState({fileInfos: files.data,});
          }).catch(() => {
            this.setState({progress: 0,message: "Could not upload the file!",currentFile: undefined,
              isError: true}); });
        this.setState({selectedFiles: undefined,});
      }

    onDebBlur(values){
      // alert(this.state.totamt)
      this.setState({debselval:values.debit})
      if(values.debit!==undefined && values.debit.length!==0){
        this.setState({partyid:values.debit.id})
      }
    }
    handleSDDate=()=>{
      this.setState({SDdate:this.state.SDdate===true?false:true})
    }

    // handlecTDS=()=>{
    //   this.setState({CTDS:this.state.CTDS===true?false:true},()=>

    //   this.state.CTDS===true?
    //   this.setState({tdsdata1:directtaxasset}):"")
    // }

    handleGenadv=()=>{
      this.setState({Genadv:this.state.Genadv===true?false:true})
    }
    
    handleFsdDateChange = (date) => {
      if(date!==null){
      this.setState({ fsd: date });
      this.setState({
        fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
      });
    }else{
      this.setState({ SDdate:false})
    }
    };

    onSubmit(values){
      this.setState({loader:true})
      let selected,lossid,lossamt,lossname
      let mode,bank,bankid
      let ctdsid,ctdsname,ctdsval
      let tcsid,tcsname,tcsvalue,tcspercentage,tcsamt,advledger
      if(values.debit.subheadid==="AH000003"){
        mode=0
      }else{
        mode=1
        bank=values.debit.name
        bankid=values.debit.id
      }
      if(this.state.tcsfromso!==0 ){
        if(this.state.tcsfromso!==null){
        // alert(values.tcs.id)
        tcsid=values.tcs!=undefined?values.tcs.id:undefined
        tcsname=values.tcs!=undefined?values.tcs.name:undefined
        tcsvalue=values.tcsval
        tcspercentage=values.tcspercent
        tcsamt=tcsso
      }
      }

      // if(this.state.CTDS===true){
      //   ctdsid=values.ctds.id
      //   ctdsname=values.ctds.name
      //   ctdsval=values.ctcstotal
      // }

      if(rectcheck===1){
        if(this.state.selchk!==undefined){
          if(this.state.selchk.length!==0){
          selected=this.state.selchk
          }else{
          selected=this.state.selserv
          }
        }
      }else if (rectcheck===2){
        if(this.state.selchk1!==undefined){
          selected=this.state.selchk1
        }
      }

      if(this.state.pendingdata.map((e)=>e.soadvance)!==0){
        // this.state.pendingdata.map((e)=>{return soadvance=e.soadvance})
        let a=this.state.pendingdata.map((e)=>e.advledger)
        advledger=a.toLocaleString();
      }
      let rectid
      this.state.pendingdata.map((e)=>{return rectid=e.id })
      let canreq={
        rectid:rectid,
        tenantid:currentTenant
      }

      if(this.state.pendingcn!==null && this.state.pendingcn.length!==0){
        lossid=values.loss.id
        this.state.pendingcn.map(e=>{return lossamt=e.totalamount})
        lossname=values.loss.name
      }


      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debdth:values.debit.th,debitamt:values.debitval,
        creditid: values.credit.id,creditname: values.credit.name,credth:values.credit.th,creditamt:values.creditvalue,
        narration: values.narration,createdBy: userid,tenantId: currentTenant,
        selectedval:selected,jvtype:jvtype,soid:this.state.soid,mode:mode,receiptref:values.receiptref,
        soadvance:this.state.advsoamt,jvid:this.state.jvid,cjvid:this.state.cjvid,
        invid:this.state.invid!==null?this.state.invid.find((e,i)=>e.valueOf(i)):"",
        advledger:advledger,advstatus:advstatus,customsoid:this.state.customsoid,custominvid:this.state.custominvid,jvinsertiontype:this.state.jvinsertiontype,
        bank:bank,bankid:bankid,rectid:this.state.rectid.toLocaleString(),tcsid:tcsid,tcsname:tcsname,tcsvalue:tcsvalue,tcspercentage:tcspercentage,tcsamt:tcsamt,
        sdebitamt:this.state.serviceamount,sdebitid:this.state.serviceledger,frompage:this.props.location.pathname,
        date:this.state.fed!==''?this.state.fed:null,genadv:this.state.Genadv,selectedinvcn:this.state.pendingcn,
        lossid:lossid,lossamt:lossamt,lossname:lossname,rectreftype:this.state.rectreftype,ctdsid:ctdsid,ctdsname:ctdsname,ctdsval:ctdsval
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }else if(values.debitval>(this.state.transpendingamount+tcsso)){
        this.setState({loader:false})
        Swal.fire({
          title: "Error",
          text: "Value cannot be more than pending amount",
          icon: "error",
          confirmButtonText: "Ok",
          // showCancelButton: true,
          // cancelButtonText: "No",
          confirmButtonColor: "#152f5f",
        })
      }else //if(this.state.Genadv===true){
      if(this.state.Genadv===true && parseFloat(this.state.genadvancecurrentamt)>0 && parseFloat(values.debitval)>parseFloat(this.state.genadvancecurrentamt)){
        this.setState({loader:false})
        Swal.fire({
          title: "Error",
          text: "Value cannot be more than advance amount",
          icon: "error",
          confirmButtonText: "Ok",
          // showCancelButton: true,
          // cancelButtonText: "No",
          confirmButtonColor: "#152f5f",
        })
     // }
      }else{
        if(this.state.invid!==null){
            let daodata={
          invid:this.state.invid.find((e,i)=>e.valueOf(i)),
           tenantId:currentTenant
            }
        NewJournalService.getInvStatus(daodata)
        .then(response=>{
          // response.data
          this.setState({loader:false})
          if(response.data!==2){
            if(this.state.jvinsertiontype==="SALEADV" || this.state.jvinsertiontype==="SALEINV" ){
              Swal.fire({
                title: "Information",
                text: "This action is irreversible, please recheck before confirming in CONFIRMATION screen ",
                icon: "info",
                confirmButtonText: "Ok",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonColor: "#152f5f",
              }).then((result) => {
                if (result.value === true) {
                  // if(tcsid!==undefined){
                  //   this.setState({loader:false})
                  //   Swal.fire({
                  //     title: "Information",
                  //     text: "Tcs will be adjusted in this entry values are updated in confirm Screen",
                  //     icon: "info",
                  //     confirmButtonText: "Ok",
                  //     // showCancelButton: true,
                  //     // cancelButtonText: "No",
                  //     confirmButtonColor: "#152f5f",
                  //   })
                  //   this.props.navigate({pathname:`/journalsaleconfirm`}, {state:jv});
                  // }
                  this.props.navigate({pathname:`/journalsaleconfirm`}, {state:jv});
                  this.setState({loader:false})
                  // this.goBack()
                  }
                }) 
              }else{
                this.props.navigate({pathname:`/journalsaleconfirm`}, {state:jv});
              }
           //this.props.navigate({pathname:`/journalsaleconfirm`}, {state:jv});
          }  
          else
          {
            this.setState({loader:false})
            Swal.fire({
              title: "Cancellation",
              text: "This invoice is Cancelled and cannot continue, Initiate Entry cancellation request ?",
              icon: "error",
              confirmButtonText: 'Yes',
              showCancelButton: true,
              cancelButtonText: 'No',
              confirmButtonColor: "#152f5f",
            }).then((result) => {
              if (result.value === true) {
                this.setState({loader:true})
                NewJournalService.rectificatinEntry(canreq)
                .then(response=>{
                  if(response.status===200){
                    Swal.fire({
                      title: "Confirmation",
                      text: "Entry cancellation Request Raised",
                      icon: "info",
                    confirmButtonText: "Ok",
                    confirmButtonColor:'#152f5f',
                    })

                  this.props.navigate(-1);
                  }
                })
              this.setState({loader:false})
              }else{
                this.setState({loader:false})
              }
            })
         }
         })
      }else{
        this.props.navigate({pathname:`/journalsaleconfirm`}, {state:jv});

      }
    }
    }

    componentDidMount(){
        console.log(this.state.pendingdata)
       SalesServices.getInvoiceCreditNotes(currentTenant)
       .then(response=>{
         this.setState({invcrnotes:response.data})
        invcrnotes=response.data
        })
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.state.pendingdata.map((e)=>{return this.setState({jvinsertiontype:e.jvinsertiontype})})
      this.state.pendingdata.map((e) => { return this.setState({jvid:e.jvid,cjvid:e.cjvid})})

      this.loadData()
     
     
       }

       addlDataAdd(){
        let tam
        this.state.pendingdata.map((e) =>{return tam=e.transpendingamount})
        if(!this.state.totalcombdata && this.state.totalcombdata.length===0){
          this.loadData()
          this.setState({loading:true})
         }else{
          this.setState({loading:false})
          this.setState({crdate:this.state.pendingdata.map((e)=>{return e.createdAt}),
          totamt:tam
         })
         }

        // advsoamt=null
         let z,x,y,f,ee,g,h,i
         this.state.pendingdata.map((e)=>{return z=e.soid})
         this.state.pendingdata.map((e)=>{return x=e.customsoid})
         this.state.pendingdata.map((e)=>{return g=e.custominvid})
         this.state.pendingdata.map((e)=>{return y=e.soadvance})
         this.state.pendingdata.map((e)=>{return f=e.invoiceamt})
         this.state.pendingdata.map((e) => { return ee=e.dcadjusted })
         this.state.pendingdata.map((e) => { return h=e.custominvid })
         this.state.pendingdata.map((e) => { return i=e.rectreftype })

         

         

        
        this.setState({pendingReceipts:this.state.pendingdata,
          soid:z, customsoid:x,advsoamt:y,newinvamt:f,custominvid:g,custominvid:h,rectreftype:i
        },()=>this.addLedvalues())
        //  this.state.pendingdata.map((e)=>{return advsoamt=e.soadvance}
         
       
          let a,tpa
          this.state.pendingdata.map((e)=>{return a=e.transpendingamount})
         if(a!==0){
          tpa=null
          this.state.pendingdata.map((e)=>{return tpa=e.transpendingamount})
          if(ee!==undefined){
            if(ee!==null){
           // tpa=parseInt(tpa)-parseInt(ee)
            }
          }
          this.setState({selamt:tpa})
          // this.state.pendingdata.map((e)=>{return selamt=e.transpendingamount})
         }else{
          tpa=null
          this.state.pendingdata.map((e)=>{return tpa=e.pendingamount})
          if(ee!==undefined){
            if(ee!==null){
            tpa=parseInt(tpa)-parseInt(ee)
            }
          }
           this.setState({selamt:tpa})
          //  this.state.pendingdata.map((e)=>{return selamt=e.pendingamount})
          }
          this.state.pendingdata.map((e)=>{return tcsso=e.tcsamount})
          if(tcsso!==undefined){
          this.setState({tcsfromso:tcsso})
          }
          
      // console.log(this.props.history.location)
  
        let aa,bb,cc,dd
        this.state.pendingdata.map((e) => { return bb=e.gstin })
        this.state.pendingdata.map((e) => { return aa=e.pendingamount})
        this.state.pendingdata.map((e) => { return cc=e.customer })
        this.state.pendingdata.map((e) => { return dd=e.transpendingamount })
        // alert(dd)

          if(ee!==undefined ){
            if(ee!==null){
            aa=parseInt(aa)-parseInt(ee)
           // dd=parseInt(dd)-parseInt(ee)
            }
          }

        this.setState({totalpending:aa,gst:bb,custname:cc,transpendingamount:dd})
        totalpending=aa
        let ap=[...new Set(this.state.pendingdata.map((e) => { return e.advancerecd})),];
        advancerecd=''
        if(ap.find((e)=>e!==null)){
        advancerecd=ap.find((e,i)=>e.valueOf(i));
        }
        let rectid=this.state.pendingdata.map((e)=>e.id)
        this.setState({rectid:rectid})
        year = new Date().getFullYear()
        day= new Date().getDate()
        let mn = new Date().getMonth()+1
        curmonth= moment(mn,'M').format('MMMM')
        
        this.state.pendingdata.map((e) => {return selrects=e.rcttrans})
        this.setState({selrects:selrects})
  
        if(this.state.advsoamt!==0){
          this.setState({totamt:this.state.advsoamt})
          totamt=this.state.advsoamt
          }else{
          this.setState({totamt:this.state.selamt})
          totamt=this.state.selamt
         }
  
         let th2=this.state.totalcombdata.filter(e=>e.th==="TH2")
         let th5=this.state.totalcombdata.filter(e=>e.th==="TH5")
         let expval1 = th2.concat(th5)
         this.setState({expval:expval1})
        

       // entrytype=1
        // let val=totalcombdata 
        tcsdata= this.state.totalcombdata.filter((e) => (e.taxtype === 1))
       // tcsdata=this.state.totalcombdata.filter((e)=>e.shid==100029)       
       }

       addLedvalues(){

          val=this.state.totalcombdata 

       pendingcn=invcrnotes.filter((e) =>e.custominvid===this.state.custominvid)   
        this.setState({pendingcn:pendingcn},()=>{
        if(pendingcn!==null){
          this.state.pendingcn.map((e)=>{return cnpendingamt=e.totalamountwtax})
          this.setState({cnpendingamt:cnpendingamt},()=>{
           if(this.state.cnpendingamt!==null){
            totamt=this.state.selamt-this.state.cnpendingamt
            this.setState({totamt:totamt})
           }
           })
        }
      })

        if(this.state.soid!==null && this.state.advsoamt===0 ){
          let ledid
          this.state.pendingdata.map(e=>{return ledid=e.customerid})
          this.setState({credvalue:val.filter((e)=> e.pid===ledid)},()=>
          this.setState({genadvancecurrentamt:this.state.credvalue.map((e)=> e.genadvcuramount)})
          )
        }else if(this.state.soid===null && this.state.custominvid!==null){
          let ledid
          this.state.pendingdata.map(e=>{return ledid=e.customerid})
          this.setState({credvalue:val.filter((e)=> e.pid===ledid)},()=>
          this.setState({genadvancecurrentamt:this.state.credvalue.map((e)=> e.genadvcuramount)}))
        } else if(this.state.soid!==null && this.state.advsoamt!==0){
          this.state.pendingdata.map(e=>{return advcustled=e.customerid})
          this.setState({credvalue:val.filter((e)=> e.pid===advcustled)},()=>
          this.setState({genadvancecurrentamt:this.state.credvalue.map((e)=> e.genadvcuramount)}))
        }else if(this.state.soid===null){
          let ledid
          this.state.pendingdata.map(e=>{return ledid=e.customerid})
          this.setState({credvalue:val.filter((e)=> e.id===ledid)},()=>
          this.setState({genadvancecurrentamt:this.state.credvalue.map((e)=> e.genadvcuramount)}))
        }
       // let deb1
        //deb1=this.state.totalcombdata.filter((e) => e)
        let losses=this.state.totalcombdata.filter((e)=>e.th==="TH5")
        this.setState({debvalue:losses.concat(this.state.totalcombdata.filter((e)=>e.th==="TH6"))})

        this.setState({loader:false})
       }
       
     validate(values){
      let errors = {},deb =values.debitval,cre = values.creditvalue
      //type = totalcombdata

       if (!values.debit || values.debit==='') {errors.debit = "Accounts is required";}
       if(!values.debitval){errors.debitval="Value Cannot be Blank"}
      //  else if(deb!==cre){
      //   errors.creditvalue="Value Cannot be be different from debit Value"}
      //  if (!values.credit || values.credit==='') {errors.credit = "Accounts is required"}
      //  if(!values.creditvalue){errors.creditvalue="Value Cannot be Blank"}else if(deb!==cre){
      //   errors.creditvalue="Value Cannot be be different from debit Value"}
       if (!values.narration) {errors.narration = "Narration is required";}
      //  if(this.state.tcsfromso!==null ){
      //   if(this.state.tcsfromso!==0){
      //   if(!values.tcs) {errors.tcs = "TCS Ledger is required";}}
      //  }
      //  if (!values.receiptref) {errors.receiptref = "Receipt Reference is required";}
      return errors;
     }

   
    onTcsChange(option){this.setState({ tcschecked: option.id });}
   
    handlecTDS=()=>{
      let losses=this.state.totalcombdata.filter((e)=> e.th==="TH5")
      let a = this.state.totalcombdata.filter(e =>e.th==="TH3" && e.subheadid==="AH000024")
      //console.log(a)
      this.setState({CTDS:this.state.CTDS===true?false:true},()=>
      this.state.CTDS===true?
      this.setState({debvalue:a}):this.setState({debvalue:losses.concat(this.state.totalcombdata.filter((e)=> e.th==="TH6"))}))
   //val.filter((e) => (e=>e.th==="TH3" && e.shid===100029))
   //let th3=val1.filter(e=>e.th==="TH3")
  // directtaxasset=th3.filter(e=>e.shid===100029)


    }

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
    let a 
    this.state.pendingdata.map((e)=>{return a=e.sitems})
    this.setState({selchk:a}) 
    //selchk=a
 }
 handlePrevBalChange= event =>{
  this.setState({ prevrectschecked: event.target.checked })
}
handleAdvrecd(){
  advstatus=1
}
    render() {
      const {message,isError} = this.state;
        return (
          <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
<div className="content-wrapper">
<div className="card card-info">

  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/ar'}} state= {{message:''}} >
                 Accounts Receivable
              </Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/settlements'}} state= {{message:''}} >
                Settlement Entry
              </Link>
    </li>

  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
        
    <Formik
      initialValues={{debitval:'',creditvalue:'',narration:'',preceipts,receiptref:""}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">


<div className="form-row mt-0 mb-0">
</div>

<div className="form-row mt-0 mb-0 text-sm sm">


{advancerecd!=='' ?

 <fieldset className="form-group col-md-12">

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
  <TableCell className="text-bold">Customer </TableCell>
  <TableCell className="text-bold ">Customer GST </TableCell>
  <TableCell className="text-bold" >Transaction Pending</TableCell>
  <TableCell className="text-bold ">Total Pending </TableCell>
  </TableRow>
    </TableHead>
  
   <TableBody>
<TableRow>
  <TableCell>{this.state.custname}</TableCell>
 <TableCell>{this.state.gst}</TableCell>
 <TableCell >{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {this.state.transpendingamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>


<TableCell >{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {this.state.totalpending} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell></TableRow>
</TableBody></Table>

 </fieldset>:null}

{this.state.soid!==null ?<>
<fieldset className="form-group col-md-12">
<TableContainer ><Table className="table-sm" aria-label="customized table">
  <TableHead className="text-bold text-blue">
  <TableRow  style={{ textAlign: "center" }}>
    <TableCell>Date</TableCell>
  <TableCell>SO Amt</TableCell><TableCell>Invoice Amt</TableCell><TableCell>Tax</TableCell>
  {this.state.serviceamount!==null?<TableCell>Service Amount</TableCell>:null}
  <TableCell>Total</TableCell><TableCell>Amt Pending</TableCell></TableRow></TableHead><TableBody>
  {this.state.pendingdata!== undefined || this.state.pendingdata!==0? 
  this.state.pendingdata.map((e) => (
   <TableRow key={e.id} style={{ textAlign: "center" }}>
<TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell>
<TableCell>{e.soamount}</TableCell><TableCell>{e.proinvoiceamt!==null?e.proinvoiceamt:e.invoiceamt}</TableCell>
<TableCell>{e.tax!==null?e.tax:"NA"}</TableCell>{e.serviceamount!==null?<TableCell>{e.serviceamount!==null?this.state.serviceamount:e.transpendingamount}</TableCell>:null}
 <TableCell>{e.invoiceamt}</TableCell><TableCell>{this.state.transpendingamount}</TableCell>
 </TableRow> )):null}</TableBody></Table></TableContainer></fieldset>

 {this.state.pendingcn!==null && this.state.pendingcn.length!==0 ? <>
 <fieldset className="form-group col-md-12 mt-0 mb-0">
<label className='text-red'>Credit Notes</label></fieldset>

 <fieldset className="form-group col-md-12">
<TableContainer ><Table className="table-sm" aria-label="customized table">
  <TableHead className="text-bold text-blue">
  <TableRow  style={{ textAlign: "center" }}>
    <TableCell>Customer</TableCell>
  <TableCell>Invoice</TableCell><TableCell>CN Amount</TableCell><TableCell>Tax</TableCell>
  <TableCell>Tax Amt</TableCell><TableCell>Total CN</TableCell></TableRow></TableHead><TableBody>
  {this.state.pendingcn.map((e) => (
   <TableRow key={e.id} style={{ textAlign: "center" }}>
<TableCell>{e.customer}</TableCell>
<TableCell>{e.einvid!==null?e.einvid:custominvid}</TableCell><TableCell>{e.totalamount}</TableCell>
<TableCell>{e.tax!==null?e.tax:"NA"}</TableCell>
 <TableCell>{e.taxamount}</TableCell><TableCell>{e.totalamountwtax}</TableCell>
 </TableRow> ))}</TableBody></Table></TableContainer></fieldset></>:""}





 {this.state.advsoamt===0?
<> <fieldset className="form-group col-md-2 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.displaychecked}
            onChange={this.handleCheckboxChange}
          />
          {this.state.displaychecked===true?"Hide Invoice-Items":"Show Invoice-Items"}
        </label>
           </div>
           </fieldset>

            <fieldset className="form-group col-md-2 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.prevrectschecked}
            onChange={this.handlePrevBalChange}
          />
          {this.state.prevrectschecked===true?"Hide Prev Receipts":"Show Prev Receipts"}
        </label>
           </div>
           </fieldset></>:null}
  <fieldset className="form-group col-md-4 mt-0 mb-0">  
  {/* {this.state.genadvancecurrentamt>0?<>
  <label className="text-sm"> General Current Advance : &nbsp;&nbsp;&nbsp;</label>
  <label className="text-sm"> {this.state.genadvancecurrentamt} </label></>:""}       */}
  </fieldset>
  {this.state.displaychecked===true?

 <fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table">
{ this.state.selchk.length!==0?<>
<TableHead className="text-bold">
   <TableRow style={{ textAlign: "center" }}>
  <TableCell>

  </TableCell>
 <TableCell>Item</TableCell><TableCell>Invoice</TableCell><TableCell>Date</TableCell><TableCell>Price</TableCell>
 <TableCell>Tax</TableCell><TableCell>Qty</TableCell><TableCell>Total</TableCell></TableRow></TableHead>
 <TableBody>{this.state.selectedPSO!== undefined || this.state.selectedPSO.length!==0? 
  this.state.selchk.map((e) => (
  <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>
  </TableCell><TableCell>{e.name}</TableCell><TableCell>{e.invid}</TableCell>
  <TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.unitprice}</TableCell>
  <TableCell>{e.taxamount}</TableCell><TableCell>{e.quantity}</TableCell><TableCell>{e.unitprice * e.quantity}</TableCell>
  </TableRow>)):null}</TableBody></>:<>
    <TableHead><TableRow className="text-sm" >
            <TableCell>Service Name</TableCell>
            <TableCell>Milestone</TableCell><TableCell>Amount</TableCell><TableCell>Percentage</TableCell>
             <TableCell>Completion Date</TableCell></TableRow></TableHead>
              <TableBody>
               {this.state.selserv.map((e,i) => (
               <TableRow key={i}>
                 <TableCell>{e.invname}</TableCell><TableCell>{e.milestone}</TableCell>
                  <TableCell>{e.totalwithtax}</TableCell>
                  <TableCell>{e.percentage}</TableCell>
                   <TableCell>{moment(e.completiondate).format('DD-MM-yyyy HH:mm')}</TableCell>
                  </TableRow>))} </TableBody></>}
  
  
  </Table></TableContainer></fieldset>:null}</>:null} 


  {this.state.prevrectschecked===true?

  <fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table">
<TableHead className="text-bold">
   <TableRow style={{ textAlign: "center" }}>
  
 <TableCell>SOID</TableCell><TableCell>Trans ID</TableCell><TableCell>Date</TableCell><TableCell>Amount</TableCell>
 <TableCell>Mode</TableCell><TableCell>Bank Name</TableCell><TableCell>Branch</TableCell></TableRow></TableHead>
 <TableBody>
 {this.state.selrects.map((e)=>(
  <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>{e.soid}</TableCell><TableCell>{e.transid}</TableCell>
  <TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>
  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {e.receiptamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat></TableCell>
  <TableCell>{e.mode}</TableCell><TableCell>{e.bank}</TableCell><TableCell>{e.branch}</TableCell>
  </TableRow>))}</TableBody>
  </Table></TableContainer></fieldset>:null}




  
  {rectcheck===2 && this.state.selectedPSO2!==undefined && preceipts2!==undefined?<>
    {preceipts2.length!==0?

 <fieldset className="form-group col-md-12 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.displaychecked}
            onChange={this.handleCheckboxChange}
          />
          {this.state.displaychecked===true?"Hide Items":"Show Items"}
        </label>
           </div>
           </fieldset>:null}</>:null}
  {this.state.displaychecked===true?<>

  {rectcheck===2 && this.state.selectedPSO2!== undefined ?
<> <fieldset className="form-group col-md-12">
<TableContainer ><Table className="table-sm" aria-label="customized table">
<TableHead className="text-bold"><TableRow style={{ textAlign: "center" }}>
<TableCell></TableCell>
<TableCell>Item</TableCell><TableCell>Date</TableCell><TableCell>Price</TableCell>
<TableCell>Tax</TableCell><TableCell>Qty</TableCell><TableCell>Total</TableCell></TableRow></TableHead><TableBody>
  {this.state.selchk!== undefined? 
  this.state.selchk.map((e) => (
    <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>
    </TableCell><TableCell>{e.name}</TableCell>
    <TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.unitprice}</TableCell>
    <TableCell>{e.taxamount}</TableCell><TableCell>{e.quantity}</TableCell><TableCell>{e.unitprice * e.quantity}</TableCell>
    </TableRow> )):null}</TableBody></Table></TableContainer></fieldset>
  
  </>:null}</>:null}



  </div>
  {/* {rectcheck!==undefined?<> */}

 <div className="form-row col-md-12 text-left form-switch mt-3">

 {/* <div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.CTDS} onChange={this.handlecTDS} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.CTDS===false?"NO CustTDS":"CustTDS"}</span>
       </div> */}



 <div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="form-group col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(this.state.crdate).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    //crdate financialyearstart
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} variant="standard"/>} autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :null}

{this.state.genadvancecurrentamt>0 && this.state.invid!==null?
    //<div className="form-row col-md-6 text-left form-switch mt-3">
 <div className="form-group col-md-4 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.Genadv} onChange={this.handleGenadv} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.Genadv===false?"Against Bank":"Against Gen Advance"}</span>
       </div>
       //</div>
       :""}
    </div>

    {/* {this.state.CTDS===true?
<div className="form-row mt-0 mb-0 sm "> 
  <fieldset  className="form-group col-md-4 mt-0 mb-2"> 
  <Autocomplete id="itcs" className="tcs-select text-sm" name="atcs" options={this.state.tdsdata1}
  getOptionLabel={(option) => option.name}
   value={values.tcs?undefined:undefined} onChange={(e, value) => { setFieldValue("ctds",value);}}
   renderInput={params => (
   <FormikTextField {...params}  type="text" name="ctds" className="text-sm" label="Select TDS"
   //onBlur={()=>setFieldValue("tcstotal",tcsso)} 
   variant="standard" fullWidth />)}/>
  </fieldset>
      <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="ctcstotal"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" //onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField></fieldset>
   
</div>
:""} */}

<div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.CTDS} onChange={this.handlecTDS} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.CTDS===false?"Loss/Gain":"TDS"}</span>
       </div>

<div className="form-row" > 

 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={this.state.debvalue}
  getOptionLabel={(option) => option.name } 
  groupBy={(option) =>option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
  // getOptionSelected={(option) => option.name } 
  value={values.debit?undefined:undefined} 
  //renderOption={(option) => option.th==="TH3"?<span className='text-green'>{option.name+"("+option.amount+")"}</span>:<span className='text-red'>{option.name+"("+option.amount+")"}</span>}
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label={this.state.CTDS===false?"Loss/Gain Ledger":"TDS Ledgers"} className="text-sm"
  onBlur={()=> this.state.totamt!==null?
    setFieldValue("debitval",this.state.totamt) && this.onDebBlur(values):
    this.state.totalpending!==null?setFieldValue("debitval",this.state.transpendingamount!==undefined?Math.round(parseFloat(this.state.transpendingamount)*100)/100:Math.round(parseFloat(this.state.totalpending)*100)/100)
    && this.onDebBlur(values):this.state.advsoamt===null?setFieldValue("debitval",this.state.totalpending) 
    && this.onDebBlur(values):setFieldValue("debitval",this.state.totamt) && this.onDebBlur(values)} 
  
  variant="standard" fullWidth />)}/></fieldset>
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField></fieldset>
  </div>     

<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={this.state.credvalue}
  getOptionLabel={(option) => option.name +" ("+option.amount+")"}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard" fullWidth />)}/>
</fieldset>

 <fieldset  className="form-group col-md-5" disabled> 
 <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField>  
 </fieldset>
 </div>

 {/* {ccountry==="India"  && this.state.advsoamt===0 && this.state.tcsfromso!==null ?
 <>
 {this.state.tcsfromso!==0?
<div className="form-row mt-0 mb-0 sm "> 
  <fieldset  className="form-group col-md-4 mt-0 mb-2"> 
  <Autocomplete id="itcs" className="tcs-select text-sm" name="atcs" options={tcsdata}
  getOptionLabel={(option) => option.name}
   value={values.tcs?undefined:undefined} onChange={(e, value) => { setFieldValue("tcs",value);}}
   renderInput={params => (
   <FormikTextField {...params}  type="text" name="tcs" className="text-sm" label="Select TCS"
   onBlur={()=>setFieldValue("tcstotal",tcsso)} variant="standard" fullWidth />)}/>
  </fieldset>
    <fieldset className="form-group col-md-2 mt-0 mb-2 test-sm" disabled >
      <NumericFormat name="tcstotal" className="form-control" value = {tcsso}// value={(values.tcsval * values.tcspercent) / 100}
       label={tcsso!=='' ? " " : "Value"} displayType={"input"} customInput={FormikTextField} decimalScale={2}
       fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
     </fieldset>
   </div>:""}</>:null}  */}



 {/* {this.state.pendingcn!==null && this.state.pendingcn.length!==0 ? <>

  <fieldset className="form-group col-md-6 mt-3 mb-4">
  <Autocomplete id="loss" className="loss-select" name="loss" value={"expense" ? undefined : undefined}
  options={this.state.expval.sort((a, b) => -a.subname.localeCompare(b.subname))}
  groupBy={(option) => option.subname}
  getOptionLabel={(option) => option.name}  
  onChange={(e, value) => {setFieldValue("loss", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="loss" label="Map Expense and Losses" className="form-control"
  size='small' onChange={(e) => this.expLedgers(e.target.value)}
  variant="standard" fullWidth /> )} /> </fieldset> </>:""} */}



 {/* <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="receiptref" className="form-control" label="Receipt Ref No"
  variant="standard"/></fieldset></div> */}

  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>




<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : null}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="hovbuttonColour btn btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     
 </div>
 </>
)
}
}

export default withRouter(SettlementSales);              



 
