import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {
  clientname,
  currentTenant,
  logo,
  thousandsdisplay,
  userid,
} from "../Common/Credentials.js";
import { spin } from "../Common/NewLoader.jsx";
import { assetmanagementid } from "../Common/Permissions.js";
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import Menu from '../Component/Menu'

import MUIDataTable from "mui-datatables";
import CustomToolbar from "../CommonComponents/CustomToolbar.js";

import { ThemeProvider } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import AssetsService from "./AssetsService.js";
import moment from "moment";
import { NumericFormat } from "react-number-format";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class Assetmaintenance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data:[],
      message: "",
      loading: false,
    };
   
  }

  // handleChange=()=>{
  //   this.setState({producttype:!this.state.producttype})
  // }

  
  pdf() {
    const columns = [{header:"Item Name",dataKey:'name'},{header:"Item Code",dataKey:'itemcode'},{header:"HSN Code",dataKey:'hsn'},{header:"Tax(%)",dataKey:'tax'},{header:"Total Stock",dataKey:"stock"},{header:"Unit of Measurement",dataKey:"uomname"},{header:"Category",dataKey:'category'},{header:"Latest Purchase Price",dataKey:"cost"}]
    var doc = new jsPDF('p', 'pt');
    doc.addImage(logo, "GIF",  40, 20, 60, 60)
    doc.setFontSize(16)
    doc.setFont('Times-Roman')
    doc.text(`${clientname}`,180,40)
    doc.setFontSize(14)
    doc.text(`Items / Products Table`,210,80)
    doc.line(0, 90, 600, 90);
    autoTable(doc,{
      theme:'grid',
      startY: 100,
      columnStyles: { 7: { halign: 'right'} },
      body: this.state.items,
      columns:columns,
    });
    doc.save("ItemTable.pdf");
  }



  updateItemClicked(id) {
    this.props.navigate({
      pathname: `/newitems`,
      state: {
        id: { id },
      },
    });
  }

 

 

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    // this.setState({ loading: true });
    // if (this.props.history.location.state.producttype===null){
    // }else{
    //   this.setState({producttype:this.props.history.location.state.producttype})
    // }

    // this.refreshItems();
    AssetsService.GetAssetsMaintenanceofTenant(currentTenant).then(response=>{
      this.setState({data:response.data})
    })

    // if (this.props !== undefined) {
    //   this.setState({ message: this.props.location.state.message });
    // }
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "asset",
        label: "Asset Id",
        options: { filter: false, sort: true,},
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: false, sort: true,},
      },
    //   {
    //     name: "assettype",
    //     label: "Asset Type",
    //     options: { filter: false, sort: true },
    //   },
    //   {
    //     name: "warehouse",
    //     label: "Warehouse",
    //     options: { filter: false, sort: true },
    //   },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "type",
        label: "Type",
        options: { filter: true, sort: true },
      },
      {
        name: "totalamount",
        label: "Amount",
        options: { filter: true, sort: true },
        customBodyRender:(value)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={value} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
      },
      {name: "createdAt",label: "Date",options: {filter: false,sort: true,
        customBodyRender:(value)=>{
         return(moment(value).format('DD-MM-YYYY HH:mm'))
        } 
      }},
      {
        name: "description",
        label: "Description",
        options: { filter: true, sort: true },
      },
    //   {
    //     name: "Action ",
    //     options: {
    //       filter: false,
    //       sort: false,
    //       empty: true,
    //       display: inventoryid === "2" || inventoryid === "3" ? true : false,
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         return (<>
    //           <Link
    //             as="button"
    //             data-tip
    //             data-for="edit"
    //             className="fa fa-edit textColour mr-2"
    //             to={{
    //               pathname: "/newasset",
    //               state: {
    //                 id: tableMeta.rowData.find((e) => e),
    //                 pagename: "Update Item",
    //               },
    //             }}
    //           ></Link>
    //           |
    //           <Link
    //             as="button"
    //             data-tip
    //             data-for="view"
    //             className="fa fa-list-alt textColour textColour ml-2"
    //             to={{
    //               pathname: "/assetdetails",
    //               state: this.state.data.find(e=>e.id ===tableMeta.rowData[0])
    //             }}
    //           ></Link>
    //            //<Tooltip id="edit" place="top" effect="solid">
    //               Edit
    //             </Tooltip>
    //             //<Tooltip id="view" place="top" effect="solid">
    //               View
    //             </Tooltip>
    //        </> );
    //       },
    //       setCellProps: (value) => ({ style: { textAlign: "center" } })
    //     },
    //   },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print:false,
      customToolbar: () => {
        return (
          <>
          {assetmanagementid === "2" || assetmanagementid === "3" ?
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newassetmaintenance"}} state ={ { id: -1, pagename: "New Asset Maintenance" } 
                } 
              >
                <CustomToolbar />
              </Link>:''}
          
             <span as="button" onClick={this.pdf.bind(this)}>
             <React.Fragment>
        <Tooltip title={"pdf"}>
          <IconButton>
          <i className="fa fa-file-pdf"></i>
          </IconButton>
        </Tooltip>
      </React.Fragment>

             </span>
          </>
        );
      },
      // setRowProps: row => { 
      //   if ((row[3].substr(0, 10)) === moment().format('DD-MM-YYYY')) {
      //     return {
      //       style: { background: "#ecf8fb" }
      //     };
      //   }
      // }
    };

    return (
      <div>
       <Header />
                 <Menu/>
              <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement" className="text-info">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active">Asset Maintenance / Expenses</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.card */}

                  {/* <div className="card"> */}
                    <div className="card-header m-0 p-2">
                      <div className="text-center text-green">
                        {/* {this.state.message} */}
                      </div>
                    </div>

                    {/* <div className="card-body p-0"> */}
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <ThemeProvider theme={theme}> 
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Asset Maintenance / Expenses"}
                            id="assets"
                            data={this.state.data}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                        )}
                      </div>
                    {/* </div> */}
                  {/* </div> */}

                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
      <Footer/>
      </div>
    );
  }
}
export default withRouter(Assetmaintenance);

