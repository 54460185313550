// import { Field, Form, Formik } from 'formik';
import { Form, Formik } from 'formik';
import React, { Component } from 'react'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {ColorRing} from "react-loader-spinner";
import { Link } from 'react-router-dom';
import { currentTenant, currentUser, userid, userwhid } from '../Common/Credentials.js';
import Footer from '../Component/Footer.js';
import Header from '../Component/Header.js';
import Menu from '../Component/Menu.js';
import JournalForms from './JournalForms.jsx';
import NewJournalService from './NewJournalService.js';
import DynamicTable from './DynamicTable.jsx';
import SubAccService from '../Masters/Subaccounts/SubAccService.js';
import JournalPurchase from './JournalPurchase.jsx';
import JournalSales from './JournalSales.jsx';
import JournalDirectSales from './JournalDirectSales.jsx';
import PurchseServices from '../Purchase/PurchseServices.js';
import SalesServices from '../Sales/SalesServices.js';
import UomServices from '../Masters/Uom/UomServices.js';
import Swal from 'sweetalert2'
import CommonEntry from './CommonEntry.jsx';
import DirectEntry from './DirectEntry.jsx';
import { Backdrop, CircularProgress } from '@mui/material';
import OpeningEntries from './OpeningEntries.jsx';
import MultiEntry from './MultiEntry.jsx';



export let jtype,aedata,jname,combdata,incombdata,outcombdata,expcombdata,iecombdata,totalcombdata,personal,real,nom,ctype,lgcombdata,lgcombdata1,assets,incomecombdata,
transtype,gsttype,subacclist,uom,saltranstype,appgrn,appinv,saedata,typeofentry,indirecttax,directtax,invechecked,directtaxasset,reserveasset,BranchList;
// export let jname;
// export let combdata;
// export let personal;
// export let real;
// export let nom;
// export let ctype;
let cbradio;
let val1=null;
let inventrycheckedone=null
let transradio;
let saltransradio;
let gstradio,entrybutton;

export default class Journal extends Component {
    constructor(props) {
        super(props)
    
    
        this.state = {
            journaltypes:[
                {"id":1,"name":"Journal Entry"},
                {"id":2,"name":"Purchase"},
                {"id":3,"name":"Sales"},
                {"id":4,"name":"Contra"},
                // {"id":5,"name":"Payment"},
                // {"id":6,"name":"Receipt"},
                // {"id":7,"name":"Receipt"},
                //{"id":8,"name":"Test"},
               
            ],
            contratype:[{"id":1,"name":"Deposit"},{"id":2,"name":"Withdrawal"}],
            transtype:[{"id":1,"name":"Credit"},{"id":2,"name":"Instant"}],
            cbchecked:'',
            inventrycheckedone:'',
            invechecked:'',
            gsttype:[{id:1,name:"Within State"},{id:2,name:"Inter State"}],
            typeofentrylist:[
              {"id":1,"name":"Expenses"},
              {"id":2,"name":"Common Entry"},
              // {"id":3,"name":"Common"},   
              {"id":4,"name":"Direct Entry"}, 
              {"id":5,"name":"Openings"},       
              {"id":6,"name":"Multi Entry"},
    
          ],
          inventrytypes: [
            { id: 0, name: "SO Transactions" },
            { id: 1, name: "Direct Transactions" },
          ],
          //checked:null,
            btncolor:true,
            btncolor1:true,
            loading:false,
            checked:null,
            checked1:null,
            loader1:true
    
        }
        // this.handleFocus=this.handleFocus.bind(this);
        this.cbcontra=this.cbcontra.bind(this)
        this.refreshData=this.refreshData.bind(this)
        this.transtype=this.transtype.bind(this)
        this.TypeOfEntry=this.TypeOfEntry.bind(this)
        this.invEntry=this.invEntry.bind(this)
      }

      changeColor(){
        this.setState({btncolor: !this.state.btncolor})
        this.setState({btncolor1: !this.state.btncolor1})

     }
      onChange(option){
        // console.log(option.id)
       this.setState({checked: option.id});
       //console.log(this.state.checked)
       if(this.state.checked===option.id){
         this.setState({btncolor:"btn btn-primary"})
       } else{
        this.setState({btncolor:"btn btn-outline-primary"})
       }
       jtype=option.id

      //  console.log(jtype)
       jname=option.name
      //  console.log(jname)
    }

    onINVEntryCheck(option) {
      this.setState({ invechecked: option.id },()=>{
      // this.setState({inventrycheckedone:inventrycheckedone})
      // this.setState({checked1: option.id})

      if(this.state.invechecked===option.id){
        this.setState({btncolor1:"btn btn-primary"})
      } else{
       this.setState({btncolor1:"btn btn-outline-primary"})
      }})
      invechecked=option.id
    }

    refreshData(){
      // NewJournalService.retriveJournalTypes(currentTenant)
      // .then(response =>{
      //   this.setState({journaltypes:response.data})
      //   this.setState({loading:false})

      // })  

      SubAccService.retriveAllSubAcc(currentTenant)
      .then(response =>{
        subacclist=response.data
        this.setState({loading:false})
      })
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
          Swal.fire({
            title: "Please Check !!!",
            text: "Your Session is Lost: Please login Back to resume",
            icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          })
            this.props.navigate(`/`);
        }
    })

      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        val1=response.data
        totalcombdata=val1
        let th1=val1.filter(e=>e.th==="TH1")
        let th2=val1.filter(e=>e.th==="TH2")
        let th3=val1.filter(e=>e.th==="TH3")
        let th4=val1.filter(e=>e.th==="TH4")
        let th5=val1.filter(e=>e.th==="TH5")
        let th6=val1.filter(e=>e.th==="TH6")
        let th7=val1.filter(e=>e.th==="TH7")


        // let th6=val1.filter(e=>e.th==="TH6")
        combdata=th3.concat(th4)
        let val2=th1.concat(th2)
        let val3=val2.concat(th5)
        iecombdata=th1.concat(th2)
        incomecombdata=th1.concat(th6)
        let newval=combdata.concat(th5)
        let newval1=newval.concat(th6)
        lgcombdata=newval1.concat(th7)
        lgcombdata1=lgcombdata.concat(th2)
        expcombdata=th2.concat(th5)
        indirecttax=val1.filter(e=>e.taxtype===2)
        directtax=val1.filter(e=>e.taxtype===1)
        directtaxasset=th3.filter(e=>e.subheadid==="AH000024")
        let a=th3.filter(e=>e.subheadid==="AH000002")
        reserveasset=a.concat(th3.filter(e=>e.subheadid==="AH000023"))
        this.setState({loading:false})
        //let newone=th3.concat(th4)
        let valout = th3.concat(th4) //newone.concat(th7)
        assets=th3.filter(e=>e.subheadid!=="AH000024")
        //let tmpincom=valout.filter(e=>e.type===0 )
        incombdata=valout.filter(e=>e.type===0)//&& e.taxtype===0 && e.shid!==100004 && e.shid!==100003  e.type===0 ||
        outcombdata=valout.filter(e=>e.type===1)//&& e.taxtype===0 && e.shid!==100004 && e.shid!==100003  e.type===1 ||


       })

       UomServices.getUOM(currentTenant) 
       .then(response=>{
        uom=response.data
        this.setState({loading:false},()=>this.setState({loader1:false}))

       })

       let data = {
        tenantid:currentTenant,
        userid:currentUser,
       whid:userwhid,
        // "deptid":"DEPT1001"
    }

       PurchseServices.getApprovedGrn(data)
       .then(response=>{
         appgrn=response.data
         this.setState({loading:false},()=>this.setState({loader1:false}))

       })

       SalesServices.getApprovedInvoices(data)
       .then(response=>{
        appinv=response.data
         this.setState({loading:false},()=>this.setState({loader1:false}))

       })

       PurchseServices.getAdvancePayments(currentTenant)
       .then(response=>{
         aedata=response.data
         this.setState({loading:false},()=>this.setState({loader1:false}))

       })
       SalesServices.getAdvancePayments(currentTenant)
       .then(response=>{
         saedata=response.data
         this.setState({loading:false},()=>this.setState({loader1:false}))

       })

       NewJournalService.GetBranch(currentTenant).then((response)=>
        BranchList=response.data
      );
      // NewJournalService.getNomLedgers(currentTenant)
      // .then(response=>{
      //   nom=response.data
      //   this.setState({loading:false})

      //  // this.setState({nom:response.data})
      // })
      // NewJournalService.getParLedgers(currentTenant)
      // .then(response=>{
      //   personal=response.data
      //   this.setState({loading:false})

      //   //this.setState({personal:response.data})
      // })
      // NewJournalService.getRealLedgers(currentTenant)
      // .then(response=>{
      //   real=response.data
      //   this.setState({loading:false})

      //   //this.setState({real:response.data})
      // })
      
    }

    componentDidMount(){
      inventrycheckedone=null
      typeofentry=''

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }else{
        this.setState({loading:true})
      this.refreshData()
      }
     
    }

    // componentDidUpdate(){
    //   if(this.state.loading===true){
    //     this.refreshData()
    //   }
    // }

    cbcontra(){
       <JournalForms/>

      cbradio=this.state.contratype.map((cboption)=>{
                   
        return <label key={cboption.id} >

            <div className="form-group col-md-12">
              <div className="radio-item">
              {/* <div className="form-control bg-light border-light"> */}

                     <input 
                        type="radio" 
                        checked={this.state.cbchecked === cboption.id ? true : false} 
                        key={cboption.id}
                        onChange={this.onCBChange.bind(this,cboption)} 
                        style={{marginRight:8}}
                        value={cboption.id} 
                        />
                    {cboption.name}
                    </div></div>
                    {/* </div> */}
                </label>
                }
                )}

    transtype(){
                  // <JournalForms/>
            
                  transradio=this.state.transtype.map((transoption)=>{
                               
                    return <label key={transoption.id} >
            
                        <div className="form-group col-md-12">
                          <div className="radio-item">
                          {/* <div className="form-control bg-light border-light"> */}
            
                                 <input 
                                    type="radio" 
                                    checked={this.state.transchecked === transoption.id ? true : false} 
                                    key={transoption.id}
                                    onChange={this.onTransChange.bind(this,transoption)} 
                                    style={{marginRight:8}}
                                    value={transoption.id} 
                                    />
                                {transoption.name}
                                </div>
                                </div>
                                {/* </div> */}
                            </label>
                            }
                            )}

         saletranstype(){
                              // <JournalForms/>
                        
                              saltransradio=this.state.transtype.map((saltransoption)=>{
                                           
                                return <label key={saltransoption.id} >
                        
                                    <div className="form-group col-md-12">
                                      <div className="radio-item">                        
                                             <input
                                                type="radio" 
                                                checked={this.state.saltranschecked === saltransoption.id ? true : false} 
                                                key={saltransoption.id}
                                                onChange={this.onSalesTransChange.bind(this,saltransoption)} 
                                                style={{marginRight:8}}
                                                value={saltransoption.id} 
                                                />
                                            {saltransoption.name}
                                            </div>
                                            </div>
                                        </label>
                                        }
                                        )}

      invEntry(){
        // invechecked

        // <div className="form-group col-md-12 text-sm mt-0 mb-2 float-right">
        inventrycheckedone=this.state.inventrytypes.map((option) => {
          return (
            <label key={option.id}>
              <div className="form-group  col-md-12">
               <div className="btn-group">
                  <input
                  //  type="radio" 
                  //  checked={this.state.invechecked === invechecked ? true : false} 
                    className={
                      this.state.invechecked === option.id
                      ? "btn buttonColour text-yellow text-bold "
                        : "btn hovbuttonColour text-white"
                    }
                    key={option.id}
                    onClick={this.onINVEntryCheck.bind(this, option)}
                    style={{ marginRight: 8,width:525 }}
                    value={option.name}
                  />
                </div>
              </div>
            </label>
          );
        })
      // </div>



      }

      gsttype(){
                              // <JournalForms/>
                        
                              gstradio=this.state.gsttype.map((gstoption)=>{
                                           
                                return <label key={gstoption.id} >
                        
                                    <div className="right form-group col-md-12">
                                      <div className="radio-item">
                                      {/* <div className="form-control bg-light border-light"> */}
                        
                                             <input 
                                                type="radio" 
                                                checked={this.state.gstchecked === gstoption.id ? true : false} 
                                                key={gstoption.id}
                                                onChange={this.onGstChange.bind(this,gstoption)} 
                                                style={{marginRight:8}}
                                                value={gstoption.id} 
                                                />
                                            {gstoption.name}
                                            </div></div>
                                            {/* </div> */}
                                        </label>
                                        }
                                        )}
  
    onCBChange(cboption){
    this.setState({cbchecked: cboption.id});
             
      ctype=cboption.id
                  //console.log("ctype +++ "+ ctype )
      }

      TypeOfEntry(){
        entrybutton=this.state.typeofentrylist.map((option)=>{

          return <label key={option.id} >
  
              <div className="form-group col-md-9 text-sm">
                <div className="btn-group">
                {/* <div className="form-control bg-light border-light"> */}
  
                       <input 
                          className={this.state.checked1===option.id?
                            "btn buttonColour text-yellow text-bold"
                            : "btn hovbuttonColour test-white "
                            // "btn btn-sm btn-outline-info":"btn btn-sm btn-info"
                          }
                          type="button" 
                          //checked={this.state.checked === option.id ? true : false} 
                          key={option.id}
                          onClick={this.onChangetoe.bind(this,option)} 
                          style={{marginRight: 8,width:120}}
                          value={option.name} 
                          />
                      {/* {option.name} */}
                  
                      </div></div>
                      {/* </div> */}
                  </label>
                 
  
      })
  

      }

      onTransChange(transoption){
        this.setState({transchecked: transoption.id});      
          transtype=transoption.id
      }
      onSalesTransChange(saltransoption){
        this.setState({saltranschecked: saltransoption.id});      
          saltranstype=saltransoption.id
      }

      onGstChange(gstoption){
       this.setState({gstchecked: gstoption.id});
  
       gsttype=gstoption.id
     
    }

    onChangetoe(option){
      this.setState({checked1: option.id})

     if(this.state.checked1===option.id){
       this.setState({btncolor1:"btn btn-primary"})
     } else{
      this.setState({btncolor1:"btn btn-outline-primary"})
     }
     typeofentry=option.id
  
    
  
  
    //  console.log(jtype)
     //jname=option.name
    //  console.log(jname)
  }

    render() {
      // const {typeofentrylist} = this.state
      let btn_class = this.state.btncolor?"btn btn-outline-primary":"btn btn-primary"
      let btn_class1= this.state.btncolor1?"btn btn-outline-primary":"btn btn-primary"


        const {journaltypes} = this.state
        let spin = <ColorRing
        type="Watch"
        color="Blue"
        height={100}
        width={200}
        timeout={10000} //3 secs
      />
        

        return (
          <>
<Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="content-wrapper">

          <div className="card card-info">

            {/* <div className="card-header sm ">
              <h3 className="card-title">Journals</h3>
              <h6 className="text-center">{jname!==undefined?jname:''}</h6>  

            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
              <Link to={{pathname: '/journals'}} state= {{message:''}} >
                          Journals
                        </Link>
              </li>
              <li className="breadcrumb-item active">{jname!==undefined?jname:''}</li>

            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
            <div className="text-center">
            {/* {this.state.loading===true?spin:''} */}
            </div>

            <Formik
            initialValues={{journaltypes,btn_class,btn_class1}}
            enableReinitialize={true}>
            {() => (
              
              <Form className="form-sm">
    
            <div className="form-row">

            {journaltypes.map((option)=>{

                    return <label key={option.id} >

                        <div className="form-group col-md-9 text-sm">
                          <div className="btn-group">
                          {/* <div className="form-control bg-light border-light"> */}

                                 <input 
                                className={this.state.checked===  option.id
                                  ? "btn buttonColour text-yellow text-bold"
                                  : "btn hovbuttonColour text-white"

                                  // ?"btn btn-sm btn-outline-info":"btn btn-sm btn-info"
                                }
                                    // className="btn btn-info"
                                    type="button" 
                                    // checked={this.state.checked === option.id ? true : false} 
                                    key={option.id}
                                    onClick={this.onChange.bind(this,option)} 
                                    style={{marginRight: 8,width:250}}
                                    value={option.name} 
                                    />
                                {/* {option.name} */}
                            
                                </div></div>
                                {/* </div> */}
                            </label>
                           

                })
            }
            
            </div>  
            <hr/> 

       
            </Form>  

            )}

</Formik>

              {/* <div className="form-row">
              <div  className="form-group col-md-8"> */}
              {this.state.checked!==undefined & this.state.checked===1?this.TypeOfEntry():''}
              {/* {this.state.checked!==undefined & this.state.checked===2?this.transtype():''} */}
              {this.state.checked!==undefined && this.state.checked===2 && val1!==null?<JournalPurchase/>:''}
              {this.state.checked!==undefined && this.state.checked===3 && val1!==null?this.invEntry():''}
              {/* {this.state.checked!==undefined & this.state.checked===3 & val1!==null?<JournalSales/>:''} */}
              {this.state.checked!==undefined & this.state.checked===4 & val1!==null?this.cbcontra():''}
              {/* {this.state.checked!==undefined & this.state.checked===5 & val1!==null?<JournalPayment/>:''} */}
              {/* {this.state.checked!==undefined & this.state.checked===6 & val1!==null?<JournalReceipts/>:''} */}
              {this.state.checked!==undefined & this.state.checked===7 & val1!==null ?<JournalForms/>:''}
              {this.state.checked!==undefined & this.state.checked===8 & val1!==null?<DynamicTable/>:''}
              {this.state.checked!==undefined & this.state.checked===9?<JournalForms/>:''}
              {this.state.checked!==undefined & this.state.checked===10?<JournalForms/>:''}
              {this.state.checked!==undefined & this.state.checked===11?<JournalForms/>:''}

              
              {this.state.checked!==undefined & this.state.checked===1?entrybutton:''}
              {this.state.checked!==undefined & this.state.checked===1 & typeofentry===1 & val1!==null?<DynamicTable/>:""}
              {this.state.checked!==undefined & this.state.checked===1 & typeofentry===2 & val1!==null?<CommonEntry/>:""}
              {/* {this.state.checked!==undefined & this.state.checked===1 & typeofentry===3 & val1!==null?<DynamicTable/>:""} */}
              {this.state.checked!==undefined & this.state.checked===1 & typeofentry===4 & val1!==null?<DirectEntry/>:""}
              {this.state.checked!==undefined & this.state.checked===1 & typeofentry===5 & val1!==null?<OpeningEntries/>:""}
              {this.state.checked!==undefined & this.state.checked===1 & typeofentry===6 & val1!==null?<MultiEntry/>:""}


              {this.state.checked!==undefined & this.state.checked===3?inventrycheckedone:''}
              {this.state.checked!==undefined & this.state.checked===3 & this.state.invechecked===0 ?<JournalSales/>:""}
              {this.state.checked!==undefined & this.state.checked===3 & this.state.invechecked===1 ?<JournalDirectSales/>:""}





              {this.state.checked!==undefined & this.state.checked===4?cbradio:''}
              {this.state.cbchecked!==undefined & this.state.cbchecked===1 & this.state.checked!==undefined & this.state.checked===4 & val1!==null?<JournalForms/>:''}
              {this.state.cbchecked!==undefined & this.state.cbchecked===2 & this.state.checked!==undefined & this.state.checked===4 & val1!==null?<JournalForms/>:''}

              {this.state.checked!==undefined & this.state.checked===2 ?transradio:''}
              {this.state.transchecked!==undefined & this.state.transchecked===1 & this.state.checked!==undefined & this.state.checked===2 ?this.gsttype():''}
              {this.state.transchecked!==undefined & this.state.transchecked===2 & this.state.checked!==undefined & this.state.checked===2 ?this.gsttype():''}

              {this.state.checked!==undefined & this.state.checked===3 ?saltransradio:''}
              {this.state.saltranschecked!==undefined & this.state.saltranschecked===1 & this.state.checked!==undefined & this.state.checked===3 ?this.gsttype():''}
              {this.state.saltranschecked!==undefined & this.state.saltranschecked===2 & this.state.checked!==undefined & this.state.checked===3 ?this.gsttype():''}

 <div className="form-row">
              <div  className="form-group col-md-12">
              {this.state.checked===2 & this.state.checked!==undefined & this.state.transchecked===1 ?gstradio:''}
              {this.state.checked===2 & this.state.gstchecked!==undefined & this.state.gstchecked===1 & this.state.transchecked!==undefined & this.state.transchecked===1 & val1!==null ?<JournalPurchase/>:''}
              {this.state.checked===2 & this.state.gstchecked!==undefined & this.state.gstchecked===2 & this.state.transchecked!==undefined & this.state.transchecked===1 & val1!==null?<JournalPurchase/>:''}

              {this.state.checked===2 & this.state.checked===2 & this.state.checked!==undefined & this.state.transchecked===2 ?gstradio:''}
              {this.state.checked===2 & this.state.gstchecked!==undefined & this.state.gstchecked===1 & this.state.transchecked!==undefined & this.state.transchecked===2 & val1!==null?<JournalPurchase/>:''}
              {this.state.checked===2 & this.state.gstchecked!==undefined & this.state.gstchecked===2 & this.state.transchecked!==undefined & this.state.transchecked===2 & val1!==null ?<JournalPurchase/>:''}

              {this.state.checked===3 & this.state.checked!==undefined & this.state.saltranschecked===1 ?gstradio:''}
              {this.state.checked===3 & this.state.gstchecked!==undefined & this.state.gstchecked===1 & this.state.saltranschecked!==undefined & this.state.saltranschecked===1 & val1!==null ?<JournalSales/>:''}
              {this.state.checked===3 & this.state.gstchecked!==undefined & this.state.gstchecked===2 & this.state.saltranschecked!==undefined & this.state.saltranschecked===1 & val1!==null ?<JournalSales/>:''}

              {this.state.checked===3 & this.state.checked!==undefined & this.state.saltranschecked===2 ?gstradio:''}
              {this.state.checked===3 & this.state.gstchecked!==undefined & this.state.gstchecked===1 & this.state.saltranschecked!==undefined & this.state.saltranschecked===2 & val1!==null ?<JournalSales/>:''}
              {this.state.checked===3 & this.state.gstchecked!==undefined & this.state.gstchecked===2 & this.state.saltranschecked!==undefined & this.state.saltranschecked===2 & val1!==null ?<JournalSales/>:''}
</div>
</div>
 </div>

          </div>

        </div>



           <Footer/>     
            </div>
            </>
        )
    }
}
