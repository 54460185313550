import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import {
  clientname,
  currentTenant,
  dataaccess,
  financialyearstart,
  logo,
  thousandsdisplay,
  userid,
  userwhid,
} from "../../Common/Credentials";
import InventoryService from "../InventoryService";
import moment from "moment";
import { spin } from "../../Common/NewLoader.jsx";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { inventoryid } from "../../Common/Permissions";
import {NumericFormat} from "react-number-format";
import { Box, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider,Typography,TablePagination, Grid, TextField  } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SalesServices from "../../Sales/SalesServices.js";
import ProductionServices from "../../Production/ProductionServices.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class StockDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stock: [],
      itemstock: [],
      itemstockdata:[],
      thirdpartystockdata:[],
      loading: false,
      itemid:"",
      itemname:"",
      type:"Stock Details",
      stocktype:"Own Stock",
      batchstockdata:[],
      salesconsumptiondata:[],
      productionconsumptiondata:[],
      page: 0,
      rowsPerPage:10,
      fromdate:moment(new Date()).subtract('7','days'),
      todate:new Date(),
    };
    this.refreshStocks = this.refreshStocks.bind(this);
  }

  updateStockClicked(id) {
    this.props.navigate({
      pathname: `/updatestock`,
      state: {
        id: { id },
      },
    });
  }

  
  handleFromDateChange = (date) => {
    this.setState({ fromdate: moment(new Date(date))});
  };
  handleToDateChange = (date) =>{
    this.setState({ todate: moment(new Date(date))});
  }

  Submit = () =>{
    
   let datadao = {
    tenantid:currentTenant,
    startdate:this.state.fromdate,
    enddate:this.state.todate,
    userid:userid,
    dataaccess:dataaccess,
    whid:userwhid,
    id:"",
    itemid:this.state.itemid
   }

    InventoryService.getbyitemforconsumption(datadao).then((response) =>{
      this.setState({batchstockdata: response.data.sort((a, b) => (a.batchid > b.batchid ? 1 : -1))})
  
      let datadao2 = {
        tenantid:currentTenant,
        startdate:this.state.fromdate,
        enddate:this.state.todate,
        userid:userid,
        dataaccess:dataaccess,
        whid:userwhid,
        id:"",
        itemid:this.state.itemid,
        list:response.data.map(e=>e.batchid)
       }
      SalesServices.getdelivereditems(datadao2).then((response) =>
        this.setState({salesconsumptiondata: response.data})
      )
  
      ProductionServices.Storeaddedquantity(datadao2).then((response) =>
        this.setState({productionconsumptiondata: response.data})
      )
  
    })
  
  }


  handleChangePage = (event, newPage) => {
    this.setState({page:newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:+event.target.value,page:0});
  };

  refreshStocks() {
    InventoryService.retriveStock(currentTenant)
      .then((response) => {
        this.setState({
          stock: response.data.sort((a, b) => (a.date < b.date ? 1 : -1)),
        });
        this.setState({ loading: false });

        this.Data(response.data)
      })
      .catch((e) => {
        if (e.response?.data?.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
      InventoryService.GetThirdpartystock(currentTenant)
      .then((response) => {
        this.setState({
          thirdpartystockdata: response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
        });
      })
      .catch((e) => {
        if (e.response?.data?.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
      
  }

  Data = (data) =>{
    const products = data.reduce(
      (
        item,
        {
          name,
          id,
          totalStock,
          batchid,
          batchstock,
          brand,
          brandid,
          closingStock,
          createdby,
          createdbyname,
          currentStock,
          custombatchid,
          date,
          openingStock,
          rate,
          stockfrom,
          stockitemtype,
          stocktype,
          supplier,
          supplierid,
          tenantid,
          uomname,
          waddress,
          whstock,
          wid,
        }
      ) => {
        if (!item[name]) item[name] = [];
        item[name].push({
          id: id,
          name: name,
          totalStock: totalStock,
          batchid:batchid,
          batchstock:batchstock,
          brand:brand,
          brandid:brandid,
          closingStock:closingStock,
          createdby:createdby,
          createdbyname:createdbyname,
          currentStock:currentStock,
          custombatchid:custombatchid,
          date:date,
          openingStock:openingStock,
          rate:rate,
          stockfrom:stockfrom,
          stockitemtype:stockitemtype,
          stocktype:stocktype,
          supplier:supplier,
          supplierid:supplierid,
          tenantid:tenantid,
          uomname:uomname,
          waddress:waddress,
          whstock:whstock,
          wid:wid,
        });
        return item;
      },
      {}
    );
    this.setState({ products: products });
    // End of Sorting Products

     // Calcuating total sales by products
     let stockdata = []
     Object.entries(products).forEach(([key, value]) => {
      let a = {
        name: key,
        id: value.map((e) => e.id)[0],
        brand:  value.map((e) => e.brand)[0],
        model: value.map((e) => e.model)[0],
        uomname:value.map((e) => e.uomname)[0],
        totalStock: value.map((e) => e.totalStock)[0],
        avg: value.reduce((a, v) => (a = a + v.rate), 0)/value.length,
        fifo: value.sort((a, b) => (a.date > b.date ? 1 : -1)).map((e) => e.rate)[0],
        lifo:value.sort((a, b) => (a.date < b.date ? 1 : -1)).map((e) => e.rate)[0],
      };
      stockdata.push(a);
    });
    this.setState({itemstockdata:stockdata})  
  }

  handleselect = (data,value) =>{
   this.setState({itemstock: this.state.stock.filter((e) => e.id ===data),itemid:data,itemname:value,type:"Stock Details",
    fromdate:moment(new Date()).subtract('7','days'),todate:new Date()
    })

   let datadao = {
    tenantid:currentTenant,
    startdate:moment(new Date()).subtract('7','days'),
    enddate:new Date(),
    userid:userid,
    dataaccess:dataaccess,
    whid:userwhid,
    id:"",
    itemid:data,
   }

   InventoryService.getbyitemforconsumption(datadao).then((response) =>{
    this.setState({batchstockdata: response.data.sort((a, b) => (a.batchid > b.batchid ? 1 : -1))})

    let datadao2 = {
      tenantid:currentTenant,
      startdate:moment(new Date()).subtract('7','days'),
      enddate:new Date(),
      userid:userid,
      dataaccess:dataaccess,
      whid:userwhid,
      id:"",
      itemid:data,
      list:response.data.map(e=>e.batchid)
     }
    SalesServices.getdelivereditems(datadao2).then((response) =>
      this.setState({salesconsumptiondata: response.data})
    )

    ProductionServices.Storeaddedquantity(datadao2).then((response) =>
      this.setState({productionconsumptiondata: response.data})
    )

  })

 

  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshStocks();
  }
  render() {
    const columns = [
      {
        name: "sid",
        label: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      // {
      //   name: "name",
      //   label: "Item name",
      //   options: { filter: true, sort: true },
      // },
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true, customBodyRender: (value,tableMeta) => 
            tableMeta.rowData[8] === "Production"?"Production": value===undefined||value === null || value===""?"Opening":value
         },
      },
      // {name: "brand",label: "Brand",options: {filter: true,sort: true,customBodyRender: (value) => value===""||value=== null||value=== undefined?"NA":value}},
      // {name: "model",label: "Model/Grade",options: {filter: true,sort: true,customBodyRender: (value) => value===""||value=== null||value=== undefined?"NA":value}},
      {
        name: "waddress",
        label: "Org unit",
        options: { filter: true, sort: true },
      },
      {
        name: "custombatchid",
        label: "Batch Id",
        options: { filter: false, sort: true },
      },
      {
        name: "addedStock",
        label: "Added Stock",
        options: { filter: false, sort: true },
      },
      {
        name: "consumedStock",
        label: "Consumed Stock",
        options: { filter: false, sort: true },
      },
      {
        name: "batchstock",
        label: "Current Stock",
        options: { filter: false, sort: true },
      },
      {
        name: "stocktype",
        label: "Stock Type",
        options: { filter: true, sort: true },
      },
      {
        name: "stockfrom",
        label: "Stock From",
        options: { filter: true, sort: true },
      },
      // {
      //   name: "totalStock",
      //   label: "Total Item Stock",
      //   options: { filter: false, sort: true },
      // },
      {
        name: "date",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "rate",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "stockfrom",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: inventoryid === "2" || inventoryid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value==="Direct" && tableMeta.rowData[4] === tableMeta.rowData[6] ?
              <Link
                as="button"
                data-tip
                data-for="edit"
                className="fa fa-edit"
                to={{
                  pathname: "/addstock"}}
                  state= {{
                    id: tableMeta.rowData.find((e) => e),
                    pagename: "Update Stock",
                }}
              ></Link>
              :""
            );
          },
        },
      },
    ];

    const maincolumns = [
      {
        name: "id",
        label: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "name",
        label: "Item name",
        options: { filter: true, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span
                className="text-info cursorPointer"
                onClick={() =>this.handleselect(tableMeta.rowData.find((e) => e),value)}
              >{value}</span>
            );
          },
        },
      },
      {name: "brand",label: "Brand",options: {filter: true,sort: true,customBodyRender: (value) => value===""||value=== null||value=== undefined?"NA":value}},
      {name: "model",label: "Model/Grade",options: {filter: true,sort: true,customBodyRender: (value) => value===""||value=== null||value=== undefined?"NA":value}},
      {
        name: "totalStock",
        label: "Total stock",
        options: { filter: false, sort: true },
      },
      {
        name: "fifo",
        label: "FIFO Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "avg",
        label: "Average Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "lifo",
        label: "LIFO Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    
      // {
      //   name: "View",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     display: inventoryid === "2" || inventoryid === "3" ? true : false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <Link
      //           as="button"
      //           data-tip
      //           data-for="edit"
      //           className="fa fa-list-alt"
      //           to={{
      //             pathname: "/addstock"}}
      //             state= {{
      //               id: tableMeta.rowData.find((e) => e),
      //               pagename: "Update Stock",
      //           }}
      //         ></Link>
      //       );
      //     },
      //   },
      // },
    ];

    const thirdpartycolumns = [
      {
        name: "sid",
        label: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "name",
        label: "Item name",
        options: { filter: true, sort: true },
      },
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true, customBodyRender: (value,tableMeta) => 
            tableMeta.rowData[8] === "Production"?"Production": value===undefined||value === null || value===""?"Opening":value
         },
      },
      {name: "brand",label: "Brand",options: {filter: true,sort: true,customBodyRender: (value) => value===""||value=== null||value=== undefined?"NA":value}},
      {name: "model",label: "Model/Grade",options: {filter: true,sort: true,customBodyRender: (value) => value===""||value=== null||value=== undefined?"NA":value}},
      {
        name: "warehouse",
        label: "Org unit",
        options: { filter: true, sort: true },
      },
      {
        name: "addedStock",
        label: "Added Stock",
        options: { filter: false, sort: true },
      },
      {
        name: "currentStock",
        label: "Current Stock",
        options: { filter: false, sort: true },
      },
      {
        name: "stocktype",
        label: "Stock Type",
        options: { filter: true, sort: true },
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "cost",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      // {
      //   name: "stockfrom",
      //   label: "Edit",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     display: inventoryid === "2" || inventoryid === "3" ? true : false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         value==="Direct" && tableMeta.rowData[5] === tableMeta.rowData[6] ?
      //         <Link
      //           as="button"
      //           data-tip
      //           data-for="edit"
      //           className="fa fa-edit"
      //           to={{
      //             pathname: "/addstock"}}
      //             state= {{
      //               id: tableMeta.rowData.find((e) => e),
      //               pagename: "Update Stock",
      //           }}
      //         ></Link>
      //         :""
      //       );
      //     },
      //   },
      // },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {inventoryid === "2" || inventoryid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/addstock"}}
                  state= {{ id: -1, pagename: this.state.stocktype === "Own Stock"?"Add Stock":"ThirdParty Stock", data: undefined,stocktype:this.state.stocktype === "Own Stock"?"Own":"ThirdParty" }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    
    return (
      <div>
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            {this.state.itemid === "" || this.state.itemid === undefined || this.state.itemid === null? 
              "":<span
              as="button"
              className=" text-secondary cursorPointer" 
             onClick={() => this.setState({itemid:"",itemname:""})}
            >
              ❮ Back &nbsp;&nbsp;
            </span>}
            <li className="breadcrumb-item">
              <Link to="/inventory">Home</Link>
            </li>

           
            {this.state.itemid === "" || this.state.itemid === undefined || this.state.itemid === null? 
               <li className="breadcrumb-item active">Stocks</li>:<>
                <li className="breadcrumb-item text-primary cursorPointer" onClick={() => this.setState({itemid:"",itemname:""})}>Stocks</li>
              <li className="breadcrumb-item active">Stocks Details</li>
              </>}
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.card */}
                  <div className="">
                    {/* <div className="card-header">
                      <h3 className="card-title">Stocks</h3>
                    </div> */}
                    {/* /.card-header */}
                    <div className="card-body">
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center"> {spin}</div>
                        ) : (
                          this.state.itemid === "" || this.state.itemid === undefined || this.state.itemid === null? <>
                          <fieldset className="form-group col-md-12">
                          <fieldset
                                  className={`form-group col-md-3 shadow-sm  text-center btn ${
                                    this.state.stocktype === "Own Stock"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.stocktype === "Own Stock"}
                                  onClick={() => this.setState({stocktype:"Own Stock"})}
                                >
                                  Own Stock
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-3  btn shadow-sm text-center ${
                                    this.state.stocktype === "Third Party Stock"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.stocktype === "Third Party Stock"}
                                  onClick={() => this.setState({stocktype:"Third Party Stock"})}
                                >
                                  Third Party Stock
                                </fieldset>
                              </fieldset>
                              {this.state.stocktype === "Own Stock"?
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm"
                              title={"Stock"}
                              data={this.state.itemstockdata}
                              columns={maincolumns}
                              options={options}
                            />
                          </ThemeProvider>
                          :
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm"
                              title={"Thirdparty stock"}
                              data={this.state.thirdpartystockdata}
                              columns={thirdpartycolumns}
                              options={options}
                            />
                          </ThemeProvider>
                          }
                          </>:
                          
                          <>
                          
                          <fieldset className="form-group col-md-12">
                                <fieldset
                                  className={`form-group col-md-4  shadow-sm text-center btn ${
                                    this.state.type === "Stock Details"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Stock Details"}
                                  onClick={() => this.setState({type:"Stock Details"})}
                                >
                                Stock Details
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-4 shadow-sm btn text-center ${
                                    this.state.type === "Stock Consumption"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Stock Consumption"}
                                  onClick={() => this.setState({type:"Stock Consumption"})}
                                >
                                  Stock Consumption
                                </fieldset>
                              </fieldset>

                              {this.state.type === "Stock Details"?
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm"
                                    title={`${this.state.itemname} - Stock Details`}
                                    data={this.state.itemstock}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                                :<>
                                <div className="row">
                                 <fieldset className="mr-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="Select From Date"
                                  name="from"
                                  format="dd-MM-yyyy"
                                  maxDate={new Date()}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset className="mr-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="Select To Date"
                                  name="to"
                                  format="DD-MM-YYYY"
                                  maxDate={new Date()}
                                  minDate={this.state.fromdate}
                                  value={this.state.todate}
                                  onChange={this.handleToDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          <fieldset>
                            <button className="btn btn-sm hovbuttonColour" style={{marginTop:"16px"}} onClick={this.Submit}>
                              Apply
                            </button>
                          </fieldset>
                          </div>
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                  <TableContainer sx={{ maxHeight: 500 }}>
                                      <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell />
                                            <TableCell>Batch</TableCell>
                                            <TableCell>Supplier</TableCell>
                                            <TableCell>Added Stock</TableCell>
                                            <TableCell>Current Stock</TableCell>
                                            <TableCell>Stock Type</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Stock From</TableCell>
                                            <TableCell>Purchase Order Id/ GRN Id</TableCell>
                                            <TableCell>Warehouse</TableCell>
                                            <TableCell>Date</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.batchstockdata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,idx) => (
                                            <Row key={idx} row={row} salesconsumptiondata={this.state.salesconsumptiondata.filter(e=>e.batchid === row.batchid)} 
                                              productionconsumptiondata = {this.state.productionconsumptiondata.filter(e=>e.batchid === row.batchid)}
                                            />
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    <TablePagination
                                      rowsPerPageOptions={[10, 25, 100]}
                                      component="div"
                                      count={this.state.batchstockdata.length}
                                      rowsPerPage={this.state.rowsPerPage}
                                      page={this.state.page}
                                      onPageChange={this.handleChangePage}
                                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    />
                                </Paper>
                                </>
                              }
                          </>)}
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
}


function Row(props) {
  console.log(props)
  const { row } = props;
  const {salesconsumptiondata} = props;
  const {productionconsumptiondata} =props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={`${open?"bg-light":""}`}>
        <TableCell>
          {salesconsumptiondata.length > 0 || productionconsumptiondata.length>0 ?
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          :""}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.custombatchid}
        </TableCell>
        <TableCell>{row.stockfrom === "Production"?"Production": (row.supplier===undefined||row.supplier === null || row.supplier===""?(row.byproduct===true?"By Product":"Opening"):row.supplier)}</TableCell>
        <TableCell>{row.addedStock}</TableCell>
        <TableCell>{row.currentStock}</TableCell>
        <TableCell>{row.stocktype}</TableCell>
        <TableCell>
            <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={row.rate}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
          </TableCell>
          <TableCell>{row.stockfrom}</TableCell>
          <TableCell>{row.stockfrom === "Purchase"? row.poid +" / "+row.grnid :""}</TableCell>
          <TableCell>{row.waddress}</TableCell>
        <TableCell>{moment(row.date).format("DD-MM-YYYY HH:mm")}</TableCell>
      </TableRow>
      <TableRow  className={`${open?"bg-light":""}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Consumption In </TableCell>
                    {salesconsumptiondata.length>0?<>
                    <TableCell>Customer</TableCell>
                    <TableCell>Invoice Id/No</TableCell>
                    <TableCell>Delivery Note</TableCell>
                    </>:""}
                    {productionconsumptiondata.length>0?<>
                    <TableCell>Store</TableCell>
                    <TableCell>Workcenter/Production Unit</TableCell>
                    </>:""}
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Consumption Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesconsumptiondata?.map((historyRow,i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        Sales
                      </TableCell>
                      <TableCell>{historyRow.customer}</TableCell>
                      <TableCell>{historyRow.invid}</TableCell>
                      <TableCell>{historyRow.dnid}</TableCell>
                      {productionconsumptiondata.length>0?<>
                      <TableCell>{historyRow.store}</TableCell>
                      <TableCell>{historyRow.workcenter}</TableCell>
                      </>:""}
                      <TableCell>{historyRow.quantity}</TableCell>
                      <TableCell>
                      <NumericFormat
                          className="pr-3"
                          displayType={"text"}
                          value={historyRow.price}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          disabled={true}
                          thousandSeparator={true}
                          thousandsGroupStyle={thousandsdisplay}
                        ></NumericFormat>
                      </TableCell>
                      <TableCell>{moment(historyRow.date).format("DD-MM-YYYY HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                  {productionconsumptiondata?.map((historyRow,i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        Production
                      </TableCell>
                      {salesconsumptiondata.length>0?<>
                      <TableCell>{historyRow.customer}</TableCell>
                      <TableCell>{historyRow.invid}</TableCell>
                      <TableCell>{historyRow.dnid}</TableCell>
                      </>:""}
                      <TableCell>{historyRow.store}</TableCell>
                      <TableCell>{historyRow.workcenter}</TableCell>
                      <TableCell>{historyRow.quantity}</TableCell>
                      <TableCell>
                      <NumericFormat
                          className="pr-3"
                          displayType={"text"}
                          value={historyRow.price}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          disabled={true}
                          thousandSeparator={true}
                          thousandsGroupStyle={thousandsdisplay}
                        ></NumericFormat>
                      </TableCell>
                      <TableCell>{moment(historyRow.date).format("DD-MM-YYYY HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



export default withRouter(StockDataTable);
