import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { ToWords } from "to-words";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";

class JvDetails extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.location.state.id)
    const data = this.props.location.state.id;
    this.state = {
      jvdata:data
    };
  }
  componentDidMount() {
    console.log(this.state.jvdata)
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }
  render() {
    let jv=this.state.jvdata
    const toWords = new ToWords();
     let amtwords = toWords.convert(jv.id.charAt(0)==="J" || jv.typeofentry===6?jv.creditidamt:jv.debtotal+jv.taxidoneamt+jv.taxidtwoamt);
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">Jv Details</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Jv Details</li>
            </ol>
            <div>
              <span
                as="button"
                className="float-left btn btn-sm bg-white shadow-sm ml-3"
                onClick={()=>this.props.navigate(-1)}
              >
                <i className="fas fa-arrow-left" /> Back
              </span>
            </div>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ?<div className="text-center">{spin}</div> : <>
                                    
                                    {jv.id.charAt(0)==="J"?
<table className="table table-sm">

<thead className="backgroundColour text-white">
  <tr>
    <td>Ledgers</td>
    <td className="text-right"> Debit </td>
    <td className="text-right">Credit</td>
  </tr>
</thead>
<tbody>

{/* {jv.openadjusted!==null?
<tr>
  <td>Opening Adjusted</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.openadjusted} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>:""} */}

  <tr>
    {/* <td>{jv.debitname}</td> */}
    <td>{jv.openadjusted!==null?jv.debitname+ " (" + "Advance Adjusted"+" (---) "+"+"+jv.openadjusted+")":jv.debitname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.openadjusted!==null?jv.debitidamt+jv.openadjusted:jv.debitidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
  {jv.sdebitid!==null && jv.sdebitid !=="" ?
  <tr>
    <td>{jv.sdebitname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.sdebitidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>:''}

{/* {parseFloat(jv.debitidamt)>parseFloat(jv.creditidamt) || parseFloat(jv.debitidamt)===parseFloat(jv.creditidamt)?<> */}
{parseFloat(jv.debitidamt)<parseFloat(jv.creditidamt) || parseFloat(jv.debitidamt)===parseFloat(jv.creditidamt)?<>
  { jv.sgstid!==null ?
   <tr> 
    <td>{jv.sgstname} </td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.sgstamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}

{ jv.igstid!==null?
   <tr> <td>{jv.igstname} </td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.igstamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}

{jv.cgstid!==null?
   <tr> <td>{jv.cgstname} </td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.cgstamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}

{jv.vatid!==null?
   <tr> <td>{jv.vatname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.vatamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}
</>:<>
 { jv.sgstid!==null ?
  <tr> <td>{jv.sgstname} </td>
     <td></td>
   <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.sgstamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
   </tr>
   :''}

{ jv.igstid!==null?
  <tr> <td>{jv.igstname} </td>
     <td></td>
   <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.igstamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
   </tr>
   :''}

{jv.cgstid!==null?
  <tr> <td>{jv.cgstname} </td>
     <td></td>
   <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.cgstamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
   </tr>
   :''}

{jv.vatid!==null?
  <tr> <td>{jv.vatname}</td>
     <td></td>
   <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.vatamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
   </tr>
   :''}
</>}
  <tr>
    <td>{jv.creditname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
  <tr>
 
  </tr>
  {jv.taxval1!==undefined & jv.jvtype===1?
   <tr> <td>{jv.gst.name}@ {jv.gstpercent}%</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxval1} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}

{jv.itaxval!==undefined & jv.jvtype===1?
   <tr> <td>{jv.igst.name} @ {jv.igstpercent}%</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.itaxval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}

{jv.taxval2!==undefined & jv.jvtype===1?
   <tr> <td>{jv.cgst.name} @ {jv.cgstpercent}%</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxval2} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}

{jv.vatval!==undefined & jv.jvtype===1?
   <tr> <td>{jv.vat.name} @ {jv.vatpercent}%</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.vatval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}
<tr> <td><span className="text-info text-bold">Amount In Words :{" "}</span>{" "}{amtwords}</td>
<td></td><td></td></tr>
  <tr>
  <td><span className="text-info text-bold">Narration :  </span>  {jv.naration}</td>
  <td></td>
  <td></td>
  </tr>
</tbody>
</table>
:
<table className="table table-sm">

<thead className="backgroundColour text-white">
  <tr>
    <td>Ledgers</td>
    <td className="text-right"> Debit </td>
    <td className="text-right">Credit</td>
  </tr>
</thead>
<tbody>
<tr>
    <td>{jv.cname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
  {jv.cidoname!==null?
  <tr>
    <td>{jv.cidoname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditidoneamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
    :""}
  {jv.cidtwname!==null?
  <tr>
    <td>{jv.cidtwname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditidtwoamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
    :""}
  {jv.cidthname!==null?
  <tr>
    <td>{jv.cidthname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditidthreeamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
    :""}
  {jv.cidfname!==null?
  <tr>
    <td>{jv.cidfname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditidfouramt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
    :""}
  {parseFloat(jv.debitidamt)>parseFloat(jv.creditidamt) || parseFloat(jv.debitidamt)===parseFloat(jv.creditidamt)?<>
  {jv.taxonename!==null?
  <tr>
    <td>{jv.taxonename}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxidoneamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
  :""}
  {jv.taxtwoname!==null?
  <tr>
    <td>{jv.taxtwoname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxidtwoamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
    :""}
</>:<>
{jv.taxonename!==null?
  <tr>
    <td>{jv.taxonename}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxidoneamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
  :""}
  {jv.taxtwoname!==null?
  <tr>
    <td>{jv.taxtwoname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxidtwoamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
    :""}
     {jv.dtaxid!==null?
  <tr>
    <td>{jv.dtaxname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.dtaxidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
    :""}
</>}
<tr>
    <td>{jv.name}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debtotal!==null && jv.debitidoneamt===null?jv.debtotal:jv.debitidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
  {jv.dioname!==null?
  <tr>
    <td>{jv.dioname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debitidoneamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
    :""}
  {jv.ditwname!==null?
  <tr>
    <td>{jv.ditwname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debitidtwoamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
    :""}
  {jv.dithname!==null?
  <tr>
    <td>{jv.dithname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debitidthreeamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
    :""}
   {jv.difname!==null?
  <tr>
    <td>{jv.difname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debitidfouramt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
  </tr>
    :""}
  {jv.typeofentry!==6?
  <tr> 
    <td>Total :</td>
      <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.cretotal+jv.dtaxidamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
      <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debtotal+jv.taxidoneamt+jv.taxidtwoamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>:""}

      <tr> <td><span className="text-info text-bold">Amount In Words :{" "}</span>{" "}{amtwords}</td>
      <td></td><td></td></tr>
  <tr>
  <td><span className="text-info text-bold">Narration :  </span>  {jv.naration}</td>
  <td></td>
  <td></td>
  </tr>
</tbody>
</table>
}

</>
                  }
                
                
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(JvDetails);
