import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Credentials, { userid, currentTenant, einvoice, transactionFrom } from "../Common/Credentials";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Menu from "../Component/Menu";
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import IOSSwitch from "../CommonComponents/IOSSwitch";
import { FormControlLabel } from "@mui/material";
import SettingsServices from "./SettingsServices";
import PdfTempletes from "./PdfTempletes";
import Einvoicesettings from "./Einvoicesettings";
import plusfillIcon from "../images/Icons/plussolid.png"
import clearIcon from "../images/Icons/cross-mark.png"
import configicon from "../images/Icons/file.png"
import mailsettings from "../images/Icons/mailsetting.png"
import automailIcon from "../images/Icons/email.png"
import prototype from "../images/Icons/prototype.png"
import calendar from "../images/Icons/calendar.png"
import additonaloptions from "../images/Icons/choose.png"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import MasterService from "../Masters/MasterService.js";
import Swal from "sweetalert2";

const regexprefix = /^[a-zA-Z0-9-]*$/;
const regexslno= /^[0-9]*$/;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soeditprice: false,
      salescal: false,
      dnreq: false,
      pouploadm: false,
      hover: false,
      additionlparams: false,
      salesautojre:false,
      purchaseautojre:false,
      disabletransactiondateselection:false,
      status: 0,
      customerprefix: "",
      customersno: "",
      customeredit: false,
      customereditstatus: "",
      supplierprefix: "",
      suppliersno: "",
      supplieredit: false,
      suppliereditstatus: "",
      agentprefix: "",
      agentno: "",
      agentedit: false,
      agenteditstatus: "",
      empprefix: "",
      empno: "",
      empedit: false,
      empditstatus: "",
      soprefix: "",
      sono: "",
      soedit: false,
      soeditstatus: "",
      poprefix: "",
      pono: "",
      poedit: false,
      poeditstatus: "",
      grnprefix: "",
      grnno: "",
      grnedit: false,
      grneditstatus: "",
      scnprefix: "",
      scnno: "",
      scnedit: false,
      scneditstatus: "",
      invoiceprefix: "",
      invoicesno: "",
      invoiceedit: false,
      invoiceeditstatus: "",
      dnprefix: "",
      dnno: "",
      dnedit: false,
      dneditstatus: "",
      qtprefix: "",
      qtno: "",
      qtedit: false,
      qteditstatus: "",
      assetprefix: "",
      assetno: "",
      assetedit: false,
      asseteditstatus: "",
      rentalprefix: "",
      rentalno: "",
      rentaledit: false,
      rentaleditstatus: "",
      soldprefix: "",
      soldno: "",
      soldedit: false,
      soldeditstatus: "",
      impprefix: "",
      impno: "",
      impedit: false,
      impeditstatus: "",
      estprefix: "",
      estno: "",
      estedit: false,
      esteditstatus: "",
      joprefix: "",
      jono: "",
      joedit: false,
      joeditstatus: "",
      storeprefix: "",
      storeno: "",
      storeedit: false,
      storeeditstatus: "",
      creditnoteprefix: "",
      creditnoteno: "",
      creditnoteedit: false,
      creditnoteeditstatus: "",
      mailbehavior: true,
      backdateinvoicestatus:false,
      salesproducts: true,
      salesservice: false,
      salespands: false,
      jretype:"",
      invuploadm: false,
      purchaseproducts: true,
      purchaseservice: false,
      purchasepands: false,
      salesroundoff:false,
      purchaseroundoff:false,

      templateid: 0,
      loader: false,
      buttondisable: false,
      licences: [],
      settings:{},
      transactionstartdate:new Date()
    };
  }




  handleChange = () => {
    let data = {
      soeditprice: !this.state.soeditprice === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.SoEditPrice(data).then(() => {
      this.setState({ soeditprice: !this.state.soeditprice });
    });

    // SettingsServices.UpdateSettings(data).then(
    //   (response) => {
    //     this.setState({
    //       additionlparams:response.data.additionalparamsforitem === "YES" ? true : false,
    //       salescal: response.data.salescalreq === "YES" ? true : false,
    //       dnreq: response.data.dnreq === "YES" ? true : false,
    //       soeditprice: response.data.soeditprice === "YES" ? true : false,
    //       salesproducts: response.data.salesproducts === "YES" ? true : false,
    //       salesservice: response.data.salesservice === "YES" ? true : false,
    //       salespands: response.data.salespands === "YES" ? true : false,
    //       purchaseproducts:response.data.purchaseproducts === "YES" ? true : false,
    //       purchaseservice: response.data.purchaseservice === "YES" ? true : false,
    //       purchasepands: response.data.purchasepands === "YES" ? true : false,
    //       pouploadm: response.data.pouploadm === "YES" ? true : false,
    //       invuploadm: response.data.invuploadm === "YES" ? true : false,
    //       mailbehavior: response.data.mailbehavior === "YES" ? true : false,
    //       salesautojre:response.data.salesautojre === "YES"?true : false,
    //       purchaseautojre:response.data.purchaseautojre === "YES"?true : false,
    //       salesroundoff:response.data.salesroundoff === "YES"?true:false,
    //       purchaseroundoff:response.data.purchaseroundoff==="YES"?true:false,
    //     });
    //   })
  };

  handleSalesCal = () => {
    let data = {
      salescal: !this.state.salescal === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.SoSalesCalucation(data).then(() => {
      this.setState({ salescal: !this.state.salescal }, () => {
        if (this.state.salescal === true) {
          let data = {
            soeditprice: "YES",
            tenantid: currentTenant,
          };
          SettingsServices.SoEditPrice(data).then(() => {
            this.setState({ soeditprice: "YES" });
          });
        }
      });
    });
  };

  AllowExtraMarkup = (name) => {
    let data = {
      allowextramakup: !this.state.allowextramakup === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    // let data = this.state.settings 
    // console.log(name);
    // data[name] = data[name] === "YES"? "NO": "YES";
    // console.log(data);
    SettingsServices.UpdateSettings(data).then(() => {
      this.setState({ allowextramakup: !this.state.allowextramakup });
    });
  };


  SalesValueRoundoff = () => {
    let data = {
      salesroundoff: !this.state.salesroundoff === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.SalesValueRoundoff(data).then(() => {
      this.setState({ salesroundoff: !this.state.salesroundoff });
    });
  };

  PurchaseValueRoundoff = () => {
    let data = {
      purchaseroundoff: !this.state.purchaseroundoff === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.PurchaseValueRoundoff(data).then(() => {
      this.setState({ purchaseroundoff: !this.state.purchaseroundoff });
    });
  };

  // handleDnReq = () =>{
  //   let data = {
  //     dnreq: !this.state.dnreq === true ? "YES" : "NO",
  //     tenantid: currentTenant,
  //   };
  //   SettingsServices.Dnreq(data).then(() => {
  //     this.setState({
  //           dnreq: !this.state.dnreq,
  //           tenantid: currentTenant,
  //     });
  //   });
  // }

  handleAdditonalParams = () => {
    let data = {
      apitem: !this.state.additionlparams === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.AdditionlParams(data).then(() => {
      this.setState({ additionlparams: !this.state.additionlparams });
    });
  };

  handleMailbehavior = () => {
    let data = {
      mailbehavior: !this.state.mailbehavior === true ? "YES" : "NO",
      tenantid: currentTenant,
    };

    SettingsServices.MailbehaviorService(data).then(() => {
      this.setState({ mailbehavior: !this.state.mailbehavior });
    });
  };

  handleJREEntryTypeChange = (e) =>{
    
    if(e.target.value && e.target.value !== this.state.jretype){
    let data = {
      jretype: e.target.value,
      tenantid: currentTenant,
    };
    SettingsServices.UpdateJREEntryType(data).then(() => {
      this.setState({ jretype: e.target.value});
    });
  }
  }

  handleSalesService = () => {
    let data = {
      salesservice: !this.state.salesservice === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.SalesService(data).then(() => {
      this.setState({ salesservice: !this.state.salesservice });
    });
  };

  handleChangeSalesAutoJRE = () =>{
    let data = {
      salesservice: !this.state.salesautojre === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.SalesAutoJRE(data).then(() => {
      this.setState({ salesautojre: !this.state.salesautojre });
    });
  }

  handleChangePurchaseAutoJRE = () =>{
    let data = {
      salesservice: !this.state.purchaseautojre === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.PurchaseAutoJRE(data).then(() => {
      this.setState({ purchaseautojre: !this.state.purchaseautojre });
    });
  }

  handlepouploadm = () => {
    let data = {
      pouploadm: !this.state.pouploadm === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.POupload(data).then(() => {
      this.setState({ pouploadm: !this.state.pouploadm });
    });
  };
  handlebackdateinvoice = () =>{
    let data = {
      backdateinvoicestatus: !this.state.backdateinvoicestatus === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.BackDateInvoiceStatus(data).then(() => {
      this.setState({ backdateinvoicestatus: !this.state.backdateinvoicestatus });
    });
  }

  handleSalesPandS = () => {
    let data = {
      salespands: !this.state.salespands === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.SalesPandS(data).then(() => {
      this.setState({ salespands: !this.state.salespands });
    });
  };

  handleLicense = () => {
    this.setState({
      licences: [
        ...this.state.licences,
        { name: "", number: "", edit: true, tenantid: currentTenant },
      ],
    });
  };

  onlicenceNameChange = (val, idx) => {
    let data = this.state.licences;
    data.find((e, i) => i === idx).name = val;
    this.setState({ licences: data });
  };

  onlicenceChange = (val, idx) => {
    let data = this.state.licences;
    data.find((e, i) => i === idx).number = val;
    this.setState({ licences: data });
  };

  updateLicence = (val, idx) => {
    this.setState({ buttondisable: true });
    SettingsServices.Addlicencesoftenant(val)
      .then((response) => {
        // this.GetLicences()
        let data = this.state.licences.filter((e, i) => i !== idx);
        this.setState({
          licences: [...data, response.data],
          buttondisable: false,
        });
      })
      .catch(() => this.setState({ buttondisable: false }));
  };

  Deletelicence = (val, idx) => {
    if (val.id === null || val.id === undefined || val.id === "") {
      let data = this.state.licences.filter((e, i) => i !== idx);
      this.setState({ licences: data });
    } else {
      SettingsServices.Deletelicenseoftenant(val.id).then(() => {
        let data = this.state.licences.filter((e, i) => i !== idx);
        this.setState({ licences: data });
      });
    }
  };

  handlePurchaseService = () => {
    let data = {
      purchaseservice: !this.state.purchaseservice === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.PurchaseService(data).then(() => {
      this.setState({ purchaseservice: !this.state.purchaseservice });
    });
  };

  handlePurchasePandS = () => {
    let data = {
      purchasepands: !this.state.purchasepands === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.PurchasePandS(data).then(() => {
      this.setState({ purchasepands: !this.state.purchasepands });
    });
  };

  handleinvuploadm = () => {
    let data = {
      invuploadm: !this.state.invuploadm === true ? "YES" : "NO",
      tenantid: currentTenant,
    };
    SettingsServices.InvUpload(data).then(() => {
      this.setState({ invuploadm: !this.state.invuploadm });
    });
  };

  handleCustomerEdit = () => {
    this.setState({ customeredit: true });
  };
  handleSupplierEdit = () => {
    this.setState({ supplieredit: true });
  };
  handleEmployeeEdit = () => {
    this.setState({ empedit: true });
  };
  handleSoEdit = () => {
    this.setState({ soedit: true });
  };
  handlePoEdit = () => {
    this.setState({ poedit: true });
  };
  handleGrnEdit = () => {
    this.setState({ grnedit: true });
  };
  handleSCNedit = () => {
    this.setState({ scnedit: true });
  };
  handleInvoiceEdit = () => {
    this.setState({ invoiceedit: true });
  };
  handleDNEdit = () => {
    this.setState({ dnedit: true });
  };
  handleQtEdit = () => {
    this.setState({ qtedit: true });
  };
  handleAssetEdit = () => {
    this.setState({ assetedit: true });
  };
  handleRentalEdit = () => {
    this.setState({ rentaledit: true });
  };
  handleSoldEdit = () => {
    this.setState({ soldedit: true });
  };
  handleImpEdit = () => {
    this.setState({ impedit: true });
  };
  handleEstEdit = () => {
    this.setState({ estedit: true });
  };
  handleJoEdit = () => {
    this.setState({ joedit: true });
  };
  handleStoreEdit = () => {
    this.setState({ storeedit: true });
  };

  handlecreditnoteEdit = () => {
    this.setState({ creditnoteedit: true });
  };

  updateCustprefix = () => {
    let data = {
      custprefix: this.state.customerprefix,
      custserialnum: this.state.customersno,
      tenantid: currentTenant,
    };
    SettingsServices.updateCustprefix(data).then(() => {
      this.setState({ customeredit: false });
    });
  };

  updateSupplierPrefix = () => {
    let data = {
      supprefix: this.state.supplierprefix,
      supserialnum: this.state.suppliersno,
      tenantid: currentTenant,
    };
    SettingsServices.updateSupprefix(data).then(() => {
      this.setState({ supplieredit: false });
    });
  };

  updateEmployeePrefix = () => {
    let data = {
      custprefix: this.state.empprefix,
      custserialnum: this.state.empno,
      tenantid: currentTenant,
    };
    SettingsServices.updateEmployeeprefix(data).then(() => {
      this.setState({ empedit: false });
    });
  };

  updateSoprefix = () => {
    let data = {
      soprefix: this.state.soprefix,
      soserialnum: this.state.sono,
      tenantid: currentTenant,
    };
    SettingsServices.updateSoprefix(data).then(() => {
      this.setState({ soedit: false });
    });
  };

  updatePoprefix = () => {
    let data = {
      poprefix: this.state.poprefix,
      poserialnum: this.state.pono,
      tenantid: currentTenant,
    };
    SettingsServices.updatePoprefix(data).then(() => {
      this.setState({ poedit: false });
    });
  };

  updateGrnprefix = () => {
    let data = {
      grnprefix: this.state.grnprefix,
      grnserialnum: this.state.grnno,
      tenantid: currentTenant,
    };
    SettingsServices.updateGrnprefix(data).then(() => {
      this.setState({ grnedit: false });
    });
  };

  updateSCNprefix = () => {
    let data = {
      scnprefix: this.state.scnprefix,
      scnserialnum: this.state.scnno,
      tenantid: currentTenant,
    };
    SettingsServices.updateSCNprefix(data).then(() => {
      this.setState({ scnedit: false });
    });
  };

  updateInvprefix = () => {
    let data = {
      invprefix: this.state.invoiceprefix,
      invserialnum: this.state.invoicesno,
      tenantid: currentTenant,
    };
    SettingsServices.updateInvprefix(data).then(() => {
      this.setState({ invoiceedit: false });
    });
  };

  updateDnprefix = () => {
    let data = {
      dnprefix: this.state.dnprefix,
      dnserialnum: this.state.dnno,
      tenantid: currentTenant,
    };
    SettingsServices.updateDnprefix(data).then(() => {
      this.setState({ dnedit: false });
    });
  };

  updateQtprefix = () => {
    let data = {
      quotationprefix: this.state.qtprefix,
      quotationserialnum: this.state.qtno,
      tenantid: currentTenant,
    };
    SettingsServices.updateQtprefix(data).then(() => {
      this.setState({ qtedit: false });
    });
  };

  updateAssetprefix = () => {
    let data = {
      assetprefix: this.state.assetprefix,
      serialnum: this.state.assetno,
      tenantid: currentTenant,
    };
    SettingsServices.updateAssetprefix(data).then(() => {
      this.setState({ assetedit: false });
    });
  };
  updateRentalprefix = () => {
    let data = {
      prefix: this.state.rentalprefix,
      serialnum: this.state.rentalno,
      tenantid: currentTenant,
    };
    SettingsServices.updateRentalprefix(data).then(() => {
      this.setState({ rentaledit: false });
    });
  };

  updateSoldprefix = () => {
    let data = {
      prefix: this.state.soldprefix,
      serialnum: this.state.soldno,
      tenantid: currentTenant,
    };
    SettingsServices.updateSoldprefix(data).then(() => {
      this.setState({ soldedit: false });
    });
  };

  updateImpprefix = () => {
    let data = {
      prefix: this.state.impprefix,
      serialnum: this.state.impno,
      tenantid: currentTenant,
    };
    SettingsServices.updateImpprefix(data).then(() => {
      this.setState({ impedit: false });
    });
  };

  updateEstprefix = () => {
    let data = {
      prefix: this.state.estprefix,
      serialnum: this.state.estno,
      tenantid: currentTenant,
    };
    SettingsServices.updateEstprefix(data).then(() => {
      this.setState({ estedit: false });
    });
  };

  updateJoprefix = () => {
    let data = {
      prefix: this.state.joprefix,
      serialnum: this.state.jono,
      tenantid: currentTenant,
    };
    SettingsServices.updateJoprefix(data).then(() => {
      this.setState({ joedit: false });
    });
  };

  updateStoreprefix = () => {
    let data = {
      prefix: this.state.storeprefix,
      serialnum: parseInt(this.state.storeno),
      tenantid: currentTenant,
    };
    SettingsServices.updateStoreprefix(data).then(() => {
      this.setState({ storeedit: false });
    });
  };

  updatecreditnoteprefix = () => {
    let data = {
      prefix: this.state.creditnoteprefix,
      serialnum: this.state.creditnoteno,
      tenantid: currentTenant,
    };
    SettingsServices.updateCreditnoteprefix(data).then(() => {
      this.setState({ creditnoteedit: false });
    });
  };

  // handleFinalSubmit=()=>{

  // }


  handleTransactionStartDateChange = (date)=>{
    this.setState({transactionstartdate:date})
  }
  handleConfirmTransactionStartDate = () =>{
    let data = {
      date:this.state.transactionstartdate,
      tenantid:currentTenant
    }

    Swal.fire({
      title: "Set transaction start date",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
    MasterService.UpdateransactionStartDate(data).then(()=>{
      Credentials.setTransactionFrom(this.state.transactionstartdate)
      this.setState({disabletransactiondateselection:true})
      Swal.fire({
        title: "Transaction start date added successfully!",
        text: "now you can start your transactions.",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })
      })
      }})
  }

  GetAllPrefixes = () => {
    this.setState({ status: 3 });
    SettingsServices.GetAllPrefixes(currentTenant).then((resp) => {
      this.setState({
        customerprefix: resp.data.custprefix?.custprefix,
        customersno: resp.data.custprefix?.custserialnum,
        customereditstatus: resp.data.custprefix?.activestatus,

        supplierprefix: resp.data.supprefix?.supprefix,
        suppliersno: resp.data.supprefix?.supserialnum,
        suppliereditstatus: resp.data.supprefix?.activestatus,

        // agentprefix:resp.data.agentprefix.prefix,
        // agentno:resp.data.agentprefix.serialnum,
        // agenteditstatus: resp.data.agentprefix.activestatus,

        soprefix: resp.data.soprefix?.soprefix,
        sono: resp.data.soprefix?.soserialnum,
        soeditstatus: resp.data.soprefix?.activestatus,

        poprefix: resp.data.poprefix?.poprefix,
        pono: resp.data.poprefix?.poserialnum,
        poeditstatus: resp.data.poprefix?.activestatus,

        grnprefix: resp.data.grnprefix?.grnprefix,
        grnno: resp.data.grnprefix?.grnserialnum,
        grneditstatus: resp.data.grnprefix?.activestatus,

        scnprefix: resp.data.scnprefix?.scnprefix,
        scnno: resp.data.scnprefix?.scnserialnum,
        scneditstatus: resp.data.scnprefix?.activestatus,

        invoiceprefix: resp.data.invprefix?.invprefix,
        invoicesno: resp.data.invprefix?.invserialnum,
        invoiceeditstatus: resp.data.invprefix?.activestatus,

        dnprefix: resp.data.dnprefix?.dnprefix,
        dnno: resp.data.dnprefix?.dnserialnum,
        dneditstatus: resp.data.dnprefix?.activestatus,

        qtprefix: resp.data.qtprefix?.quotationprefix,
        qtno: resp.data.qtprefix?.quotationserialnum,
        qteditstatus: resp.data.qtprefix?.activestatus,

        assetprefix: resp.data.assetprefix?.assetprefix,
        assetno: resp.data.assetprefix?.serialnum,
        asseteditstatus: resp.data.assetprefix?.activestatus,

        rentalprefix: resp.data.rentalprefix?.prefix,
        rentalno: resp.data.rentalprefix?.serialnum,
        rentaleditstatus: resp.data.rentalprefix?.activestatus,

        soldprefix: resp.data.soldprefix?.prefix,
        soldno: resp.data.soldprefix?.serialnum,
        soldeditstatus: resp.data.soldprefix?.activestatus,

        impprefix: resp.data.impprefix?.prefix,
        impno: resp.data.impprefix?.serialnum,
        impeditstatus: resp.data.impprefix?.activestatus,

        estprefix: resp.data.estprefix?.prefix,
        estno: resp.data.estprefix?.serialnum,
        esteditstatus: resp.data.estprefix?.activestatus,

        joprefix: resp.data.joprefix?.prefix,
        jono: resp.data.joprefix?.serialnum,
        joeditstatus: resp.data.joprefix?.activestatus,

        storeprefix: resp.data.storeprefix?.prefix,
        storeno: resp.data.storeprefix?.serialnum,
        storeeditstatus: resp.data.storeprefix?.activestatus,

        empprefix: resp.data.employeeprefix?.custprefix,
        empno: resp.data.employeeprefix?.custserialnum,
        empeditstatus: resp.data.employeeprefix?.activestatus,

        creditnoteprefix: resp.data.creditnoteprefix?.prefix,
        creditnoteno: resp.data.creditnoteprefix?.serialnum,
        creditnoteeditstatus: resp.data.creditnoteprefix?.activestatus,
      });
    });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    Credentials.setSelectedMenu("Settings")
    SettingsServices.GetSettings(currentTenant).then((response) => {
      this.setState({
        settings:response.data,
        additionlparams:response.data.additionalparamsforitem === "YES" ? true : false,
        salescal: response.data.salescalreq === "YES" ? true : false,
        dnreq: response.data.dnreq === "YES" ? true : false,
        soeditprice: response.data.soeditprice === "YES" ? true : false,
        salesproducts: response.data.salesproducts === "YES" ? true : false,
        salesservice: response.data.salesservice === "YES" ? true : false,
        salespands: response.data.salespands === "YES" ? true : false,
        purchaseproducts:response.data.purchaseproducts === "YES" ? true : false,
        purchaseservice: response.data.purchaseservice === "YES" ? true : false,
        purchasepands: response.data.purchasepands === "YES" ? true : false,
        pouploadm: response.data.pouploadm === "YES" ? true : false,
        invuploadm: response.data.invuploadm === "YES" ? true : false,
        mailbehavior: response.data.mailbehavior === "YES" ? true : false,
        salesautojre:response.data.salesautojre === "YES"?true : false,
        purchaseautojre:response.data.purchaseautojre === "YES"?true : false,
        salesroundoff:response.data.salesroundoff === "YES"?true:false,
        purchaseroundoff:response.data.purchaseroundoff==="YES"?true:false,
        backdateinvoicestatus:response.data.backdateinvoicestatus==="YES"?true:false,
        jretype:response.data.jretype
      });
    });

    this.GetLicences();
  }
  GetLicences = () => {
    SettingsServices.Getlicencesoftenant(currentTenant).then((response) =>
      this.setState({ licences: response.data })
    );
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/index">Home</Link>
              </li>
              <li className="breadcrumb-item active">Settings</li>
            </ol>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title text-secondary">
                  <i className="fas fa-cog mr-2"></i>
                  Settings
                </h3>
              </div>
              <div className="card-body">
                <div className="form row">
                  <div className="form-group col-md-5 col-sm-12  px-3 mb-5 bg-body rounded">
                    <Table className="table-sm m-0 p-0 table-bordred">
                      <TableBody>
                      <TableRow>
                          <TableCell style={{border:'none'}}>
                            {" "}
                            {/* <i class="fa fa-calendar-day textColour text-lg"></i> */}
                            <img className="nav-icon" width="20" src={calendar}/>
                          </TableCell>
                          <TableCell style={{border:'none'}} className="text-md text-secondary p-3">
                            Transaction Start Date
                          </TableCell>
                          <TableCell style={{border:'none'}} className="d-flex" >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="standard"
                                id="date-picker-dialog"
                                // label="Transaction Start Date"
                                value={transactionFrom===null?this.state.transactionstartdate:transactionFrom}
                                onChange={this.handleTransactionStartDateChange}
                                renderInput={(params) => <TextField {...params}  value={this.state.fsd}  variant="standard" fullWidth size="small"/>}
                                autoOk={true}
                                disabled={transactionFrom !== null || this.state.disabletransactiondateselection}
                              />
                            </Grid>
                          </LocalizationProvider>
                          {transactionFrom === null && !this.state.disabletransactiondateselection?
                          <button type="button" className="btn btn-sm hovbuttonColour ml-2 my-auto" onClick={this.handleConfirmTransactionStartDate}><i className="fa fa-check"></i></button>
                          :""}
                          </TableCell>
                        </TableRow>
                        
                        <TableRow>
                          <TableCell style={{border:'none'}}>
                            {" "}
                            {/* <i className="fas fa-plus-square textColour" /> */}
                            <img className="nav-icon" width="20" src={additonaloptions}/>
                          </TableCell>
                          <TableCell style={{border:'none'}} className="text-md text-secondary p-3">
                            Additonal Parameters for Item Creation
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            <FormControlLabel
                              className="float-right my-0 mr-0"
                              control={
                                <IOSSwitch
                                  className="sm my-0 mr-0"
                                  checked={this.state.additionlparams}
                                  onChange={this.handleAdditonalParams}
                                  name="checkedB"
                                />
                              }
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{border:'none'}} >
                            {" "}
                            {/* <i className="fas fa-envelope textColour" /> */}
                            <img className="nav-icon" width="24" src={automailIcon}/>
                          </TableCell>
                          <TableCell style={{border:'none'}}  className="text-md text-secondary p-3">
                            Mail behavior Automatic
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            <FormControlLabel
                              className="float-right my-0 mr-0"
                              control={
                                <IOSSwitch
                                  className="sm my-0 mr-0"
                                  checked={this.state.mailbehavior}
                                  onChange={this.handleMailbehavior}
                                  name="checkedB"
                                />
                              }
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{border:'none'}} >
                            {" "}
                            {/* <i className="fas fa-envelope textColour" /> */}
                            <img className="nav-icon" width="24" src={automailIcon}/>
                          </TableCell>
                          <TableCell style={{border:'none'}}  className="text-md text-secondary p-3">
                          Data Visibility
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                          <FormControl variant="standard" className="float-right my-0 mr-0" fullWidth>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={this.state.jretype}
          onChange={this.handleJREEntryTypeChange}
          label="Age"
          fullWidth
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"Branch"}>Organization Unit/Branch</MenuItem>
          <MenuItem value={"Department"}>Department</MenuItem>
        </Select>
      </FormControl>

                          </TableCell>
                        </TableRow>

                        <TableRow 
                         style={{
                          backgroundColor:
                            this.state.status === 1 ? "#ffde1638" : "",
                        }}
                        >
                          <TableCell style={{border:'none'}} >
                            {" "}
                            {/* <i className="far fa-list-alt textColour"></i> */}
                            <img className="nav-icon" width="20" src={configicon}/>
                          </TableCell>
                          <TableCell style={{border:'none'}} 
                            className="text-md text-secondary p-3"
                          >
                            {/* <i className="fas fa-envelope textColour mr-2" /> */}
                            Sales Settings
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            {this.state.status === 1 ? (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 0 })}
                              >
                                {/* <i className="fas fa-times text-danger float-right mt-1" /> */}
                                <img className="nav-icon rounded" width="30" src={clearIcon}/>
                              </span>
                            ) : (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 1 })}
                              >
                                {/* <i className="fas fa-plus textColour float-right mt-1" /> */}
                                {/* <img className="nav-icon mr-2" width="25" src={plusIcon}/> */}
                                <img className="nav-icon" width="30" src={plusfillIcon}/>
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                        {einvoice ? (
                          <TableRow 
                          style={{
                            backgroundColor:
                              this.state.status === 5? "#ffde1638" : "",
                          }}
                          >
                            <TableCell style={{border:'none'}} >
                              {" "}
                              {/* <i className="far fa-list-alt textColour"></i> */}
                               <img className="nav-icon" width="20" src={configicon}/>
                            </TableCell>
                            <TableCell style={{border:'none'}} 
                              className="text-md text-secondary p-3"
                            >
                              {/* <i className="fas fa-envelope textColour mr-2" /> */}
                              e-Invoice Settings
                            </TableCell>
                            <TableCell style={{border:'none'}} >
                              {this.state.status === 5 ? (
                                <span
                                  className="cursorPointer d-flex justify-content-end"
                                  onClick={() => this.setState({ status: 0 })}
                                >
                                  {/* <i className="fas fa-times text-danger float-right mt-1" /> */}
                                  <img className="nav-icon rounded" width="30" src={clearIcon}/>
                                </span>
                              ) : (
                                <span
                                  className="cursorPointer d-flex justify-content-end"
                                  onClick={() => this.setState({ status: 5 })}
                                >
                                  {/* <i className="fas fa-plus textColour float-right mt-1" /> */}
                                  <img className="nav-icon" width="30" src={plusfillIcon}/>
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        <TableRow 
                        style={{
                          backgroundColor:
                            this.state.status === 4 ? "#ffde1638" : "",
                        }}
                        >
                          <TableCell style={{border:'none'}} >
                            {" "}
                            {/* <i className="far fa-list-alt textColour"></i> */}
                            <img className="nav-icon" width="20" src={configicon}/>
                          </TableCell>
                          <TableCell style={{border:'none'}} 
                            className="text-md text-secondary p-3"
                          >
                            {/* <i className="fas fa-envelope textColour mr-2" /> */}
                            Purchase Settings
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            {this.state.status === 4 ? (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 0 })}
                              >
                                {/* <i className="fas fa-times text-danger float-right mt-1" /> */}
                                <img className="nav-icon rounded" width="30" src={clearIcon}/>
                              </span>
                            ) : (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 4 })}
                              >
                                {/* <i className="fas fa-plus textColour float-right mt-1" /> */}
                                <img className="nav-icon" width="30" src={plusfillIcon}/>
                              </span>
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{border:'none'}} >
                            {" "}
                            {/* <i className="fas fa-envelope textColour" /> */}
                            <img className="nav-icon" width="20" src={mailsettings}/>
                          </TableCell>
                          <TableCell style={{border:'none'}}  className="text-md text-secondary p-3">
                            Mail Configuration
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            <Link
                              className="cursorPointer d-flex justify-content-end"
                              to={"/mailsettings"}
                            >
                              {/* <i className="fas fa-plus  textColour float-right mt-1" /> */}
                              <img className="nav-icon" width="30" src={plusfillIcon}/>
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{
                          backgroundColor:
                            this.state.status === 2 ? "#ffde1638" : "",
                        }}
                        >
                          <TableCell style={{border:'none'}} >
                            {" "}
                            {/* <i className="far fa-list-alt textColour"></i> */}
                            <img className="nav-icon" width="25" src={prototype}/>
                          </TableCell>
                          <TableCell style={{border:'none'}} 
                            className="text-md text-secondary p-3"
                          >
                            {/* <i className="fas fa-envelope textColour mr-2" /> */}
                            Templates Settings
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            {this.state.status === 2 ? (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 0 })}
                              >
                                {/* <i className="fas fa-times text-danger float-right mt-1" /> */}
                                <img className="nav-icon rounded" width="30" src={clearIcon}/>
                              </span>
                            ) : (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 2 })}
                              >
                                {/* <i className="fas fa-plus textColour float-right mt-1" /> */}
                                <img className="nav-icon" width="30" src={plusfillIcon}/>
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{
                          backgroundColor:
                            this.state.status === 3 ? "#ffde1638" : "",
                        }}
                        >
                          <TableCell style={{border:'none'}} >
                            {/* <i className="fas fa-tools textColour"></i> */}
                            <img className="nav-icon" width="25" src={prototype}/>
                          </TableCell>
                          <TableCell style={{border:'none'}} 
                            className="text-md text-secondary p-3"
                          >
                            {/* <i className="fas fa-envelope textColour mr-2" /> */}
                            Prefix and Serial No. Settings
                          </TableCell>
                          <TableCell style={{border:'none'}} >
                            {this.state.status === 3 ? (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={() => this.setState({ status: 0 })}
                              >
                                {/* <i className="fas fa-times text-danger float-right mt-1" /> */}
                                <img className="nav-icon rounded" width="30" src={clearIcon}/>
                              </span>
                            ) : (
                              <span
                                className="cursorPointer d-flex justify-content-end"
                                onClick={this.GetAllPrefixes}
                              >
                                {/* <i className="fas fa-plus textColour float-right mt-1" /> */}
                                <img className="nav-icon" width="30" src={plusfillIcon}/>
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>

                  {this.state.status === 1 ? (
                    <div className="form-group col-md-7 shadow-sm p-3 mb-5 bg-body rounded">
                      <Table className="table-sm m-0 p-0 table-bordred">
                        <TableBody>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3 font-weight-bold">
                              Sales Settings
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Auto JRE
                            </TableCell>
                            <TableCell>
                            <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.salesautojre}
                                    onChange={this.handleChangeSalesAutoJRE}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          {this.state.salesautojre?
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Auto JRE Settings
                            </TableCell>
                            <TableCell>
                              <Link
                                to="/autojresettings"
                                className="float-right"
                              >
                                {/* <i className="fas fa-plus  textColour float-right mt-1" /> */}
                                <img className="nav-icon" width="30" src={plusfillIcon}/>
                              </Link>
                            </TableCell>
                          </TableRow>
                          :""}
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Sale order price editable
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.soeditprice}
                                    onChange={this.handleChange}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Sale on direct price
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.salescal}
                                    onChange={this.handleSalesCal}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Extra Markup
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.allowextramakup}
                                    onChange={()=>this.AllowExtraMarkup("salescal")}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          {/* <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Value round off
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.salesroundoff}
                                    onChange={this.SalesValueRoundoff}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow> */}
                          {/* <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Delivery / Shipment Note
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.dnreq}
                                    onChange={this.handleDnReq}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow> */}
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              PO upload mandatory
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.pouploadm}
                                    onChange={this.handlepouploadm}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                            Permit invoicing for past dates
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.backdateinvoicestatus}
                                    onChange={this.handlebackdateinvoice}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Products
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    disabled
                                    checked={this.state.salesproducts}
                                    // onChange={this.handleSalesCal}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Services
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.salesservice}
                                    onChange={this.handleSalesService}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Products and Services
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.salespands}
                                    onChange={this.handleSalesPandS}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              License numbers
                            </TableCell>
                            <TableCell>
                              <h4
                                className="fa fa-plus float-right cursorPointer"
                                onClick={this.handleLicense}
                              ></h4>
                            </TableCell>
                          </TableRow>
                          {this.state.licences.map((val, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                <TextField
                                  label="Name"
                                  className="mr-3"
                                  value={val.name}
                                  onChange={(e) =>
                                    this.onlicenceNameChange(
                                      e.target.value,
                                      idx
                                    )
                                  }
                                  variant="standard"
                                ></TextField>
                                <TextField
                                  label="License Number"
                                  value={val.number}
                                  onChange={(e) =>
                                    this.onlicenceChange(e.target.value, idx)
                                  }
                                  variant="standard"
                                ></TextField>
                              </TableCell>
                              <TableCell>
                                <button
                                  className="btn btn-sm hovbuttonColour mt-3 mr-1 fa fa-save"
                                  onClick={() => this.updateLicence(val, idx)}
                                  disabled={this.state.buttondisable}
                                ></button>
                                <button
                                  className="btn btn-sm deletebutton mt-3 "
                                  onClick={() => this.Deletelicence(val, idx)}
                                  disabled={this.state.buttondisable}
                                >
                                  <i className="fa fa-trash text-white"></i>
                                </button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.status === 4 ? (
                    <div className="form-group col-md-7 shadow-sm p-3 mb-5 bg-body rounded">
                      <Table className="table-sm m-0 p-0 table-bordred">
                        <TableBody>
                          {/* <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Invoice upload mandatory
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.invuploadm}
                                    onChange={this.handleinvuploadm}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow> */}
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Products
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    disabled
                                    checked={this.state.purchaseproducts}
                                    // onChange={this.handleSalesCal}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Services
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.purchaseservice}
                                    onChange={this.handlePurchaseService}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Products and Services
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.purchasepands}
                                    onChange={this.handlePurchasePandS}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                          {/* <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Value round off
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.purchaseroundoff}
                                    onChange={this.PurchaseValueRoundoff}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow> */}

                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Auto JRE
                            </TableCell>
                            <TableCell>
                            <FormControlLabel
                                className="float-right my-0 mr-0"
                                control={
                                  <IOSSwitch
                                    className="sm my-0 mr-0"
                                    checked={this.state.purchaseautojre}
                                    onChange={this.handleChangePurchaseAutoJRE}
                                    name="checkedB"
                                  />
                                }
                              />
                            </TableCell>
                            <>{this.state.purchaseautojre}</>
                          </TableRow>

                          {this.state.purchaseautojre?
                          <TableRow>
                            <TableCell className="text-md text-secondary p-3">
                              Auto JRE Settings
                            </TableCell>
                            <TableCell>
                              <Link
                                to="/purchaseautojresettings"
                                className="float-right"
                              >
                                {/* <i className="fas fa-plus  textColour float-right mt-1" /> */}
                                <img className="nav-icon" width="30" src={plusfillIcon}/>
                              </Link>
                            </TableCell>
                          </TableRow>
                          :""}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.status === 2 ? (
                    <div className="form-group col-md-7 shadow-sm p-3 mb-5 bg-body rounded">
                      <PdfTempletes />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.status === 3 ? (
                    <div className="form-group col-md-7 shadow-sm p-3 mb-5 bg-body rounded">
                      <div className="card-header text-center text-md text-bold textColour">
                        Prefix and Serial No. Settings
                      </div>
                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Customer Prefix"
                            value={this.state.customerprefix}
                            onChange={(e) =>
                              this.setState({ customerprefix: regexprefix.test(e.target.value)?e.target.value: this.state.customerprefix })
                            }
                            disabled={this.state.customeredit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Customer Serial No."
                            value={this.state.customersno}
                            onChange={(e) =>
                              this.setState({ customersno: regexslno.test(e.target.value)?e.target.value: this.state.customersno })
                            }
                            disabled={this.state.customeredit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Customer Id"
                            value={
                              this.state.customerprefix +
                              "-" +
                              this.state.customersno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.customeredit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateCustprefix}
                            ></button>
                          </div>
                        ) : this.state.customereditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleCustomerEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Supplier Prefix"
                            value={this.state.supplierprefix}
                            onChange={(e) =>
                              this.setState({ supplierprefix: regexprefix.test(e.target.value)?e.target.value: this.state.supplierprefix })
                            }
                            disabled={this.state.supplieredit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Supplier Serial No."
                            value={this.state.suppliersno}
                            onChange={(e) =>
                              this.setState({ suppliersno: e.target.value })
                            }
                            disabled={this.state.supplieredit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Customer Id"
                            value={
                              this.state.supplierprefix +
                              "-" +
                              this.state.suppliersno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.supplieredit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateSupplierPrefix}
                            ></button>
                          </div>
                        ) : this.state.suppliereditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleSupplierEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Employee Prefix"
                            value={this.state.empprefix}
                            onChange={(e) =>
                              this.setState({ empprefix:regexprefix.test(e.target.value)?e.target.value: this.state.empprefix })
                            }
                            disabled={this.state.empedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Employee Serial No."
                            value={this.state.empno}
                            onChange={(e) =>
                              this.setState({ empno: regexslno.test(e.target.value)?e.target.value: this.state.empno })
                            }
                            disabled={this.state.empedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Employee Id"
                            value={
                              this.state.empprefix + this.state.empno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.empedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateEmployeePrefix}
                            ></button>
                          </div>
                        ) : this.state.empditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleEmployeeEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Sale Order Prefix"
                            value={this.state.soprefix}
                            onChange={(e) =>
                              this.setState({ soprefix: regexprefix.test(e.target.value)?e.target.value: this.state.soprefix })
                            }
                            disabled={this.state.soedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Sale Order Serial No."
                            value={this.state.sono}
                            onChange={(e) =>
                              this.setState({ sono: regexslno.test(e.target.value)?e.target.value: this.state.sono })
                            }
                            disabled={this.state.soedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Sale Order Id"
                            value={this.state.soprefix + this.state.sono}
                            disabled
                          ></TextField>
                        </div>
                        {this.state.soedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateSoprefix}
                            ></button>
                          </div>
                        ) : this.state.soeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleSoEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Purchase Order Prefix"
                            value={this.state.poprefix}
                            onChange={(e) =>
                              this.setState({ poprefix: regexprefix.test(e.target.value)?e.target.value: this.state.poprefix })
                            }
                            disabled={this.state.poedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Purchase Order Serial No."
                            value={this.state.pono}
                            onChange={(e) =>
                              this.setState({ pono: regexslno.test(e.target.value)?e.target.value: this.state.pono })
                            }
                            disabled={this.state.poedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Purchase Order Id"
                            value={this.state.poprefix + "-" + this.state.pono}
                            disabled
                          ></TextField>
                        </div>
                        {this.state.poedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updatePoprefix}
                            ></button>
                          </div>
                        ) : this.state.poeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handlePoEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="GRN Prefix"
                            value={this.state.grnprefix}
                            onChange={(e) =>
                              this.setState({ grnprefix: regexprefix.test(e.target.value)?e.target.value: this.state.grnprefix })
                            }
                            disabled={this.state.grnedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="GRN Serial No."
                            value={this.state.grnno}
                            onChange={(e) =>
                              this.setState({ grnno: regexslno.test(e.target.value)?e.target.value: this.state.grnno })
                            }
                            disabled={this.state.grnedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="GRN Id"
                            value={
                              this.state.grnprefix + "-" + this.state.grnno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.grnedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateGrnprefix}
                            ></button>
                          </div>
                        ) : this.state.grneditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleGrnEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="SCN Prefix"
                            value={this.state.scnprefix}
                            onChange={(e) =>
                              this.setState({ scnprefix: regexprefix.test(e.target.value)?e.target.value: this.state.scnprefix })
                            }
                            disabled={this.state.scnedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="SCN Serial No."
                            value={this.state.scnno}
                            onChange={(e) =>
                              this.setState({ scnno: regexslno.test(e.target.value)?e.target.value: this.state.scnno })
                            }
                            disabled={this.state.scnedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="SCN Id"
                            value={
                              this.state.scnprefix + "-" + this.state.scnno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.scnedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateSCNprefix}
                            ></button>
                          </div>
                        ) : this.state.scneditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleSCNedit}
                            ></button>
                          </div>
                        )}
                      </div>
                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Invoice Prefix"
                            value={this.state.invoiceprefix}
                            onChange={(e) =>
                              this.setState({ invoiceprefix: regexprefix.test(e.target.value)?e.target.value: this.state.invoiceprefix })
                            }
                            disabled={this.state.invoiceedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Invoice Serial No."
                            value={this.state.invoicesno}
                            onChange={(e) =>
                              this.setState({ invoicesno: regexslno.test(e.target.value)?e.target.value: this.state.invoicesno })
                            }
                            disabled={this.state.invoiceedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Invoice Id"
                            value={
                              this.state.invoiceprefix +
                              "-" +
                              this.state.invoicesno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.invoiceedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateInvprefix}
                            ></button>
                          </div>
                        ) : this.state.invoiceeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleInvoiceEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Delivery Note Prefix"
                            value={this.state.dnprefix}
                            onChange={(e) =>
                              this.setState({ dnprefix: regexprefix.test(e.target.value)?e.target.value: this.state.dnprefix })
                            }
                            disabled={this.state.dnedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Delivery Note Serial No."
                            value={this.state.dnno}
                            onChange={(e) =>
                              this.setState({ dnno: regexslno.test(e.target.value)?e.target.value: this.state.dnno })
                            }
                            disabled={this.state.dnedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Delivery Note Id"
                            value={this.state.dnprefix + "-" + this.state.dnno}
                            disabled
                          ></TextField>
                        </div>
                        {this.state.dnedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateDnprefix}
                            ></button>
                          </div>
                        ) : this.state.dneditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleDNEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Quotation Prefix"
                            value={this.state.qtprefix}
                            onChange={(e) =>
                              this.setState({ qtprefix: regexprefix.test(e.target.value)?e.target.value: this.state.qtprefix  })
                            }
                            disabled={this.state.qtedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Quotation Serial No."
                            value={this.state.qtno}
                            onChange={(e) =>
                              this.setState({ qtno: regexslno.test(e.target.value)?e.target.value: this.state.qtno })
                            }
                            disabled={this.state.qtedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Quotation Id"
                            value={this.state.qtprefix + "-" + this.state.qtno}
                            disabled
                          ></TextField>
                        </div>
                        {this.state.qtedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateQtprefix}
                            ></button>
                          </div>
                        ) : this.state.qteditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleQtEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Asset Prefix"
                            value={this.state.assetprefix}
                            onChange={(e) =>
                              this.setState({ assetprefix: regexprefix.test(e.target.value)?e.target.value: this.state.assetprefix })
                            }
                            disabled={this.state.assetedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Asset Serial No."
                            value={this.state.assetno}
                            onChange={(e) =>
                              this.setState({ assetno: regexslno.test(e.target.value)?e.target.value: this.state.assetno })
                            }
                            disabled={this.state.assetedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Asset Id"
                            value={
                              this.state.assetprefix + "-" + this.state.assetno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.assetedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateAssetprefix}
                            ></button>
                          </div>
                        ) : this.state.asseteditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleAssetEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Rental Prefix"
                            value={this.state.rentalprefix}
                            onChange={(e) =>
                              this.setState({ rentalprefix: regexprefix.test(e.target.value)?e.target.value: this.state.rentalprefix })
                            }
                            disabled={this.state.rentaledit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Rental Serial No."
                            value={this.state.rentalno}
                            onChange={(e) =>
                              this.setState({ rentalno: regexslno.test(e.target.value)?e.target.value: this.state.rentalno })
                            }
                            disabled={this.state.rentaledit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Rental Id"
                            value={
                              this.state.rentalprefix +
                              "-" +
                              this.state.rentalno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.rentaledit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateRentalprefix}
                            ></button>
                          </div>
                        ) : this.state.rentaleditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleRentalEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Sold Prefix"
                            value={this.state.soldprefix}
                            onChange={(e) =>
                              this.setState({ soldprefix: regexprefix.test(e.target.value)?e.target.value: this.state.soldprefix })
                            }
                            disabled={this.state.soldedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Sold Serial No."
                            value={this.state.soldno}
                            onChange={(e) =>
                              this.setState({ soldno: regexslno.test(e.target.value)?e.target.value: this.state.soldno })
                            }
                            disabled={this.state.soldedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Sold Id"
                            value={
                              this.state.soldprefix + "-" + this.state.soldno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.soldedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateSoldprefix}
                            ></button>
                          </div>
                        ) : this.state.soldeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleSoldEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Immediate production Prefix"
                            value={this.state.impprefix}
                            onChange={(e) =>
                              this.setState({ impprefix: regexprefix.test(e.target.value)?e.target.value: this.state.impprefix })
                            }
                            disabled={this.state.impedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Immediate production Serial No."
                            value={this.state.impno}
                            onChange={(e) =>
                              this.setState({ impno: regexslno.test(e.target.value)?e.target.value: this.state.impno })
                            }
                            disabled={this.state.impedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Immediate production Id"
                            value={
                              this.state.impprefix + "-" + this.state.impno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.impedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateImpprefix}
                            ></button>
                          </div>
                        ) : this.state.impeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleImpEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Estimation Prefix"
                            value={this.state.estprefix}
                            onChange={(e) =>
                              this.setState({ estprefix: regexprefix.test(e.target.value)?e.target.value: this.state.estprefix })
                            }
                            disabled={this.state.estedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Estimation Serial No."
                            value={this.state.estno}
                            onChange={(e) =>
                              this.setState({ estno: regexslno.test(e.target.value)?e.target.value: this.state.estno })
                            }
                            disabled={this.state.estedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Estimation Id"
                            value={
                              this.state.estprefix + "-" + this.state.estno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.estedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateEstprefix}
                            ></button>
                          </div>
                        ) : this.state.esteditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleEstEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Joborder Prefix"
                            value={this.state.joprefix}
                            onChange={(e) =>
                              this.setState({ joprefix: regexprefix.test(e.target.value)?e.target.value: this.state.joprefix })
                            }
                            disabled={this.state.joedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Joborder Serial No."
                            value={this.state.jono}
                            onChange={(e) =>
                              this.setState({ jono: regexslno.test(e.target.value)?e.target.value: this.state.jono})
                            }
                            disabled={this.state.joedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="jo Id"
                            value={this.state.joprefix + "-" + this.state.jono}
                            disabled
                          ></TextField>
                        </div>
                        {this.state.joedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateJoprefix}
                            ></button>
                          </div>
                        ) : this.state.joeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleJoEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Store Prefix"
                            value={this.state.storeprefix}
                            onChange={(e) =>
                              this.setState({ storeprefix: regexprefix.test(e.target.value)?e.target.value: this.state.storeprefix })
                            }
                            disabled={this.state.storeedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Store Serial No."
                            value={this.state.storeno}
                            onChange={(e) =>
                              this.setState({ storeno: regexslno.test(e.target.value)?e.target.value: this.state.storeno })
                            }
                            disabled={this.state.storeedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="store Id"
                            value={
                              this.state.storeprefix + "-" + this.state.storeno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.storeedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updateStoreprefix}
                            ></button>
                          </div>
                        ) : this.state.storeeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handleStoreEdit}
                            ></button>
                          </div>
                        )}
                      </div>

                      <div className="row p-2">
                        <div className="col-md-3">
                          <TextField
                            label="Creditnote Prefix"
                            value={this.state.creditnoteprefix}
                            onChange={(e) =>
                              this.setState({
                                creditnoteprefix: regexprefix.test(e.target.value)?e.target.value: this.state.creditnoteprefix
                              })
                            }
                            disabled={this.state.creditnoteedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Creditnote Serial No."
                            value={this.state.creditnoteno}
                            onChange={(e) =>
                              this.setState({ creditnoteno: regexslno.test(e.target.value)?e.target.value: this.state.creditnoteno })
                            }
                            disabled={this.state.creditnoteedit === false}
                          ></TextField>
                        </div>
                        <div className="col-md-3">
                          <TextField
                            label="Creditnote Id"
                            value={
                              this.state.creditnoteprefix +
                              "-" +
                              this.state.creditnoteno
                            }
                            disabled
                          ></TextField>
                        </div>
                        {this.state.creditnoteedit === true ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-save"
                              onClick={this.updatecreditnoteprefix}
                            ></button>
                          </div>
                        ) : this.state.creditnoteeditstatus === "ACTIVE" ? (
                          ""
                        ) : (
                          <div className="col-md-3">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3 fa fa-edit"
                              onClick={this.handlecreditnoteEdit}
                            ></button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.status === 5 ? (
                    <div className="form-group col-md-7 shadow-sm p-3 mb-5 bg-body rounded">
                      <Einvoicesettings />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(Settings);
