import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { spin } from "../../Common/NewLoader";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ProjectManagementServices from "../ProjectManagementServices";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ProjectDetails = () => {

  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoading(true);
    ProjectManagementServices.GetProjectById(params.id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [params]);

  const handleDeleteSite = (id) => {
    Swal.fire({
      title: `Are you sure you want to delete?`,
      text: ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        ProjectManagementServices.DeleteSite(id).then(() =>
          ProjectManagementServices.GetProjectById(params.id).then(
            (response) => {
              setData(response.data);
            }
          )
        );
      }
    });
  };

  const handleDeleteSiteIncharge = (id) => {
    Swal.fire({
      title: `Are you sure you want to delete?`,
      text: ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        ProjectManagementServices.DeleteSiteIncharge(id).then(() =>
          ProjectManagementServices.GetProjectById(params.id).then(
            (response) => {
              setData(response.data);
            }
          )
        );
      }
    });
  };

  const handleDeleteAssignClient = (id) => {
    Swal.fire({
      title: `Are you sure you want to delete?`,
      text: ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        ProjectManagementServices.DeleteClientAssign(id).then(() =>
          ProjectManagementServices.GetProjectById(params.id).then(
            (response) => {
              setData(response.data);
            }
          )
        );
      }
    });
  };

  const handleDeleteProjectManager = (id) => {
    Swal.fire({
      title: `Are you sure you want to delete?`,
      text: ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        ProjectManagementServices.DeleteProjectManager(id).then(() =>
          ProjectManagementServices.GetProjectById(params.id).then(
            (response) => {
              setData(response.data);
            }
          )
        );
      }
    });
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <Link as="button" className=" text-secondary" to="/projects">
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="breadcrumb-item active">Project Details</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="card-body">
                {loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <table className="float-left">
                        <tbody>
                          <tr>
                            <td>Project Name</td>
                            <td> : {data.projectName}</td>
                          </tr>
                          <tr>
                            <td>Project Code</td>
                            <td> : {data.projectCode}</td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td> : {data.status}</td>
                          </tr>
                          <tr>
                            <td>Remarks</td>
                            <td> : {data.remark}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>





                    
                    <div className="col-md-12">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ backgroundColor: "#e8ecef" }}
                        >
                          <Typography>Project Manager</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <div className="col-md-12 py-2">
                              {data.projectmanager !== undefined &&
                              data.projectmanager !== null &&
                              data.projectmanager.length > 0 ? (
                                <Table
                                  className="float-left table-border"
                                  size="small"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        project Manager Name
                                      </TableCell>
                                      <TableCell>Contact Number</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data.projectmanager.map((e) => (
                                      <TableRow key={e.id}>
                                        <TableCell>{e.managerName}</TableCell>
                                        <TableCell>
                                          {e.managerContactNo}
                                        </TableCell>
                                        <TableCell>{e.managerEmail}</TableCell>
                                        <TableCell>
                                          {" "}
                                          <i
                                            as="button"
                                            className="fa fa-trash text-danger cursorPointer"
                                            onClick={() =>
                                              handleDeleteProjectManager(e.id)
                                            }
                                          ></i>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              ) : (
                                ""
                              )}
                            </div>
                            <Link
                              as="button"
                              className="btn btn-sm hovbuttonColour mr-2 my-2"
                              to={{ pathname: "/addprojectmanager" }}
                              state={{
                                projectId: data.projectId,
                                projectName: data.projectName,
                                projectCode: data.projectCode,
                              }}
                            >
                              <i className="fa fa-plus" /> Project Manager
                            </Link>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="col-md-12"></div>






                    <div className="col-md-12 mt-3">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ backgroundColor: "#e8ecef" }}
                        >
                          <Typography>Client</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className=" col-md-12">
                           
                            <div className="col-md-12 py-2">
                              {data.clientassign !== undefined &&
                              data.clientassign !== null &&
                              data.clientassign.length > 0 ? (
                                <Table
                                  className="float-left table-border"
                                  size="small"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Client Name</TableCell>
                                      <TableCell>Contact Number</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data.clientassign.map((e) => (
                                      <TableRow key={e.id}>
                                        <TableCell>{e.customerName}</TableCell>
                                        <TableCell>
                                          {e.customerContactNo}
                                        </TableCell>
                                        <TableCell>
                                          {e.customerEmailId}
                                        </TableCell>
                                        <TableCell>
                                          {" "}
                                          <i
                                            as="button"
                                            className="fa fa-trash text-danger cursorPointer"
                                            onClick={() =>
                                              handleDeleteAssignClient(e.id)
                                            }
                                          ></i>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <Link
                            as="button"
                            className="btn btn-sm hovbuttonColour mr-2 my-2"
                            to={{ pathname: "/assignclient" }}
                            state={{
                              projectId: data.projectId,
                              projectName: data.projectName,
                              projectCode: data.projectCode,
                            }}
                          >
                            <i className="fa fa-plus" /> Assign Client
                          </Link>
                        </AccordionDetails>
                      </Accordion>
                    </div>















                    <div className="col-md-12 mt-3">
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      style={{ backgroundColor: "#e8ecef" }}
    >
      <Typography>
Site
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
    <div className="row">
      <div className="col-md-12">
        {data.site?.length > 0 && data.site.map((site,i) => (

            <Table className="float-left table-border" size="small" key={i}>
              <TableHead>
                <TableRow>
                  <TableCell>Site Name</TableCell>
                  <TableCell>Site Code</TableCell>
                  <TableCell>Status</TableCell>                  
                  <TableCell>Site Incharge Details</TableCell>
                  <TableCell>Site Address</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{site.siteName}</TableCell>
                  <TableCell>{site.siteCode}</TableCell>
                  <TableCell>{site.status}</TableCell>
                  <TableCell>
                    <div className="col-md-12">
                    </div>
                    {site.siteIncharges?.length > 0 && (
                      <div className="col-md-12">
                        <Table className="float-left table-border border" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Site Incharge Name</TableCell>
                              <TableCell>Contact Number</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {site.siteIncharges.map((incharge) => (
                              <TableRow key={incharge.id}>
                                <TableCell>{incharge.inchargeName}</TableCell>
                                <TableCell>{incharge.inchargeContactNo}</TableCell>
                                <TableCell>{incharge.inchargeEmail}</TableCell>
                                <TableCell>
                                  <i
                                    className="fa fa-trash text-danger cursorPointer"
                                    onClick={() => handleDeleteSiteIncharge(incharge.id)}
                                  />
                                                      <Link
                                              className="fa fa-plus cursorPointer ml-2 mr-2"
                                              to={{ pathname: "/addsiteincharge" }}
                                              state={{
                                                projectId: data.projectId,
                                                projectName: data.projectName,
                                                projectCode: data.projectCode,
                                                siteId: site.id,
                                                siteName: site.siteName,}}
                    />

                     
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{site.address}</TableCell>
                  <TableCell>{`${site.city}, ${site.state}, ${site.country}`}</TableCell>
                  <TableCell>
                    <i
                      className="fa fa-trash text-danger cursorPointer"
                      onClick={() => handleDeleteSite(site.id)}
                    />

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
        ))}
      </div>
    
      <div className="col-md-12">
        <FormLabel>
          <Link
            as="button"
            className="btn btn-sm hovbuttonColour mr-2 my-2"
            to={{ pathname: "/addsite" }}
            state={{
              projectId: data.projectId,
              projectName: data.projectName,
              projectCode: data.projectCode,
            }}
          >
            <i className="fa fa-plus" />  Add Site
          </Link>
        </FormLabel>
      </div>
      </div>
    </AccordionDetails>
  </Accordion>
</div>















                    <div className="col-md-12 mt-3">
                      <button
                        type="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        /* onClick={()=> }*/
                      >
                        Approve
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        /* onClick={()=> }*/
                      >
                        Review
                      </button>

                      <Link
                        as="button"
                        className="btn btn-sm deletebutton"
                        to={{ pathname: "/projects" }}
                        state={{ message: "" }}
                      >
                        <b className="text-danger"> X </b>Cancel
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectDetails;
