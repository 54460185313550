import { Form, Formik } from 'formik'
import {FormikTextField} from '../../CommonComponents/FormField.js'
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {Autocomplete,Checkbox,FormLabel,TextField} from '@mui/material';
//import {TextField} from '@mui/material';
import { ccountry, currentTenant, financialyearend, financialyearstart, transactionFrom, userid} from '../../Common/Credentials.js';
import NewLedgerService from './NewLedgerService';
import {noliaincomeexp} from './NewLedger'
import SubAccService from '../../Masters/Subaccounts/SubAccService.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { Spinner } from 'react-bootstrap';
import { partyledgers } from './Party.jsx';
import moment from "moment";
import Swal from 'sweetalert2'
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { FormControlLabel } from '@mui/material';
import IOSSwitch from '../../CommonComponents/IOSSwitch.js';
import NewJournalService from '../NewJournalService.js';
import SubHeadsService from '../Subhead/SubHeadsService.js';
import { expcombdata } from '../Journal.jsx';


// const App = React.createClass({
//   getInitialState() {
//     return { prevPath: '' }
//   }
let taxtype=0,partytype=""

// let  //,subheadid='',subaccid=''
//,amtselid=null //using for amount validation
let   pleddata=[]//,currdate=false

class NewParty extends Component {

  constructor(props) {
    super(props)


    //  const {pagename} = this.props.location.state;
    this.state = {
      // type: 'password',
      //  pagename:pagename,
      id:'',
      partydata:[],
      empdata:[],
      subheads:[],
      subaccdata:[],
      LedGroups:[],
      amtselid:null,
     name:'',
    error:'',
   ifsccode:'',
    bank:'',
    branch:'',
    pan: "",
    ledgername:'',
    prevPath: '',
    loader:false, 
    ledtype:0,
    ledopeval:0,
    fed:'',
    SDdate:false,
    opereq:false,
    opetype:false,
    mapleddata:"",
    amount:0,
    partytype1:null,
    amountval:"",
    topheadtype:"",
    noexp:[{"id":"noexp","name":"No Expense Mapping","amount":0.00,"entrytype":0,"subacc":"","tenantId":"","rule":0,"behaviour":0,"subname":"z - NA","totamount":null,"th":"","taxtype":0}],
    notename:"",notetype:"",refund:1,receipt:1,expval:[],val:[],

    ledgertypesbtn:[
      {id:0,name:"Customers/Suppliers/Agents"},
      {id:1,name:"Employees / Workers"},
      {id:2,name:"Investors / Partners"},
  ],

    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.goBack=this.goBack.bind(this);this.valueAction=this.valueAction.bind(this);
    this.valueBehaviuor=this.valueBehaviuor.bind(this);this.setPartyType=this.setPartyType.bind(this)
  }

  componentDidMount() {
    this.setState({mapleddata:noliaincomeexp})
    this.setState({amtselid:null})
    // this.setState({
    //   fed: moment(transactionFrom.$d).format("yyyy-MM-DD HH:mm:ss"),
    // });

   // amtselid=null
//     alert(this.props.history.location.state.lv)
// alert(this.state.amtselid)
// alert(moment(financialyearstart).format("YYYY-MM-DD"))
   // if(this.props.history.location.state.lv==='Personal'){
      //    this.setState({ledtype:this.props.history.location.state.lv})
      //     // frmpurchase=this.props.history.location.state.lv
      //   //  console.log(this.props.history.location.state.lv),
      // }

    //}

    // console.log(this.props.history.location.state)

    NewJournalService.getAllLedgers(currentTenant)
    .then(response=>{
      this.setState({mapleddata:response.data.filter(e=>e.th==="TH3"),val:response.data})
      //noliaincomeexp=totledgers.filter(e=>e.th==="TH3")
      })
   
  NewLedgerService.retrivePartyLedgers(currentTenant)
  .then(response => {
    this.setState({partydata:response.data})
  })
  NewLedgerService.retriveEmployees(currentTenant)
  .then(response => {
    this.setState({empdata:response.data})
  })

  SubAccService.retriveSubHead(currentTenant)
  .then(response =>{
    
    this.setState({subheads:response.data})

  })
// if(this.state.ledtype===2){
  SubAccService.retriveSubIdName(currentTenant)
  .then(response =>{
    this.setState({subaccdata:response.data})
  })
  if(partyledgers===undefined){
    this.addData()
  }else{
    pleddata=partyledgers
  }

  SubHeadsService.getLedGroups(currentTenant)
  .then(
    response => {
        this.setState({LedGroups:response.data,loading:false})
       // ledgroups=response.data
      })    
  
// }
  if(userid===undefined){
    window.confirm('Your Session is Lost: Please login Back to resume')
    this.props.navigate(`/`)
  }

}

goBack(){
  this.props.navigate(-1);
}

addDataNew(){
  this.setState({refund:2,ledopeval:0})
   
  //let val = response.data;

  let th2=this.state.val.filter(e=>e.th==="TH2")
  let th5=this.state.val.filter(e=>e.th==="TH5")
  let expval1 = th2.concat(th5)
  this.setState({expval:expval1.concat(this.state.noexp)})
}

addDataNew1(){
  this.setState({receipt:2,ledopeval:0})
   
  //let val = response.data;
  let th2=this.state.val.filter(e=>e.th==="TH2")
  let th5=this.state.val.filter(e=>e.th==="TH5")
  let expval1 = th2.concat(th5)
  this.setState({expval:expval1.concat(this.state.noexp)})

}

// componentDidUpdate(nextProps){
//   if (nextProps.location !== this.props.location) {
//     this.setState({ prevPath: this.props.location.prevPath })
//   }
// }

onChange(option){
  this.setState({ledtype: option.id});
}

addData(){
  this.setState({amtselid:1})
  NewLedgerService.getPledgers(currentTenant)
  .then(response =>{
    this.setState({pledgers:response.data})
    pleddata=response.data
  })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

}

setPartyType(value){
  if(value!==null){
    partytype =value.ptype
   // this.setState({partytype1:value.ptype})
  //console.log(partytype)
  }
}

onSubmit(values) {
  
  let amt


    if(values.amount!==undefined){
    if(values.pledger!==null && values.pledger.ptype==="CUSTOMER"){
      if(this.state.opetype===false){
        amt=values.amount
      }else{
        amt=-values.amount
      }
    }else{
      if(this.state.opetype===true){
        amt=-values.amount
      }else{
        amt=values.amount
      }
    }
   }else{
      amt=this.state.amount
    }

  // if(values.amount!==undefined){
  //   if(this.state.opetype===false){
  //     if(values.pledger.ptype==="CUSTOMER"){
  //       amt=values.amount
  //     }else{
  //       amt=-values.amount
  //     }
  //   }else{
  //     if(values.pledger.ptype==="CUSTOMER"){
  //       amt=-values.amount
  //     }else {
  //       amt=values.amount
  //     }
  //   }
  // }else{
  //   amt=this.state.amount
  // }
 // amt=values.amount!==undefined?
 // this.state.ledvalue===0 && this.state.opetype===true && values.subhead.th==="TH3" ?values.amount:-values.amount:this.state.amount
  // if(values.amount===undefined){
  //   amt=0
  // }else{
  //   amt=values.amount
  // }
  let pledger,ledgroupid,ledgroupname,eledger
  if(values.ledgroup !== null ){
    ledgroupid = values.ledgroup.id
    ledgroupname= values.ledgroup.name
  }
  if(values.pledger !== null ){
    pledger = values.pledger.id
  }
  else{
    pledger = null
  }
  if(values.eledger !== undefined){
    eledger = values.eledger.id
  }
  else{
    eledger = null
  }
  let pan 
  if(this.state.ledtype===0 && ccountry==="India"){
    pan=values.pledger.pan
  }else{
    pan=values.pan
  }

  this.setState({loader:true})

  let newpledger = {
    name: values.name,
    createdBy:userid,
    amount:amt,
    pledger:pledger,ledgroupid:ledgroupid,ledgroupname:ledgroupname,eledger:eledger,
    expenseid:values.expense!==undefined?values.expense.id:null,
    expensename:values.expense!==undefined?values.expense.name:null,
    assledid:this.state.amtselid===1 && values.assled!==null?values.assled.id:null,
    assledname:this.state.amtselid===1 && values.assled!==null?values.assled.name:null,
    subhead:values.subhead.id,
    tenantId:currentTenant,
    pan:pan,
    date:this.state.fed!==''?this.state.fed:null,
    receipt:amt!==0?parseInt(this.state.receipt):parseInt(0),
    refund:amt!==0?parseInt(this.state.refund):parseInt(0),
    notetype:amt!==0?parseInt(this.state.notetype):parseInt(0),
   // ledtype:parseInt(this.state.ledtype),
    //bank:values.bank,
    //accname:values.accname,
   // accnumber:values.number,
    //ifsc:values.ifsccode,
    //branch:values.branch,
    taxtype:taxtype,
    partyledtype:this.state.ledtype,
  }
 // let updatepledger = {
    // id:this.state.id,
    // name: values.name,
    // createdby:userid

   //  }
  //  MasterService.createTenant(newtenant).then(() => this.props.navigate(`/tenants`))
  if (this.state.id === '') {
    
    // if(values.name===partyledgers.map((e)=>e.name)){
    //   alert('Ledger already exists !! Please Use a Different Name')
    // }else{

    if(this.state.ledopeval===0){
    NewLedgerService.createPartyLedger(newpledger)
     .then(() =>  
    {this.props.location.state.lv!==undefined?this.goBack():
    // //,{message:values.name + "   : Created Successfully"}
     this.props.navigate({pathname:`/newledgers`},{state:{message:values.name + "   : Created Successfully"}})
   // this.props.navigate(<AssetsLiability/>)
    })
    
    .catch(()=>this.setState({loader:false}))
    // console.log(newpledger)
    // }
  }else{
    alert("Opening behaviour needs to be selected")
    this.setState({loader:false})
  }
  }else {
    // AccTopService.updateAcctop(updateAccTop)
    // .then(() => 
    //  this.props.navigate('/acctop',{message:values.name + "   : Updated Successfully"}))

}   
}

handleChange(value){
this.setState({ledgername:value})
}


 
validate(values) {
    let errors = {};
    var chars = /^[A-Za-z0-9-_]+$/
    var num = /^[0-9]+$/

    if (!values.name) {
      errors.name = <span className="text-right">"Account Name is required"</span>;
    }
    else if (!chars.test(values.name)) {
      errors.name = 'Only Characters,numarics and - _ are allowed- empty Spaces are not allowed';
    }

    if(this.state.opereq===true){
    if (!values.amount) {
      errors.amount = <span className="text-right">"Amount is required"</span>;
    }
    else if (!num.test(values.amount)) {
      errors.amount = 'Only Numbers are allowed';
    }
  }
    let a = pleddata.map((e)=>e.name)
    if(values.name!==undefined){
    if(a.find(e=>e.toUpperCase().trim()===values.name.toUpperCase().trim())){
      errors.name = 'Ledger already exists !! Please use a different name for Ledger';
    }
  }
    if(this.state.ledtype === 0){
    if (!values.pledger || values.pledger==='' ) {
      errors.pledger = "Party ledger is required";
    }
    if (!values.ledgroup || values.ledgroup==='' ) {
      errors.ledgroup = "Ledger group is required";
    }
  }

  if(this.state.ledtype === 1){
  if (!values.eledger || values.eledger==='' ) {
    errors.eledger = "Employee is required";
  }
}

  if(this.state.ledtype !== 0 && ccountry==="India"){
    if (this.state.pan==="") {
      errors.pan = "Pan is required";
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}/gi.test(this.state.pan)) {
      errors.pan = "Invalid Ex: ABCDE1234F";
    }
    }
  if(this.state.amtselid===1){
    if (!values.assled || values.assled===undefined) {
      errors.assled = "Accounts is required";
    }
  }
    if (!values.subhead || values.subhead===undefined) {
      errors.subhead = "Accounts is required";
    }

if(this.state.refund===2 || this.state.receipt===2){
    if (!values.expense || values.expense===undefined) {
      errors.expense = "Accounts is required";
    }}
    // if (!values.amount || values.amount===undefined) {
    //   errors.amount = "Amount is required. Enter 0 if no value";
    // }
    // if(!values.ifsccode){
    //   errors.ifsccode =" IFSC Required"
    // }else if(values.ifsccode.length!==11){
    //   errors.ifsccode =" Invalid"

    // }
    
    return errors;

  }
  handleFsdDateChange = (date) => {
    if(date!==null){
    this.setState({ fsd: date.$d });
    this.setState({
      fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
    });
  }else{
    this.setState({ SDdate:false})
  }
  };

  SubHead(value){
    // topheadtype=''
    // subheadid=''
    // subaccid=''
    if(value!==null){
    this.state.subaccdata.map((e)=> {return e.id===value.sa?this.setState({topheadtype:e.th}):""})
    // subaccid=value.sa
    // subheadid=value.id
    }
  }

  valueAction(value){
  if((value>0 && value!=="") || (value<0 && value!=="")){
    this.setState({amountval:value},()=>this.valueBehaviuor())
  }else{
    this.setState({amountval:""},()=>this.valueBehaviuor())
  }
  // if(value>0 && topheadtype==='TH4'){
  //   this.addCorrepondingLedger()
  // }else if(value<=0){
  //   this.setState({amtselid:null})
  // }
}
 valueBehaviuor(){

  if(this.state.opetype===true && this.state.amountval!==0 &&  this.state.topheadtype==='TH4'){
    this.setState({notename:"Opening Behaviour : Supplier Credit Payable",notetype:1})

  }else if(this.state.opetype===false && this.state.amountval!==0 && this.state.topheadtype==='TH4'){
    this.setState({notename:"Opening Behaviour : Supplier Debit Receivable",notetype:2})


  }else if(this.state.opetype===false && this.state.amountval!==0 && this.state.topheadtype==='TH3'){
    this.setState({notename:"Opening Behaviour : Customer Debit Receivable",notetype:3})
    // alert("Customer Credit Note")//this.state.amountval>0

  }else if(this.state.opetype===true && this.state.amountval!==0 && this.state.topheadtype==='TH3'){
    this.setState({notename:"Opening Behaviour : Customer Credit Payable",notetype:4})

    // alert("Customer Debit Note")//this.state.amountval<0

  }else{
    this.setState({notename:"",notetype:""})

  }

 }


 addCorrepondingLedger(){
  Swal.fire({
    title: "Corresponding",text: "Do you want to add corresponding ledger",icon: "question",confirmButtonText: "Yes",
    showCancelButton: true,cancelButtonText:"No",confirmButtonColor: "#152f5f",
  }).then((result)=>{
    if(result.value===true){
     this.addData()
    }else{
      this.setState({amtselid:null})
      //this.updateState(e)
    }
  })
 }

//  addData(){
//  // amtselid=1
//   //alert("added the ledger field")
//  }

 handleSDDate=()=>{
  this.setState({SDdate:this.state.SDdate===true?false:true})
}

handleOpereq=()=>{
  this.setState({opereq:this.state.opereq===true?false:true},()=>{
    if(this.state.ledtype === 0){
    this.setState({ledopeval:this.state.opereq===true?0:1})}
})
}

handleOpeType=()=>{
  this.setState({opetype:this.state.opetype===true?false:true})
}

// handleCDDate=()=>{
//   this.setState({CDdate:true,SDdate:false})
// }
    render() {
      let {id,name,partydata,ifsccode,bank,subheads} = this.state

      
      let a=subheads.filter((e) => e.rule === 0)
      // console.log(a)

      let b,c,val,a1=[]
      // if(!this.state.subaccdata){
        a1=this.state.subaccdata.filter((e) => e.id === "AH000001")
      // }
      b=a.filter((e)=> e.sa==="AH000018")
      c=a.filter((e)=> e.sa==="AH000019")

      if(this.state.ledtype===0){
        // alert(this.state.partytype)
       // Object.assign(b.filter((e)=>e.name="Sundry Debtors(Customers) - Receivables"))
      //  Object.assign(c.filter((e)=>e.name="Sundry Creditors(Suppliers/Agents) - Payables"))
              // if(this.state.partytype1==="CUSTOMER"){

        //val=b
      // }else if(this.state.partytype1==="SUPPLIER"){
      // Object.assign(c.filter((e)=>e.name="Sundry Creditors(Suppliers/Agents) - Payables"))
      // val=c
      //  }
       val=b.concat(c)
      // val=b/.concat(c)

      }else if(this.state.ledtype===1){
      //  Object.assign(c.filter((e)=>e.name="Sundry Creditors(Employees) - Payables"))
        val=c
      }else if(this.state.ledtype===2){
       
          let b 
          a1.map((e) => {return b = e.subheads})
          val=b
         // console.log(b)
        
       // Object.assign(c.filter((e)=>e.name="Sundry Creditors(Partners/Directors) - Payables"))
      }

        return (
          <div >
            <Header />
            <Menu/>
          
          <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">{this.state.name}</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                          Accounts</li>
              <li className="breadcrumb-item text-secondary">
                          Ledgers</li>
              <li className="breadcrumb-item text-secondary">
                {/* <Link to="/newledgers">New Party Ledger</Link> */}
                New Party Ledger
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>

            <div className="card-body">







                <Formik
              
              initialValues={{id,name,partydata,ifsccode,bank,val,pledger:null,subhead:null,ledgroup:null,pan:null,amount:undefined,expense:undefined}}
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validate={this.validate}
              enableReinitialize={true}>
              {({setFieldValue,values}) => (
                
                <Form autoComplete="off">

                  <div className="form-row">
                    
                    <div className="form-row">

                      {this.state.ledgertypesbtn.map((option)=>{

                        return <label key={option.id} >
                          <div className="form-group col-md-12 text-sm">
                            <div className="btn-group">
                              <input 
                                className={
                                  this.state.ledtype === option.id
                                    ? "btn buttonColour text-yellow text-bold"
                                    : "btn buttonColour"
                                }
                                type="button" 
                                // checked={this.state.checked === option.id ? true : false} 
                                key={option.id}
                                onClick={this.onChange.bind(this,option)} 
                                style={{marginRight: 8,width:200}}
                                value={option.name} 
                              />
                            </div>
                          </div>
                        </label>
                        })
                      }
                    </div> 

                    <div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    </div>
                  {this.state.ledtype === 0?
                  <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="partyledger"
                      //className="pledger-select"
                      //name="pledger1"
                      options={partydata}
                      getOptionLabel={(option) => option.companyname+" ("+(option.customid!==null?option.customid:option.id)+")"}
                      isOptionEqualToValue={(option) => !option.value}
                      value={values.pledger?undefined:undefined}
                      onChange={(e, value) => {
                        // console.log(value);
                          setFieldValue("pledger", value);
                        
                         // setFieldValue("name", this.state.ledgername.companyname)
                          values.pledger==='' || values.pledger===null || value===null?                         
                          setFieldValue("name",'')
                          :setFieldValue("name", value.name)
                        //   {values.pledger!==null?setFieldValue("name", value.companyname):setFieldValue("name",'')} 

                        //  setFieldValue("name", "")}
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        // style={{ height }}
                        type="text"
                        name="pledger"
                        placeholder="Select Party"
                        variant="standard"
                        onBlur={this.setPartyType.bind(values.pledger)} 
                        fullWidth
                        required
                        helpertext="Account is required"
                      />
                    )}
                  />
                  </fieldset>
                  :''}

{this.state.ledtype === 1?
                  <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="empledger"
                      //className="pledger-select"
                      //name="pledger1"
                      options={this.state.empdata}
                      getOptionLabel={(option) => option.first +" "+option.last+" ("+option.customid+")"}
                      isOptionEqualToValue={(option) => !option.value}
                      value={values.pledger?undefined:undefined}
                      onChange={(e, value) => {
                        // console.log(value);
                          setFieldValue("eledger", value);
                        
                         // setFieldValue("name", this.state.ledgername.companyname)
                          values.pledger==='' || values.pledger===null || value===null?                         
                          setFieldValue("name",'')
                          :setFieldValue("name", value.name)
                        //   {values.pledger!==null?setFieldValue("name", value.companyname):setFieldValue("name",'')} 

                        //  setFieldValue("name", "")}
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        // style={{ height }}
                        type="text"
                        name="eledger"
                        placeholder="Select Employee"
                        variant="standard"
                        fullWidth
                        required
                        helpertext="Account is required"
                      />
                    )}
                  />
                  </fieldset>
                  :''}



        <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="subaccount"
                      className="subhead-select"
                      name="subhead"
                      options={val}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option) => !option.value}
                      value={values.subhead?undefined:undefined}
                      onChange={(e, value) => {
                      //  console.log(value);
                       setFieldValue("subhead", value) && this.SubHead(value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="subhead"
                        // style={{ height }}
                        placeholder="Select Account"
                        variant="standard"
                        // onBlur={(e) => {
                        //   //  console.log(value);
                        //   this.liabilitySubHead(e);
                        //     }}
                        
                        fullWidth
                        required
                        helpertext="Account is required"
                      />
                    )}
                  />
                      </fieldset>
                      
                      {this.state.ledtype === 0?
                     <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="ledgroup"
                      className="ledgroup-select"
                      name="ledgroup"
                      options={this.state.LedGroups}
                      //getOptionLabel={option => option.id+option.companyname}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option) => !option.value}
                      value={values.subhead?undefined:undefined}
                      onChange={(e, value) => {
                      //  console.log(value);
                       setFieldValue("ledgroup", value) && this.SubHead(value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="ledgroup"
                        // style={{ height }}
                        placeholder="Select Group"
                        variant="standard"
                        // onBlur={(e) => {
                        //   //  console.log(value);
                        //   this.liabilitySubHead(e);
                        //     }}
                        
                        fullWidth
                        required
                        helpertext="Group is required"
                      />
                    )}
                  />
                      </fieldset>:""}


                      <fieldset className="form-group col-md-6 mb-4">
                      <FormikTextField

                        className="form-control"
                        name="name"
                        // style={{ height }}
                        placeholder="Ledger Name"
                        variant="standard"
                        fullWidth
                        required
                        helpertext="Ledger is required"
                      />
                      
                      </fieldset>
                    {/*  <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        // style={{ height }}
                        name="ifsccode"
                        placeholder="IFSC Code"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset> */}
{/* 
                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        // style={{ height }}
                        name="bank"
                        placeholder="Bank"
                        variant="standard"
                        fullWidth
                      /> */}
    
                      {/* </fieldset>
                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="branch"
                        // style={{ height }}
                        placeholder="Branch"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset> */}
{/* 
                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="number"
                        //style={{ height }}
                        placeholder="Account Number"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset> */}
{/* 
                      <fieldset className="form-group col-md-6">
                      <FormikTextField
                        className="form-control"
                        name="accname"
                        //style={{ height }}
                        placeholder="Account Name"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset> */}

                  {this.state.ledtype !== 0 && ccountry==="India"?
                      <fieldset className="form-group col-md-6">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="pan"
                          placeholder="PAN - Ex: ABCDE1234F"
                          value={this.state.pan}
                          variant="standard"
                          fullWidth
                        required
                          onChange={(e) =>
                            this.setState({ pan: e.target.value })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>:''}
                      <fieldset className="form-group col-md-3 mb-4 text-right">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.opereq} onChange={this.handleOpereq} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.opereq===false?"No Opening":"Opening"}</span>
       </fieldset>

      {this.state.opereq===true && this.state.ledtype===0?
       <fieldset className="form-group col-md-3 mb-4 text-center">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.opetype} onChange={this.handleOpeType} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.opetype===false?"Receivable":"Payable"}</span>
       </fieldset>:""}

{values.name && this.state.opereq===true?

<>
                      <fieldset className="form-group col-md-6 mb-4">
                      <FormikTextField
                        className="form-control"
                        name="amount"
                        //style={{ height }}
                        placeholder="Opening Balance"
                        type="number"
                        variant="standard"
                        fullWidth
                       // defaultValue={this.state.amount}
                        required
                         onBlur={(e) => {
                          this.valueAction(e.target.value);
                            }}
                        helpertext="Amount is required"
                      />
                      </fieldset>
                      
                      <fieldset className="form-group col-md-6 mb-4">
                      
                      </fieldset>
                      </>
                      :""}


{this.state.ledtype===0 && this.state.notename!=="" && this.state.amountval!==""  && values.name? //&& partytype!=="AGENT"
 <fieldset className="form-group col-md-6 mb-4">
<label>{this.state.notename}</label>

{this.state.ledtype===0 && this.state.notetype===1 && this.state.opetype===true?
<>
 <FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
 <FormControlLabel control={<Checkbox checked={this.state.refund===1} onChange={()=>this.setState({refund:1,ledopeval:0})}/>} label="Refund" />
 </>
:this.state.ledtype===0 && this.state.notetype===2  && this.state.opetype===false?
<>
 <FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
 <FormControlLabel control={<Checkbox checked={this.state.receipt===1} onChange={()=>this.setState({receipt:1,ledopeval:0})}/>} label="Receivable" />
 {partytype==="AGENT"?"":
   <FormControlLabel control={<Checkbox checked={this.state.receipt===2} onChange={()=>this.addDataNew1()}/>} label="Adjust amount in next PO" />}
      {/* <FormControlLabel control={<Checkbox checked={this.state.refund===false} onChange={()=>this.setState({refund:false})}/>} label="Adjust amount in next Invoice" /> */}
 </>
:this.state.ledtype===0 && this.state.notetype===3 && this.state.opetype===false?
<>
 <FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
   <FormControlLabel control={<Checkbox checked={this.state.receipt===1} onChange={()=>this.setState({receipt:1,ledopeval:0})}/>} label="Receipt" />
   {/* <FormControlLabel control={<Checkbox checked={this.state.refund===false} onChange={()=>this.setState({refund:false})}/>} label="Adjust amount in next Invoice" /> */}
 </>
:this.state.ledtype===0 && this.state.notetype===4 && this.state.opetype===true?<>
<FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
  <FormControlLabel control={<Checkbox checked={this.state.refund===1} onChange={()=>this.setState({refund:1,ledopeval:0})}/>} label="Refund" />
  <FormControlLabel control={<Checkbox checked={this.state.refund===2} onChange={()=>this.addDataNew()}/>} label="Adjust amount in next Invoice" />
</>
:""
}

{this.state.refund===2 || this.state.receipt===2?
  <fieldset className="form-group col-md-6 mt-3 mb-4">
  <Autocomplete id="expense" className="debit-select" name="expense" value={"expense" ? undefined : undefined}
  options={this.state.expval.sort((a, b) => -a.subname.localeCompare(b.subname))} groupBy={(option) => option.subname}
  getOptionLabel={(option) => option.name}  
  onChange={(e, value) => {setFieldValue("expense", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="expense" label="Map Expense and Losses" className="form-control"
  size='small' onChange={(e) => this.expLedgers(e.target.value)}
  variant="standard" fullWidth /> )} /> </fieldset> :""}

 </fieldset>:""
}



{this.state.amtselid===null || this.state.amtselid!==1?"":
    <fieldset className="form-group col-md-12">
    <Autocomplete
         id="assled" className="assled-select" name="assled1" options={this.state.mapleddata!==undefined?this.state.mapleddata:this.addDataNew()} getOptionLabel={(option) => option.name}
         isOptionEqualToValue={(option) => !option.value}
         value={values.subhead?undefined:undefined}
 onChange={(e, value) => {
  setFieldValue("assled", value);
   }}
 renderInput={params => (
   <FormikTextField
     {...params}
   className="form-control" type="text" name="assled" placeholder="Select Account" variant="standard"
   fullWidth required helpertext="Account is required"/>
)}/>
 </fieldset>}
{/* {addlled!==''?addlled:''} */}
                    </div>

                  <div>
                  <div className="form-row">
                    <fieldset className="form-group col-md-12">
                  {this.state.loader===true? <button className="btn hovbuttonColour mr-1 btn-sm" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>:<button className="btn hovbuttonColour mr-1 btn-sm" type="submit"><i className="fas fa-pen"></i>{"Save"}</button>}
                  {/* </fieldset>
                  <fieldset className="form-group col-md-2">  */}
                  <button  className="btn deletebutton btn-sm" type="button" onClick={this.goBack}><i className="fas fa-times"/> Close</button>
                  {/* <Link as="button" type="submit" className="btn btn-success" to={{pathname: , state: {message:''}}}>Close</Link> */}
                  </fieldset>
                  
                  </div>
                  </div>
                 
                  

                </Form>
              )}
            </Formik>
            </div>
            </div>
          

            </div>
            <Footer/>
            </div>
        )
    }
}
export default withRouter(NewParty);
