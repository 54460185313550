import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import moment from "moment";
import { spin } from "../../Common/NewLoader.jsx";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { salesid } from "../../Common/Permissions";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu";
import InventoryService from "../InventoryService.js";
import { ThemeProvider } from "@mui/material";
import {
  createTheme,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import PosService from "../../Sales/PointofSales/PosService";
import {NumericFormat} from "react-number-format";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class acceptingstockissueTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockissue: [],
      acceptedstock: [],
      returnedstock: [],
      lossesdata: [],
      priceData:[],
      returned: false,
      accepted: true,
      lossesb: false,
      loading: false,
      comments: ["Damaged", "Shortage"],
      comment: "",
      losses: "",
      price: 0,
    };
    this.refreshStocks = this.refreshStocks.bind(this);
  }

  handleAccepted = () => {
    this.setState({ accepted: true, returned: false, lossesb: false });
  };
  handlereturned = () => {
    this.setState({ accepted: false, returned: true, lossesb: false });
  };
  handlelosses = () => {
    this.setState({ accepted: false, returned: false, lossesb: true });
  };

  updateStockClicked(id) {
    this.props.navigate({
      pathname: `/updatestock`,
      state: {
        id: { id },
      },
    });
  }

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleAddLosses = (data) => {
    let lossesdata = {
      said: data,
      // batchid:this.state.acceptedstock.find((e) => e.id === data).batchid,
      itemid: this.state.acceptedstock.find((e) => e.id === data).itemid,
      itemname: this.state.acceptedstock.find((e) => e.id === data).itemname,
      quantity: this.state.losses,
      catid: this.state.acceptedstock.find((e) => e.id === data).catid,
      uom: this.state.acceptedstock.find((e) => e.id === data).uom,
      price: this.state.acceptedstock.find((e) => e.id === data).cost,
      totalloss:this.state.acceptedstock.find((e) => e.id === data).cost *
      parseFloat(this.state.losses),
      tenantid: currentTenant,
      comments: this.state.comment,
      deptid: this.state.acceptedstock.find((e) => e.id === data).deptid,
      whid: this.state.acceptedstock.find((e) => e.id === data).whid,
      createdby: userid,
      // store:this.state.id
    };
    PosService.NewPosLosses(lossesdata).then(() =>
      //   this.state.stockissue.find((e) => e.id === data).quantity =
      //   this.state.stockissue.find((e) => e.id === data).quantity -
      //   this.state.losses;
      // this.state.stockissue.find((e) => e.id === data).lossstatus = false;
      // this.setState({ stockissue: this.state.stockissue });
      this.refreshStocks()
    );
  };

  handlelossstatus = (data) => {
    this.state.acceptedstock.find((e) => e.id === data).lossstatus = true;
    this.setState({ acceptedstock: this.state.acceptedstock });
  };

  handleclose = (data) => {
    this.state.acceptedstock.find((e) => e.id === data).lossstatus = false;
    this.setState({ acceptedstock: this.state.acceptedstock });
  };

  refreshStocks() {
    this.setState({ accepted: true, returned: false, lossesb: false });
    InventoryService.GetAcceptedStockIssue(currentTenant)
      .then((response) => {
        this.setState(
          {
            stockissue: response.data.sort((a, b) =>
              a.createdAt < b.createdAt ? 1 : -1
            ),
            loading: false,
          },
          () =>
            this.setState(
              {
                acceptedstock: this.state.stockissue.filter(
                  (e) => e.acceptedquantity > 0
                ),
                returnedstock: this.state.stockissue.filter(
                  (e) => e.returnquantity > 0
                ),
              },
              () =>
                this.setState({
                  acceptingstock: this.state.stockissue.map((e) =>
                    Object.assign(e, { lossstatus: false })
                  ),
                })
            )
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    PosService.GetPosLosses(currentTenant).then((response) =>
      this.setState({ lossesdata: response.data })
    );
    PosService.GetPosPrices(currentTenant).then((response) =>
      this.setState({
        priceData: response.data,
        loading: false,
      })
    );
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshStocks();
  }

  onquantityfocus() {
    document
      .getElementById("quantity")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("quantity")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true },
      },
      {
        name: "createdbyname",
        label: "Issued By",
        options: { filter: true, sort: true },
      },
      {
        name: "receivedbyname",
        label: "Accepted By",
        options: { filter: true, sort: true },
      },
      {
        name: "whaddress",
        label: "Warehouse",
        options: { filter: false, sort: true },
      },
      {
        name: "deptname",
        label: "Department",
        options: { filter: false, sort: true },
      },
      {
        name: "acceptedquantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "description",
        label: "Description",
        options: { filter: false, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder : "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "lossstatus",
        label: "Losses",
        options: {
          filter: true,
          sort: true,
          display:salesid === "2" || salesid === "3",
          customBodyRender: (value, tableMeta, updateValue) => {
            return value === true ? (
              <div className="row">
                <fieldset className="form-group col-md-6">
                  <TextField
                    id="quantity"
                    type="number"
                    label="Quantity"
                    onChange={(e) => this.setState({ losses: e.target.value<0?0:e.target.value })}
                    variant="standard"
                    error={this.state.losses>tableMeta.rowData[6]}
                    helperText={this.state.losses>tableMeta.rowData[6]?"Exceeds avilable quantity":""}
                    fullwidth ="true"
                    onFocus={this.quantityfocus}
                  />
                </fieldset>
                <fieldset className="form-group col-md-6">
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    className="col-md-12"
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Comments
                    </InputLabel>
                    <Select
                      value={this.state.comment}
                      onChange={this.handleCommentChange}
                    >
                      {this.state.comments.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="form-group col-md-12">
                  <button
                    as="button"
                    className="hovbuttonColour btn btn-sm fa fa-save mr-1"
                    onClick={() => this.handleAddLosses(tableMeta.rowData[0])}
                    disabled={
                      this.state.losses === undefined ||
                      this.state.losses === "" ||
                      this.state.losses === 0 ||
                      this.state.comment === ""||this.state.losses>tableMeta.rowData[6]
                    }
                  ></button>
                  <button
                    as="button"
                    className="deletebutton btn btn-sm"
                    onClick={() => this.handleclose(tableMeta.rowData[0])}
                  >
                    {" "}
                    X
                  </button>
                </fieldset>
              </div>
            ) : (
              <button
                as="button"
                className="hovbuttonColour btn btn-sm mr-1"
                onClick={() => this.handlelossstatus(tableMeta.rowData[0])}
              >
                <i className="fa fa-plus"></i>
              </button>
            );
          },
        },
      },
    ];

    const columns2 = [
      {
        name: "id",
        label: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true },
      },
      {
        name: "createdbyname",
        label: "Issued By",
        options: { filter: true, sort: true },
      },
      {
        name: "receivedbyname",
        label: "Accepted By",
        options: { filter: true, sort: true },
      },
      {
        name: "whaddress",
        label: "Warehouse",
        options: { filter: false, sort: true },
      },
      {
        name: "deptname",
        label: "Department",
        options: { filter: false, sort: true },
      },
      {
        name: "returnquantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "description",
        label: "Description",
        options: { filter: false, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder : "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {salesid === "2" || salesid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/acceptingstockissue"}}
                  state={{ id: -1, pagename: "Add Stock Issue"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    const columns3 = [
      {
        name: "id",
        label: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <>
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={tableMeta}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      { name: "uom", label: "UOM", options: { filter: false, sort: true } },
      {
        name: "warehouse",
        label: "Warehouse",
        options: { filter: false, sort: true },
      },
      {
        name: "dept",
        label: "Department",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "comments",
        label: "Comments",
        options: { filter: false, sort: true },
      },
      {
        name: "totalloss",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <>
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={tableMeta}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder : "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "createdby",
        label: "Created By",
        options: { filter: true, sort: true },
      },
      {
        name: "accstatus",
        label: "Status",
        options: { filter: true, sort: true },
      },
    ];
    const options3 = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Stocks Acceptance</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Stocks Acceptance</h3>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center"> {spin}</div>
                          ) : (
                            <>
                              <div className="form-row">
                                <fieldset className="form-group col-md-4">
                                  <span className="mx-3">Accepted</span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.accepted}
                                        onChange={this.handleAccepted}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-4">
                                  <span className="mx-3">Returned</span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.returned}
                                        onChange={this.handlereturned}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-4">
                                  <span className="mx-3">Losses</span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.lossesb}
                                        onChange={this.handlelosses}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                              </div>
                              {this.state.accepted === true ? (
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm"
                                    title={"Accepted Stock"}
                                    data={this.state.acceptedstock}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                              ) : (
                                ""
                              )}
                              {this.state.returned === true ? (
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm"
                                    title={"Returned Stock"}
                                    data={this.state.returnedstock}
                                    columns={columns2}
                                    options={options}
                                  />
                                </ThemeProvider>
                              ) : (
                                ""
                              )}
                              {this.state.lossesb === true ? (
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm"
                                    title={"Losses"}
                                    data={this.state.lossesdata}
                                    columns={columns3}
                                    options={options3}
                                  />
                                </ThemeProvider>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(acceptingstockissueTable);
