import React from "react";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import { Autocomplete, Backdrop, CircularProgress, Grid, } from "@mui/material";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  contactnumber,
  cstate,
  cstatecode,
  currency,
  currentTenant,
  czip,
  dataaccess,
  einvoice,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  transactionFrom,
  userid,
  userwhid,
} from "../../Common/Credentials.js";
import { Form, Formik } from "formik";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import SalesServices from "../SalesServices";
import downloadfiles from "../../Common/upload/downloadfiles.js";
import { NumericFormat } from "react-number-format";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { Modal, Spinner } from "react-bootstrap";
import InventoryService from "../../inventory/InventoryService.js";
import SettingsServices from "../../Settings/SettingsServices.js";
import MasterService from "../../Masters/MasterService.js";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import TransporterServices from "../../Masters/Transporter/TransporterServices.js";
import CategoryServices from "../../Masters/Category/CategoryServices.js";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices.js";
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";
import CustomerService from "../../Masters/Customers/CustomerService.js";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DepartmentServices from "../../Masters/Department/DepartmentServices.js";

let datalist = [];

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invtype: "Order Invoice",
      invid: this.props.location.state.id,
      pagename: this.props.location.state.pagename,
      saleorderdata: [],
      usercustomers: [],
      saleorderid: "",
      saleorderitems: [],
      custom: [],
      customer: "",
      custid: "",
      agent: "",
      agentid: "",
      customerdetails: "",
      debitamount: 0,
      openingbalance: 0,
      adjustdebit: false,
      adjustopen: false,
      itemid: "",
      itemname: "",
      salesenum: "",
      quantity: 0,
      orderedquantity: "",
      pendingquantity: "",
      grandtotal: "",
      status: "0",
      sodata: "",
      addservice: false,
      serviceList: [],
      milestones: [],
      contracttype: "",
      DnIDList: [],
      dndata: "",
      dnid: "",
      completed: "",
      backdateinvoicestatus:false,
      sotype: 0,
      show: 0,
      open: false,
      open1: false,
      po: "",
      accepteditems: [],
      loader: false,
      creditperiod: 0,
      serviceid: "",
      currencylist: [],
      currencyid: "",
      currency: "",
      curconversionrate: 1,
      invtotalamountwtax: 0,
      tcsreq: false,
      tcs: 0,
      tcsamount: 0,
      whdata: [],
      itemquantitylist: [],
      batchidlist: [],
      batchid: "",
      quantitywindow: false,
      pouploadm: "",
      vehiclenum: null,
      distance: 0,
      transporterid: null,
      name: "",
      phoneno: "",
      pincode: "",
      state: "",
      statecode: "",
      city: "",
      houseno: "",
      areaname: "",
      country: "",
      disname: "",
      disphoneno: "",
      dispincode: "",
      disstate: "",
      disstatecode: "",
      discity: "",
      dishouseno: "",
      disareaname: "",
      discountry: "",
      err: false,
      adderr: false,
      CountryData: [],
      StatesData: [],
      CitiesData: [],
      disStatesData: [],
      disCitiesData: [],
      defaultadd: true,
      defaultdisadd: true,
      dnrequired: false,
      fifoprice: "",
      lifoprice: "",
      avgprice: "",
      insurancereq: false,
      insurance: 0,
      tcsableamount: 0,
      insuranceamount: 0,
      insurancetax: 0,
      einvoice: false,
      additionalchargeslist: [],
      additionalcharges: 0,
      additionalchargestax: 0,
      totaladditionalcharges: 0,
      additionalchargestaxamount: 0,
      natureofcharge: "",
      invoiceadditionalcharges: 0,
      invoiceadditionalchargestax: 0,
      totalinsuranceamount: 0,
      samedaydelivery: einvoice ? false : true,
      totalinvoice: 0,
      addtype: "Dispatch details",
      errormsg: [],
      transportername: null,
      deliverymethods: [
        { value: "1", name: "Own Vehicle" },
        { value: "2", name: "Hired Vehicle" },
        { value: "3", name: "GTA" },
        { value: "4", name: "Others" },
      ],
      transmode: 1,
      vehicletypes: [
        { value: "O", name: "ODC" },
        { value: "R", name: "Regular" },
      ],
      vehicletype: "R",
      supplytype: "O",
      transportationmode: 1,
      transactiontype: 1,
      dlryaccordian: true,
      addchraccordian: true,
      itemstock: 0,
      iscomposite: false,
      transporterslist: [],
      transporterselectiontype: false,
      validtrnsid: einvoice ? false : true,

      WarehouseList: [],
      DepartmentList:[],
      CategoryList: [],
      ServiceCategoryList: [],
      itemList: [],
      filteritemlist: [],
      sizelist: [],
      whdata: [],
      warehouse: "",
      whid: "",
      department:"",
      deptid:"",
      itemname: "",
      hsnid: "",
      hsn: "",
      price: "",
      type: "",
      uom: "",
      category: "",
      categoryid: "",
      uomid: "",
      description: "",
      brand: "",
      model: "",
      size: "",
      colour: "",
      thickness: "",
      totalmtrroll: "",
      settings: "",
      discount: "",
      discountamount: "",
      pricevariance: 0,
      taxtype: "",
      gsttype: "",
      taxrequired: true,
      convertionreq: false,
      basecurrency: "",
      tax: 0,
      sgst: 0,
      cgst: 0,
      serviceitemList: [],
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicecostconv: 0,
      servicecgst: "",
      servicesgst: "",
      serviceigst: "",
      servicegst: "",
      servicevat: "",
      servicetaxamount: "",
      servicecostwttaxconv: 0,
      servicecostwttax: "",
      servicedescription: "",
      servicetotal: "",
      serviceerr: "",
      grandservicetotal: 0,
      servicedata: [],
      transidreq: false,
      autojre:false,
      valueroundoff:0,
      markup1:0,
      markup:0,
      invoiceremarks:"",
      customcreatedAt:"",
      SDdate:false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleServicecategory = (value, values) => {
    if (values !== "" && values !== undefined && typeof values === "object") {
      this.setState({
        servicecatid: values.id,
        servicecategory: values.category,
        filterserv: this.state.serviceitemList.filter(
          (e) => e.catid === values.id
        ),
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    } else {
      this.setState({
        servicecatid: "",
        servicecategory: "",
        filterserv: [],
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    }
  };

  handleService = (value, values) => {
    if (values !== "" && values !== undefined && typeof values === "object") {
      this.setState({
        serviceid: values.id,
        service: values.service,
        servicetax: this.state.taxrequired === true ? values.servicetax : 0,
        servicecode: values.servicecode,
        servicedescription: values.description,
        servicecatid:values.servicecatid,
        servicecategory:values.servicecategory,
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    } else {
      this.setState({
        serviceid: "",
        service: "",
        servicetax: 0,
        servicecode: "",
        servicedescription: "",
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        servicecatid:"",
        servicecategory:"",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    }
  };

  handleServiceAmountChange = (e) => {
    this.setState({
      servicecost: e.target.value,
      servicecostconv:
        parseFloat(e.target.value) * this.state.curconversionrate,
      servicetaxamount:
        (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecgst:
        ((this.state.servicetax / 100) * parseFloat(e.target.value)) / 2,
      servicesgst:
        ((this.state.servicetax / 100) * parseFloat(e.target.value)) / 2,
      serviceigst: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicegst: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicevat: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecostwttax:
        parseFloat(e.target.value) +
        (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecostwttaxconv:
        (parseFloat(e.target.value) +
          (this.state.servicetax / 100) * parseFloat(e.target.value)) *
        this.state.curconversionrate,
    });
  };

  handleaddService = () => {
    let servicedata = {
      serviceid: this.state.serviceid,
      service: this.state.service,
      servicedescription: this.state.servicedescription,
      servicetax: this.state.servicetax,
      servicecost: this.state.servicecost,
      servicecode: this.state.servicecode,
      servicetotal: this.state.servicecostwttax,
      servicecompleted: 0,
      recurrencetill: this.state.recurrencetill,
    };
    this.state.serviceList.push(servicedata);

    this.setState({
      serviceList: [...this.state.serviceList],
      grandservicetotal: this.state.servicecostwttax,
    });
  };

  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      SalesServices.GetCustomerTerms(values.id).then((res) =>
        SalesServices.IsPaymentPending(values.id).then((response) => {
          if (
            response.data &&
            res.data !== null &&
            res.data.creditstatus === "Required"
          ) {
            Swal.fire({
              title:'Pending payment from customer!',
              text: "Payment is due from the customer, please settle it before continuing",
              icon:"warning",
              confirmButtonColor: "#152f5f",
            }).then(()=>
            this.setState({
              custid: "",
              customer: "",
              saleorderid: "",
              customerdetails: "",
              saleorderdata: [],
              accepteditems: [],
              tcsableamount: 0,
              totaladditionalcharges: 0,
              invoiceadditionalcharges: 0,
              invoiceadditionalchargestax: 0,
            }))
            
          }else{
            this.setState({
              custid: values.id,
              customer: values.companyname,
              debitamount: values.debitamount,
              openingbalance: values.openingbalance,
              adjustopen: false,
              adjustdebit: values.debitamount > 0 ? true : false,
              einvoice:
                this.state.einvoice && values.countryname === "India"
                  ? true
                  : false,
              saleorderid: "",
              tcsableamount: 0,
              totaladditionalcharges: 0,
              invoiceadditionalcharges: 0,
              invoiceadditionalchargestax: 0,

              name: values.companyname,
              phoneno: values.contactnumber,
              pincode: values.zip,
              state: values.statename,
              city: values.cityname,
              houseno: values.address,
              areaname: values.address2,
              country: values.countryname,
              statecode: values.statecode,
              currency: values.currency,
              currencyid: this.state.currencylist.find(
                (e) => e.currency === values.currency
              ).id,
              taxtype: values.taxtype,
              gsttype:
                values.statename === cstate && values.countryname === "India"
                  ? "INTRASTATE"
                  : "INTERSTATE",
              convertionreq:
                values.currency === this.state.basecurrency ? false : true,
            });

            const id = values.id;
            datalist = [];

            let selectedcustomer = this.state.custom.filter(
              (customer) => customer.id === id
            );
            this.setState({ customerdetails: selectedcustomer });

            let datadao = {
              tenantid: currentTenant,
              startdate: financialyearstart,
              enddate: financialyearend,
              userid: userid,
              dataaccess: dataaccess,
              whid: userwhid,
              id: id,
              poupload: this.state.pouploadm === "YES" ? true : false,
            };

            if (this.state.pagename === "New Proforma Invoice") {
              // (this.state.pouploadm === "YES"
              //   ? SalesServices.GetSObycustidforProformaInvoice(id)
              SalesServices.GetSObycustidforProformaInvoice(datadao)
                .then((res) =>
                  this.setState({
                    saleorderdata: res.data,
                    accepteditems: [],
                  })
                )
                .catch((e) => {
                  if (e.response.data.error === "Unauthorized") {
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                  }
                });
            } else {
              if(this.state.settings.salesautojre === "YES"){
                if(values.ledgerid=== null || values.ledgerid === "" || values.ledgerid === undefined){
              Swal.fire({
                title:'',
                text: "As ledger is not created for the customer journal entry cannot be auto inserted, please do the manual entry.",
                icon:"warning",
                confirmButtonColor: "#152f5f",
              }).then(()=>this.setState({autojre:false}))

              }else{
                this.setState({autojre:true})
              }
            }else{
              this.setState({autojre:false})
            }
              // (this.state.pouploadm === "YES"
              //   ? SalesServices.GetSoByCustidForInvoice(id)
              //   :
              SalesServices.getSoByCustidForInvoice(datadao)
                .then((res) =>
                  this.setState({
                    saleorderdata: res.data,
                  })
                )
                .catch((e) => {
                  if (e.response.data.error === "Unauthorized") {
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                  }
                });

              MasterService.getDistance(this.state.dispincode, values.zip).then(
                (res) => this.setState({ distance: res.data === ''||res.data === null || res.data===undefined?0:res.data })
              );
            }
          }
        })
      );
    } else {
      this.setState({
        custid: "",
        customer: "",
        saleorderid: "",
        customerdetails: "",
        saleorderdata: [],
        accepteditems: [],
        tcsableamount: 0,
        totaladditionalcharges: 0,
        invoiceadditionalcharges: 0,
        invoiceadditionalchargestax: 0,
      });
    }
  };

  handleWarehouse = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        warehouse: values.name,
        whid: values.id,
        deptid:"",
        department:"",
        itemname: "",
        hsnid: "",
        hsn: "",
        quantity: "",
        price: "",
        type: "",
        uom: "",
        uomid: "",
        description: "",
      });
      DepartmentServices.deptbywarehouse(values.id).then((response) =>{
        this.setState({
          DepartmentList: response.data
        })
        if(response.data.length ===1){
          this.setState({
            department: response.data[0].name,
            deptid: response.data[0].id
          })
        }
    });
    } else {
      this.setState({ warehouse: "", whid: "" });
    }
  };

  handleDepartment = (value,values) => {
    if (values !== "" && typeof values === 'object' && values !==null) {
      this.setState({
        department: values.name,
        deptid: values.id
      })
    }else{
      this.setState({department:'',deptid:''})
    }

  }

  handleCategory = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        categoryid: values.id,
        category: values.category,
        filteritemlist: this.state.itemList.filter(
          (e) => e.catid === values.id
        ),
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        price: "",
        quantity: "",
      });
    } else {
      this.setState({
        categoryid: "",
        category: "",
        filteritemlist: [],
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        price: "",
        quantity: "",
      });
    }
  };

  handleitem = (value, values) => {
    if (this.state.whid === "" || this.state.warehouse === "") {
      alert("Please Select Org Unit");
    } else {
      if (values !== "" && typeof values === "object" && values !== null) {
        this.setState({
          itemid: values.itemid,
          itemname: values.name,
          quantity: "",
          totalpricewtax: "",
        });
        // if (this.state.creditstatus !== "NotRequired") {
        //   if (this.state.availabelcredit < 0) {
        //     this.setState({ availabelcredit: this.state.creditvalue });
        //   }
        // }
        const id = values.itemid;
        // SalesServices.getprice(id).then((res) =>
        let itemdata = this.state.itemList.filter((e) => e.itemid === id);
        itemdata.map((data) =>
          this.setState({
            type:
              this.state.salescalreq === "YES"
                ? "LIFO"
                : data.type === null || data.type === undefined
                ? "LIFO"
                : data.type,
            price: this.state.salescalreq === "YES" ? "" : data.price,
            markup1:data.markup1,
            markup:data.markup,
            hsn: data.hsn,
            uom: data.uom,
            uomid: data.uomid,
            brand: data.brand,
            model: data.model,
            tax: this.state.taxrequired === true ? data.tax : 0,
            cgst: this.state.taxrequired === true ? data.tax / 2 : 0,
            sgst: this.state.taxrequired === true ? data.tax / 2 : 0,
            additionalparams: data.additionalparams,
            description: data.description,
          })
        );
        let warehousedata = this.state.whdata.filter(
          (e) => e.whid === this.state.whid
        );
        let saleslist = warehousedata.find((e) => e).saleslist;
        let a = saleslist.filter((e) => e.itemid === id);
        // let stock = a.map(e => e.currentstock)[0] === undefined ? 0 : a.map(e => e.currentstock)
        let stock = a.reduce(
          (total, currentValue) => (total = total + currentValue.currentstock),
          0
        );
        this.setState({ stock: stock });
      } else {
        this.setState({ itemname: "", itemid: "" });
      }
    }
  };

  handleTypeChange = (e) => {
    this.setState({ type: e.target.value });
  };

  handlemarkup1Change = (e)=>{
    this.setState({
      markup1: e.target.value,
    })
  }


  handleSize = (value, values) => {
    if (
      value !== "" &&
      this.state.sizelist.includes(values) &&
      values !== null
    ) {
      this.setState({ size: values });
    } else {
      this.setState({ size: "" });
    }
  };

  handleColour = (value, values) => {
    if (
      values !== "" &&
      this.state.colourlist.includes(values) &&
      values !== null
    ) {
      this.setState({ colour: values });
    } else {
      this.setState({ colour: "" });
    }
  };

  handlemilestonestatus = (i) => {
    this.state.milestones.find((e, index) => index === i).servicestatus =
      "Completed";
    this.setState({
      completed: this.state.milestones.find((e, index) => index === i),
    });
  };

  handleSaleOrder = (value, values) => {
    if (
      values !== "" &&
      this.state.saleorderdata.includes(values) &&
      values !== null
    ) {
      this.setState({
        saleorderid: values,
        tcsableamount: 0,
        totaladditionalcharges: 0,
        invoiceadditionalcharges: 0,
        invoiceadditionalchargestax: 0,
      });
      const id = values;
      datalist = [];

      SalesServices.GetDataforInvoice(id).then((response) =>
        this.setState({
          sodata: response.data,
          agent: response.data.agent,
          agentid: response.data.agentid,
          whid: response.data.whid,
          deptid:response.data.deptid,
          currency: response.data.currency,
          curconversionrate: response.data.curconversionrate,
          gsttype: response.data.gsttype,
          taxtype: response.data.taxtype,
          // saleorderitems: response.data.saleorderitems,
          serviceList: response.data.servicelist,
          saleorderitems: response.data.saleorderitems,
          serviceid:
            response.data.sotype === 1
              ? response.data.servicelist.find((e) => e).serviceid
              : "",
          milestones:
            response.data.sotype === 1
              ? response.data.servicelist.find((e) => e).milestones
              : [],
          contracttype:
            response.data.sotype === 1
              ? response.data.servicelist.find((e) => e).contracttype
              : "",
          totaltaxamount: response.data.totaltaxamount,
          totaliv: response.data.totaliv,
          grandtotal: response.data.grandtotalproducttotal,
          grandservicetotal: response.data.grandservicetotal,
          sotype: response.data.sotype,
          creditperiod:
            response.data.creditperiod === null ||
            response.data.creditperiod === undefined
              ? 0
              : response.data.creditperiod,
          accepteditems:
            this.state.pagename === "New Proforma Invoice" ||
            response.data.sotype === 2
              ? response.data.saleorderitems
              : [],
          completed:
            response.data.sotype === 1 &&
            this.state.pagename === "New Proforma Invoice"
              ? response.data.servicelist.find((e) => e).milestones[0]
              : "",
          addservice: response.data.sotype === 2 ? true : false,
          pricevariance: response.data.pricevariance,
        })
      );
    } else {
      this.setState({
        saleorderid: "",
        DnIDList: [],
        accepteditems: [],
        sodata: "",
        serviceList: [],
        saleorderitems: [],
        serviceid: "",
        milestones: [],
        contracttype: "",
        totaltaxamount: 0,
        totaliv: 0,
        grandtotal: 0,
        grandservicetotal: 0,
        sotype: 0,
        tcsableamount: 0,
        totaladditionalcharges: 0,
        invoiceadditionalcharges: 0,
        invoiceadditionalchargestax: 0,
      });
    }
  };

  TransporteridChange = (e) => {
    this.setState({ transporterid: e.target.value?.trim().toUpperCase() });
    if (this.state.einvoice) {
      if (e.target.value.length === 15) {
        let data = {
          tenantId: currentTenant,
          reqparam: e.target.value.trim().toUpperCase(),
        };
        SalesServices.validateGSTIN(data)
          .then((response) => {
            if (
              response.data.body === null ||
              response.data.body.statusCodeValue === 400
            ) {
              this.setState({ err: true, validtrnsid: false });
              Swal.fire({
                title: `Invalid Transporter ID`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
            } else {
              this.setState({ validtrnsid: true, err: false });
            }
          })
          .catch(() => this.setState({ err: true, validtrnsid: false }));
      } else {
        this.setState({
          err: false,
          validtrnsid: false,
        });
      }
    } else {
      this.setState({
        transporterid: e.target.value,
        err: false,
        validtrnsid: true,
      });
    }
  };

  handleinsurancereq = (e) => {
    this.setState({ insurancereq: !this.state.insurancereq });
  };

  handleInsuranceChange = (e) => {
    this.setState({
      insurance: e.target.value === "" ? 0 : parseFloat(e.target.value),
      insuranceamount:
        ((this.state.totalinvoice * parseFloat(e.target.value)) / 100).toFixed(
          3
        ) === "NaN"
          ? 0
          : (
              (this.state.totalinvoice * parseFloat(e.target.value)) /
              100
            ).toFixed(2),
      totalinsuranceamount:
        e.target.value === ""
          ? 0
          : (
              parseFloat(
                (this.state.totalinvoice * parseFloat(e.target.value)) / 100
              ) +
              (parseFloat(
                (this.state.totalinvoice * parseFloat(e.target.value)) / 100
              ) *
                parseFloat(this.state.insurancetax)) /
                100
            ).toFixed(2),
    });
  };

  handleInsuranceAmount = (e) => {
    this.setState({
      insuranceamount: e.target.value === "" ? 0 : parseFloat(e.target.value),
      insurance:
        e.target.value === ""
          ? 0
          : (parseFloat(e.target.value) / this.state.totalinvoice) * 100,
      totalinsuranceamount:
        e.target.value === ""
          ? 0
          : (
              parseFloat(e.target.value) +
              (parseFloat(e.target.value) *
                parseFloat(this.state.insurancetax)) /
                100
            ).toFixed(3),
    });
  };

  handleInsuranceTax = (e) => {
    const iamt = parseFloat(this.state.insuranceamount)
    const currval = parseFloat(e.target.value)
    const taxindec = ((currval/100) * iamt).toFixed(2)
    console.log(iamt,taxindec)
    this.setState({
      insurancetax: parseFloat(e.target.value),
      totalinsuranceamount: (parseFloat(iamt) + parseFloat(taxindec)),
    });
  };

  handleDefaultadd = () => {
    let data = this.state.custom.find((e) => e.id === this.state.custid);
    this.setState(
      {
        defaultadd: !this.state.defaultadd,
      },
      () => {
        if (this.state.defaultadd === true) {
          this.setState({
            name: data.companyname,
            phoneno: data.contactnumber,
            pincode: data.zip,
            state: data.statename,
            city: data.cityname,
            houseno: data.address,
            areaname: data.address2,
            country: data.countryname,
            statecode: data.statecode,
          });
        } else {
          this.setState({
            name: "",
            phoneno: "",
            pincode: "",
            state: "",
            city: "",
            houseno: "",
            areaname: "",
            country: "",
          });
        }
      }
    );
  };

  handleDefaultdisadd = () => {
    this.setState(
      {
        defaultdisadd: !this.state.defaultdisadd,
      },
      () => {
        if (this.state.defaultdisadd === true) {
          this.setState({
            disname: clientname,
            disphoneno: contactnumber,
            dispincode: czip,
            disstate: cstate,
            disstatecode: cstatecode,
            discity: ccity,
            dishouseno: address,
            disareaname: address2,
            discountry: ccountry,
          });
        } else {
          this.setState({
            disname: "",
            disphoneno: "",
            dispincode: "",
            disstate: "",
            discity: "",
            dishouseno: "",
            disareaname: "",
            discountry: "",
            disstatecode: "",
          });
        }
      }
    );
  };
  handleDNreq = () => {
    this.setState({
      dnrequired: !this.state.dnrequired,
    });
  };

  handleCountryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        country: newValue.name,
        StatesData: newValue.states,
        err: false,
      });
    }
  };

  handleStateChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        state: newValue.name,
        statecode: newValue.code,
        CitiesData: newValue.cities,
        err: false,
      });
    }
  };
  handleCityChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({ city: newValue.name, err: false });
    }
  };

  handleDisCountryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        discountry: newValue.name,
        disStatesData: newValue.states,
        err: false,
      });
    }
  };

  handleDisStateChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        disstate: newValue.name,
        disstatecode: newValue.code,
        disCitiesData: newValue.cities,
        err: false,
      });
    }
  };
  handleDisCityChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({ discity: newValue.name, err: false });
    }
  };

  handletcsreq = (e) => {
    this.setState({ tcsreq: !this.state.tcsreq });
  };
  handletcsChange = (e) => {
    const dtcs = this.state.tcsableamount.toFixed(2)
    const tcsamt = ((parseFloat(e.target.value)/100)*parseFloat(dtcs)).toFixed(2)
    console.log(parseFloat(e.target.value)/100,dtcs,tcsamt)
    this.setState({
      tcs: parseFloat(e.target.value),
      tcsamount:  parseFloat(tcsamt),
    });
  };

  handledownload = (file) => {
    downloadfiles.download(this.state.sodata.popath, this.state.sodata.pofile);
  };

  handleAccept = (id) => {
    
    let itemsdetails = this.state.saleorderitems.filter(
      (data) => data.id === id
    );
    this.setState({ quantity: "", description: "", show: 1 });
    itemsdetails.map((data) =>
      this.setState(
        {
          id: data.id,
          itemid: data.itemid,
          itemname: data.name,
          hsn: data.hsn,
          price: data.price,
          salesenum: data.salesenum,
          tax: data.tax,
          categoryid: data.categoryid,
          taxamount: data.taxamount,
          markup:data.markup,
          markup1:data.markup1,
          orderedquantity: parseFloat(data.quantity),
          pendingquantity: parseFloat(data.pending),
          uom: data.uom,
          totalprice: data.totalprice,
          brand: data.brand,
          colour: data.colour,
          totalpricewtax: data.totalpricewtax,
          size: data.size,
          thickness: data.thickness,
          totalmtrroll: data.totalmtrroll,
          description: data.description,
          insurance: data.insurance,
          discount: data.discount,
          discountamount: data.discountamount,
          pricevariance: data.pricevariance,
          fifoprice: data.fifoprice,
          lifoprice: data.lifoprice,
          avgprice: data.avgprice,
          additionalchargestax: 0,
          additionalcharges: 0,
          totaladditionalcharges: 0,
          additionalchargestaxamount: 0,
          natureofcharge: "",
        },
        () =>
          this.setState({
            itemstock: this.state.whdata
              ?.find((e) => e.whid === this.state.sodata.whid)
              ?.saleslist?.find((e) => e.itemid === this.state.itemid)
              ?.totalstock,
            itemquantitylist: this.state.whdata
              .find((e) => e.whid === this.state.sodata.whid)
              .saleslist.filter(
                (sl) => sl.itemid === this.state.itemid && sl.currentstock !== 0
              )
              .map((e) => Object.assign(e, { enteredquantity: 0 })),
          })
      )
    );
  };

  AdditionalCharges = () => {
    if (
      this.state.additionalcharges === "" ||
      this.state.natureofcharge === ""
    ) {
      this.setState({ adderr: true });
    } else {
      let data = this.state.additionalchargeslist;
      data.push({
        additionalcharges: this.state.additionalcharges,
        additionalchargestax: this.state.additionalchargestax,
        additionalchargestaxamount: this.state.additionalchargestaxamount,
        totaladditionalcharges: parseFloat(this.state.totaladditionalcharges),
        natureofcharge: this.state.natureofcharge,
      });
      this.setState({
        additionalchargeslist: data,
        invoiceadditionalcharges:
          this.state.invoiceadditionalcharges +
          parseFloat(this.state.totaladditionalcharges),
        invoiceadditionalchargestax:
          this.state.invoiceadditionalchargestax +
          parseFloat(this.state.additionalchargestaxamount),
        additionalcharges: 0,
        additionalchargestax: 0,
        additionalchargestaxamount: 0,
        totaladditionalcharges: 0,
        natureofcharge: "",
      });

      if(this.state.autojre){
        Swal.fire({
          title:'',
          text: "As you are adding additonal charges Auto journal entry is not possible, please do the manual entry.",
          icon:"warning",
          confirmButtonColor: "#152f5f",
        })
      }

    }
  };

  RemoveAdditionalCharges = (i) => {
    let data = this.state.additionalchargeslist.filter((e, idx) => idx !== i);
    let data2 = this.state.additionalchargeslist.find((e, idx) => idx === i);
    this.setState({
      additionalchargeslist: data,
      invoiceadditionalcharges:
        this.state.invoiceadditionalcharges - data2.totaladditionalcharges,
      invoiceadditionalchargestax:
        this.state.invoiceadditionalchargestax -
        data2.additionalchargestaxamount,
      additionalcharges: 0,
      additionalchargestax: 0,
      additionalchargestaxamount: 0,
      totaladditionalcharges: 0,
      natureofcharge: "",
    });
  };

  handleTransporterChange = (value, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      this.setState({
        transportername: "",
        transporterid: "",
        validtrnsid: false,
      });
    } else {
      this.setState({
        transportername: newValue.transname,
        transporterid: newValue.transid,
        validtrnsid: true,
      });
    }
  };

  onSubmit() {
    if (this.state.quantity === "0") {
    } else {
      if (this.state.invtype === "Order Invoice") {
        let disc = (this.state.price * this.state.discount) / 100;
        let halftaxroundoff = ((parseFloat(this.state.tax/2) * ((this.state.price + parseFloat(this.state.markup1===null||this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1) - disc) * parseFloat(this.state.quantity))) / 100).toFixed(2)
          let taxroundoff = this.state.taxtype === "GST" && ccountry === "India" && this.state.gsttype === "INTRASTATE"? (parseFloat(halftaxroundoff) + parseFloat(halftaxroundoff)) :
           ((parseFloat(this.state.tax) * ((this.state.price + parseFloat(this.state.markup1===null || this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1) - disc) * parseFloat(this.state.quantity))) / 100).toFixed(2)
          let totalpriceroundoff =  ((this.state.price + parseFloat(this.state.markup1===null || this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1) - disc) * parseFloat(this.state.quantity)).toFixed(2)
        console.log(halftaxroundoff , taxroundoff)
          this.setState({
          totalinvoice:
            this.state.totalinvoice + parseFloat(totalpriceroundoff),
          invtotalamountwtax:
            parseFloat(this.state.invtotalamountwtax) +
            parseFloat(taxroundoff)+
            parseFloat(totalpriceroundoff) +
            parseFloat(this.state.totaladditionalcharges),
          tcsableamount:
            parseFloat(this.state.invtotalamountwtax) +
            parseFloat(taxroundoff)+
            parseFloat(totalpriceroundoff) +
            parseFloat(this.state.totaladditionalcharges),
        });

        let list = {
          id: this.state.id,
          soitemid: this.state.id,
          itemid: this.state.itemid,
          name: this.state.itemname,
          hsn: this.state.hsn,
          categoryid: this.state.categoryid,
          quantity: parseFloat(this.state.quantity),
          price: this.state.price,
          markup:this.state.markup,
          markup1:this.state.markup1===undefined||this.state.markup1===""||this.state.markup1===null?0:this.state.markup1,
          salesenum: this.state.salesenum,
          tax: this.state.tax,
          discount: this.state.discount,
          discountamount: disc * parseFloat(this.state.quantity),
          taxamount: parseFloat(taxroundoff),
          description: this.state.description,
          totalprice:parseFloat(totalpriceroundoff),
          pending: this.state.pendingquantity - this.state.quantity,
          uom: this.state.uom,
          brand: this.state.brand,
          colour: this.state.colour,
          totalpricewtax: parseFloat(taxroundoff)+ parseFloat(totalpriceroundoff) + parseFloat(this.state.totaladditionalcharges),
          size: this.state.size,
          thickness: this.state.thickness,
          totalmtrroll: this.state.totalmtrroll,
          // insurance: this.state.insurance,
          // insuranceamount:this.state.insuranceamount,
          pricevariance: this.state.pricevariance,
          fifoprice: this.state.fifoprice,
          lifoprice: this.state.lifoprice,
          avgprice: this.state.avgprice,
          additionalchargestax: this.state.additionalchargestax,
          additionalcharges: this.state.additionalcharges,
          totaladditionalcharges: this.state.totaladditionalcharges,
          additionalchargestaxamount: this.state.additionalchargestaxamount,
          natureofcharge: this.state.natureofcharge,
        };

        datalist.push(list);
        let checklist  = datalist.filter(e=>e.categoryid !== this.state.categoryid)
            if(checklist.length>0 && this.state.autojre){
              Swal.fire({
                title:'',
                text: "As different ledger are added journal entry cannot be auto inserted, please do the manual entry.",
                icon:"warning",
                confirmButtonColor: "#152f5f",
              })
            }

        if (this.state.pendingquantity === 0) {
          this.setState({
            saleorderitems: this.state.saleorderitems.filter(
              (data) => data.id !== this.state.id
            ),
          });
        } else {
          this.state.saleorderitems.find(
            (e) => e.id === this.state.id
          ).pending =
            this.state.saleorderitems.find((e) => e.id === this.state.id)
              .pending - this.state.quantity;
        }

        let data = this.state.itemquantitylist;
        let am = data.filter((a) => a.enteredquantity !== 0);
        am.map(
          (a) =>
            (this.state.whdata
              .find((e) => e.whid === this.state.sodata.whid)
              .saleslist.find((sl) => sl.batchid === a.batchid).currentstock =
              this.state.whdata
                .find((e) => e.whid === this.state.sodata.whid)
                .saleslist.find((sl) => sl.batchid === a.batchid).currentstock -
              a.enteredquantity)
        );
        this.setState({
          accepteditems: datalist,
          quantity: "",
          description: "",
          show: 0,
          additionalchargestax: 0,
          additionalcharges: 0,
          totaladditionalcharges: 0,
          natureofcharge: "",
          autojre:this.state.autojre && checklist.length===0?true:false,
        });
      } else {

        let price =
          (parseFloat(this.state.price)+parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) +
          ((parseFloat(this.state.price)+parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) *
            parseFloat(
              this.state.pricevariance === undefined
                ? 0
                : this.state.pricevariance
            )) /
            100;
        let d =
          (parseFloat(price) *
            parseFloat(
              this.state.discount === undefined || this.state.discount === ""
                ? 0
                : this.state.discount
            )) /
          100;
        let disc =
          ((parseFloat(this.state.price)+parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) *
            parseFloat(
              this.state.discount === undefined || this.state.discount === ""
                ? 0
                : this.state.discount
            )) /
          100;

          let halftaxroundoff = ((parseFloat(this.state.tax/2) * ((price - d) * parseFloat(this.state.quantity))) / 100).toFixed(2)
          let taxroundoff = this.state.taxtype === "GST" && ccountry === "India" && this.state.gsttype === "INTRASTATE"? (parseFloat(halftaxroundoff) + parseFloat(halftaxroundoff)) :
           ((parseFloat(this.state.tax) * ((price - d) * parseFloat(this.state.quantity))) / 100).toFixed(2)
          let totalpriceroundoff =  ((price - d) * parseFloat(this.state.quantity)).toFixed(2)
        this.setState({
          discountamount: d * this.state.quantity,
          totalpricewtax:
          parseFloat(totalpriceroundoff) + parseFloat(taxroundoff),
          taxamount:parseFloat(taxroundoff),
          totalprice: parseFloat(totalpriceroundoff),
          totalinvoice: this.state.totalinvoice + totalpriceroundoff,
          invtotalamountwtax:
            parseFloat(this.state.invtotalamountwtax) +
            parseFloat(totalpriceroundoff) + parseFloat(taxroundoff) +
            parseFloat(this.state.totaladditionalcharges),
          tcsableamount:
          parseFloat(this.state.invtotalamountwtax) +
          parseFloat(totalpriceroundoff) + parseFloat(taxroundoff) +
          parseFloat(this.state.totaladditionalcharges),
        });

        this.setState(
          {
            totaliv:
              parseFloat(this.state.totaliv) +
              parseFloat(totalpriceroundoff),
            grandtotal: this.state.grandtotal + parseFloat(totalpriceroundoff) +parseFloat(taxroundoff),
            totaltaxamount:
              this.state.totaltaxamount + parseFloat(taxroundoff),
            totaldiscount: this.state.totaldiscount + d * this.state.quantity,
          },
          () => {
            let list = {
              id: this.state.id,
              soitemid: this.state.id,
              itemid: this.state.itemid,
              name: this.state.itemname,
              hsn: this.state.hsn,
              categoryid: this.state.categoryid,
              quantity: parseFloat(this.state.quantity),
              price: (parseFloat(this.state.price)+parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)),
              markup1:this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1,
              markup:this.state.markup,
              salesenum: this.state.type,
              tax: this.state.tax,
              discount:
                this.state.discount === undefined || this.state.discount === ""
                  ? 0
                  : parseFloat(this.state.discount),
              discountamount:
                (((parseFloat(this.state.price)+parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) *
                  parseFloat(
                    this.state.discount === undefined ||
                      this.state.discount === ""
                      ? 0
                      : this.state.discount
                  )) /
                  100) *
                parseFloat(this.state.quantity),
              taxamount: parseFloat(taxroundoff),
              description: this.state.description,
              totalprice: parseFloat(totalpriceroundoff),
              pending: 0,
              uom: this.state.uom,
              brand: this.state.brand,
              colour: this.state.colour,
              totalpricewtax: parseFloat(totalpriceroundoff) + parseFloat(taxroundoff)+ parseFloat(this.state.totaladditionalcharges),
              size: this.state.size,
              thickness: this.state.thickness,
              totalmtrroll: this.state.totalmtrroll,
              // insurance: this.state.insurance,
              // insuranceamount:this.state.insuranceamount,
              pricevariance: this.state.pricevariance,
              fifoprice: this.state.fifoprice,
              lifoprice: this.state.lifoprice,
              avgprice: this.state.avgprice,
              additionalchargestax: this.state.additionalchargestax,
              additionalcharges: this.state.additionalcharges,
              totaladditionalcharges: this.state.totaladditionalcharges,
              additionalchargestaxamount: this.state.additionalchargestaxamount,
              natureofcharge: this.state.natureofcharge,
            };

            datalist.push(list);
            let checklist  = datalist.filter(e=>e.categoryid !== this.state.categoryid)
            if(this.state.autojre && checklist.length>0){
              Swal.fire({
                title:'',
                text: "As different ledger are added journal entry cannot be auto inserted, please do the manual entry.",
                icon:"warning",
                confirmButtonColor: "#152f5f",
              })
            }
            this.setState({
              accepteditems: datalist,
              autojre:this.state.autojre && checklist.length===0?true:false
            });

            if (
              datalist.length === 1 &&
              this.props.location.state.invid !== null &&
              this.props.location.state.invid !== undefined &&
              this.props.location.state.invid !== ""
            ) {
              MasterService.getDistance(this.state.dispincode, values.zip).then(
                (res) =>
                  this.setState({ distance: res.data === null ? 0 : res.data })
              );
              let data = this.state.custom.find(
                (e) => e.id === this.state.custid
              );
              this.setState({
                name: data.companyname,
                phoneno: data.contactnumber,
                pincode: data.zip,
                state: data.statename,
                city: data.cityname,
                houseno: data.address,
                areaname: data.address2,
                country: data.countryname,
                statecode: data.statecode,
              });
            }



            this.setState({
              quantity: "",
              totalpricewtax: "",
            });
          }
        );
      }
    }
  }

  removeItemFromList = (index) => {
    let removed = this.state.accepteditems.filter((item, i) => i === index);
    datalist = this.state.accepteditems.filter((item, i) => i !== index);
    this.setState({
      accepteditems: this.state.accepteditems.filter((item, i) => i !== index),
      totaliv: this.state.totaliv - removed.totalprice,
      grandtotal: this.state.grandtotal - removed.totalpricewtax,
      totaltaxamount: this.state.totaltaxamount - removed.taxamount,
      totaldiscount: this.state.totaldiscount + removed.discountamount,
    });
  };

  handlesubmit = (values) => {
    if (
      (this.state.name === "" ||
        this.state.phoneno === "" ||
        this.state.pincode === "" ||
        this.state.pincode === undefined ||
        this.state.pincode === null ||
        this.state.state === "" ||
        this.state.city === "" ||
        this.state.houseno === "" ||
        this.state.houseno.length>100 ||
        this.state.areaname === "" ||
        this.state.areaname.length>100 ||
        this.state.country === "" ||
        this.state.disname === "" ||
        this.state.disphoneno === "" ||
        this.state.dispincode === "" ||
        this.state.dispincode === undefined ||
        this.state.dispincode === null ||
        this.state.disstate === "" ||
        this.state.discity === "" ||
        this.state.dishouseno === "" ||
        this.state.dishouseno.length>100 ||
        this.state.disareaname === "" ||
        this.state.disareaname.length>100 ||
        this.state.discountry === "" ||
        ((this.state.vehiclenum === "" ||
          this.state.distance === "" ||
          this.state.distance === null ||
          this.state.distance === undefined) &&
          // ||
          // this.state.transporterid === ""|| this.state.validtrnsid === false
          this.state.samedaydelivery)) &&
      this.state.pagename === "New Invoice"
    ) {
      this.setState({ err: true });
    } else {
      Swal.fire({
        title:
          this.state.pagename === "New Proforma Invoice"
            ? "Save & send Proforma Invoice for approvel?"
            : this.state.samedaydelivery && this.state.einvoice
            ? "Save & send Invoice for approvel along with Eway-bill details?"
            : "Save & send Invoice for approvel?",
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.setState({ loader: true });
          let servicedata = [];
          if (this.state.invtype === "Direct Invoice") {
            if (this.state.sotype === 1) {
              servicedata = [
                {
                  serviceid: this.state.serviceid,
                  service: this.state.service,
                  servicedescription: this.state.servicedescription,
                  servicetax: this.state.servicetax,
                  servicecost: this.state.servicecost,
                  servicecode: this.state.servicecode,
                  servicetotal: this.state.servicecostwttax,
                  servicecatid:this.state.servicecatid,
                  servicecategory:this.state.servicecategory,
                },
              ];
            }
            if (this.state.sotype === 2 && this.state.serviceList.length > 0) {
              this.state.serviceList.map((s) =>
                servicedata.push({
                  serviceid: s.serviceid,
                  service: s.service,
                  servicedescription: s.servicedescription,
                  servicetax: s.servicetax,
                  servicecost: parseFloat(s.servicecost),
                  servicecode: s.servicecode,
                  servicetotal: parseFloat(s.servicetotal),
                  servicecatid:s.servicecatid,
                  servicecategory:s.servicecategory,
                })
              );
            }
          }
          let data = {
            saleorderid: this.state.saleorderid,
            // dnid:this.state.dnid,
            whid: this.state.whid,
            deptid:this.state.deptid,
            custid: this.state.custid,
            customer: this.state.customer,
            agent:
              this.state.agentid === "" || this.state.agentid === undefined
                ? null
                : this.state.agentid,
            invid: this.state.invid,
            invtype: this.state.invtype,
            itemlist: this.state.accepteditems,
            milestone:
              this.state.completed === "" ? null : this.state.completed,
            serviceid: this.state.serviceid,
            createdby: userid,
            poid: this.state.poid,
            addservice: this.state.addservice
              ? true
              : this.state.sotype === 2 && servicedata.length > 0
              ? true
              : false,
            creditperiod: this.state.creditperiod,
            currencyid: this.state.currencyid,
            currency: this.state.currency,
            services: servicedata,
            curconversionrate: parseFloat(this.state.curconversionrate),
            tcsreq: this.state.tcsreq,
            tenantid: currentTenant,
            tcs: this.state.tcs === undefined ? 0 : parseFloat(this.state.tcs),
            tcsamount:
              this.state.tcsamount === undefined
                ? 0
                : parseFloat(this.state.tcsamount),
            tcsableamount: parseFloat(this.state.tcsableamount),
            name: this.state.name,
            phoneno: this.state.phoneno,
            pincode: this.state.pincode,
            state: this.state.state,
            statecode: this.state.statecode,
            city: this.state.city,
            houseno: this.state.houseno,
            areaname: this.state.areaname,
            country: this.state.country,
            adjustdebit: this.state.adjustdebit,
            adjustopen: this.state.adjustopen,
            disname: this.state.disname,
            disphoneno: this.state.disphoneno,
            dispincode: this.state.dispincode,
            disstate: this.state.disstate,
            disstatecode: this.state.disstatecode,
            discity: this.state.discity,
            dishouseno: this.state.dishouseno,
            disareaname: this.state.disareaname,
            discountry: this.state.discountry,
            iscomposite: this.state.iscomposite,

            taxtype: this.state.taxtype,
            gsttype: this.state.gsttype,
            sotype: this.state.sotype,
            currency: this.state.currency,
            curconversionrate: this.state.curconversionrate,

            batnchidlist: this.state.batchidlist,

            insurancereq: this.state.insurancereq,
            insurance: this.state.insurancereq
              ? parseFloat(this.state.insurance)
              : 0,
            insuranceamount: this.state.insurancereq
              ? parseFloat(this.state.insuranceamount)
              : 0,
            insurancetax: this.state.insurancereq
              ? parseFloat(this.state.insurancetax)
              : 0,
            totalinsuranceamount: this.state.insurancereq
              ? parseFloat(this.state.totalinsuranceamount)
              : 0,
            einvoicerequired: this.state.einvoice,
            additionalcharges: this.state.additionalchargeslist,
            totaladdtitionalcharges: parseFloat(
              this.state.invoiceadditionalcharges
            ),
            totaladdtitionalchargestax: parseFloat(
              this.state.invoiceadditionalchargestax
            ),
            samedaydelivery: this.state.samedaydelivery.toString(),

            vehiclenum:this.state.vehiclenum,
            transporterid: this.state.samedaydelivery
              ? this.state.transporterid
              : null,
            distance:
              this.state.distance === null ||
              this.state.distance === undefined ||
              this.state.samedaydelivery === false
                ? 0
                : parseInt(this.state.distance),
            transid: this.state.samedaydelivery
              ? this.state.transporterid
              : null,
            transname: this.state.samedaydelivery ? this.state.transname : null,
            vehicletype: this.state.vehicletype ? this.state.vehicletype : null,
            // Ttansmode:this.state.samedaydelivery?this.state.transmode:null,
            supplytype: this.state.samedaydelivery
              ? this.state.supplytype
              : null,
            transportationmode: this.state.samedaydelivery
              ? this.state.transportationmode
              : 0,
            transactiontype: this.state.samedaydelivery
              ? this.state.transactiontype
              : null,
              autojre:this.state.additionalchargeslist.length>0?false:this.state.autojre,
              valueroundoff:this.state.valueroundoff,
              invoiceremarks:this.state.invoiceremarks,
              sddate:this.state.SDdate,
              date:this.state.customcreatedAt
          };
          if (this.state.pagename === "New Proforma Invoice") {
            SalesServices.GenerateProformaInvoice(data)
              .then((res) =>
                // this.props.navigate("/salesinvoice", {
                //   state: { message: "", type: "" },
                // })
                this.props.navigate("/viewsalesinvoice", {
                  state: { id:res.data, pagename:"Proforma Invoice Details",temp:false,type:"Proforma Invoice",goback:false},
                })
              )
              .catch((e) => {
                this.setState({ loader: false });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
              });
          } else {
            if (this.state.invid === -1) {
              SalesServices.GenerateInvoice(data)
                .then((res) =>
                this.props.navigate("/viewsalesinvoice", {
                  state: { id:res.data, pagename:"Tax Invoice Details",temp:true,type:"Tax Invoice",goback:false},
                })
                )
                .catch((e) => {
                  this.setState({
                    errormsg:
                      e.response.data.body === undefined
                        ? []
                        : JSON.parse(e.response.data.body),
                    loader: false,
                  });
                  if (
                    e.response === undefined ||
                    e.response.data.error === "Unauthorized"
                  ) {
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                  } else {
                    Swal.fire({
                      title:
                        e.response.data.body === undefined
                          ? e.response.data.error
                          : JSON.parse(e.response.data.body),
                      text: ".",
                      icon: "error",
                      confirmButtonText: "Ok",
                      confirmButtonColor: "#152f5f",
                    });
                  }
                });
            } else {
              SalesServices.UpdateInvoice(data)
                .then((res) =>
                  this.props.navigate("/viewsalesinvoice", {
                    state: { id:res.data, pagename:"Tax Invoice Details",temp:true,type:"Tax Invoice",goback:false},
                  })
                )
                .catch((e) => {
                  this.setState({
                    errormsg:
                      e.response.data.body === undefined
                        ? []
                        : JSON.parse(e.response.data.body),
                    loader: false,
                  });
                  if (
                    e.response === undefined ||
                    e.response.data.error === "Unauthorized"
                  ) {
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                  } else {
                    Swal.fire({
                      title:
                        e.response.data.body === undefined
                          ? "An unauthorized error occurred, Please try again after sometime"
                          : JSON.parse(e.response.data.body),
                      text: ".",
                      icon: "error",
                      confirmButtonText: "Ok",
                      confirmButtonColor: "#152f5f",
                    });
                  }
                });
            }
          }
        }
      });
    }
  };

  handleSelect = () => {
    // let a =this.state.itemquantitylist
    this.setState({ quantitywindow: true });
  };

  handleQuantityChange = (e, i) => {
    // this.setState({ quantity: e });

    let q;
    let data = this.state.itemquantitylist;
    if (e === "" || e === undefined || parseFloat(e) < 0) {
      q = 0;
    }
    // else if(this.state.pendingquantity<(parseFloat(e)+this.state.quantity)){
    //   q=this.state.pendingquantity-this.state.quantity
    // }
    else {
      q = parseFloat(e);
    }
    if (q > data.find((e, index) => index === i).currentstock) {
      data.find((e, index) => index === i).enteredquantity = data.find(
        (e, index) => index === i
      ).currentstock;
    } else {
      data.find((e, index) => index === i).enteredquantity = q;
    }
    let qa = data.reduce((a, b) => a + b.enteredquantity, 0);

    this.setState({ quantity: qa, itemquantitylist: data });
  };

  handleSaveQuantity = () => {
    if (this.state.pendingquantity < this.state.quantity) {
    } else {
      let data = this.state.itemquantitylist;
      let am = data.filter((a) => a.enteredquantity !== 0);
      if (this.state.batchidlist.length !== 0) {
        let bid = this.state.batchidlist.map((a) => a.batchid);
        am.map((a) =>
          bid.includes(a.batchid)
            ? (this.state.batchidlist.find(
                (b) => b.batchid === a.batchid
              ).quantity =
                this.state.batchidlist.find((b) => b.batchid === a.batchid)
                  .quantity - a.enteredquantity)
            : this.state.batchidlist.push({
                batchid: a.batchid,
                quantity: a.enteredquantity,
                stockid: a.stockid,
                tenantid: currentTenant,
                whid: this.state.sodata.whid,
                itemid: this.state.itemid,
              })
        );
      } else {
        am.map((a) =>
          this.state.batchidlist.push({
            batchid: a.batchid,
            quantity: a.enteredquantity,
            stockid: a.stockid,
            tenantid: currentTenant,
            whid: this.state.sodata.whid,
            itemid: this.state.itemid,
          })
        );
      }
      this.setState({ quantitywindow: false, itemquantitylist: data });
    }
  };

  refreshData() {
    const id =
      this.props.location.state === undefined
        ? this.history.props.push("/saleorder")
        : this.props.location.state.id;

    if (id !== -1) {
      SalesServices.GetTempInvoice(id).then((res) => {
        this.setState({
          saleorderid: res.data.saleorderid,
          accepteditems: res.data.invoiceitemlist,
          completed: res.data.milestone,
          serviceid: res.data.serviceid,
          poid: res.data.poid,
          addservice: res.data.addservice,
          creditperiod: res.data.creditperiod,
          currencyid: res.data.currencyid,
          currency: res.data.currency,
          curconversionrate: parseFloat(res.data.curconversionrate),
          tcsreq: res.data.tcsreq,
          tcs: parseFloat(res.data.tcs),
          tcsamount: parseFloat(res.data.tcsamount),
          tcsableamount: parseFloat(res.data.tcsableamount),
          invtotalamountwtax: res.data.totalinvoicewtax,
          totalinvoice: res.data.totalinvoice,
          name: res.data.dname,
          taxtype: res.data.taxtype,
          gsttype: res.data.gsttype,
          phoneno: res.data.dphoneno,
          pincode: res.data.dpincode,
          state: res.data.dstate,
          statecode: res.data.dstatecode,
          city: res.data.dcity,
          houseno: res.data.dhouseno,
          areaname: res.data.dareaname,
          country: res.data.dcountry,
          adjustdebit: res.data.debitadjusted===null||res.data.debitadjusted===0?false:true,
          adjustopen: res.data.openadjusted===null||res.data.openadjusted===0?false:true,
          disname: res.data.disname,
          disphoneno: res.data.disphoneno,
          dispincode: res.data.dispincode,
          disstate: res.data.disstate,
          disstatecode: res.data.disstatecode,
          discity: res.data.discity,
          dishouseno: res.data.dishouseno,
          disareaname: res.data.disareaname,
          discountry: res.data.discountry,
          iscomposite: res.data.iscomposite,
          insurancereq: res.data.insurancereq,
          insurance: parseFloat(res.data.insurance),
          insuranceamount: parseFloat(res.data.insuranceamount),
          insurancetax: parseFloat(res.data.insurancetax),
          totalinsuranceamount: parseFloat(res.data.totalinsurance),
          einvoice: res.data.einvoicerequired,
          additionalchargeslist: res.data.additionalchares,
          invoiceadditionalcharges: parseFloat(res.data.totaladditionalchares),
          invoiceadditionalchargestax: res.data.additionalchares.reduce(
            (a, b) => (a = a + b.additionalchargestaxamount),
            0
          ),
          samedaydelivery: res.data.samedaydelivery === "TRUE" ? true : false,
          vehiclenum: res.data.vehiclenum,
          transportername: res.data.transportername,
          transporterid: res.data.transporterid,
          distance: parseInt(res.data.distance),
          transid: res.data.transporterid,
          transname: res.data.transname,
          vehicletype: res.data.vehicletype,
          supplytype: res.data.supplytype,
          transportationmode: res.data.transportationmode,
          transactiontype: res.data.transactiontype,
        });

        SalesServices.GetDataforInvoice(res.data.customsoid).then((response) =>
          this.setState({
            sodata: response.data,
            serviceList: response.data.servicelist,
            saleorderitems: response.data.saleorderitems,
            serviceid:
              response.data.sotype === 1
                ? response.data.servicelist.find((e) => e).serviceid
                : "",
            milestones:
              response.data.sotype === 1
                ? response.data.servicelist.find((e) => e).milestones
                : [],
            contracttype:
              response.data.sotype === 1
                ? response.data.servicelist.find((e) => e).contracttype
                : "",
            totaltaxamount: response.data.totaltaxamount,
            totaliv: response.data.totaliv,
            grandtotal: response.data.grandtotalproducttotal,
            grandservicetotal: response.data.grandservicetotal,
            sotype: response.data.sotype,
            creditperiod:
              response.data.creditperiod === null ||
              response.data.creditperiod === undefined
                ? 0
                : response.data.creditperiod,
          })
        );
        CustomerService.retriveCustomer(res.data.custid).then((res) =>
          this.setState({
            custid: res.data.id,
            customer: res.data.companyname,
            debitamount: res.data.debitamount,
            openingbalance: res.data.openingbalance,
            name: res.data.companyname,
            phoneno: res.data.contactnumber,
            pincode: res.data.zip,
            state: res.data.statename,
            city: res.data.cityname,
            houseno: res.data.address,
            areaname: res.data.address2,
            country: res.data.countryname,
            statecode: res.data.statecode,
            currency: res.data.currency,
            currencyid: this.state.currencylist.find(
              (e) => e.currency === res.data.currency
            ).id,
            taxtype: res.data.taxtype,
            gsttype:
              res.data.statename === cstate && res.data.countryname === "India"
                ? "INTRASTATE"
                : "INTERSTATE",
            convertionreq: res.data.currency === currency ? false : true,
          })
        );
      });
    }

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
        ServiceCategoryList: response.data.filter(
          (e) => e.categorytype === "Service"
        ),
      })
    );

    InventoryService.GetWHdata(currentTenant).then((response) => {
      this.setState({ whdata: response.data });
    });

    InventoryService.GetItems(currentTenant).then((response) =>
      this.setState({
        itemList: response.data,
        closingStock:
          response.data.length === 0
            ? 0
            : response.data.find((e) => e.closingstock !== null).closingstock,
      })
    );

    InventoryService.getServices(currentTenant).then((res) => {
      this.setState({ serviceitemList: res.data });
    });

    WarehouseServices.getWarehouses(currentTenant).then((response) =>
      this.setState(
        {
          WarehouseList: response.data,
        },
        () => {
          if (this.state.WarehouseList.length === 1) {
            this.setState({
              warehouse: this.state.WarehouseList[0].name,
              whid: this.state.WarehouseList[0].id,
            });
            DepartmentServices.deptbywarehouse(this.state.WarehouseList[0].id).then((response) =>{
              this.setState({
                DepartmentList: response.data
              })
              if(response.data.length ===1){
                this.setState({
                  department: response.data[0].name,
                  deptid: response.data[0].id
                })
              }
          });
          } else if (dataaccess === "Organization" || dataaccess === "User") {
            this.setState({
              warehouse: this.state.WarehouseList.find((e) => e.id === userwhid)
                .name,
              whid: this.state.WarehouseList.find((e) => e.id === userwhid).id,
              WarehouseList: this.state.WarehouseList.filter(
                (e) => e.id === userwhid
              ),
            });
            DepartmentServices.deptbywarehouse(this.state.WarehouseList.find(e=>e.id === userwhid).id).then((response) =>{
              this.setState({
                DepartmentList: response.data
              })
              if(response.data.length ===1){
                this.setState({
                  department: response.data[0].name,
                  deptid: response.data[0].id
                })
              }
          });
          }
        }
      )
    );

    SalesServices.GetAllCurrency().then((response) => {
      this.setState({ currencylist: response.data });
    });

    SalesServices.retriveAllCustomers(currentTenant)
      .then((res) => {
        this.setState({
          custom: res.data.filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.companyname)
          ),
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    SettingsServices.GetSettings(currentTenant).then((response) => {
      this.setState({
        settings: response.data,
        soeditprice: response.data.soeditprice,
        salescalreq: response.data.salescalreq,
        pouploadm: response.data.pouploadm,
        dnrequired: response.data.dnreq,
        autojre:response.data.salesautojre==="YES"?true:false,
        // valueroundoff:response.data.salesroundoff==="YES"?1:0
        backdateinvoicestatus:response.data.backdateinvoicestatus==="YES"?true:false,
      });
    });

    MasterService.getParameters(currentTenant).then((res) => {
      this.setState({
        parameters: res.data,
        sizelist: res.data.size,
        colourlist: res.data.colour,
        thicknesslist: res.data.thickness,
        brandslist: res.data.brands,
      });
    });
    MasterService.GetCurrencyOfTenant(currentTenant).then((response) => {
      this.setState({
        basecurrency: response.data.currency,
        currencyid: response.data.id,
        currency: response.data.currency,
        curconversionrate: 1,
      });
    });

    MasterService.getCountries()
      .then((response) =>
        this.setState({
          CountryData: response.data,
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    SettingsServices.geteinvoicecredentials(currentTenant).then((response) => {
      this.setState({
        einvoice: response.data.status === "ACTIVE" ? true : false,
      });
    });

    TransporterServices.getTransporters(currentTenant).then((response) =>
      this.setState({ transporterslist: response.data })
    );

    this.setState({
      disname: clientname,
      disphoneno: contactnumber,
      dispincode: czip,
      disstate: cstate,
      disstatecode: cstatecode,
      discity: ccity,
      dishouseno: address,
      disareaname: address2,
      discountry: ccountry,
    });
    
    if (
      this.props.location.state?.soid !== undefined &&
      this.props.location.state?.soid !== null &&
      this.props.location.state?.soid !== ""
    ) {
      let id = this.props.location.state.soid;
      datalist = [];

      SalesServices.GetDataforInvoice(id).then((response) => {
        this.setState({
          customer: response.data.customer,
          custid: response.data.custid,
          saleorderid: id,
          tcsableamount: 0,
          totaladditionalcharges: 0,
          invoiceadditionalcharges: 0,
          invoiceadditionalchargestax: 0,
          sodata: response.data,
          agent: response.data.agent,
          agentid: response.data.agentid,
          whid: response.data.whid,
          deptid:response.data.deptid,
          currency: response.data.currency,
          curconversionrate: response.data.curconversionrate,
          gsttype: response.data.gsttype,
          taxtype: response.data.taxtype,
          // saleorderitems: response.data.saleorderitems,
          serviceList: response.data.servicelist,
          saleorderitems: response.data.saleorderitems,
          serviceid:
            response.data.sotype === 1
              ? response.data.servicelist.find((e) => e).serviceid
              : "",
          milestones:
            response.data.sotype === 1
              ? response.data.servicelist.find((e) => e).milestones
              : [],
          contracttype:
            response.data.sotype === 1
              ? response.data.servicelist.find((e) => e).contracttype
              : "",
          totaltaxamount: response.data.totaltaxamount,
          totaliv: response.data.totaliv,
          grandtotal: response.data.grandtotalproducttotal,
          grandservicetotal: response.data.grandservicetotal,
          sotype: response.data.sotype,
          creditperiod:
            response.data.creditperiod === null ||
            response.data.creditperiod === undefined
              ? 0
              : response.data.creditperiod,
          accepteditems:
            this.state.pagename === "New Proforma Invoice" ||
            response.data.sotype === 2
              ? response.data.saleorderitems
              : [],
          completed:
            response.data.sotype === 1 &&
            this.state.pagename === "New Proforma Invoice"
              ? response.data.servicelist.find((e) => e).milestones[0]
              : "",
          addservice: response.data.sotype === 2 ? true : false,
          pricevariance: response.data.pricevariance,
        });

        CustomerService.retriveCustomer(response.data.custid).then((res) =>
          this.setState({
            custid: res.data.id,
            customer: res.data.companyname,
            debitamount: res.data.debitamount,
            openingbalance: res.data.openingbalance,
            adjustopen: false,
            adjustdebit: res.data.debitamount > 0 ? true : false,
            name: res.data.companyname,
            phoneno: res.data.contactnumber,
            pincode: res.data.zip,
            state: res.data.statename,
            city: res.data.cityname,
            houseno: res.data.address,
            areaname: res.data.address2,
            country: res.data.countryname,
            statecode: res.data.statecode,
            currency: res.data.currency,
            currencyid: this.state.currencylist.find(
              (e) => e.currency === res.data.currency
            ).id,
            taxtype: res.data.taxtype,
            gsttype:
              res.data.statename === cstate && res.data.countryname === "India"
                ? "INTRASTATE"
                : "INTERSTATE",
            convertionreq: res.data.currency === currency ? false : true,
          })
        );
      });
    }
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      })
    );
    this.refreshData();
    datalist = [];
  }

  validate(values) {
    let errors = {};
    //  let isError = false;
    var num = /^[0-9]/;

    if (this.state.customer === "") {
      errors.customer = "Please Select Customer";
    }

    if (
      this.state.invtype === "Order Invoice" &&
      this.state.saleorderid === ""
    ) {
      errors.saleorder = "Please Select Sale Order";
    }

    if (
      this.state.quantity === "" ||
      this.state.quantity === undefined ||
      this.state.quantity === "0" ||
      parseFloat(this.state.quantity) <= 0 ||
      !num.test(parseFloat(this.state.quantity))
    ) {
      errors.quantity = "Quantity is required";
    }
    if (
      this.state.invtype === "Order Invoice" &&
      parseFloat(this.state.quantity) > this.state.pendingquantity
    ) {
      errors.quantity = `Avilable quantity is ${this.state.pendingquantity}`;
    }
    //  if (this.state.poid === '') {
    //    errors.poid = "Pelese provide PO Ref"
    //  }

    if (
      this.state.invtype === "Direct Invoice" &&
      (this.state.price === "" ||
        this.state.price === undefined ||
        this.state.price === "0" ||
        this.state.price === 0)
    ) {
      errors.price = "Price is required";
    }
    return errors;
  }

  render() {
    let {
      custom,
      customer,
      custid,
      saleorderdata,
      itemid,
      itemname,
      quantity,
      orderedquantity,
      grandtotal,
      status,
      saleorderitems,
      poid,
      DnIDList,
      currencylist,
      currencyid,
      curconversionrate,
      tcs,
      tcsreq,
      tcsamount,
      pendingquantity,
      creditperiod,
      additionalchargeslist,
      additionalcharges,
      additionalchargestax,
      totaladditionalcharges,
      additionalchargestaxamount,
      natureofcharge,
      vehiclenum,
      distance,
      transporterid,

      WarehouseList,
      DepartmentList,
      CategoryList,
      filteritemlist,
      sizelist,
      colourlist,
      brandslist,
      filterserv,
      serviceitemList,
      serviceList,
      service,
      serviceid,
      ServiceCategoryList,
      servicecategory,
      servicecatid,
      servicetax,
      servicecode,
      servicecost,
      servicecostconv,
      servicecgst,
      servicesgst,
      serviceigst,
      servicegst,
      servicevat,
      servicecostwttax,
      servicecostwttaxconv,
      servicetaxamount,
      servicedescription,
      servicetotal,
      grandservicetotal,
      serviceerr,
      markup1,
      markup,
      invoiceremarks
    } = this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.loader}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/salesinvoice" }}
                state={{
                  message: "",
                  type:
                    this.state.pagename === "New Proforma Invoice"
                      ? "Proforma Invoice"
                      : "Tax Invoice",
                }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link
                  to={{ pathname: "/salesinvoice" }}
                  state={{
                    message: "",
                    type:
                      this.state.pagename === "New Proforma Invoice"
                        ? "Proforma Invoice"
                        : "Tax Invoice",
                  }}
                >
                  Invoice
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                {this.state.pagename}
              </li>
            </ol>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title">{this.state.pagename}</h3>
              </div>

              <div className="card-body">
                <Formik
                  initialValues={{
                    custom,
                    customer,
                    custid,
                    saleorderdata,
                    itemid,
                    itemname,
                    quantity,
                    orderedquantity,
                    grandtotal,
                    status,
                    saleorderitems,
                    poid,
                    DnIDList,
                    currencylist,
                    currencyid,
                    curconversionrate,
                    tcs,
                    tcsreq,
                    tcsamount,
                    pendingquantity,
                    creditperiod,
                    additionalchargeslist,
                    additionalcharges,
                    additionalchargestax,
                    totaladditionalcharges,
                    additionalchargestaxamount,
                    natureofcharge,
                    vehiclenum,
                    distance,
                    transporterid,

                    WarehouseList,
                    DepartmentList,
                    CategoryList,
                    filteritemlist,
                    sizelist,
                    colourlist,
                    brandslist,
                    filterserv,
                    serviceitemList,
                    serviceList,
                    service,
                    serviceid,
                    ServiceCategoryList,
                    servicecategory,
                    servicecatid,
                    servicetax,
                    servicecode,
                    servicecost,
                    servicecostconv,
                    servicecgst,
                    servicesgst,
                    serviceigst,
                    servicegst,
                    servicevat,
                    servicecostwttax,
                    servicecostwttaxconv,
                    servicetaxamount,
                    servicedescription,
                    servicetotal,
                    grandservicetotal,
                    serviceerr,
                    markup1,
                    markup,
                    invoiceremarks
                  }}
                  validateOnChange={false}
                  validate={this.validate}
                  onSubmit={this.onSubmit}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) => (
                    <Form className="form-group row">
                      {this.state.pagename === "New Proforma Invoice" ||
                      this.state.accepteditems.length > 0 ? (
                        ""
                      ) : (
                        <fieldset className="form-group col-md-12">
                          <fieldset
                            className={`form-group col-md-4  shadow-sm text-center btn ${
                              this.state.invtype === "Order Invoice"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={this.state.invtype === "Order Invoice"}
                            onClick={() =>
                              this.setState({ invtype: "Order Invoice" })
                            }
                          >
                            Order Invoice
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-4 shadow-sm btn text-center ${
                              this.state.invtype === "Direct Invoice"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={this.state.invtype === "Direct Invoice"}
                            onClick={() =>
                              this.setState({ invtype: "Direct Invoice" })
                            }
                          >
                            Direct Invoice
                          </fieldset>
                        </fieldset>
                      )}

                      {this.state.invid === -1 ? (
                        <>
                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="customer"
                              options={custom}
                              getOptionLabel={(option) => option.companyname}
                              value={values.customer ? undefined : undefined}
                              onChange={this.handleCustomer}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="customer"
                                  value={this.state.customer}
                                  label={`${
                                    this.state.customer === "" ||
                                    this.state.customer === undefined ||
                                    this.state.customer === null
                                      ? "Select Customer"
                                      : this.state.customer
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          {this.state.invtype === "Direct Invoice" ? (
                            ""
                          ) : (
                            <>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="saleorder"
                                  options={saleorderdata}
                                  getOptionLabel={(option) => option}
                                  value={
                                    values.saleorder ? undefined : undefined
                                  }
                                  onChange={this.handleSaleOrder}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="saleorder"
                                      value={this.state.saleorderid}
                                      label={`${
                                        this.state.saleorderid === "" ||
                                        this.state.saleorderid === undefined ||
                                        this.state.saleorderid === null
                                          ? "Select Order"
                                          : this.state.saleorderid
                                      }`}
                                      variant="standard"
                                      fullwidth="true"
                                    />
                                  )}
                                />
                              </fieldset>

                              {this.state.pagename ===
                              "New Proforma Invoice" ? (
                                ""
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="number"
                                    name="creditperiod"
                                    onChange={(e) => {
                                      this.setState({
                                        creditperiod: e.target.value,
                                      });
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={this.state.creditperiod}
                                    label={`Credit Period (DAYS)`}
                                    variant="standard"
                                    fullwidth="true"
                                  />
                                </fieldset>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      <fieldset className="form-group col-md-12" />

                      {this.state.saleorderid === "" ? (
                        this.state.invtype === "Direct Invoice" ? (
                          <>
                            <>
                              {this.state.settings.salesservice === "NO" &&
                              this.state.settings.salespands === "NO" ? (
                                ""
                              ) : (
                                <fieldset className="form-group col-md-12 p-0 m-0">
                                  <div className="form-row p-0 m-0">
                                    <fieldset className="form-group col-md-4 p-0 m-0">
                                      <span className="mx-3">Products</span>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            className="sm"
                                            checked={this.state.sotype === 0}
                                            onChange={() =>
                                              this.setState({ sotype: 0 })
                                            }
                                            name="checkedB"
                                          />
                                        }
                                      />
                                    </fieldset>
                                    {this.state.settings.salesservice ===
                                    "YES" ? (
                                      <fieldset className="form-group col-md-4 p-0 m-0">
                                        <span className="mx-3">Services</span>
                                        <FormControlLabel
                                          control={
                                            <IOSSwitch
                                              className="sm"
                                              checked={this.state.sotype === 1}
                                              onChange={() =>
                                                this.setState({ sotype: 1 })
                                              }
                                              name="checkedB"
                                            />
                                          }
                                        />
                                      </fieldset>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.settings.salespands ===
                                    "YES" ? (
                                      <fieldset className="form-group col-md-4 p-0 m-0">
                                        <span className="mx-3">
                                          {" "}
                                          Products & Services
                                        </span>
                                        <FormControlLabel
                                          control={
                                            <IOSSwitch
                                              className="sm"
                                              checked={this.state.sotype === 2}
                                              onChange={() =>
                                                this.setState({ sotype: 2 })
                                              }
                                              name="checkedB"
                                            />
                                          }
                                        />
                                      </fieldset>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </fieldset>
                              )}
                            </>

                            {this.state.sotype === 0 ||
                            this.state.sotype === 2 ? (
                              <>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={WarehouseList}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={this.handleWarehouse}
                                    // disabled={this.state.grandtotal!==0}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={this.state.warehouse}
                                        label={`Select Org Unit (${this.state.warehouse})`}
                                        variant="standard"
                                        fullwhidth="true"
                                        // disabled={this.state.grandtotal!==0}
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="department"
                                    options={DepartmentList}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                      values.department ? undefined : undefined
                                    }
                                    onChange={this.handleDepartment}
                                    // disabled={this.state.grandtotal!==0}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="department"
                                        value={this.state.department}
                                        label={`Select Department (${this.state.department})`}
                                        variant="standard"
                                        fullwhidth="true"
                                        disabled={DepartmentList.length ===1}
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="category"
                                    options={CategoryList}
                                    getOptionLabel={(option) => option.category}
                                    value={
                                      values.category ? undefined : undefined
                                    }
                                    onChange={this.handleCategory}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="category"
                                        value={this.state.category}
                                        label={`Select Category (${this.state.category})`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3 ">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="itemname"
                                    options={filteritemlist}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                      values.itemname ? undefined : undefined
                                    }
                                    onChange={this.handleitem}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="itemname"
                                        value={this.state.itemname}
                                        label={`Select Item (${this.state.itemname})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    id="price"
                                    className="form-control"
                                    type="text"
                                    name="price"
                                    value={
                                      this.state.price === ""
                                        ? 0
                                        : this.state.price
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        price:
                                          e.target.value === undefined ||
                                          e.target.value === null ||
                                          e.target.value < 0
                                            ? 0
                                            : e.target.value,
                                        totalpricewtax: "",
                                      })
                                    }
                                    label={`Price (Type: ${this.state.type})`}
                                    variant="standard"
                                    fullWidth
                                    disabled={
                                      this.state.orderfrom === 1 ||
                                      this.state.settings.soeditprice === "NO"
                                    }
                                  />
                                </fieldset>
                                {this.state.settings.allowextramakup ==="YES"?
                                <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  id="markup1"
                                  className="form-control"
                                  type="number"
                                  name="markup1"
                                  label={"Markup1"}
                                  min="0"
                                  value={this.state.markup1}
                                  onChange={this.handlemarkup1Change}
                                />
                              </fieldset>:""}

                                {this.state.salescalreq === "YES" &&
                                this.state.settings.soeditprice === "YES" ? (
                                  <fieldset className="form-group col-md-3">
                                    <FormControl variant="standard" fullWidth>
                                      <InputLabel id="demo-simple-select-standard-label">
                                        Type
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.type}
                                        onChange={this.handleTypeChange}
                                        label="Type"
                                      >
                                        <MenuItem value={"Average"}>
                                          Average
                                        </MenuItem>
                                        <MenuItem value={"FIFO"}>FIFO</MenuItem>
                                        <MenuItem value={"LIFO"}>LIFO</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </fieldset>
                                ) : (
                                  ""
                                )}

                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    id="quantity"
                                    className="form-control"
                                    type="number"
                                    name="quantity"
                                    label={`Quantity (Available: ${
                                      this.state.stock === undefined ||
                                      this.state.stock === null
                                        ? 0
                                        : this.state.stock
                                    })`}
                                    min="0"
                                    onChange={(e) =>
                                      this.setState({
                                        quantity:
                                          e.target.value === undefined
                                            ? 0
                                            : e.target.value,
                                        totalpricewtax: "",
                                      })
                                    }
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    id="discount"
                                    className="form-control"
                                    type="number"
                                    name="discount"
                                    label={"Discount (%)"}
                                    min="0"
                                    value={this.state.discount}
                                    onChange={(e) =>
                                      this.setState({
                                        discount: e.target.value,
                                      })
                                    }
                                    onFocus={this.ondiscountfocus}
                                  />
                                </fieldset>
                                {this.state.currency ===
                                this.state.basecurrency ? (
                                  ""
                                ) : (
                                  <fieldset className="form-group col-md-3">
                                    <FormikTextField
                                      id="pricevariance"
                                      className="form-control"
                                      type="number"
                                      name="pricevariance"
                                      label={"Price variance in %"}
                                      min="0"
                                      onChange={(e) =>
                                        this.setState({
                                          pricevariance: e.target.value,
                                          totalpricewtax: "",
                                          availabelcredit:
                                            this.state.creditvalue,
                                        })
                                      }
                                      onFocus={this.onpricevariancefocus}
                                    />
                                  </fieldset>
                                )}

                                {this.state.taxrequired === true ? (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="text"
                                        name="hsn"
                                        value={this.state.hsn}
                                        label={`${
                                          this.state.taxtype === "VAT"
                                            ? "Item Code"
                                            : " HSN Code"
                                        }`}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>

                                    {this.state.taxtype === "GST" &&
                                    ccountry === "India" ? (
                                      this.state.gsttype === "INTRASTATE" ? (
                                        <>
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="cgst"
                                              value={this.state.cgst}
                                              label="CGST (%)"
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="sgst"
                                              value={this.state.sgst}
                                              label="SGST (%)"
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                        </>
                                      ) : (
                                        <>
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="tax"
                                              value={this.state.tax}
                                              label="IGST (%)"
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {this.state.taxtype === "GST" ? (
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="tax"
                                              value={this.state.tax}
                                              label="GST (%)"
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                        ) : (
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="tax"
                                              label="VAT (%)"
                                              value={this.state.tax}
                                              min="0"
                                              disabled
                                            />
                                          </fieldset>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="uom"
                                    value={this.state.uom}
                                    label="UOM"
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3 ">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="brand"
                                    label="Brand"
                                    value={this.state.brand}
                                    disabled
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3 ">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="model"
                                    label="Model"
                                    value={this.state.model}
                                    disabled
                                  />
                                </fieldset>
                                {this.state.additionalparams === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <Autocomplete
                                        id="subaccount"
                                        className="subacc-select"
                                        name="size"
                                        options={sizelist}
                                        getOptionLabel={(option) => option}
                                        value={
                                          values.size ? undefined : undefined
                                        }
                                        onChange={this.handleSize}
                                        renderInput={(params) => (
                                          <FormikTextField
                                            {...params}
                                            className="form-control"
                                            type="text"
                                            name="size"
                                            value={this.state.size}
                                            label={`Select Size (${
                                              this.state.size === null ||
                                              this.state.size === undefined
                                                ? ""
                                                : this.state.size
                                            })`}
                                            variant="standard"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </fieldset>

                                    <fieldset className="form-group col-md-3">
                                      <Autocomplete
                                        id="subaccount"
                                        className="subacc-select"
                                        name="colour"
                                        options={colourlist}
                                        getOptionLabel={(option) => option}
                                        value={
                                          values.colour ? undefined : undefined
                                        }
                                        onChange={this.handleColour}
                                        renderInput={(params) => (
                                          <FormikTextField
                                            {...params}
                                            className="form-control"
                                            type="text"
                                            name="colour"
                                            value={this.state.colour}
                                            label={`Select Colour (${
                                              this.state.colour === null ||
                                              this.state.colour === undefined
                                                ? ""
                                                : this.state.colour
                                            })`}
                                            variant="standard"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </fieldset>

                                    <fieldset className="form-group col-md-3 ">
                                      <FormikTextField
                                        className="form-control"
                                        type="text"
                                        name="thikness"
                                        value={this.state.thickness}
                                        onChange={(e) =>
                                          this.setState({
                                            thickness: e.target.value,
                                          })
                                        }
                                        label={`Thickness (${this.state.thickness})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    </fieldset>

                                    <fieldset className="form-group col-md-3 ">
                                      <FormikTextField
                                        className="form-control"
                                        type="text"
                                        name="totalmtrroll"
                                        value={this.state.totalmtrroll}
                                        onChange={(e) =>
                                          this.setState({
                                            totalmtrroll: e.target.value,
                                          })
                                        }
                                        label="TotalMtrRoll"
                                        variant="standard"
                                        fullWidth
                                      />
                                    </fieldset>
                                  </>
                                )}
                                <fieldset className="form-group col-md-12  mt-3 mb-5">
                                  <FormikTextField
                                    name="description"
                                    className="form-control"
                                    multiline
                                    rows={1}
                                    label="Description/Specifications"
                                    variant="outlined"
                                    value={this.state.description}
                                    onChange={(e) =>
                                      this.setState({
                                        description: e.target.value,
                                      })
                                    }
                                  />
                                </fieldset>
                                <div className="form-group col-md-12 m-0 p-0" />

                                {this.state.einvoice &&
                                (this.state.iscomposite === false ||
                                  this.state.accepteditems.length !== 0) ? (
                                  <fieldset className="form-group col-md-12 m-0">
                                    <FormGroup row>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state.iscomposite}
                                            onChange={() =>
                                              this.setState({
                                                iscomposite:
                                                  !this.state.iscomposite,
                                              })
                                            }
                                            name="iscomposite"
                                            color="primary"
                                          />
                                        }
                                        label={`Is Composite Item`}
                                      />
                                    </FormGroup>
                                  </fieldset>
                                ) : (
                                  ""
                                )}
                                <fieldset className="form-group col-md-12 m-0">
                                  {this.state.iscomposite ? (
                                    <span className="text-sm text-red">
                                      Note: Reverse GST will be applicable on
                                      this invoice
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </fieldset>

                                {this.state.creditstatus === "Required" ? (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <>Available Credit</>
                                      <>
                                        &nbsp; : &nbsp;{" "}
                                        <NumericFormat
                                          displayType={"text"}
                                          value={
                                            this.state.availabelcredit === "" ||
                                            this.state.availabelcredit ===
                                              undefined
                                              ? 0
                                              : this.state.availabelcredit
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </>
                                    </fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <>Credit Value</>
                                      <>
                                        &nbsp; : &nbsp;{" "}
                                        <NumericFormat
                                          displayType={"text"}
                                          value={
                                            this.state.initialCredit === "" ||
                                            this.state.initialCredit ===
                                              undefined
                                              ? 0
                                              : this.state.initialCredit
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </>
                                    </fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <>Credit Period</>
                                      <>
                                        &nbsp; : &nbsp;{" "}
                                        {this.state.creditperiod === ""
                                          ? "0"
                                          : this.state.creditperiod}{" "}
                                        days
                                      </>
                                    </fieldset>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="form-group col-md-12 m-0 p-0" />

                                {this.state.availabelcredit < 0 ? (
                                  <>
                                    <p className="text-red col-md-12  m-0 p-0">
                                      Execeded Credit Value, Requsest for credit
                                    </p>

                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        id="requestrridet"
                                        name="requestrridet"
                                        onChange={(e) =>
                                          this.setState({
                                            requestcredit: parseFloat(
                                              e.target.value
                                            ),
                                          })
                                        }
                                        label={`Enter Request Credit Amount`}
                                        value={this.state.requestcredit}
                                        variant="standard"
                                        fullwhidth="true"
                                        error={this.state.requestcredit <= 0}
                                      />
                                    </fieldset>
                                    <div className="form-group col-md-12 m-0 p-0" />
                                    <button
                                      className="btn btn-sm hovbuttonColour"
                                      onClick={this.handleRequestCredit}
                                      type="button"
                                    >
                                      Request Credit Value
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-sm hovbuttonColour"
                                      type="submit"
                                    >
                                      Add item
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.sotype !== 0 ? (
                              <div className="form-row">
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="servicecategory"
                                    options={ServiceCategoryList}
                                    getOptionLabel={(option) => option.category}
                                    value={
                                      values.servicecategory
                                        ? undefined
                                        : undefined
                                    }
                                    onChange={this.handleServicecategory}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="servicecategory"
                                        value={this.state.servicecategory}
                                        label={`${
                                          this.state.servicecategory === "" ||
                                          this.state.servicecategory ===
                                            undefined
                                            ? `Select Service Category (${this.state.servicecategory})`
                                            : this.state.servicecategory
                                        }`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="service"
                                    options={filterserv}
                                    getOptionLabel={(option) => option.service}
                                    value={
                                      values.service ? undefined : undefined
                                    }
                                    onChange={this.handleService}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="service"
                                        value={this.state.service}
                                        label={`${
                                          this.state.service === "" ||
                                          this.state.service === undefined
                                            ? `Select Service (${this.state.service})`
                                            : this.state.service
                                        }`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="servicecode"
                                    label="Code"
                                    value={this.state.servicecode}
                                    disabled
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    id="amount"
                                    className="form-control"
                                    type="number"
                                    name="servicecost"
                                    label="Amount"
                                    onChange={this.handleServiceAmountChange}
                                    onFocus={this.onamountfocus}
                                  />
                                </fieldset>
                                {this.state.currency ===
                                this.state.basecurrency ? (
                                  ""
                                ) : (
                                  <fieldset className="form-group col-md-3">
                                    <FormikTextField
                                      id="amount"
                                      className="form-control"
                                      type="number"
                                      name="servicecostconv"
                                      label={`Amount in ${this.state.currency}`}
                                      value={this.state.servicecostconv}
                                      disabled
                                    />
                                  </fieldset>
                                )}
                                {this.state.taxrequired === true ? (
                                  <>
                                    {this.state.taxtype === "GST" &&
                                    ccountry === "India" ? (
                                      <>
                                        {this.state.gsttype === "INTRASTATE" ? (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <FormikTextField
                                                className="form-control"
                                                type="number"
                                                name="servicecgst"
                                                label={`CGST (${
                                                  this.state.servicetax === ""
                                                    ? 0
                                                    : this.state.servicetax / 2
                                                }%)`}
                                                value={this.state.servicecgst}
                                                disabled
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <FormikTextField
                                                className="form-control"
                                                type="number"
                                                name="servicesgst"
                                                label={`SGST (${
                                                  this.state.servicetax === ""
                                                    ? 0
                                                    : this.state.servicetax / 2
                                                }%)`}
                                                value={this.state.servicesgst}
                                                disabled
                                              />
                                            </fieldset>
                                          </>
                                        ) : (
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="serviceigst"
                                              label={`IGST (${
                                                this.state.servicetax === ""
                                                  ? 0
                                                  : this.state.servicetax
                                              }%)`}
                                              value={this.state.serviceigst}
                                              disabled
                                            />
                                          </fieldset>
                                        )}
                                      </>
                                    ) : this.state.taxtype === "GST" ? (
                                      <fieldset className="form-group col-md-3">
                                        <FormikTextField
                                          className="form-control"
                                          type="number"
                                          name="servicegst"
                                          label={`GST (${
                                            this.state.servicetax === ""
                                              ? 0
                                              : this.state.servicetax
                                          }%)`}
                                          value={this.state.servicegst}
                                          disabled
                                        />
                                      </fieldset>
                                    ) : (
                                      <fieldset className="form-group col-md-3">
                                        <FormikTextField
                                          className="form-control"
                                          type="number"
                                          name="servicevat"
                                          label={`VAT (${
                                            this.state.servicetax === ""
                                              ? 0
                                              : this.state.servicetax
                                          }%)`}
                                          value={this.state.servicevat}
                                          disabled
                                        />
                                      </fieldset>
                                    )}

                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="servicecostwttax"
                                        label="Total Amount"
                                        value={this.state.servicecostwttax}
                                        disabled
                                      />
                                    </fieldset>

                                    {this.state.currency ===
                                    this.state.basecurrency ? (
                                      ""
                                    ) : (
                                      <fieldset className="form-group col-md-3">
                                        <FormikTextField
                                          className="form-control"
                                          type="number"
                                          name="servicecostwttaxconv"
                                          label={`Total Amount in ${this.state.currency}`}
                                          value={
                                            this.state.servicecostwttaxconv
                                          }
                                          disabled
                                        />
                                      </fieldset>
                                    )}
                                    <fieldset className="form-group col-md-12  mt-3 mb-5">
                                      <FormikTextField
                                        name="servicedescription"
                                        className="form-control"
                                        multiline
                                        rows={2}
                                        label="Description"
                                        variant="outlined"
                                        value={this.state.servicedescription}
                                        onChange={(e) =>
                                          this.setState({
                                            servicedescription: e.target.value,
                                          })
                                        }
                                      />
                                    </fieldset>
                                  </>
                                ) : (
                                  ""
                                )}
                                {this.state.sotype === 1 ? (
                                  ""
                                ) : (
                                  <>
                                    <span
                                      className="btn btn-sm hovbuttonColour m-0 cursorPointer"
                                      onClick={this.handleaddService}
                                    >
                                      Add Service
                                    </span>

                                    <div className="form-group col-md-12 text-red text-sm">
                                      {this.state.serviceerr}
                                    </div>
                                    {this.state.serviceList.length === 0 ? (
                                      ""
                                    ) : (
                                      <TableContainer>
                                        <Table className="table-sm">
                                          <TableHead>
                                            <TableRow
                                              style={{ textAlign: "center" }}
                                            >
                                              <TableCell>Service</TableCell>
                                              {/* <TableCell>Service Category</TableCell> */}
                                              <TableCell>
                                                Service Code
                                              </TableCell>
                                              <TableCell>
                                                Service Description
                                              </TableCell>
                                              <TableCell>
                                                Service Cost
                                              </TableCell>
                                              {this.state.taxrequired ===
                                              true ? (
                                                <>
                                                  {this.state.taxtype ===
                                                    "GST" &&
                                                  ccountry === "India" ? (
                                                    this.state.gsttype ===
                                                    "INTRASTATE" ? (
                                                      <TableCell>
                                                        CGST(%)
                                                      </TableCell>
                                                    ) : (
                                                      <TableCell>
                                                        IGST
                                                      </TableCell>
                                                    )
                                                  ) : this.state.taxtype ===
                                                    "GST" ? (
                                                    <TableCell>GST</TableCell>
                                                  ) : (
                                                    <TableCell>VAT</TableCell>
                                                  )}
                                                  {this.state.gsttype ===
                                                  "INTRASTATE" ? (
                                                    <TableCell>
                                                      SGST(%)
                                                    </TableCell>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              <TableCell>
                                                Service Total
                                              </TableCell>
                                              <TableCell>Action</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {this.state.serviceList.map(
                                              (data, index) => (
                                                <TableRow
                                                  key={index}
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <TableCell>
                                                    {data.service}
                                                  </TableCell>
                                                  {/* <TableCell>{data.servicecategory}</TableCell> */}
                                                  <TableCell>
                                                    {data.servicecode}
                                                  </TableCell>
                                                  <TableCell>
                                                    {data.servicedescription}
                                                  </TableCell>
                                                  <TableCell>
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      value={data.servicecost}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.taxrequired ===
                                                  true ? (
                                                    <>
                                                      {this.state.taxtype ===
                                                        "GST" &&
                                                      ccountry === "India" &&
                                                      this.state.gsttype ===
                                                        "INTRASTATE" ? (
                                                        <>
                                                          <TableCell>
                                                            {data.servicetax /
                                                              2}{" "}
                                                            %
                                                          </TableCell>
                                                          <TableCell>
                                                            {data.servicetax /
                                                              2}{" "}
                                                            %
                                                          </TableCell>
                                                        </>
                                                      ) : (
                                                        <TableCell>
                                                          {data.servicetax} %
                                                        </TableCell>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <TableCell>
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      value={data.servicetotal}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell>
                                                    <span
                                                      className="fa fa-trash text-danger cursorPointer"
                                                      style={{
                                                        paddingLeft: "7px",
                                                      }}
                                                      onClick={() =>
                                                        this.removeServiceFromList(
                                                          index
                                                        )
                                                      }
                                                    ></span>
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                            <TableRow
                                              style={{ textAlign: "center" }}
                                            >
                                              <TableCell></TableCell>
                                              <TableCell></TableCell>
                                              {this.state.taxrequired ===
                                              true ? (
                                                this.state.taxtype === "GST" &&
                                                ccountry === "India" &&
                                                this.state.gsttype ===
                                                  "INTRASTATE" ? (
                                                  <>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                  </>
                                                ) : (
                                                  <TableCell></TableCell>
                                                )
                                              ) : (
                                                ""
                                              )}
                                              <TableCell></TableCell>
                                              <TableCell></TableCell>
                                              <TableCell>
                                                {this.state
                                                  .grandservicetotal === "" ? (
                                                  0
                                                ) : (
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    value={
                                                      this.state
                                                        .grandservicetotal
                                                    }
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                )}
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <div className="form-group col-md-12">
                            <u>Order Details : </u>
                          </div>
                          <div className="form-group col-md-3">
                            <table className="float-left mb-3">
                              <tbody>
                                <tr>
                                  <td>Orderid</td>
                                  <td>:&nbsp;{this.state.sodata.customsoid}</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td>:&nbsp;{this.state.sodata.customer}</td>
                                </tr>
                                <tr>
                                  <td>Created By</td>
                                  <td>:&nbsp;{this.state.sodata.createdby}</td>
                                </tr>
                                {this.state.sodata.agent === null ||
                                this.state.sodata.agent === undefined ||
                                this.state.sodata.agent === "" ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Agent</td>
                                    <td>:&nbsp;{this.state.sodata.agent}</td>
                                  </tr>
                                )}
                                {this.state.sotype === 1 ? (
                                  ""
                                ) : (
                                  <>
                                    <tr>
                                      <td>Org Unit</td>
                                      <td>
                                        :&nbsp;{this.state.sodata.warehouse}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Credit Period&nbsp;</td>
                                      <td>
                                        :&nbsp;
                                        {this.state.sodata.creditperiod ===
                                          undefined ||
                                        this.state.sodata.creditperiod === null
                                          ? 0
                                          : this.state.sodata.creditperiod}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Credit Value&nbsp;</td>
                                      <td>
                                        :&nbsp;
                                        <NumericFormat
                                          className="pr-3"
                                          displayType={"text"}
                                          value={
                                            this.state.sodata.creditvalue ===
                                              undefined ||
                                            this.state.sodata.creditvalue ===
                                              null
                                              ? 0
                                              : this.state.sodata.creditvalue
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="form-group col-md-3">
                            <table className="float-left mb-3">
                              <tbody>
                                <tr>
                                  <td>Tax Type</td>
                                  <td>:&nbsp;{this.state.sodata.taxtype}</td>
                                </tr>
                                {this.state.sodata.taxtype === "GST" ? (
                                  <tr>
                                    <td>GST Type</td>
                                    <td>:&nbsp;{this.state.sodata.gsttype}</td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                <tr>
                                  <td>Total Amount&nbsp;</td>
                                  <td>
                                    :&nbsp;
                                    <NumericFormat
                                      className="pr-3"
                                      displayType={"text"}
                                      value={this.state.sodata.sototalwtax}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      disabled={true}
                                      thousandSeparator={true}
                                      thousandsGroupStyle={thousandsdisplay}
                                    ></NumericFormat>
                                  </td>
                                </tr>
                                {this.state.sodata.advancepaymentreq ===
                                "True" ? (
                                  <>
                                    <tr>
                                      <td>Advance Amount Req.&nbsp;&nbsp;</td>
                                      <td>
                                        :&nbsp;
                                        <NumericFormat
                                          displayType={"text"}
                                          value={
                                            this.state.sodata.advanceamountreq
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Advance Amount Received</td>
                                      <td>
                                        :&nbsp;
                                        <NumericFormat
                                          displayType={"text"}
                                          value={
                                            this.state.sodata.advanceamountpaid
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  ""
                                )}
                                {this.state.saleorderitems.length === 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Consinment Amount&nbsp;</td>
                                    <td>
                                      :&nbsp;
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={
                                          this.state.sodata.sototalwtax -
                                          this.state.sodata.advanceamountpaid
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                )}
                                {this.state.sodata.poid === "" ||
                                this.state.sodata.poid === undefined ||
                                this.state.sodata.poid === null ? (
                                  ""
                                ) : (
                                  <>
                                    <tr>
                                      <td>PO Ref Id/no</td>
                                      <td>:&nbsp;{this.state.sodata.poid}</td>
                                    </tr>
                                    <tr>
                                      <td>PO File</td>
                                      <td>
                                        :&nbsp;
                                        <span
                                          as="button"
                                          // className="text-blue btn btn-link m-0"
                                          onClick={() =>
                                            this.handledownload(
                                              this.state.sodata.pofile
                                            )
                                          }
                                        >
                                          {this.state.sodata.pofile}
                                        </span>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {this.state.sotype === 0 ||
                          this.state.sotype === 2 ? (
                            <>
                              {this.state.sotype === 0 &&
                              this.state.pagename !== "New Proforma Invoice" &&
                              this.state.invid === -1 ? (
                                <TableContainer>
                                  <Typography>
                                    Orderd Items / Products
                                  </Typography>
                                  <Table
                                    aria-label="collapsible table"
                                    className="table-sm"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell />
                                        <TableCell />
                                        {this.state.saleorderitems.filter(
                                          (e) =>
                                            e.soidforadjust !== null &&
                                            e.soidforadjust !== undefined &&
                                            e.soidforadjust !== ""
                                        ).length > 0 ? (
                                          <TableCell>
                                            Adjusted from SO
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        <TableCell className="text-center">
                                          Item Name
                                        </TableCell>
                                        {this.state.sodata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            HSN
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        {currency ===
                                        this.state.sodata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price({this.state.sodata.currency})
                                          </TableCell>
                                        )}
                                        {currency ===
                                        this.state.sodata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price Variance (%)
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Type
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Pending Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Discount
                                        </TableCell>
                                        {this.state.sodata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Discount (
                                            {this.state.sodata.currency})
                                          </TableCell>
                                        )}
                                        {/* <TableCell className="text-center">
                                        Insurance
                                      </TableCell>
                                      {this.state.sodata.currency ===
                                      currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Insurance (
                                          {this.state.sodata.currency})
                                        </TableCell>
                                      )} */}
                                        <TableCell className="text-center">
                                          Total
                                        </TableCell>
                                        {this.state.sodata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total ({this.state.sodata.currency})
                                          </TableCell>
                                        )}
                                        {this.state.sodata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <>
                                            {this.state.sodata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                              this.state.sodata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-center">
                                                        CGST(
                                                        {
                                                          this.state.sodata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        SGST(
                                                        {
                                                          this.state.sodata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    IGST
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      IGST(
                                                      {
                                                        this.state.sodata
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              )
                                            ) : this.state.sodata.taxtype ===
                                              "GST" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  GST
                                                </TableCell>
                                                {this.state.sodata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    GST(
                                                    {this.state.sodata.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <TableCell className="text-center">
                                                  VAT
                                                </TableCell>
                                                {this.state.sodata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    VAT(
                                                    {this.state.sodata.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                            <TableCell className="text-center">
                                              Total Price
                                            </TableCell>
                                            {this.state.sodata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                Total Price(
                                                {this.state.sodata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.saleorderitems.map(
                                        (row, index) => (
                                          <React.Fragment key={index}>
                                            <TableRow>
                                              <TableCell className="text-center">
                                                {this.state.accepteditems
                                                  .map((data) => data.id)
                                                  .includes(row.id) ? (
                                                  ""
                                                ) : (
                                                  <span
                                                    as="button"
                                                    className="btn btn-sm hovbuttonColour"
                                                    onClick={() =>
                                                      this.handleAccept(row.id)
                                                    }
                                                  >
                                                    Add
                                                  </span>
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  aria-label="expand row"
                                                  size="small"
                                                  onClick={() =>
                                                    this.setState({
                                                      open: !this.state.open,
                                                    })
                                                  }
                                                >
                                                  {this.state.open ? (
                                                    <KeyboardArrowUpIcon />
                                                  ) : (
                                                    <KeyboardArrowDownIcon />
                                                  )}
                                                </IconButton>
                                              </TableCell>
                                              {this.state.saleorderitems.filter(
                                                (e) =>
                                                  e.soidforadjust !== null &&
                                                  e.soidforadjust !==
                                                    undefined &&
                                                  e.soidforadjust !== ""
                                              ).length > 0 ? (
                                                <TableCell className="text-center">
                                                  {row.soidforadjust}
                                                </TableCell>
                                              ) : (
                                                ""
                                              )}
                                              <TableCell className="text-center">
                                                {row.name}
                                              </TableCell>
                                              {this.state.sodata.taxtype ===
                                              "NA" ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.hsn}
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.price}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {currency ===
                                              this.state.sodata.currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.price *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {currency ===
                                              this.state.sodata.currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.pricevariance}
                                                </TableCell>
                                              )}
                                              <TableCell className="text-center">
                                                {row.salesenum}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.quantity}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.pending}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.uom}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.discountamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.discount}%)
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.discountamount *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  &nbsp;({row.discount}%)
                                                </TableCell>
                                              )}
                                              {/* <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={row.insuranceamount}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              &nbsp;({row.insurance}%)
                                            </TableCell>
                                            {this.state.sodata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    row.insuranceamount *
                                                    this.state.sodata
                                                      .curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.insurance}%)
                                              </TableCell>
                                            )} */}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.totalprice}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.totalprice *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {this.state.sodata.taxtype ===
                                              "NA" ? (
                                                ""
                                              ) : (
                                                <>
                                                  {this.state.sodata.taxtype ===
                                                    "GST" &&
                                                  this.state.sodata.gsttype ===
                                                    "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount * 0.5
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax / 2}%)
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount / 2
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax / 2}%)
                                                      </TableCell>
                                                      {this.state.sodata
                                                        .currency ===
                                                      currency ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <TableCell className="text-right">
                                                            <NumericFormat
                                                              displayType={
                                                                "text"
                                                              }
                                                              value={
                                                                (row.taxamount /
                                                                  2) *
                                                                this.state
                                                                  .sodata
                                                                  .curconversionrate
                                                              }
                                                              decimalScale={2}
                                                              fixedDecimalScale={
                                                                true
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                true
                                                              }
                                                              thousandsGroupStyle={
                                                                thousandsdisplay
                                                              }
                                                            ></NumericFormat>
                                                            &nbsp;({row.tax / 2}
                                                            %)
                                                          </TableCell>
                                                          <TableCell className="text-right">
                                                            <NumericFormat
                                                              displayType={
                                                                "text"
                                                              }
                                                              value={
                                                                (row.taxamount /
                                                                  2) *
                                                                this.state
                                                                  .sodata
                                                                  .curconversionrate
                                                              }
                                                              decimalScale={2}
                                                              fixedDecimalScale={
                                                                true
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                true
                                                              }
                                                              thousandsGroupStyle={
                                                                thousandsdisplay
                                                              }
                                                            ></NumericFormat>
                                                            &nbsp;({row.tax / 2}
                                                            %)
                                                          </TableCell>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={row.taxamount}
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax}%)
                                                      </TableCell>
                                                      {this.state.sodata
                                                        .currency ===
                                                      currency ? (
                                                        ""
                                                      ) : (
                                                        <TableCell className="text-right">
                                                          <NumericFormat
                                                            displayType={"text"}
                                                            value={
                                                              row.taxamount *
                                                              this.state.sodata
                                                                .curconversionrate
                                                            }
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                              true
                                                            }
                                                            disabled={true}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            thousandsGroupStyle={
                                                              thousandsdisplay
                                                            }
                                                          ></NumericFormat>
                                                          &nbsp;({row.tax}%)
                                                        </TableCell>
                                                      )}
                                                    </>
                                                  )}
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.totalpricewtax}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.totalpricewtax *
                                                          this.state.sodata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                            </TableRow>
                                            <TableRow>
                                              <TableCell
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingTop: 0,
                                                }}
                                                colSpan={6}
                                              >
                                                <Collapse
                                                  in={this.state.open}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <Box margin={1}>
                                                    <Table
                                                      size="small"
                                                      aria-label="purchases"
                                                    >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center">
                                                            Brand
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Colour
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Size
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Thickness
                                                          </TableCell>
                                                          {/* <TableCell align="center">UOM</TableCell> */}
                                                          <TableCell align="center">
                                                            TotalMtrRoll
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Description
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        <TableRow key={index}>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                          >
                                                            {row.brand}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.colour}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.size}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.thickness}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.totalmtrroll}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.description}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </Box>
                                                </Collapse>
                                              </TableCell>
                                            </TableRow>
                                          </React.Fragment>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                ""
                              )}
                              {this.state.serviceList === "" ||
                              this.state.serviceList.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  {this.state.addservice === true ? (
                                    <TableContainer>
                                      <Typography>Services</Typography>
                                      <Table className="table-sm">
                                        <TableHead>
                                          <TableRow
                                            style={{ textAlign: "center" }}
                                          >
                                            <TableCell className="text-center">
                                              Sl. No.
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Service
                                            </TableCell>
                                            <TableCell className="text-center">
                                              HSN/SAC Code
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Price
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Tax (%)
                                            </TableCell>
                                            <TableCell className="text-center">
                                              TotalPrice
                                            </TableCell>
                                            {this.state.serviceList
                                              .map((e) => e.contracttype)
                                              .toLocaleString() ===
                                            "Recurring" ? (
                                              <TableCell className="text-center">
                                                Recurring Type
                                              </TableCell>
                                            ) : (
                                              ""
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody className="text-sm">
                                          {this.state.serviceList.map(
                                            (data, index) => (
                                              <TableRow
                                                key={index}
                                                className="text-center"
                                              >
                                                <TableCell className="text-center">
                                                  {index + 1}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {data.service}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {data.servicecode}
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={data.serviceprice}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {data.servicetax} %
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={
                                                      data.servicetotalwtax
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                {this.state.serviceList
                                                  .map((e) => e.contracttype)
                                                  .toLocaleString() ===
                                                "Recurring" ? (
                                                  <TableCell className="text-center">
                                                    {this.state.serviceList.map(
                                                      (e) => e.recurringtype
                                                    )}
                                                  </TableCell>
                                                ) : (
                                                  ""
                                                )}
                                              </TableRow>
                                            )
                                          )}
                                          <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell className="text-center">
                                              Total
                                            </TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.sodata
                                                    .grandservicetotal
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                            {this.state.serviceList
                                              .map((e) => e.contracttype)
                                              .toLocaleString() ===
                                            "Recurring" ? (
                                              <TableCell></TableCell>
                                            ) : (
                                              ""
                                            )}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.sotype === 1 ||
                              this.state.sotype === 2 ? (
                                <>
                                  <TableContainer>
                                    <Typography>Services</Typography>
                                    <Table className="table-sm">
                                      <TableHead className="text-sm">
                                        <TableRow
                                          style={{ textAlign: "center" }}
                                        >
                                          {/* <TableCell className="text-center">Sl. No.</TableCell> */}
                                          <TableCell className="text-center">
                                            Service
                                          </TableCell>
                                          <TableCell className="text-center">
                                            HSN/SAC Code
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Price
                                          </TableCell>
                                          {this.state.sodata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.sodata.taxtype ===
                                            "GST" ? (
                                            ccountry === "India" ? (
                                              this.state.sodata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST (%)
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST (%)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center">
                                                  IGST (%)
                                                </TableCell>
                                              )
                                            ) : (
                                              <TableCell className="text-center">
                                                GST (%)
                                              </TableCell>
                                            )
                                          ) : (
                                            <TableCell className="text-center">
                                              VAT (%)
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            TotalPrice
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              TotalPrice(
                                              {this.state.sodata.currency})
                                            </TableCell>
                                          )}
                                          {this.state.serviceList
                                            .map((e) => e.contracttype)
                                            .toLocaleString() ===
                                          "Recurring" ? (
                                            <TableCell className="text-center">
                                              Recurring Type
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody className="text-sm">
                                        {this.state.serviceList.map(
                                          (data, index) => (
                                            <TableRow
                                              key={index}
                                              className="text-center"
                                            >
                                              {/* <TableCell className="text-center">{index + 1}</TableCell> */}
                                              <TableCell className="text-center">
                                                {data.service}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.servicecode}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    data.serviceprice ===
                                                    undefined
                                                      ? data.servicecost
                                                      : data.serviceprice
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.sodata.taxtype ===
                                              "NA" ? (
                                                ""
                                              ) : this.state.sodata.taxtype ===
                                                  "GST" &&
                                                ccountry === "India" &&
                                                this.state.sodata.gsttype ===
                                                  "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    {data.servicetax / 2} %
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {data.servicetax / 2} %
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center">
                                                  {data.servicetax} %
                                                </TableCell>
                                              )}

                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.servicetotalwtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      data.servicetotalwtax *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {this.state.serviceList
                                                .map((e) => e.contracttype)
                                                .toLocaleString() ===
                                              "Recurring" ? (
                                                <TableCell className="text-center">
                                                  {this.state.serviceList.map(
                                                    (e) => e.recurringtype
                                                  )}
                                                </TableCell>
                                              ) : (
                                                ""
                                              )}
                                            </TableRow>
                                          )
                                        )}
                                        <TableRow>
                                          <TableCell className="text-center">
                                            Total
                                          </TableCell>
                                          <TableCell />
                                          <TableCell />
                                          {this.state.sodata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : this.state.sodata.taxtype ===
                                              "GST" &&
                                            ccountry === "India" &&
                                            this.state.sodata.gsttype ===
                                              "INTRASTATE" ? (
                                            <>
                                              <TableCell />
                                              <TableCell />
                                            </>
                                          ) : (
                                            <TableCell />
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.sodata
                                                  .grandservicetotal
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.sodata
                                                    .grandservicetotal *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.serviceList
                                            .map((e) => e.contracttype)
                                            .toLocaleString() ===
                                          "Recurring" ? (
                                            <TableCell></TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  {this.state.serviceList
                                    .map((e) => e.contracttype)
                                    .toLocaleString() !== "Recurring" &&
                                  this.state.pagename !==
                                    "New Proforma Invoice" ? (
                                    <>
                                      <TableContainer>
                                        <Typography>Milestones</Typography>
                                        <Table className="table-sm mt-3">
                                          <TableHead className="text-sm">
                                            <TableRow>
                                              <TableCell className="text-center">
                                                Name
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Expected Date
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Percentage
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Amount
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  Amount(
                                                  {this.state.sodata.currency})
                                                </TableCell>
                                              )}
                                              <TableCell className="text-center">
                                                Status
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Actions
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {this.state.milestones.map(
                                              (data, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {data.name}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {moment(
                                                      data.expecteddate
                                                    ).format("DD-MM-YYYY")}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {data.percentage}
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      className="pr-3"
                                                      displayType={"text"}
                                                      value={
                                                        data.totalpricewtax
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          data.totalpricewtax *
                                                          this.state.sodata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                  <TableCell className="text-center">
                                                    {data.servicestatus}
                                                  </TableCell>
                                                  {this.state.completed ===
                                                  "" ? (
                                                    <TableCell className="text-center">
                                                      {
                                                        <span
                                                          as="button"
                                                          className="cursorPointer"
                                                          onClick={() =>
                                                            this.handlemilestonestatus(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Pending (
                                                          <i className="fa fa-check text-green text-sm" />
                                                          )
                                                        </span>
                                                      }
                                                    </TableCell>
                                                  ) : (
                                                    <TableCell></TableCell>
                                                  )}
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>

                                      {this.state.completed === "" ? (
                                        ""
                                      ) : (
                                        <>
                                          <TableContainer>
                                            <Table className="table-sm mt-3">
                                              <TableHead>Completed</TableHead>
                                              <TableHead className="text-sm">
                                                <TableRow
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <TableCell className="text-center">
                                                    Name
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Expected Date
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Percentage
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Amount
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      Amount(
                                                      {
                                                        this.state.sodata
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                  {this.state.pagename ===
                                                  "New Proforma Invoice" ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      Status
                                                    </TableCell>
                                                  )}
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {/* {this.state.completed.map((data,index) => (  */}
                                                <TableRow>
                                                  <TableCell className="text-center">
                                                    {this.state.completed.name}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {moment(
                                                      this.state.completed
                                                        .expecteddate
                                                    ).format("DD-MM-YYYY")}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {
                                                      this.state.completed
                                                        .percentage
                                                    }
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      className="pr-3"
                                                      displayType={"text"}
                                                      value={
                                                        this.state.completed
                                                          .totalpricewtax
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          this.state.completed
                                                            .totalpricewtax *
                                                          this.state.sodata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                  {this.state.pagename ===
                                                  "New Proforma Invoice" ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      {
                                                        this.state.completed
                                                          .servicestatus
                                                      }
                                                    </TableCell>
                                                  )}
                                                </TableRow>
                                                {/* ))}  */}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {this.state.show === 0 ? (
                            ""
                          ) : (
                            <>
                              <div className="form-group col-md-12" />
                              <div className="form-group col-md-12">
                                <u>
                                  <h5>Add to List</h5>
                                </u>
                              </div>

                              <fieldset className="form-group col-md-3 m-0">
                                <span className="text-dark">Item Name</span>
                                <>&nbsp;:&nbsp;{this.state.itemname}</>
                              </fieldset>
                              <fieldset className="form-group col-md-3 m-0">
                                <span>
                                  {`Remaining Quantity : ${
                                    this.state.pendingquantity -
                                    this.state.quantity
                                  }`}
                                </span>
                              </fieldset>
                              <fieldset className="form-group col-md-3 m-0">
                                <span>
                                  {`Inventory Stock : ${this.state.itemstock}`}
                                </span>
                              </fieldset>
                              <fieldset className="form-group col-md-12 m-0" />
                              {this.state.einvoice &&
                              (this.state.iscomposite === false ||
                                this.state.accepteditems.length !== 0) ? (
                                <fieldset className="form-group col-md-12 m-0">
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.iscomposite}
                                          onChange={() =>
                                            this.setState({
                                              iscomposite:
                                                !this.state.iscomposite,
                                            })
                                          }
                                          name="iscomposite"
                                          color="primary"
                                        />
                                      }
                                      label={`Is Composite Item`}
                                    />
                                  </FormGroup>
                                </fieldset>
                              ) : (
                                ""
                              )}
                              <fieldset className="form-group col-md-12 m-0">
                                {this.state.iscomposite ? (
                                  <span className="text-sm text-red">
                                    Note: Reverse GST will be applicable on this
                                    invoice
                                  </span>
                                ) : (
                                  ""
                                )}
                              </fieldset>

                              {this.state.pagename ===
                              "New Proforma Invoice" ? (
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    id="quantity"
                                    className="form-control"
                                    name="quantity"
                                    type="text"
                                    label="Quantity"
                                    // InputProps={{ inputProps: { min: 0} }}
                                    value={this.state.quantity}
                                    onChange={(e) =>
                                      this.setState({
                                        quantity: e.target.value,
                                      })
                                    }
                                    variant="standard"
                                  />
                                </fieldset>
                              ) : (
                                <>
                                  <fieldset className="form-group col-md-3">
                                    <FormikTextField
                                      id="quantity"
                                      type="text"
                                      className="form-control"
                                      name="quantity"
                                      label="Quantity"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      value={quantity}
                                      onChange={(e) =>
                                        this.setState({
                                          quantity: e.target.value,
                                        })
                                      }
                                      // onClick={this.handleSelect}
                                      // disabled
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-12" />
                                </>
                              )}
                              <fieldset className="form-group col-md-12 m-0" />
                              <button
                                className="btn btn-sm hovbuttonColour"
                                type="submit"
                              >
                                Add Item
                              </button>
                            </>
                          )}
                        </>
                      )}

                      {this.state.accepteditems.length === 0 ? (
                        ""
                      ) : (
                        <>
                          <TableContainer className="mt-5">
                            <Typography>Invoice Items / Products</Typography>
                            <Table
                              aria-label="collapsible table"
                              className="table-sm"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell className="text-center">
                                    Item Name
                                  </TableCell>
                                  {this.state.taxtype === "NA" ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      HSN
                                    </TableCell>
                                  )}
                                  <TableCell className="text-center">
                                    Price
                                  </TableCell>
                                  {currency === this.state.currency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Price({this.state.currency})
                                    </TableCell>
                                  )}
                                  {currency === this.state.currency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Price Variance (%)
                                    </TableCell>
                                  )}
                                  <TableCell className="text-center">
                                    Type
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    UOM
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Discount
                                  </TableCell>
                                  {this.state.currency === currency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Discount ({this.state.currency})
                                    </TableCell>
                                  )}
                                  {/* <TableCell className="text-center">
                                        Insurance
                                      </TableCell>
                                      {this.state.sodata.currency ===
                                      currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Insurance (
                                          {this.state.sodata.currency})
                                        </TableCell>
                                      )} */}
                                  <TableCell className="text-center">
                                    Total
                                  </TableCell>
                                  {this.state.currency === currency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Total ({this.state.currency})
                                    </TableCell>
                                  )}
                                  {this.state.taxtype === "NA" ? (
                                    ""
                                  ) : (
                                    <>
                                      {this.state.taxtype === "GST" &&
                                      ccountry === "India" ? (
                                        this.state.gsttype === "INTRASTATE" ? (
                                          <>
                                            <TableCell className="text-center">
                                              CGST
                                            </TableCell>
                                            <TableCell className="text-center">
                                              SGST
                                            </TableCell>
                                            {this.state.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <>
                                                <TableCell className="text-center">
                                                  CGST(
                                                  {this.state.sodata.currency})
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  SGST(
                                                  {this.state.sodata.currency})
                                                </TableCell>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell className="text-center">
                                              IGST
                                            </TableCell>
                                            {this.state.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                IGST(
                                                {this.state.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )
                                      ) : this.state.taxtype === "GST" ? (
                                        <>
                                          <TableCell className="text-center">
                                            GST
                                          </TableCell>
                                          {this.state.currency === currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              GST(
                                              {this.state.currency})
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <TableCell className="text-center">
                                            VAT
                                          </TableCell>
                                          {this.state.currency === currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              VAT(
                                              {this.state.currency})
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                      <TableCell className="text-center">
                                        Total Price
                                      </TableCell>
                                      {this.state.currency === currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Total Price(
                                          {this.state.currency})
                                        </TableCell>
                                      )}
                                    </>
                                  )}
                                  {this.state.invtype === "Direct Invoice" ? (
                                    <TableCell className="text-center">
                                      Action
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.accepteditems.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <TableRow>
                                      <TableCell>
                                        <IconButton
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() =>
                                            this.setState({
                                              open: !this.state.open,
                                            })
                                          }
                                        >
                                          {this.state.open ? (
                                            <KeyboardArrowUpIcon />
                                          ) : (
                                            <KeyboardArrowDownIcon />
                                          )}
                                        </IconButton>
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {row.name}
                                      </TableCell>
                                      {this.state.taxtype === "NA" ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          {row.hsn}
                                        </TableCell>
                                      )}
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          value={row.price}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      {currency === this.state.currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              row.price *
                                              this.state.sodata
                                                .curconversionrate
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      )}
                                      {currency === this.state.currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          {row.pricevariance}
                                        </TableCell>
                                      )}
                                      <TableCell className="text-center">
                                        {row.salesenum}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {parseFloat(row.quantity)}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {row.uom}
                                      </TableCell>
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          value={row.discountamount}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                        &nbsp;({row.discount}%)
                                      </TableCell>
                                      {this.state.currency === currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              row.discountamount *
                                              this.state.sodata
                                                .curconversionrate
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                          &nbsp;({row.discount}%)
                                        </TableCell>
                                      )}

                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          value={row.totalprice}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      {this.state.currency === currency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              row.totalprice *
                                              this.state.sodata
                                                .curconversionrate
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      )}
                                      {this.state.taxtype === "NA" ? (
                                        ""
                                      ) : (
                                        <>
                                          {this.state.taxtype === "GST" &&
                                          this.state.gsttype ===
                                            "INTRASTATE" ? (
                                            <>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.taxamount * 0.5}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.tax / 2}%)
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.taxamount / 2}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.tax / 2}%)
                                              </TableCell>
                                              {this.state.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (row.taxamount / 2) *
                                                        this.state.sodata
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    &nbsp;({row.tax / 2}%)
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (row.taxamount / 2) *
                                                        this.state.sodata
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    &nbsp;({row.tax / 2}%)
                                                  </TableCell>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.taxamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.tax}%)
                                              </TableCell>
                                              {this.state.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.taxamount *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  &nbsp;({row.tax}%)
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.totalpricewtax}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.currency === currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.totalpricewtax *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                      {this.state.invtype ===
                                      "Direct Invoice" ? (
                                        <TableCell className="text-center">
                                          <i
                                            className="fa fa-trash text-danger cursorPointer"
                                            onClick={() =>
                                              this.removeItemFromList(index)
                                            }
                                          />
                                        </TableCell>
                                      ) : (
                                        ""
                                      )}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                        colSpan={6}
                                      >
                                        <Collapse
                                          in={this.state.open}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Box margin={1}>
                                            <Table
                                              size="small"
                                              aria-label="purchases"
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Brand
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Colour
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Size
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Thickness
                                                  </TableCell>
                                                  {/* <TableCell align="center">UOM</TableCell> */}
                                                  <TableCell align="center">
                                                    TotalMtrRoll
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Description
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    {row.brand}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {row.colour}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {row.size}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {row.thickness}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {row.totalmtrroll}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {row.description}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <fieldset className="form-group col-md-12" />
                          {this.state.pagename === "New Proforma Invoice" ? (
                            ""
                          ) : (
                            <>
                              {(this.state.openingbalance !== null &&
                              this.state.openingbalance !== undefined &&
                              this.state.openingbalance > 0) || (this.state.adjustopen && this.state.invid!==-1) ? (
                                <>
                                  <fieldset className="form-group col-md-12 m-0">
                                    <FormGroup row>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state.adjustopen}
                                            onChange={() =>
                                              this.setState({
                                                adjustopen:
                                                  !this.state.adjustopen,
                                              })
                                            }
                                            name="adjustopen"
                                            color="primary"
                                          />
                                        }
                                        className="text-info"
                                        // disabled={
                                        //   this.state.openingbalance >
                                        //   this.state.totalinvoice
                                        // }
                                        label={`Adjust From Opening Balance (${this.state.openingbalance})`}
                                      />
                                    </FormGroup>
                                  </fieldset>
                                </>
                              ) : (
                                ""
                              )}

                              {(this.state.debitamount !== null &&
                              this.state.debitamount !== undefined &&
                              this.state.debitamount > 0) || (this.state.adjustdebit && this.state.invid!==-1) ? (
                                <>
                                  <fieldset className="form-group col-md-12 m-0">
                                    <FormGroup row>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state.adjustdebit}
                                            onChange={() =>
                                              this.setState({
                                                adjustdebit:
                                                  !this.state.adjustdebit,
                                              })
                                            }
                                            name="adjustdebit"
                                            color="primary"
                                          />
                                        }
                                        className="text-info"
                                        label={`Adjust From Credit Amount (${this.state.debitamount})`}
                                      />
                                    </FormGroup>
                                  </fieldset>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          {this.state.pagename === "New Proforma Invoice" ? (
                            ""
                          ) : (
                            <>
                              <div className="form-group col-md-12 m-0">
                                <Accordion
                                  expanded={this.state.addchraccordian}
                                  onChange={() =>
                                    this.setState({
                                      addchraccordian:
                                        !this.state.addchraccordian,
                                    })
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "#e8ecef" }}
                                  >
                                    <Typography>
                                      Additional charges{ccountry==="India"?" & TCS":""}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="row">
                                      <fieldset className="form-group col-md-3 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  this.state.insurancereq
                                                }
                                                onChange={
                                                  this.handleinsurancereq
                                                }
                                                name="ordertype"
                                                color="primary"
                                              />
                                            }
                                            label="Insurance Required?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      {this.state.insurancereq === true ? (
                                        <>
                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              id="insurance"
                                              name="insurance"
                                              type="number"
                                              label={`Insurance (%)`}
                                              InputProps={{
                                                inputProps: { min: 0 },
                                              }}
                                              value={this.state.insurance}
                                              onChange={
                                                this.handleInsuranceChange
                                              }
                                              variant="standard"
                                              fullWidth
                                              onFocus={this.oninsurancefocus}
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              id="insuranceamount"
                                              type="number"
                                              name="insuranceamount"
                                              label={`Insurance Amount`}
                                              value={this.state.insuranceamount}
                                              variant="standard"
                                              fullWidth
                                              onChange={
                                                this.handleInsuranceAmount
                                              }
                                              onFocus={this.oninsurancefocus}
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              id="insurance tax"
                                              type="number"
                                              name="insurance tax"
                                              label={`Insurance Tax(%)`}
                                              value={this.state.insurancetax}
                                              variant="standard"
                                              fullWidth
                                              onChange={this.handleInsuranceTax}
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              type="number"
                                              name="totalinsuranceamount"
                                              label={`Total Insurance Amount`}
                                              value={
                                                this.state.totalinsuranceamount
                                              }
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <hr />

                                    {this.state.additionalchargeslist.length >
                                    3 ? (
                                      ""
                                    ) : (
                                      <>
                                        <fieldset className="form-row">
                                          <fieldset className="form-group col-md-3">
                                            <TextField
                                              className="form-control"
                                              id="natureofcharge"
                                              name="natureofcharge"
                                              type="text"
                                              label={`Nature/Type of Charges`}
                                              value={this.state.natureofcharge}
                                              variant="standard"
                                              fullWidth
                                              onChange={(e) =>
                                                this.setState({
                                                  natureofcharge:
                                                    e.target.value,
                                                })
                                              }
                                              error={
                                                this.state.natureofcharge ===
                                                  "" && this.state.adderr
                                              }
                                              helperText={
                                                this.state.natureofcharge ===
                                                  "" && this.state.adderr
                                                  ? "Please enter natureofcharge"
                                                  : ""
                                              }
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              id="additionalcharges"
                                              name="additionalcharges"
                                              type="number"
                                              label={`Additional Charges`}
                                              InputProps={{
                                                inputProps: { min: 0 },
                                              }}
                                              value={
                                                this.state.additionalcharges
                                              }
                                              variant="standard"
                                              fullWidth
                                              onChange={(e) =>
                                                this.setState({
                                                  additionalcharges: parseFloat(
                                                    e.target.value
                                                  ),
                                                  totaladditionalcharges: (
                                                    (parseFloat(
                                                      e.target.value
                                                    ) *
                                                      this.state
                                                        .additionalchargestax) /
                                                      100 +
                                                    parseFloat(e.target.value)
                                                  ).toFixed(3),
                                                  additionalchargestaxamount:
                                                    (parseFloat(
                                                      e.target.value
                                                    ) *
                                                      this.state
                                                        .additionalchargestax) /
                                                    100,
                                                })
                                              }
                                              error={
                                                this.state.additionalcharges ===
                                                  "" && this.state.adderr
                                              }
                                              helperText={
                                                this.state.additionalcharges ===
                                                  "" && this.state.adderr
                                                  ? "Please enter amount"
                                                  : ""
                                              }
                                            />
                                          </fieldset>

                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              name="additionalchargestax"
                                              type="number"
                                              InputProps={{
                                                inputProps: { min: 0 },
                                              }}
                                              label={`Additional Charges Tax (%)`}
                                              value={
                                                this.state.additionalchargestax
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  additionalchargestax:
                                                    parseFloat(e.target.value),
                                                  additionalchargestaxamount:
                                                    (this.state
                                                      .additionalcharges *
                                                      parseFloat(
                                                        e.target.value
                                                      )) /
                                                    100,
                                                  totaladditionalcharges: (
                                                    (this.state
                                                      .additionalcharges *
                                                      parseFloat(
                                                        e.target.value
                                                      )) /
                                                      100 +
                                                    parseFloat(
                                                      this.state
                                                        .additionalcharges
                                                    )
                                                  ).toFixed(3),
                                                })
                                              }
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>

                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              id="toataladditionalcharges"
                                              name="totaladditionalcharges"
                                              type="number"
                                              label={`Total Additional Charges`}
                                              value={
                                                this.state
                                                  .totaladditionalcharges
                                              }
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-2">
                                            <span
                                              className="btn btn-sm hovbuttonColour mt-2"
                                              onClick={this.AdditionalCharges}
                                            >
                                              <i className="fas fa-plus"></i>{" "}
                                              Add
                                            </span>
                                          </fieldset>
                                        </fieldset>
                                      </>
                                    )}
                                    {this.state.additionalchargeslist.length >
                                    0 ? (
                                      <TableContainer className="my-2">
                                        <Typography>
                                          Additional Charges
                                        </Typography>
                                        <Table className="table-sm border">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>
                                                Nature of charge
                                              </TableCell>
                                              <TableCell>
                                                Additional charge
                                              </TableCell>
                                              <TableCell>Tax</TableCell>
                                              <TableCell>Tax amount</TableCell>
                                              <TableCell>
                                                Total Additional charge
                                              </TableCell>
                                              <TableCell>Remove</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {this.state.additionalchargeslist.map(
                                              (e, i) => (
                                                <TableRow key={i}>
                                                  <TableCell>
                                                    {e.natureofcharge}
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        e.additionalcharges
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell>
                                                    {e.additionalchargestax}
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        e.additionalchargestaxamount
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        e.totaladditionalcharges
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell
                                                    onClick={() =>
                                                      this.RemoveAdditionalCharges(
                                                        i
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-times text-danger"></i>
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                            <TableRow>
                                              <TableCell>Total</TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state
                                                      .invoiceadditionalcharges -
                                                    this.state
                                                      .invoiceadditionalchargestax
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              <TableCell></TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state
                                                      .invoiceadditionalchargestax
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state
                                                      .invoiceadditionalcharges
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    ) : (
                                      ""
                                    )}
                                    <hr />
                                  { ccountry==="India"?
                                    <div className="row">
                                      <fieldset className="col-md-3 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.tcsreq}
                                                onChange={this.handletcsreq}
                                                name="ordertype"
                                                color="primary"
                                              />
                                            }
                                            label="TCS Required?"
                                          />
                                        </FormGroup>
                                      </fieldset>

                                      {this.state.tcsreq === true ? (
                                        <>
                                          <fieldset className="form-group col-md-3">
                                            <TextField
                                              className="form-control"
                                              id="tcsableamount"
                                              name="tcsableamount"
                                              type="number"
                                              label={`TCS on Amount`}
                                              value={this.state.tcsableamount.toFixed(2)}
                                              variant="standard"
                                              fullWidth
                                              onChange={(e) =>
                                                this.setState({
                                                  tcsableamount: e.target.value,
                                                  tcsamount: (
                                                    (this.state.tcs *
                                                      e.target.value) /
                                                    100
                                                  ).toFixed(2),
                                                })
                                              }
                                            />
                                          </fieldset>

                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              name="tcs"
                                              type="number"
                                              onChange={this.handletcsChange}
                                              label={`TCS (%)`}
                                              value={this.state.tcs}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-2">
                                            <TextField
                                              className="form-control"
                                              type="number"
                                              name="tcsamount"
                                              label="TCS Amount"
                                              value={this.state.tcsamount}
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />
                                          </fieldset>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>:""}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </>
                          )}

                          {(this.state.accepteditems.length === 0 &&
                            this.state.serviceList.length === 0) ||
                          this.state.pagename === "New Proforma Invoice" ? (
                            ""
                          ) : (
                            <div className="col-12 my-3">
                              <Accordion
                                expanded={this.state.dlryaccordian}
                                onChange={() =>
                                  this.setState({
                                    dlryaccordian: !this.state.dlryaccordian,
                                  })
                                }
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{ backgroundColor: "#e8ecef" }}
                                >
                                  <Typography>
                                    Delivery address details
                                    {this.state.err ? (
                                      <span className="tetx-red">
                                        - Fill all the mandatory fields
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {this.state.einvoice ? (
                                    <div className="row m-0">
                                      <fieldset className="form-group col-md-3 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  this.state.samedaydelivery
                                                }
                                                onChange={() =>
                                                  this.setState({
                                                    samedaydelivery:
                                                      !this.state
                                                        .samedaydelivery,
                                                  })
                                                }
                                                name="ordertype"
                                                color="primary"
                                              />
                                            }
                                            label="Same day delivery"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="row m-0">
                                    <fieldset
                                      className={`form-group col-md-3 shadow-sm  text-center btn ${
                                        this.state.addtype ===
                                        "Dispatch details"
                                          ? "borderedbutton2"
                                          : " hovbuttonColour"
                                      }`}
                                      disabled={
                                        this.state.addtype ===
                                        "Dispatch details"
                                      }
                                      onClick={() =>
                                        this.setState({
                                          addtype: "Dispatch details",
                                        })
                                      }
                                    >
                                      Dispatch details
                                    </fieldset>
                                    <fieldset
                                      className={`form-group col-md-3  btn shadow-sm text-center ${
                                        this.state.addtype === "Ship to details"
                                          ? "borderedbutton2"
                                          : " hovbuttonColour"
                                      }`}
                                      disabled={
                                        this.state.addtype === "Ship to details"
                                      }
                                      onClick={() =>
                                        this.setState({
                                          addtype: "Ship to details",
                                        })
                                      }
                                    >
                                      Ship to details
                                    </fieldset>
                                  </div>

                                  {this.state.addtype === "Dispatch details" ? (
                                    <>
                                      <fieldset className="form-group col-md-3 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  this.state.defaultdisadd
                                                }
                                                onChange={
                                                  this.handleDefaultdisadd
                                                }
                                                name="ordertype"
                                                color="primary"
                                              />
                                            }
                                            label="Default address"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      <div className="row">
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="text"
                                            name="disname"
                                            label="Full Name"
                                            value={this.state.disname}
                                            onChange={(e) =>
                                              this.setState({
                                                disname: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              this.state.disname === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.disname === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>

                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="text"
                                            name="disphoneno"
                                            label="Phone Number"
                                            value={this.state.disphoneno}
                                            onChange={(e) =>
                                              this.setState({
                                                disphoneno: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              this.state.disphoneno === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.disphoneno === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="number"
                                            name="dispincode"
                                            label="disPincode"
                                            value={this.state.dispincode}
                                            onChange={(e) => {
                                              this.setState({
                                                dispincode: e.target.value,
                                                err: false,
                                              });
                                              MasterService.getDistance(
                                                e.target.value,
                                                this.state.pincode
                                              ).then((res) =>
                                                this.setState({
                                                  distance: res.data,
                                                })
                                              );
                                            }}
                                            error={
                                              this.state.dispincode === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.dispincode === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>

                                        {this.state.defaultdisadd ? (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="discountry"
                                                label="Country"
                                                value={this.state.discountry}
                                                variant="standard"
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="disstate"
                                                label="State"
                                                value={this.state.disstate}
                                                variant="standard"
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="discity"
                                                label="City"
                                                value={this.state.discity}
                                                variant="standard"
                                              />
                                            </fieldset>
                                          </>
                                        ) : (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="discountry"
                                                options={this.state.CountryData}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                getOptionSelected={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.discountry
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={
                                                  this.handleDisCountryChange
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="discountry"
                                                    value={
                                                      this.state.discountry
                                                    }
                                                    label={`Select Country (${this.state.discountry})`}
                                                    variant="standard"
                                                    error={
                                                      this.state.discountry ===
                                                        "" && this.state.err
                                                    }
                                                    helperText={
                                                      this.state.discountry ===
                                                        "" && this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="disstate"
                                                options={this.state.disStatesData}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                getOptionSelected={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.disstate
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={
                                                  this.handleDisStateChange
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="disstate"
                                                    value={this.state.disstate}
                                                    label={`Select State (${this.state.disstate})`}
                                                    variant="standard"
                                                    error={
                                                      this.state.disstate ===
                                                        "" && this.state.err
                                                    }
                                                    helperText={
                                                      this.state.disstate ===
                                                        "" && this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>

                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="discity"
                                                options={this.state.disCitiesData}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                getOptionSelected={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.discity
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={this.handleDisCityChange}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="discity"
                                                    value={this.state.discity}
                                                    label={`Select City (${this.state.discity})`}
                                                    variant="standard"
                                                    error={
                                                      this.state.discity ===
                                                        "" && this.state.err
                                                    }
                                                    helperText={
                                                      this.state.discity ===
                                                        "" && this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                          </>
                                        )}
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            name="dishouseno"
                                            label="Address"
                                            value={this.state.dishouseno}
                                            onChange={(e) =>
                                              this.setState({
                                                dishouseno: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              (this.state.dishouseno === "" || this.state.dishouseno.length>100 )&&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.dishouseno === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : "Max 100 characters"
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            name="roadname"
                                            label="Roadname/Area/Colony"
                                            value={this.state.disareaname}
                                            onChange={(e) =>
                                              this.setState({
                                                disareaname: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              (this.state.disareaname === "" || this.state.disareaname.length>100)&&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.disareaname === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : "Max 100 characters"
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <fieldset className="form-group col-md-3 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.defaultadd}
                                                onChange={this.handleDefaultadd}
                                                name="ordertype"
                                                color="primary"
                                              />
                                            }
                                            label="Same as billing address"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      <div className="row">
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            label="Full Name"
                                            value={this.state.name}
                                            onChange={(e) =>
                                              this.setState({
                                                name: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              this.state.name === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.name === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>

                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="text"
                                            name="phoneno"
                                            label="Phone Number"
                                            value={this.state.phoneno}
                                            onChange={(e) =>
                                              this.setState({
                                                phoneno: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              this.state.phoneno === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.phoneno === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="number"
                                            name="pincode"
                                            label="Pincode"
                                            value={this.state.pincode}
                                            onChange={(e) => {
                                              this.setState({
                                                pincode: e.target.value,
                                                err: false,
                                              });
                                              MasterService.getDistance(
                                                this.state.dispincode,
                                                e.target.value
                                              ).then((res) =>
                                                this.setState({
                                                  distance: res.data,
                                                })
                                              );
                                            }}
                                            error={
                                              this.state.pincode === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.pincode === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>

                                        {this.state.defaultadd ? (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="country"
                                                label="Country"
                                                value={this.state.country}
                                                variant="standard"
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="state"
                                                label="State"
                                                value={this.state.state}
                                                variant="standard"
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="city"
                                                label="City"
                                                value={this.state.city}
                                                variant="standard"
                                              />
                                            </fieldset>
                                          </>
                                        ) : (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="country"
                                                options={this.state.CountryData}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                getOptionSelected={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.country
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={
                                                  this.handleCountryChange
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="country"
                                                    value={this.state.country}
                                                    label={`Select Country (${this.state.country})`}
                                                    variant="standard"
                                                    error={
                                                      this.state.country ===
                                                        "" && this.state.err
                                                    }
                                                    helperText={
                                                      this.state.country ===
                                                        "" && this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="state"
                                                options={this.state.StatesData}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                getOptionSelected={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.state
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={
                                                  this.handleStateChange
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="state"
                                                    value={this.state.state}
                                                    label={`Select State (${this.state.state})`}
                                                    variant="standard"
                                                    error={
                                                      this.state.state === "" &&
                                                      this.state.err
                                                    }
                                                    helperText={
                                                      this.state.state === "" &&
                                                      this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>

                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="city"
                                                options={this.state.CitiesData}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                getOptionSelected={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.city
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={this.handleCityChange}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="city"
                                                    value={this.state.city}
                                                    label={`Select City (${this.state.city})`}
                                                    variant="standard"
                                                    error={
                                                      this.state.city === "" &&
                                                      this.state.err
                                                    }
                                                    helperText={
                                                      this.state.city === "" &&
                                                      this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                          </>
                                        )}
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            name="houseno"
                                            label="Address"
                                            value={this.state.houseno}
                                            onChange={(e) =>
                                              this.setState({
                                                houseno: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              (this.state.houseno === "" || this.state.houseno.length>100) &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.houseno === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : "Max 100 characters"
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            name="roadname"
                                            label="Roadname/Area/Colony"
                                            value={this.state.areaname}
                                            onChange={(e) =>
                                              this.setState({
                                                areaname: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              (this.state.areaname === "" || this.state.areaname.length>100) &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.areaname === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : "Max 100 characters"
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                      </div>
                                    </>
                                  )}
                                  { ccountry==="India" && (this.state.samedaydelivery ||
                                  this.state.einvoice === false) ? (
                                    <>
                                      <fieldset className="col-md-12 mt-1">
                                        <hr className="m-0" />
                                        <label className="text-secondaryHeading m-0 p-1">
                                          Transportation Details :
                                        </label>
                                        <hr className="m-0" />
                                      </fieldset>
                                      <div className="row">
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="text"
                                            name="vehiclenum"
                                            label="Vehicel No."
                                            value={this.state.vehiclenum}
                                            onChange={(e) =>
                                              this.setState({
                                                vehiclenum: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              this.state.vehiclenum === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.vehiclenum === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>

                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="number"
                                            name="distance"
                                            label="Approximate Distance(Km)"
                                            value={this.state.distance}
                                            onChange={(e) =>
                                              this.setState({
                                                distance: e.target.value,
                                                err: false,
                                              })
                                            }
                                            error={
                                              this.state.distance === "" &&
                                              this.state.err
                                            }
                                            helperText={
                                              this.state.distance === "" &&
                                              this.state.err
                                                ? "Empty field!"
                                                : " "
                                            }
                                            variant="standard"
                                          />
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                          <FormControl
                                            variant="standard"
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-standard-label">
                                              Vehicle Type
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={this.state.vehicletype}
                                              onChange={(e) =>
                                                this.setState({
                                                  vehicletype: e.target.value,
                                                })
                                              }
                                              label="Vehicle Type"
                                              fullWidth
                                            >
                                              {this.state.vehicletypes.map(
                                                (e, i) => (
                                                  <MenuItem
                                                    value={e.value}
                                                    key={i}
                                                  >
                                                    {e.name}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        </fieldset>

                                        <fieldset className="form-group col-md-3">
                                          <FormControl
                                            variant="standard"
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-standard-label">
                                              Transaction Types{" "}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={this.state.transactiontype}
                                              defaultValue={
                                                this.state.transactiontype
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  transactiontype:
                                                    e.target.value,
                                                })
                                              }
                                              label="Vehicle Type"
                                              fullWidth
                                            >
                                              <MenuItem value={1}>
                                                Regular
                                              </MenuItem>
                                              <MenuItem value={2}>
                                                Bill To - Ship To
                                              </MenuItem>
                                              <MenuItem value={3}>
                                                Bill From - Dispatch From
                                              </MenuItem>
                                              <MenuItem value={4}>
                                                Combination of 2 and 3
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                          <FormControl
                                            variant="standard"
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-standard-label">
                                              Transportation Mode
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              defaultValue={
                                                this.state.transportationmode
                                              }
                                              value={
                                                this.state.transportationmode
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  transportationmode:
                                                    e.target.value,
                                                })
                                              }
                                              label="Vehicle Type"
                                              fullWidth
                                            >
                                              <MenuItem value={1}>
                                                Road
                                              </MenuItem>
                                              <MenuItem value={2}>
                                                Rail
                                              </MenuItem>
                                              <MenuItem value={3}>Air</MenuItem>
                                              <MenuItem value={4}>
                                                Ship
                                              </MenuItem>
                                              <MenuItem value={5}>
                                                inTransit
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </fieldset>

                                        <fieldset className="form-group col-md-3">
                                          <FormControl
                                            variant="standard"
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-standard-label">
                                              Supply Type{" "}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={this.state.supplytype}
                                              defaultValue={
                                                this.state.supplytype
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  supplytype: e.target.value,
                                                })
                                              }
                                              label="Vehicle Type"
                                              fullWidth
                                            >
                                              <MenuItem value={"I"}>
                                                Inward
                                              </MenuItem>
                                              <MenuItem value={"O"}>
                                                Outward
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </fieldset>
                                        {this.state.einvoice?
                                        <fieldset className="form-group col-md-12 mt-2 m-0">
                                          <FormGroup row>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={
                                                    this.state.transidreq
                                                  }
                                                  onChange={() =>
                                                    this.setState({
                                                      transidreq:
                                                        !this.state.transidreq,
                                                      transporterid: null,
                                                      transportername: null,
                                                    })
                                                  }
                                                  name="transidreq"
                                                  color="primary"
                                                />
                                              }
                                              label="Add transporter details for Part A?"
                                            />
                                          </FormGroup>
                                        </fieldset>
                                        :""}
                                        <div className="col-md-12 m-0" />
                                        {this.state.transidreq ? (
                                          <>
                                            <div className="col-md-12">
                                              <fieldset
                                                className={`form-group col-md-3  btn shadow-sm text-center ${
                                                  this.state
                                                    .transporterselectiontype ===
                                                  false
                                                    ? "borderedbutton2"
                                                    : " hovbuttonColour"
                                                }`}
                                                disabled={
                                                  this.state
                                                    .transporterselectiontype ===
                                                  false
                                                }
                                                onClick={() =>
                                                  this.setState({
                                                    transporterselectiontype: false,
                                                    transportername: "",
                                                    transporterid: "",
                                                  })
                                                }
                                              >
                                                Enter Manually
                                              </fieldset>
                                              <fieldset
                                                className={`form-group col-md-3 shadow-sm  text-center btn ${
                                                  this.state
                                                    .transporterselectiontype ===
                                                  true
                                                    ? "borderedbutton2"
                                                    : " hovbuttonColour"
                                                }`}
                                                disabled={
                                                  this.state
                                                    .transporterselectiontype ===
                                                  true
                                                }
                                                onClick={() =>
                                                  this.setState({
                                                    transporterselectiontype: true,
                                                    transportername: "",
                                                    transporterid: "",
                                                  })
                                                }
                                              >
                                                Select
                                              </fieldset>
                                            </div>

                                            {this.state
                                              .transporterselectiontype ===
                                            true ? (
                                              <>
                                                <fieldset className="form-group col-md-3">
                                                  <Autocomplete
                                                    id="subaccount"
                                                    className="subacc-select"
                                                    name="transportername"
                                                    options={
                                                      this.state
                                                        .transporterslist
                                                    }
                                                    getOptionLabel={(option) =>
                                                      option.transname
                                                    }
                                                    value={
                                                      values.transname
                                                        ? undefined
                                                        : undefined
                                                    }
                                                    onChange={
                                                      this
                                                        .handleTransporterChange
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        className="form-control"
                                                        type="text"
                                                        name="transportername"
                                                        value={
                                                          this.state
                                                            .transportername
                                                        }
                                                        label={`Select Transporter (${this.state.transportername})`}
                                                        variant="standard"
                                                        error={
                                                          this.state
                                                            .transportername ===
                                                            "" && this.state.err
                                                        }
                                                        helperText={
                                                          this.state
                                                            .transportername ===
                                                            "" && this.state.err
                                                            ? "Empty field!"
                                                            : " "
                                                        }
                                                        fullWidth
                                                      />
                                                    )}
                                                  />
                                                </fieldset>
                                                <fieldset className="form-group col-md-3 ">
                                                  <TextField
                                                    className="form-control"
                                                    type="text"
                                                    name="transporterid"
                                                    label={`${
                                                      this.state
                                                        .transporterid === "" ||
                                                      this.state
                                                        .transporterid === null
                                                        ? "Transporter Id"
                                                        : " "
                                                    }`}
                                                    value={
                                                      this.state.transporterid
                                                    }
                                                    variant="standard"
                                                    disabled
                                                  />
                                                </fieldset>
                                              </>
                                            ) : (
                                              <>
                                                <fieldset className="form-group col-md-3">
                                                  <TextField
                                                    className="form-control"
                                                    type="text"
                                                    name="transportername"
                                                    label="Transporter Name"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        transportername:
                                                          e.target.value,
                                                        err: false,
                                                      })
                                                    }
                                                    error={
                                                      this.state
                                                        .transportername ===
                                                        "" && this.state.err
                                                    }
                                                    helperText={
                                                      this.state
                                                        .transportername ===
                                                        "" && this.state.err
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    variant="standard"
                                                  />
                                                </fieldset>
                                                {this.state.einvoice ? (
                                                  <fieldset className="form-group col-md-3 ">
                                                    <TextField
                                                      className="form-control"
                                                      type="text"
                                                      name="transporterid"
                                                      label="Transporter Id"
                                                      onChange={(e) =>
                                                        this.TransporteridChange(
                                                          e
                                                        )
                                                      }
                                                      error={
                                                        (this.state
                                                          .transporterid ===
                                                          "" ||
                                                          this.state
                                                            .validtrnsid ===
                                                            false) &&
                                                        this.state.err
                                                      }
                                                      helperText={
                                                        (this.state
                                                          .transporterid ===
                                                          "" ||
                                                          this.state
                                                            .validtrnsid ===
                                                            false) &&
                                                        this.state.err
                                                          ? "Empty field!"
                                                          : this.state
                                                              .validtrnsid ===
                                                            false
                                                          ? "Invalid Transportor Id"
                                                          : " "
                                                      }
                                                      variant="standard"
                                                    />
                                                  </fieldset>
                                                ) : (
                                                  <fieldset className="form-group col-md-3 ">
                                                    <TextField
                                                      className="form-control"
                                                      type="text"
                                                      name="transporterid"
                                                      label="Transporter Id"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          transporterid:
                                                            e.target.value,
                                                          err: false,
                                                          validtrnsid: true,
                                                        })
                                                      }
                                                      error={
                                                        this.state
                                                          .transporterid === ""
                                                      }
                                                      helperText={
                                                        this.state
                                                          .transporterid === ""
                                                          ? "Empty field!"
                                                          : ""
                                                      }
                                                      variant="standard"
                                                    />
                                                  </fieldset>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </AccordionDetails>
                              </Accordion>
                              
                            </div>

                          )}
                          {einvoice === false && this.state.backdateinvoicestatus?
                          <fieldset className="col-12">
                          <fieldset className="form-group col-md-3 col-sm-12">
                                          <FormGroup row>
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        checked={this.state.SDdate}
                                                        onChange={()=>this.setState({SDdate:!this.state.SDdate})}
                                                        name="ordertype"
                                                        color="primary"
                                                      />
                                                    }
                                                    label="Add Custom Invoice creation date"
                                                  />
                                                </FormGroup>
                        </fieldset>
                   {this.state.SDdate?
                <fieldset className="form-group col-md-3 col-sm-12" >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid  justifyContent="space-around">
                          <DateTimePicker
                           disableFuture
                           showtodaybutton
                           showcancelbutton
                          //  views={["year", "month","date"]}
                            variant="inline"
                            id="date-picker-dialog"
                           // label="Financial Year Start Date"
                            // inputVariant="outlined"
                            minDate={this.state.invtype === "Direct Invoice" ?new Date(moment(transactionFrom).format("YYYY-MM-DD")):moment(this.state.sodata.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                            maxDate={new Date(moment().format("YYYY-MM-DD"))}
                            
                            label={`Select Date : ${this.state.SDdate?"Custom":"Default"}`}
                            format="yyyy-MM-DD HH:mm:ss"
                            value={this.state.customcreatedAt}
                             InputAdornmentProps={{ position: "start" }}
                            onChange={(date)=>{
                                 this.setState({ customcreatedAt: moment(new Date(date))})
                                 console.log(new Date(date))
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard"/>}
                            autoOk={true}
                            fullWidth
                            disabled={this.state.SDdate===false}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </fieldset>
                    :''}
                    </fieldset>:""}
                            <fieldset className="form-group col-md-12  mt-3 mb-5">
                                  <FormikTextField
                                    name="invoiceremarks"
                                    className="form-control"
                                    multiline
                                    rows={2}
                                    label="Remarks"
                                    variant="outlined"
                                    value={this.state.invoiceremarks}
                                    onChange={(e) =>
                                      this.setState({
                                        invoiceremarks: e.target.value,
                                      })
                                    }
                                  />
                                </fieldset>   
                        </>
                      )}
                      <fieldset className="form-group col-md-12" />

                      <fieldset className="form-group col-md-12">
                        {this.state.iscomposite ? (
                          <span className="text-sm text-red">
                            Note: Reverse GST will be applicable on this invoice
                          </span>
                        ) : (
                          ""
                        )}
                      </fieldset>
                      {/* {this.state.status === '0'? */}
                      {this.state.contracttype === "Recurring" ||
                      (this.state.invtype === "Direct Invoice" &&
                        this.state.servicecostwttax > 0) ? (
                        <span
                          as="button"
                          className="btn btn-sm hovbuttonColour col-md-auto col-sm-12"
                          onClick={this.handlesubmit}
                          disabled={this.state.loader === true}
                        >
                          {this.state.loader === true ? (
                            <>
                              <Spinner
                                className="mr-1"
                                animation="border"
                                size="sm"
                              />
                              {"Saving"}
                            </>
                          ) : (
                            <>
                              <i className="fas fa-save mr-1" />
                              Save & send for approval
                            </>
                          )}
                        </span>
                      ) : this.state.accepteditems.length === 0 &&
                        this.state.completed === "" ? (
                        <button
                          className="btn btn-sm hovbuttonColour col-md-auto col-sm-12"
                          disabled
                        >
                          <i className="fas fa-save mr-1" /> Save & send for approval
                        </button>
                      ) : (
                        <span
                          as="button"
                          className="btn btn-sm hovbuttonColour col-md-auto col-sm-12"
                          onClick={this.handlesubmit}
                          disabled={this.state.loader === true}
                        >
                          {this.state.loader === true ? (
                            <>
                              <Spinner animation="border" size="sm" />
                              {"Saving"}
                            </>
                          ) : (
                            <>
                              <i className="fas fa-save mr-1" />
                             Save & send for approval
                            </>
                          )}
                        </span>
                      )}
                      <span className="m-1"></span>
                      <Link
                        as="button"
                        className="btn btn-sm deletebutton col-md-auto col-sm-12"
                        to={{ pathname: "/salesinvoice" }}
                        state={{
                          message: "",
                          type:
                            this.state.pagename === "New Proforma Invoice"
                              ? "Proforma Invoice"
                              : "Tax Invoice",
                        }}
                      >
                        <b className="text-danger"> X </b>Cancel
                      </Link>
                      <div className="text-danger text-center col-sm-12">
                        {typeof this.state.errormsg === "string"
                          ? this.state.errormsg
                          : this.state.errormsg?.map(
                              (e, i) => e.ErrorMessage + ", "
                            )}
                      </div>

                      <Modal
                        show={this.state.quantitywindow}
                        onHide={() => this.setState({ quantitywindow: false })}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="lg"
                      >
                        <Modal.Header closeButton>Select Quantity</Modal.Header>
                        <Modal.Body>
                          <div className="form-row">
                            <fieldset className="form-group col-md-3 m-0">
                              <table className="float-left">
                                <tbody>
                                  <tr>
                                    <td>
                                      <FormLabel>Pending Quantity :</FormLabel>
                                    </td>
                                    <td>&nbsp;{this.state.pendingquantity}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <FormLabel>Total Quantity :</FormLabel>
                                    </td>
                                    <td>&nbsp;{this.state.quantity}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </fieldset>
                            <fieldset className="col-md-12">
                              {this.state.itemquantitylist.length === 0 ? (
                                <FormLabel>Out of Stock</FormLabel>
                              ) : (
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="text-center">
                                          Batch Id
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Date
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Avilable Quantity
                                        </TableCell>
                                        <TableCell>Enter Quanity</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.itemquantitylist.map(
                                        (e, i) => (
                                          <TableRow key={i}>
                                            <TableCell className="text-center">
                                              {e.batchid}
                                            </TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={e.price}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {moment(e.createdAt).format(
                                                "DD-MM-YYYY hh:mm"
                                              )}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.currentstock}
                                            </TableCell>
                                            <TableCell>
                                              <fieldset className="col-md-12">
                                                <TextField
                                                  className="form-control"
                                                  type="number"
                                                  name={`enteredquantity${i}`}
                                                  value={e.enteredquantity}
                                                  InputProps={{
                                                    inputProps: {
                                                      min: 0,
                                                      max: e.currentstock,
                                                    },
                                                  }}
                                                  onChange={(e) =>
                                                    this.handleQuantityChange(
                                                      e.target.value,
                                                      i
                                                    )
                                                  }
                                                  error={
                                                    e.enteredquantity >
                                                      e.currentstock ||
                                                    e.enteredquantity < 0
                                                  }
                                                  helperText={
                                                    e.enteredquantity >
                                                    e.currentstock
                                                      ? "Exceeds Avliable Quantity"
                                                      : e.enteredquantity < 0
                                                      ? "Enter Positive Quantity"
                                                      : ""
                                                  }
                                                />
                                              </fieldset>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                            </fieldset>
                            <fieldset className="col-md-12 mt-3">
                              <Button
                                className="btn btn-sm hovbuttonColour text-white col-md-auto col-sm-12"
                                onClick={this.handleSaveQuantity}
                              >
                                Submit
                              </Button>
                            </fieldset>
                            {this.state.pendingquantity <
                            this.state.quantity ? (
                              <fieldset className="col-md-auto col-sm-12 text-danger mt-2">
                                Exceeds quantity
                              </fieldset>
                            ) : (
                              ""
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>

                      {/*  <button className="float-center btn btn-sm">Print</button>
                    } */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Invoice);
