import React, { Component } from 'react'
import { Formik, Form, FastField } from "formik";
import withRouter from '../withRouter';
import {NumericFormat} from 'react-number-format';
import { currentTenant, currentUser, thousandsdisplay, transactionFrom, userid } from '../Common/Credentials';
import { ThemeProvider,createTheme, Backdrop, CircularProgress, Autocomplete, Grid, TextField} from '@mui/material';
import moment from 'moment';
import NewJournalService from './NewJournalService';
import { Link } from 'react-router-dom';
import AuthenticationService from '../Authentication/AuthenticationService';
import { FormikTextField } from '../CommonComponents/FormField.js';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import { Spinner } from "react-bootstrap";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const getTotal = (value) => {
  let total = 0;
value.forEach(vtotal => {
 total += vtotal;
});
 return total;
};

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class SalaryRequests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      monthwisesmpsalary:[],
      pendingadvances:[],
      salarydata:[],
      expdata:[],expdata1:[],
      totalcombdata:[],
      directtax:[],liab:[],
      sal:false,
      selectbtn:[
        {id:1,name:"Salaries"},
        {id:2,name:"Advances"}],
      selectedoption:'',
      pt:'',pf:'',insurance:'',it:'',
      saltotal:'',pttotal:'',pftotal:'',ittotal:'',insurancetotal:'',month:'',year:'',jvinsertiontype:0,
        loader:false,
        loader1:true,
        selecteddate:moment(new Date())         
      }
    this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
    this.loadData=this.loadData.bind(this);this.addSalaryData=this.addSalaryData.bind(this);
    this.updateSal=this.updateSal.bind(this);this.addLedgers=this.addLedgers.bind(this);
    this.getSalData=this.getSalData.bind(this);this.addTotals=this.addTotals.bind(this);
    }

    monthHandler = (date) => {
      console.log(new Date(date))
      this.setState({selecteddate:new Date(date)});
      this.addSalaryData(new Date(date))
    };

    onSubmit(values) {
      this.setState({loader:true})
      if(this.state.salarydata!==undefined && this.state.salarydata.find((e)=>e.ledgerid===null)){
        Swal.fire({
          title: "Please Check !!!",
          text: "Employee Ledger Missing, Cannot Continue. Please Create Employee Ledger " + "\n"
           + "\n"+" Hint :  Party Ledger --> New + --> Employees/Workers",
           icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then((result) => {
          if (result.value === true) {
            this.props.navigate({pathname:`/newledgers`});
      }});
      }else if(this.state.pendingadvances.find((e)=>e.ledgerid===null)){
        Swal.fire({
          title: "Please Check !!!",
          text: "Employee Ledger Missing, Cannot Continue. Please Create Employee Ledger " + "\n"
           + "\n"+" Hint :  Party Ledger --> New + --> Employees/Workers",
           icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then((result) => {
          if (result.value === true) {
            this.props.navigate({pathname:`/newledgers`});
      }});
      }else if(this.state.selectedoption===1){ 
      let data={
        salaryprogress:this.state.salarydata,
        salid:values.salaries.id,salname:values.salaries.name,
        pfid:values.pf.id,pfname:values.pf.name,
        ptid:values.pt.id,ptname:values.pt.name,
        itid:values.it.id,itname:values.it.name,
        insuranceid:values.insurance.id,insurancename:values.insurance.name,
        createdby:userid,tenantid:currentTenant,saltotal:this.state.saltotal,
        pttotal:this.state.pttotal,pftotal:this.state.pftotal,ittotal:this.state.ittotal,
        insurancetotal:this.state.insurancetotal,jvinsertiontype:this.state.jvinsertiontype,

      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
      } else {
        // console.log(data)
        NewJournalService.addSalary(data)
          .then(() =>
          this.setState({loader:false}),
           this.props.navigate(
                  { pathname: "/accounts" },
                  { message: values + "   : Entered Successfully" }
                )
          )
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    }else if(this.state.selectedoption===2){
      let data={
        pendingadvances:this.state.pendingadvances,
        expid:values.salaries.id,expname:values.salaries.name,
        createdby:userid,tenantid:currentTenant,jvinsertiontype:this.state.jvinsertiontype
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
      } else {
        NewJournalService.addAdvance(data)
          .then(() =>
          this.setState({loader:false}),
           this.props.navigate(
                  { pathname: "/accounts" },
                  { message: values + "   : Entered Successfully" }
                )
          )
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }

    }
    }

    updateSal(){
      this.setState({sal:true})
    }
    componentDidMount(){

      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.loadData()
        this.setState({loading:false})
       }
    onChange(option){
        this.setState({selectedoption: option.id});
        if(option.id===1){
          this.setState({jvinsertiontype:11});
        }else if(option.id===2){
          this.setState({jvinsertiontype:12});
        }
      }
   
    loadData(date){
      NewJournalService.getAllLedgers(currentTenant)
      .then(resp=>{
        this.setState({totalcombdata:resp.data},()=>this.addLedgers())
      })
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    }

    addLedgers(){
      let de1 =this.state.totalcombdata.filter((e)=>e.th==="TH2")
      let debdata= de1.concat(this.state.totalcombdata.filter((e)=>e.th==="TH3"))
      let directtax=this.state.totalcombdata.filter(e=>e.taxtype===1)
      let a =this.state.totalcombdata.filter(e=>e.th==="TH4")
      let liab = a.filter(e=>e.type===3 && e.subheadid !=="AH000006" && e.taxtype !== 2)
      this.setState({expdata:debdata,directtax:directtax,liab:liab},()=>this.getSalData())
    }
    getSalData(){
      NewJournalService.getEmployeeAdvances(currentTenant)
      .then(response =>{
        this.setState({pendingadvances:response.data})  
      })
      NewJournalService.getMonthWiseEmpSalary(currentTenant)
      .then(response =>{
        this.setState({monthwisesmpsalary:response.data},()=>this.addSalaryData(new Date()))  
      })
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      })
    }

    addSalaryData(date){

      // let aaa=this.state.monthwisesmpsalary.map((e)=>
      //                         e.id && e.month && e.createdAt,e.salaryprogress.map((f)=>
      //                         f.id&&f.mainid&&f.empid&&f.empname&&f.grossSalary&&f.epf&&f.incometax&&f.professionalTax&&
      //                         f.healthInsurance&&f.tenantid&&f.netpay))

      let m = new Date(date).getMonth() + 1
      let y = new Date(date).getFullYear()
      console.log(m,y)
      let saldata=  []
      // console.log(this.state.monthwisesmpsalary)
      if(this.state.monthwisesmpsalary.length!==0 ){
      saldata=this.state.monthwisesmpsalary.filter(e=>
        e.salaryprogress.length!==0?e.salaryprogress:"")
      // this.state.monthwisesmpsalary.map(e=>
        
      // //console.log(e.salaryprogress),
      // //this.setState({month:e.month,year:e.year}),
      // this.setState({month:e.month,year:e.year})
      // )
       let tmp = saldata.find(e=> e.year === y && e.month === m)
        tmp = tmp?tmp.salaryprogress:[]
        this.setState({salarydata:tmp,month:m,year:y},()=>this.addTotals())

    //  console.log(saldata)
      //this.setState({salarydata:saldata},()=>this.addTotals())

      }else if(this.state.pendingadvances.length!==0){
        this.setState({loader1:false})
            } else{
        this.setState({loader1:false})
               Swal.fire({
            title: "Confirmation",
            text: "No Pending Salaries",
            icon: "info",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
           })

      }
    }

    addTotals(){
      // console.log(this.state.salarydata)
      let saltotal,pttotal,pftotal,ittotal,insurancetotal
    if(this.state.salarydata!==undefined && this.state.salarydata.length!==0){
      saltotal=this.state.salarydata.map((e)=>e.netpay)
      pttotal=this.state.salarydata.map((e)=>e.professionalTax)
      pftotal=this.state.salarydata.map((e)=>e.epf)
      ittotal=this.state.salarydata.map((e)=>e.incometax)
      insurancetotal=this.state.salarydata.map((e)=>e.healthInsurance)
    

      this.setState({saltotal:getTotal(saltotal),pttotal:getTotal(pttotal),pftotal:getTotal(pftotal),
        ittotal:getTotal(ittotal),insurancetotal:getTotal(insurancetotal)},()=>this.setState({loader1:false}))
      }else{
        this.setState({loader1:false})
      }
      

    }
     validate(values){
      let errors = {};
      if(!values.salaries){
        errors.salaries="Required";
      }
     }

     selectedData(values){
     }
    //  submitRecord(value){
    //   let canreq={
    //     rectid:value,
    //     tenantid:currentTenant,
    //     createdBy:userid
    //   }

    //   NewJournalService.canConfirm(canreq)
    //   .then(response=>{
    //     console.log(response)
    //     if(response.status===200){
    //       Swal.fire({
    //         title: "Confirmation",
    //         text: "Request Completed Successfully",
    //         icon: "info",
    //         confirmButtonText: "Ok",
    //         confirmButtonColor:'#152f5f',
    //        })
    //        this.loadData() 
    //     }else{
    //      Swal.fire({
    //      title: "Error",
    //      text: "Request Cannot be  Completed",
    //      icon: "error",
    //      confirmButtonText: "Ok",
    //      confirmButtonColor:'#152f5f',
    //     })
    //     }

    //   })

    //   this.props.navigate(1);
    //  }
   
    render() {

      // let preceipts=null,salaries,pf,pt,it
      // genpreceipts=null,soids=null
      // genpreceipts=this.state.monthwisesmpsalary


      const Months = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "mainid",label: "mainid",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "empid",label: "empid",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "empname",label: "Name",options: {filter: false,sort: true}},
       {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
       customBodyRender:(tableMeta)=>{
         return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
       }}},
       {name: "grossSalary",label: "Gross",options: {filter: false,sort: true,
       customBodyRender: (value) => {
            return (
              <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
                fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
       {name: "epf",label: "PF",options: {filter: false,sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
            fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
          );
        },
        setCellProps: () => ({ style: { textAlign:'right'}}),
      }},
       {name: "incometax",label: "Income Tax",options: {filter: false,sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
            fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
          );
        },
        setCellProps: () => ({ style: { textAlign:'right'}}),
      }},
       {name: "professionalTax",label: "P T",options: {filter: false,sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
            fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
          );
        },
        setCellProps: () => ({ style: { textAlign:'right'}}),
      }},
      //  {name: "healthInsurance",label: "Insurance",options: {filter: false,sort: true,
      //   customBodyRender: (value) => {
      //     return (
      //       <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
      //       fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      //     );
      //   },
      //   setCellProps: () => ({ style: { textAlign:'right'}}),
      // }},
       {name: "healthInsurance",label: "Insurance",options: {filter: false,sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
            fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
          );
        },
        setCellProps: () => ({ style: { textAlign:'right'}}),
      }},
       {name: "tenantid",label: "tenantid",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "netpay",label: "Net Salary",options: {filter: false,sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
            fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
          );
        },
        setCellProps: () => ({ style: { textAlign:'right'}}),
      }},
       {name: "month",label: "Month",options: {filter: true,sort: true,
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta,'M').format('MMMM'))
      }}},
       {name: "year",label: "year",options: {filter: false,sort: false,display:false,viewColumns:false, }},
       



      //  f.empid,f.empname,f.grossSalary,f.epf,f.incometax,f.professionalTax,
      //                         f.healthInsurance,f.tenantid,f.netpay
        // {name: "custominvid",label: "Invoice",options: {filter: true,sort: true,
        //   customBodyRender:(value,tableMeta)=>{
        //     return (
        //       value.length!==0 ?value:"NA"
        //     );   
        //   },
        //   setCellProps: () => ({ style: { textAlign:'left'}}), 
        
        // }},
        // {name: "pendingamount",label: "Amount",options: {filter: false,sort: true,
        //   customBodyRender: (value) => {
        //     return (
        //       <NumericFormat
        //         className="pr-3 align-center"
        //         displayType={"text"}
        //         value={value}
        //         decimalScale={2}
        //         fixedDecimalScale={true}
        //         disabled={true}
        //         thousandSeparator={true}
        //         thousandsGroupStyle={thousandsdisplay}
        //       ></NumericFormat>
        //     );
        //   },
        //   setCellProps: () => ({ style: { textAlign:'right'}}),
        // }},
         
        // {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        //   customBodyRender:(value,tableMeta)=>{
        //     return (
        //       value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
        //       onClick={() => this.selectedData(tableMeta.rowData)}> Confirm
        //       </button>:null
        //     );   
        //   },
        //   setCellProps: () => ({ style: { textAlign:'center'}}),   
        // }},
      ]
      const MonthsOptions = {
        filterType: 'checkbox',
        selectableRows: "none",
      
      }

      const Advance = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "empid",label: "empid",options: {filter: false,sort: false,display:false,viewColumns:false}},
       {name: "empname",label: "Name",options: {filter: false,sort: true}},
       {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
       customBodyRender:(tableMeta)=>{
         return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
       }}},
       {name: "advance",label: "Advance",options: {filter: false,sort: true,
       customBodyRender: (value) => {
            return (
              <NumericFormat className="pr-3 align-center" displayType={"text"} value={value} decimalScale={2}
                fixedDecimalScale={true} disabled={true} thousandSeparator={true}thousandsGroupStyle={thousandsdisplay}></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'left'}}),
        }},
       {name: "tenantid",label: "tenantid",options: {filter: false,sort: false,display:false,viewColumns:false}},
  
      ]
      const AdvanceOptions = {
        filterType: 'checkbox',
        selectableRows: "none",
      
      }

        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
   <li className="breadcrumb-item text-secondary">
      Accounts
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/salentry'}} state= {{message:''}} >
                Salary Entries
              </Link>
    </li>
  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
  {/* salaries:'',pf:'',pt:'',it:'' */}
        
    <Formik
      initialValues={{salaries:undefined,pf:undefined,pt:undefined,it:undefined}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}
      >

{({ values, setFieldValue }) => (
        <Form autoComplete="off">

{this.state.selectbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onChange.bind(this,option)} 
               style={{marginRight: 8,width:400}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
}
      
{this.state.selectedoption===1?<>
  <fieldset className="col-md-3 tw-mb-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container>
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Select Month and Year"
                        openTo="month"
                        views={["year", "month"]}
                        minDate={moment(transactionFrom)}
                        maxDate={new Date()}
                        value={this.state.selecteddate}
                        onChange={this.monthHandler}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                </fieldset>
                   {/* <fieldset>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="From Date"
                                        name="from"
                                        // format="dd/MM/yyyy"
                                        // openTo="month"
                                        // minDate={moment().add(-1, "month")}
                                        views={["year", "month"]}
                                        maxDate={new Date()}
                                        value={this.state.month}
                                        onChange={this.monthHandler}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset> */}
       <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Salaries`}
                            data={this.state.salarydata}
                            columns={Months}
                            options={MonthsOptions}
                          />
                        </ThemeProvider>
                      </div>

        <div className=" form-row mt-4">
        <fieldset className="form-group col-md-3">
        <FastField name="salaries" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Select Salary Exp Ledger" : undefined } >
                    {({ meta }) => (
                    <Autocomplete
                      id="salaries"
                      className="subacc-select"
                      name="salaries"
                      options={this.state.expdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
                      size="small"
                      clearOnBlur={false}
                      groupBy={(option) => option.ledgroupname !== null? option.subname + " - " +
                            option.ledgroupname: option.subname}
                      getOptionLabel={(option) =>option.name}
                      value={values.salaries?undefined : undefined}
                      onChange={(e, value) => {setFieldValue("salaries", value)}}
                      renderInput={(params) => (
                        <FormikTextField  {...params}
                         className="form-control"
                          type="text"
                          name="salaries"
                          label={`Select Salary Exp Ledger`}
                          variant="standard"
                          fullWidth
                        />
                       
                      )}
                    />
                    )}
                    </FastField>
                  </fieldset>
                
                  {values.salaries!==undefined?
                  <fieldset className="form-group col-md-2">
                  <FastField name="pf" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Select PF Exp Ledger" : undefined } >
                    {({ meta }) => (
                    <Autocomplete
                      id="pf"
                      className="subacc-select"
                      name="pf"
                      options={this.state.liab.sort((a, b) => -a.subname.localeCompare(b.subname))}
                      size="small"
                      clearOnBlur={true}
                      groupBy={(option) => option.ledgroupname !== null? option.subname + " - " +
                            option.ledgroupname: option.subname}
                      getOptionLabel={(option) =>option.name}
                      value={values.salaries?undefined : undefined}
                      onChange={(e, value) => {setFieldValue("pf", value)}}
                      renderInput={(params) => (
                        <FormikTextField
                          {...params}
                          className="form-control"
                          type="text"
                          name="pf"
                          label={`Select PF Exp Ledger`}
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                    )}
                    </FastField>
                  </fieldset>:""}

                  {values.pf!==undefined?
                  <fieldset className="form-group col-md-2">
                  <FastField name="pt" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Select PT Exp Ledger" : undefined } >
                    {({ meta }) => (
                    <Autocomplete
                      id="pt"
                      className="subacc-select"
                      name="pt"
                      options={this.state.liab.sort((a, b) => -a.subname.localeCompare(b.subname))}
                      size="small"
                      clearOnBlur={true}
                      groupBy={(option) => option.ledgroupname !== null? option.subname + " - " +
                            option.ledgroupname: option.subname}
                      getOptionLabel={(option) =>option.name}
                      value={values.pt?undefined : undefined}
                      onChange={(e, value) => {setFieldValue("pt", value)}}
                      renderInput={(params) => (
                        <FormikTextField
                          {...params}
                          className="form-control"
                          type="text"
                          name="pt"
                          label={`Select PT Exp Ledger`}
                          variant="standard"
                          fullWidth
                        />
                        
                      )}
                    />
                    )}
                    </FastField>
                  </fieldset>:""}

                  {values.pt!==undefined?
                  <fieldset className="form-group col-md-2">
                  <FastField name="it" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Select IT Exp Ledger" : undefined } >
                    {({ meta }) => (
                    <Autocomplete
                      id="it"
                      className="subacc-select"
                      name="it"
                      options={this.state.directtax.sort((a, b) => -a.subname.localeCompare(b.subname))}
                      size="small"
                      clearOnBlur={true}
                      groupBy={(option) => option.ledgroupname !== null? option.subname + " - " +
                            option.ledgroupname: option.subname}
                      getOptionLabel={(option) =>option.name}
                      value={values.it?undefined : undefined}
                      onChange={(e, value) => {setFieldValue("it", value)}}
                      renderInput={(params) => (
                        <FormikTextField
                          {...params}
                          className="form-control"
                          type="text"
                          name="it"
                          label={`Select IT Exp Ledger`}
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                    )}
                    </FastField>
                  </fieldset>:""}

                  {values.it!==undefined?
                  <fieldset className="form-group col-md-3">
                  <FastField name="insurance" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Select Insurance  Ledger" : undefined } >
                    {({ meta }) => (
                    <Autocomplete
                      id="insurance"
                      className="subacc-select"
                      name="insurance"
                      options={this.state.liab.sort((a, b) => -a.subname.localeCompare(b.subname))}
                      size="small"
                      clearOnBlur={true}
                      groupBy={(option) => option.ledgroupname !== null? option.subname + " - " +
                            option.ledgroupname: option.subname}
                      getOptionLabel={(option) =>option.name}
                      value={values.insurance?undefined : undefined}
                      onChange={(e, value) => {setFieldValue("insurance", value)}}
                      renderInput={(params) => (
                        <FormikTextField
                          {...params}
                          className="form-control"
                          type="text"
                          name="insurance"
                          label={`Select Insurance Exp Ledger`}
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                    )}
                    </FastField>
                  </fieldset>:""}
                  {values.insurance!==undefined?
                  <fieldset className="form-group col-md-12 mt-4">
 <button className="btn hovbuttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>:""}
          </div>  
          </>:this.state.selectedoption===2?<>
       <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`EMP Advances`}
                            data={this.state.pendingadvances}
                            columns={Advance}
                            options={AdvanceOptions}
                          />
                        </ThemeProvider>
                      </div>

        <div className=" form-row mt-4">
        <fieldset className="form-group col-md-3">
        <FastField name="salaries" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Select Salary Exp Ledger" : undefined } >
                    {({ meta }) => (
                    <Autocomplete
                      id="salaries"
                      className="subacc-select"
                      name="salaries"
                      options={this.state.expdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
                      size="small"
                      clearOnBlur={false}
                      groupBy={(option) => option.ledgroupname !== null? option.subname + " - " +
                            option.ledgroupname: option.subname}
                      getOptionLabel={(option) =>option.name}
                      value={values.salaries?undefined : undefined}
                      onChange={(e, value) => {setFieldValue("salaries", value)}}
                      renderInput={(params) => (
                        <FormikTextField  {...params}
                         className="form-control"
                          type="text"
                          name="salaries"
                          label={`Select Salary Exp Ledger`}
                          variant="standard"
                          fullWidth
                        />
                       
                      )}
                    />
                    )}
                    </FastField>
                  </fieldset>
                
                 
                  <fieldset className="form-group col-md-12 mt-4">
 <button className="btn hovbuttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
          </div>  
          </>:""}  


</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(SalaryRequests);              



 
