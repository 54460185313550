import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import { currentTenant, currentUser, thousandsdisplay, userid } from "../Common/Credentials.js";
import NewJournalService from "./NewJournalService.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Backdrop,
  CircularProgress
} from "@mui/material";
import moment from "moment";
import { Spinner } from "react-bootstrap";

 
let gsttype;
class JvEntryDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jv: this.props.location.state.jv,
      cjv: this.props.location.state.cjv,
      jvdata:'',
      cjvdata:'',
      updatedcjvdata:'',
      updatedjvdata:'',
      loader: false,
      messageresp:'',
      editclicked:0,loader1:true,totalcombdata:[],toe:'',
      debtotal:0,cretotal:0,directtax:0,indirecttax:0,validated:false
    };
    //this.validate=this.validate.bind(this);
     this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.DeleteEntry=this.DeleteEntry.bind(this);
    this.editSelect=this.editSelect.bind(this);
    this.editSub=this.editSub.bind(this);
  }

  // goBack() {
  //   alert("I am here outside")

  //   if(this.state.jv.jvtype===6){
  //     this.props.navigate(`/dtaxes`);
  //   }else{
  //   this.props.navigate(-1);
  //   }
  // }

  editSub(){
    if(this.state.cjv!==undefined){
     if((this.state.debtotal+this.state.indirecttax)===(this.state.cretotal+this.state.directtax)){
      // console.log(this.state.updatedcjvdata)
      NewJournalService.editCJV(this.state.updatedcjvdata)
      .then(response =>{
      this.setState({messageresp:response.data.body},()=>this.setState({loader:false},()=>
      Swal.fire({
        title: "Respone",
        text: this.state.messageresp,
        icon: "question",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          this.setState({loader:false})
          this.goBack()
          }
        })  
      ))  
         })
    }
  }else {
  if((this.state.debtotal+this.state.indirecttax)===(this.state.cretotal)){
      // console.log(this.state.updatedcjvdata)
    
      NewJournalService.editJV(this.state.updatedjvdata)
      .then(response =>{
      this.setState({messageresp:response.data.body},()=>this.setState({loader:false},()=>
      Swal.fire({
        title: "Respone",
        text: this.state.messageresp,
        icon: "question",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          this.setState({loader:false})
          this.goBack()
          }
        })  
      ))  
     })
  }
  }
}

onSubmit(values) {

    let cjv,jv,debtot,cretot,indtax,dirtax
if(this.state.cjv!==undefined){

     cjv={
      id:this.state.cjvdata.id,
      debitval:values.debitval!==undefined?values.debitval:this.state.cjvdata.debitidamt,
      debitid:values.debit!==undefined?values.debit.id:this.state.cjvdata.debitid,
      debitname:values.debit!==undefined?values.debit.name:this.state.cjvdata.name,

      debitoneval:values.debitoneval!==undefined?values.debitoneval:this.state.cjvdata.debitidoneamt,
      debitidone:values.debitone!==undefined?values.debitone.id:this.state.cjvdata.debitidone,
      dioname:values.debitone!==undefined?values.debitone.name:this.state.cjvdata.dioname,

      debittwoval:values.debittwoval!==undefined?values.debittwoval:this.state.cjvdata.debitidtwoamt,
      debitidtwo:values.debittwo!==undefined?values.debittwo.id:this.state.cjvdata.debitidtwo,
      ditwname:values.debittwo!==undefined?values.debittwo.name:this.state.cjvdata.ditwname,

      debitthreeval:values.debitthreeval!==undefined?values.debitthreeval:this.state.cjvdata.debitidthreeamt,
      debitidthree:values.debitthree!==undefined?values.debitthree.id:this.state.cjvdata.debitidthree,
      dithname:values.debitthree!==undefined?values.debitthree.name:this.state.cjvdata.dithname,

      debitfourval:values.debitfourval!==undefined?values.debitfourval:this.state.cjvdata.debitidfouramt,
      debitidfour:values.debitfour!==undefined?values.debitfour.id:this.state.cjvdata.debitidfour,
      difname:values.debitfour!==undefined?values.debitfour.name:this.state.cjvdata.difname,

      taxoneval:values.taxoneval!==undefined?values.taxoneval:this.state.cjvdata.taxidoneamt,
      taxidone:values.taxone!==undefined?values.taxone.id:this.state.cjvdata.taxidone,
      taxonename:values.taxone!==undefined?values.taxone.name:this.state.cjvdata.taxonename,

      taxtwoval:values.taxtwoval!==undefined?values.taxtwoval:this.state.cjvdata.taxidtwoamt,
      taxidtwo:values.taxtwo!==undefined?values.taxtwo.id:this.state.cjvdata.taxidtwo,
      taxtwoname:values.taxtwo!==undefined?values.taxtwo.name:this.state.cjvdata.taxtwoname,

      dtaxval:values.dtaxval!==undefined?values.dtaxval:this.state.cjvdata.dtaxidamt,
      dtaxid:values.dtax!==undefined?values.dtax.id:this.state.cjvdata.dtaxid,
      dtaxname:values.dtax!==undefined?values.dtax.name:this.state.cjvdata.dtaxname,

      creditval:values.creditval!==undefined?values.creditval:this.state.cjvdata.creditidamt,
      creditid:values.credit!==undefined?values.credit.id:this.state.cjvdata.creditid,
      cname:values.credit!==undefined?values.credit.name:this.state.cjvdata.cname,

      creditoneval:values.creditoneval!==undefined?values.creditoneval:this.state.cjvdata.creditidoneamt,
      creditidone:values.creditone!==undefined?values.creditone.id:this.state.cjvdata.creditidone,
      cidoname:values.creditone!==undefined?values.creditone.name:this.state.cjvdata.cidoname,

      credittwoval:values.credittwoval!==undefined?values.credittwoval:this.state.cjvdata.creditidtwoamt,
      creditidtwo:values.credittwo!==undefined?values.credittwo.id:this.state.cjvdata.creditidtwo,
      cidtwname:values.credittwo!==undefined?values.credittwo.name:this.state.cjvdata.cidtwname,

      creditthreeval:values.creditthreeval!==undefined?values.creditthreeval:this.state.cjvdata.creditidthreeamt,
      creditidthree:values.creditthree!==undefined?values.creditthree.id:this.state.cjvdata.creditidthree,
      cidthname:values.creditthree!==undefined?values.creditthree.id:this.state.cjvdata.cidthname,

      creditfourval:values.creditfourval!==undefined?values.creditfourval:this.state.cjvdata.creditidfouramt,
      creditidfour:values.creditfour!==undefined?values.creditfour.id:this.state.cjvdata.creditidfour,
      cidfname:values.creditfour!==undefined?values.creditfour.id:this.state.cjvdata.cidfname,

      narration:values.narration!==undefined?values.narration:this.state.cjvdata.naration,
      tenantid:currentTenant,
      createdBy:currentUser,
      
     
    }
   
          if(cjv.debitval===undefined){debtot=0}else{debtot=parseInt(cjv.debitval)}
          if(cjv.debitoneval===undefined || cjv.debitoneval===null ){debtot=debtot}else{debtot=debtot+parseInt(cjv.debitoneval)}
          if(cjv.debittwoval===undefined || cjv.debittwoval===null){debtot=debtot+0}else{debtot=debtot+parseInt(cjv.debittwoval)}
          if(cjv.debitthreeval===undefined || cjv.debitthreeval===null){debtot=debtot+0}else{debtot=debtot+parseInt(cjv.debitthreeval)}
          if(cjv.debitfourval===undefined || cjv.debitfourval===null){debtot=debtot+0}else{debtot=debtot+parseInt(cjv.debitfourval)}

          if(cjv.taxoneval===undefined || cjv.taxoneval===null){indtax=0}else{indtax=parseInt(cjv.taxoneval)}
          if(cjv.taxtwoval===undefined || cjv.taxtwoval===null){indtax=indtax+0}else{indtax=indtax+parseInt(cjv.taxtwoval)}

          if(cjv.creditval===undefined || cjv.creditval===null){cretot=0}else{cretot=parseInt(cjv.creditval)}
          if(cjv.creditoneval===undefined || cjv.creditoneval===null ){cretot=cretot}else{cretot=cretot+parseInt(cjv.creditoneval)}
          if(cjv.credittwoval===undefined || cjv.credittwoval===null){cretot=cretot+0}else{cretot=cretot+parseInt(cjv.credittwoval)}
          if(cjv.creditthreeval===undefined || cjv.creditthreeval===null){cretot=cretot+0}else{cretot=cretot+parseInt(cjv.creditthreeval)}
          if(cjv.creditfourval===undefined || cjv.creditfourval===null){cretot=cretot+0}else{cretot=cretot+parseInt(cjv.creditfourval)}
          if(cjv.dtaxval===undefined || cjv.dtaxval===null){dirtax=0}else{dirtax=parseInt(cjv.dtaxval)}

          this.setState({debtotal:debtot,cretotal:cretot,directtax:dirtax,indirecttax:indtax})


          if((debtot+indtax)===(cretot+dirtax)){
            cjv.debtotal=debtot
            cjv.cretotal=cretot
            this.setState({validated:true,updatedcjvdata:cjv})
            // ,()=>this.state.validated===true?this.editSub(cjv):null
          }else{
            Swal.fire({
              title: "Respone",text: "Debit and Credit Are not Equal",icon: "info",
               confirmButtonText: "Ok",
              //  showCancelButton: true,cancelButtonText: "No",
              confirmButtonColor: "#152f5f",
            })
          }
    }
  if(this.state.jv!==undefined){
      jv={
       id:this.state.jvdata.id,
       debitidamt:values.debitval!==undefined?values.debitval:this.state.jvdata.debitidamt,
       debitid:values.debit!==undefined?values.debit.id:this.state.jvdata.debitid,
       debitname:values.debit!==undefined?values.debit.name:this.state.jvdata.debitname,

       sdebitidamt:values.sdebitval!==undefined?values.sdebitval:this.state.jvdata.sdebitidamt,
       sdebitid:values.sdebit!==undefined?values.sdebit.id:this.state.jvdata.sdebitid,
       sdebitname:values.sdebit!==undefined?values.sdebit.name:this.state.jvdata.sdebitname,
 
       addlonetotamt:values.debitoneval!==undefined?values.debitoneval:this.state.jvdata.addlonetotamt,
       addloneledid:values.debitone!==undefined?values.debitone.id:this.state.jvdata.addloneledid,
       addlonename:values.debitone!==undefined?values.debitone.name:this.state.jvdata.addlonename,
 
       addltwototamt:values.debittwoval!==undefined?values.debittwoval:this.state.jvdata.addltwototamt,
       addltwoledid:values.debittwo!==undefined?values.debittwo.id:this.state.jvdata.addltwoledid,
       addltwoname:values.debittwo!==undefined?values.debittwo.name:this.state.jvdata.addltwoname,
 
       addlthreetotamt:values.debitthreeval!==undefined?values.debitthreeval:this.state.jvdata.addlthreetotamt,
       addlthreeledid:values.debitthree!==undefined?values.debitthree.id:this.state.jvdata.addlthreeledid,
       addlthreename:values.debitthree!==undefined?values.debitthree.name:this.state.jvdata.addlthreename,
 
       addlfourtotamt:values.debitfourval!==undefined?values.debitfourval:this.state.jvdata.addlfourtotamt,
       addlfourledid:values.debitfour!==undefined?values.debitfour.id:this.state.jvdata.addlfourledid,
       addlfourledname:values.debitfour!==undefined?values.debitfour.name:this.state.jvdata.addlfourledname,
 
       insurtotamt:values.insuranceval!==undefined?values.insuranceval:this.state.jvdata.insurtotamt,
       insurledid:values.insurance!==undefined?values.insurance.id:this.state.jvdata.insurledid,
       insurledname:values.insurance!==undefined?values.insurance.name:this.state.jvdata.insurledname,

       surval:values.surchargeval!==undefined?values.surchargeval:this.state.jvdata.surval,
       surchargeid:values.surcharge!==undefined?values.surcharge.id:this.state.jvdata.surchargeid,
       surchargename:values.surcharge!==undefined?values.surcharge.name:this.state.jvdata.surchargename,

       penval:values.penaltyval!==undefined?values.penaltyval:this.state.jvdata.penval,
       penaltyid:values.penalty!==undefined?values.penalty.id:this.state.jvdata.penaltyid,
       penaltyname:values.penalty!==undefined?values.penalty.name:this.state.jvdata.penaltyname,

       sgstamt:values.sgstval!==undefined?values.sgstval:this.state.jvdata.sgstamt,
       sgstid:values.sgst!==undefined?values.sgst.id:this.state.jvdata.sgstid,
       sgstname:values.sgst!==undefined?values.sgst.name:this.state.jvdata.sgstname,

       igstamt:values.igstval!==undefined?values.igstval:this.state.jvdata.igstamt,
       igstid:values.igst!==undefined?values.igst.id:this.state.jvdata.igstid,
       igstname:values.igst!==undefined?values.igst.name:this.state.jvdata.igstname,

       vatamt:values.vatval!==undefined?values.vatval:this.state.jvdata.vatamt,
       vatid:values.vat!==undefined?values.vat.id:this.state.jvdata.vatid,
       vatname:values.vat!==undefined?values.vat.name:this.state.jvdata.vatname,
 
       taxidtwoamt:values.taxtwoval!==undefined?values.taxtwoval:this.state.jvdata.taxidtwoamt,
       taxidtwo:values.taxtwo!==undefined?values.taxtwo.id:this.state.jvdata.taxidtwo,
       taxtwoname:values.taxtwo!==undefined?values.taxtwo.name:this.state.jvdata.taxtwoname,
 
       creditidamt:values.creditval!==undefined?values.creditval:this.state.jvdata.creditidamt,
       creditid:values.credit!==undefined?values.credit.id:this.state.jvdata.creditid,
       creditname:values.credit!==undefined?values.credit.name:this.state.jvdata.creditname,
 
       narration:values.narration!==undefined?values.narration:this.state.jvdata.naration,
       tenantid:currentTenant,
       createdBy:currentUser,
       
      
     }
    
           if(jv.debitidamt===undefined){debtot=0}else{debtot=parseInt(jv.debitidamt)}
           if(jv.sdebitidamt===undefined || jv.sdebitidamt===null ){debtot=debtot}else{debtot=debtot+parseInt(jv.sdebitidamt)}
           if(jv.addlonetotamt===undefined || jv.addlonetotamt===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.addlonetotamt)}
           if(jv.addltwototamt===undefined || jv.addltwototamt===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.addltwototamt)}
           if(jv.addlthreetotamt===undefined || jv.addlthreetotamt===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.addlthreetotamt)}
           if(jv.addlfourtotamt===undefined || jv.addlfourtotamt===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.addlfourtotamt)}
           if(jv.insurtotamt===undefined || jv.insurtotamt===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.insurtotamt)}
           if(jv.surval===undefined || jv.surval===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.surval)}
           if(jv.penval===undefined || jv.penval===null){debtot=debtot+0}else{debtot=debtot+parseInt(jv.penval)}

 
           if(jv.sgstamt===undefined || jv.sgstamt===null){indtax=0}else{indtax=parseInt(jv.sgstamt)}
           if(jv.taxtwoval===undefined || jv.taxtwoval===null){indtax=indtax+0}else{indtax=indtax+parseInt(jv.taxtwoval)}

           if(jv.igstamt===undefined || jv.igstamt===null){indtax=0}else{indtax=parseInt(jv.igstamt)}
           if(jv.vatamt===undefined || jv.vatamt===null){indtax=0}else{indtax=parseInt(jv.vatamt)}

 
           if(jv.creditidamt===undefined || jv.creditidamt===null){cretot=0}else{cretot=parseInt(jv.creditidamt)}
           this.setState({debtotal:debtot,cretotal:cretot,directtax:dirtax,indirecttax:indtax})
 
           alert((debtot+indtax) +" And "+(cretot))

           if((debtot+indtax)===(cretot)){
             jv.debtotal=debtot
             jv.cretotal=cretot
             this.setState({validated:true,updatedjvdata:jv})
             // ,()=>this.state.validated===true?this.editSub(cjv):null
           }else{
             Swal.fire({
               title: "Respone",text: "Debit and Credit Are not Equal",icon: "info",
                confirmButtonText: "Ok",
               //  showCancelButton: true,cancelButtonText: "No",
               confirmButtonColor: "#152f5f",
             })
           }
     }


  }

  componentDidMount() {
    if(this.state.jv!==undefined){

    let data={
      tenantid:currentTenant,
      id:this.state.jv
    }
    
    // this.setState({toe:this.state.jvdata.typeofentry})
    //  if(this.state.jv!==undefined){
      NewJournalService.getJVDetail(data)
      .then(response =>{
        this.setState({jvdata:response.data},()=>this.setState({loader1:false}))
        
      }) 
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    //  }

    }else if(this.state.cjv!==undefined){
      let data={
        tenantid:currentTenant,
        id:this.state.cjv
      }
  
      //  if(this.state.cjv!==undefined){
        NewJournalService.getCJVDetail(data)
        .then(response =>{
          this.setState({cjvdata:response.data},()=>this.setState({loader1:false}))  
        }) 
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
       }

    // }

  }

  editSelect(){
    let rectid='',narration=''
    rectid=this.state.jv
    if(this.state.jvdata!==undefined){
      //  alert(this.state.jvdata.typeofentry)

      if(this.state.jvdata.typeofentry>100){
        Swal.fire({
          title: "Respone",text: "Old record cannot be edited",icon: "info",
           confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        })
      }
      // else if(this.state.jvdata.typeofentry===3 || this.state.jvdata.typeofentry===4 || this.state.jvdata.typeofentry===6){
      //   Swal.fire({
      //     title: "Respone",text: "Payment and Receipt cannot be edited ! It can only only be deleted",icon: "info",
      //      confirmButtonText: "Ok",
      //     //  showCancelButton: true,cancelButtonText: "No",
      //     confirmButtonColor: "#152f5f",
      //   }).then((result) => {
      //     if (result.value === true) {
      //       this.setState({loader:false})
      //       this.goBack()
      //       }
      //     })
      //  }

      // else if(this.state.jvdata.jvtype==="Sales" || this.state.jvdata.jvtype==="Purchase"){
      //   Swal.fire({
      //     title: "Respone",text: "Sales and Purchase entry cannot be Edited ! Cancel from sales and purchase modules",icon: "info",
      //      confirmButtonText: "Ok",
      //     //  showCancelButton: true,cancelButtonText: "No",
      //     confirmButtonColor: "#152f5f",
      //   }).then((result) => {
      //     if (result.value === true) {
      //       this.setState({loader:false})
      //       this.goBack()
      //       }
      //     })
      // }
      else{
      rectid=this.state.jv
        Swal.fire({
          title: "Respone",text: "This feature will be enabled Soon",icon: "info",
           confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        })

    //  Swal.fire({
    //     title: "Edit Entry",
    //     text: "Do you want to Edit Entry",
    //     icon: "question",
    //     confirmButtonText: "Yes",
    //     showCancelButton: true,
    //     cancelButtonText: "No",
    //     confirmButtonColor: "#152f5f",
    //   }).then((result) => {
    //     if (result.value === true) {
    //       this.setState({loader1:true})
    //       this.setState({editclicked:1})
    //       NewJournalService.getAllLedgers(currentTenant)
    //       .then(response=>{
    //         this.setState({totalcombdata:response.data},()=>this.setState({loader1:false}))
    //         // totalcombdata=response.data
    //       })
    //       }
    //    })
      }
    }else if(this.state.cjv!==undefined){
      Swal.fire({
        title: "Respone",text: "This feature will be enabled Soon",icon: "info",
         confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })
    // Swal.fire({
    //   title: "Edit Entry",
    //   text: "Do you want to Edit Entry",
    //   icon: "question",
    //   confirmButtonText: "Yes",
    //   showCancelButton: true,
    //   cancelButtonText: "No",
    //   confirmButtonColor: "#152f5f",
    // }).then((result) => {
    //   if (result.value === true) {
    //     this.setState({loader1:true})
    //     this.setState({editclicked:1})
    //     NewJournalService.getAllLedgers(currentTenant)
    //     .then(response=>{
    //       this.setState({totalcombdata:response.data},()=>this.setState({loader1:false}))
    //       // totalcombdata=response.data
    //     })
    //     }
    //       })
      }
  }

  DeleteEntry(){
    let rectid='',narration=''
    
    if(this.state.jv!==undefined ){
      if(this.state.jvdata.jvinsertiontype===null || this.state.jvdata.typeofentry>100){
        Swal.fire({
          title: "Respone",text: "Old record cannot be Deleted",icon: "info",
           confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        })
    }
    else{
      rectid=this.state.jv
      Swal.fire({title: "Delete Entry", text: "Do you want to Delete Entry", icon: "question",
        confirmButtonText: "Yes",showCancelButton: true,cancelButtonText: "No", confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          Swal.fire({title: "Value!", text: "Enter Deletion Reason:", icon: "info",
            showCancelButton: true,input: "text",
            inputValidator: (value) => {
              if (!value) {return "Narration is required!";
              }else{
              narration=value
              let data1={ rectid:rectid,tenantid:currentTenant,createdBy:currentUser,narration:narration}
              NewJournalService.deleteJV(data1)
              .then(response =>{
              this.setState({messageresp:response.data.body},()=>this.setState({loader:false},()=>
              Swal.fire({
                title: "Respone",text: this.state.messageresp,icon: "info",
                 confirmButtonText: "Ok",
                //  showCancelButton: true,cancelButtonText: "No",
                confirmButtonColor: "#152f5f",
              }).then((result) => {
                if (result.value === true) {
                  this.setState({loader:false})
                  this.goBack()
                  }
                }) ))  
                 })
              }
              }
              
              }) 
    
            }
              })
          }
    }else if(this.state.cjv!==undefined){
      rectid=this.state.cjv
      Swal.fire({title: "Delete Entry", text: "Do you want to Delete Entry", icon: "question",
        confirmButtonText: "Yes",showCancelButton: true,cancelButtonText: "No", confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          Swal.fire({title: "Value!", text: "Enter Deletion Reason:", icon: "info",
            showCancelButton: true,input: "text",
            inputValidator: (value) => {
              if (!value) {return "Narration is required!";
              }else{
              narration=value
  
              let data1={ rectid:rectid,tenantid:currentTenant,createdBy:currentUser,narration:narration}
              NewJournalService.deleteCJV(data1)
            .then(response =>{
            this.setState({messageresp:response.data.body},()=>this.setState({loader:false},()=>
      
            Swal.fire({
              title: "Respone",
              text: this.state.messageresp,
              icon: "info",
              confirmButtonText: "Ok",
              // showCancelButton: true,
              // cancelButtonText: "No",
              confirmButtonColor: "#152f5f",
            }).then((result) => {
              if (result.value === true) {
                this.setState({loader:false})
                this.goBack()
                }
              }) 
              ))  
               })
            }
            }
            
            }) 
  
          }
            })
    }
  }

  // componentDidUpdate() {}

  goBack() {

      this.props.navigate(-1);
    
  }

 

  render() {
    let debtotal,cretotal,debitval,sdebitval,debitoneval,debittwoval,debitthreeval,debitfourval,taxtwoval,dtaxval,creditval,
          creditoneval,credittwoval,creditthreeval,creditfourval,narration,insurance,insuranceval,surcharge,surchargeval,penalty,penaltyval
    let debit,sdebit,debitone,debittwo,debitthree,debitfour,credit,creditone,credittwo,creditthree,creditfour,sgst,sgstval,igst,igstval,vat,vatval,taxtwo,dtax
     let jv = this.state.jvdata
     let cjv = this.state.cjvdata
    let debvalue, credvalue,directtax,tax
    if(this.state.cjv!==undefined){
    debvalue=this.state.totalcombdata.filter((e) => e.th==='TH2')
    credvalue=this.state.totalcombdata.filter((e) => e.rule === 0);
    directtax=this.state.totalcombdata.filter(e=>e.taxtype===1)
    tax = this.state.totalcombdata.filter((e) => (e.taxtype === 2) );
    }else  if(this.state.jv!==undefined ){
      if(jv.typeofentry===3 || jv.typeofentry===6){
        credvalue=this.state.totalcombdata.filter((e)=>e.subheadid==="AH000020" || e.subheadid==="AH000021" || e.subheadid==="AH000022")
      }
     else if(jv.typeofentry===4){
        debvalue=this.state.totalcombdata.filter((e)=>e.subheadid==="AH000020" || e.subheadid==="AH000021" || e.subheadid==="AH000022")
      }else if(jv.typeofentry===1){
        credvalue=this.state.totalcombdata.filter((e) => e.th==='TH1')
        debvalue=this.state.totalcombdata.filter((e) => e.rule === 0);
        tax = this.state.totalcombdata.filter((e) => (e.taxtype === 2) );
      }else if(jv.typeofentry===0){
        debvalue=this.state.totalcombdata.filter((e) => e.th==='TH2')
        credvalue=this.state.totalcombdata.filter((e) => e.rule === 0);
        tax = this.state.totalcombdata.filter((e) => (e.taxtype === 2) );
      }else{
      debvalue=this.state.totalcombdata.filter((e) => e.th==='TH2')
      credvalue=this.state.totalcombdata.filter((e) => e.rule === 0);
      tax = this.state.totalcombdata.filter((e) => (e.taxtype === 2) );

      }
    }
    return (
      
      <div>


        <Header />
        <Menu />

        <div className="content-wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Entry Details</li>
            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
              <div className="text-center"></div>

              <Formik
                initialValues={{debtotal,cretotal,debitval, debitoneval,debittwoval,debitthreeval,debitfourval,
                taxtwoval,dtaxval,creditval,creditoneval,credittwoval,creditthreeval,creditfourval,
                debit,debitone,debittwo,insurance,insuranceval,surcharge,surchargeval,penalty,penaltyval,
                debitthree,debitfour,credit,creditval,creditone,creditoneval,credittwo,credittwoval,sdebit,sdebitval,
                creditthree,creditthreeval,creditfour,creditfourval,sgst,sgstval,igst,igstval,vat,vatval,
                taxtwo,taxtwoval,dtax,dtaxval,narration}}
                onSubmit={this.onSubmit}
                validateOnChange={true}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({values, setFieldValue }) => (
                  <Form>
<div className="mb-4">
<div className="form-row col-md-12 mt-0 mb-4">
<fieldset className="form-group col-md-2 mt-0 mb-0">
<label>Date</label>
</fieldset>
<fieldset className="form-group col-md-3 mt-0 mb-0">
<label>{moment(jv.createdAt).format('DD-MM-YYYY HH:mm:ss')}</label>
</fieldset>
<label>|</label>
<fieldset className="form-group col-md-2 mt-0 mb-0">
<label>Entrytype</label>
</fieldset>
{/* <fieldset className="form-group col-md-2 mt-0 mb-0"></fieldset> */}
<fieldset className="form-group col-md-2 mt-0 mb-0">
<label>{this.state.jvdata.jvinsertiontype==="SALEADV"  || this.state.jvdata.jvinsertiontype==="SALEINV"?"Sales": this.state.jvdata.jvinsertiontype==="PURINV" || this.state.jvdata.jvinsertiontype==="PURADV"?"Purchase": "Others"}</label>
</fieldset>
</div>

<div className="form-row col-md-12 mt-0 mb-0">

{this.state.jv!==undefined?<>
  
<fieldset className="form-group buttonColour text-left col-md-6 mt-0 mb-0">
<label>Details</label>
</fieldset>
<fieldset className="form-group buttonColour text-center col-md-3 mt-0 mb-0">
<label>Debit</label>
</fieldset>
<fieldset className="form-group buttonColour text-center col-md-3 mt-0 mb-0">
<label>Credit</label>
</fieldset>
 {/* ---------------------------------------------------------------------------------------------------------------------- */}

{jv.debitidamt!==null  ?<>
 {this.state.editclicked!==1 || this.state.jvdata.typeofentry===3 || this.state.jvdata.typeofentry===6?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.debitname}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="debit" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="debit" className="debit-select" name="debit" options={this.state.jvdata.typeofentry===3 || this.state.jvdata.typeofentry===6?debvalue:debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debit" label={values.debit!==undefined?values.debit.name:jv.debitname}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.debitidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="debitval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="debitval" className="form-control" label={values.debitval!==undefined?values.debitval:jv.debitidamt} type="text"
 onBlur={() =>  values.debitval!==undefined?setFieldValue("debtotal", parseInt(values.debitval)):setFieldValue("debtotal", parseInt(jv.debitidamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.sdebitidamt!==null  ?<>
 {this.state.editclicked!==1 || this.state.jvdata.typeofentry===3?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.sdebitname}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="sdebit" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="sdebit" className="sdebit-select" name="sdebit" options={this.state.jvdata.typeofentry===3?debvalue:debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.sdebit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("sdebit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="sdebit" label={values.sdebit!==undefined?values.sdebit.name:jv.sdebitname}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.debitidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="sdebitval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="sdebitval" className="form-control" label={values.sdebitval!==undefined?values.sdebitval:jv.sdebitidamt} type="text"
 onBlur={() =>  values.sdebitval!==undefined?setFieldValue("debtotal", parseInt(values.sdebitval)):setFieldValue("debtotal", parseInt(jv.sdebitidamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

{jv.addlonetotamt!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.addlonename}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="debitone" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="debitone" className="debitone-select" name="debitone" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debitone ? undefined : undefined} onChange={(e, value) => {setFieldValue("debitone", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debitone" label={values.debitone!==undefined?values.debitone.name:jv.addlonename}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.addlonetotamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="debitoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="debitoneval" className="form-control" label={values.debitoneval!==undefined?values.debitoneval:jv.addlonetotamt} type="text"
 onBlur={() =>  values.debitoneval!==undefined?setFieldValue("debtotal", parseInt(values.debitoneval)):setFieldValue("debtotal", parseInt(jv.addlonetotamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.addltwototamt!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.addltwoname}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="debittwo" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="debittwo" className="debittwo-select" name="debittwo" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debittwo ? undefined : undefined} onChange={(e, value) => {setFieldValue("debittwo", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debittwo" label={values.debittwo!==undefined?values.debittwo.name:jv.addltwoname}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.addltwototamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="debittwoval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="debittwoval" className="form-control" label={values.debittwoval!==undefined?values.debittwoval:jv.addltwototamt} type="text"
 onBlur={() =>  values.debittwoval!==undefined?setFieldValue("debtotal", parseInt(values.debittwoval)):setFieldValue("debtotal", parseInt(jv.addltwototamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.addlthreetotamt!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.addlthreename}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="debitthree" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="debitthree" className="debitthree-select" name="debitthree" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debitthree ? undefined : undefined} onChange={(e, value) => {setFieldValue("debitthree", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="addlthree" label={values.debitthree!==undefined?values.debitthree.name:jv.addlthreename}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.addlthreetotamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="debitthreeval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="debitthreeval" className="form-control" label={values.debitthreeval!==undefined?values.debitthreeval:jv.addlthreetotamt} type="text"
 onBlur={() =>  values.debitthreeval!==undefined?setFieldValue("debtotal", parseInt(values.debitthreeval)):setFieldValue("debtotal", parseInt(jv.addlthreetotamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.addlfourtotamt!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.addlfourname}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="debitfour" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="debitfour" className="debitfour-select" name="debitfour" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debitfour ? undefined : undefined} onChange={(e, value) => {setFieldValue("debitfour", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debitfour" label={values.debitfour!==undefined?values.debitfour.name:jv.addlfourname}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.addlfourtotamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="debitfourval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="debitfourval" className="form-control" label={values.debitfourval!==undefined?values.debitfourval:jv.addlfourtotamt} type="text"
 onBlur={() =>  values.debitfourval!==undefined?setFieldValue("debtotal", parseInt(values.debitfourval)):setFieldValue("debtotal", parseInt(jv.addlfourtotamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.insurtotamt!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.insurname}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="insurance" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="insurance" className="insurance-select" name="insurance" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.insurance ? undefined : undefined} onChange={(e, value) => {setFieldValue("insurance", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="insurance" label={values.insurance!==undefined?values.insurance.name:jv.insurname}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.insurtotamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="insuranceval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="insuranceval" className="form-control" label={values.insuranceval!==undefined?values.insuranceval:jv.insurtotamt} type="text"
 onBlur={() =>  values.insuranceval!==undefined?setFieldValue("debtotal", parseInt(values.insuranceval)):setFieldValue("debtotal", parseInt(jv.insurtotamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.surval!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.surchargename}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="surcharge" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="surcharge" className="surcharge-select" name="surcharge" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.surcharge ? undefined : undefined} onChange={(e, value) => {setFieldValue("surcharge", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="surcharge" label={values.surcharge!==undefined?values.surcharge.name:jv.surchargename}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.surval} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="surchargeval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="surchargeval" className="form-control" label={values.surchargeval!==undefined?values.surchargeval:jv.surval} type="text"
 onBlur={() =>  values.surchargeval!==undefined?setFieldValue("debtotal", parseInt(values.surchargeval)):setFieldValue("debtotal", parseInt(jv.surval))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.penval!==null  ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.penaltyname}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="penalty" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="penalty" className="penalty-select" name="penalty" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.penalty ? undefined : undefined} onChange={(e, value) => {setFieldValue("penalty", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="penalty" label={values.penalty!==undefined?values.penalty.name:jv.penaltyname}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1 ?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.surval} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="penaltyval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="penaltyval" className="form-control" label={values.penaltyval!==undefined?values.penaltyval:jv.penval} type="text"
 onBlur={() =>  values.surchargeval!==undefined?setFieldValue("debtotal", parseInt(values.penaltyval)):setFieldValue("debtotal", parseInt(jv.penval))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  
  {jv.sgstamt!==null ?<>
    {this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{jv.sgstname!==null?jv.sgstname:"SGST"}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="sgst" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="sgst" className="sgst-select" name="sgst" options={tax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.sgst ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("sgst", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="sgst" label={values.sgst!==undefined?values.sgst.name:jv.sgstname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {jv.debitidamt<jv.creditidamt?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.sgstamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat></fieldset></>
  :<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="sgstval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="sgstval" className="form-control" label={values.sgstval!==undefined?values.sgstval:jv.sgstamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)
 +parseInt(values.debitthreeval)+parseInt(values.debitfourval)+parseInt(values.sgstval))}
 variant="standard"></FormikTextField>)}</Field></fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.sgstamt} decimalScale={2}
  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat></fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="sgstval" className="form-control" label={values.sgstval!==undefined?values.sgstval:jv.sgstamt} type="text"
  onBlur={() =>  setFieldValue("cretotal", parseInt(values.sgstval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}</>}
  </>:""}
  
  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.igstamt!==null ?<>
    {this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{jv.igstname!==null?jv.igstname:"IGST"}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="igst" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="igst" className="igst-select" name="igst" options={tax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.igst ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("igst", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="igst" label={values.igst!==undefined?values.igst.name:jv.igstname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {jv.debitidamt<jv.creditidamt?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.igstamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat></fieldset></>
  :<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="igstval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="sgstval" className="form-control" label={values.igstval!==undefined?values.igstval:jv.igstamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)
 +parseInt(values.debitthreeval)+parseInt(values.debitfourval)+parseInt(values.igstval))}
 variant="standard"></FormikTextField>)}</Field></fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.igstamt} decimalScale={2}
  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat></fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="sgstval" className="form-control" label={values.igstval!==undefined?values.igstval:jv.igstamt} type="text"
  onBlur={() =>  setFieldValue("cretotal", parseInt(values.igstval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}</>}
  </>:""}
  
  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.vatamt!==null ?<>
    {this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{jv.vatname!==null?jv.vatname:"VAT"}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="vat" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="vat" className="vat-select" name="vat" options={tax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.vat ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("vat", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="vat" label={values.vat!==undefined?values.vat.name:jv.vatname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {jv.debitidamt<jv.creditidamt?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.vatamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat></fieldset></>
  :<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="vatval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="vatval" className="form-control" label={values.vatval!==undefined?values.vatval:jv.vatamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)
 +parseInt(values.debitthreeval)+parseInt(values.debitfourval)+parseInt(values.igstval))}
 variant="standard"></FormikTextField>)}</Field></fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.vatamt} decimalScale={2}
  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat></fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="vatval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="vatval" className="form-control" label={values.vatval!==undefined?values.vatval:jv.vatamt} type="text"
  onBlur={() =>  setFieldValue("cretotal", parseInt(values.vatval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}</>}
  </>:""}
  
  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  {jv.cgstamt!==null?<>
  {this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{jv.cgstname!==null?jv.cgstname:"CGST"}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxtwo" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="taxtwo" className="taxtwo-select" name="taxtwo" options={tax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("taxtwo", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="taxtwo" label={values.taxtwo!==undefined?values.taxtwo.name:jv.cgstname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {jv.debitidamt<jv.creditidamt?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.cgstamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset></>:
  <><fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
    <Field name="taxtwoval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="taxtwoval" className="form-control" label={values.taxtwoval!==undefined?values.taxtwoval:jv.cgstamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)+
 parseInt(values.debitthreeval)+parseInt(values.debitfourval)+parseInt(values.taxoneval)+parseInt(values.taxtwoval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.cgstamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxtwoval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="taxtwoval" className="form-control" label={values.taxtwoval!==undefined?values.taxtwoval:jv.cgstamt} type="text"
  onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}</>}
  </>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}


{jv.creditidamt!==null?<>
  {this.state.editclicked!==1 || this.state.jvdata.typeofentry===4?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0"  style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{jv.creditname}</label>
  </fieldset></>:<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="credit" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Credit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="credit" className="credit-select" name="credit" options={this.state.jvdata.typeofentry===4?credvalue:credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))} //.sort((a, b) => -a.subname.localeCompare(b.subname))
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.credit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("credit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="credit" label={values.credit!==undefined?values.credit.name:jv.creditname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.creditidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
   </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="creditval" className="form-control" label={values.creditval!==undefined?values.creditval:jv.creditidamt} type="text"
onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval)+parseInt(values.creditval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
  </>}</>:""}

{/* <div className="form-row col-md-12 mt-0 mb-0"> */}
{/* <fieldset className="form-group text-right col-md-1 mt-0 mb-0"></fieldset>
<fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
<label>{jv.creditname}</label>
</fieldset>
<fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
<NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.creditidamt} decimalScale={2}
                fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
</fieldset> */}


<fieldset className="form-group col-md-2 mt-0 mb-0" style={{ border:"1px solid black"}}>
<label>Narration:</label>
</fieldset>
<fieldset className="form-group col-md-10 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{jv.naration}</label>
</fieldset>

</>:""}

{this.state.cjv!==undefined?<>
  
  <fieldset className="form-group buttonColour text-left col-md-6 mt-0 mb-0">
  <label>Details</label>
  </fieldset>
  <fieldset className="form-group buttonColour text-center col-md-3 mt-0 mb-0">
  <label>Debit</label>
  </fieldset>
  <fieldset className="form-group buttonColour text-center col-md-3 mt-0 mb-0">
  <label>Credit</label>
  </fieldset>

    {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.debitidamt!==null ?<>
 {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{cjv.name}</label>
  </fieldset>
</>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0" style={{ border:"1px solid black"}}>
  <Field name="debit" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined
   }>
    {({ meta }) => (
<Autocomplete id="debit" className="debit-select" name="debit" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debit" label={values.debit!==undefined?values.debit.name:cjv.name}
variant="standard" fullWidth />)}/> 
)}</Field>
</fieldset>
</>}

{this.state.editclicked!==1?<>
<fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.debitidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>

  <fieldset className="form-group text-right col-md-3 mt-0 mb-0"  style={{ border:"1px solid black"}}>
  <Field name="debitval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined
   }>
    {({ meta }) => (
<FormikTextField name="debitval" className="form-control" label={values.debitval!==undefined?values.debitval:cjv.debitidamt} type="text"
 onBlur={() =>  values.debitval!==undefined?setFieldValue("debtotal", parseInt(values.debitval)):setFieldValue("debtotal", parseInt(cjv.debitidamt))}
 variant="standard"></FormikTextField>
 )}</Field>
 </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>

  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}


{cjv.debitidoneamt!==null ?<>
{this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0 text-sm" style={{ border:"1px solid black"}}>
    <label>{cjv.dioname}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 text-sm" style={{ border:"1px solid black"}}>
  <Field name="debitone" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="debitone" className="debitone-select" name="debitone" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debitone", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debitone" label={values.debitone!==undefined?values.debitone.name:cjv.dioname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset>
 </>}
 {this.state.editclicked!==1 ?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0 text-sm" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.debitidoneamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0 text-sm" style={{ border:"1px solid black"}}></fieldset>
  </>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 text-sm"  style={{ border:"1px solid black"}}>
  <Field name="debitoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="debitoneval" className="form-control" label={values.debitoneval!==undefined?values.debitoneval:cjv.debitidoneamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+values.debitoneval!==undefined?parseInt(values.debitoneval):parseInt(cjv.debitidamt))}
 variant="standard"></FormikTextField>)}</Field> </fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0 text-sm" style={{ border:"1px solid black"}}></fieldset>
  </>}</>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.debitidtwoamt!==null?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{cjv.ditwname}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="debittwo" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="debittwo" className="debittwo-select" name="debittwo" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debittwo", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debittwo" label={values.debittwo!==undefined?values.debittwo.name:cjv.ditwname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset>
  </>}

  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.debitidtwoamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="debittwoval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="debittwoval" className="form-control" label={values.debittwoval!==undefined?values.debittwoval:cjv.debitidoneamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>} </>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}
  
{cjv.debitidthreeamt!==null?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{cjv.dithname}</label>
  </fieldset> </>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="debitthree" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="debitthree" className="debitthree-select" name="debitthree" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debitthree", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debitthree" label={values.debitthree!==undefined?values.debitthree.name:cjv.dithname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset>
  </>}

  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.debitidthreeamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
    <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="debitthreeval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="debitthreeval" className="form-control" label={values.debitthreeval!==undefined?values.debitthreeval:cjv.debitidthreeamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)+parseInt(values.debitthreeval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
   <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>}</>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}

 
  {cjv.debitidfouramt!==null?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{cjv.difname}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="debitfour" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Debit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="debitfour" className="debitfour-select" name="debitfour" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debitfour", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debitfour" label={values.debitfour!==undefined?values.debitfour.name:cjv.difname}
variant="standard" fullWidth />)}/>)}</Field></fieldset></>}

{this.state.editclicked!==1 ?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.debitidfouramt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
  </fieldset>
    <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="debitfourval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="debitfourval" className="form-control" label={values.debitfourval!==undefined?values.debitfourval:cjv.debitidfouramt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)
 +parseInt(values.debitthreeval)+parseInt(values.debitfourval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
   {/* <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset> */}
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.taxidoneamt!==null ?<>
    {this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>SGST/IGST/VAT</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxone" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="taxone" className="taxone-select" name="taxone" options={tax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("taxone", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="taxone" label={values.taxone!==undefined?values.taxone.name:cjv.taxonename}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {cjv.debitidamt<cjv.creditidamt?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.taxidoneamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat></fieldset></>
  :<>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="taxoneval" className="form-control" label={values.taxoneval!==undefined?values.taxoneval:cjv.taxidoneamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)
 +parseInt(values.debitthreeval)+parseInt(values.debitfourval)+parseInt(values.taxoneval))}
 variant="standard"></FormikTextField>)}</Field></fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group  text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.taxidoneamt} decimalScale={2}
  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat></fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="taxoneval" className="form-control" label={values.taxoneval!==undefined?values.taxoneval:cjv.taxidoneamt} type="text"
  onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}</>}
  </>:""}
  
  {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.taxidtwoamt!==null?<>
  {this.state.editclicked!==1 ?<>
  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>CGST</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxtwo" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="taxtwo" className="taxtwo-select" name="taxtwo" options={tax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("taxtwo", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="taxtwo" label={values.taxtwo!==undefined?values.taxtwo.name:cjv.taxtwoname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {cjv.debitidamt<cjv.creditidamt?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.taxidtwoamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset></>:
  <><fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
    <Field name="taxtwoval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="taxtwoval" className="form-control" label={values.taxtwoval!==undefined?values.taxtwoval:cjv.taxidtwoamt} type="text"
 onBlur={() =>  setFieldValue("debtotal", parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)+
 parseInt(values.debitthreeval)+parseInt(values.debitfourval)+parseInt(values.taxoneval)+parseInt(values.taxtwoval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={jv.taxidtwoamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="taxtwoval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="taxtwoval" className="form-control" label={values.taxtwoval!==undefined?values.taxtwoval:cjv.taxidtwoamt} type="text"
  onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}</>}
  </>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}

    

  {cjv.dtaxidamt!==null?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0"></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{cjv.dtaxname}</label>
  </fieldset>
  </>:<>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="dtax" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Tax Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="dtax" className="dtax-select" name="dtax" options={directtax.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("dtax", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="dtax" label={values.dtax!==undefined?values.dtax.name:cjv.dtaxname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.dtaxidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="dtaxval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
  <FormikTextField name="dtaxval" className="form-control" label={values.dtaxval!==undefined?values.dtaxval:cjv.dtaxidamt} type="text"
 onBlur={() =>  setFieldValue("debtotal",parseInt(values.debitval)+parseInt(values.debitoneval)+parseInt(values.debittwoval)+parseInt(values.debitthreeval)
 +parseInt(values.debitfourval)+parseInt(values.taxoneval)+parseInt(values.taxtwoval)+parseInt(values.dtaxval))} variant="standard"></FormikTextField>)}</Field></fieldset></>}
  </>:""}
  
    {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.creditidamt!==null?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0"  style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{cjv.cname}</label>
  </fieldset></>:<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="credit" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Credit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="credit" className="credit-select" name="credit" options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.credit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("credit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="credit" label={values.credit!==undefined?values.credit.name:cjv.cname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.creditidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
   </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="creditval" className="form-control" label={values.creditval!==undefined?values.creditval:cjv.creditidamt} type="text"
onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval)+parseInt(values.creditval))}
 variant="standard"></FormikTextField>)}</Field></fieldset>
  </>}</>:""}

  {/* ---------------------------------------------------------------------------------------------------------------------- */}
  
  {cjv.creditidoneamt!==null?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{cjv.cidoname}</label>
  </fieldset></>:<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditone" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Credit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="creditone" className="creditone-select" name="creditone" options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.credit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("creditone", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="creditone" label={values.creditone!==undefined?values.creditone.name:cjv.cidoname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}

{this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.creditidoneamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="creditoneval" className="form-control" label={values.creditoneval!==undefined?values.creditoneval:cjv.creditidoneamt} type="text"
onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval)+parseInt(values.creditval)+parseInt(values.creditoneval))}
variant="standard"></FormikTextField>)}</Field></fieldset>
  </>}</>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.creditidtwoamt!==null?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{cjv.cidtwname}</label>
  </fieldset></>:<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="credittwo" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Credit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="credittwo" className="credittwo-select" name="credittwo" options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.credit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("credittwo", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="credittwo" label={values.credittwo!==undefined?values.credittwo.name:cjv.cidtwname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}


{this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.creditidtwoamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditoneval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="credittwoval" className="form-control" label={values.credittwoval!==undefined?values.credittwoval:cjv.creditidtwoamt} type="text"
onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval)
+parseInt(values.creditval)+parseInt(values.creditoneval)+parseInt(values.credittwoval))}
variant="standard"></FormikTextField>)}</Field> </fieldset>
  </>}</>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.creditidthreeamt!==null?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0"></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{cjv.cidthname}</label>
  </fieldset></>:<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0"></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditthree" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Credit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="creditthree" className="creditthree-select" name="creditthree" options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.credit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("creditthree", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="creditthree" label={values.creditthree!==undefined?values.creditthree.name:cjv.cidthname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}


{this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.creditidthreeamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditthreeval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="creditthreeval" className="form-control" label={values.creditthreeval!==undefined?values.creditthreeval:cjv.creditidthreeamt} type="text"
onBlur={() =>  values.creditthreeval!==undefined?setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval)+parseInt(values.creditval)
+parseInt(values.creditoneval)+parseInt(values.credittwoval)+parseInt(values.creditthreeval)):setFieldValue("cretotal",cjv.creditidthreeamt)}
variant="standard"></FormikTextField>)}</Field></fieldset>
  </>}</>:""}

    {/* ---------------------------------------------------------------------------------------------------------------------- */}


  {cjv.creditidfouramt!==null?<>
    {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-5 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>{cjv.cidfname}</label>
  </fieldset></>:<>
  <fieldset className="form-group text-right col-md-1 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditfour" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Please Select a valid Credit Ledger" : undefined}>
    {({ meta }) => (
<Autocomplete id="creditfour" className="creditfour-select" name="creditfour" options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.credit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("creditfour", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="creditfour" label={values.creditfour!==undefined?values.creditfour.name:cjv.cidfname}
variant="standard" fullWidth />)}/>)}</Field> </fieldset></>}


{this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.creditidfouramt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}></fieldset>
  <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm" style={{ border:"1px solid black"}}>
  <Field name="creditfourval" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Value cannot be blank" : undefined}>
    {({ meta }) => (
<FormikTextField name="creditfourval" className="form-control" label={values.creditfourval!==undefined?values.creditfourval:cjv.creditidfouramt} type="text"
onBlur={() =>  setFieldValue("cretotal", parseInt(values.taxoneval)+parseInt(values.taxtwoval)+parseInt(values.creditval)
+parseInt(values.creditoneval)+parseInt(values.credittwoval)+parseInt(values.creditthreeval)+parseInt(values.creditfourval))} variant="standard"></FormikTextField>)}</Field> </fieldset>
  </>}</>:""}
  
  {/* ---------------------------------------------------------------------------------------------------------------------- */}

  <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm" hidden>
<FormikTextField name="debtotal" className="form-control" label="%" type="text" variant="standard"/>
</fieldset>

<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm" hidden>
<FormikTextField name="cretotal" className="form-control" label="%" type="text" variant="standard"/>
</fieldset>

  {/* ---------------------------------------------------------------------------------------------------------------------- */}


  <fieldset className="form-group col-md-6 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>Total</label>
  </fieldset>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-3 text-right mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.debtotal+cjv.taxidoneamt+cjv.taxidtwoamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 text-right mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={this.state.debtotal+this.state.indirecttax} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>}
  {this.state.editclicked!==1?<>
  <fieldset className="form-group text-right col-md-3 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={cjv.cretotal+cjv.dtaxidamt} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>:<>
  <fieldset className="form-group col-md-3 text-right mt-0 mb-0" style={{ border:"1px solid black"}}>
  <NumericFormat className="pr-3 align-center" displayType={"text"} value={this.state.cretotal+this.state.directtax} decimalScale={2}
                  fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
  </NumericFormat>
  </fieldset></>}

  <fieldset className="form-group col-md-2 mt-0 mb-0" style={{ border:"1px solid black"}}>
  <label>Narration:</label>
  </fieldset>

  {this.state.jv===undefined || this.state.jv===null?<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-10 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{cjv.naration}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-10 mt-0 mb-0 " style={{ border:"1px solid black"}}>
  <Field name="narration" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Narration cannot be blank" : undefined
   }>
    {({ meta }) => (
    <FormikTextField name="narration" className="form-control" multiline rows={1}
                        label="Narration" variant="outlined" />
    )}</Field>                   
  </fieldset></>}
  </>:<>
  {this.state.editclicked!==1?<>
  <fieldset className="form-group col-md-10 mt-0 mb-0" style={{ border:"1px solid black"}}>
    <label>{jv.naration}</label>
  </fieldset></>:<>
  <fieldset className="form-group col-md-10 mt-0 mb-0 " style={{ border:"1px solid black"}}>
  <Field name="narration" validate={(value) => value === null || value === undefined || value === "" ||
    value.length === 0 ? "Narration cannot be blank" : undefined
   }>
    {({ meta }) => (
    <FormikTextField name="narration" className="form-control" multiline rows={1}
                        label="Narration" variant="outlined" />
    )}</Field>                   
  </fieldset></>}

  </>}
  </>:""}

</div>

</div>
<div className="form-row">
                        <fieldset className="form-group col-md-12">
                        {this.state.editclicked!==1?<>
                          {/* <button
                            className="btn hovbuttonColour mr-1 btn-sm"
                            type="button"
                            onClick={this.editSelect.bind()}
                          >
                           <i className="fas fa-pen"></i>
                            Edit
                          </button> */}
                 {this.state.jvdata.jvinsertiontype==="SALEADV"  || this.state.jvdata.jvinsertiontype==="SALEINV" || 
                 this.state.jvdata.jvinsertiontype==="PURINV" || this.state.jvdata.jvinsertiontype==="PURADV"?
                   "" :   
                   <button
                          className="btn deletebutton mr-1 btn-sm"
                          type="button"
                        onClick={this.DeleteEntry.bind()}
                        >
                         
                          <i className="fas fa-times" />
                          Delete
                        </button>}
                          
                          </>:""}
                          <button
                            className="btn deletebutton mr-1 btn-sm"
                            type="button"
                            onClick={this.goBack}
                          >
                           
                            <i className="fas fa-times" />
                            Close
                          </button>

                          {this.state.editclicked===1?
                          this.state.validated===false?
                            <button
                            className="btn hovbuttonColour mr-1 btn-sm"
                            type="submit">
                           <i className="fas fa-pen"></i>
                           Validate
                          </button>:
                           <button
                           className="btn hovbuttonColour mr-1 btn-sm"
                           type="button" onClick={this.editSub.bind()} >
                           <i className="fas fa-times" />
                              {this.state.loader === true ? (
                              <>
                                <Spinner animation="border" size="sm" />
                                &nbsp;{"Confirm"}
                              </>
                            ) : (
                              "Submit"
                            )}
                           
                         </button>
                       :""}
                       </fieldset>
                      </div>             
                  </Form>
                )}
              </Formik>

          
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(JvEntryDetails);
