import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { NumericFormat } from "react-number-format";
import {
  currentTenant,
  currentUser,
  thousandsdisplay,
  userid,
} from "../Common/Credentials";
import moment from "moment";
import {
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableCell,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import AuthenticationService from "../Authentication/AuthenticationService";
import { Spinner } from "react-bootstrap";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import { spin } from "../Common/NewLoader";
import Footer from "../Component/Footer";
import NewLedgerService from "../Accounts/Ledgers/NewLedgerService";
import Swal from "sweetalert2";

const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach((totamt) => {
    total += totamt;
  });
  return total;
};
let totaltaxes = []
  // sur = 0,
  // pen = 0; //pendigSalTds=[],,surcharge,totalamt,pendingamt,

let ppaymennts;

class DTaxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendigDirectTaxes: [],
      addedTaxes: [],
      surcharge: 0,
      loader: false,
      loader1:true,
      sur:0,pen:0,th:null,totaltaxes:[]
    };
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  onSubmit(values) {
    this.setState({ loader: true });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);
    }
    this.loadData();
    this.setState({ loading: false });
  }

  loadData() {
    NewLedgerService.getDirectTaxes(currentTenant)
      .then((response) => {
        this.setState({ pendigDirectTaxes: response.data },()=>this.setState({loader1:false}));
        // pendigSalTds=response.data
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }
  validate(values) {}
  addRecord(e) {
    Swal.fire({
      title: "Surcharge",
      text: "Do you want to add Surcharge",
      icon: "question",
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: "#152f5f",
    }).then((result) => {
      if (result.value === true) {
        Swal.fire({
          title: "Value!",
          text: "Enter Surcharge Value:",
          icon: "info",
          showCancelButton: true,
          input: "number",
          inputValidator: (value) => {
            if (!value) {
              return "You need to enter value!";
            }
            else if(this.state.th!==null && this.state.th!==e.th){
              alert("Asset and Liability cannot be combined")
              return "Asset and Liability cannot be combined!";
            }
            this.setState({sur:parseFloat(value),th:e.th},()=>
            e.surcharge = this.state.sur,
            e.totalamount = e.totaltaxamount + this.state.sur)
            Swal.fire({
              title: "Penalty",
              text: "Do you want to add Penalty",
              icon: "question",
              confirmButtonText: "Yes",
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonColor: "#152f5f",
            }).then((result) => {
              if (result.value === true) {
                Swal.fire({
                  title: "Value!",
                  text: "Enter Penalty Value:",
                  icon: "info",
                  showCancelButton: true,
                  input: "number",
                  inputValidator: (value) => {
                    if (!value) {
                      return "You need to enter value!";
                    }
                    else if(this.state.th!==null && this.state.th!==e.th){
                      alert("Asset and Liability cannot be combined")
                      return "Asset and Liability cannot be combined!";
                    }
                    this.setState({pen:parseFloat(value),th:e.th},()=>
                    e.penalty = this.state.pen,
                    e.totalamount = e.totaltaxamount + this.state.sur + this.state.pen)
                    this.updateState(e)
                  },
                }).then(() =>
                  this.updateState(e));
              }else if(this.state.th!==null && this.state.th!==e.th){
                alert("Asset and Liability cannot be combined")
              } else {
                this.setState({th:e.th})
                e.totalamount = e.totaltaxamount + this.state.sur;
                this.updateState(e);
              }
            });
          },
        })//.then(() => 
         // this.state.th!==null && this.state.th!==e.th?this.updateState(e):null);
        } else {
        Swal.fire({
          title: "Penalty",
          text: "Do you want to add Penalty",
          icon: "question",
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#152f5f",
        }).then((result) => {
          if (result.value === true) {
            Swal.fire({
              title: "Value!",
              text: "Enter Penalty Value:",
              icon: "info",
              showCancelButton: true,
              input: "number",
              inputValidator: (value) => {
                if (!value) {
                  return "You need to enter value!";
                }
                else if(this.state.th!==null && this.state.th!==e.th){
                  alert("Asset and Liability cannot be combined")
                  return "Asset and Liability cannot be combined!";
                }
                this.setState({pen:parseFloat(value),th:e.th},()=>
                    e.penalty = this.state.pen,
                    e.totalamount = e.totaltaxamount + this.state.pen)
                    this.updateState(e)
              },
            }).then(() => 
              this.updateState(e));
             // this.state.th!==null && this.state.th!==e.th?this.updateState(e):null);
            }else if(this.state.th!==null && this.state.th!==e.th){
            alert("Asset and Liability cannot be combined")
          }else {
            this.setState({th:e.th})
            e.totalamount = e.totaltaxamount;
            this.updateState(e);
          }
        });
      }
      });
  }
  removeRecord(e) {
    this.setState({addedTaxes:this.state.addedTaxes.filter((data)=> data.id != e.id )})
    this.state.pendigDirectTaxes.push(e);
    this.setState({ loading: false });
  }
  updateState(e) {
    let abc
    if(this.state.addedTaxes!==null && this.state.addedTaxes.length===0 ){
    this.state.addedTaxes.push(e);
    } 
    this.state.addedTaxes.map(f=>{return abc=f.id}) 
    if(abc!==e.id){
      this.state.addedTaxes.push(e);
    }
    this.setState({pendigDirectTaxes:this.state.pendigDirectTaxes.filter((data)=> data.id !== e.id )})
    let a =this.state.addedTaxes.map((e) => (e.totaltaxamount?e.totaltaxamount:0)+(e.surcharge?e.surcharge:0)+(e.penalty?e.penalty:0))
    this.setState({totaltaxes:a})

    this.setState({ loading: false });
  }
  submitRecord(id) {
    if (id !== undefined) {
      let pendingdtaxes;
      pendingdtaxes = {
        pendingdtaxes: this.state.addedTaxes,
        total: getTotal(totaltaxes),
      };
      this.props.navigate({pathname:`/dtp`}, {state:pendingdtaxes });
    }
  }

  render() {
    let ppayments = null,
      addedpmts = null;
    ppayments = this.state.pendigDirectTaxes;
    addedpmts = this.state.addedTaxes;
    totaltaxes = this.state.addedTaxes.map((e) => e.totalamount);

    return (
      <>
        <Header />
        <Menu />
        <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="content-wrapper">
            <div className="card card-info">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item text-secondary">
                  <Link to="/accounts">Home</Link>
                </li>
                <li className="breadcrumb-item text-secondary">
                  <Link to={{ pathname: "/journals" }} state={{ message: "" }}>
                    Taxes
                  </Link>
                </li>
              </ol>
              <div className="text-center text-danger">{this.state.error}</div>
              <div className="card-body">
                <div className="text-center">
                  {this.state.loading === true ? spin : ""}
                </div>

                <Formik
                  initialValues={{ ppaymennts }}
                  onSubmit={this.onSubmit}
                  validateOnChange={true}
                  validate={this.validate}
                  enableReinitialize={true}
                >
                  {({ values }) => (
                    <Form autoComplete="off">
                      <div className="form-row mt-0 mb-0">
                        <fieldset className="form-group col-md-12 align-right">
                          <span className=" align-center text-green">
                            Direct Taxes
                          </span>
                          <TableContainer>
                            <Table
                              className="table-sm"
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow className="col-md-12 text-sm">
                                  <TableCell className="form-group col-md-2 text-sm">
                                    Type
                                  </TableCell>
                                  <TableCell className="form-group col-md-2 text-sm">
                                    For
                                  </TableCell>
                                  <TableCell className="form-group col-md-2 text-sm">
                                    Towards
                                  </TableCell>
                                  <TableCell className="form-group col-md-2 text-sm">
                                    Date
                                  </TableCell>
                                  <TableCell className="form-group col-md-2 text-sm">
                                    Pan
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm ">
                                    Pending Amt
                                  </TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.pendigDirectTaxes.length !== 0 ? (
                                  this.state.pendigDirectTaxes.map((e, i) => (
                                    <TableRow key={i}>
                                      <TableCell className="form-group col-md-2 text-sm">
                                        {e.itemname}
                                      </TableCell>
                                      <TableCell className="form-group col-md-2 text-sm">
                                        {e.ledgername}
                                      </TableCell>
                                      <TableCell className="form-group col-md-2 text-sm">
                                        {e.ieledger}
                                      </TableCell>
                                      <TableCell className="form-group col-md-2 text-sm">
                                        {moment(e.createdAt).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </TableCell>
                                      <TableCell className="form-group col-md-2 text-sm">
                                        {e.pan}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1">
                                        <NumericFormat
                                          className="text-right"
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={e.totaltaxamount}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          as="button"
                                          to={this}
                                          className="fa fa-plus-square text-success"
                                          onClick={() => this.addRecord(e)}
                                        >
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell>
                                      <span className=" align-center text-green">
                                        No Payments Pending
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <br></br>
                        </fieldset>
                        <fieldset className="form-group col-md-12 align-right">
                          <span className=" align-center text-green">
                            Direct Taxes
                          </span>
                          <TableContainer>
                            <Table
                              className="table-sm"
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow className="col-md-12 text-sm">
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Type
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    For
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Towards
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Date
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Pan
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm ">
                                    Pending Amt
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Surcharge
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Penalty
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Total
                                  </TableCell>
                                  <TableCell className="form-group col-md-1 text-sm">
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.addedTaxes.length !== 0 ? (
                                  this.state.addedTaxes.map((z, i) => (
                                    <TableRow key={i}>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        {z.itemname}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        {z.ledgername}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        {z.ieledger}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        {moment(z.createdAt).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        {z.pan}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1">
                                        <NumericFormat
                                          className="text-right"
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={z.totaltaxamount}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        <NumericFormat
                                          className="text-right"
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={z.surcharge}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                      <NumericFormat
                                          className="text-right"
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={z.penalty}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                        {/* {z.penalty} */}
                                      </TableCell>
                                      <TableCell className="form-group col-md-1 text-sm">
                                        <NumericFormat
                                          className="text-right"
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={(z.totaltaxamount?z.totaltaxamount:0)+(z.surcharge?z.surcharge:0)+(z.penalty?z.penalty:0)}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          as="button"
                                          to={this}
                                          className="fa fa-minus-square text-danger"
                                          onClick={() => this.removeRecord(z)}
                                        >
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell>
                                      <span className=" align-center text-green">
                                        No Added Pending
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow bgcolor="#EBECF0">
                                  <TableCell className="col-md-2 text-sm">
                                    Total
                                  </TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-2 text-sm"></TableCell>
                                  <TableCell className="col-md-1 text-right">
                                    <NumericFormat
                                      className="text-right"
                                      displayType={"text"}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      disabled={true}
                                      value={getTotal(this.state.totaltaxes)}
                                      thousandSeparator={true}
                                      thousandsGroupStyle={thousandsdisplay}
                                    ></NumericFormat>
                                  </TableCell>
                                  <TableCell>
                                    {addedpmts.length !== 0 ? (
                                      <button
                                        className="btn btn-info btn-sm"
                                        type="Submit"
                                        onClick={() =>
                                          this.submitRecord(values)
                                        }
                                      >
                                        {" "}
                                        {this.state.loader === true ? (
                                          <>
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                            />
                                            &nbsp;{"Submitting"}
                                          </>
                                        ) : (
                                          "Payment"
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <br></br>
                        </fieldset>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(DTaxes);
