import React, { Component } from 'react'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {ColorRing} from "react-loader-spinner";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from '../../Common/Credentials.js';
import { accountsid } from '../../Common/Permissions.js';
import SubHeadsService from './SubHeadsService.js';
// import { currentTenant } from '../../Common/Credentials.js';

import { Backdrop, CircularProgress, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import MUIDataTable from 'mui-datatables';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

export let subheads,ledgroups
class SubHeadDataTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:this.props.location!==undefined?this.props.location.state.message:"",
        SubHeads:[],
        LedGroups:[],
        loading:false,
        loader1:true,
        selectedoption:'',
        selectbtn:[
          {id:1,name:"Sub Heads"},
          {id:2,name:"Ledger Groups"},],
        // UserPermissions:'',


    }
    this.refreshSubAcc=this.refreshSubAcc.bind(this)
    this.deleteSubHead=this.deleteSubHead.bind(this)
    this.updateSubHead=this.updateSubHead.bind(this)

  }



  deleteSubHead(id){
    SubHeadsService.deleteSubHeads(id)
   .then( response =>
     this.setState({message:id+" : " +response.data,loading:false},()=> this.refreshSubAcc(),)
     )
    }

    deleteLedGroup(id){
      SubHeadsService.deleteLedGroup(id)
     .then( response =>
       this.setState({message:id+" : " +response.data,loading:false},()=> this.refreshSubAcc(),)
       )
      .catch(err =>
        this.setState({message:"Group Cannot be deleted as it is in use",loading:false},()=> this.refreshSubAcc(),)
      )
      }

onChange(option){
  this.setState({selectedoption: option.id});
}

updateSubHead(id){
  SubHeadsService.retriveSubAcc(id)
    .then(
      response => {
          this.setState({SubHeads:response.data,loading1:false})

      })}


  
  refreshSubAcc(){
    SubHeadsService.getSubHeads(currentTenant)
    .then(
      response => {
          this.setState({SubHeads:response.data,loading1:false},()=>this.setState({loader1:false}))
          subheads=response.data
        })  
      SubHeadsService.getLedGroups(currentTenant)
    .then(
      response => {
          this.setState({LedGroups:response.data,loading1:false},()=>this.setState({loader1:false}))
          ledgroups=response.data
        })    
  }

    componentDidMount () {

      // AccTopService.retriveAllAccTop()
      // .then(response => {
      //   this.setState({TopAcc:response.data})
      // })
      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

      // if (this.props.history.location.state.message===undefined){
      //   this.setState({message:''})
      // }else{
      //   this.setState({message:this.props.history.location.state.message})
      // }
      // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))
      this.refreshSubAcc();

     }

    

    render() {
      let spin = <ColorRing
    type="Watch"
    color="Blue"
    height={100}
    width={200}
    timeout={this.state.loading1===false} //3 secs
  />
       let confirm = this.state.message
      confirm.search("Deleted")?
      confirm = <div className="text-center text-success text-uppercase">{this.state.message}</div>
      :
      confirm = <div className="text-center text-warning text-uppercase">{this.state.message}</div>


      const columns = [
        {name: "id",label: "Id", options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },},
        {name: "code",label: "Code",options: { filter: true, sort: true },},
        {name: "name",label: "Sub Heads",options: { filter: true, sort: true },},
        { name: "saname",label: "Accounts",options: { filter: true, sort: true }, },
        {name: "tenantId",label: "tenantId", options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },},
        {name: "Actions",options: {filter: false, sort: true, empty: true,
            display: accountsid === "2" || accountsid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<>
                {tableMeta.rowData[3]==="ALL"?<span className="textColour mr-2">Default Head</span>:<>
                <Link as="button" className="fa fa-edit textColour mr-2"
                  to={{pathname: "/newsubheads"}} state= {{ id:tableMeta.rowData[0],name:tableMeta.rowData[1], pagename:'Edit Sub Head' }}>
                </Link>
                <>
                {this.state.SubHeads.length>1 ?
                <span as="button" data-tip data-for="delete" className="fa fa-trash text-danger"
                  onClick={() => {
                    if ( window.confirm(`Are you sure you wish to delete Sub Head ${tableMeta.rowData.find((e) => e )}?`))
                      this.deleteSubHead(tableMeta.rowData.find((e) => e)); }}></span>:''}</>
                    </> }
                     {/* </> ))} */}
                       </>
              );
            },
          },
        },
      ]
      const options = { filterType: "checkbox",selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {accountsid === "2" || accountsid === "3" ? (
                <Link as="button" className="float-right btn btn-sm m-0 p-0"
                  to={{ pathname: "/newsubheads"}} state= {{ id: -1, pagename:'New SubHead'}}>
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };

      const columnsled = [
        {name: "id",label: "Id", options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },},
        {name: "name",label: "Names",options: { filter: true, sort: true },},
        {name: "Actions",options: {filter: false, sort: true, empty: true,
            display: accountsid === "2" || accountsid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<>
                {tableMeta.rowData[3]==="ALL"?<span className="textColour mr-2">Default Head</span>:<>
                <Link as="button" className="fa fa-edit textColour mr-2"
                  to={{pathname: "/newledgroups"}} state= {{ id:tableMeta.rowData[0],name:tableMeta.rowData[1], pagename:'Edit Ledger Group' }}>
                </Link>
                <>
                {this.state.SubHeads.length>1 ?
                <span as="button" data-tip data-for="delete" className="fa fa-trash text-danger"
                  onClick={() => {
                    if ( window.confirm(`Are you sure you wish to delete Ledger Group ${tableMeta.rowData.find((e) => e )}?`))
                      this.deleteLedGroup(tableMeta.rowData.find((e) => e)); }}></span>:''}</>
                    </> }
                     {/* </> ))} */}
                       </>
              );
            },
          },
        },
      ]
      const optionsled = { filterType: "checkbox",selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {accountsid === "2" || accountsid === "3" ? (
                <Link as="button" className="float-right btn btn-sm m-0 p-0"
                  to={{ pathname: "/newledgroups"}} state= {{ id: -1, pagename:'New Ledger Group' }}>
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };


        return (
            <div>
               <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
      <div className="card">
            {/* <div className="card-header">
              <h3 className="card-title text-uppercase">SubHeads</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">SubHeads</li>
            </ol>
          {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
          {confirm}
            <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

 {this.state.selectbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onChange.bind(this,option)} 
               style={{marginRight: 8,width:400}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
}
              
              {/* /.card */}

              <div className="card">
              

                {/* <div className="card-header">
                  <h3 className="card-title ">
                  SubAcc 
                  </h3>
                </div> */}
                {/* /.card-header */}
                <div className="card-body">
                {this.state.selectedoption===1?
                                    <div>{this.state.loading === true ?<div className="text-center">{spin}</div> : 
                                    <ThemeProvider theme={theme}>
                                      <MUIDataTable
                                      className="table-sm px-3"
                                      title={"Sub Heads"}
                                      data={this.state.SubHeads}
                                      columns={columns}
                                      options={options}
                                    />
                                    </ThemeProvider>
                                    }</div> :""}

            {this.state.selectedoption===2?
                                    <div>{this.state.loading === true ?<div className="text-center">{spin}</div> : 
                                    <ThemeProvider theme={theme}>
                                      <MUIDataTable
                                      className="table-sm px-3"
                                      title={"Ledger Groups"}
                                      data={this.state.LedGroups}
                                      columns={columnsled}
                                      options={optionsled}
                                    />
                                    </ThemeProvider>
                                    }</div> :""}

                <div>
                <div className="text-center">
            {this.state.loading===true?spin:''}
            </div>

                </div>
                
                      </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>


        
     </div>
                
            </div>
             
          
        )
       
    }
    
}
export default withRouter(SubHeadDataTable)