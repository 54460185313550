import React, { useState } from "react";
import { useLocation } from "react-router";
import {Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { currentTenant, financialyearend, thousandsdisplay } from '../../Common/Credentials';
import { ThemeProvider } from 'react-bootstrap';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { spin } from '../../Common/NewLoader';
import Footer from '../../Component/Footer';
import MUIDataTable from "mui-datatables";
import { FormControlLabel, TextField, createTheme } from "@mui/material";
import { Autocomplete,Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import moment from "moment";
import NewJournalService from "../NewJournalService";
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";



export default function LedgersViewNew(props){
  const history = useLocation();
  const [loader] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedLed, setselectedLed] = useState([]);
  const [openingamt, setopeningamt] = useState([]);
  const [SDdate, setSDdate] = useState([false]);
  const [fsd, setFsd] = useState(null);
  const [fed, setFed] = useState(null);
  const [currentamt, setcurrentamt] = useState([]);
  const [debtotal, setdebtotal] = useState([]);
  const [cretotal, setcretotal] = useState([]);
  const [ledgercode,setledgercode]=useState([]);



  const [selectedledger, setSelectedledger] = useState('');

  const getTotal = (value) => {
    let total = 0;
    value.forEach(vtotal => {
      total += vtotal;
    });
    return total;
  };
  
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e9ecef !important",
          border: [[1, "solid", "#ece6e6"]],
        },
        body: {
          border: [[1, "solid", "#ece6e6"]],
        },
      },
    },
  });

  let data={
    tenantId:currentTenant,
    fromDate:fsd,
    toDate:fed
  }

  function getData() {
  NewJournalService.getPartyLedgersView(data).then((response) =>{
    setBanks(response.data);
  });
}

  const submitRecord=(value)=>{
    let directaddedstock
    if(value!==undefined){
  //  directaddedstock=dirstock.filter(e=>e.id===value.rowData[0])
    } 
    history.push(`/dirstockentry`, {directaddedstock});
   }

   function handleSDDate() {
    setSDdate(SDdate===true?false:true)
    if(SDdate===false){
      setFed(null)
      setFsd(null)
    }
  }
 
  function  handleFsdDateChange(date) {
    if(date!==null){
    //setFsd(date)
    setFsd(moment(date.$d).format("yyyy-MM-DD HH:mm:ss"))
  }else{
    setSDdate(false)
  }
  };

  function  handleFsdDateChange1(date) {
    if(date!==null){
   // setFsd(date)
    setFed(moment(date.$d).format("yyyy-MM-DD HH:mm:ss"))
  }else{
    setSDdate(false)
  }
  };
  
  const Bankcolumns = [
    {
      name: "behav",
      label: "EntryType",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        customBodyRender: (tableMeta) => {
          return moment(tableMeta).format("DD-MM-YYYY hh:mm");
        },
      },
    },    
    {name: "orderid",label: "Type",options: {filter: false,sort: true,}},
    {name:"debitname",label:"Dr Ledger",options: {filter: true,sort: true,
    //  customBodyRender: (tableMeta, values) => {
    //    // console.log(values.rowData.find((e) => e))
    //     return values.rowData.find((e) => e) !== 1?tableMeta:"-NA-"

    //      }
      }},
      {name:"creditname",label:"Cr Ledger",options: {filter: true,sort: true,
        // customBodyRender: (tableMeta, values) => {
        //   // console.log(values.rowData.find((e) => e))
        //    return values.rowData.find((e) => e) === 1?tableMeta:"-NA-"
   
        //     }
         }},

    {
      name: "currentamt",
      label: "Previous/Opening Balance",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (tableMeta) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={tableMeta}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "jvamount",
      label: "Debit",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (tableMeta, values) => {
          //  console.log(values.rowData[10])
          return (
            <>
              {values.rowData[10] ==="DEBIT" ?//("+"):("-")}
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat> :0}
            </>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "jvamount",
      label: "Credit",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (tableMeta, values) => {
          //console.log(values.rowData.find(e=>e))
          return (
            <>
              {values.rowData[10]=== "CREDIT" ?
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat> : 0}
            </>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
      },
    },

    {
      name: "amount",
      label: "Current/Closing Balance",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (tableMeta) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={tableMeta}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
        //   customHeadRender: (columnMeta,) => (
        //     <div style={{style: { textAlign:'right'}}}>
        //         {columnMeta.name}
        //     </div>
        // )
      },
    },

    {
      name: "id",
      label: "Transaction",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <>
            //   {value !== null ? (
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  to={{ pathname: "/jvdetails"}} state= {{ id: value}}
                >
                  {value.id}
                </Link>
            //   ) : tableMeta.rowData[7] !== null ? (
            //     <Link
            //       as="button"
            //       data-tip
            //       data-for="view"
            //       to={{
            //         pathname: "/jvdetails"}}
            //         state={{ id: tableMeta.rowData[7]}}
            //     >
            //       {tableMeta.rowData[7].id}
            //     </Link>
            //   ) : (
            //     "Opening"
            //   )}
            // </>
          );
        },
      },
      setCellProps: (value) => ({ style: { textAlign: "center" } }),
    },
    {
      name: "entryType",
      label: "EntryType",
      options: {
        filter: false,
        sort: false,
         display: false,
        viewColumns: false,
      },
    },


    //{name: "warehouse",label:"Branch/Warehouse",options: {filter: false,sort: true,}},
   // {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
    //   customBodyRender:(tableMeta)=>{
    //     return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //   },
    //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // }},
    // {name: "amount",label: "Total Amount",options: {filter: false,sort: true,sortOrder: 'asc',
    //   customBodyRender:(tableMeta)=>{
    //     return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //   },
    //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // }},
    // {name: "id",label:<span className="text-bold">Action</span>,options: {filter: false,sort: false,display:true,viewColumns:false,    
    //   customBodyRender:(value,tableMeta)=>{
    //     return (
    //       value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
    //       onClick={() => submitRecord(tableMeta)}>  {loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pass Entry"}
    //       </button>:<span className="text-red text-bold">No Values</span>
    //     );      
    //   },
    //   setCellProps: value => ({ style: { outerHeight:100}}),
    // }},
   ];


   const Bankoptions = {
    filterType: 'checkbox',
    selectableRows: "none",
    resizableColumns: true,

  }

   const onLedgerSelect=(value, values)=>{
    if (value !== undefined || value !== "") {
      let val = banks.filter((e) => e.ledgername === value).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);
      let val1 = banks.filter((e) =>  e.isopening!==1 && e.ledgername === value).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);
      setledgercode(val[0].ledgercode)

      let v1= val.filter(e=>e.entryType==="DEBIT");
      let v2= val.filter(e=>e.entryType==="CREDIT");

      let v3 = v2.map(e=>e.jvid!==null && e.jvid.creditname==="Settlement" ?e.jvamount:0)
      // console.log(getTotal(v3)*2)

      setdebtotal(parseFloat(getTotal(v1.map(e=>e.jvamount))+getTotal(v3)*2).toFixed(2))
     setcretotal(parseFloat(getTotal(v2.map(e=>e.jvamount))).toFixed(2))
      
// console.log(val)
      setselectedLed(val1)
      setSelectedledger(value)
      let opeamt,cloamt;
      val.map((e)=>e.isopening!==null  && e.isopening===1 ?(opeamt=e.amount) :(opeamt=0) )

      const closingbalance = [
        ...new Set(
          val.map((e) => {
            return e.closingbalance;
          })
        ),
      ];

     // val.map((e)=>e.closingbalance!==null ?cloamt=closingbalance :cloamt=0)

      // let val1 = val.filter(e=>e.jvid !== null?e.jvid:e.cjvid)
    // 
      setopeningamt(opeamt)
      setcurrentamt(closingbalance!==null?closingbalance:0)
      // console.log(val)

      // this.setState({ selectedLed: val, selectedledger: value });
    }
  }

  const ledgers = [
    ...new Set(
      banks.map((e) => {
       // let a= e.ledgercode!==null?e.ledgername.trim().concat(" [" + e.ledgercode + "]"):e.ledgername.trim();
       ////  return a.trim()
      return  e.ledgername.trim();

      })
    ),
  ];

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item active">Ledger</li>
          </ol>
          <div className="form-row">
            <div className="card-body">
              <div>
                {banks === null ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <>
                  <div className="mb-5">
                  <Formik
                  initialValues={{ ledgersel: "", ledgers }}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => (
                    <Form autoComplete="off">
                     <span className="text-red"> </span>
                     <div className="form-row col-md-12 text-left">
    <div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" onChange={handleSDDate} name="checkedB" />}/> 
     <span className="text-left textColour">{SDdate===false?"Standard":"Custom Date"}</span>
     {/* checked={SDdate}  */}
       </div>
    {SDdate===true?<>
     <div className="form-group col-md-1 text-left">
    <label>From Date</label></div>
    <div className="form-group col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    //minDate={new Date(moment(this.state.crdate).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={fsd}
    // label="Custom Date" 
    //crdate financialyearstart
    InputAdornmentProps={{position: "start"}} onChange={handleFsdDateChange}
    renderInput={(params) => <TextField {...params} variant="standard"/>} autoOk={true}/>
    </Grid></LocalizationProvider>
    </div>
<div className="form-group col-md-1 text-left">
<label>To Date</label></div>
<div className="form-group col-md-3 text-left">
<LocalizationProvider dateAdapter={AdapterDayjs}>
<Grid  justifyContent="space-around">
<DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
id="date-picker-dialog" inputVariant="standard"
//minDate={new Date(moment(this.state.crdate).format("yyyy-MM-DD"))}
maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
format="dd/MM/yyyy HH:mm" value={fed}
// label="Custom Date" 
//crdate financialyearstart
InputAdornmentProps={{position: "start"}} onChange={handleFsdDateChange1}
renderInput={(params) => <TextField {...params} variant="standard"/>} autoOk={true}/>
</Grid></LocalizationProvider></div></>
     :null}
     <div className="form-group col-md-1 text-left">
      <button
                      type="submit"
                      value="Add"
                      onClick={getData}
                      className=" btn btn-sm hovbuttonColour mr-2">
                      Search
                    </button>
                    </div>
                  {/* //  <i className="fa fa-save mr-1"></i> */}
     </div>
                     <div className="form-row col-md-12 mt-0 mb-3">
                  <fieldset className="form-group col-md-4 mt-0 mb-3">
                          <Autocomplete
                            id="aledgers"
                            className="grn-select "
                            name="aledersel"
                            options={ledgers}
                            getOptionLabel={(option) => option}
                            
                            
                            value={values.ledgersel ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("ledgersel", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control mb-3"
                                type="text"
                                name="ledgersel"
                                label="Select Ledger"
                                onBlur={(e) => {
                                  onLedgerSelect(e.target.value, values);
                                }}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-right">
                          <label>Opening :   </label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-left">
                          <label>{openingamt}</label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-right">
                          <label>DebTot :   </label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-left">
                          <label>{debtotal}</label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-right">
                          <label>CreTot :   </label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-left">
                          <label>{cretotal}</label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-right">
                          <label>Current :   </label>
                        </fieldset>
                        <fieldset className="form-group col-md-1 mt-0 mb-3 text-left">
                          <label>
                          {currentamt}
                         </label>
                        </fieldset> 
                        </div>

   
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3 text-sm"
                        title={ledgercode!==null?"Ledger - " + ledgercode:"Ledger" }
                        id="dirstock"
                        data={selectedLed.map(oth=>{
                          return[oth.behav,oth.createdAt,
                            oth.orderid!==null && oth.orderid.charAt(0)==='S'?oth.custominvid:oth.orderid!==null && oth.orderid.charAt(0)==='P'?oth.custompoid:oth.genadvanceadded!=null?"Advance":oth.orderid,
                            oth.jvid!==null?oth.jvid.debitname:oth.cjvid!==null?oth.cjvid.name:"Opening",
                            oth.jvid!==null?oth.jvid.creditname:oth.cjvid!==null && oth.cjvid.jvinsertiontype==="SALARIES"?oth.cjvid.cidthname:oth.cjvid!==null?oth.cjvid.cname:"Opening",
                            oth.jvid!==null?oth.currentamt:oth.cjvid!==null?oth.genadvanceadded!=null?oth.jvamount:oth.currentamt:oth.jvamount,
                            oth.jvid!==null ?oth.jvamount:oth.cjvid!==null ?oth.jvamount:"Opening", 
                            oth.jvid!==null ?oth.jvamount:oth.cjvid!==null ?oth.jvamount:null,
                            oth.genadvanceadded!=null?oth.genadvancecurrent:oth.amount,
                            oth.jvid!==null?oth.jvid:oth.cjvid!==null?oth.cjvid:"Opening",
                            oth.entryType!==null?oth.entryType:0


                            // oth.jvid!==null?oth.jvid.creditname:"Opening",
                            // oth.jvid!==null?oth.jvid.debitname:"Opening",
                            // oth.jvid!==null?oth.currentamt:oth.jvamount,
                            // oth.jvid!==null?oth.jvamount:"Opening", oth.jvid!==null?oth.jvamount:null,oth.amount,oth.jvid!==null?oth.jvid:"Opening"
                          ]
                        })}
                        columns={Bankcolumns}
                        options={Bankoptions}
                      />
                    </ThemeProvider>
                    </Form>
                  )}
                </Formik>
                    </div>

                  </>
                )} 
              </div>

            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );


}         



 
