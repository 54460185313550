import React, { Component } from 'react'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {ColorRing} from "react-loader-spinner";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import SubAccService from './SubAccService.js';
import { accountsid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import { createTheme } from '@mui/material';
import { ThemeProvider } from "@mui/material";
import MUIDataTable from 'mui-datatables';

// import { currentTenant } from '../../Common/Credentials.js';

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class SubAccDataTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:this.props.location!==null?this.props.location.state.message:null,
        SubAcc:[],
        loading:false,
        // UserPermissions:'',


    }
    this.refreshSubAcc=this.refreshSubAcc.bind(this)
    this.deleteSubAcc=this.deleteSubAcc.bind(this)
    this.updateSubAcc=this.updateSubAcc.bind(this)

  }



  deleteSubAcc(id){
    SubAccService.deleteSubAcc(id)
   .then( response =>
     this.setState({message:id+" : " +response.data,loading:false},()=>this.refreshSubAcc()),
     )
}

updateSubAcc(id){
  SubAccService.retriveSubAcc(id)
    .then(
      response => {
          this.setState({SubAcc:response.data,loading:false})

      })}


  
  refreshSubAcc(){
  SubAccService.retriveAllSubAcc(currentTenant)
    .then(
      response => {
          this.setState({SubAcc:response.data,loading:false})
          const script = document.createElement("script");
        script.src= '../../../js/content.js';
        script.async= true;
        document.body.appendChild(script);
        }
        
      )   
    
  }

    componentDidMount () {
   //console.log(this.props)
      // AccTopService.retriveAllAccTop()
      // .then(response => {
      //   this.setState({TopAcc:response.data})
      // })
      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

      if (this.props.location.state.message===null){
        this.setState({message:''})
      }else{
        this.setState({message:this.props.location.state.message})
      }
      // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))
      this.refreshSubAcc();

     }

    

    render() {
      let spin = <ColorRing
    type="Watch"
    color="Blue"
    height={100}
    width={200}
    timeout={this.state.loading===false} //3 secs
  />
       let confirm = this.state.message
      confirm==="Deleted Successfully"?
      confirm = <div className="text-center text-success text-uppercase">{this.state.message}</div>
      :
      confirm = <div className="text-center text-danger text-uppercase">{this.state.message}</div>

      const columns = [
        {name: "id",label: "Id", options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },},
        {name: "name",label: "Accounts",options: { filter: true, sort: true },},
        {name: "code",label: "Code",options: { filter: true, sort: true },},
        { name: "thname",label: "Acc Type",options: { filter: true, sort: true }, },
        {name: "tenantId",label: "tenantId", options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },},
        {name: "Actions",options: {filter: false, sort: true, empty: true,
            display: accountsid === "2" || accountsid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<>
                {tableMeta.rowData[3]==="ALL"?<span className="textColour mr-2">Default Account</span>:<>
                <Link as="button" className="fa fa-edit textColour mr-2"
                  to={{pathname: "/newsubacc"}} state= {{ id:tableMeta.rowData[0],name:tableMeta.rowData[1], pagename:'Edit Sub Account' }}>
                </Link>
                <>
                {this.state.SubAcc.length>1 ?
                <span as="button" data-tip data-for="delete" className="fa fa-trash text-danger"
                  onClick={() => {
                    if ( window.confirm(`Are you sure you wish to delete SubAccount. ${tableMeta.rowData.find((e) => e )}? This will delete all attached ledgers. Are you sure`))
                      this.deleteSubAcc(tableMeta.rowData.find((e) => e)); }}></span>:''}</>
                    </> }
                     {/* </> ))} */}
                       </>
              );
            },
          },
        },
      ]
      const options = { filterType: "checkbox",selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {accountsid === "2" || accountsid === "3" ? (
                <Link as="button" className="float-right btn btn-sm m-0 p-0"
                  to={{ pathname: "/newsubacc"}} state= {{ id: -1, pagename:'New Sub Account'  }}>
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };

    
        return (
            <div>

      <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title text-uppercase">SubAcc</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">SubAcc</li>
            </ol>
          {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
          {confirm}
            <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              
              {/* /.card */}
              <div className="card">
              
              <div className="card-body">
                                    <div>{this.state.loading === true ?<div className="text-center">{spin}</div> : 
                                    <ThemeProvider theme={theme}>
                                      <MUIDataTable
                                      className="table-sm px-3"
                                      title={"Sub Heads"}
                                      data={this.state.SubAcc}
                                      columns={columns}
                                      options={options}
                                    />
                                    </ThemeProvider>
                                    }</div>                              
             </div>
                {/* <div className="card-header">
                  <h3 className="card-title ">
                  SubAcc 
                  </h3>
                </div> */}
                {/* /.card-header */}
                
                <div className="card-body">
                {/* <div>
                <div className="text-center">
            {this.state.loading===true?spin:''}
            </div>
                {appmasterid === '3' || appmasterid === '2' ?
                <Link as="button" className="float-right btn btn-sm btn-warning" to={{pathname: '/newsubacc'}} state ={ {id: -1,pagename:'New SubAcc'}}}>New SubAcc</Link>
                :''} 
                </div> */}
                
       {/* <table
       id="example1"
       className="table table-bordered table-striped">
      
       <thead>
         <tr>
         <th>Accounts</th>
         <th>Top Heads</th>
         {appmasterid === '3' || appmasterid === '2' ?
         <th>Action</th>
         :''} 
         </tr>
       </thead>
       
       <tbody>
         
         {
           this.state.SubAcc.map(
             result => 
             <tr key={result.id}>
               <td className="text-blue">{result.name}</td>
               <td className="text-orange">{result.thname}</td>
               {result.createdBy===0?
               <td className="text-orange">Default Account</td>:<>

                {appmasterid === '3' || appmasterid === '2' ?


               <td><Link as="button" data-tip data-for="edit" className="fa fa-edit" to={{pathname: '/newsubacc' }} state ={ {id: result.id,pagename:'Update SubAcc'}}}></Link> | 
                <li data-tip data-for="delete" className="fa fa-trash text-danger" onClick={() => { if (window.confirm('Are you sure you wish to delete SubAccount.? This will delete all attached ledgers. Are you sure?')) this.deleteSubAcc(result.id)}}></li>
                //<Tooltip id="edit" place="top" effect="solid">Edit</Tooltip>
               //<Tooltip id="delete" place="top" effect="solid">Delete</Tooltip></td>
                :''} 
               </>}
   
             </tr>
             
           )
         }
            
       </tbody>
                                         
     </table>   */}
    
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>


        
     </div>
                
            </div>
             
          
        )
       
    }
    
}
export default withRouter(SubAccDataTable)