import { Field, Form, Formik } from "formik";
import { Autocomplete, Checkbox, FormControlLabel, FormLabel } from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, thousandsdisplay, userwhid, dataaccess, financialyearstart, financialyearend } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import SalesServices from "../SalesServices";
import { Table } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableBody } from "@mui/material";
import {NumericFormat} from "react-number-format";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService.js";
import { Spinner } from "react-bootstrap";

let datalist = [];
class NewSaleOrderReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usercustomers:[],
      saleorderdata: [],
      saleorderid: "",
      invoiceid:"",
      saleorderitems: [],
      custom: [],
      customer: "",
      custid: "",
      id:'',
      itemid: "",
      itemname: "",
      hsn: "",
      uom:'',
      quantity: "",
      orderedquantity: "",
      pendingquantity:'',
      grandtotal: "",
      status: "0",
      damagestatus:"0",
      description: "",
      returnlist: [],
      dnlist:[],
      dnid:"",
      batchid: "",
      refund:2,
      loader:false,
      dndata:'',
      insuranceamount:0,
      tcsamt:0,
      invinsurance:0,
      invtcs:0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleCustomer = (value,values) => {
    if (values !== "" && typeof values === "object" &&values !== null) {
      this.setState({
        custid: values.id,
        customer: values.companyname,
        saleorderid: "",
        dnid:"",
        saleorderdata: [],
        saleorderitems: [],
        returnlist: [],
        itemid: "",
        itemname: "",
        insuranceamount:0,
      tcsamt:0
      });
      datalist = [];
      const id = values.id;

      let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid:userid,
        dataaccess: dataaccess,
        whid:userwhid,
        id:id
      }
      SalesServices.GetDnidForReturns(datadao).then((response)=> 
      this.setState({dnlist: response.data})
      ).catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    //   SalesServices.GetSoidForReturnsByCustid(datadao).then((res) =>
    //     this.setState({
    //       saleorderdata: res.data,
    //     })
    //   )
    //   .catch((e)=>{
    //     if(e.response.data.error ==="Unauthorized"){
    //         alert("Your Session is Lost: Please login Back to resume");
    //         this.props.navigate(`/`);
    //     }
    // })
    }else{
      this.setState({
        custid:'',
        customer: '',
        saleorderid: "",
        dnid:"",
        saleorderdata: [],
        saleorderitems: [],
        returnlist: [],
        itemid: "",
        itemname: "",
        id:'',
        insuranceamount:0,
        tcsamt:0
      });
      datalist = [];
    }
  };

  handleSaleOrder = (value,values) => {
    if (values !== "" && this.state.saleorderdata.includes(values) && values !== null) {
      this.setState({
        saleorderid: values,
        saleorderitems: [],
        returnlist: [],
        itemid: "",
        itemname: "",
      });
      datalist = [];
      const id = values;
      let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid:userid,
        dataaccess: dataaccess,
        whid:userwhid,
        id:id
      }
      SalesServices.GetDnidForReturns(datadao).then((response)=> 
      this.setState({
        dnlist: response.data,
      })
      );

      // SalesServices.GetDataToUpdate(id).then((response) =>
      //   this.setState({
      //     saleorderitems: response.data.saleorderitems,
      //     grandtotal: response.data.sototalwtax,
      //   })
      // );
    }else{
      this.setState({
        saleorderid: '',
        saleorderitems: [],
        returnlist: [],
        itemid: "",
        itemname: "",
        id:'',
      });
      datalist = [];
    }
  };

  handleDn = (value,values) => {
    if (values !== "" && this.state.dnlist.includes(values) && values !== null) {
      this.setState({
        dnid: values,
        saleorderitems: [],
        returnlist: [],
        itemid: "",
        itemname: "",
      });
      datalist = [];
      const id = values;

      SalesServices.GetInvDataOfProducts(id).then((response) =>
        this.setState({
          dndata:response.data,
          saleorderitems: response.data.deliveryitems,
          grandtotal: response.data.totalamountwtax,
        })
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    SalesServices.getinvoiceandsoidbydnid(id).then(res=>
      this.setState({
        saleorderid:res.data.customsoid,
        invoiceid:res.data.custominvid,  
        refund:res.data.payment==="Pending"?2:0,
        invinsurance:res.data.insurance,
        invtcs:res.data.tcs
    })
    )
    }else{
      this.setState({
        dnid: '',
        saleorderitems: [],
        returnlist: [],
        itemid: "",
        itemname: "",
      });
      datalist = [];
    }
  }

  handleReturn = (id) => {
    let itemsdetails = this.state.saleorderitems.filter(
      (data) => data.id === id
    );
    this.setState({ quantity: "", description: "" });
    itemsdetails.map((data) =>
      this.setState({
        id: data.id,
        categoryid:data.categoryid,
        itemid: data.itemid,
        itemname: data.name,
        hsn: data.hsn,
        uom:data.uom,
        price: data.price,
        tax: data.tax,
        taxamount:data.taxamount,
        batchid:data.batchid,
        // orderedquantity: data.currentquantity,
        pendingquantity:data.accepted,
        orderedquantity:data.quantity,
        totalpricewtax:data.totalpricewtax,
      })
    );
  };

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleReturnReason = (e) => {
    this.setState({damagestatus:e.target.value});
  };

  onSubmit() {
    if (this.state.quantity === "0") {
    } else {

      // this.setState({
      //   pendingquantity: this.state.pendingquantity - this.state.quantity,
      // });
      let list = {
        id: this.state.id,
        itemid: this.state.itemid,
        name: this.state.itemname,
        categoryid:this.state.categoryid,
        hsn: this.state.hsn,
        uom: this.state.uom,
        quantity: parseFloat(this.state.quantity),
        price: this.state.price,
        tax: this.state.tax,
        refund:this.state.status === "0"?this.state.refund:2,
        exchangereturn: parseInt(this.state.status),
        damagestatus:parseInt(this.state.damagestatus),
        description: this.state.description,
        taxamount: (parseFloat(this.state.quantity)* this.state.price) * (this.state.tax/100),
        batchid: this.state.batchid,
        totalpricewtax: (parseFloat(this.state.quantity) * this.state.price) + ((parseFloat(this.state.quantity)* this.state.price) * (this.state.tax/100)),
      };

      let data = { 
        id: this.state.id,
        itemid: this.state.itemid,
        name: this.state.itemname,
        hsn: this.state.hsn,
        uom: this.state.uom,
        price: this.state.price,
        tax: this.state.tax,
        categoryid:this.state.categoryid,
        taxamount:this.state.taxamount,
        quantity: this.state.orderedquantity,
        pending: this.state.pendingquantity - parseFloat(this.state.quantity),
        totalpricewtax: this.state.totalpricewtax,
      }

      if(this.state.pendingquantity-this.state.quantity === 0){
        this.setState({saleorderitems:this.state.saleorderitems.filter((data) => data.id !== this.state.id)})
      }else{
        this.setState({saleorderitems:this.state.saleorderitems.filter((data) => data.id !== this.state.id)},
        ()=>this.state.saleorderitems.push(data)
        ) 
      } 
      datalist.push(list);
      this.setState({ returnlist: datalist,pendingquantity: this.state.pendingquantity - this.state.quantity ,quantity: "", description: "",id:''});
    }
  }

  removeItemFromList(id) {
    // const deleteitem = datalist.filter((item,index)=>index === id);
    // deleteitem.map(data=>this.setState({orderedquantity:this.state.orderedquantity + parseInt(data.quantity)}))

    const deleteitem = datalist.filter((item, index) => item.id === id);
    let delquantity =  deleteitem.map((data) =>data.quantity)

    const newlist = datalist.filter((item, index) => item.id !== id);
    this.setState({
      returnlist: newlist,
    });
    datalist = newlist;
    
    // const newlist = datalist.filter((item, index) => item.iid !== id);
    // this.setState({
    //   returnlist: newlist,
    // });
    // datalist = newlist;

    let itemsdetails = this.state.saleorderitems.find((data) => data.id === id);
    let deletedata={
      id: itemsdetails.id,
      itemid:itemsdetails.itemid,
      name: itemsdetails.name,
      hsn: itemsdetails.hsn,
      uom:itemsdetails.uom,
      price: itemsdetails.price,
      categoryid: itemsdetails.categoryid,
      tax: itemsdetails.tax,
      taxamount:itemsdetails.taxamount,
      quantity: itemsdetails.quantity,
      pending: itemsdetails.pending + parseFloat(delquantity),
      totalpricewtax: itemsdetails.totalpricewtax
    }
    let remlist=this.state.saleorderitems.filter((data) => data.id !==id)
    remlist.push(deletedata)
    this.setState({saleorderitems:remlist})
  }

  handelSubmit = () => {
    this.setState({loader:true})
    let data = {
      dnid: this.state.dnid,
      custid: this.state.custid,
      customer: this.state.customer,
      tenantid: currentTenant,
      itemlist:datalist,
      insuranceamount:this.state.insuranceamount,
      tcsamt:this.state.tcsamt,
      createdby:userid
    }
    SalesServices.NewSalesReturnOrder(data).then(() =>
      this.props.navigate({pathname:`/salesreturns`})
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
      this.setState({loader:false})
  })
  };

  refreshData() {
    // SalesServices.GetItems(currentTenant).then((response) =>
    //   this.setState({
    //     data: response.data,
    //   })
    // );

    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      this.setState({
        custom: res.data.filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)),
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>this.setState({usercustomers:res.data===''||res.data===undefined?[]:res.data.customers.split(",")}))
    this.refreshData();
    datalist = [];
  }

  validate(values) {
    let errors = {};
    if (
      this.state.quantity === "" ||
      this.state.quantity === undefined ||
      this.state.quantity === "0" ||
      this.state.quantity<0
    ) {
      errors.quantity = "Quantity is required";
    } else if (this.state.quantity > this.state.pendingquantity) {
      errors.quantity = `Avilable quantity is ${this.state.pendingquantity}`;
    }
    if (this.state.description === "") {
      errors.description = "Pelese provide description";
    }
    return errors;
  }
  onfocus(){
    document.getElementById('quantity').addEventListener('mousewheel', function(event) {
     if (window.document.activeElement.type === 'number') {
       event.preventDefault()
     }
   })
   document.getElementById('quantity').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }

  render() {

    let {
      saleorderid,
      dnid,
      custom,
      quantity,
      itemname,
      customer,
      custid,
      itemid,
      status,
      damagestatus,
      saleorderdata,
      description,
      dnlist,
    } = this.state;
  
    return (
      <div>
         <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/salesreturns" }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/salesreturns">Sales Returns</Link>
              </li>
              <li className="breadcrumb-item active">New Sales Returns</li>
            </ol>
            <div className="card-body">
              <Formik
                initialValues={{
                  saleorderid,
                  dnid,
                  custom,
                  customer,
                  custid,
                  itemid,
                  itemname,
                  quantity,
                  status,
                  damagestatus,
                  description,
                  saleorderdata,
                  dnlist,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form>
                    <div className="form-row">
                
                      <fieldset hidden className="form-group col-md-3">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                      {this.state.dnid ===''?
                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="customer"
                          loadingText="Loading..."
                          options={custom}
                          getOptionLabel={(option) => option.companyname}
                          value={values.customer?undefined:undefined}
                          onChange={this.handleCustomer}
                          
                          renderInput={params => (  
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="customer"
                              label="Select Customer"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      :
                      <fieldset className="form-group col-md-3" disabled>
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="customer"
                          label="Customer"
                          value={this.state.customer}
                        />
                      </fieldset>
                      }
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="dnlist"
                            options={dnlist}
                            getOptionLabel={(option) => option}
                            
                            
                            value={values.dnlist?undefined:undefined}
                            onChange={this.handleDn}
                            renderInput={params => (  
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="dnlist"
                                label="Select Delivery Note"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                        {this.state.dnid !== '' && this.state.dnid !== null  && this.state.saleorderid !== null && this.state.saleorderid!==''?
                        <fieldset className="form-group col-md-3" disabled>
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="saleorder"
                          label="Saleorder"
                          value={this.state.saleorderid}
                        />
                      </fieldset>:""}
                      {this.state.dnid !== '' && this.state.dnid !== null  && this.state.invoiceid !== null && this.state.invoiceid!==''?
                      <fieldset className="form-group col-md-3" disabled>
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="invoice"
                          label="Invoice"
                          value={this.state.invoiceid}
                        />
                      </fieldset>:""}

                      {this.state.dnid === '' || this.state.saleorderitems.length === 0 ?'':
                        <div style={{ paddingTop: "25px" }} className="form-group col-md-12">
                          <Table className="table-sm text-center">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">Item Name</TableCell>
                                <TableCell className="text-center">HSN</TableCell>
                                <TableCell className="text-center">Price</TableCell>
                                <TableCell className="text-center">Quantity</TableCell>
                                <TableCell className="text-center">Pending Quantity</TableCell>
                                <TableCell className="text-center">Tax(%)</TableCell>
                                <TableCell className="text-center">Tax Amount</TableCell>
                                <TableCell className="text-center">Total Price</TableCell>
                                <TableCell className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.saleorderitems.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">{data.name}</TableCell>
                                  <TableCell className="text-center">{data.hsn}</TableCell>
                                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  <TableCell className="text-center">{data.quantity}{data.uom}</TableCell>
                                  <TableCell className="text-center">{data.accepted}</TableCell>
                                  <TableCell className="text-center">{data.tax}%</TableCell>
                                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  <TableCell className="text-center">
                                    <span
                                      className="text-info cursorPointer"
                                      onClick={() => this.handleReturn(data.id)}
                                    >
                                      Return/Exchange
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell className="text-center">Total</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell className="text-right">
                                <NumericFormat className="pr-3" displayType={'text'} value={this.state.grandtotal} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      }

                      {this.state.id === ''? '' :
                        <>
                            <u className="form-group col-md-12 mt-3">
                            <FormLabel style={{display:'flex',alignItems:'center'}} > Return or Exchange Order Details</FormLabel>
                          </u>

                          {/* <fieldset className="form-group col-md-4"> */}
                            <FormLabel style={{display:'flex',alignItems:'center'}} >Itemname : &nbsp; {this.state.itemname} &nbsp;&nbsp;&nbsp; </FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.state.status==="0"} onChange={()=>this.setState({status:"0"})}/>} label="Return" />
                          <FormControlLabel control={<Checkbox checked={this.state.status==="1"} onChange={()=>this.setState({status:"1"})}/>} label="Exchange" />
                          {/* </fieldset> */}

                          

                          <fieldset className="form-group col-md-12 m-0"></fieldset>
                          {this.state.status === "0" ? 
                          <>
                            <FormLabel style={{display:'flex',alignItems:'center'}} >Reason for Return :&nbsp;&nbsp;&nbsp; </FormLabel>
                            {/* <>&nbsp;  : &nbsp; {this.state.itemname}</> */}
                            <FormControlLabel control={<Checkbox checked={this.state.damagestatus==="0"} onChange={()=>this.setState({damagestatus:"0"})}/>} label="Damaged" />
                          <FormControlLabel control={<Checkbox checked={this.state.damagestatus==="1"} onChange={()=>this.setState({damagestatus:"1"})}/>} label="Other" />
                          </>
                          :""}
                          <fieldset className="form-group col-md-12 m-0"></fieldset>
  {this.state.status==="0" && this.state.refund!==2?
                              < >
                              <FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
                                <FormControlLabel control={<Checkbox checked={this.state.refund===0} onChange={()=>this.setState({refund:0})}/>} label="Refund" />
                                <FormControlLabel control={<Checkbox checked={this.state.refund===1} onChange={()=>this.setState({refund:1})}/>} label="Adjust amount in next Invoice" />
                              </>:''
                            }
<fieldset className="form-group col-md-12 m-0"></fieldset>

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="quantity"
                              className="form-control"
                              type="number"
                              name="quantity"
                              label="Quantity"
                              onChange={(e) => this.setState({ quantity: e.target.value })}
                              onFocus={this.focus}
                            />
                          </fieldset>
                        
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="textarea"
                              name="description"
                              label="Description"
                              onChange={(e) => this.setState({ description: e.target.value })}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-12"/>

                          <button className="btn btn-sm hovbuttonColour"  type="onSubmit">
                          <b>+</b> Add to List
                          </button>
                          </>}
                          {this.state.returnlist.length === 0 ? '':<>
                         
                            <div className="form-group col-md-12 pt-5">
                               <u className="form-group col-md-12 mt-3">
                                Return or Exchange Order Details
                                </u>
                              
                              <Table className="table-sm">
                                <TableHead>
                                  <TableRow >
                                    <TableCell className="text-center">Item Name</TableCell>
                                    <TableCell className="text-center">HSN</TableCell>
                                    <TableCell className="text-center">Price</TableCell>
                                    <TableCell className="text-center">Quantity</TableCell>
                                    <TableCell className="text-center">Tax(%)</TableCell>
                                    <TableCell className="text-center">Tax Amount</TableCell>
                                    <TableCell className="text-center">Total Price</TableCell>
                                    <TableCell className="text-center">Exchange or Return</TableCell>
                                    <TableCell className="text-center">Description</TableCell>
                                    <TableCell className="text-center">Refund</TableCell>
                                    {/* <TableCell className="text-center">Action</TableCell> */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.returnlist.map((data, index) => (
                                    <TableRow key={index} className="text-center align-item-center">
                                      <TableCell className="text-center">{data.name}</TableCell>
                                      <TableCell className="text-center">{data.hsn}</TableCell>
                                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      <TableCell className="text-center">{data.quantity}{data.uom}</TableCell>
                                      <TableCell className="text-center">{data.tax}%</TableCell>
                                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      <TableCell className="text-center">
                                        {data.exchangereturn === 1 ? "Exchange" : "Return"}
                                      </TableCell>
                                      <TableCell className="text-center">{data.description}</TableCell>
                                      <TableCell className="text-center">{data.refund?"Refund":"Adjust"}</TableCell>
                                      {/* <TableCell className="text-center">
                                        <Link
                                          as="button"
                                          data-tip
                                          data-for="delete"
                                          className="fa fa-trash text-danger bg-white shadow-sm"
                                          style={{ paddingLeft: "7px" }}
                                          onClick={() => this.removeItemFromList(data.id)}
                                        />
                                      </TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>

                            {(this.state.invinsurance && this.state.invinsurance !== 0 ) || (this.state.invtcs && this.state.invtcs !== 0 )?
                            <div className="form-group col-md-12">
                            <div className="row pt-5">
                              {(this.state.invtcs && this.state.invtcs !== 0 ) &&
                                    <fieldset className="col-md-3 mb-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="tcsamt"
                            value={this.state.tcsamt}
                            label={`TCS Amount`}
                            onChange={(e)=>this.setState({tcsamt:e.target.value})}
                            variant="standard"
                            fullWidth
                            
                          />
                        </fieldset>}
                        {(this.state.invinsurance && this.state.invinsurance !== 0 ) &&
                        <fieldset className="col-md-3 mb-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="insuranceamount"
                            value={this.state.insuranceamount}
                            onChange={(e)=>this.setState({insuranceamount:e.target.value})}
                            label={`Insurance Amount`}
                            variant="standard"
                            fullWidth
                            
                          />
                        </fieldset>  }
                            </div>
                            </div>:""
                            }
                            </>
                          }
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="form-group col-md-12" />
              {this.state.returnlist.length === 0 ?
                <button className="btn btn-sm hovbuttonColour" disabled>
                 <b><i className="fa fa-check"></i></b> Submit
              </button>
               :
                <button className="btn btn-sm hovbuttonColour" onClick={this.handelSubmit} disabled={this.state.loader===true}>
                  {this.state.loader===true?<> <Spinner
                                className="mr-1"
                                animation="border"
                                size="sm"
                              /> Submitting</>:<><b className=""><i className="fa fa-check"></i></b> Submit</>}
                </button>
              }
              <Link
                as="button"
                className="btn btn-sm ml-3 deletebutton"
                to={{ pathname: "/salesreturns" }}
              >
                <b> X</b> Cancel
              </Link>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}

export default withRouter(NewSaleOrderReturn);
