import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import { currentTenant, thousandsdisplay, userid } from "../Common/Credentials";
import NewJournalService from "./NewJournalService";
import {NumericFormat} from "react-number-format";
import { ToWords } from 'to-words';
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";


//let jvtype=1
class ComboJournalConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmjv: this.props.location.state,
      loader:false
    };
    //this.validate=this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  onSubmit(values) {
    // let sgstid, sgstpercent, sgstval;
    // let cgstid, cgstpercent, cgstval;
    // let igstid, igstpercent, igstval;
    // let vatid, vatpercent, vatval;
    // let whid, items, qty, uom, cost;
    this.setState({loader:true})
    let expenseid,expensename
    if(this.state.confirmjv.expense!==undefined){
      expenseid=this.state.confirmjv.expense.id
      expensename=this.state.confirmjv.expense.name
    }

    let dijv = {
      // debitid: this.state.confirmjv.debitid,
      // debitamt: this.state.confirmjv.debitamt,
      // creditid: this.state.confirmjv.creditid,
      // creditamt: this.state.confirmjv.creditamt,
      // narration: this.state.confirmjv.narration,
      // jvtype: this.state.confirmjv.jvtype,
      // createdBy: userid,
      // tenantId: currentTenant,
      // entrytype: this.state.confirmjv.entrytype,
      // sgstid: sgstid,
      // sgstpercent: sgstpercent,
      // sgstval: sgstval,
      // cgstid: cgstid,
      // cgstpercent: cgstpercent,
      // cgstval: cgstval,
      // igstid: igstid,
      // igstpercent: igstpercent,
      // igstval: igstval,
      // vatid: vatid,
      // vatpercent: vatpercent,
      // vatval: vatval,
      // whid: whid,
      // items: items,
      // qty: qty,
      // uom: uom,
      // cost: cost,
      // expense: this.state.confirmjv.expense,
      branch:this.state.confirmjv.branch,
      dept:this.state.confirmjv.dept,
      debt: this.state.confirmjv.debt,
      tax:this.state.confirmjv.tax,
      dtax:this.state.confirmjv.dtax,
      cred: this.state.confirmjv.cred,
      createdBy: userid,
      tenantId: currentTenant,
      naration: this.state.confirmjv.naration,
      type: this.state.confirmjv.type,
      expenseid:expenseid,
      expensename:expensename,
      debtotal:this.state.confirmjv.debtotal,
      taxtotal:this.state.confirmjv.taxtotal,
      dtaxtotal:this.state.confirmjv.dtaxtotal,
      cretotal:this.state.confirmjv.cretotal,
      typeofentry:this.state.confirmjv.typeofentry,
      taxbehav:this.state.confirmjv.taxbehav,
      indtaxtype:this.state.confirmjv.indtaxtype,
      indsubtaxtype:this.state.confirmjv.indsubtaxtype,
      date:this.state.confirmjv.date,
      advanceentry:parseInt(this.state.confirmjv.advanceentry),
      jvinsertiontype:this.state.confirmjv.jvinsertiontype,ncentry:this.state.confirmjv.ncentry,resentry:this.state.confirmjv.resentry,
      lt:this.state.confirmjv.lt===true?parseInt(0):parseInt(1),ap:this.state.confirmjv.ap===true?parseInt(0):parseInt(1),
      ar:this.state.confirmjv.ar===true?parseInt(0):parseInt(1),dr:this.state.confirmjv.dr===true?parseInt(0):parseInt(1),
      dp:this.state.confirmjv.dp===true?parseInt(0):parseInt(1),
      mode:this.state.confirmjv.mode,bank:this.state.confirmjv.bank,bankid:this.state.confirmjv.bankid,

    };

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    } else {
      NewJournalService.insertEntryComb(dijv).then(() =>
        this.props.navigate({pathname:`/journals`}, {
         message: values + "   : Combo Entry Successfull",
       })
      )
      .catch((e)=>{
        if(e.response.status===400){
          Swal.fire({
            title: "Please Check !!!",
            text: e.response.data,
            icon: "warning",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
         // alert(e.response.data)
         // this.props.navigate(`/cjournalconfirm`);
        }
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        } 
    })
    }
  }

  componentDidMount() {
    // console.log(this.props.location.state)
  }

  componentDidUpdate() {}

  goBack() {
    this.props.navigate(-1);
  }

  render() {
    let drjv = this.state.confirmjv.debt
    let taxjv=[],dtaxjv=[]
    taxjv=this.state.confirmjv.tax
    dtaxjv=this.state.confirmjv.dtax
    let crjv = this.state.confirmjv.cred
    let jv = this.state.confirmjv
    const toWords = new ToWords();
    let amtwords = toWords.convert(parseInt(jv.ar===true || jv.dr===true ?jv.cretotal-jv.dtaxtotal:jv.debtotal-jv.dtaxtotal));


    // let debjv = this.state.confirmjv.map(e => e.debt);
    // console.log(jv)
    return (
      <div>
        <Header />
         <Menu />
       

        <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">Confirm Entry</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              {/* <li className="breadcrumb-item text-secondary">
        <Link to={{pathname: '/journals', state: {message:''}}} >
                    Accounts
                  </Link>
        </li> */}
              <li className="breadcrumb-item active">Confirm Entry</li>
            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
              <div className="text-center"></div>

              <Formik
                initialValues={{ jv,drjv,crjv,taxjv,amtwords }}
                onSubmit={this.onSubmit}
                validateOnChange={true}
                validate={this.validate}
                enableReinitialize={true}
              >
                {() => (
                  <Form>
                    {/* {jv.whid !== undefined ? (
                      <div className="form-row">
                        <div>
                          <h4>Inventory</h4>
                        </div>
                        <table className="table table-sm">
                          <thead className="bg-primary">
                            <tr>
                              <td>Item</td>
                              <td className="text-right">WareHouse</td>
                              <td className="text-right">Cost</td>
                              <td className="text-right">Quantity</td>
                              <td className="text-right">UOM</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{jv.items.name}</td>
                              <td className="text-right">{jv.whid.address}</td>
                              <td className="text-right">{jv.uprice}</td>
                              <td className="text-right">{jv.qty}</td>
                              <td className="text-right">{jv.uom}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )} */}

                    <div className="form-row">
                      <table className="table table-sm">
                        <thead style={{backgroundColor:"#e9ecef"}}>
                          <tr>
                            <td>Ledgers</td>
                            <td className="text-right"> Debit </td>
                            <td className="text-right">Credit</td>
                          </tr>
                        </thead>
                        <tbody>
                          {drjv.map((e)=>
                          <tr key={e.name.id}>
                            <td>{e.name.name}</td>
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={e.value}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            
                            </td>
                            
                            <td></td>
                          </tr>
)}
                        {crjv.map((e)=>
                          <tr key={e.name.id}>
                            <td>{e.name.name}</td>
                            <td></td>
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={e.value}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </td>
                          </tr>
                        )}
{this.state.confirmjv.tax.map(e=>e.value!==null)?
taxjv.map((e)=>
                e.name!==null?
                          <tr key={e.name.id}>
                            <td>{e.name.name}</td>

                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.taxbehav===1?"(-) "+e.value:e.value}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            
                            </td>
                            <td></td>
                            <td></td>

                          </tr>
                          :""
):""}


                          {/* {(jv.taxval1 !== undefined) & (jv.jvtype === 0) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.gst.name}@ {jv.gstpercent}%
                              </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval1}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {(jv.itaxval !== undefined) & (jv.jvtype === 0) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.igst.name} @ {jv.igstpercent}%
                              </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.itaxval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {(jv.taxval2 !== undefined) & (jv.jvtype === 0) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.cgst.name} @ {jv.cgstpercent}%
                              </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval2}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {(jv.vatval !== undefined) & (jv.jvtype === 0) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.vat.name} @ {jv.vatpercent}%
                              </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.vatval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : (
                            ""
                          )} */}
{this.state.confirmjv.dtax.map(e=>e.value!==null)?
dtaxjv.map((e)=>
                e.name!==null?
                
                          <tr key={e.name.id}>
                            <td>{e.name.name}</td>
                            {jv.ar===true || jv.dr===true ?<td></td>:""}
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.taxbehav===1?"(-) "+e.value:e.value}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </td>
                            <td></td>

                          </tr>
                          :""
):""}

                          {/* {(jv.taxval1 !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.gst.name}@ {jv.gstpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval1}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {(jv.itaxval !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.igst.name} @ {jv.igstpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.itaxval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {(jv.taxval2 !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.cgst.name} @ {jv.cgstpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval2}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {(jv.vatval !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                              {" "}
                              <td>
                                {jv.vat.name} @ {jv.vatpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.vatval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )} */}
                          {/* <tr>
                          <td>Total</td>
                          <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.ar===true || jv.dr===true ?jv.debtotal+jv.taxtotal:jv.debtotal-jv.dtaxtotal}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            
                            </td>
                            
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.ar===true || jv.dr===true ?jv.cretotal-jv.dtaxtotal:jv.cretotal-jv.taxtotal}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat> 
                              </td>
                            </tr> */}
                            
                            <tr>
                            <td>
                              <span className="text-blue text-bold">
                                Amount In Words :{" "}
                              </span>{" "}
                              {amtwords}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span className="text-blue text-bold">
                                Narration :{" "}
                              </span>{" "}
                              {jv.naration}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <hr/>  */}

                    <div>
                      <div className="form-row">
                        <fieldset className="form-group col-md-12 mr-3">
                          <button className="btn hovbuttonColour btn-sm" type="submit">
                             {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Confirm"}</>:"Confirm"}
                          </button>
                        {/* </fieldset>
                        <fieldset className="form-group col-md-2"> */}
                          <button
                            className="btn btn-sm bg-white shadow-sm"
                            type="button"
                            onClick={this.goBack}
                          >
                           <b style={{ color: "#FA5733" }}> X</b>  Close
                          </button>
                        </fieldset>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(ComboJournalConfirm);
