import {Box,Step,StepButton,Stepper,Typography,Button,TextField,Table,TableHead,TableRow,TableBody,TableCell,Grid,TableContainer,Slider,
  } from "@mui/material";
  import * as React from "react";
  import { Form } from "react-bootstrap";
  import { Formik } from "formik";
  import { Link } from "react-router-dom";
  import Header from "../../Component/Header";
  import Menu from "../../Component/Menu";
  import Footer from "../../Component/Footer";
  import { currentTenant, thousandsdisplay } from "../../Common/Credentials";
  import { useLocation } from "react-router";
  import SalesServices from "../../Sales/SalesServices";
  import { Autocomplete } from "@mui/material";
  import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
  import CategoryServices from "../../Masters/Category/CategoryServices";
  import InventoryService from "../../inventory/InventoryService";
  import UserService from "../../users/UserService";
  import UomServices from "../../Masters/Uom/UomServices";
  import {NumericFormat} from "react-number-format";
  import withRouter from "../../withRouter";
  
  
  const NewProjectIndenting = (props) => {
    const history = useLocation();
    const [id] = React.useState(history.state === undefined?-1:history.state.id)
    const [steps] = React.useState([
      "Raw Materials",
      "Equipments",
      "Man Power",
    ])
    const [frequency,setfrequency] = React.useState(0)
    const [againstso,setAgainstSo] = React.useState(false);
    const [categoryList,setCategoryList] = React.useState([]);
    const [category,setCategory] = React.useState("");
    const [catid,setCatId] = React.useState("");
    const [appxamount, setAppxAmount] = React.useState();
    const [UOMList,setUOMList]= React.useState([]);
    const [uom,setUOM]  = React.useState("");
    const [uomid,setUOMId]  = React.useState("");
    const [amount,setAmount] = React.useState(0);
    const [price,setPrice] = React.useState(0);
    const [itemsList,setItemList] = React.useState([]);
    const [filterItemList,setFilterItemList]= React.useState([]);
    const [itemid, setItemId] = React.useState("");
    const [itemname, setItemname] = React.useState("");
    const [itemquantity, setItemquantity] = React.useState("");
    const [bomitems, setBomItems] = React.useState([]);
    const [equipmentid, setEquipmentId] = React.useState("");
    const [equipment, setEquipment] = React.useState("");
    const [equipmentquantity, setEquipmentQuantity] = React.useState("");
    const [equipmentList, setEquipmentList] = React.useState([]);
    const [mantypeid, setManTypeId] = React.useState("");
    const [mantype, setManType] = React.useState("");
    const [mancount, setManCount] = React.useState("");
    const [manpowerList, setManPowerList] = React.useState([]);
    const [ setQAstatus] = React.useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed] = React.useState({});
  
  
    React.useEffect(() => {
      SalesServices.GetSoIdofProducts(currentTenant).then((response) =>
        setSOList(response.data)
      );
  
      CategoryServices.getCategory(currentTenant).then((response) =>
        setCategoryList(response.data.filter((e) => e.categorytype === "Product"))
      );
      InventoryService.retriveAllItems(currentTenant).then((response) =>setItemList(response.data))
  
      // InventoryService.getItemNames(currentTenant).then((response) =>setItemList(response.data))

      // UomServices.getUOM(currentTenant).then((response) =>
      // setUOMList(response.data)
      // );
      if(id === -1){
        return
      }else{
  
        ProjectManagementServices.getEstimationsbyId(id).then(response=>{
  
        setBomItems(response.data.bomitems)
        setEquipmentList(response.data.equipments)
        setManPowerList(response.data.manpower)
        setFinalProductsList(response.data.finalproduct)
        setDuration(response.data.duration)
        setStartDate(response.data.startdate)
        setEndDate(response.data.enddate)
        setQAstatus(response.data.qastatus)
        setWorkCenter(response.data.workcenter)
        setWorkCenterId(response.data.workcenterid)
        setTo(response.data.to)
        setToName(response.data.toname)
        setAgainstSo(response.data.soid === "NA"?false:true)
        setSoId(response.data.soid)
        setAppxAmount(response.data.appxamount === undefined?0:response.data.appxamount)
        setAdvance(response.data.advance)
        setWhId(response.data.whid)
        setWarehouse(response.data.warehouse)
      })
    }
    }, [id]);
  
  
      /* ----------BOM Start--------- */
  
    const handleCategoryChange = (e, newValue) => {
      if (newValue === "" || newValue === null || newValue === undefined) {
        setCatId("");
        setCategory("");
      } else {
        setCatId(newValue.id);
        setCategory(newValue.category);
        setFilterItemList(activeStep===1?itemsList.filter(e=>e.catid === newValue.id && e.cost !== null): itemsList.filter(e=>e.catid === newValue.id))
      }
    };
  
    const handleSelectItem = (e, newValue) => {
      if(newValue === "" || newValue === null || newValue === undefined) {
        setItemname("");
        setItemId("");
        setPrice(0)
      }else{
        setItemname(newValue.name);
        setItemId(newValue.id);
        setPrice(itemsList.find(e=>e.id === newValue.id).cost)
        setUOM(itemsList.find(e=>e.id === newValue.id).uomname)
        setUOMId(itemsList.find(e=>e.id === newValue.id).uom)
      }
    };
  
    const handleItemQuantity = (e) => {
      setItemquantity(e.target.value);
    };
  
    const handlefrequencyChange = (event, newValue) => {
      setfrequency(newValue)
    }
  
    const handleAddBOMItem = () => {
      if (itemname === "" && itemquantity === "" && itemquantity === "0" && itemquantity === 0 && uom ===""){
      }
      else{
        if(bomitems.length === 0||bomitems.filter(e=>e.itemid === itemid).length === 0){
        let data = {
          itemid: itemid,
          itemname: itemname,
          quantity: parseFloat(itemquantity),
          uom:uom,
          uomid:uomid,
          frequency:frequency,
          price:price,
          totalprice:price*parseFloat(itemquantity)
        };
        bomitems.push(data)
      }else{
        bomitems.find(e=>e.itemid === itemid).quantity = bomitems.find(e=>e.itemid === itemid).quantity + parseFloat(itemquantity)
        bomitems.find(e=>e.itemid === itemid).totalprice = bomitems.find(e=>e.itemid === itemid).totalprice + (parseFloat(itemquantity)*price)
      }
        setItemquantity("")
        setUOM("")
        setUOMId("")
        setfrequency("")
      }
    };
    
      /* ----------BOM End--------- */
  
      /* ----------Equipment Start--------- */
  
  
    const handleSelectEquipment = (e, newValue) => {
      if(newValue === "" || newValue === null || newValue === undefined) {
        setEquipment("");
        setEquipmentId("");
      }else{
        setEquipment(newValue.name);
        setEquipmentId(newValue.id);
      }
    };
  
    const handleEquipmentQuantity = (e) => {
      setEquipmentQuantity(e.target.value);
    };
  
    const handleAmount = (e) => {
      setAmount(e.target.value);
    };

    const handleAddEquipments = () => {
      if (equipment !== "" && equipmentquantity !== "") {
        let data = {
          name: equipment,
          itemid:equipmentid,
          quantity: parseFloat(equipmentquantity),
          amount:parseFloat(amount),
          totalamount:parseFloat(equipmentquantity)*parseFloat(amount)
        };
        equipmentList.push(data);
        setAmount(0)
        setEquipmentQuantity(0)
      }
    };
  
    const handelRemoveEquipment = (value) => {
      if (value !== "") {
        setEquipmentList(equipmentList.filter((e, index) => index !== value));
        setAppxAmount(appxamount - equipmentList.find((e, index) => index === value).totalamount)
      }
    };
  
    
      /* ----------Equipment End--------- */
   
      /* ----------Man Power Start--------- */
  
  
    const handleSelectManType = (e) => {
      setManType(e.target.value);
      // setManTypeId(e.target.value);
    };
  
    const handleManCount = (e) => {
      setManCount(e.target.value);
    };
  
    const handleAddManPower = () => {
      if (mantype !== "" && mancount !== "") {
        let data = {
          mantypeid: 1,
          mantype: mantype,
          mancount: parseFloat(mancount),
          amount:parseFloat(amount),
          totalamount:parseFloat(mancount)*parseFloat(amount)
  
        };
        manpowerList.push(data);
        setManPowerList([...manpowerList])
      }
    };
  
    const handleRemoveManPower = (value) => {
      if (value !== "") {
        setManPowerList(manpowerList.filter((e, index) => index !== value));
        setAppxAmount(appxamount - manpowerList.filter((e, index) => index === value).totalamount)
      }
    };
  
    
      /* ----------Man Power End--------- */
   
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
      if(newActiveStep === 3){
        let a = 0
        let b = 0
        let c = 0
        bomitems.map(e=>a = a + e.totalprice)
        equipmentList.map(e=> b = b + e.totalamount)
        manpowerList.map(e=>c = c+ e.totalamount)
        setAppxAmount(a+b+c)
      }
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
      if(step === 2){
        let a = 0
        let b = 0
        let c = 0
        bomitems.map(e=>a = a + e.totalprice)
        equipmentList.map(e=> b = b + e.totalamount)
        manpowerList.map(e=>c = c+ e.totalamount)
        setAppxAmount(a+b+c)
  
      }
    };
  
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/projectindenting" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/projectmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/projectindenting">Project Indenting</Link>
              </li>
              <li className="breadcrumb-item active">New Project Indenting</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          New Project Indenting 
                        </h3>
                      </div>
  
                      <div className="card-body">
  
                          <Box sx={{ width: "100%" }}>
                            <Stepper nonLinear activeStep={activeStep}>
                              {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                  <StepButton
                                    color="inherit"
                                    onClick={handleStep(index)}
                                    style={{ outline: "none" }}
                                  >
                                    {label}
                                  </StepButton>
                                </Step>
                              ))}
                            </Stepper>
                            <div>
                              {allStepsCompleted() ? (
                                <React.Fragment></React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Formik
                                    initialValues={{}}
                                    // onSubmit={onSubmit}
                                    validateOnChange={false}
                                    // validate={validate}
                                    enableReinitialize={true}
                                  >
                                    {({ values }) => (
                                      <Form
                                        autoComplete="off"
                                        style={{ paddingLeft: "30px" }}
                                      >
                                        {/* ----------BOM Start--------- */}
                                        { ((activeStep === 0)) ? (
                                        <div className="form-row">
                                          <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="category"
                                              options={categoryList}
                                              getOptionLabel={(option) =>
                                                option.category
                                              }
                                              value={
                                                values.category
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleCategoryChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="category"
                                                  value={category}
                                                  label={`Select Category (${category})`}
                                                  variant="standard"
                                                  error={catid === ""}
                                                  helperText={
                                                    catid === ""
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                          <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="itemname"
                                              options={filterItemList}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              
                                              value={
                                                values.itemname
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleSelectItem}
                                              renderInput={(params) => (
                                            <TextField
                                            {...params}
                                              id="standard-error-helper-text"
                                              label={`Select Item (${itemname})`}
                                              value={itemname}
                                              variant="standard"
                                              error={itemname === ""}
                                              helperText={
                                                itemname === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                            )}
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Price"
                                              type="number"
                                              name="price"
                                              value={price}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            {/* <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="uom"
                                              options={UOMList}
                                              getOptionLabel={(option) => option.name}
                                              
                                              
                                              value={values.uom?undefined:undefined}
                                              onChange={handleUOM}
                                              renderInput={params => (   */}
                                                <TextField
                                                    // {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="uom"
                                                  label={`UOM `}
                                                  variant="standard"
                                                  value={uom}
                                                  fullWidth
                                                  error={uom === ""}
                                                    helperText={
                                                      uom === ""
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                />
                                              {/* )}
                                            /> */}
                                          </fieldset>   
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Quantity"
                                              type="number"
                                              value={itemquantity}
                                              onChange={handleItemQuantity}
                                              variant="standard"
                                              error={itemquantity === ""}
                                              helperText={
                                                itemquantity === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          
                                          
                              
                        
                                          <fieldset className="col-md-12 d-flex align-items-center">
                                            <span
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddBOMItem}
                                            >
                                              Add
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {bomitems.length !== 0 && ( activeStep === 1) ? (
                                        <TableContainer>
                                          <Typography> Raw Materials List</Typography>
                                          <Table className="table-sm">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="text-center">
                                                  Itemname
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Price
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Quantity
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  UOM
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Total
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {bomitems.map((e, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {e.itemname}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                 <NumericFormat className="pr-3" displayType={'text'} value={e.price}  decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.quantity}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.uom}
                                                  </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.totalprice}  decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                 </TableCell>
                                                  <TableCell className="text-center">
                                                    <span
                                                      as="buttom"
                                                      className="btn btn-sm deletebutton"
                                                      onClick={() =>
                                                        handelRemoveItem(index)
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      ) : (
                                         ""
                                      )}
                                        {/* ----------BOM End--------- */}

                                        {/* ----------Equipment Start--------- */}
                                        {(( activeStep === 1))  ? (
                                          <div className="form-row">
                                           <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="category"
                                                options={categoryList}
                                                getOptionLabel={(option) =>
                                                  option.category
                                                }
                                                
                                                value={
                                                  values.category
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handleCategoryChange}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="category"
                                                    value={category}
                                                    label={`Select Category (${category})`}
                                                    variant="standard"
                                                    error={catid === ""}
                                                    helperText={
                                                      catid === ""
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                            <fieldset className="col-md-3">
                                            <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="itemname"
                                                options={filterItemList}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                
                                                value={
                                                  values.itemname
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handleSelectEquipment}
                                                renderInput={(params) => (
                                              <TextField
                                              {...params}
                                              id="standard-error-helper-text"
                                              label={`Select Equipment ${equipment}`}
                                              value={equipment}
                                              variant="standard"
                                              error={equipment === ""}
                                              helperText={
                                                equipment === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                              />
                                              )}
                                              />
                                            </fieldset> 
                                            <fieldset className="col-md-3">
                                              <TextField
                                                id="standard-error-helper-text"
                                                label="Enter Amount (per Quantity)"
                                                type="number"
                                                value={amount}
                                                onChange={handleAmount}
                                                variant="standard"
                                                fullWidth
                                              />
                                            </fieldset>
                                            <fieldset className="col-md-3">
                                              <TextField
                                                id="standard-error-helper-text"
                                                label="Enter Quantity"
                                                type="number"
                                                value={equipmentquantity}
                                                onChange={handleEquipmentQuantity}
                                                variant="standard"
                                                error={equipmentquantity === ""}
                                                helperText={
                                                  equipmentquantity === ""
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            </fieldset>
                                            <fieldset className="col-md-4 d-flex align-items-center">
                                              <span
                                                as="button"
                                                className="btn btn-sm hovbuttonColour"
                                                onClick={handleAddEquipments}
                                              >
                                                Add
                                              </span>
                                            </fieldset>
                                          </div>
                                        ) : (
                                            ""
                                          )}
                                        {equipmentList.length !== 0 && (activeStep === 1) ? (
                                          <TableContainer>
                                            <Typography>Equipments</Typography>
                                            <Table className="table-sm">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell className="text-center">
                                                    Equipment
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Quantity
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Amount
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Total Amount
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    Actions
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {equipmentList.map((e, index) => (
                                                  <TableRow key={index}>
                                                    <TableCell className="text-center">
                                                      {e.name}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      {e.quantity}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                    <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                  </TableCell>
                                                    
                                                    <TableCell className="text-center">
                                                      <span
                                                        as="buttom"
                                                        className="btn btn-sm deletebutton"
                                                        onClick={() =>
                                                          handelRemoveEquipment(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Remove
                                                      </span>
                                                    </TableCell>
                                                  </TableRow>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                      ) : (
                                            ""
                                      )}
                                        {/* ----------Equipment End--------- */}

                                        {/* ----------Man Power Start--------- */}
                                        {((activeStep === 2)) ? (
                                        <div className="form-row">
                                          <fieldset className="col-md-4">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Position/Type"
                                              value={mantype}
                                              onChange={handleSelectManType}
                                              variant="standard"
                                              error={mantype === ""}
                                              helperText={
                                                mantype === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-4">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter No. of Employees/Workers/Count"
                                              type="number"
                                              value={mancount}
                                              onChange={handleManCount}
                                              variant="standard"
                                              error={mancount === ""}
                                              helperText={
                                                mancount === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Amount (per Head)"
                                              type="number"
                                              value={amount}
                                              onChange={handleAmount}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-4 d-flex align-items-center">
                                            <span
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddManPower}
                                            >
                                              Add
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {manpowerList.length !== 0 && ( activeStep === 2) ? (
                                        <TableContainer>
                                          <Typography>Man Power</Typography>
                                          <Table className="table-sm">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="text-center">
                                                  Position/Type
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  No. of Employees/Workers/Count
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Amount
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Total Amount
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {manpowerList.map((e, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {e.mantype}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.mancount}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                  <TableCell className="text-center">
                                                    <span
                                                      as="buttom"
                                                      className="btn btn-sm deletebutton"
                                                      onClick={() =>
                                                        handleRemoveManPower(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      ) : (
                                        ""
                                      )}
                                        {/* ----------Man Power End--------- */}
                                       
                                      </Form>
                                    )}
                                  </Formik>
  
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <Button
                                      color="inherit"
                                      disabled={activeStep === 0}
                                      onClick={handleBack}
                                      sx={{ mr: 1 }}
                                      style={{ outline: "none" }}
                                    >
                                      ❮ Back
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button
                                      onClick={handleNext}
                                      sx={{ mr: 1 }}
                                      disabled={activeStep === 5}
                                      style={{ outline: "none" }}
                                    >
                                      Next ❯
                                    </Button>
                                  </Box>
                                </React.Fragment>
                              )}
                            </div>
                          </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  
  export default withRouter(NewProjectIndenting)
  