import React, { Component } from 'react'
import { Formik, Form } from "formik";
import withRouter from '../withRouter';
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete} from '@mui/material';
import {totalcombdata} from './Journal';
import { NumericFormat } from 'react-number-format';import { ccountry, currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
// import LinearProgress from '@mui/material/LinearProgress';
import { FormLabel } from '@mui/material';
import { Typography} from '@mui/material'; //styled
import moment from 'moment';
import FileServices from '../Common/upload/FileServices';
import UploadFilesService from '../Common/upload/UploadFilesService';
import NewJournalService from './NewJournalService';
import { TableHead,TableBody,TableRow,TableContainer,Table, TableCell} from "@mui/material";
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';

const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach(totamt => {
    total += totamt;
  });
  return total;
};
let curmonth,year,day,pmtcheck,pnpcheck,advstatus=0//entrytype
let doctype = "supplierinvoices"
let ppaymennts,ppaymentid,ppaymennts2,genpayments,jvtype=3,tcsdata,advpayments,advsupled,advancepaid,totalpending=[],pgenpayments=[]
// const BorderLinearProgress = styled((theme) => ({
// root: {height: 15,borderRadius: 5,},
// colorPrimary: {backgroundColor: "#EEEEEE",},
// bar: {borderRadius: 5,backgroundColor: '#1a90ff',},
// }))(LinearProgress);

const Checkbox = props => (
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
)
class JournalPayment extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            partyid:null,poid:null,pmtid:null,
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            paymenttype: [{ id: 1, name: "GRN/SCN-Invoice" },{ id: 2, name: "Direct Invoice" },{ id: 3, name: "Advance" }],
            pmttype:[{ id: 0, name: "Purchase" },{ id: 1, name: "Non-Purchase" }],
            debselval:'',pmtchecked:'',pnpchecked:'',tcschecked:0,selectedFiles: undefined,serviceamount:null,serviceledger:"",
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],
            fileInfo:"",pendingPayments:[],selectedPP:[],selectedPP2:[],selectedPPO:[],selectedPPO2:[],
            selchk:[],selchk1:[],selamt:[],selamt2:[],displaychecked:false,prevpmtschecked:false,advancepo:[],selectedadv:[],selamt3:[],
            selserv:[],selpmts:[],genpayment:[],
            loader:false
             
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.selectFile=this.selectFile.bind(this);this.upload=this.upload.bind(this);
        this.CheckBoxSelect=this.CheckBoxSelect.bind(this);this.handleAdvpaid=this.handleAdvpaid.bind(this)

    }

    selectFile=event=> {
        this.setState({selectedFiles: event.target.files,});
      }

      upload() {
        let currentFile = this.state.selectedFiles[0];
         this.setState({progress: 100,currentFile: currentFile,});
        UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
          this.setState({progress: Math.round((100 * event.loaded) / event.total)});
        }).then((response) => {
         this.setState({message: response.data.message,isError: false});
            return FileServices.getFiles1(currentFile.name);
          }).then((files) => {this.setState({fileInfos: files.data,});
          }).catch(() => {
            this.setState({progress: 0,message: "Could not upload the file!",currentFile: undefined,
              isError: true}); });
        this.setState({selectedFiles: undefined,});
      }

    onDebBlur(values){
      this.setState({debselval:values.debit})
      if(values.debit!==undefined && values.debit.length!==0){
        this.setState({partyid:values.debit.id})
      }
    }

    onSubmit(values){
      this.setState({loader:true})
      // let filedata= this.state.fileInfos.map((e)=>e.name)
      let selected
      let mode,bank,poadvance
      let tcsid,tcsname,tcsvalue,tcspercentage,tcsamt,advledger=null
      if(values.credit.id==="RLED1"){
        mode=0
      }else{
        mode=1
        bank=values.credit.name
      }
      if(this.state.tcschecked===1){
        tcsid=values.tcs.id
        tcsname=values.tcs.name
        tcsvalue=values.tcsval
        tcspercentage=values.tcspercent
        tcsamt=values.tcstotal

      }

      if(pmtcheck===1){
        if(this.state.selchk!==undefined){
          if(this.state.selchk.length!==0){
          selected=this.state.selchk
          }else{
          selected=this.state.selserv
          }
        }
      }else if (pmtcheck===2){
        if(this.state.selchk1!==undefined){
          selected=this.state.selchk1
        }
      }
      // let a
      if(this.state.selectedadv!==undefined){
        poadvance=this.state.selectedadv.map((e)=>e.poadvance)
        this.state.selectedadv.map((e)=>{ return advledger=e.advledger})
      }
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
        creditid: values.credit.id,creditname: values.credit.name,creditamt: values.creditvalue,
        narration: values.narration,createdBy: userid,tenantId: currentTenant,
        selectedval:selected,jvtype:jvtype,poid:this.state.poid,mode:mode,paymentref:values.paymentref,
        poadvance:poadvance.find((e,i)=>e.valueOf(i)),
        advledger:advledger,advstatus:advstatus,
        bank:bank, pmtid:this.state.pmtid.toLocaleString(),tcsid:tcsid,tcsname:tcsname,tcsvalue:tcsvalue,tcspercentage:tcspercentage,
        tcsamt:tcsamt,sdebitamt:this.state.serviceamount,sdebitid:this.state.serviceledger
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      } 
      else {
        this.props.navigate(`/journalconfirm`, { jv });
      }
    }

    componentDidMount(){
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      year = new Date().getFullYear()
      day= new Date().getDate()
      let mn = new Date().getMonth()+1
      curmonth= moment(mn,'M').format('MMMM')
      NewJournalService.getPendingPayments(currentTenant)
      .then(response =>{
        this.setState({pendingPayments:response.data})  
      }) 
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
        this.setState({loading:false})
       }

     componentWillUnmount(){
        this.render()
     }
     validate(values){
      let errors = {},deb = values.debitval,cre = values.creditvalue//type = combdata
      //  let ledgers= type.filter((e) => e.type === 1)

       if (!values.debit || values.debit==='') {errors.debit = "Accounts is required";}
       if(!values.debitval){errors.debitval="Value Cannot be Blank"}else if(deb!==cre){
        errors.creditvalue="Value Cannot be be different from debit Value"}
       if (!values.credit || values.credit==='') {errors.credit = "Accounts is required"}
       if(!values.creditvalue){errors.creditvalue="Value Cannot be Blank"}else if(deb!==cre){
        errors.creditvalue="Value Cannot be be different from debit Value"}
       if (!values.narration) {errors.narration = "Narration is required";}
       if (!values.paymentref) {errors.paymentref = "Payment Reference is required";}return errors;
     }

    onPPBlur(value){
      let selpp //,selval,pendingpo
     if(value!==undefined){       

       if(pmtcheck===1){
      //  selval=this.state.pendingPayments.filter((e)=>e.supname===value && e.transpendingamount!==0 && e.poid!==null && e.poadvance===0)
       selpp = this.state.pendingPayments.filter((e)=>e.supname===value && e.poid!==null && e.transpendingamount!==0 && e.poadvance===0)
      this.setState({selectedPP:selpp})
       } else if(pmtcheck===2){
        this.setState({pmtid:null})
        // selval=this.state.pendingPayments.filter((e)=>e.supname===value && e.transpendingamount!==0 && e.poid===null && e.poadvance===0)
        //pmtid=selval.map((e)=>e.id)
       //this.setState({pmtid:pmtid})
        selpp = this.state.pendingPayments.filter((e)=>e.supname===value && e.poid===null && e.transpendingamount!==0 && e.poadvance===0)
        this.setState({selectedPP2:selpp})

       }else if(pmtcheck===3){
        this.setState({pmtid:null})
        // selval=this.state.pendingPayments.filter((e)=>e.supname===value && e.transpendingamount!==0 && e.poid!==null && e.poadvance!==0)
        //pmtid=selval.map((e)=>e.id)
      // this.setState({pmtid:pmtid})
        selpp = this.state.pendingPayments.filter((e)=>e.supname===value && e.poid!==null && e.transpendingamount!==0 && e.poadvance!==0)
        this.setState({advancepo:selpp})

       } 
       }
        else{
      this.setState({selectedPP:[],selectedPP2:[],advancepo:[],genpayment:[]})}

     if(pnpcheck===1){
       let pmtid
        this.setState({pmtid:null})
        // selval=this.state.pendingPayments.filter((e)=>e.supname===value && e.poid===null && e.transpendingamount!==0 && e.poadvance===null && e.id!==null)
        //pmtid=selval.map((e)=>e.id)
      // this.setState({pmtid:pmtid})
        selpp = this.state.pendingPayments.filter((e)=>e.supname===value && e.poid===null && e.transpendingamount!==0 && e.poadvance===null && e.id!==null)
        this.setState({genpayment:selpp})
        pmtid=selpp.map((e)=>e.id)
       this.setState({pmtid:pmtid})
        pgenpayments=selpp
       }else if(pnpcheck===0){
        pgenpayments=[]
        this.setState({genpayment:[]})
       }
    }

    
    Popending(value){
      let pendingpo,pmtid
      if(value!==undefined ){
       this.setState({poid:value})
       if(pmtcheck===1){
        pendingpo = this.state.selectedPP.filter((e)=>e.poid===value)
       this.setState({pmtid:null})
       pmtid=pendingpo.map((e)=>e.id)
       this.setState({pmtid:pmtid})
       this.setState({selectedPPO:pendingpo},()=>this.CheckBoxSelect(value))
       }else if(pmtcheck===2){
        this.setState({poid:""})
        pendingpo = this.state.selectedPP2.filter((e)=>e.id===value)
        this.setState({pmtid:null})
       pmtid=pendingpo.map((e)=>e.id)
       this.setState({pmtid:pmtid})
        this.setState({selectedPPO2:pendingpo},()=>this.CheckBoxSelect(value))
       }else if(pmtcheck===3){
        this.setState({poid:value})
        pendingpo = this.state.advancepo.filter((e)=>e.poid===value)
        this.setState({pmtid:null})
       pmtid=pendingpo.map((e)=>e.id)
       this.setState({pmtid:pmtid})
        this.setState({selectedadv:pendingpo},()=>this.CheckBoxSelect(value))
      }
       }else{this.setState({selectedPPO:[],selectedPPO2:[],selectedadv:[]})}}
   
    onTcsChange(option){this.setState({ tcschecked: option.id });}
    onPmtChange(option) {
      if(option.id===1){
        this.setState({selectedPPO2:[]})
      }else if(option.id===2){
        this.setState({selectedPPO:[]})
      }
    pmtcheck=option.id
    this.setState({pmtchecked: option.id })}
    onPnpChange(option) {
      pnpcheck=option.id
      this.setState({ pnpchecked: option.id });
      if(option.id===0){
        pmtcheck=1
        this.setState({ pmtchecked: option.id });
      }
 }


  CheckBoxSelect(poid){
     if(pmtcheck===1){
      let servval,seltran
      this.setState({selpmts:[]})
      let val =this.state.selectedPPO.filter((e)=>e.poid===poid)
      this.setState({selamt:val.map((e)=>e.transpendingamount)})
      val.map((e)=>{return seltran= e.pmttrans})
      this.setState({selpmts:seltran})
      val.map((e)=>{return servval=e.savailed})
      this.setState({selserv:servval})
      NewJournalService.getitems(poid)
      .then(response =>{
        this.setState({selchk:response.data})  
      })  
    } else if(pmtcheck===2){
      this.setState({selpmts:[]})
      let val =this.state.selectedPPO2.filter((e)=>e.id===poid)
      this.setState({selamt2:val.map((e)=>e.transpendingamount)})
      this.setState({selpmts:val.map((e)=>{return e.pmttrans})})
      this.setState({selchk1:val.map((e)=>e.pitems)})  
    }
    else if(pmtcheck===3){
      let val =this.state.selectedadv.filter((e)=>e.poid===poid)
      this.setState({selamt3:val.map((e)=>e.poadvance)})
      // this.setState({selchk2:val.map((e)=>e.pitems)})  
    }
  }

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
 }
 handlePrevBalChange= event =>{
  this.setState({ prevpmtschecked: event.target.checked })
}
handleAdvpaid(){
  advstatus=1
}
    render() {
      let totamt,cred1
      // const {selectedFiles,currentFile,progress,message,fileInfos,isError} = this.state;
      const {message,isError} = this.state;
      let debvalue,credvalue,selpp,gst,selchk,selpp2,selpp3
      // entrytype=1
      let val=totalcombdata 
      if(val!==undefined){
      tcsdata=val.filter((e) => (e.taxtype === 1))
      }
      
      if(pnpcheck===1){
        let ledid
        pgenpayments.map(e=>{return ledid=e.supid})
        debvalue=val.filter((e)=> e.id===ledid)
      }else if(pmtcheck===1){
        debvalue=val.filter((e)=> e.id===ppaymentid)
      }else if(pmtcheck===3){
        debvalue=val.filter((e)=> e.id===advsupled)
      }else{
        debvalue=val.filter((e)=> e.rule===0 & e.type===1)

      }
      
      cred1=val.filter((e) => e.type === 2)
      credvalue=cred1.filter((e)=>e.subheadid==="AH000020" || e.subheadid==="AH000021" )
      if(this.state.pendingPayments!==undefined && this.state.pendingPayments.length!==0){
            let withgrnPayments
          if(pmtcheck===1){
            withgrnPayments=this.state.pendingPayments.filter((e)=>e.poid!==null && e.transpendingamount!==0 && e.poadvance===0)
            ppaymennts = [...new Set(withgrnPayments.map((e) => { return e.supname })),];
            withgrnPayments.map((e) => { return ppaymentid=e.ledgerid })
          }else if(pmtcheck===2){
              withgrnPayments=this.state.pendingPayments.filter((e)=>e.poid===null && e.transpendingamount!==0 && e.poadvance===0)
              ppaymennts2 = [...new Set(withgrnPayments.map((e) => { return e.supname })),];
          }else if(pmtcheck===3){
            withgrnPayments=this.state.pendingPayments.filter((e)=>e.poid!==null && e.transpendingamount!==0 && e.poadvance!==0)
            advpayments = [...new Set(withgrnPayments.map((e) => { return e.supname })),];
            withgrnPayments.map((e) => { return advsupled=e.ledgerid })
        }
        }
       if(pnpcheck===1){
        let withgenPayments
        withgenPayments=this.state.pendingPayments.filter((e)=>e.poid===null && e.transpendingamount!==0 && e.poadvance===null && e.id!==null)
          genpayments = [...new Set(withgenPayments.map((e) => { return e.supname })),];
      }
        if(pmtcheck===1){
          if(this.state.selectedPP!==undefined){
            selpp = [...new Set(this.state.selectedPP.map((e) => { return e.poid })),];
            this.state.selectedPP.map((e)=>{return this.setState({serviceamount:e.serviceamount})})
            this.state.selectedPP.map((e)=>{return this.setState({serviceledger:e.servledger})})

        }
        }else if(pmtcheck===2){
          if(this.state.selectedPP2!==undefined){
          selpp2 = [...new Set(this.state.selectedPP2.map((e) => { return e.id })),];
         }
      }else if(pmtcheck===3){
        if(this.state.selectedadv!==undefined){
        selpp3 = [...new Set(this.state.advancepo.map((e) => { return e.poid })),];
       }
      }

      if(pmtcheck===1 && this.state.selectedPPO!==undefined){
            gst = [...new Set(this.state.selectedPPO.map((e) => { return e.gstin })),];
            selchk= this.state.selchk.map((e) => e.id)
            totamt=this.state.selamt.map(e=>e)
            totalpending=[...new Set(this.state.selectedPPO.map((e) => { return e.pendingamount})),];
            let ap=[...new Set(this.state.selectedPPO.map((e) => { return e.advancepaid})),];
            advancepaid=''
            if(ap.find((e,i)=>e!==null)){
              advancepaid=ap.find((e,i)=>e.valueOf(i));
            }
          }else if(pmtcheck===2 && this.state.selectedPPO2.length!==0 ){
            gst = [...new Set(this.state.selectedPPO2.map((e) => { return e.gstin })),];
            this.state.selchk1.map((e) => { return selchk=e })
            totamt=this.state.selamt2.map(e=>e)
            totalpending=[...new Set(this.state.selectedPPO2.map((e) => { return e.pendingamount})),];
          }else if(pmtcheck===3 && this.state.advancepo.length!==0 ){
            gst = [...new Set(this.state.advancepo.map((e) => { return e.gstin })),];
            //let tmp=[...new Set(this.state.selchk1.map((e) => { return selchk=e })),];
            totamt=this.state.selamt3.map(e=>e)
            totalpending=[...new Set(this.state.advancepo.map((e) => { return e.pendingamount})),];
          }

          if(pnpcheck===1 && pgenpayments.length!==0 ){
           // gst = [...new Set(this.state.advancepo.map((e) => { return e.gstin })),];
            //let tmp=[...new Set(this.state.selchk1.map((e) => { return selchk=e })),];
            //totamt=this.state.selamt3.map(e=>e)
            totamt=pgenpayments.map(e=>e.pendingamount)
            totalpending=[...new Set(pgenpayments.map((e) => { return e.pendingamount})),];
            // console.log(totalpending.find((e,i)=>e.valueOf(i))>= getTotal(totamt))
          }
          if(advancepaid!==''){
            if(totalpending.find((e,i)=>e.valueOf(i))<=totamt){
              this.handleAdvpaid();
            } 
          }
        return (
            <div>
        
    <Formik
      initialValues={{ debvalue,credvalue,narration:'',selpp,selpp2,ppaymennts,ppaymennts2,selchk}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">


<div className="form-row mt-0 mb-0">

<fieldset className="form-group col-md-4">
<FormLabel>Payment Type</FormLabel>
{this.state.pmttype.map((option) => {return (<label key={option.id}>
 <div className="form-group col-md-12 mt-0 mb-0"> <div className="radio-item mt-0 mb-0 text-sm">
<input type="radio" checked={this.state.pnpchecked === option.id ? true : false} key={option.id}
 onChange={this.onPnpChange.bind(this, option)} style={{ marginRight: 8 }} value={option.id}/>
 {option.name} </div> </div> </label>); })} </fieldset>

{this.state.pnpchecked===0?
<fieldset className="form-group col-md-6 mt-0 mb-0"> 
<FormLabel>Purchase Payments</FormLabel>
{this.state.paymenttype.map((option) => {return (<label key={option.id}>
<div className="form-group col-md-12 mt-0 mb-0"><div className="radio-item mt-0 mb-0 text-sm">
<input type="radio" checked={pmtcheck === option.id ? true : false} key={option.id}
onChange={this.onPmtChange.bind(this, option)} style={{ marginRight: 8 }} value={option.id}/>
{option.name}</div></div></label>); })}</fieldset>:""}</div>

<div className="form-row mt-0 mb-0 text-sm sm">
{this.state.pnpchecked===0 && pmtcheck===1  && ppaymennts!==undefined ?<>
  {ppaymennts.length!==0?
<fieldset  className="form-group col-md-4 mt-0 mb-3 text-sm sm"> 
<Autocomplete id="pendingpayment" className="payment-select" name="ppayment" options={ppaymennts}
 getOptionLabel={(option) => option}  loadingText="Loading..."
  value={values.ppayment?undefined:undefined} 
onChange={(e, value) => {setFieldValue("ppayment",value)}} renderInput={params => (
<FormikTextField {...params}  type="text" name="ppayment" label="Pending Payments" className="text-sm"
 onBlur={(e)=>this.onPPBlur(e.target.value)} variant="standard" fullWidth />)}/> </fieldset>:
 this.state.pnpchecked===0 && pmtcheck===1  && ppaymennts.length===0 ?
  <fieldset  className="form-group col-md-4 mt-0 mb-3"> 
  <FormLabel className="text-success">No Pending Payments</FormLabel></fieldset>:""}</>:""}

  {this.state.pnpchecked===0 && ppaymennts!==undefined  && selpp!==undefined && pmtcheck===1?<>
  {ppaymennts.length!==0?
 <fieldset  className="form-group col-md-3 mt-0 mb-3 text-sm sm"> 
 <Autocomplete id="pendingpo" className="payment-select" name="popending" options={selpp}
  getOptionLabel={(option) => option}  loadingText="Loading..."
   value={values.ppayment?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("popending",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="popending" className="text-sm" label="Pending Po's" 
  onBlur={(e)=>this.Popending(e.target.value)} variant="standard" fullWidth />)}/></fieldset>
  :""}
  </>:""}

  {this.state.pnpchecked===0 && pmtcheck===2  && ppaymennts2!==undefined?<>
    {ppaymennts2.length!==0?
 <fieldset  className="form-group col-md-4 mt-0 mb-3 text-sm sm"> 
 <Autocomplete id="pendingpayment" className="payment-select" name="ppayment" options={ppaymennts2}
 getOptionLabel={(option) => option}  loadingText="Loading..."
  value={values.ppayment?undefined:undefined} 
 onChange={(e, value) => {setFieldValue("ppayment",value)}} renderInput={params => (
 <FormikTextField {...params}  type="text" name="ppayment" label="Pending Payments" className="text-sm"
  onBlur={(e)=>this.onPPBlur(e.target.value)} variant="standard" fullWidth />)}/> </fieldset>:
  this.state.pnpchecked===0 && pmtcheck===2  && ppaymennts2.length===0?
  <fieldset  className="form-group col-md-4 mt-0 mb-3"> 
  <FormLabel className="text-success">No Pending Payments</FormLabel></fieldset>:""}</>:""}
 
  {this.state.pnpchecked===0 && ppaymennts2!==undefined && selpp2!==undefined && pmtcheck===2?<>
  {ppaymennts2.length!==0?
 <fieldset  className="form-group col-md-3 mt-0 mb-3 text-sm sm"> 
 <Autocomplete id="pendingpmt" className="payment-select" name="pmtpending" options={selpp2}
  getOptionLabel={(option) => option}  loadingText="Loading..."
   value={values.ppayment?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("popending",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="pmtpending" className="text-sm" label="Pending Payments" 
  onBlur={(e)=>this.Popending(e.target.value)} variant="standard" fullWidth />)}/></fieldset>
  :""}</>:""}

{this.state.pnpchecked===0 && pmtcheck===3  && advpayments!==undefined ?<>
  {advpayments.length!==0?
<fieldset  className="form-group col-md-4 mt-0 mb-3 text-sm sm"> 
<Autocomplete id="pendingpayment" className="payment-select" name="ppayment" options={advpayments}
 getOptionLabel={(option) => option}  loadingText="Loading..."
  value={values.ppayment?undefined:undefined} 
onChange={(e, value) => {setFieldValue("ppayment",value)}} renderInput={params => (
<FormikTextField {...params}  type="text" name="ppayment" label="Pending Payments" className="text-sm"
 onBlur={(e)=>this.onPPBlur(e.target.value)} variant="standard" fullWidth />)}/> </fieldset>:
 this.state.pnpchecked===0 && pmtcheck===3  && advpayments.length===0 ?
  <fieldset  className="form-group col-md-4 mt-0 mb-3"> 
  <FormLabel className="text-success">No Pending Payments</FormLabel></fieldset>:""}</>:""}

  {this.state.pnpchecked===0 && advpayments!==undefined  && selpp3!==undefined && pmtcheck===3?<>
  {advpayments.length!==0?
 <fieldset  className="form-group col-md-3 mt-0 mb-3 text-sm sm"> 
 <Autocomplete id="pendingpo" className="payment-select" name="popending" options={selpp3}
  getOptionLabel={(option) => option}  loadingText="Loading..."
   value={values.ppayment?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("popending",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="popending" className="text-sm" label="Pending Po's" 
  onBlur={(e)=>this.Popending(e.target.value)} variant="standard" fullWidth />)}/></fieldset>
  :""}
  </>:""}

{/* ------------- Selection for NON SO's ---------------*/}
  {pnpcheck===1 ?<>
    {genpayments.length!==0?
 <fieldset  className="form-group col-md-4 mt-0 mb-3 text-sm sm"> 
 <Autocomplete id="genpayments" className="payment-select" name="gpayment" options={genpayments}
 getOptionLabel={(option) => option}  loadingText="Loading..."
  value={values.ppayment?undefined:undefined} 
 onChange={(e, value) => {setFieldValue("gpayment",value)}} renderInput={params => (
 <FormikTextField {...params}  type="text" name="gpayment" label="Pending Payments" className="text-sm"
  onBlur={(e)=>this.onPPBlur(e.target.value)} variant="standard" fullWidth />)}/> </fieldset>:
  pnpcheck===1  && genpayments.length===0?
  <fieldset  className="form-group col-md-4 mt-0 mb-3"> 
  <FormLabel className="text-success">No Pending Payments</FormLabel></fieldset>:""}</>:""}

  {/* ----------------------------*/}


{this.state.pnpchecked===0 && pmtcheck===1  && ppaymennts!==undefined ?
 <fieldset className="form-group col-md-5">
 {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0?
<table className="float-center"> <tbody>
 <tr><td className="text-bold text-right">Supplier GST :</td><td>{gst}</td></tr>
 <tr><td className="text-bold text-right">Transaction Pending:</td>
 <td className="text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {getTotal(totamt)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td> </tr>
{advancepaid!==''?
totalpending.find((e,i)=>e.valueOf(i))<=getTotal(totamt)?
<tr><td className="text-bold text-right">Less Advance :</td>
 <td className="text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {advancepaid} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td> </tr>:"":""}
  <tr><td className="text-bold text-right">Total Pending :</td>
<td className="text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {totalpending.find((e,i)=>e.valueOf(i))} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td></tr>
</tbody></table>:""}</fieldset>:

this.state.pnpchecked===0 && pmtcheck===2 && ppaymennts2!==undefined ?
 <fieldset className="form-group col-md-5">
 {this.state.selectedPPO2.length!==0 || this.state.selchk1.length!==0?
<table className="float-center"> <tbody>
 <tr><td className="text-bold text-right">Supplier GST :</td><td>{gst}</td></tr>
 <tr><td className="text-bold text-right">Pending Amount :</td>
 <td>{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {getTotal(totamt)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td></tr>
<tr><td className="text-bold text-right">Total Pending :</td>
<td>{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {totalpending.find((e,i)=>e.valueOf(i))} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td></tr></tbody></table>:""}</fieldset>:

 this.state.pnpchecked===0 && pmtcheck===3 && advpayments!==undefined ?
 <fieldset className="form-group col-md-5">
 {advpayments.length!==0 ?
<table className="float-center"> <tbody>
 <tr><td className="text-bold text-right">Supplier GST :</td><td>{gst}</td></tr>
 <tr><td className="text-bold text-right">Advance Request :</td>
 <td>{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {this.state.selamt3.find((e,i)=>e.valueOf(i)) }thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td></tr>
<tr><td className="text-bold text-right">Total Pending :</td>
<td>{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {totalpending.find((e,i)=>e.valueOf(i))} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</td></tr>
 </tbody></table>:""}</fieldset>:""}

{this.state.pnpchecked===0 && pmtcheck===1 && this.state.selectedPPO.length!==0?<>
<fieldset className="form-group col-md-12">
<TableContainer ><Table className="table-sm" aria-label="customized table">
  <TableHead className="text-bold text-blue">
  <TableRow  style={{ textAlign: "center" }}>
    <TableCell>Date</TableCell>
  <TableCell>PO Amt</TableCell><TableCell>Invoice Amt</TableCell><TableCell>GRN/SCN Amt Pending</TableCell>
  {this.state.serviceamount!==null?<TableCell>Service Amount</TableCell>:""}
  <TableCell>Tax</TableCell>
  <TableCell>Total</TableCell></TableRow></TableHead><TableBody>
  {this.state.selectedPPO!== undefined || this.state.selectedPPO.length!==0? 
  this.state.selectedPPO.map((e) => (
   <TableRow key={e.id} style={{ textAlign: "center" }}>
<TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell>
<TableCell>{e.poamount}</TableCell><TableCell>{e.proinvoiceamt}</TableCell><TableCell>{this.state.serviceamount!==null?e.transpendingamount-e.tax-this.state.serviceamount:e.transpendingamount-e.tax}</TableCell>
{this.state.serviceamount!==null?<TableCell>{this.state.serviceamount}</TableCell>:""}
<TableCell>{e.tax}</TableCell> <TableCell>{e.transpendingamount}</TableCell>
 </TableRow> )):""}</TableBody></Table></TableContainer></fieldset>


<> <fieldset className="form-group col-md-2 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.displaychecked}
            onChange={this.handleCheckboxChange}
          />
          {this.state.displaychecked===true?"Hide Grn/Srcn-Items":"Show Grn/Scn-Items"}
        </label>
           </div>
           </fieldset>

            <fieldset className="form-group col-md-2 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.prevpmtschecked}
            onChange={this.handlePrevBalChange}
          />
          {this.state.prevpmtschecked===true?"Hide Prev Payments":"Show Prev Payments"}
        </label>
           </div>
           </fieldset></>
 
  {this.state.displaychecked===true?

 <fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table">
{ this.state.selchk.length!==0?<>
<TableHead className="text-bold">
   <TableRow style={{ textAlign: "center" }}>
  <TableCell>

  </TableCell>
 <TableCell>Item</TableCell><TableCell>GRN</TableCell><TableCell>Date</TableCell><TableCell>Price</TableCell>
 <TableCell>Tax</TableCell><TableCell>Qty</TableCell>
 <TableCell>Total</TableCell></TableRow></TableHead>
 <TableBody>{this.state.selectedPPO!== undefined || this.state.selectedPPO.length!==0? 
  this.state.selchk.map((e) => (
  <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>
  </TableCell><TableCell>{e.name}</TableCell><TableCell>{e.grnid}</TableCell>
  <TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.unitprice}</TableCell>
  <TableCell>{e.taxamount}</TableCell>
  <TableCell>{e.quantity}</TableCell><TableCell>{e.unitprice * e.quantity}</TableCell>
  </TableRow>)):""}</TableBody></>:<>
    <TableHead><TableRow className="text-sm" >
            <TableCell>Service Name</TableCell>
            <TableCell>Milestone</TableCell><TableCell>Amount</TableCell><TableCell>Percentage</TableCell>
             <TableCell>Completion Date</TableCell></TableRow></TableHead>
              <TableBody>
               {this.state.selserv.map((e) => (
               <TableRow >
                 <TableCell>{e.scnname}</TableCell><TableCell>{e.milestone}</TableCell>
                  <TableCell>{e.totalwithtax}</TableCell>
                  <TableCell>{e.percentage}</TableCell>
                   <TableCell>{moment(e.completiondate).format('DD-MM-yyyy HH:mm')}</TableCell>
                  </TableRow>))} </TableBody></>}
  
  
  </Table></TableContainer></fieldset>:""}</>:""} 

{pnpcheck===1 && pgenpayments!==null && pgenpayments.length!==0?

<fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table">
<TableHead className="text-bold">
 <TableRow style={{ textAlign: "center" }}>

<TableCell>PMT ID</TableCell><TableCell>Expense Ledger</TableCell><TableCell>Date</TableCell><TableCell>Amount</TableCell>
</TableRow></TableHead>
<TableBody>
{pgenpayments.map((e)=>(
<TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>{e.id}</TableCell><TableCell>{e.supname}</TableCell>
<TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.pendingamount}</TableCell>
</TableRow>))}</TableBody>
</Table></TableContainer></fieldset>:""}


  {this.state.prevpmtschecked===true?

  <fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table">
<TableHead className="text-bold">
   <TableRow style={{ textAlign: "center" }}>
  
 <TableCell>POID</TableCell><TableCell>Trans ID</TableCell><TableCell>Date</TableCell><TableCell>Amount</TableCell>
 <TableCell>Mode</TableCell><TableCell>Bank Name</TableCell><TableCell>Branch</TableCell></TableRow></TableHead>
 <TableBody>
 {this.state.selpmts.map((e)=>(
  <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>{e.poid}</TableCell><TableCell>{e.transid}</TableCell>
  <TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.paymentamount}</TableCell>
  <TableCell>{e.mode}</TableCell><TableCell>{e.bank}</TableCell><TableCell>{e.branch}</TableCell>
  </TableRow>))}</TableBody>
  </Table></TableContainer></fieldset>:""}




  
  {this.state.pnpchecked===0 && pmtcheck===2 && this.state.selectedPPO2!==undefined && ppaymennts2!==undefined?<>
    {ppaymennts2.length!==0?

 <fieldset className="form-group col-md-12 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.displaychecked}
            onChange={this.handleCheckboxChange}
          />
          {this.state.displaychecked===true?"Hide Items":"Show Items"}
        </label>
           </div>
           </fieldset>:""}</>:""}
  {this.state.displaychecked===true?<>

  {this.state.pnpchecked===0 && pmtcheck===2 && this.state.selectedPPO2!== undefined ?
<> <fieldset className="form-group col-md-12">
<TableContainer ><Table className="table-sm" aria-label="customized table">
<TableHead className="text-bold"><TableRow style={{ textAlign: "center" }}>
<TableCell></TableCell>
<TableCell>Item</TableCell><TableCell>Date</TableCell><TableCell>Price</TableCell>
<TableCell>Tax</TableCell><TableCell>Qty</TableCell><TableCell>Total</TableCell></TableRow></TableHead><TableBody>
  {selchk!== undefined? 
  selchk.map((e) => (
    <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>
    </TableCell><TableCell>{e.name}</TableCell>
    <TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.unitprice}</TableCell>
    <TableCell>{e.taxamount}</TableCell><TableCell>{e.quantity}</TableCell><TableCell>{e.unitprice * e.quantity}</TableCell>
    </TableRow> )):""}</TableBody></Table></TableContainer></fieldset>
  
  </>:""}</>:""}



  </div>
  {pmtcheck!==undefined || pnpcheck===1?<>
 <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={debvalue}
  getOptionLabel={(option) => option.name } 
  getOptionSelected={(option) => option.name } value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>totamt!==undefined?
   totalpending.find((e,i)=>e.valueOf(i))>=getTotal(totamt)?setFieldValue("debitval",getTotal(totamt)):setFieldValue("debitval",totalpending.find((e,i)=>e.valueOf(i))) && this.onDebBlur(values):setFieldValue("debitval",values.debitval) 
   && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField></fieldset>
  </div>     

<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={credvalue}
getOptionLabel={(option) => option.name +" ("+option.amount+")"}
 renderOption={(option) => option.name +" ("+option.amount+")"} 
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard" fullWidth />)}/>
</fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0" value={values.debitval} 
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
 </fieldset>
 </div>



{ccountry==="India" &&  this.state.pnpchecked!==undefined &&  this.state.pnpchecked===0?<>
{pmtcheck===1 || pmtcheck===2 ?
 <div className="form-row mt-0 mb-0 sm "> 
  <fieldset className="form-group col-md-2 text-sm mt-6">
  <FormLabel>TCS :</FormLabel>{this.state.tdsreq.map((option) => {return (
  <label key={option.id}><div className="form-group col-md-12 mt-0 mb-0"><div className="radio-item mt-0 mb-0 text-sm">
  <input type="radio" checked={this.state.tcschecked === option.id ? true : false} key={option.id}
  onChange={this.onTcsChange.bind(this, option)} style={{ marginRight: 8 ,marginTop: 25 }} value={option.id}/>
  {option.name}</div></div> </label>); })} </fieldset>

  {this.state.tcschecked===1?<>
  <fieldset  className="form-group col-md-4 mt-0 mb-0"> 
  <Autocomplete id="itcs" className="tcs-select text-sm" name="atcs" options={tcsdata}
  getOptionLabel={(option) => option.name}
    
   value={values.tcs?undefined:undefined} onChange={(e, value) => { setFieldValue("tcs",value);}}
   renderInput={params => (
   <FormikTextField {...params}  type="text" name="tcs" className="text-sm" label="Select TCS"
   onBlur={()=>setFieldValue("tcsval",values.debitval)} variant="standard" fullWidth />)}/>
  </fieldset>
   <fieldset  className="form-group col-md-3">   
   <FormikTextField name="tcsval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"} type="number" 
  //onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField>
   </fieldset>
   <fieldset className="form-group col-md-1 mt-0 mt-0 test-sm">
    <FormikTextField name="tcspercent" className="form-control" label={values.tcspercent ? " " : "%"}
      type="number" onBlur={() => {setFieldValue("tcstotal",(values.tcsval * values.tcspercent) / 100);}}
      variant="standard"/></fieldset>
    <fieldset className="form-group col-md-2 mt-0 mt-0 test-sm" disabled>
      <NumericFormat name="tcstotal" className="form-control" value={(values.tcsval * values.tcspercent) / 100}
       label={values.tcspercent ? " " : "Value"} displayType={"input"} customInput={FormikTextField} decimalScale={2}
       fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
     </fieldset>
   
   </>:""}
</div>:""}</>:""}

 <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/></fieldset></div>

  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div></>:""}


<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="btn btn-info btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
)
}
}

export default withRouter(JournalPayment);              



 
