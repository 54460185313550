import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import AuthenticationService from '../../Authentication/AuthenticationService.jsx';
import {userid,currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
// import SubAccService from './SubAccService.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete, Backdrop, CircularProgress } from '@mui/material';
import SubHeadsService from './SubHeadsService.js';
import { Spinner } from 'react-bootstrap';
import { subheads } from './SubHeadDataTable.jsx';





class NewSubHead extends Component {
  constructor(props) {
    super(props)


     const {pagename} = this.props.location.state;
    this.state = {
      // type: 'password',
       pagename:pagename,
      id:this.props.location.state!==undefined?this.props.location.state.id:null,
      
     name:this.props.location.state!==undefined?this.props.location.state.name:null,
    error:'',
    subacc:'',
    sadata:[],
    // htype: [
    //   { id: 0, name: "Party-Personal Accounts" },
    //   { id: 1, name: "Asset/Liability - Real Accounts" },
    //   { id: 2, name: "Income and Expenses - Nominal Accounts" },
    // ],
    htypechecked:'',
    loader1:true
     

    }
    this.onSubmit = this.onSubmit.bind(this);
    //  this.handleChange=this.handleChange.bind(this)
    this.validate = this.validate.bind(this);
    this.onExit=this.onExit.bind(this)
  }

  onExit(){
    AuthenticationService.logout()
  }

  onSubmit(values) {
    
    let newSubHead = {
      name: values.name,
      code:values.code,
      tenantId:currentTenant ,
      createdBy:userid,
      sa:{id:values.subacc.id},
      // sa:values.subacc.id,
     rule:values.subacc.rule
    }
    let updateSubHead = {
      id:this.state.id,
      name: values.name,
      code:values.code,
      tenantId:currentTenant ,
      createdBy:userid,
      sa:{id:values.subacc.id},
      rule:values.subacc.rule
       }
      //  if(this.state.htypechecked===''){
      //   alert("Please Select a account type")
      // } else if(this.state.htypechecked!==''){
           if (this.state.id === null ||this.state.id === -1 ) {
            SubHeadsService.createSubHead(newSubHead)
              .then(() =>      
               this.props.navigate({pathname:`/subheads`},{state:{message:values.name + "   : Created Successfully"}}))
                .catch(error => this.setState({error:error.message + "   Account Name Already Exists"})) 
              }else {
                SubHeadsService.updateSubHead(updateSubHead)
             .then(() => 
               this.props.navigate({pathname:'/subheads'},{message:values.name + "   : Updated Successfully"}))
 // }   
  }
}
  
  componentDidMount() {
    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    // const {id} = this.props.location.state;
    SubHeadsService.retriveSubAccounts(currentTenant)
    .then(response => {
      this.setState({sadata:response.data},()=>this.setState({loader1:false}))
    }
      )
  
    
//  if (id < 1 || id!=='') {
// SubHeadsService.retriveSubAcc(id)
// .then(response => this.setState({
//   id:response.data.id,
//   name: response.data.name,
//   th:response.data.th.name
// }))
//  }
}


  validate(values) {
    let errors = {};
    var chars = /^[A-Za-z ]+$/
    let a = subheads.map((e)=>e.name)

    if (!values.name && values.name===undefined) {
      errors.name = "Account Name is required";
    }else if (!chars.test(values.name)) {
      errors.name = 'Only Characters are allowed';
    }else{
      if(a.find(e=>e.toUpperCase().trim()===values.name.toUpperCase().trim())){
        errors.name = 'Sub Head already exists !! Please use a different name ';
      }
    }
  
    

//     if(values!==undefined){
    
  
// }

    if(values.subacc===""||values.subacc===undefined){
      errors.subacc="Please Select Sub Account"
    }

    if(values.code===""||values.code===undefined){
      errors.code="Code is required"
    }
    
    return errors;

  }

  onTypeChange(option) {
    this.setState({ htypechecked: option.id });
  }

  render() {
    // let countrypre = [this.state.countryprefix]
    
     let {id,name,sadata} = this.state
     let subacc = this.props.subacc

    return (
    
      
      <div>
        <Header />
          <Menu/>
        <div className="content-wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="card">
            {/* <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/subheads"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
              <Link to={{pathname: '/subheads'}} state={{message:''}} >
                          Sub Heads
                        </Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
            
              <Formik
                initialValues={{id,name,subacc,sadata} }
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {({setFieldValue,values}) => (
                  <Form autoComplete="off">

                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id" onBlur={()=>this.state.pagename==="New SubHead"?setFieldValue("name",this.state.name):""} />
                      </fieldset>


                      <fieldset className="form-group col-md-4" >
                          <FormikTextField className="form-control" type="text" name="code" label="Code" />
                      </fieldset>
                      
                      <fieldset className="form-group col-md-4" >
                          <FormikTextField className="form-control" type="text" name="name" label="Head Name" />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subacc"
                          className="subacc-select"
                          name="subacc"
                          options={sadata!==undefined?sadata.sort((a, b) => -a.thname.localeCompare(b.thname)):"" }
                          groupBy={(option) => option.thname} 
                          getOptionLabel={(option) => option.name + " (" + option.code + ")"}
                          
                          value={values.subacc?undefined:undefined}
                          onChange={(e, value) => {
                            setFieldValue("subacc", value);
                          }}
                          renderInput={params => (  
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="subacc"
                             // onBlur={(e) =>{this.ChangeteState(e.target.value,values)}}
                              label="Select Sub Account"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      {/* <div className="form-row mt-3 mb-0">
                    <fieldset className="form-group col-md-9">
                      <FormLabel className="text-success">&nbsp;Head Type</FormLabel>
                      </fieldset>

                      <fieldset className="form-group col-md-12 text-sm">
                      {this.state.htype.map((option) => {
                        return (
                          <label key={option.id}>
                            <div className="form-group col-md-12 mt-0 mb-0">
                              <div className="radio-item mt-0 mb-0">
                                <input
                                  type="radio"
                                  checked={
                                    this.state.htypechecked === option.id
                                      ? true
                                      : false
                                  }
                                  key={option.id}
                                  onChange={this.onTypeChange.bind(this, option)}
                                  style={{ marginRight: 8 }}
                                  value={option.id}
                                />
                                {option.name}
                              </div>
                            </div>
                          </label>
                        );
                      })}
                    </fieldset>
                  </div> */}

                      {/* <fieldset className="form-group col-md-4">
                      <label><span className="text-sm">Top Heads<span className="text-danger float-sm-right">*</span></span></label>
                      <Field as="select" name="th" className="form-control" value={th}>
                      <option>Select Top Head...</option>
                      {this.state.sadata.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                      })}</Field>
                        <ErrorMessage name="th" component="div" className="text-danger float-sm-right" />
                      </fieldset> */}


                    </div>
                    {/* <div className="form-row">
                    <fieldset className="form-group col-md-12"/>
                      <fieldset className="form-group col-md-1">
                    <button className="btn btn-sm btn-success" type="submit">Save</button>
                    </fieldset>
                    <fieldset className="form-group col-md-1">
                    <Link as="button" type="submit" className="btn btn-sm btn-success" to={{pathname: '/subacc', state: {message:''}}}>Close</Link>
                    </fieldset>
                    </div> */}

                    <div className="form-group col-md-12"/>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    {/* <Link as="button" className="btn btn-sm deletebutton ml-1" to={{pathname: '/subheads'}} ><b style={{color:"#FA5733"}}> X</b> Cancel </Link> */}
                   
                    {/* <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/ledgers' ,state: {message:this.state.message}}}>Save</Link> */}
                    

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewSubHead)
