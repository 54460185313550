import React, { useEffect, useState } from 'react'
import Footer from '../../../Component/Footer'
import ProjectManagementServices from '../../ProjectManagementServices';
import { currentTenant, userid } from '../../../Common/Credentials';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import withRouter from '../../../withRouter';
import { Form, Formik } from 'formik';
import { FormikTextField } from '../../../CommonComponents/FormField';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import UserService from '../../../users/UserService';

const AddProjectManager = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loader,setLoader] = useState(false)
    const [projectId,setProjectId] = useState("")
    const [project,setProject] = useState("")
    const [userList, setUserList] = useState([]);
    const [projectManagerId, setProjectManagerId] = useState("");
    const [projectManagerName, setProjectManagerName] = useState("");
    const [projectManagerContactNo, setProjectManagerContactNo] = useState("");
    const [projectManagerEmail, setProjectManagerEmail] = useState("");
    const [remark,setRemark] = useState("")

    useEffect(()=>{
        setProjectId(location.state.projectId)
        setProject(location.state.projectName +" ("+location.state.projectCode+")")
        UserService.retriveAllUsers(currentTenant).then((response) =>
        setUserList(response.data)
      );
    },[])

    const handleProjectManagerChange = (value, values) => {
      if (values !== "" && typeof values === "object" && values !== null) {
        setProjectManagerId(values.id);
        setProjectManagerName(values.first + " " + values.last);
        setProjectManagerContactNo(values.contactnumber);
        setProjectManagerEmail(values.email);
      } else {
        setProjectManagerId();
        setProjectManagerName();
        setProjectManagerContactNo();
        setProjectManagerEmail();
      }
    };

    const onSubmit = () => {
        setLoader(true)
        let data = {
            projectId: projectId,
            managerId: projectManagerId,
            managerName: projectManagerName,
            managerContactNo: projectManagerContactNo,
            managerEmail: projectManagerEmail,
            tenantId: currentTenant,
            remark: remark,
            createdby: userid,
            updatedby: userid,
        }
        ProjectManagementServices.AddProjectManager(data)
        .then(()=>navigate(-1))
        .catch(()=>setLoader(false))
    }

  return (
    <div>
         <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to={-1}>Project details</Link>
            </li>
            <li className="breadcrumb-item active">Add Project Manager</li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                      <div>
                      <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    // validate={validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className='row'>
                        <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="project"
                              label="Project "
                              value={project}
                              disabled
                            //   onChange={(e) => setSiteName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12 m-0 p-0"/>
                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="projectManagerName"
                              options={userList}
                              getOptionLabel={(option) =>
                                option.first + " " + option.last
                              }
                              value={values.customer ? undefined : undefined}
                              onChange={handleProjectManagerChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="projectManagerName"
                                  value={projectManagerName}
                                  label="Project Manager"
                                      
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectManagerContactNo"
                              label="Project Manager Contact No."
                              value={projectManagerContactNo}
                              onChange={(e) =>
                                setProjectManagerContactNo(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectManagerEmail"
                              label="Project Manager Email"
                              value={projectManagerEmail}
                              onChange={(e) =>
                                setProjectManagerEmail(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField
                              name="description"
                              className="form-control"
                              multiline
                              rows={2}
                              label="Remarks"
                              variant="outlined"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                          </fieldset>
                            <button className="btn btn-sm hovbuttonColour mr-2" disabled = {loader}>
                              {loader? <Spinner animation="border" size="sm" className="mr-1"/>:""}
                                        Submit
                            </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={-1}
                          >
                            <b className="text-danger"> X </b>Cancel
                          </Link>
                        </Form>
                    )}
                    </Formik>
                  </div>
                </div>
               </div>
            </div>
          </section>
         </div>
        </div>
      <Footer/>  
    </div>
  )
}

export default withRouter(AddProjectManager)