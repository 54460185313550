import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials.js";
import GRNServices from "./GRNServices";
import moment from "moment";
import { spin } from "../../Common/NewLoader.jsx";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Settings from "../../Settings/Settings.jsx";
import SettingsServices from "../../Settings/SettingsServices.js";
import { purchaseid } from "../../Common/Permissions.js";

class ViewGRNItems extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    this.state = {
      grnitems: "",
      grnitemslist: [],
      id: id,
      message: "",
      loading: false,
      cancellationdescription: "",
      showCancelModal: false,
      status: "",
      customdate: false,
      date: new Date(),
      autojre: "",
    };
    // this.handleConfirm = this.handleConfirm.bind(this);
  }

  getGRNItems(id, temp) {
    // const { id } = this.props.location.state;
    if (temp) {
      GRNServices.retriveTempGRNItems(id)
        .then((response) => {
          this.setState({
            grnitems: response.data,
            grnitemslist: response.data.tempgrnitemslist,
          });
          this.setState({ loading: false });
        })
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    } else {
      GRNServices.retriveGRNItems(id)
        .then((response) => {
          this.setState({
            grnitems: response.data,
            grnitemslist: response.data.grnitemslist,
          });
          this.setState({ loading: false });
        })
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  }

  handleDelete = () => {
    Swal.fire({
      title: "Delete GRN?",
      text: "Are you sure you?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        GRNServices.DeleteGRN(this.state.id)
          .then((response) => {
            Swal.fire({
              title: "Deleted Successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.props.navigate("/grn"));
          })
          .catch((e) =>
            Swal.fire({
              title: `Server Error, please try again later`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          );
      }
    });
  };
  AutoJRE = () => {
    GRNServices.PurchaseAutoJRE(this.props.location.state.id).then(() =>
      this.getGRNItems(this.props.location.state.id, false)
    );
  };
  
  CancelGRN = ()=>{
    let data = {
      id: this.state.id,
      status: 2,
      user: userid,
      canceldesc: this.state.cancellationdescription,
      customdate: this.state.customdate,
      date: this.state.date,
    };
    Swal.fire({
      title:"Cancel GNR",
      text: "Are you sure you?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        GRNServices.CancelGRN(data)
          .then((response) => {
            Swal.fire({
              title: "Cancelled Successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.props.navigate("/grn")
            );
          })
          .catch((e) =>
            Swal.fire({
              title: `Server Error, please try again later`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          );
      }
    });
  }

  handleStatus = (status) => {
    if (
      (status === 2 || status === 1) &&
      this.state.cancellationdescription === ""
    ) {
      this.setState({ showCancelModal: true, status: status });
    } else {
      this.setState({ showCancelModal: false });
      let data = {
        id: this.state.id,
        status: status,
        user: userid,
        canceldesc: this.state.cancellationdescription,
        customdate: this.state.customdate,
        date: this.state.date,
      };
      Swal.fire({
        title:
          status === 0
            ? "Approved GRN"
            : status === 1
            ? "Send GRN for Revise"
            : "Delete GNR",
        text: "Are you sure you?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          GRNServices.Status(data)
            .then((response) => {
              Swal.fire({
                title:
                  status === 0
                    ? "Approved Successfully"
                    : status === 1
                    ? "Sent for Revise"
                    : "Cancelled Successfully",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() =>
                status !== 2
                  ? this.getGRNItems(response.data, status === 0 ? false : true)
                  : this.props.navigate(-1)
              );
            })
            .catch((e) =>
              Swal.fire({
                title: `Server Error, please try again later`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            );
        }
      });
    }
  };

  handlecreatedAtDateChange = (date) => {
    this.setState({ date: date });
    this.setState({
      fed: moment(date).format("yyyy-MM-DD HH:mm:ss"),
    });
  };

  handlecustomDate = () => {
    this.setState({
      customdate: this.state.customdate === true ? false : true,
      date: new Date(),
    });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.getGRNItems(
      this.props.location.state.id,
      this.props.location.state.temp
    );
    SettingsServices.GetSettings(currentTenant).then((res) =>
      this.setState({ autojre: res.data.purchaseautojre })
    );
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <span
                className="text-secondary mr-2 cursorpointer"
                onClick={() =>
                  this.props.location.state.goback === undefined
                    ? this.props.navigate(-1)
                    : this.props.navigate("/grn")
                }
              >
                ❮ Back
              </span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/grn">GRN</Link>
              </li>
              <li className="breadcrumb-item active">
                {" "}
                GRN Items / Accepted Goods
              </li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">GRN Items</h3>
                      </div>
                      <div className="card-body">
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <>
                            <table className="float-left mb-3">
                              <tbody>
                                <tr>
                                  <td>GRN ID</td>
                                  <td>
                                    : &nbsp; {this.state.grnitems.customgrnid}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Supplier</td>
                                  <td>
                                    :&nbsp;&nbsp;{this.state.grnitems.supplier}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Purchase Order &nbsp;</td>
                                  <td>
                                    : &nbsp;{" "}
                                    <Link
                                      as="button"
                                      to={{ pathname: "/viewpurchaseorder" }}
                                      state={{ id: this.state.grnitems.poid }}
                                    >
                                      {this.state.grnitems.custompoid}
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Created by</td>
                                  <td>
                                    : &nbsp; {this.state.grnitems.createdbyname}
                                  </td>
                                </tr>
                                {this.props.location.state.temp ? (
                                  ""
                                ) : (
                                  <>
                                    <tr>
                                      <td>Ledger Status</td>
                                      <td>
                                        : &nbsp;{" "}
                                        {this.state.grnitems.ledgerentry}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Payment Status</td>
                                      <td>
                                        : &nbsp; {this.state.grnitems.payment}
                                      </td>
                                    </tr>
                                  </>
                                )}

                                <tr>
                                  <td>Including Services</td>
                                  <td>
                                    : &nbsp;{" "}
                                    {this.state.grnitems.includingservices}
                                  </td>
                                </tr>
                                {this.state.grnitems.agent === null ||
                                this.state.grnitems.agent === undefined ||
                                this.state.grnitems.agent === "" ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Agent</td>
                                    <td data-tip data-for="agentphno">
                                      :&nbsp;&nbsp;{this.state.grnitems.agent}
                                    </td>
                                    {/* //<Tooltip id="agentphno" place="top" effect="solid">Agent Ph. {this.state.grnitems.agentphno}</Tooltip> */}
                                  </tr>
                                )}
                                <tr>
                                  <td>Total Price</td>
                                  <td>
                                    : &nbsp;
                                    <NumericFormat
                                      className="pr-3"
                                      displayType={"text"}
                                      value={this.state.grnitems.totalprice}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      disabled={true}
                                      thousandSeparator={true}
                                      thousandsGroupStyle={thousandsdisplay}
                                    ></NumericFormat>
                                  </td>
                                </tr>
                                {this.state.grnitems.advanceadjusted ===
                                  undefined ||
                                this.state.grnitems.advanceadjusted === null ||
                                this.state.grnitems.advanceadjusted === 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Advance Adjusted</td>
                                    <td>
                                      : &nbsp;
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={
                                          this.state.grnitems.advanceadjusted
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                )}
                                {this.state.grnitems.openadjusted ===
                                  undefined ||
                                this.state.grnitems.openadjusted === null ||
                                this.state.grnitems.openadjusted === 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Opening Balance Adjusted</td>
                                    <td>
                                      : &nbsp;
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={this.state.grnitems.openadjusted}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                )}
                                {this.state.grnitems.creditadjusted ===
                                  undefined ||
                                this.state.grnitems.creditadjusted === null ||
                                this.state.grnitems.creditadjusted === 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Credit Adjusted</td>
                                    <td>
                                      : &nbsp;
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={
                                          this.state.grnitems.creditadjusted
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                )}
                                {this.props.location.state.temp ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Balance</td>
                                    <td>
                                      : &nbsp;
                                      <NumericFormat
                                        className="pr-3 text-danger"
                                        displayType={"text"}
                                        value={this.state.grnitems.balance}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Date</td>
                                  <td>
                                    : &nbsp;{" "}
                                    {moment(
                                      this.state.grnitems.createdAt
                                    ).format("DD-MM-yyyy")}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <Table className="table-sm border">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="text-center">
                                    Order Id
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Item Name
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Price
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Weighted Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Accepted Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Difference Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Returned Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Total Price
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Tax(%)
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Total Price
                                  </TableCell>
                                  {this.state.grnitems.advanceadjusted ===
                                    undefined ||
                                  this.state.grnitems.advanceadjusted ===
                                    null ||
                                  this.state.grnitems.advanceadjusted === 0 ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Advance Adjusted
                                    </TableCell>
                                  )}
                                  {this.state.grnitems.openadjusted ===
                                    undefined ||
                                  this.state.grnitems.openadjusted === null ||
                                  this.state.grnitems.openadjusted === 0 ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Opening Adjusted
                                    </TableCell>
                                  )}
                                  {this.state.grnitems.creditadjusted ===
                                    undefined ||
                                  this.state.grnitems.creditadjusted === null ||
                                  this.state.grnitems.creditadjusted === 0 ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Credit Adjusted
                                    </TableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.grnitemslist.map((data, index) => (
                                  <TableRow key={index}>
                                    <TableCell className="text-center">
                                      {" "}
                                      <Link
                                        as="button"
                                        to={{ pathname: "/viewpurchaseorder" }}
                                        state={{
                                          id:
                                            data.poid === null ||
                                            data.poid === undefined ||
                                            data.poid === ""
                                              ? this.state.grnitems.custompoid
                                              : data.poid,
                                        }}
                                      >
                                        {data.poid === null ||
                                        data.poid === undefined ||
                                        data.poid === ""
                                          ? this.state.grnitems.custompoid
                                          : data.poid}
                                      </Link>
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {data.name}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={data.price}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {data.quantity}
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {data.weightedquantity}
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {data.accepted}
                                    </TableCell>
                                    <TableCell
                                      className={`text-center  ${
                                        data.weightedquantity - data.quantity >
                                        0
                                          ? "text-green"
                                          : data.weightedquantity -
                                              data.quantity <
                                            0
                                          ? "text-red"
                                          : ""
                                      } `}
                                    >
                                      {(
                                        data.weightedquantity - data.quantity
                                      ).toFixed(2)}
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {data.returnquantity}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={data.totalprice}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-1"
                                        displayType={"text"}
                                        value={data.taxamount}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                      ({data.tax}%)
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={data.totalpricewtax}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    {this.state.grnitems.advanceadjusted ===
                                      undefined ||
                                    this.state.grnitems.advanceadjusted ===
                                      null ||
                                    this.state.grnitems.advanceadjusted ===
                                      0 ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={data.advanceadjusted}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                    )}
                                    {this.state.grnitems.openadjusted ===
                                      undefined ||
                                    this.state.grnitems.openadjusted === null ||
                                    this.state.grnitems.openadjusted === 0 ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={data.openadjusted}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                    )}
                                    {this.state.grnitems.creditadjusted ===
                                      undefined ||
                                    this.state.grnitems.creditadjusted ===
                                      null ||
                                    this.state.grnitems.creditadjusted === 0 ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={data.creditadjusted}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        )}
                        {this.state.grnitems.status === "Pending" ? (
                          <div>
                            <fieldset className="form-group col-md-3">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.customdate}
                                      onChange={this.handlecustomDate}
                                      name="ordertype"
                                      color="primary"
                                    />
                                  }
                                  label="Custom date?"
                                />
                              </FormGroup>
                            </fieldset>
                            {this.state.customdate ? (
                              <fieldset className="form-group col-md-3">
                                {/* <div className="form-row mr-4 mb-4"> */}
                                {/* <fieldset style={{ marginLeft: "30px" }}> */}
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Grid justifyContent="space-around">
                                    <DateTimePicker
                                      disableFuture
                                      showtodaybutton
                                      showcancelbutton
                                      //  views={["year", "month","date"]}
                                      variant="inline"
                                      id="date-picker-dialog"
                                      // label="Financial Year Start Date"
                                      // inputVariant="outlined"
                                      minDate={
                                        new Date(
                                          moment(financialyearstart).format(
                                            "YYYY-MM-DD"
                                          )
                                        )
                                      }
                                      maxDate={
                                        new Date(
                                          moment(financialyearend).format(
                                            "YYYY-MM-DD"
                                          )
                                        )
                                      }
                                      label={`Custom Date : ${
                                        this.state.customdate
                                          ? "Custom"
                                          : "Default"
                                      }`}
                                      format="dd/MM/yyyy HH:mm"
                                      value={this.state.date}
                                      InputAdornmentProps={{
                                        position: "start",
                                      }}
                                      onChange={this.handlecreatedAtDateChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                        />
                                      )}
                                      autoOk={true}
                                      fullWidth
                                      disabled={this.state.customdate === false}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                              </fieldset>
                            ) : (
                              ""
                            )}
                            </div>):""}



                            {this.state.grnitems.status === "Pending" ? (
                          <div>
                            {purchaseid === 3 || purchaseid === "3" ? (
                              <button
                                className="btn btn-sm hovbuttonColour mr-1 mt-3"
                                onClick={() => this.handleStatus(0)}
                              >
                                <i className="fa fa-check  m-0" /> Approve
                              </button>
                            ) : (
                              ""
                            )}

                            <button
                              className="btn btn-sm btn-warning mr-1 mt-3"
                              onClick={() => this.handleStatus(1)}
                            >
                              <i className="fa fa-rotate-right m-0" /> Revise
                            </button>
                            <button
                              className="btn btn-sm deletebutton mt-3"
                              onClick={() => this.handleStatus(2)}
                            >
                              <i className="fa fa-times  m-0" /> Delete
                            </button>
                          </div>
                        ) : this.state.grnitems.status === "Revise" ? (
                          <>
                            <Link
                              as="button"
                              data-tip
                              data-for="view details"
                              className="btn btn-sm hovbuttonColour mr-1 mt-3"
                              style={{ paddingRight: "5px" }}
                              to={{ pathname: "/newgrn" }}
                              state={{
                                id: this.state.id,
                                pagename: "Edit Accepted Goods",
                              }}
                            >
                              <i className="fa fa-edit"></i> Edit GRN
                            </Link>
                            <button
                              className="btn btn-sm deletebutton mt-3"
                              onClick={() => this.handleDelete()}
                            >
                              <i className="fa fa-times m-0" /> Delete
                            </button>
                          </>
                        ) : <>
                        {this.state.grnitems.autojre === "TRUE" &&
                          this.state.autojre === "YES" &&
                          this.state.grnitems.status === "Approved" &&
                          this.state.grnitems.ledgerentry === "Pending" ? 
                          <button
                            className="btn btn-sm hovbuttonColour float-center mr-2 mt-2"
                            onClick={() => this.AutoJRE()}
                          >
                            Journal Entry
                          </button>:""}
                          {this.state.grnitems.status === "Approved" &&
                          this.state.grnitems.payment === "Pending" ?
                          <button
                              className="btn btn-sm deletebutton mt-2"
                              onClick={() => this.CancelGRN()}
                            >
                              <i className="fa fa-times  m-0" /> Cancel GRN
                            </button>
                           :""}
                          </> }
                      </div>
                      {this.state.grnitems.canceldesc !== null &&
                      this.state.grnitems.canceldesc !== undefined &&
                      this.state.grnitems.canceldesc !== "" ? (
                        <fieldset className="form-group col-md-12">
                          <label className="text-red">
                            {this.state.grnitems.status === "Revise"
                              ? "Note"
                              : "Cancellation Description"}{" "}
                            : {this.state.grnitems.canceldesc}
                          </label>
                        </fieldset>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* Order Cancllation Modal */}
                    <Modal
                      show={this.state.showCancelModal}
                      onHide={() =>
                        this.setState({
                          showCancelModal: false,
                          cancellationdescription: "",
                        })
                      }
                      backdrop="static"
                      keyboard={false}
                      centered
                      size="lg"
                    >
                      <Modal.Header closeButton>
                        {this.state.status === 2
                          ? "Reason For Cancellation"
                          : "Reason for Revise"}
                      </Modal.Header>
                      <div className="form-row mt-3 ml-3">
                        <fieldset className="form-group col-md-8">
                          <TextField
                            name="cancellationdescription"
                            className="form-control"
                            multiline
                            rows={2}
                            label={
                              this.state.status === 2
                                ? "Reason For Cancellation"
                                : "Reason for Revise"
                            }
                            variant="outlined"
                            value={this.state.cancellationdescription}
                            onChange={(e) =>
                              this.setState({
                                cancellationdescription: e.target.value,
                              })
                            }
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-12" />
                        <fieldset className="form-group col-md-12" />
                        <button
                          className="btn btn-sm hovbuttonColour float-center ml-2"
                          onClick={() => this.handleStatus(this.state.status)}
                        >
                          <i className="fas fa-save mr-1" />
                          {"Submit"}
                        </button>
                      </div>
                      <Modal.Body> </Modal.Body>
                    </Modal>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ViewGRNItems);
