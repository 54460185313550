import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  cstate,
  currentTenant,
  thousandsdisplay,
  userid,
} from "../Common/Credentials";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import CustomerService from "../Masters/Customers/CustomerService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AssetsService from "./AssetsService";
import Swal from "sweetalert2";
import { Autocomplete } from "@mui/material";
import { Form, Formik } from "formik";
import { spin } from "../Common/NewLoader";
import moment from "moment";
import InventoryService from "../inventory/InventoryService";
import {NumericFormat} from "react-number-format";
import { useNavigate } from "react-router-dom";
import  withRouter from "../withRouter.js";

const RentAsset = (props) => {
  const history = useLocation();
  const [pagename] = useState(
    props.location.state === undefined ? "" : props.location.state.pagename
  );
  const [id] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? ""
      : props.location.state.data.id
  );
  const [assettype,setAssetType] = useState("own")
  const [assetid, setAssetId] = useState("");
  const [itemname, setitemname] = useState("");
  const [itemid, setitemid] = useState("");
  const [serialno, setSerialNo] = useState("");
  const [serialnolist, setSerialNolist] = useState([]);
  const [items, setItems] = useState([]);
  const [uom,setUom] = useState('')
  const [hsn,setHsn] = useState('')
  const [assetidList, setAssetIdlist] = useState([]);
  const [thirdpartyassetidList, setThirdpartyAssetIdlist] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showmodel, setShowmodel]= useState(false);
  const [customer, setCustomer] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? ""
      : props.location.state.data.customer
  );
  const [custid, setCustid] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? ""
      : props.location.state.data.custid
  );
  const [depreciation,setDepreciation] = useState(0)
  const [amount, setAmount] = useState("");
  const [amountinctax, setAmountInctax] = useState("");
  const [period, setPeriod] = useState(
    props.location.state === undefined || props.location.state.data === ""
    ? ""
    : props.location.state.pagename === "Edit Rental Quotation"
    ? props.location.state.data.rentassetsitemsquotation[0].period
    : ""
  );
  const [advance, setAdvance] = useState(0);
  const [startdate, setStartdate] = useState(new Date());
  const [loading, setLoding] = useState(false);
  const [list, setList] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? []
      : props.location.state.pagename === "Edit Rental Quotation"
      ? props.location.state.data.rentassetsitemsquotation
      : props.location.state.pagename === "Edit Sales Quotation"
      ? props.location.state.data.soldassetsitemsquotation
      : []
  );
  const [types] = useState(["Daily", "Weekly", "Monthly", "Yearly"]);
  const [ptypes] = useState(["Day", "Week", "Month", "Year"]);
  const [periodtype, setPeriodType] = useState(
  props.location.state === undefined || props.location.state.data === ""
      ? "Month"
      : props.location.state.pagename === "Edit Rental Quotation"
      ? props.location.state.data.rentassetsitemsquotation[0].periodtype
      : "Month"
  );
  const [amounttype, setAmountType] = useState("Monthly");
  const [type, setType] = useState("New");
  const [custcountry,setCustCountry] = useState('')

  const [error,setError] = useState(false)
  // const [Perioddays] = useState([
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //   21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  // ]);
  // const [Periodmonths] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  // const [Periodyears] = useState([
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //   21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  //   40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  // ]);
  // const [perioddays, setPerioddays] = useState(0);
  // const [periodmonths, setPeriodmonths] = useState(0);
  // const [periodyears, setPeriodyears] = useState(0);
  const [taxrequired, setTaxrequired] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? true
      : props.location.state.data.taxrequired
  );
  const [gsttype, setgsttype] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? "INTRASTATE"
      : props.location.state.data.gsttype
  );
  const [tax, setTax] = useState(0);
  const [taxtype, setTaxtype] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? "GST"
      : props.location.state.data.taxtype
  );
  const [totalamount, setTotalamount] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? 0
      : props.location.state.data.totalamount
  );
  const [totalamountinctax, setTotalamountinctax] = useState(
    props.location.state === undefined || props.location.state.data === ""
      ? 0
      : props.location.state.data.totalamountinctax
  );
  const [quotation, setQuotation] = useState("");
  const [rentalquotationlist, setRentelQuotationList] = useState([]);
  const [soldquotationlist, setSoldQuotationList] = useState([]);
  const [thirdpartitems,setThirdpartItems] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    setLoding(true);
    CustomerService.retriveAllCustomers(currentTenant)
      .then((response) => {
        setCustomers(response.data);
        setLoding(false);
      })
      .catch((e) => {
        if (e.response !== undefined) {
          if (e.response.data.error === "Unauthorized") {
            Swal.fire({
              title: "Your Session is Lost",
              text: "Please login Back to resume",
              icon: "error",
              confirmButtonText: "Yes",
              confirmButtonColor: "#152f5f",
            }).then(() => {
              navigate("/");
            });
          }
        }
      });
    AssetsService.GetAssetsidforRentandSold(currentTenant).then((response) => {
      let rdata = response.data;
      let res = [];
      if (props.location.state === undefined || props.location.state === "") {
        setAssetIdlist(rdata)
      } else {
        if (
          props.location.state.pagename === "Edit Rental Quotation" ||
          props.location.state.pagename === "Edit Sales Quotation"
        ) {
          let prdata;
          if (props.location.state.pagename === "Edit Rental Quotation") {
            prdata = props.location.state.data.rentassetsitemsquotation.map(
              (e) => e.assetid
            );
          } else {
            prdata = props.location.state.data.soldassetsitemsquotation.map(
              (e) => e.assetid
            );
          }

          rdata.map((e) => (prdata.includes(e) ? "" :  res.push(e)));
          setAssetIdlist(res);
        } else {
          setAssetIdlist(rdata);
        }
      }
    });

    AssetsService.GetThirdpartyassetsforRental(currentTenant).then((response)=>{
        setThirdpartyAssetIdlist(response.data)
    })

    AssetsService.getAllRentalQuotationId(currentTenant).then((res) => {
      setRentelQuotationList(res.data);
    });
    AssetsService.getAllSoldQuotationId(currentTenant).then((res) => {
      setSoldQuotationList(res.data);
    });

    InventoryService.retriveAllItems(currentTenant).then((response) =>
      setItems(response.data)
    );
  }, [history,props.location.state]);

  const handleAssetChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setAssetId("");
      setitemid("");
      setitemname("");
      setSerialNo("");
    } else {
      setAssetId(newValue);

      if(assettype==="own"){
      AssetsService.GetAssetById(newValue).then((response) => {
        // setAssetDetails(response.data)
        setitemid(response.data.itemid);
        setitemname(response.data.itemname);
        setSerialNo(response.data.serialno);
        setUom(response.data.uom)
        setHsn(response.data.hsn)
        setTax(
          taxrequired === true
            ? items.find((e) => e.id === response.data.itemid).tax
            : 0
        );
      });
    }else{
      AssetsService.GetThirdpartyAssetById(newValue).then((response) => {
        setitemid(response.data.itemid);
        setitemname(response.data.itemname);
        setThirdpartItems(response.data.thirdpartyassetitems.filter(e=>e.assetstatus==="Active"));
        setUom(response.data.uom)
        setHsn(response.data.hsn)
        setTax(
          taxrequired === true
            ? items.find((e) => e.id === response.data.itemid).tax
            : 0
        );
        setShowmodel(true)
      });
    }
    }
  };

  const handleQuotationChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setQuotation("");
    } else {
      setQuotation(newValue);
    }

    if (pagename === "New Rent Asset") {
      AssetsService.getRentalQuotationbyId(newValue).then((res) => {
        setList(res.data.rentassetsitemsquotation);
        setCustomer(res.data.customer);
        setCustid(res.data.custid);
        setTotalamount(res.data.totalamount);
        setTotalamountinctax(res.data.totalamountinctax);
        setAdvance(res.data.advance);
        setTaxrequired(res.data.taxrequired);
        setTaxtype(res.data.taxtype);
        setgsttype(res.data.gsttype);
      });
    } else {
      AssetsService.getSoldQuotationbyId(newValue).then((res) => {
        setList(res.data.soldassetsitemsquotation);
        setCustomer(res.data.customer);
        setCustid(res.data.custid);
        setTotalamount(res.data.totalamount);
        setTotalamountinctax(res.data.totalamountinctax);
        setAdvance(res.data.advance);
        setTaxrequired(res.data.taxrequired);
        setTaxtype(res.data.taxtype);
        setgsttype(res.data.gsttype);
      });
    }
  };

  const handlecustomerChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setCustid("");
      setCustomer("");
    } else {
      setCustid(newValue.id);
      setCustomer(newValue.companyname);
      setCustCountry(newValue.countryname)
      setTaxtype(newValue.taxtype)
      setgsttype(newValue.statename=== cstate && newValue.countryname==="India"?"INTRASTATE":"INTERSTATE")
    }
  };

  const handleamountChange = (e) => {
    setAmount(e.target.value);
    if (taxrequired === false) {
      setAmountInctax(e.target.value);
    } else {
      setAmountInctax(
        parseFloat(e.target.value) + (parseFloat(e.target.value) * tax) / 100
      );
    }
  };
  const handleAmountTypeChange = (e) => {
    setAmountType(e.target.value);
  };
  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };
  const handlePeriodTypeChange = (e) => {
    setPeriodType(e.target.value);
  };

  const handleadvanceChange = (e) => {
    if(e.target.value>totalamountinctax * period){
      setAdvance(totalamountinctax * period)
    }else{
      setAdvance(e.target.value);
    }
  };

  const handleStartDateChange = (date) => {
    setStartdate(date);
  };

  // const handlePeriodYearsChange = (e) => {
  //   setPeriodyears(e.target.value);
  // };
  // const handlePeriodMonthsChange = (e) => {
  //   setPeriodmonths(e.target.value);
  // };
  // const handlePeriodDaysChange = (e) => {
  //   setPerioddays(e.target.value);
  // };


  const Addserialno = (sno)=>{
    let sllist = serialnolist
    sllist.push(sno)
    setSerialNolist([...sllist])
  }
  const Removeserialno = (sno)=>{
    let sllist = serialnolist.filter(e=>e.serialno === sno)
    setSerialNolist([...sllist])
  }


  const Addtolist = () => {
    if (pagename === "") {
      navigate({pathname:"/asset"},{state:{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
    } else {
      if (customer === "" || custid === "" || assetid === "" || amount === "" || (serialnolist.length === 0 && assettype === "thirdparty")) {
        setError(true)
      } else {
        let data;
        setTotalamount(totalamount + parseFloat(amount));
        setTotalamountinctax(totalamountinctax + parseFloat(amountinctax));
        if (
          pagename === "New Rent Asset" ||
          pagename === "New Rental Quotation" ||
          pagename === "Edit Rental Quotation" 
        ) {
          if (period === '') {
          } else {
            if(assettype==="own"){
            data = {
              customer: customer,
              custid: custid,
              amount: parseFloat(amount),
              amountinctax: parseFloat(amountinctax),
              depreciation:!depreciation || depreciation===""?0:parseFloat(depreciation),
              periodtype: periodtype,
              amounttype: amounttype,
              period: parseFloat(period),
              tax: tax,
              startdate: startdate,
              createdby: userid,
              updatedby: userid,
              assetid: assetid,
              itemid: itemid,
              itemname: itemname,
              serialno: serialno,
              tenantid: currentTenant,
              uom:uom,
              hsn:hsn,
            };
            list.push(data)
          }else{
            serialnolist.map(e=>{
              data = {
                customer: customer,
                custid: custid,
                amount: parseFloat(amount),
                amountinctax: parseFloat(amountinctax),
                periodtype: periodtype,
                amounttype: amounttype,
                period: parseFloat(period),
                tax: tax,
                startdate: startdate,
                createdby: userid,
                updatedby: userid,
                assetid: assetid,
                itemid: itemid,
                itemname: itemname,
                serialno: e,
                tenantid: currentTenant,
                uom:uom,
                hsn:hsn,
                assettype:assettype,
              };
              list.push(data)
            })
          }
          }
        } else {
          data = {
            itemid: itemid,
            itemname: itemname,
            serialno: serialno,
            amount: parseFloat(amount),
            amountinctax: parseFloat(amountinctax),
            tax: taxrequired === true ? tax : 0,
            createdby: userid,
            updatedby: userid,
            assetid: assetid,
            tenantid: currentTenant,
            uom:uom,
            hsn:hsn,
            assettype:assettype,
          };
          list.push(data)
        }
        console.log(data)

        
        setAssetIdlist(assetidList.filter((e) => e !== assetid));
        setThirdpartyAssetIdlist(thirdpartyassetidList.filter((e) => e !== assetid))
        setAssetId("");
        setitemid("");
        setitemname("");
        setSerialNo("");
        setAmount("");
        setStartdate(new Date());
        setTax(0)
        setAmountInctax("");
        setError(false)
        setUom('')
        setHsn('')
        setSerialNolist([])
        setThirdpartItems([])
      }
    }
  };
  const RemoveFromList = (i) => {
    let assetid1 = list.find((e, index) => index === i);
    setTotalamount(totalamount - assetid1.amount);
    setTotalamountinctax(totalamountinctax - assetid1.amountinctax);
    assetidList.push(assetid1.assetid);
    setList(list.filter((e, index) => index !== i));
  };

  const onsubmit = () => {
    if (
      pagename === "New Rent Asset" ||
      pagename === "New Rental Quotation" ||
      pagename === "Edit Rental Quotation"
    ) {
      let data = {
        id: id,
        rentassetitems: list,
        customer: customer,
        custid: custid,
        tenantid: currentTenant,
        createdby: userid,
        updatedby: userid,
        quotation: quotation === "" ? null : quotation,
        totalamount: totalamount,
        totalamountinctax: totalamountinctax,
        advance: advance === "" ? 0 : advance,
        taxrequired: taxrequired,
        taxtype: taxrequired === true ? taxtype : "NA",
        gsttype: taxrequired === true && taxtype === "GST" && custcountry === "India" && gsttype === "INTRASTATE"?0:1,
      };
      if (pagename === "New Rental Quotation") {
        AssetsService.NewRentalQuotation(data).then(() => {
          Swal.fire({
            title: "New Rental Quotation",
            text: "Saved Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => {
            navigate({pathname:"/asset"},{state:{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
          });
        });
      } else if (pagename === "New Rent Asset") {
        AssetsService.NewRentAssets(data).then(() => {
          Swal.fire({
            title: "New Rental Assets",
            text: "Saved Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => {
            navigate({pathname:"/asset"},{state:{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
          });
        });
      } else {
        AssetsService.EditRentalQuotation(data).then(() => {
          Swal.fire({
            title: "Rental Quotation",
            text: "Edited Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => {
            navigate({pathname:"/asset" },{state:{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
          });
        });
      }
    } else {
      let data = {
        id: id,
        soldassetsitems: list,
        customer: customer,
        custid: custid,
        tenantid: currentTenant,
        createdby: userid,
        updatedby: userid,
        quotation: quotation === "" ? null : quotation,
        totalamount: totalamount,
        totalamountinctax: totalamountinctax,
        advance: advance === "" ? 0 : advance,
        taxrequired: taxrequired,
        taxtype: taxrequired === true ? taxtype : "NA",
        gsttype: taxrequired === true && taxtype === "GST" && custcountry === "India" && gsttype === "INTRASTATE"?0:1,
      };

      if (pagename === "New Sales Asset") {
        AssetsService.NewSoldAssets(data).then(() => {
          Swal.fire({
            title: "New Sales Assets",
            text: "Saved Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => {
            navigate({pathname:"/asset"},{state:{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
          });
        });
      } else if (pagename === "New Sales Quotation") {
        AssetsService.NewSalesQuotation(data).then(() => {
          Swal.fire({
            title: "New Sales Quotation",
            text: "Saved Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => {
            navigate({pathname:"/asset"},{state :{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
          });
        });
      } else {
        AssetsService.EditSalesQuotation(data).then(() => {
          Swal.fire({
            title: "Sales Quotation",
            text: "Edited Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => {
            navigate({pathname:"/asset"},{state:{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}});
          });
        });
      }
    }
  };

  const handletaxrequired = () => {
    setTaxrequired(!taxrequired);
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{pathname:"/asset"}} state ={{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to={{pathname:"/asset"}} state ={{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}}>Assets</Link>
            </li>
            <li className="breadcrumb-item active">{pagename}</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {loading ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">{pagename}</h3>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{}}
                          //   onSubmit={onSubmit}
                          validateonChange={false}
                          //   validate={validate}
                          enableReinitialize={true}
                        >
                          {({ setFieldValue, values }) => (
                            <Form
                              autoComplete="off"
                              style={{ paddingLeft: "30px" }}
                            >
                              <div className="form-row">
                                {pagename === "New Rental Quotation" ||
                                pagename === "New Sales Quotation" ||
                                pagename === "Edit Rental Quotation" ||
                                pagename === "Edit Sales Quotation" ? (
                                  ""
                                ) : (
                                  <fieldset className="form-group col-md-12">
                                    <fieldset
                                      className={`form-group col-md-3  shadow-sm text-center btn ${
                                        type === "New"
                                          ? "borderedbutton2"
                                          : " hovbuttonColour"
                                      }`}
                                      disabled={type==="New"}
                                      onClick={() => setType("New")}
                                    >
                                      Create New
                                    </fieldset>
                                    <fieldset
                                      className={`form-group col-md-3 shadow-sm btn text-center ${
                                        type === "Quotation"
                                          ? "borderedbutton2"
                                          : " hovbuttonColour"
                                      }`}
                                      disabled={type==="Quotation"}
                                      onClick={() => setType("Quotation")}
                                    >
                                      Create From Quotation
                                    </fieldset>
                                  </fieldset>
                                )}

                                {type === "New" ? (
                                  <fieldset className="form-group col-md-4">
                                    <Autocomplete
                                      id="controlled-demo"
                                      options={customers}
                                      getOptionLabel={(option) =>
                                        option.companyname
                                      }
                                  
                                      value={
                                        values.customer ? undefined : undefined
                                      }
                                      onChange={handlecustomerChange}
                                      error={
                                        customer === "" ||
                                        customer === undefined
                                      }
                                      disabled={list.length !== 0}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={`Select Customer (${customer})`}
                                          variant="standard"
                                          value={customer}
                                          error={
                                            (customer === "" ||
                                            customer === undefined) && error
                                          }
                                          helperText={
                                            (customer === "" ||
                                            customer === undefined) && error
                                              ? "Empty field!"
                                              : " "
                                          }
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </fieldset>
                                ) : (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <Autocomplete
                                        id="controlled-demo"
                                        options={
                                          pagename === "New Rent Asset"
                                            ? rentalquotationlist
                                            : soldquotationlist
                                        }
                                        getOptionLabel={(option) => option}
                                        
                                        
                                        value={values ? undefined : undefined}
                                        onChange={handleQuotationChange}
                                        error={
                                          quotation === "" ||
                                          quotation === undefined
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={`Select Quotation (${quotation})`}
                                            variant="standard"
                                            value={quotation}
                                            error={ (quotation === "" || quotation === undefined) && error}
                                            helperText={ (quotation === "" || quotation === undefined) && error? "Empty field!": " "}
                                          />
                                        )}
                                      />
                                    </fieldset>
                                    <fieldset className="form-group col-md-3 m-0 p-0">
                                      <TextField
                                        name="customer"
                                        label={`Customer`}
                                        variant="standard"
                                        value={customer}
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                  </>
                                )}
                                <fieldset className="form-group col-md-12 m-0 p-0" />
                                <fieldset className="form-group col-md-3 mt-2 m-0">
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={taxrequired}
                                          onChange={handletaxrequired}
                                          name="taxrequired"
                                          color="primary"
                                          disabled={
                                            itemname !== "" || list.length !== 0
                                          }
                                        />
                                      }
                                      label="Tax Required?"
                                    />
                                  </FormGroup>
                                </fieldset>

                                {taxrequired === true?
                            <fieldset className="form-group col-md-3 m-0">
                                <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Tax Type :&nbsp; {taxtype}</InputLabel>
                                </FormControl>
                                </fieldset>
                                :''
                              }

                          
                        {taxrequired=== true && taxtype === "GST" && custcountry === "India" && custcountry==="India"? (
                              <fieldset className="form-group col-md-6 m-0">
                              <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">GST Type :&nbsp; {gsttype}</InputLabel>
                                  </FormControl>
                              </fieldset>
                        ) : (
                         ''
                        )}
                                <fieldset className="form-group col-md-12 m-0 p-0" />
                                <fieldset className="form-group col-md-3">
                                      <FormControl
                                        // sx={{ m: 1, minWidth: 120 }}
                                        className="col-md-12"
                                      >
                                        <InputLabel id="demo-simple-select-filled-label">
                                          AssetType
                                        </InputLabel>
                                        <Select
                                          value={assettype}
                                          onChange={(e)=>setAssetType(e.target.value)}
                                          variant="standard"
                                        >
                                              <MenuItem
                                                value={"own"}
                                              >
                                                Own
                                              </MenuItem>
                                              <MenuItem
                                              value={"thirdparty"}
                                            >
                                              ThirdParty
                                            </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </fieldset>

                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="asset"
                                    options={assettype==="own"?assetidList:thirdpartyassetidList}
                                    getOptionLabel={(option) => option}
                                    
                                    
                                    value={values.asset ? undefined : undefined}
                                    onChange={handleAssetChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="assetid"
                                        value={assetid}
                                        label={`Select AssetId (${assetid})`}
                                        variant="standard"
                                        error={assetid === ""&& error}
                                        helperText={
                                          assetid === ""&& error ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="text"
                                    name="item"
                                    label={`Item`}
                                    value={itemname}
                                    variant="standard"
                                  />
                                </fieldset>
                                {/* <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="text"
                                    name="serialno"
                                    label={`Serial No.`}
                                    value={serialno}
                                    variant="standard"
                                  />
                                </fieldset> */}
                                {taxrequired === true ? (
                                  <>
                                    {taxtype === "GST" &&
                                    custcountry === "India" ? (
                                      <>
                                        {gsttype === "INTRASTATE" ? (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="number"
                                                name="tax"
                                                label="CGST (%)"
                                                value={tax / 2}
                                                variant="standard"
                                                disabled
                                                // onChange={handleTaxChange}
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="number"
                                                name="tax"
                                                label="SGST (%)"
                                                value={tax / 2}
                                                variant="standard"
                                                disabled
                                                // onChange={handleTaxChange}
                                              />
                                            </fieldset>
                                          </>
                                        ) : gsttype === false ? (
                                          <fieldset className="form-group col-md-3">
                                            <TextField
                                              className="form-control"
                                              type="number"
                                              name="tax"
                                              label="IGST (%)"
                                              value={tax}
                                              variant="standard"
                                              disabled
                                              // onChange={handleTaxChange}
                                            />
                                          </fieldset>
                                        ) : (
                                          <fieldset className="form-group col-md-3">
                                            <TextField
                                              className="form-control"
                                              type="number"
                                              name="tax"
                                              label="UTGST (%)"
                                              value={tax}
                                              variant="standard"
                                              disabled
                                              // onChange={handleTaxChange}
                                            />
                                          </fieldset>
                                        )}
                                      </>
                                    ) : taxtype === "GST" ? (
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="tax"
                                          label="GST(%)"
                                          value={tax}
                                          variant="standard"
                                          disabled
                                          // onChange={handleTaxChange}
                                        />
                                      </fieldset>
                                    ) : (
                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="number"
                                          name="tax"
                                          label="VAT(%)"
                                          value={tax}
                                          variant="standard"
                                          disabled
                                          // onChange={handleTaxChange}
                                        />
                                      </fieldset>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="number"
                                    name="amount"
                                    label="Amount/Rent"
                                    value={amount}
                                    onChange={handleamountChange}
                                    variant="standard"
                                    error={(amount === "" || amount === undefined) && error}
                                    helperText={(amount === "" || amount === undefined) && error? "Empty field!": ""}
                                    disabled={assetid === ""}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="number"
                                    name="amount"
                                    label={`Amount/Rent Inc. ${taxtype}`}
                                    variant="standard"
                                    value={amountinctax}
                                    disabled
                                  />
                                </fieldset>

                                {pagename === "New Rent Asset" ||
                                pagename === "New Rental Quotation" || pagename === 'Edit Rental Quotation' ? (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <FormControl
                                        // sx={{ m: 1, minWidth: 120 }}
                                        className="col-md-12"
                                      >
                                        <InputLabel id="demo-simple-select-filled-label">
                                          Rent/Invoice
                                        </InputLabel>
                                        <Select
                                          value={amounttype}
                                          onChange={handleAmountTypeChange}
                                          variant="standard"
                                          disabled={list.length !== 0}
                                        >
                                          {types.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </fieldset>
                                    <fieldset className="col-md-3">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container>
                                          <DatePicker
                                            variant="standard"
                                            id="date-picker-dialog"
                                            label="Start Date "
                                            format="dd/MM/yyyy"
                                            minDate={new Date()}
                                            value={startdate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params}  variant="standard" fullWidth/>}
                                            error={
                                              startdate === "" && error
                                                ? "Invalid Date"
                                                : ""
                                            }
                                            autoOk={true}
                                            disabled={list.length !== 0}
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                    </fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="number"
                                        name="period"
                                        label="Rental Period"
                                        value={period}
                                        variant="standard"
                                        onChange={handlePeriodChange}
                                        error={
                                          period === "" && error
                                            ? "Enter period"
                                            : ""
                                        }
                                        disabled={list.length !== 0}
                                      />
                                    </fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <FormControl
                                        // sx={{ m: 1, minWidth: 120 }}
                                        className="col-md-12"
                                      >
                                        <InputLabel id="demo-simple-select-filled-label">
                                          Rental Period Type
                                        </InputLabel>
                                        <Select
                                          value={periodtype}
                                          onChange={handlePeriodTypeChange}
                                          variant="standard"
                                          disabled={list.length !== 0}
                                        >
                                          {ptypes.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </fieldset>
                                  </>
                                ) : (
                                  <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="number"
                                    name="depreciation"
                                    label={`Enter Depreciation Amount till today`}
                                    variant="standard"
                                    value={depreciation}
                                    onChange={(e)=>setDepreciation(e.target.value)}
                                  />
                                </fieldset>
                                )}
                                {serialno!==null && serialno !== undefined && serialno !== ""?
                                  <fieldset className="col-md-12">
                                  <InputLabel id="demo-simple-select-standard-label">Serial No :&nbsp; {serialno}</InputLabel>
                                </fieldset>
                                :""}
                                  {
                                    showmodel?
                                    <>
                                     <TableContainer>
                        <Table className="table-sm" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell className="text-center">
                                Serial number
                              </TableCell>
                              <TableCell className="text-center">
                                Product number
                              </TableCell>
                              <TableCell className="text-center">
                                Remarks
                              </TableCell>
                              <TableCell className="text-center">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {thirdpartitems?.map((e, index) => (
                              <TableRow key={index}>
                                <TableCell className="text-center">
                                  {e.serialno}
                                </TableCell>
                                <TableCell className="text-center">
                                  {e.productno}
                                </TableCell>
                                <TableCell className="text-center">
                                  {e.remarks}
                                </TableCell>
                                {serialnolist.includes(e.serialno)?
                                <TableCell className="text-center cursorPointer textColour" onClick={()=>Removeserialno(e.serialno)}>
                                  {/* <i className="fa fa-times"/> */}
                                   Selected
                                </TableCell>
                                :
                                <TableCell className="text-center cursorPointer textColour" onClick={()=>Addserialno(e.serialno)}>
                                  Select
                                </TableCell>
                                 }
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                                    </>:""
                                  }

                                <fieldset className="col-md-12">
                                  <button
                                    type="button"
                                    onClick={Addtolist}
                                    className="btn btn-sm hovbuttonColour mr-1"
                                  >
                                    &nbsp;&nbsp; Add &nbsp;&nbsp;
                                  </button>
                                </fieldset>
                                {list.length === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <TableContainer>
                                      <Table className="table-sm my-3">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell className="text-center">
                                              AssetId
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Asset Type
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Item
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Serial No.
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Amount
                                            </TableCell>
                                            {taxrequired === true ? (
                                              <>
                                                {taxtype === "GST" &&
                                                custcountry === "India" ? (
                                                  gsttype === "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-center">
                                                        CGST(%)
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        SGST(%)
                                                      </TableCell>
                                                    </>
                                                  ) : gsttype ===
                                                    "INTERSTATE" ? (
                                                    <TableCell className="text-center">
                                                      IGST(%)
                                                    </TableCell>
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      UTGST(%)
                                                    </TableCell>
                                                  )
                                                ) : taxtype === "GST" ? (
                                                  <TableCell className="text-center">
                                                    GST(%)
                                                  </TableCell>
                                                ) : (
                                                  <TableCell className="text-center">
                                                    VAT(%)
                                                  </TableCell>
                                                )}
                                                <TableCell className="text-center">
                                                  Amount Inc. Taxes
                                                </TableCell>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            {pagename === "New Rent Asset" || pagename === "New Rental Quotation" || pagename === "Edit Rental Quotation" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  Start Date
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Period
                                                </TableCell>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <TableCell className="text-center">
                                              Action
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {list.map((e, index) => (
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {e.assetid}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.assettype}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.itemname}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.serialno}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                {
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={e.amount}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                }
                                                {pagename ===
                                                  "New Rent Asset" ||
                                                pagename ===
                                                  "New Rental Quotation"
                                                  ? `/${e.amounttype}`
                                                  : ""}
                                              </TableCell>
                                              {taxrequired === true ? (
                                                <>
                                                  {taxtype === "GST" &&
                                                  custcountry === "India" ? (
                                                    gsttype === "INTRASTATE" ? (
                                                      <>
                                                        <TableCell className="text-center">
                                                          {e.tax / 2}%
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          {e.tax / 2}%
                                                        </TableCell>
                                                      </>
                                                    ) : (
                                                      <TableCell className="text-center">
                                                        {e.tax}%
                                                      </TableCell>
                                                    )
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      {e.tax}%
                                                    </TableCell>
                                                  )}
                                                  <TableCell className="text-right">
                                                    {
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={e.amountinctax}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    }
                                                    {pagename ===
                                                      "New Rent Asset" ||
                                                    pagename ===
                                                      "New Rental Quotation"
                                                      ? `/${e.periodtype}`
                                                      : ""}
                                                  </TableCell>
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {pagename === "New Rent Asset" || pagename === "New Rental Quotation" || pagename === "Edit Rental Quotation" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    {moment(e.startdate).format(
                                                      "DD-MM-YYYY"
                                                    )}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.period} {e.periodtype}
                                                    {/* {e.periodyears} Years{" "}
                                                {e.periodmonths} Monhts{" "}
                                                {e.perioddays} Days{" "} */}
                                                  </TableCell>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              <TableCell>
                                                <button
                                                  as="button"
                                                  className="btn btn-sm deletebutton"
                                                  onClick={() =>
                                                    RemoveFromList(index)
                                                  }
                                                >
                                                  Delete{" "}
                                                </button>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="number"
                                        name="advance"
                                        label="Advance"
                                        value={advance}
                                        onChange={handleadvanceChange}
                                        variant="standard"
                                      />
                                    </fieldset>

                                    <fieldset className="col-md-12 mt-2">
                                      <button
                                        type="button"
                                        onClick={onsubmit}
                                        className="btn btn-sm hovbuttonColour mr-1"
                                      >
                                        Submit
                                      </button>
                                      <Link
                                        className="btn btn-sm deletebutton"
                                        to={{pathname:"/asset"}} state ={{sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype}}
                                      >
                                        X Cancel
                                      </Link>
                                    </fieldset>
                                  </>
                                )}
                              </div>
                              <fieldset className="form-group col-md-12 mt-2">
                                <label className="text-red">
                                  Note : If any selection is wrong or with
                                  invalid characters, please cancel the
                                  selection with "x" button in selection and
                                  reselect the Field.
                                </label>
                              </fieldset>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(RentAsset);
