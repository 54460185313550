import { Backdrop, CircularProgress, FormControlLabel, FormLabel, Grid, Radio, TextField ,Checkbox} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { FormikTextField } from "../CommonComponents/FormField.js";
import React, { Component } from "react";
import { NumericFormat } from "react-number-format";
import withRouter from "../withRouter.js";
import {
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  transactionFrom,
  userid,
} from "../Common/Credentials";
import {
  combdata,
  subacclist,
  totalcombdata,
  expcombdata,
  outcombdata,
  typeofentry,
  indirecttax,
  directtax,
  lgcombdata,
  incomecombdata,
  directtaxasset,
  incombdata,
  assets,
  lgcombdata1,
  BranchList,
} from "./Journal";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NewJournalService from "./NewJournalService.js";


let jvtype = 9; // Type of Jv 9 for Dynamic
let debdupe,
  credupe,
  expdupe,
  taxdupe,
  debtotal = "",
  cretotal = "",
  taxtotal = 0,
  dtaxtotal = 0,
  tcschecked = 0,
  taxreqchecked = 0,
  debitadditional=0,
  taxchecked = false;
let val = null,
  val2 = null,
  expval1,
  expval,
  indtaxtype,
  indsubtaxtype; //,creindividualval=[],debindividualval=[]

  const roundTo = (num, precision) => {
    const factor = Math.pow(10, precision)
    return Math.round(num * factor) / factor
  }
let taxbehav = 0; // 0-notax,1-directtax, 2-inputindirect 3-outputindirect
class CommonEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aa: "",
      btncolor: "",
      debval: [],
      dd: [],
      taxingtype: [
        { id: 1, name: "Direct" },
        //{ id: 1, name: "Direct Sales" },
        { id: 2, name: "Indirect" },
      ],
      taxreq: [
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ],
      tdsreq: [
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ],
      taxchecked: false,
      tcschecked: 0,
      taxreqchecked:0,
      advreqchecked:0,
      debtotal: "",
      cretotal: "",
      taxtotal: "",
      dtaxtotal: "",
      SDdate: false,
      NCEntry:0,
      ResEntry:0,
      fed: "",
      displaychecked: false,
      directchecked: false,
      noexp: [
        {
          id: "noexp",
          name: "No Expense Mapping",
          amount: 0.0,
          entrytype: 0,
          subacc: "",
          tenantId: "",
          rule: 0,
          behaviour: 0,
          subname: "z - NA",
          totamount: null,
          th: "",
          taxtype: 0,
        },
      ],
      loader: false,
      loader1: true,
      debitadditional: 0,
      val: [],
      val2: [],
      jvinsertiontype:1,
      lt:true,ap:false,ar:false,dr:false,dp:false,DeptList:[],branch:null,dept:null
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.debtLedgers = this.debtLedgers.bind(this);
    this.credLedgers = this.credLedgers.bind(this);
    this.expLedgers = this.expLedgers.bind(this);
    this.handleltChange =this.handleltChange.bind(this);
    this.handleapChange = this.handleapChange.bind(this);
    this.handlearChange = this.handlearChange.bind(this);
    this.handledrChange = this.handledrChange.bind(this);
    this.handledpChange =this.handledpChange.bind(this);
  }
  componentDidMount() {
   // alert(Math.round(parseFloat(25137.925*100))/100)
    // alert(typeofentry + "A")
    tcschecked = 0;
    taxbehav = 0;
    debtotal = "";
    cretotal = "";
    taxtotal = 0;
    dtaxtotal = 0;
    taxreqchecked=0;
    debitadditional=0;
    this.setState({ taxreqchecked:0,loader1:false})

  

    // const script = document.createElement("script");
    //       script.src= '../../js/content.js';
    //       script.async= true;
    //       document.body.appendChild(script);
  }

  handleltChange=()=>{
    this.props.navigate(1);
    this.setState({lt:true,ap:false,ar:false,dr:false,dp:false})
  }
  handleapChange=()=>{
    this.setState({lt:false,ap:true,ar:false,dr:false,dp:false})
  }
  handlearChange=()=>{
    this.setState({lt:false,ap:false,ar:true,dr:false,dp:false})
  }
  handledrChange=()=>{
    this.setState({lt:false,ap:false,ar:false,dr:true,dp:false})
  }
  handledpChange=()=>{
    this.setState({lt:false,ap:false,ar:false,dr:false,dp:true})
  }

  onSubmit(values, index) {
    let mode,bank,bankid,cred1,crname,credid,debt1,drname,dredid
    if(this.state.dp===true){
      values.cred.map((e,i)=> cred1=e.name.addltype)
      values.cred.map((e,i)=>crname=e.name.name)
      values.cred.map((e,i)=>credid=e.name.id)
    if(cred1===0){
      mode=0
    }else if (cred1===1){
      mode=1
      bank=crname
      bankid=credid
    }
  }
  if(this.state.dr===true){
    values.debt.map((e,i)=> debt1=e.name.addltype)
    values.debt.map((e,i)=>drname=e.name.name)
    values.debt.map((e,i)=>dredid=e.name.id)
    if(debt1===0){
      mode=0
    }else if (debt1===1){
      mode=1
      bank=drname
      bankid=dredid
    }
  }
  
    this.setState({ loader: true });
    let debt = values.debt;
    let cred = values.cred;
    let tax = values.tax;
   // let debitid
   // debt.map((e) =>{return debitid=e.name.id});
   // let leddata=[]
    // if(this.state.fed !== "" ){
    //   leddata=combdata.filter(e=> e.id===debitid)
    // console.log(combdata)
    // }
    // let dtax  = values.dtax

    // if(typeofentry===1){
    //   tax= values.tax
    //  }else{
    //    tax=values.dirdebtax
    //  }
    let debtname = debt.map((e) => e.name);

    let credname = cred.map((e) => e.name);
    let taxname = tax !== undefined ? tax.map((e) => e.name) : "";
    // let dtaxname=dtax!==undefined?dtax.map(e=>e.name):""
    let credsub = credname.map((e) => e.type);
    let a = debtname.concat(credname);
    let jvval = a.concat(taxname);

    let duplicate = [
      ...new Set(
        jvval.filter((value, index, self) => self.indexOf(value) !== index)
      ),
    ];

    let credcb = credsub.filter((e) => e === 1);
    let crednoncb = credsub.filter((sub) => {
      return sub !== 1;
    });

    let dupval = duplicate.map((e) => e.name);
    //  console.log(credcb)
    // console.log(crednoncb)
    if ((this.state.ar===true || this.state.dr===true) && (cretotal+taxtotal)-dtaxtotal !== debtotal) {
      Swal.fire({
        title: "Please Check !!!",
        text: "Debit and Credit totals should be equal",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
       alert("Not Equal");
      this.setState({ loader: false });
   } else if (duplicate.length !== 0) {
      Swal.fire({
        title: "Please Check !!!",
        text: dupval.toLocaleString() + "Ledgers are duplicated",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
      this.setState({ loader: false });
      // let crednoncb=crednonc.concat(crednonb)
    } else if ((credcb.length >= 1) & (crednoncb.length !== 0)) {
      alert("Other ledgers are not allowed with Cash and Bank Ledgers");
      this.setState({ loader: false });
    } else if (
      debtotal === 0 ||
      debtotal === "" ||
      this.state.cretotal === 0 ||
      this.state.cretotal === ""
    ) {
      Swal.fire({
        title: "Please Check !!!",
        text: "Values for Debit and credit should not be blank",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });

      this.setState({ loader: false });
    }else {
      let dijv = {
        debt: values.debt,
        tax: values.tax,
        dtax: values.dtax,
        cred: values.cred,
        createdBy: userid,
        tenantId: currentTenant,
        naration: values.narration,
        type: jvtype,
        expense: values.expense,
        debtotal: debtotal,
        taxtotal: this.state.taxtotal,
        dtaxtotal: this.state.dtaxtotal,
        cretotal: this.state.cretotal,
        taxbehav: taxbehav,
        typeofentry: typeofentry,
        indtaxtype: indtaxtype,
        indsubtaxtype: indsubtaxtype,
        advanceentry:this.state.advreqchecked===0?0:1,
        jvinsertiontype:this.state.jvinsertiontype,
        mode:mode,bank:bank,bankid:bankid,
        date: this.state.fed !== "" ? this.state.fed : null,
        lt:this.state.lt,ap:this.state.ap,ar:this.state.ar,dr:this.state.dr,dp:this.state.dp,ncentry:this.state.NCEntry,
        resentry:this.state.ResEntry,branch:this.state.branch,dept:this.state.dept
      };
     // console.log(this.state.fed + "  And " + values.cred.map(e=>{return moment(e.name.createdAt).format("yyyy-MM-DD HH:mm:ss")}))

      if (userid === undefined) {
        Swal.fire({
          title: "Please Check !!!",
          text: "Your Session is Lost: Please login Back to resume",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        });
        this.props.navigate(`/`);
      }else if (this.state.dp===true && values.cred[0].name.th==="TH3"){
        // alert(values.cred[0].name.amount)
        // alert(this.state.cretotal)
        let a=(parseFloat(values.cred[0].name.amount)-parseFloat(this.state.cretotal))
        // alert(parseFloat(a))
        if(a<0){
          Swal.fire({
            title: "Please Check !!!",
            text: "In-sufficient balance in the account",
            icon: "warning",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
         // alert("In-sufficient balance in the account");
        } else{
          this.props.navigate({ pathname: `/cjournalconfirm` }, { state: dijv });
        }
      } else if(this.state.fed!=="" && this.state.fed<values.debt.map(e=>{return moment(e.name.createdAt).format("yyyy-MM-DD HH:mm:ss")})){
        Swal.fire({
          title: "Please Check !!!",
          text: "Date cannot be before Debit Ledger Creation Date !!",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        });
      
     }else if(this.state.fed!=="" && this.state.fed<values.cred.map(e=>{return moment(e.name.createdAt).format("yyyy-MM-DD HH:mm:ss")})){
      Swal.fire({
        title: "Please Check !!!",
        text: "Date cannot be before Credit Ledger Creation Date !!",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
    
   }
  
      else {
        // console.log(dijv)
        this.props.navigate({ pathname: `/cjournalconfirm` }, { state: dijv });
        // NewJournalService.insertEntryComb(dijv);
      }
    }
  }

  onDebBlur(values) {
    let a = values.debt;
    let b = a.map((e) => e.name);
    this.setState({ debname: b });
  }

  handleSDDate = () => {
    this.setState({ SDdate: this.state.SDdate === true ? false : true });
  };
  handleCEEntry = () => {
    this.setState({ NCEntry: this.state.NCEntry === 0 ? 1 : 0 });
  };

  handleRESEntry= () => {
    this.setState({ ResEntry: this.state.ResEntry === 0 ? 1 : 0 });
  };
  handleFsdDateChange = (date) => {
    if(date!==null){
    this.setState({ fsd: date });
    this.setState({
      fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
    });
  }else{
    this.setState({ SDdate:false });
  }
  };

  addDebits=()=>{
    this.setState({debitadditional:1})
    debitadditional=1
  }

  remDebits=()=>{
    this.setState({debitadditional:0})
    debitadditional=0
  }

  validate(values, index) {
    let errors = {};
    let deb = values.debittotal;
    let cre = values.credittotal;
    let a = values.debt.map((e) => e.name);

    if (deb !== cre) {
      errors.credittotal = "Debit and Credit totals should be equal";
    }

    if (a.value === "dr") {
      alert("Debit cannot be blank");
    }

    if(!`debt[${index}].name`){
      errors.name = "Narration cannot be blank";

    }

    if (
      values.narration === null ||
      values.narration === undefined ||
      values.narration === ""
    ) {
      errors.narration = "Narration cannot be blank";
    }
    if (typeofentry === 3) {
      if (
        values.expense === null ||
        values.expense === undefined ||
        values.expense === ""
      ) {
        errors.expense = "Please Map expense";
      }
    }

    return errors;
  }

  addTotal(values, index) {
    let a = values.debt;
    let b = a.map((e) => e.value);
    this.setState({ $index: b });
    let c = b.reduce((a, v) => (a = a + v), 0);
    this.setState({ debtotal: c });
    debtotal = c;
    //debindividualval=b
    // alert(b)
  }

  addCTotal(values, index, value) {
    let a = values.cred;
    let b = a.map((e) => e.value);
    let c = b.reduce((a, v) => (a = a + v), 0);
    this.setState({ cretotal: c });
    cretotal = c;
    // if(typeofentry===1){
    //   debtotal=c
    // }
    //creindividualval=b
    // alert(b.reduce((a, v) => (a, v), 0))
  }

  addTaxTotal(values, index) {
    let a = values.tax;
    let b = a.map((e) => e.value);
    let c = b.reduce((a, v) => (a = a + v), 0);
    this.setState({ taxtotal: c });
    if (typeofentry === 2) {
      if (taxbehav === 1) {
        debtotal = c + debtotal;
      }
    }
    taxtotal = c;
  }

  addDirTaxTotal(values, index) {
    let a = values.dtax;
    let b = a.map((e) => e.value);
    let c = b.reduce((a, v) => (a = a + v), 0);
    this.setState({ dtaxtotal: c });
    dtaxtotal = c;
  }

  debtLedgers(value) {
    // taxtype=target.taxtype
    //console.log(value)
    let value1 
    if(value.indexOf("[")<0){
      value1=value
    }else{
      value1 = value.substring(0, value.indexOf("["));
    }
    //let value1 = value.before("-");
    let debt1;
    if (typeofentry === 2) {
      debt1 = null;
      debt1 = totalcombdata;
    }
    // else if(typeofentry===2){
    //   debt1=null
    //   debt1=expcombdata
    // }
    // else if (typeofentry === 3) {
    //   debt1 = null;
    //   debt1 = combdata;
    // }
    let debt = debt1.map((e) => e.name);
    let duplicate = debt.filter((val) => val === value.indexOf("[")<0?value1:value1.trim());
    if (duplicate.length === 0 || duplicate.length === null) {
      debdupe = 0;
    } else {
      debdupe = 1;
    }

  }

  taxLedgers(value, taxone) {
    let value1 
    if(value.indexOf("[")<0){
      value1=value
    }else{
      value1 = value.substring(0, value.indexOf("["));
    }
   // let value1 = value;
    let tax1;
    if (value !== null || taxone !== undefined || taxone !== null) {
      let ttype = taxone.map((e) => e.name);
      //console.log(ttype)
      if (ttype[0] !== null) {
        ttype.map((e) => {
          return (indsubtaxtype = e.indirectsubtaxtype);
        });
        ttype.map((e) => {
          return (indtaxtype = e.indirecttaxtype);
        });
      }
    }
    if (typeofentry === 2) {
      tax1 = null;
      tax1 = indirecttax;
    }
    // else if(typeofentry===2){
    //   debt1=null
    //   debt1=expcombdata
    // }
    // else if(typeofentry===3){
    //   debt1=null
    // debt1=combdata
    // }
    if (tax1 !== undefined) {
      let duplicate = tax1.filter((val) => val === value.indexOf("[")<0?value1:value1.trim());
      if (duplicate.length === 0 || duplicate.length === null) {
        taxdupe = 0;
      } else {
        taxdupe = 1;
      }
    }
  }

  credLedgers(value) {
    let value1 
    if(value.indexOf("[")<0){
      value1=value
    }else{
      value1 = value.substring(0, value.indexOf("["));
    }
   // let value1 = value;
    let cred1;
    if (typeofentry === 2) {
      cred1 = null;
      //let c=combdata.filter(e=>e.type===11)
      //let a= c.concat(combdata.filter(e=>e.type===16))
      //let b=expcombdata.filter(e=>e.rule!==2 && e.type===18)
      cred1 = totalcombdata;
    }
    // if(typeofentry===2){
    //   cred1=null
    //   cred1=outcombdata
    // }
    // if (typeofentry === 3) {
    //   cred1 = null;
    //   cred1 = combdata;
    // }

    if (typeofentry === 2) {
      let cred = cred1.map((e) => e.name);
      let duplicate = cred.filter((val) => val=== value.indexOf("[")<0?value1:value1.trim());
      if (duplicate.length === 0 || duplicate.length === null) {
        credupe = 0;
      } else {
        credupe = 1;
      }
    } else {
      credupe = 1;
    }
  }
  
  branches(value){
    this.setState({branch:value.id})
    NewJournalService.getDeptBYBranch(value.id).then((response)=>
      this.setState({DeptList:response.data})
    );
  }
  dept(value){
    this.setState({dept:value.id})
  }
  handleCheckboxChange = (event) => {
    this.setState({ taxchecked: event.target.checked });
    // if(event.target.checked=true){
    taxchecked = event.target.checked;
    if(this.state.lt!==false ||this.state.ap!==false ||this.state.dp!==false){
    taxbehav = 2;
    }else{
      taxbehav = 3;
    }
    // }else if(event.target.checked=false){
    //   taxbehav=0
    // }
  };
  //  handleDirect= event =>{
  //   this.setState({ directchecked: event.target.checked })
  //   if(event.target.checked=true){
  //     taxbehav=1
  //   }else if(event.target.checked=false){
  //     taxbehav=0
  //   }
  // }
  onTcsChange(option) {
    this.setState({ tcschecked: option.id });
   // taxbehav = option.id;
    tcschecked = option.id;
  }

  onTaxChange(option) {
    this.setState({ taxreqchecked: option.id });
    taxreqchecked = option.id;
  }

  onAdvChange(option) {
    this.setState({ advreqchecked: option.id });
    // advreqchecked = option.id;
  }

  
  // onTaxChange(option) {
  //   taxbehav=0
  //   this.setState({ taxchecked: option.id})
  //   if(typeofentry===1 && option.id===1){
  //   taxbehav=1
  //   } else if(typeofentry===1 && option.id===2){
  //     taxbehav=2
  //   }
  // }
  expLedgers(value) {
    let value1 = value;
    let exp1 = expcombdata;
    let expval = exp1.concat(this.state.noexp);
    let exp = expval.map((e) => e.name);
    let duplicate = exp.filter((value) => value === value1);
    if (duplicate.length === 0 || duplicate.length === null) {
      expdupe = 0;
    } else {
      expdupe = 1;
    }
  }
  render() {
    let sub = subacclist;
    let val3, tcsdata;
    val3 = indirecttax;
    if(this.state.ar===true || this.state.dr===true ){
      tcsdata = directtaxasset;
    }else{
    tcsdata = directtax;
    }

    
    // if (typeofentry === 1) {
    //   val = null;
    //   val2 = null;
    //   val = expcombdata;
    //   val2 = outcombdata;
    // }
    if (typeofentry === 2) {
      val = null;
      val2 = null;
      if(this.state.lt===true){
        let a = lgcombdata.filter((e)=>e.taxtype!==1)
        let b = a.filter((e)=>e.taxtype!==2)
        val=b
        val2=b
      }
      if(this.state.ap===true){
        if(this.state.ResEntry===0){
        val=expcombdata
        }else{
        val=assets.filter((e)=>e.type===2)
        }
        val2=outcombdata
      }
      if(this.state.ar===true){
        val=incombdata
        val2=incomecombdata
      }
      if(this.state.dr===true){
        val=lgcombdata.filter((e)=>e.subheadid==="AH000020" || e.subheadid==="AH000021" || e.subheadid==="AH000022" )
        val2=lgcombdata.filter((e)=>(e.subheadid!=="AH000020" || e.subheadid!=="AH000021" || e.subheadid!=="AH000022") && (e.taxtype!==2 && e.taxtype!==1 ))
      }
      if(this.state.dp===true){
        val=lgcombdata1.filter((e)=>e.subheadid!=="AH000020" || e.subheadid!=="AH000021" || e.subheadid!=="AH000022" && e.taxtype!==2 && e.taxtype!==1)
        val2=lgcombdata1.filter((e)=>e.subheadid==="AH000020" || e.subheadid==="AH000021" || e.subheadid==="AH000022")
      }
      // val = totalcombdata;
      // val2 = totalcombdata;
    }
    // if (typeofentry === 3) {
    //   val = null;
    //   val2 = null;
    //   val = combdata;
    //   val2 = combdata;
    //   expval1 = expcombdata;
    //   expval = expval1.concat(this.state.noexp);
    // }
    return (
      <div>
         <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Formik
          initialValues={{
            sub,
            val,
            debt: [{ name: null, value: null, d1: "d" }],
            tax: [{ name: null, value: null, percent: null, number: null }],
            dtax: [{ name: null, value: null, percent: null, number: null }],
            cred: [{ name: null, value: null, number: null }],
          }}
          onSubmit={this.onSubmit}
          validateOnChange={false}
          validate={this.validate}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => (
            <Form autoComplete="off">
              {/* ----------------------Tax Selection Section --------------------------------               */}
              {typeofentry !== null ? (
                <div className="table-responsive form-row ">
                  <div className="form-row col-md-12 text-left form-switch mt-3">
                  <fieldset className="form-group col-md-2">
                              <span className="mx-3">Ledger Trans</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.lt} onChange={this.handleltChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-2">
                              <span className="mx-3">A/C Payable</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.ap} onChange={this.handleapChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                              <span className="mx-3">A/C Receivable</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.ar} onChange={this.handlearChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-2">
                              <span className="mx-3">Dir Receipt</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.dr} onChange={this.handledrChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-2">
                              <span className="mx-3">Dir Payment</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.dp} onChange={this.handledpChange} name="checkedB" />}
                              />
                            </fieldset>

                  </div>
                  <div className="form-row col-md-12 align-left form-switch mt-3">
                    <fieldset className="form-group col-md-3 align-left ml-2">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            className="sm"
                            checked={this.state.SDdate}
                            onChange={this.handleSDDate}
                            name="checkedB"
                          />
                        }
                      />
                      <span className="text-left textColour">
                        {this.state.SDdate === false
                          ? "System Date"
                          : "Custom Date"}
                      </span>
                    </fieldset>


                    {this.state.SDdate === true ? (
                      <fieldset className="form-group col-md-3 text-left">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid justifyContent="space-around">
                            <DateTimePicker
                              disableFuture
                              showTodayButton
                              showCancelButton
                              variant="standard"
                              id="date-picker-dialog"
                              inputVariant="standard"
                              minDate={
                                new Date(
                                  moment(transactionFrom).format(
                                    "yyyy-MM-DD"
                                  )
                                )
                              }
                              maxDate={
                                new Date(
                                  moment(financialyearend).format("yyyy-MM-DD")
                                )
                              }
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.fsd}
                              // label="Custom Date"
                              InputAdornmentProps={{ position: "start" }}
                              onChange={this.handleFsdDateChange}
                              renderInput={(params) => (
                                <TextField {...params}  variant="standard"/>
                              )}
                              autoOk={true}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </fieldset>
                    ) : null}

{this.state.ap===true ||this.state.ar===true?<>
<fieldset className="form-group col-md-3 align-left ml-2">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            className="sm"
                            checked={this.state.NCEntry}
                            onChange={this.handleCEEntry}
                            name="checkedB"
                          />
                        }
                      />
                      <span className="text-left textColour">
                        {this.state.NCEntry === 1
                          ? "Closing Entry"
                          : "Non Closing Entry"}
                      </span>
                    </fieldset></>:""}
  {this.state.ap===true ?<>
<fieldset className="form-group col-md-3 align-left ml-2">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            className="sm"
                            checked={this.state.ResEntry}
                            onChange={this.handleRESEntry}
                            name="checkedB"
                          />
                        }
                      />
                      <span className="text-left textColour">
                        {this.state.ResEntry === 0
                          ? "Normal Entry"
                          : "Reserves Entry"}
                      </span>
                    </fieldset></>:""}
                  </div>
                 
                  <div className="form-row col-md-12 text-left form-check form-switch mt-0 mb-0">
                  {this.state.dr===true || this.state.dp===true ? (
                     <fieldset className="form-group col-md-3 text-sm mt-0 float-left">
                        <>
                          <FormLabel>Advance Entry :</FormLabel>
                          {this.state.taxreq.map((option,i) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-12 mt-0 mb-0">
                                    <input
                                      type="radio"
                                      checked={
                                        this.state.advreqchecked === option.id
                                          ? true
                                          : false
                                      }
                                      key={i}
                                      onChange={this.onAdvChange.bind(
                                        this,
                                        option
                                      )}
                                      style={{ marginRight: 8, marginTop: 25 }}
                                      value={this.state.advreqchecked!==null?this.state.advreqchecked:undefined}
                                    />
                                    {option.name}
                                </div>
                              </label>
                            );
                          })}
                        </>
                    </fieldset>) : null}
                    {this.state.advreqchecked===0?<>
                    <fieldset className="form-group col-md-3 text-sm mt-0 float-left">
                      {typeofentry !== 3 && debitadditional===0 && this.state.tcschecked===0 && this.state.lt===false? (
                        <>
                          <FormLabel>Taxes :</FormLabel>
                          {this.state.taxreq.map((option,i) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-12 mt-0 mb-0">
                                  {/* <div className="radio-item mt-0 mb-0 text-sm"> */}
                                    <input
                                      type="radio"
                                      checked={
                                        this.state.taxreqchecked === option.id
                                          ? true
                                          : false
                                      }
                                      key={i}
                                      onChange={this.onTaxChange.bind(
                                        this,
                                        option
                                      )}
                                      style={{ marginRight: 8, marginTop: 25 }}
                                      value={this.state.taxreqchecked!==null?this.state.taxreqchecked:undefined}
                                    />
                                    {option.name}
                                  {/* </div> */}
                                </div>
                              </label>
                            );
                          })}
                        </>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group col-md-6 text-sm mt-0 float-left">
                      {typeofentry !== 3 && this.state.taxreqchecked === 1 ? (
                        <>
                          <FormLabel>Direct Taxes :</FormLabel>
                          {this.state.tdsreq.map((option,i) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-12 mt-0 mb-0">
                                  {/* <div className="radio-item mt-0 mb-0 text-sm"> */}
                                    <input
                                      type="radio"
                                      checked={
                                        this.state.tcschecked === option.id
                                          ? true
                                          : false
                                      }
                                      key={i}
                                      onChange={this.onTcsChange.bind(
                                        this,
                                        option
                                      )}
                                      style={{ marginRight: 8, marginTop: 25 }}
                                      value={this.state.tcschecked!==null?this.state.tcschecked:undefined}
                                    />
                                    {option.name}
                                  {/* </div> */}
                                </div>
                              </label>
                            );
                          })}
                        </>
                      ) : null}
                      {this.state.ap===true ||this.state.ar===true?<>
                      {typeofentry === 2 && this.state.taxreqchecked === 1 ? (
                        <label className="text-sm">
                          <Checkbox
                            checked={this.state.taxchecked}
                            onChange={this.handleCheckboxChange}
                          />
                          Indirect Taxes
                        </label>
                      ) : null}
                      </>:null}
                    </fieldset></>:null}
                  </div>
                  {this.state.lt===false?<>
                    <fieldset className="form-group col-md-4 text-sm mt-0 float-left">
                  <Field name="branch" validate={(value) =>
                                                value === null || value === undefined || value === "" ||
                                                value.length === 0 
                                                  ? "Please Select Branch"
                                                  : undefined
                                              }>
                                              {({ meta }) => (

                                                <Autocomplete id="branch" className="text-sm tagSizeSmall"
                                                  name="branch" options={BranchList}
                                                  size="small" clearOnBlur={true} 
                                                  getOptionLabel={(option) =>option.name}
                                                  value={ values.branch ? undefined : undefined}
                                                  onChange={(e, value) => {setFieldValue("branch",value );
                                                  }}
                                                  renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                     <FormikTextField style={{pt: 0,mt: 0,mb: 0,pb: 0,textsize: 1, }}
                                                        {...params}
                                                        type="text" name="branch"
                                                        label="Branch"
                                                        className="form-control text-sm"
                                                        error={ meta.touched && meta.error }
                                                        size="small"
                                                        onBlur={(e) =>this.branches(values.branch)}
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              )}
                                            </Field>




                      {/* {typeofentry !== 3 && debitadditional===0 && this.state.tcschecked===0? ( */}
                       
                    </fieldset>
                    <fieldset className="form-group col-md-4 text-sm mt-0 float-left">
                  <Field name="dept" validate={(value) =>
                                                value === null || value === undefined || value === "" ||
                                                value.length === 0 
                                                  ? "Please Select Department/Cost Center"
                                                  : undefined
                                              }>
                                              {({ meta }) => (
                                                <Autocomplete id="dept" className="text-sm tagSizeSmall"
                                                  name="dept" options={this.state.DeptList}
                                                  size="small" clearOnBlur={true} 
                                                  getOptionLabel={(option) =>option.name}
                                                  value={ values.branch ? undefined : undefined}
                                                  onChange={(e, value) => {setFieldValue("dept",value );
                                                  }}
                                                  renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                     <FormikTextField style={{pt: 0,mt: 0,mb: 0,pb: 0,textsize: 1, }}
                                                        {...params}
                                                        type="text" name="dept"
                                                        label="Department/Cost Center"
                                                        className="form-control text-sm"
                                                        error={ meta.touched && meta.error }
                                                        size="small"
                                                        onBlur={(e) =>this.dept(values.dept)}
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              )}
                                            </Field>
                    </fieldset>
                    </>
                    :""}
                  {/* ----------------------Top Banner Section --------------------------------               */}
                  <table className="table table-sm table-borderless">
                    <thead className="" style={{ backgroundColor: "#e9ecef" }}>
                      <tr className="form-group col-md-12">
                        <th>Ledger</th>
                        <th></th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                  </table>
                  {/* ----------------------Debit Section --------------------------------               */}
                  {this.state.lt!==false ||this.state.ap!==false ||this.state.dp!==false?
                  <table id="jou" className="table table-sm table-borderless">
                    <>
                      {this.state.taxreqchecked !== null ? (
                        <FieldArray
                          name="debt"
                          render={(arrayHelpers, i) => (
                            <>
                              {values.debt && values.debt.length > 0
                                ? values.debt.map((debt, index) => (
                                    <tbody key={i}>
                                      <tr key={index}>
                                        <td className="form-group col-md-4 text-sm ">
                                          <fieldset className="form-group col-md-12 text-sm mt-0 mb-3">
                                            <Field name={`debt[${index}].name`} validate={(value) =>
                                                value === null || value === undefined || value === "" ||
                                                value.length === 0 || debdupe === 0
                                                  ? "Please Select a valid Debit Ledger"
                                                  : undefined
                                              }>
                                              {({ meta }) => (
                                                <Autocomplete id="debit" className="text-sm tagSizeSmall"
                                                  name={`debt[${index}].name1`} options={ val !== null  ? val.sort(
                                                          (a, b) => -a.subname.localeCompare( b.subname )): null}
                                                  size="small" clearOnBlur={true} groupBy={(option) =>
                                                    option.ledgroupname !== null ? option.subname + " - " +
                                                        option.ledgroupname : option.subname }
                                                  getOptionLabel={(option) =>option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name }
                                                  value={ `debt[${index}].name` ? undefined : undefined}
                                                  onChange={(e, value) => {setFieldValue(`debt[${index}].name`,value );
                                                  }}
                                                  renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                     <FormikTextField style={{pt: 0,mt: 0,mb: 0,pb: 0,textsize: 1, }}
                                                        {...params}
                                                        type="text" name={`debt[${index}].name`}
                                                        label={`Debit ${index + 1 }`}
                                                        className="form-control text-sm"
                                                        error={ meta.touched && meta.error }
                                                        size="small"
                                                        onBlur={(e) =>this.debtLedgers( e.target.value )}
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        {typeofentry === 3 &&
                                        this.state.taxreqchecked === 0 ? (
                                          <td className="form-group col-md-3 text-sm">
                                            <fieldset className="form-group col-md-12 text-sm mt-0 mb-3 align-left">
                                              <Field
                                                name={`debt[${index}].value`} validate={(value) =>
                                                  value === null || value === undefined ||value === ""
                                                    ? "Debit Value Cannot be blank": undefined}>
                                                {() => (
                                                  <TextField
                                                    name={`debt[${index}].value`}
                                                    className="form-control align-right text-sm"
                                                    label="Debit Amount"
                                                    type="number"
                                                    value={debtotal + taxtotal}
                                                    style={{ innnerPadding: 0 }}
                                                    size="small"
                                                    variant="standard"
                                                    disabled
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                          </td>
                                        ) : (
                                          <td className="form-group col-md-3 text-sm">
                                            <fieldset className="form-group col-md-12 text-sm mt-0 mb-3 align-left">
                                              <Field
                                                name={`debt[${index}].value`}
                                                validate={(value) =>
                                                  value === null ||
                                                  value === undefined ||
                                                  value === ""
                                                    ? "Debit Value Cannot be blank"
                                                    : undefined
                                                }
                                              >
                                                {() => (
                                                  <FormikTextField
                                                    name={`debt[${index}].value`}
                                                    className="form-control align-right text-sm"
                                                    label="Debit Amount"
                                                    type="number"
                                                    onBlur={() =>
                                                      this.addTotal(
                                                        values,
                                                        index
                                                      )
                                                    }
                                                    style={{ innnerPadding: 0 }}
                                                    size="small"
                                                    variant="standard"
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                          </td>
                                        )}
                                        <td className="form-group col-md-4 text-sm">
                                          <fieldset
                                            className="form-group col-md-12 text-right mt-0 mb-3"
                                            hidden
                                          >
                                            
                                          </fieldset>
                                        </td>
                                        <td>
                                          {debtotal !== "" && this.state.taxreqchecked===0 &&
                                          values.debt.length > 0 &&
                                          values.debt.length < 5 ? (
                                            <>
                                              {typeofentry !== 3  && this.state.ar===false  && this.state.dr===false? (
                                                <span
                                                  as="button"
                                                  to={this}
                                                  className="fa fa-plus-square text-success"
                                                  onClick={()=>
                                                    arrayHelpers.push(" ")
                                                    // && values.debt.length > 0 && values.debt.length<5 ?this.addDebits():this.remDebits()
                                                  } // insert an empty string at a position
                                                  onChange={values.debt.length >= 1 && values.debt.length<5 ?debitadditional=1:debitadditional=0}
                                                >
                                                  
                                                  |&nbsp;
                                                </span>
                                              ) : null}
                                            </>
                                          ) : null}
                                          {values.debt.length !== 1 ? (
                                            <span
                                              as="button"
                                              to={this}
                                              className="fa fa-minus-square text-danger"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              } // remove a friend from the list
                                              // onBlur={values.debt.length < 1?debitadditional=0:debitadditional=1}
                                              onChange={values.debt.length <=2 ?debitadditional=0:""}

                                            ></span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                : null}
                            </>
                          )}
                        />
                      ) : null}
                      {/* ----------------------Debit Section End --------------------------------               */}
                      {/* ----------------------Indirect Tax Section --------------------------------               */}
                      {taxchecked === true &&
                      this.state.taxreqchecked === 1 &&
                      typeofentry === 2 && (this.state.dr!==true || this.state.dp!==true) ? (
                        <FieldArray
                          name="tax"
                          render={(arrayHelpers, i) => (
                            <>
                              {values.tax && values.tax.length > 0
                                ? values.tax.map((tax, index) => (
                                    <tbody
                                      className="form-group col-md-12 tbody-sm"
                                      key={i}
                                    >
                                      <tr key={index}>
                                        <td className="text-sm">
                                          <fieldset className="form-group col-md-12 text-sm">
                                            <Field
                                              name={`tax[${index}].name`}
                                              validate={(value) =>
                                                value === undefined ||
                                                value === null ||
                                                taxdupe === 0
                                                  ? "Please Select a valid Tax Ledger"
                                                  : undefined
                                              }
                                            >
                                              {({ meta }) => (
                                                <Autocomplete
                                                  id="tax"
                                                  className="text-sm tagSizeSmall"
                                                  name={`tax[${index}].name`}
                                                  options={
                                                    val3 !== null
                                                      ? val3.sort(
                                                          (a, b) =>
                                                            -a.subname.localeCompare(
                                                              b.subname
                                                            )
                                                        )
                                                      : null
                                                  }
                                                  size="small"
                                                  clearOnBlur={true}
                                                  groupBy={(option) =>
                                                    option.subname
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name
                                                  }
                                                  value={
                                                    `tax[${index}].name`
                                                      ? undefined
                                                      : undefined
                                                  }
                                                  onChange={(e, value) => {
                                                    setFieldValue(
                                                      `tax[${index}].name`,
                                                      value
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <div
                                                      ref={
                                                        params.InputProps.ref
                                                      }
                                                    >
                                                      <FormikTextField
                                                        style={{
                                                          pt: 0,
                                                          mt: 0,
                                                          mb: 0,
                                                          pb: 0,
                                                          textsize: 1,
                                                        }}
                                                        {...params}
                                                        type="text"
                                                        name={`tax[${index}].name`}
                                                        label={`Tax ${
                                                          index + 1
                                                        }`}
                                                        className="form-control text-sm"
                                                        error={
                                                          meta.touched &&
                                                          meta.error
                                                        }
                                                        size="small"
                                                        onBlur={(e) =>
                                                          this.taxLedgers(
                                                            e.target.value,
                                                            values.tax
                                                          )
                                                        }
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        <td className="text-sm">
                                          <fieldset className="form-group col-md-12 align-right text-sm">
                                            <Field
                                              name={`tax[${index}].percent`}
                                              validate={(value) =>
                                                value === null ||
                                                value === undefined ||
                                                value === ""
                                                  ? "Tax Percent Cannot be blank"
                                                  : undefined
                                              }
                                            >
                                              {() => (
                                                <FormikTextField
                                                  name={`tax[${index}].percent`}
                                                  className="form-control align-right text-sm"
                                                  label="Tax Percentage"
                                                  type="number"
                                                  // onBlur={() => this.addTotal(values, index)}
                                                  onBlur={(e) => {
                                                    setFieldValue(
                                                      `tax[${index}].value`,
                                                      roundTo((e.target.value *
                                                        debtotal) /
                                                        100,2)
                                                    );
                                                  }}
                                                  style={{ innnerPadding: 0 }}
                                                  size="small"
                                                  variant="standard"
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        <td className="text-sm">
                                          <fieldset className="form-group col-md-12 align-right text-sm">
                                            <Field
                                              name={`tax[${index}].value`}
                                              validate={(value) =>
                                                value === null ||
                                                value === undefined ||
                                                value === ""
                                                  ? "Tax Value Cannot be blank"
                                                  : undefined
                                              }
                                            >
                                              {() => (
                                                <FormikTextField
                                                  name={`tax[${index}].value`}
                                                  className="form-control align-right text-sm"
                                                  label={(e) =>
                                                    e.target.value !==
                                                    "Tax Amount"
                                                      ? "Tax Amount"
                                                      : null
                                                  }
                                                  type="number"
                                                  onBlur={() =>
                                                    this.addTaxTotal(
                                                      values,
                                                      index
                                                    )
                                                  }
                                                  style={{ innnerPadding: 0 }}
                                                  size="small"
                                                  variant="standard"
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        <td>
                                          {debtotal !== 0 &&
                                          values.tax.length > 0 &&
                                          values.tax.length < 2 ? (
                                            <>
                                              {typeofentry !== 3 ? (
                                                <span
                                                  as="button"
                                                  to={this}
                                                  className="fa fa-plus-square text-success"
                                                  onClick={() =>
                                                    arrayHelpers.push(" ")
                                                  }
                                                >
                                                  
                                                  |&nbsp;
                                                </span>
                                              ) : null}
                                            </>
                                          ) : null}
                                          {values.tax.length !== 1 ? (
                                            <span
                                              as="button"
                                              to={this}
                                              className="fa fa-minus-square text-danger"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            ></span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                : null}
                            </>
                          )}
                        />
                      ) : null}
                      {/* ----------------------End of Indirect Tax Section --------------------------------               */}
                      {/* <tbody> */}
                        {/* <FieldArray
                          name="cred"
                          render={(arrayHelpers, i) => (
                           
                          )}
                        /> */}

<FieldArray
                          name="cred"
                          render={(arrayHelpers, i) => (
                         <>
                              {values.cred && values.cred.length > 0
                                ? values.cred.map((cred, index) => (
                                    <>
                                      {/* ----------------------Direct Tax Section --------------------------------               */}
                                      {typeofentry === 2 &&
                                      tcschecked !== 0 &&
                                      this.state.taxreqchecked === 1 ? (
                                        <>
                                          <tbody
                                             className="form-group col-md-12 tbody-sm"
                                             key={i} 
                                          >
                                            <tr key={index}>
                                              <td className="text-sm">
                                                <fieldset className="form-group col-md-12 align-right text-sm mt-0 mb-0">
                                                  <Field
                                                    name={`dtax[${index}].name`}
                                                    validate={(value) =>
                                                      value === undefined ||
                                                      value === null
                                                        ? "Please Select a valid Tax Ledger"
                                                        : undefined
                                                    }
                                                  >
                                                    {({ meta }) => (
                                                      <Autocomplete
                                                        id="dtax"
                                                        className="text-sm tagSizeSmall"
                                                        name={`dtax[${index}].name`}
                                                        options={
                                                          tcsdata !== null
                                                            ? tcsdata.sort(
                                                                (a, b) =>
                                                                  -a.subname.localeCompare(
                                                                    b.subname
                                                                  )
                                                              )
                                                            : null
                                                        }
                                                        size="small"
                                                        clearOnBlur={true}
                                                        groupBy={(option) =>
                                                          option.subname
                                                        }
                                                        getOptionLabel={(
                                                          option
                                                        ) => option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name}
                                                        value={
                                                          `dtax[${index}].name`
                                                            ? undefined
                                                            : undefined
                                                        }
                                                        onChange={(
                                                          e,
                                                          value
                                                        ) => {
                                                          setFieldValue(
                                                            `dtax[${index}].name`,
                                                            value
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <div
                                                            ref={
                                                              params.InputProps
                                                                .ref
                                                            }
                                                          >
                                                            <FormikTextField
                                                              style={{
                                                                pt: 0,
                                                                mt: 0,
                                                                mb: 0,
                                                                pb: 0,
                                                                textsize: 1,
                                                              }}
                                                              {...params}
                                                              type="text"
                                                              name={`dtax[${index}].name`}
                                                              label={"TDS "}
                                                              className="text-sm"
                                                              error={
                                                                meta.touched &&
                                                                meta.error
                                                              }
                                                              size="small"
                                                              onBlur={(e) =>
                                                                this.taxLedgers(
                                                                  e.target
                                                                    .value,
                                                                  values.tax
                                                                )
                                                              }
                                                              variant="standard"
                                                              fullWidth
                                                            />
                                                          </div>
                                                        )}
                                                      />
                                                    )}
                                                  </Field>
                                                </fieldset>
                                              </td>
                                              {/* <td></td> */}
                                              <td>
                                                <td className="text-sm">
                                                  <fieldset className="form-group col-md-12 align-right text-sm mt-0 mb-0" fullWidth>
                                                    <Field
                                                      name={`dtax[${index}].percent`}
                                                      validate={(value) =>
                                                        value === null ||
                                                        value === undefined ||
                                                        value === ""
                                                          ? "Tax Percent Cannot be blank"
                                                          : undefined
                                                      }
                                                    >
                                                      {() => (
                                                        <FormikTextField
                                                          name={`dtax[${index}].percent`}
                                                          className="align-right text-sm"
                                                          label="Tax Percentage"
                                                          type="number"
                                                          onBlur={(e) => {
                                                            setFieldValue(
                                                              `dtax[${index}].number`,
                                                              index
                                                            ) &&
                                                              setFieldValue(
                                                                `dtax[${index}].value`,
                                                                (e.target
                                                                  .value *
                                                                  (this.state
                                                                    .debtotal )) /
                                                                  100
                                                              );
                                                          }}
                                                          style={{
                                                            innnerPadding: 0,
                                                          }}
                                                          size="small"
                                                          variant="standard"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </fieldset>
                                                </td>
                                                <fieldset
                                                  className="form-group col-md-12 text-right mt-0 mb-1"
                                                  hidden
                                                >
                                                  <TextField
                                                    name={`dtax[${index}].number`}
                                                    // className="form-control align-right"
                                                    type="number"
                                                    size="small"
                                                    variant="standard"
                                                    fullWidth
                                                  />
                                                </fieldset>
                                                <td className="text-sm">
                                                  <fieldset className="form-group col-md-12 align-right text-sm mt-0 mb-0">
                                                    <Field
                                                      name={`dtax[${index}].value`}
                                                      validate={(value) =>
                                                        value === null ||
                                                        value === undefined ||
                                                        value === ""
                                                          ? "Tax Value Cannot be blank"
                                                          : undefined
                                                      }
                                                    >
                                                      {() => (
                                                        <FormikTextField
                                                          name={`dtax[${index}].value`}
                                                          // className="form-control align-right text-sm"
                                                          label={(e) =>
                                                            e.target.value !==
                                                            "Tax Amount"
                                                              ? "Tax Amount"
                                                              : null
                                                          }
                                                          type="number"
                                                          onBlur={() =>
                                                            this.addDirTaxTotal(
                                                              values,
                                                              index
                                                            )
                                                          }
                                                          style={{
                                                            innnerPadding: 0,
                                                          }}
                                                          size="small"
                                                          variant="standard"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </fieldset>
                                                </td>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      ) : null}
                                      {/* ----------------------Direct Tax Section End--------------------------------               */}
                                      {/* ----------------------Credit Section --------------------------------               */}
 {/* <tbody> 
                        <FieldArray
                          name="cred"
                          render={(arrayHelpers, i) => (
                           
                          )}
                        /> */}
                                      {this.state.taxreqchecked === 0 ||
                                     this.state.taxreqchecked === 1 ? (
                                        <tr key={index} className="mt-0 mt-0">
                                          <td className="form-group col-md-4 text-sm">
                                            <fieldset className="form-group col-md-12 mt-0 mb-1">
                                              <Field
                                                name={`cred[${index}].name`}
                                                validate={(value) =>
                                                  value === null ||
                                                  value === undefined ||
                                                  value === "" ||
                                                  value.length === 0 ||
                                                  credupe === 0
                                                    ? "Please Select a valid Credit Ledger"
                                                    : undefined
                                                }
                                              >
                                                {({ meta }) => (
                                                  <Autocomplete
                                                    id="credit"
                                                    className="credit-select"
                                                    name="acredit"
                                                    clearOnBlur={true}
                                                    options={
                                                      val2 !== undefined
                                                        ? val2.sort(
                                                            (a, b) =>
                                                              -a.subname.localeCompare(
                                                                b.subname
                                                              )
                                                          )
                                                        : null
                                                    }
                                                    groupBy={(option) =>
                                                      option.ledgroupname !==
                                                      null
                                                        ? option.subname +
                                                          " - " +
                                                          option.ledgroupname
                                                        : option.subname
                                                    }
                                                    getOptionLabel={(option) =>
                                                      option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name
                                                    }
                                                    value={
                                                      `cred[${index}].name`
                                                        ? undefined
                                                        : undefined
                                                    }
                                                    onChange={(e, value) => {
                                                      setFieldValue(
                                                        `cred[${index}].name`,
                                                        value
                                                      );
                                                    }}
                                                    renderInput={(params) => (
                                                      <FormikTextField
                                                        {...params}
                                                        type="text"
                                                        name={`cred[${index}].name`}
                                                        label={`Credit ${
                                                          index + 1
                                                        }`}
                                                        className="form-control"
                                                        error={
                                                          meta.touched &&
                                                          meta.error
                                                        }
                                                        size="small"
                                                        onBlur={(e) =>
                                                          setFieldValue(
                                                            `cred[${index}].value`,
                                                            this.state
                                                              .debtotal +
                                                              this.state
                                                                .taxtotal -
                                                              this.state
                                                                .dtaxtotal
                                                          ) &&
                                                          this.credLedgers(
                                                            e.target.value
                                                          )
                                                        }
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    )}
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                          </td>
                                          <td className="form-group col-md-3 text-sm">
                                            <fieldset
                                              className="form-group col-md-12 align-right mt-0 mb-1"
                                              hidden
                                            >
                                              <FormikTextField
                                                name="null"
                                                className="form-control"
                                                label="Credit Amount"
                                                type="number"
                                                variant="standard"
                                              />
                                            </fieldset>
                                          </td>
                                          <td className="form-group col-md-4 text-sm">
                                            <fieldset className="form-group col-md-10 text-right mt-0 mb-1">
                                              <Field
                                                name={`cred[${index}].value`}
                                                validate={(value) =>
                                                  value === null ||
                                                  value === undefined ||
                                                  value === ""
                                                    ? "Please enter Credit Value"
                                                    : undefined
                                                }
                                              >
                                                {() => (
                                                  <FormikTextField
                                                    name={`cred[${index}].value`}
                                                    className="form-control align-right"
                                                    label={
                                                      `cred[${index}].value` ===
                                                      null
                                                        ? "Credit Amount"
                                                        : null
                                                    }
                                                    type="number"
                                                    onBlur={(e) => {
                                                      setFieldValue(
                                                        `cred[${index}].number`,
                                                        index
                                                      ) &&
                                                        this.addCTotal(
                                                          values,
                                                          index,
                                                          e.target.value
                                                        );
                                                    }}
                                                    size="small"
                                                    variant="standard"
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                            <fieldset
                                              className="form-group col-md-10 text-right mt-0 mb-1"
                                              hidden
                                            >
                                              <TextField
                                                name={`cred[${index}].number`}
                                                className="form-control align-right"
                                                type="number"
                                                size="small"
                                                variant="standard"
                                              />
                                            </fieldset>
                                          </td>
                                          {this.state.tcschecked === 0 ? (
                                            <>
                                              <td>
                                                {debtotal!=="" &&
                                                values.cred.length > 0 &&
                                                values.cred.length < 5 ? (
                                                  <>
                                                    {typeofentry === 2 && this.state.ap===false && this.state.dp===false? (
                                                      <span
                                                        as="button"
                                                        to={this}
                                                        className="fa fa-plus-square text-success"
                                                        onClick={() =>
                                                          arrayHelpers.push(" ")
                                                        }
                                                        // onBlur={values.cred.length !==1?creditadditional=1:creditadditional=0}
                                                      >
                                                        
                                                        |&nbsp;
                                                      </span>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                                {values.cred.length !== 1 ? (
                                                  <span
                                                    as="button"
                                                    to={this}
                                                    className="fa fa-minus-square text-danger"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                    onChange={values.debt.length <2 ?debitadditional=0:""}

                                                  ></span>
                                                ) : null}
                                              </td>
                                            </>
                                          ) : null}
                                        </tr>
                                      ) : null}
                                    </>
                                  ))
                                : null}
                            </>
                        )}/>     
                   {/* </tbody> */}
                    </>
                   
                    {/* ----------------------Credit Section End --------------------------------               */}
                    {/* ----------------------Bottom Totals Section--------------------------------               */}
                    {/* ----------------------Bottom Totals Section end--------------------------------               */}
                    {/* ----------------------Expenses Mapping Section--------------------------------               */}
                    {typeofentry === 3 ? (
                      <tbody>
                        <tr>
                          <td>
                            <fieldset className="form-group col-md-12">
                              <Field
                                name={"expense"}
                                validate={(value) =>
                                  value === null ||
                                  value === undefined ||
                                  value === "" ||
                                  value.length === 0 ||
                                  expdupe === 0
                                    ? "Please Select a valid Expense Ledger"
                                    : undefined
                                }
                              >
                                {({ meta }) => (
                                  <Autocomplete
                                    id="expense"
                                    className="debit-select"
                                    name="expense"
                                    value={"expense" ? undefined : undefined}
                                    options={expval.sort(
                                      (a, b) =>
                                        -a.subname.localeCompare(b.subname)
                                    )}
                                    groupBy={(option) => option.subname}
                                    getOptionLabel={(option) => option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name}
                                    onChange={(e, value) => {
                                      setFieldValue("expense", value);
                                    }}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        type="text"
                                        name="expense"
                                        label="Map Expense"
                                        className="form-control"
                                        error={meta.touched && meta.error}
                                        size="small"
                                        onBlur={(e) =>
                                          this.expLedgers(e.target.value)
                                        }
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}
                              </Field>
                            </fieldset>
                          </td>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>:
                  <table id="jou" className="table table-sm table-borderless">
                    <FieldArray
                          name="cred"
                          render={(arrayHelpers, i) => (
                         <>
                              {values.cred && values.cred.length > 0
                                ? values.cred.map((cred, index) => (
                                    <>
                                     
 {/* <tbody> 
                        <FieldArray
                          name="cred"
                          render={(arrayHelpers, i) => (
                           
                          )}
                        /> */}
                                      {this.state.taxreqchecked === 0 ||
                                     this.state.taxreqchecked === 1 ? (
                                        <tr key={index} className="mt-0 mt-0">
                                          <td className="form-group col-md-4 text-sm">
                                            <fieldset className="form-group col-md-12 mt-0 mb-1">
                                              <Field
                                                name={`cred[${index}].name`}
                                                validate={(value) =>
                                                  value === null ||
                                                  value === undefined ||
                                                  value === "" ||
                                                  value.length === 0 ||
                                                  credupe === 0
                                                    ? "Please Select a valid Credit Ledger"
                                                    : undefined
                                                }
                                              >
                                                {({ meta }) => (
                                                  <Autocomplete
                                                    id="credit"
                                                    className="credit-select"
                                                    name="acredit"
                                                    clearOnBlur={true}
                                                    options={
                                                      val2 !== undefined
                                                        ? val2.sort(
                                                            (a, b) =>
                                                              -a.subname.localeCompare(
                                                                b.subname
                                                              )
                                                          )
                                                        : null
                                                    }
                                                    groupBy={(option) =>
                                                      option.ledgroupname !==
                                                      null
                                                        ? option.subname +
                                                          " - " +
                                                          option.ledgroupname
                                                        : option.subname
                                                    }
                                                    getOptionLabel={(option) =>
                                                      option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name
                                                    }
                                                    value={
                                                      `cred[${index}].name`
                                                        ? undefined
                                                        : undefined
                                                    }
                                                    onChange={(e, value) => {
                                                      setFieldValue(
                                                        `cred[${index}].name`,
                                                        value
                                                      );
                                                    }}
                                                    renderInput={(params) => (
                                                      <FormikTextField
                                                        {...params}
                                                        type="text"
                                                        name={`cred[${index}].name`}
                                                        label={`Credit ${
                                                          index + 1
                                                        }`}
                                                        className="form-control"
                                                        error={
                                                          meta.touched &&
                                                          meta.error
                                                        }
                                                        size="small"
                                                        onBlur={(e) =>
                                                          this.credLedgers(
                                                            e.target.value
                                                          )
                                                        }
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    )}
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                          </td>
                                          <td className="form-group col-md-3 text-sm">
                                            <fieldset
                                              className="form-group col-md-12 align-right mt-0 mb-1"
                                              hidden
                                            >
                                              <FormikTextField
                                                name="null"
                                                className="form-control"
                                                label="Credit Amount"
                                                type="number"
                                                variant="standard"
                                              />
                                            </fieldset>
                                          </td>
                                          <td className="form-group col-md-4 text-sm">
                                            <fieldset className="form-group col-md-10 text-right mt-0 mb-1">
                                              <Field
                                                name={`cred[${index}].value`}
                                                validate={(value) =>
                                                  value === null ||
                                                  value === undefined ||
                                                  value === ""
                                                    ? "Please enter Credit Value"
                                                    : undefined
                                                }
                                              >
                                                {() => (
                                                  <FormikTextField
                                                    name={`cred[${index}].value`}
                                                    className="form-control align-right"
                                                    label={
                                                      !this.target//`cred[${index}].value`
                                                      
                                                        ? "Credit Amount"
                                                        : null
                                                    }
                                                    type="number"
                                                    onBlur={(e) => {
                                                      setFieldValue(
                                                        `cred[${index}].number`,
                                                        index
                                                      ) &&
                                                        this.addCTotal(
                                                          values,
                                                          index,
                                                          e.target.value
                                                        );
                                                    }}
                                                    size="small"
                                                    variant="standard"
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                            <fieldset
                                              className="form-group col-md-10 text-right mt-0 mb-1"
                                              hidden
                                            >
                                              <TextField
                                                name={`cred[${index}].number`}
                                                className="form-control align-right"
                                                type="number"
                                                size="small"
                                                variant="standard"
                                              />
                                            </fieldset>
                                          </td>
                                          {this.state.tcschecked === 0 ? (
                                            <>
                                              <td>
                                                {debtotal!=="" &&
                                                values.cred.length > 0 &&
                                                values.cred.length < 5 ? (
                                                  <>
                                                    {typeofentry === 2 && this.state.ap===false && this.state.dp===false? (
                                                      <span
                                                        as="button"
                                                        to={this}
                                                        className="fa fa-plus-square text-success"
                                                        onClick={() =>
                                                          arrayHelpers.push(" ")
                                                        }
                                                        // onBlur={values.cred.length !==1?creditadditional=1:creditadditional=0}
                                                      >
                                                        
                                                        |&nbsp;
                                                      </span>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                                {values.cred.length !== 1 ? (
                                                  <span
                                                    as="button"
                                                    to={this}
                                                    className="fa fa-minus-square text-danger"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                    onChange={values.debt.length <2 ?debitadditional=0:""}

                                                  ></span>
                                                ) : null}
                                              </td>
                                            </>
                                          ) : null}
                                        </tr>
                                      ) : null}

 {/* ----------------------Indirect Tax Section --------------------------------               */}
               {taxchecked === true &&
                      this.state.taxreqchecked === 1 &&
                      typeofentry === 2 ? (
                        <FieldArray
                          name="tax"
                          render={(arrayHelpers, i) => (
                            <>
                              {values.tax && values.tax.length > 0
                                ? values.tax.map((tax, index) => (
                                    <tbody
                                      className="form-group col-md-12 tbody-sm"
                                      key={i}
                                    >
                                      <tr key={index}>
                                        <td className="text-sm">
                                          <fieldset className="form-group col-md-12 text-sm">
                                            <Field
                                              name={`tax[${index}].name`}
                                              validate={(value) =>
                                                value === undefined ||
                                                value === null ||
                                                taxdupe === 0
                                                  ? "Please Select a valid Tax Ledger"
                                                  : undefined
                                              }
                                            >
                                              {({ meta }) => (
                                                <Autocomplete
                                                  id="tax"
                                                  className="text-sm tagSizeSmall"
                                                  name={`tax[${index}].name`}
                                                  options={
                                                    val3 !== null
                                                      ? val3.sort(
                                                          (a, b) =>
                                                            -a.subname.localeCompare(
                                                              b.subname
                                                            )
                                                        )
                                                      : null
                                                  }
                                                  size="small"
                                                  clearOnBlur={true}
                                                  groupBy={(option) =>
                                                    option.subname
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name
                                                  }
                                                  value={
                                                    `tax[${index}].name`
                                                      ? undefined
                                                      : undefined
                                                  }
                                                  onChange={(e, value) => {
                                                    setFieldValue(
                                                      `tax[${index}].name`,
                                                      value
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <div
                                                      ref={
                                                        params.InputProps.ref
                                                      }
                                                    >
                                                      <FormikTextField
                                                        style={{
                                                          pt: 0,
                                                          mt: 0,
                                                          mb: 0,
                                                          pb: 0,
                                                          textsize: 1,
                                                        }}
                                                        {...params}
                                                        type="text"
                                                        name={`tax[${index}].name`}
                                                        label={`Tax ${
                                                          index + 1
                                                        }`}
                                                        className="form-control text-sm"
                                                        error={
                                                          meta.touched &&
                                                          meta.error
                                                        }
                                                        size="small"
                                                        onBlur={(e) =>
                                                          this.taxLedgers(
                                                            e.target.value,
                                                            values.tax
                                                          )
                                                        }
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        <td className="text-sm">
                                          <fieldset className="form-group col-md-12 align-right text-sm">
                                            <Field
                                              name={`tax[${index}].percent`}
                                              validate={(value) =>
                                                value === null ||
                                                value === undefined ||
                                                value === ""
                                                  ? "Tax Percent Cannot be blank"
                                                  : undefined
                                              }
                                            >
                                              {() => (
                                                <FormikTextField
                                                  name={`tax[${index}].percent`}
                                                  className="form-control align-right text-sm"
                                                  label="Tax Percentage"
                                                  type="number"
                                                  // onBlur={() => this.addTotal(values, index)}
                                                  onBlur={(e) => {
                                                    setFieldValue(
                                                      `tax[${index}].value`,
                                                      (e.target.value *
                                                        cretotal) /
                                                        100
                                                    );
                                                  }}
                                                  style={{ innnerPadding: 0 }}
                                                  size="small"
                                                  variant="standard"
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        <td className="text-sm">
                                          <fieldset className="form-group col-md-12 align-right text-sm">
                                            <Field
                                              name={`tax[${index}].value`}
                                              validate={(value) =>
                                                value === null ||
                                                value === undefined ||
                                                value === ""
                                                  ? "Tax Value Cannot be blank"
                                                  : undefined
                                              }
                                            >
                                              {() => (
                                                <FormikTextField
                                                  name={`tax[${index}].value`}
                                                  className="form-control align-right text-sm"
                                                  label={(e)=>
                                                    !e.target.value
                                                      ? "Tax Amount"
                                                      : null
                                                  }
                                                  type="number"
                                                  onBlur={() =>
                                                    this.addTaxTotal(
                                                      values,
                                                      index
                                                    )
                                                  }
                                                  style={{ innnerPadding: 0 }}
                                                  size="small"
                                                  variant="standard"
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        <td>
                                          {debtotal !== 0 &&
                                          values.tax.length > 0 &&
                                          values.tax.length < 2 ? (
                                            <>
                                              {typeofentry !== 3 ? (
                                                <span
                                                  as="button"
                                                  to={this}
                                                  className="fa fa-plus-square text-success"
                                                  onClick={() =>
                                                    arrayHelpers.push(" ")
                                                  }
                                                >
                                                  
                                                  |&nbsp;
                                                </span>
                                              ) : null}
                                            </>
                                          ) : null}
                                          {values.tax.length !== 1 ? (
                                            <span
                                              as="button"
                                              to={this}
                                              className="fa fa-minus-square text-danger"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            ></span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                : null}
                            </>
                          )}
                        />
                      ) : null}
            {/* ----------------------End of Indirect Tax Section --------------------------------               */}

 {/* ----------------------Direct Tax Section --------------------------------               */}
                                     {typeofentry === 2 &&
                                      tcschecked !== 0 &&
                                      this.state.taxreqchecked === 1 ? (
                                        <>
                                          <tbody
                                             className="form-group col-md-12 tbody-sm"
                                            // key={i} 
                                          >
                                            <tr key={index}>
                                              <td className="text-sm">
                                                <fieldset className="form-group col-md-12 align-right text-sm mt-0 mb-0">
                                                  <Field
                                                    name={`dtax[${index}].name`}
                                                    validate={(value) =>
                                                      value === undefined ||
                                                      value === null
                                                        ? "Please Select a valid Tax Ledger"
                                                        : undefined
                                                    }
                                                  >
                                                    {({ meta }) => (
                                                      <Autocomplete
                                                        id="dtax"
                                                        className="text-sm tagSizeSmall"
                                                        name={`dtax[${index}].name`}
                                                        options={
                                                          tcsdata !== null
                                                            ? tcsdata.sort(
                                                                (a, b) =>
                                                                  -a.subname.localeCompare(
                                                                    b.subname
                                                                  )
                                                              )
                                                            : null
                                                        }
                                                        size="small"
                                                        clearOnBlur={true}
                                                        groupBy={(option) =>
                                                          option.subname
                                                        }
                                                        getOptionLabel={(
                                                          option
                                                        ) => option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name}
                                                        value={
                                                          `dtax[${index}].name`
                                                            ? undefined
                                                            : undefined
                                                        }
                                                        onChange={(
                                                          e,
                                                          value
                                                        ) => {
                                                          setFieldValue(
                                                            `dtax[${index}].name`,
                                                            value
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <div
                                                            ref={
                                                              params.InputProps
                                                                .ref
                                                            }
                                                          >
                                                            <FormikTextField
                                                              style={{
                                                                pt: 0,
                                                                mt: 0,
                                                                mb: 0,
                                                                pb: 0,
                                                                textsize: 1,
                                                              }}
                                                              {...params}
                                                              type="text"
                                                              name={`dtax[${index}].name`}
                                                              label={"TDS Asset"}
                                                              className="text-sm"
                                                              error={
                                                                meta.touched &&
                                                                meta.error
                                                              }
                                                              size="small"
                                                              onBlur={(e) =>
                                                                this.taxLedgers(
                                                                  e.target
                                                                    .value,
                                                                  values.tax
                                                                )
                                                              }
                                                              variant="standard"
                                                              fullWidth
                                                            />
                                                          </div>
                                                        )}
                                                      />
                                                    )}
                                                  </Field>
                                                </fieldset>
                                              </td>
                                              {/* <td></td> */}
                                              <td>
                                                <td className="text-sm">
                                                  <fieldset className="form-group col-md-12 align-right text-sm mt-0 mb-0" fullWidth>
                                                    <Field
                                                      name={`dtax[${index}].percent`}
                                                      validate={(value) =>
                                                        value === null ||
                                                        value === undefined ||
                                                        value === ""
                                                          ? "Tax Percent Cannot be blank"
                                                          : undefined
                                                      }
                                                    >
                                                      {() => (
                                                        <FormikTextField
                                                          name={`dtax[${index}].percent`}
                                                          className="align-right text-sm"
                                                          label="Tax Percentage"
                                                          type="number"
                                                          onBlur={(e) => {
                                                            setFieldValue(
                                                              `dtax[${index}].number`,
                                                              index
                                                            ) &&
                                                              setFieldValue(
                                                                `dtax[${index}].value`,
                                                                (e.target
                                                                  .value *
                                                                  (this.state
                                                                    .cretotal )) /
                                                                  100
                                                              );
                                                          }}
                                                          style={{
                                                            innnerPadding: 0,
                                                          }}
                                                          size="small"
                                                          variant="standard"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </fieldset>
                                                </td>
                                                <fieldset
                                                  className="form-group col-md-12 text-right mt-0 mb-1"
                                                  hidden
                                                >
                                                  <TextField
                                                    name={`dtax[${index}].number`}
                                                    // className="form-control align-right"
                                                    type="number"
                                                    size="small"
                                                    variant="standard"
                                                    fullWidth
                                                  />
                                                </fieldset>
                                                <td className="text-sm">
                                                  <fieldset className="form-group col-md-12 align-right text-sm mt-0 mb-0">
                                                    <Field
                                                      name={`dtax[${index}].value`}
                                                      validate={(value) =>
                                                        value === null ||
                                                        value === undefined ||
                                                        value === ""
                                                          ? "Tax Value Cannot be blank"
                                                          : undefined
                                                      }
                                                    >
                                                      {() => (
                                                        <FormikTextField
                                                          name={`dtax[${index}].value`}
                                                          // className="form-control align-right text-sm"
                                                          label={(e) =>
                                                            e.target.value !==
                                                            "Tax Amount"
                                                              ? "Tax Amount"
                                                              : null
                                                          }
                                                          type="number"
                                                          onBlur={() =>
                                                            this.addDirTaxTotal(
                                                              values,
                                                              index
                                                            )
                                                          }
                                                          style={{
                                                            innnerPadding: 0,
                                                          }}
                                                          size="small"
                                                          variant="standard"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </fieldset>
                                                </td>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      ) : null}
  {/* ----------------------Direct Tax Section End--------------------------------               */}

    {/* ----------------------Debit Section Start--------------------------------               */}


  {this.state.taxreqchecked !== null ? (
                        <FieldArray
                          name="debt"
                          render={(arrayHelpers, i) => (
                            <>
                              {values.debt && values.debt.length > 0
                                ? values.debt.map((debt, index) => (
                                    <tbody key={i}>
                                      <tr key={index}>
                                        <td className="form-group col-md-4 text-sm ">
                                          <fieldset className="form-group col-md-12 text-sm mt-0 mb-3">
                                            <Field name={`debt[${index}].name`} validate={(value) =>
                                                value === null || value === undefined || value === "" ||
                                                value.length === 0 || debdupe === 0
                                                  ? "Please Select a valid Debit Ledger"
                                                  : undefined
                                              }>
                                              {({ meta }) => (
                                                <Autocomplete id="debit" className="text-sm tagSizeSmall"
                                                  name={`debt[${index}].name1`} options={ val !== null  ? val.sort(
                                                          (a, b) => -a.subname.localeCompare( b.subname )): null}
                                                  size="small" clearOnBlur={true} groupBy={(option) =>
                                                    option.ledgroupname !== null ? option.subname + " - " +
                                                        option.ledgroupname : option.subname }
                                                  getOptionLabel={(option) =>option.ledgercode!=null?option.name + " [" + option.ledgercode + "]":option.name }
                                                  value={ `debt[${index}].name` ? undefined : undefined}
                                                  onChange={(e, value) => {setFieldValue(`debt[${index}].name`,value );
                                                  }}
                                                  renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                     <FormikTextField style={{pt: 0,mt: 0,mb: 0,pb: 0,textsize: 1, }}
                                                        {...params}
                                                        type="text" name={`debt[${index}].name`}
                                                        label={`Debit ${index + 1 }`}
                                                        className="form-control text-sm"
                                                        error={ meta.touched && meta.error }
                                                        size="small"
                                                        onBlur={(e) =>    setFieldValue(
                                                          `debt[${index}].value`,
                                                          (this.state
                                                            .cretotal +  this.state
                                                            .taxtotal) -
                                                            this.state
                                                              .dtaxtotal
                                                        ) 
                                                        &&
                                                          this.debtLedgers( e.target.value )}
                                                        variant="standard"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              )}
                                            </Field>
                                          </fieldset>
                                        </td>
                                        {typeofentry === 3 &&
                                        this.state.taxreqchecked === 0 ? (
                                          <td className="form-group col-md-3 text-sm">
                                            <fieldset className="form-group col-md-12 text-sm mt-0 mb-3 align-left">
                                              <Field
                                                name={`debt[${index}].value`} validate={(value) =>
                                                  value === null || value === undefined ||value === ""
                                                    ? "Debit Value Cannot be blank": undefined}>
                                                {() => (
                                                  <TextField
                                                    name={`debt[${index}].value`}
                                                    className="form-control align-right text-sm"
                                                    label="Debit Amount"
                                                    type="number"
                                                    value={debtotal + taxtotal}
                                                    style={{ innnerPadding: 0 }}
                                                    size="small"
                                                    variant="standard"
                                                    disabled
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                          </td>
                                        ) : (
                                          <td className="form-group col-md-3 text-sm">
                                            <fieldset className="form-group col-md-12 text-sm mt-0 mb-3 align-left">
                                              <Field
                                                name={`debt[${index}].value`}
                                                validate={(value) =>
                                                  value === null ||
                                                  value === undefined ||
                                                  value === ""
                                                    ? "Debit Value Cannot be blank"
                                                    : undefined
                                                }
                                              >
                                                {() => (
                                                  <FormikTextField
                                                    name={`debt[${index}].value`}
                                                    className="form-control align-right text-sm"
                                                    label={(e)=>
                                                    e===!undefined
                                                        ? "Debit Amount"
                                                        : null
                                                    }
                                                    type="number"
                                                    onBlur={() =>
                                                      this.addTotal(
                                                        values,
                                                        index
                                                      )
                                                    }
                                                    style={{ innnerPadding: 0 }}
                                                    size="small"
                                                    variant="standard"
                                                  />
                                                )}
                                              </Field>
                                            </fieldset>
                                          </td>
                                        )}
                                        <td className="form-group col-md-4 text-sm">
                                          <fieldset
                                            className="form-group col-md-12 text-right mt-0 mb-3"
                                            hidden
                                          >
                                            
                                          </fieldset>
                                        </td>
                                        <td>
                                          {debtotal !== "" && this.state.taxreqchecked===0 &&
                                          values.debt.length > 0 &&
                                          values.debt.length < 5 ? (
                                            <>
                                              {typeofentry !== 3  && this.state.ar===false  && this.state.dr===false? (
                                                <span
                                                  as="button"
                                                  to={this}
                                                  className="fa fa-plus-square text-success"
                                                  onClick={()=>
                                                    arrayHelpers.push(" ")
                                                    // && values.debt.length > 0 && values.debt.length<5 ?this.addDebits():this.remDebits()
                                                  } // insert an empty string at a position
                                                  onChange={values.debt.length >= 1 && values.debt.length<5 ?debitadditional=1:debitadditional=0}
                                                >
                                                  
                                                  |&nbsp;
                                                </span>
                                              ) : null}
                                            </>
                                          ) : null}
                                          {values.debt.length !== 1 ? (
                                            <span
                                              as="button"
                                              to={this}
                                              className="fa fa-minus-square text-danger"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              } // remove a friend from the list
                                              // onBlur={values.debt.length < 1?debitadditional=0:debitadditional=1}
                                              onChange={values.debt.length <=2 ?debitadditional=0:""}

                                            ></span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                : null}
                            </>
                          )}
                        />
                      ) : null}
                      {/* ----------------------Debit Section End --------------------------------               */}

                                    </>
                                  ))
                                : null}
                            </>
                        )}/> 


                  </table>
                  }
                  {this.state.lt!==true?<>
                  <fieldset className="col-md-4 text-right mt-3 mb-0">
                    Direct Taxes 
                  </fieldset>
                  <fieldset className="form-control col-md-3 text-right mt-3 mb-0"></fieldset>
                  <fieldset className="form-control col-md-4 text-right mt-3 mb-0">
                    <NumericFormat
                      name="debittotal"
                      className="text-right"
                      value={dtaxtotal}
                      displayType={"text"}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </fieldset>
                  <fieldset className="col-md-4 text-right mt-0 mb-0">
                    Indirect Taxes 
                  </fieldset> 
                  <fieldset className="form-control col-md-3 text-right mt-0 mb-1"></fieldset> 
                   <fieldset className="form-control col-md-4 text-right mt-0 mb-1">
                    <NumericFormat
                      name="credittotal"
                      className="text-right"
                      value={taxtotal}
                      displayType={"text"}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </fieldset></>:null}
                  <fieldset className="col-md-4 text-right mt-0 mb-0">
                  Debit Total
                  </fieldset>
                  <fieldset className="form-control col-md-3 text-right mt-0 mb-0">
                    {/* <NumericFormat
                      name="debittotal"
                      className="text-right"
                      value={debtotal}
                      displayType={"text"}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat> */}
                  </fieldset>
                  <fieldset className="form-control col-md-4 text-right mt-0 mb-0">
                    <NumericFormat
                      name="debittotal"
                      className="text-right"
                      value={this.state.ar===true || this.state.dr===true?(cretotal+taxtotal)-dtaxtotal:debtotal}
                      displayType={"text"}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </fieldset>
                  <fieldset className="col-md-4 text-right mt-0 mb-0">
                  Credit Total
                  </fieldset>
                  <fieldset className="form-control col-md-3 text-right mt-0 mb-0">
                    {/* <NumericFormat
                      name="debittotal"
                      className="text-right"
                      value={this.state.ar || this.state.dr?cretotal+taxtotal:debtotal+taxtotal}
                      displayType={"text"}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat> */}
                  </fieldset>
                  <fieldset className="form-control col-md-4 text-right mt-0 mb-0">
                    <NumericFormat
                      name="credittotal"
                      className="text-right"
                      //value={this.state.ar || this.state.dr?cretotal+taxtotal:cretotal+taxtotal}
                      value={this.state.ar===true || this.state.dr===true?(debtotal+taxtotal)-dtaxtotal:cretotal}
                      displayType={"text"}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </fieldset>
                  {/* ----------------------End of Expenses Mapping Section--------------------------------               */}
                  {/* ----------------------Narration and buttons Section--------------------------------               */}
                  <fieldset className="form-group col-md-12">
                    <FormikTextField
                      name="narration"
                      className="form-control"
                      type="text"
                      label="Narration"
                      variant="filled"
                    />
                  </fieldset>
                </div>
              ) : null}
              <fieldset className="form-group col-md-12"></fieldset>
              <div>
                
                <fieldset className="form-group col-md-2">
                  <button
                    className="btn hovbuttonColour mr-1 btn-sm"
                    type="submit"
                  >
                    <i className="fas fa-pen"></i>
                    {this.state.loader === true ? (
                      <>
                        <Spinner animation="border" size="sm" />
                        &nbsp;{"Submitting"}
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </fieldset>
              </div>
              <fieldset className="form-group col-md-12"></fieldset>
              <div>
                <fieldset className="form-group col-md-12">
                  <label className="text-red">
                    Note : If any selected ledger is wrong or with invalid
                    chacters, please cancel the selection with "x" button in
                    selection and reselect the ledger.
                  </label>
                </fieldset>
              </div>
            </Form>
            
          )}
        </Formik>
      </div>
    );
  }
}
export default withRouter(CommonEntry);
