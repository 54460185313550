import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { currentTenant, thousandsdisplay } from '../../Common/Credentials.js';
import { Spinner, ThemeProvider } from 'react-bootstrap';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { spin } from '../../Common/NewLoader.jsx';
import Footer from '../../Component/Footer.js';
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material";
import NewLedgerService from "../Ledgers/NewLedgerService.js";
import { Autocomplete } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import moment from "moment";
import { id } from "date-fns/locale";
import NewJournalService from "../NewJournalService.js";



export default function LedgersTaxes(props){
  const history = useLocation();
  const [loader] = useState(false);
  const [ledger, setLedgers] = useState([]);
  const [selectedLed, setselectedLed] = useState([]);
  const [selectedledger, setSelectedledger] = useState('');

  
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e9ecef !important",
          border: [[1, "solid", "#ece6e6"]],
        },
        body: {
          border: [[1, "solid", "#ece6e6"]],
        },
      },
    },
  });


useEffect(() => {
  NewJournalService.getTaxLedgersLia(currentTenant).then((response) =>{
    setLedgers(response.data);
  });
  },[]);

  const submitRecord=(value)=>{
    let directaddedstock
    if(value!==undefined){
  //  directaddedstock=dirstock.filter(e=>e.id===value.rowData[0])
    } 
    history.push(`/dirstockentry`, {directaddedstock});
   }

  
  const Ledgercolumns = [
    {
      name: "behav",
      label: "EntryType",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        customBodyRender: (tableMeta) => {
          return moment(tableMeta).format("DD-MM-YYYY hh:mm");
        },
      },
    },    
    {name: "orderid",label: "Type",options: {filter: false,sort: true,}},
    {name:"debitname",label:"Dr Ledger",options: {filter: true,sort: true,
    //  customBodyRender: (tableMeta, values) => {
    //    // console.log(values.rowData.find((e) => e))
    //     return values.rowData.find((e) => e) !== 1?tableMeta:"-NA-"

    //      }
      }},
      {name:"creditname",label:"Cr Ledger",options: {filter: true,sort: true,
        // customBodyRender: (tableMeta, values) => {
        //   // console.log(values.rowData.find((e) => e))
        //    return values.rowData.find((e) => e) === 1?tableMeta:"-NA-"
   
        //     }
         }},

    {
      name: "amount",
      label: "Previous/Opening Balance",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (tableMeta) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={tableMeta}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "jvamount",
      label: "Debit",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (tableMeta, values) => {
          //  console.log(values.rowData[10])
          return (
            <>
              {values.rowData[10] ==="DEBIT" ?//("+"):("-")}
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat> :0}
            </>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "jvamount",
      label: "Credit",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (tableMeta, values) => {
          //console.log(values.rowData.find(e=>e))
          return (
            <>
              {values.rowData[10]=== "CREDIT" ?
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat> : 0}
            </>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
      },
    },

    {
      name: "amount",
      label: "Current/Closing Balance",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (tableMeta) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={tableMeta}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
        setCellProps: (value) => ({ style: { textAlign: "right" } }),
        //   customHeadRender: (columnMeta,) => (
        //     <div style={{style: { textAlign:'right'}}}>
        //         {columnMeta.name}
        //     </div>
        // )
      },
    },

    {
      name: "id",
      label: "Transaction",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <>
            //   {value !== null ? (
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  to={{ pathname: "/jvdetails"}} state= {{ id: value}}
                >
                  {value.id}
                </Link>
            //   ) : tableMeta.rowData[7] !== null ? (
            //     <Link
            //       as="button"
            //       data-tip
            //       data-for="view"
            //       to={{
            //         pathname: "/jvdetails"}}
            //         state={{ id: tableMeta.rowData[7]}}
            //     >
            //       {tableMeta.rowData[7].id}
            //     </Link>
            //   ) : (
            //     "Opening"
            //   )}
            // </>
          );
        },
      },
      setCellProps: (value) => ({ style: { textAlign: "center" } }),
    },
    {
      name: "entryType",
      label: "EntryType",
      options: {
        filter: false,
        sort: false,
         display: false,
        viewColumns: false,
      },
    },


    //{name: "warehouse",label:"Branch/Warehouse",options: {filter: false,sort: true,}},
   // {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
    //   customBodyRender:(tableMeta)=>{
    //     return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //   },
    //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // }},
    // {name: "amount",label: "Total Amount",options: {filter: false,sort: true,sortOrder: 'asc',
    //   customBodyRender:(tableMeta)=>{
    //     return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //   },
    //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // }},
    // {name: "id",label:<span className="text-bold">Action</span>,options: {filter: false,sort: false,display:true,viewColumns:false,    
    //   customBodyRender:(value,tableMeta)=>{
    //     return (
    //       value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
    //       onClick={() => submitRecord(tableMeta)}>  {loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pass Entry"}
    //       </button>:<span className="text-red text-bold">No Values</span>
    //     );      
    //   },
    //   setCellProps: value => ({ style: { outerHeight:100}}),
    // }},
   ];


   const Ledgeroptions = {
    filterType: 'checkbox',
    selectableRows: "none",
    resizableColumns: true,

  }

   const onLedgerSelect=(value, values)=>{
    if (value !== undefined || value !== "") {
      let val = ledger.filter((e) => e.ledgername === value).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);
      setselectedLed(val)
      setSelectedledger(value)
      // let val1 = val.filter(e=>e.jvid !== null?e.jvid:e.cjvid)
      //console.log(val)
      // this.setState({ selectedLed: val, selectedledger: value });
    }
  }

  const ledgers = [
    ...new Set(
      ledger.map((e) => {
        //return e.ledgercode!==null?e.ledgername.concat(" [" + e.ledgercode + "]"):e.ledgername;
        return e.ledgername;
      })
    ),
  ];

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item active">Ledgers</li>
          </ol>
          <div className="form-row">
            <div className="card-body">
              <div>
                {ledger === null ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <>
                  <div className="mb-5">
                  <Formik
                  initialValues={{ ledgersel: "", ledgers }}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => (
                    <Form autoComplete="off">
                      {/* <span className="text-red"> This is under development. Will be available ASAP</span> */}
                      <div className="form-row col-md-12 mt-0 mb-0">
                  <fieldset className="form-group col-md-6 mt-0 mb-3">
                          <Autocomplete
                            id="aledgers"
                            className="grn-select "
                            name="aledersel"
                            options={ledgers}
                            getOptionLabel={(option) => option}
                            
                            
                            value={values.ledgersel ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("ledgersel", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control mb-3"
                                type="text"
                                name="ledgersel"
                                label="Select Tax Ledger"
                                onBlur={(e) => {
                                  onLedgerSelect(e.target.value, values);
                                }}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-6 mt-0 mb-3 text-right">

                 <Link className="btn fas buttonColour text-yellow fa-money-bill-alt"
                  to={{ pathname: "/ledgerreal" }}
                    state={{ message: "" }}
                      >
                    &nbsp;&nbsp;&nbsp;A/L Ledgers</Link>

                          </fieldset></div>
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3 text-sm"
                        title={"Ledgers"}
                        id="dirstock"
                        data={selectedLed.map(oth=>{
                          return[oth.behav,oth.createdAt,
                            // oth.orderid!==null && oth.orderid.charAt(0)==='S'?oth.custominvid:oth.orderid!==null && oth.orderid.charAt(0)==='P'?oth.custompoid:oth.genadvanceadded!=null?"Advance":oth.orderid,
                            // oth.jvid!==null?oth.jvid.debitname:oth.cjvid!==null?oth.cjvid.name:"Opening",
                            // oth.jvid!==null?oth.jvid.creditname:oth.cjvid!==null && oth.cjvid.name==="Salaries"?oth.cjvid.cidthname:oth.cjvid!==null?oth.cjvid.cname:"Opening",
                            // oth.jvid!==null?oth.currentamt:oth.cjvid!==null?oth.genadvanceadded!=null?oth.jvamount:oth.currentamt:oth.jvamount,
                            // oth.jvid!==null ?oth.jvamount:oth.cjvid!==null ?oth.jvamount:"Opening", 
                            // oth.jvid!==null ?oth.jvamount:oth.cjvid!==null ?oth.jvamount:null,
                            // oth.genadvanceadded!=null?oth.genadvancecurrent:oth.amount,
                            // oth.jvid!==null?oth.jvid:oth.cjvid!==null?oth.cjvid:"Opening",
                            // oth.entryType!==null?oth.entryType:0
                            oth.ledgername,
                            // oth.jvid!==null?oth.thid==="TH3"?oth.jvid.debitname:oth.jvid.creditname:oth.cjvid!==null?oth.thid==="TH3"?oth.cjvid.name:oth.cjvid.cname:"Opening",
                            // oth.jvid!==null?oth.thid==="TH3"?oth.jvid.creditname:oth.jvid.debitname:oth.cjvid!==null?oth.thid==="TH3"?oth.cjvid.cname:oth.cjvid.name:"Opening",
                            oth.jvid!==null?oth.jvid.debitname:oth.cjvid!==null?oth.cjvid.name:"Opening",
                            oth.jvid!==null?oth.jvid.creditname:oth.cjvid!==null && oth.cjvid.jvinsertiontype==="SALARIES"?oth.ledgername:oth.cjvid!==null?oth.cjvid.cname:"Opening",
                            oth.jvid!==null?oth.currentamt:oth.cjvid!==null?oth.currentamt:oth.jvamount,
                            oth.jvid!==null?oth.jvamount:oth.cjvid!==null?oth.jvamount:"Opening", 
                            oth.jvid!==null?oth.jvamount:oth.cjvid!==null?oth.jvamount:null,
                            oth.amount,
                            oth.jvid!==null?oth.jvid:oth.cjvid!==null?oth.cjvid:"Opening",
                            oth.entryType!==null?oth.entryType:0

                            // oth.jvid!==null?oth.jvid.creditname:"Opening",
                            // oth.jvid!==null?oth.jvid.debitname:"Opening",
                            // oth.jvid!==null?oth.currentamt:oth.jvamount,
                            // oth.jvid!==null?oth.jvamount:"Opening", oth.jvid!==null?oth.jvamount:null,oth.amount,oth.jvid!==null?oth.jvid:"Opening"
                          ]
                        })}
                        columns={Ledgercolumns}
                        options={Ledgeroptions}
                      />
                    </ThemeProvider>
                    </Form>
                  )}
                </Formik>
                    </div>

                  </>
                )} 
              </div>

            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );


}         



 
