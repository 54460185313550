import {Autocomplete, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from "moment";
import React, { Component } from 'react'
import { NumericFormat } from 'react-number-format';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Credentials, { currentTenant, thousandsdisplay, userid ,financialyearstart,financialyearend} from '../../Common/Credentials.js';
import  { spin } from '../../Common/NewLoader.jsx';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import NewReportService from '../NewReportService.js';
import './table.css';
import NewJournalService from '../NewJournalService.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';

let name = ''
//let id=[]
let cre1=''
let deb1=''



const debTotal = (debit) => {
  let total=0 ;
  //  console.log(data)
  debit.forEach(debit => {
    total += debit;
  });
  // console.log(total)
  
  return total;
};
const creTotal = (credit) => {
  let total=0 ;
  //  console.log(data)
  credit.forEach(credit => {
    total += credit;
  });
  // console.log(total)
  return total;
};

// const calculateTotal=(ledtotal)=>{
//   let total = 0 ;
//   ledtotal.forEach(ledtotal => {
//     total += ledtotal;
//   });
//   // console.log(total)
//   return total;
// }


export let debitexcess,creditexcess,onFySelect
class NewPandL extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            subaccdata:[],
            dirtaxpaid:[],
            inputindirecttax:[],
            // combdata:[],
            loading:false,
            totalamt:'',
            fylist:[],
             fyfrom:null,
           fyto:null
           }
    }
   
    onFySelect(value){
      let year
      if(value.from!==financialyearstart){
        year={
          from:value.from,
          to:value.to,
          tenantid:currentTenant
            }
        this.setState({fyfrom:value.from,fyto:value.to})
      }else{
        year={
      from:'Cur',
      to:'year',
      tenantid:currentTenant
        }
        this.setState({fyfrom:financialyearstart,fyto:financialyearend})
  
      }

      NewReportService.getDirtaxPaid(currentTenant).then((res)=>{
        this.setState({dirtaxpaid:res.data})
      });
      NewReportService.getInputIndirecttax(currentTenant).then((res)=>{
        this.setState({inputindirecttax:res.data})
      });
     
      NewReportService.retrivePLLedgers(year).then((response) => {
        this.setState({ subaccdata: response.data.sort((a, b)=>{
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      }) });
        const script = document.createElement("script");
        script.src = "../../js/content.js";
        script.async = true;
        document.body.appendChild(script);
        this.setState({ loading: false });
      })
      //NewJournalService.getAssets(year).then((response) => {
      //   this.setState({ assets: response.data },()=>this.assetsubheads())
      //   this.setState({ loading: false });
      // })
      // NewJournalService.getLiabilities(year).then((response) => {
      //   this.setState({ liabilities: response.data },()=>this.liabsubheads())
      //   this.setState({ loading: false });
      // })
    }

    fyyears(){
      // let fyyears=[]
      //let def={from:'Current',to:'year'}
      let def={from:financialyearstart,to:financialyearend}

   this.setState({ 
     fylist: this.state.fylist.concat(def)
   })   // val=this.state.fylist.filter((e,i)=>e.from &&)
      
       //this.setState({loader1:false})
     }
    componentDidMount() {
        this.setState({ loading: true });
        name = ''
        //id=''
        if (userid === undefined) {
          window.confirm("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
 
        if(creditexcess === undefined && debitexcess === undefined){
        alert("Please Run/Generate Trail Balance once before P and L")
        }
       
        NewJournalService.getFinancialYears(currentTenant).then((response) => {
          this.setState({ fylist: response.data },()=>this.fyyears())
          this.setState({ loading: false,loader1:false });
        })
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
    
        // NewJournalService.getAllLedgers(currentTenant).then((response) => {
        //   this.setState({ combdata: response.data });
        //   this.setState({ loading: false });
    
        //   //  // this.setState({combdata:response.data})
        // });
      }

      calculateLedgerExpences(totalamt,name){
        let a
            Object.entries(totalamt).forEach(([key, value]) => {
              if(key === name){
               a = {
                name: key,
                expenses: value.reduce((a, v) => 
                (a = a +(v.totamount === null? 0 : ((v.rule===(0||1) && v.entrytype===0 && v.name === name)?(v.rule===2  && v.entrytype===1 && v.name === name)?v.totamount:v.totamount:0))
                ), 0),
                // income: value.reduce((a, v) => 
                // (a = a +(v.totamount === null? 0 : ((v.rule===0,1 && v.entrytype===1 && v.name === name) ?(v.rule===2  && v.entrytype===0 && v.name === name )?v.totamount:v.totamount:0))
                // ), 0),
              };
            }
            });
            return a.expenses === 0?null: a.expenses
      }
      calculateLedgerIncome(totalamt,name){
        let a
            Object.entries(totalamt).forEach(([key, value]) => {
              if(key === name){
               a = {
                name: key,
                // expenses: value.reduce((a, v) => 
                // (a = a +(v.totamount === null? 0 : ((v.rule===0,1 && v.entrytype===0 && v.name === name)?(v.rule===2  && v.entrytype===1 && v.name === name)?v.totamount:v.totamount:0))
                // ), 0),
                income: value.reduce((a, v) => 
                (a = a +(v.totamount === null? 0 : ((v.rule===(0||1) && v.entrytype===1 && v.name === name) ?(v.rule===2  && v.entrytype===0 && v.name === name )?v.totamount:v.totamount:0))
                ), 0),
              };
            }
            });
            return a.income === 0 ?null:a.income
      }
     calcSubTotal(name1){
      cre1=this.state.subaccdata.map((e) => { return e.th==="TH2" && e.name===name1 ? e.totamount:0})
      deb1=this.state.subaccdata.map((e) => { return e.th==="TH1" && e.name===name1 ? e.totamount:0})

    }

    setProfit(amt){
    Credentials.setPlprofit(amt)
    }
    setLoss(amt){
      Credentials.setPlloss(amt)
    }
    
    render() {
      //let debit = this.state.subaccdata.map(e => {return ((e.rule===0,1 && e.entrytype===0) ?(e.rule===2  && e.entrytype===1)?e.totamount:e.totamount:0)})
      //let credit = this.state.subaccdata.map(e => {return ((e.rule===0,1 && e.entrytype===1) ?(e.rule===2  && e.entrytype===0)?e.totamount:e.totamount:0)})


      let debit = this.state.subaccdata.map(e => {return (e.th==="TH1"?e.totamount:0)})
      let credit = this.state.subaccdata.map(e => {return (e.th==="TH2"?e.totamount:0)})
      let dtax = this.state.dirtaxpaid.map(e=>{return e.totaltaxamount} )
      let sgst = this.state.inputindirecttax.map(e => {return (e.statetaxamount!==null?e.statetaxamount:0)})
      let cgst = this.state.inputindirecttax.map(e => {return (e.centraltaxamount!==null?e.centraltaxamount:0)})
      let igst = this.state.inputindirecttax.map(e => {return (e.integratedtaxamount!==null?e.integratedtaxamount:0)})
      let vat = this.state.inputindirecttax.map(e => {return (e.vatamount!==null?e.vatamount:0)})
      // let indirecttaxes=[] 
      // indirecttaxes = this.state.inputindirecttax.find(e=> e.itemname)

    // let partyone = this.state.subaccdata.map((e)=>{return e.partyledgers})
    // let pdata = partyone.filter((e)=> e!==null)
     // let partydata


        // console.log(totalamt)
    
      // var newArr = this.removeDuplicatesFromArray(this.state.subaccdata);
        // console.log(newArr); 
    //  pdata.map(e=>{return partydata=e})
      // console.log(this.state.dirtaxpaid)
      creditexcess = creTotal(credit) > debTotal(debit)? creTotal(credit)-debTotal(debit):null
      debitexcess= debTotal(debit)> creTotal(credit)?debTotal(debit) - creTotal(credit):null
        return (
          
            <div>
              <Header />
               <Menu/>
            
            
             <div className="content-wrapper">
             <div className="card card-info">
             {/* <div className="card-header">
              <h3 className="card-title">Profit and Loss</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Profit and Loss</li>
            </ol>
            <div >

            <div className="card-body">
              
            <div className="text-center">
            {this.state.loading===true?spin:''}
            </div>
                <Formik
            initialValues={{fylist:'' }}
            onSubmit={this.onSubmit}
            validateOnChange={true}
            validate={this.validate}
            enableReinitialize={true}>
            {({ values, setFieldValue }) => (
             
            
             <Form>

<fieldset className="form-group col-md-4 mt-0 mb-3">
<Autocomplete id="fylist1" className="fy-select " name="fylist" options={this.state.fylist} getOptionLabel={(option) => option.from.substring(0,4) +"-"+  option.to.substring(0,4)}
 // isOptionEqualToValue ={(option) => option.id}
value={values.fylist? undefined: undefined} onChange={(e, value) => {setFieldValue("fylist", value) && this.onFySelect(value)}}
renderInput={(params) => (
<FormikTextField {...params} className="form-control mb-3" type="text" name="fylist" label="Select Financial year" 
  variant="standard" fullWidth/>)}/></fieldset>

<div><h5 className="text-center">P&L Income Expense Statement</h5></div>
                      <div><p className="text-center">For the Financial Year - {moment(financialyearstart).format('DD-MM-YYYY')} --- {moment(financialyearend).format('DD-MM-YYYY')}
                     </p></div>
             <Table 
      //  id="tb"
       className="table table-sm table-borderless">
      
       <TableHead className="thead-light thead-bordered mt-0 mb-0" >
         <TableRow className="tr-bordered mt-0 mb-4">
         <TableCell>Account</TableCell>
         <TableCell className="text-right">Income</TableCell>
         <TableCell className="text-right">Expenses</TableCell>
         </TableRow>
       </TableHead>
       
       <TableBody>
       {this.state.subaccdata.map(
         
       (resulttop,ind )=> 

              <TableRow className="mt-0 mb-0 " key={ind} >
                   {/* <span hidden>{name = resulttop.name}</span> */}

             {resulttop.totamount!==0 ?
                         
              <Table className="table table-sm table-borderless mt-1 mb-0 ">
                {resulttop.name === name?'':
              <TableHead className="thead-light mt-0 mb-0 ">
              <TableCell className="text-sm text-left text-black mt-0 mb-0">{resulttop.code!==null?resulttop.name.concat(" [" + resulttop.code + "]"):resulttop.name}</TableCell>
              <TableCell hidden className="text-sm text-right text-black mt-0 mb-0" >{this.calcSubTotal(resulttop.name)}</TableCell>
              <TableCell className="text-sm text-right font-weight-bold text-black mt-0 mb-0" >{resulttop.th==="TH2"?
              <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} value={creTotal(cre1)}></NumericFormat>:
              <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} value={debTotal(deb1)}></NumericFormat>}</TableCell>

              </TableHead>
              }
              <TableBody>
              <TableRow>

              {/* <TableCell className="text-sm text-left text-black mt-0 mb-0">{resulttop.name}</TableCell> */}

              {/* <TableCell className="text-sm text-left text-black mt-0 mb-0"></TableCell> */}
              </TableRow>

                <TableRow>

                <TableCell className="text-sm font-weight-bold text-left text-blue text-bold mt-0 mb-0 ">
                {resulttop.ledgercode!==null?resulttop.ledname.concat(" [" + resulttop.ledgercode + "]"):resulttop.ledname}
                   </TableCell>
                <TableCell className="text-sm text-right text-blue mt-0 mb-0 ">
                  {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} value={resulttop.totamount}></NumericFormat>}</TableCell>
                </TableRow>
                {/* <Table className="table-bordered align-left mt-0 mb-0 col-md-6"> */}
                {/* <TableRow className="bordered align-left mt-0 mb-0"> */}
                {resulttop.partyledgers!==null?resulttop.partyledgers.map((partysub,i)=>(<>
                  <TableRow className="align-left mt-0 mb-0" key={i}>
                {partysub.totalpmts!==0 && resulttop.ledid===partysub.ieledger ?<>
                    <TableCell className="text-sm text-left mt-0 mb-0 ">
                    <Link to={{pathname: "/newledgerview"}}state={partysub.ledgername}>{partysub.ledgercode!==null?partysub.ledgername.concat(" [" + partysub.ledgercode + "]"):partysub.ledgername}</Link>
                    </TableCell>
                    <TableCell className="text-sm text-right mt-0 mb-0">
                    {<NumericFormat className="text-sm text-right mt-0 mb-0"  displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} value={partysub.totalrects!==null?partysub.totalrects:partysub.totalpmts}></NumericFormat>}
                    </TableCell>
                  
                </>:""}
                </TableRow>
              </> )):""}
              {/* </TableRow> */}
              {/* </Table> */}
              </TableBody>
              
               </Table>
              
               :null}

  {resulttop.totamount!==0?<>

    {resulttop.name === name?'':<>
{/* 
    <TableCell className="text-right">asdsadasdasd</TableCell>
 <TableCell className="text-right">{resulttop.totamount}</TableCell> */}

    {resulttop.th==="TH1"? <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {debTotal(deb1) }></NumericFormat>}</TableCell>:<TableCell className="text-right text-green"></TableCell>}
 {resulttop.th==="TH2" ?<TableCell className="text-sm text-right text-orange mt-0 mb-0">
                    {<NumericFormat className="text-sm text-right text-orange mt-0 mb-0"  displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} value={creTotal(cre1)}></NumericFormat>}
                   {/* {console.log(resulttop.totamount)} */}
                    </TableCell>:""}
  

 </> }
 <span hidden>{name = resulttop.name}</span>

 </>:null} 

 </TableRow>
 )}
  {/* <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {creTotal(credit) }></NumericFormat>}</TableCell> 
              {/* <TableCell className="text-sm text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value={this.calculateLedgerExpences(totalamt,resulttop.name)}></NumericFormat>}</TableCell>

              <TableCell className="text-sm text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value={this.calculateLedgerIncome(totalamt,resulttop.name)}></NumericFormat>}</TableCell> */}

        {/* Taxes */}
         <TableRow >
            <TableCell className="text-right">Total(Exclusive of Taxes)</TableCell>
            <TableCell className="text-right font-weight-bold">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {debTotal(debit)}></NumericFormat>}</TableCell>
               <TableCell className="text-right font-weight-bold">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {creTotal(credit)}></NumericFormat>}</TableCell>
             
            </TableRow>
             {/* debTotal(debit)> creTotal(credit)?creTotal(credit)+(debTotal(debit) - creTotal(credit)):creTotal(credit) */}
            {/* creTotal(credit) > debTotal(debit)?debTotal(debit)+(creTotal(credit)-debTotal(debit)):debTotal(debit) */}
           {/* Less Taxes */}           
            {/* <TableRow >
            <TableCell> 
             <Table className="table table-sm table-borderless m-0">
              
             <TableHead className="thead-light">
             <TableCell className="text-sm text-left text-black">Less Taxes</TableCell>
             <TableCell></TableCell>
             </TableHead>
              </Table>
              </TableCell>
             <TableCell/><TableCell/>
            </TableRow> */}
               
               {/* Direct Taxes */}
               <TableRow >
            <TableCell> 
             <Table className="table table-sm table-borderless">
             <TableHead className="thead-light">
             <TableCell className="text-sm text-left text-black">Direct Taxes</TableCell>
             <TableCell></TableCell>
             
             </TableHead>
             
             <TableBody>
               {/* <TableRow> */}
             
            {this.state.dirtaxpaid!==null?this.state.dirtaxpaid.map((dtaxpaid,i)=>(<>
                {/* //  <Table className="table-bordered align-left mt-0 mb-0 col-md-6"> */}
               <TableRow className="bordered align-left mt-0 mb-0" key={i}>
                    <TableCell className="text-sm text-left mt-0 mb-0 ">
                   {dtaxpaid.ledgername}
                    </TableCell>
                    <TableCell className="text-sm text-right mt-0 mb-0">
                    {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {dtaxpaid.totaltaxamount}></NumericFormat>}
                    </TableCell>
                  
               </TableRow>
               {/* </Table>   */}
              </> )):""}
               {/* </TableRow> */}
             </TableBody>
              </Table>
              </TableCell>
             
              {/* <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {null}></NumericFormat>}</TableCell> */}

             <TableCell className="text-right text-green">NA</TableCell>
              <TableCell className="text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {creTotal(dtax)}></NumericFormat>}</TableCell>
            </TableRow>

            {/* Indirect Taxes */}
            <TableRow>
            <TableCell> 
             <Table className="table table-sm table-borderless ">
              
             <TableHead className="thead-light md-col-12">
             <TableCell className="text-sm text-left text-black md-col-12">Indirect Taxes</TableCell>
             <TableCell></TableCell>
             <TableCell className="text-sm text-right text-black md-col-12">(input Credit)</TableCell>
             </TableHead>

             <TableBody>
             
            {this.state.inputindirecttax!==null?this.state.inputindirecttax.map((iindtax)=>(<>
              {/* <TableRow className="align-left mt-0 mb-0 "> */}
              <TableCell className="text-sm text-left mt-0 mb-0 ">{iindtax.itemname}</TableCell>
                {iindtax.statetaxamount!==null?
               <TableRow className="align-left mt-0 mb-0 ">
                <TableCell className="text-sm text-left mt-0 mb-0 ">SGST</TableCell>
                <TableCell></TableCell>
                <TableCell className="text-sm text-right mt-0 mb-0 ">
               {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {iindtax.statetaxamount}></NumericFormat>}</TableCell> </TableRow>:""}
              {iindtax.centraltaxamount!==null?
               <TableRow className="bordered align-left mt-0 mb-0">
              <TableCell className="text-sm text-left  mt-0 mb-0 ">CGST</TableCell>
              <TableCell></TableCell>
              <TableCell className="text-sm  text-right mt-0 mb-0 ">
              {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {iindtax.centraltaxamount}></NumericFormat>}</TableCell></TableRow>:""}
              {iindtax.integratedtaxamount!==null?
               <TableRow className="bordered align-left mt-0 mb-0">
              <TableCell className="text-sm text-left  mt-0 mb-0 ">IGST</TableCell>
              <TableCell></TableCell>
              <TableCell className="text-sm  text-right mt-0 mb-0 ">
              {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {iindtax.integratedtaxamount}></NumericFormat>}</TableCell></TableRow>:""}
              {iindtax.vatamount!==null?
               <TableRow className="bordered align-left mt-0 mb-0">
              <TableCell className="text-sm text-left  mt-0 mb-0 ">VAT</TableCell>
              <TableCell></TableCell>
              <TableCell className="text-sm  text-right mt-0 mb-0 ">
              {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {iindtax.vatamount}></NumericFormat>}</TableCell></TableRow>:""}
              
              {/* </TableRow> */}
              </> )):""}
             </TableBody>
              </Table>
              </TableCell>
             
              {/* <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {null}></NumericFormat>}</TableCell> */}
             <TableCell className="text-right text-green">NA</TableCell>
              <TableCell className="text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {creTotal(sgst)+creTotal(cgst)+creTotal(igst)+creTotal(vat)}></NumericFormat>}</TableCell>
            </TableRow>

              {/* Total Taxes */}
            {/* <TableRow >
            <TableCell className="text-right">Total Taxes</TableCell>
            <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {creTotal(credit)+debTotal(dtax) > debTotal(debit)?debTotal(debit)+(creTotal(credit)+debTotal(dtax)-debTotal(debit)):debTotal(debit)}></NumericFormat>}</TableCell>
              <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {debTotal(debit)> creTotal(credit)+debTotal(dtax)?creTotal(credit+debTotal(dtax))+(debTotal(debit) - creTotal(credit)+debTotal(dtax)):creTotal(credit)+debTotal(dtax)}></NumericFormat>}</TableCell>
            </TableRow> */}
           

  
              <TableRow >
              <TableCell className=" bg-light text-sm text-left text-black font-weight-bold">Diff To Balance Sheet</TableCell>
              {/* {creTotal(credit) > debTotal(debit)?<TableCell className="text-right text-sm text-red font-weight-bold">{(debTotal(debit)-creTotal(credit))}</TableCell>:
               <TableCell className="text-right text-sm text-green font-weight-bold">{(creTotal(credit)-debTotal(debit))}</TableCell>} */}
          
          
          
            {/* <TableCell className="text-right text-sm text-red font-weight-bold">{debTotal(debit)> creTotal(credit)?creTotal(credit)-(debTotal(debit) - creTotal(credit)):creTotal(credit)}</TableCell> */}
              <TableCell className="text-right text-sm text-red font-weight-bold">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
               value = {(creTotal(credit)+creTotal(dtax))  > debTotal(debit)? creTotal(credit)+creTotal(dtax) - debTotal(debit):0.00} ></NumericFormat>}</TableCell> 
               <TableCell hidden>{this.setLoss((creTotal(credit)+creTotal(dtax)) > debTotal(debit)? creTotal(credit)+creTotal(dtax) - debTotal(debit):0.00)}</TableCell> 
              <TableCell className="text-right text-sm text-green font-weight-bold">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}  
              value = {debTotal(debit)> (creTotal(credit)+creTotal(dtax))?debTotal(debit) -(creTotal(credit)+creTotal(dtax)):0.00}></NumericFormat>}</TableCell>
            </TableRow>
            <TableCell hidden>{this.setProfit(debTotal(debit)> (creTotal(credit)+creTotal(dtax))?debTotal(debit) -(creTotal(credit)+creTotal(dtax)):0.00)}</TableCell> 


           <br></br>
             <TableRow>
              <TableCell className="text-right">Total</TableCell>
              <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {(creTotal(credit)+creTotal(dtax)) > debTotal(debit)?debTotal(debit)+((creTotal(credit)+creTotal(dtax))-debTotal(debit)):debTotal(debit)}></NumericFormat>}</TableCell>
              <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {debTotal(debit)> (creTotal(credit)+creTotal(dtax)) ?(creTotal(credit)+creTotal(dtax))+(debTotal(debit) - (creTotal(credit)+creTotal(dtax))):(creTotal(credit)+creTotal(dtax))}></NumericFormat>}</TableCell>
            </TableRow>
  

       </TableBody>    
      
                       
     </Table> 
     </Form>
            )}
     </Formik>
</div>
</div>
</div>
</div>

            <Footer/>    
            </div>
        )
    }
}

export default withRouter(NewPandL)