import React, { Component, useEffect } from 'react'
import AppRoute from './users/Route'

 const App = ()=> {
    const spinner = document.getElementById('spinner');

    const currentVersion = process.env.REACT_APP_VERSION;
  const clearCacheData = () => {
    if ('caches' in window) { 
      caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
        console.warn('Cleared caches in this browser.');
    }).catch((error) => {
      console.error('Error clearing cache:', error);
    });
  } else {
    console.warn('Caches not available in this browser.');
  }

};

    useEffect(() => {
        const storedVersion = localStorage.getItem('appVersion');
        if (storedVersion !== currentVersion) {
            // Notify the user about the new version
            // alert('A new version of the app is available! Please refresh the page.');

            // Update the stored version to the current version
            localStorage.setItem('appVersion', currentVersion);
            clearCacheData()
        }
    }, [currentVersion]);



if (spinner && !spinner.hasAttribute('hidden')) {
  spinner.setAttribute('hidden', 'true');
}
    return (
      <div>
      
      <AppRoute/>
       
      </div>
    )
  }

export default App
