import React, { useEffect, useState } from "react";
import withRouter from "../withRouter";
import Menu from "../Component/Menu";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import transactionsIcon from "../images/Icons/transaction.png";
import Credentials from "../Common/Credentials";
import mastersIcom from "../images/Icons/generic.png";

import dashboardIcon from "../images/Icons/dashboard.png";

const ProjectManagement = () => {
  useEffect(() => {
    Credentials.setSelectedMenu("Project Management");
  }, []);

  return (
    <div>
      <Header />
      <Menu />

      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Project Management</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
          {/* 
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item active">Project Management</li>
          </ol>
          <section className="content">
          <div className="h-max border-slate-300 bg-white p-3 rounded-lg  "> */}

          <ul className="d-flex flex-row list-unstyled">
            <li className="p-1 mr-4">
              <Link
                to={{ pathname: "/projectdashboard" }}
                state={{ message: "" }}
                className="textColour py-1"
              >
                <img className="nav-icon mr-2" width="25" src={dashboardIcon} />
                Dashboard
              </Link>
            </li>
          </ul>
          <div className="container-fluid mt-3">
            <div className="row gap-x-8 gap-y-6 mt-0">
              <div className="col-sm-12 col-md-6 col-lg-4  w-full">
                <span className="font-weight-bold my-3">
                  {/* <i className="nav-icon fas fas fa-th textColour " /> */}
                  <img className="nav-icon" width="25" src={mastersIcom} />
                  <span className="pl-2"> Masters</span>
                </span>
                <ul className="menuul pl-4 ml-3">
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/estimationparameters" }}
                      state={{ message: "" }}
                    >
                      Estimation Parameters
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/Labours" }}
                      state={{ message: "" }}
                    >
                      Labours
                    </Link>
                  </li>
                </ul>
               
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 w-full ">
                <span className="font-weight-bold my-3">
                  <img className="nav-icon" width="25" src={transactionsIcon} />
                  <span className="pl-2">Transactions</span>
                </span>
                <ul className=" menuul pl-4 ml-3">
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/projects" }}
                      state={{ message: "" }}
                    >
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/projectestimation" }}
                      state={{ message: "" }}
                    >
                      Project Estimation
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/projectindenting" }}
                      state={{ message: "" }}
                    >
                      Project Indenting
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </div> */}
      {/* </div> */}

      <Footer />
    </div>
  );
};

export default withRouter(ProjectManagement);
