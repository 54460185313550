import { Form, Formik } from 'formik'
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from 'react'
import { currentTenant, financialyearend, thousandsdisplay, transactionFrom, userid } from '../../Common/Credentials'
import NewLedgerService from './NewLedgerService'
import { NumericFormat } from 'react-number-format';import { Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment';
import { FormControlLabel } from '@mui/material';
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import IOSSwitch from '../../CommonComponents/IOSSwitch.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";




export default class Cash extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
        currentbal:[],
        Name:"Cash",
        id:'',
        fed:'',
        SDdate:false,
        loader:false
        }
        this.validate=this.validate.bind(this)
        this.onSubmit=this.onSubmit.bind(this)
        this.addData=this.addData.bind(this)
    }
    

    validate(values) {
        let errors = {};
        var num = /^[0-9,.]+$/

        let a = this.state.currentbal.map((e)=>e.name)
        if(a.find(e=>e===values.accname)){
          errors.accname = 'Ledger already exists !! Please use a different name for Ledger';
        }
        if (!values.openingbal || values.openingbal==='') {
          errors.openingbal = 'Opening Balance is required';
        }else if (!num.test(values.openingbal)) {
          errors.openingbal = 'Only Numbers are allowed';
        }

        
        return errors;
    
      }

      deleteLedger(id){
        NewLedgerService.delCash(id)
        .then(response=>{
          Swal.fire({
            title: "Response",
            text: response.data,
            icon: "info",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          })
          this.addData()
        })
      }

      
      onSubmit(values) {
        this.setState({loader:true})
        let newCash = {
          name: this.state.Name,
          ledname:values.accname,
          createdby:userid,
          amount:values.openingbal,
          tenantId:currentTenant,
          date:this.state.fed!==''?this.state.fed:null

        }
        if (this.state.id === '') {
          NewLedgerService.addCahOpening(newCash)
          .then((response) =>{
          this.setState({currentbal1:response.data,loader:false})
          this.addData()
          } )
          .catch((e)=>{
            if(e.response.data.error ==="Unauthorized"){
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
            }
        })

          
        }else {

           this.setState({loader:false})
      }   
      }
    
componentDidMount(){
  this.addData()
  this.setState({
    fed: moment(transactionFrom.$d).format("yyyy-MM-DD HH:mm:ss"),
  });
}

handleSDDate=()=>{
  this.setState({SDdate:this.state.SDdate===true?false:true})
}
handleFsdDateChange = (date) => {
  if(date!==null){
  this.setState({ fsd: date.$d });
  this.setState({
    fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
  });
}else{
  this.setState({ SDdate:false})
}
};


addData(){
  NewLedgerService.getCurrentBal(currentTenant)

  .then((response) =>{
    this.setState({currentbal:response.data})

  })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
}

    render() {
      

        return (
            <div>
                <Formik
              
              initialValues={{}}
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validate={this.validate}
              enableReinitialize={true}>
              {({setFieldValue,values,setValues}) => (
                
                <Form>

                  


                  <div className="form-row">
                  {/* <div className="col-md-12 "> */}
                  <div className="form-group col-md-3 text-left">
                  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="form-group col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} variant="standard" />} autoOk={true} />
    </Grid></LocalizationProvider></div>
     :""}
          <div className="form-group col-md-6 text-left"></div>

     {/* </div> */}
                
                    
                    <fieldset className="form-group col-md-5">
                      <FormikTextField
                        className="form-control"
                        name="accname"
                        placeholder="Account Name"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset>

                      <fieldset className="form-group col-md-5">
                      <FormikTextField
                        className="form-control"
                        name="openingbal"
                        placeholder="Opening Balance"
                        variant="standard"
                        fullWidth
                      />
                      </fieldset>
                   
                   <fieldset className="form-group col-md-2">
     <button className="btn buttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</>:"Save"}</button>
    </fieldset>
                    </div>

                  <div>
                 
                  </div>

                  <div>
<table 
       id="example1"
       className="table table-bordered table-sm text-sm ">
      
       <thead >
         <tr>
         <th className="form-group col-md-5">Account Name</th>
         <th className="form-group col-md-5">Opening Balance</th>
         <th className="form-group col-md-1">Action</th>

         {/* <th>Action</th> */}
         </tr>
       </thead>
       
       <tbody>
         
         {
           this.state.currentbal.map(
             result => 
             <tr key={result.id}>
               <td className="form-group col-md-5 text-blue">{result.ledgercode!==null?result.name+ " (" + result.ledgercode + ")":result.name}</td>
               <td className="form-group col-md-5 text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} value = {result.amount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>}</td>
               <td><span  className="form-group col-md-1 fa fa-trash text-danger " onClick={()=>this.deleteLedger(result.id)}></span></td>
             </tr>
             
           )
         }
              
       </tbody>

     
                                         
     </table> 
     </div>
                 
                  

                </Form>
              )}
            </Formik>
                
            </div>
        )
    }
}
