import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

export let currentTenant

class SettingsServices {
    GetSettings(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getclientsettings/${currentTenant}`)
    }

    GetAllPrefixes(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallprefixes/${currentTenant}`)
    }

    retriveMailContext(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/gettemplist/${currentTenant}`)
    }

    retriveTemplets(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getalltemplatetype/${currentTenant}`)
    }

    addTemplete(newtemplete){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addtemplate`,newtemplete )
    }
    deleteTemplete(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletetemp/${id}`)
    }
    ActiveTemplete(id){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/setactivetemplate/${id}`)
    }
    GetActiveTemplate(data,tenantId){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getactivetempbytype/${data}/${tenantId}`)
    }
    EditTemplete(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatetemplate`,data)
    }

    geteinvoicecredentials(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/geteinvoicecredentials/${currentTenant}`,)
    }

    addeInvoicecredentials(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addeinvoicecredentials`,data)
    }

    updateeInvoicecredentials(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateeinvoicecredentials`,data)
    }

    updateeinvoicestatus(id,data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateeinvoicestatus/${id}/${data}`,)
    }


    
    retrivePdfTemplets(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallpdftemplatetype/${currentTenant}`)
    }

    addPdfTemplete(newtemplete){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addpdftemplate`,newtemplete )
    }
    deletePdfTemplete(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletepdftemp/${id}`)
    }
    ActivePdfTemplete(id){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/setactivepdftemplate/${id}`)
    }
    GetActivePdfTemplate(data,currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getpdfactivetempbytype/${data}/${currentTenant}`)
    }
    EditPdfTemplete(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatepdftemplate`,data)
    }


    
    SoEditPrice(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesoeditprice`,data)
    }
      
    SoSalesCalucation(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesalescalreq`,data)
    }

    SalesValueRoundoff(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/salesvalueroundoff`,data)
    }

    Dnreq(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatednreq`,data)
    }

    SalesService(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesalesservicesetting`,data)
    }

    POupload(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatepouploadsetting`,data)
    }
    BackDateInvoiceStatus(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatebackdateinvoicestatus`,data)
    }

    SalesPandS(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesalespandssetting`,data)
    }


    PurchaseService(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatepurchaseservicesetting`,data)
    }

    PurchaseValueRoundoff(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/purchasevalueroundoff`,data)
    }

    PurchasePandS(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatepurchasepandssetting`,data)
    }

    InvUpload(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateinvuploadsetting`,data)
    }







    AdditionlParams(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateadditionalparams`,data)
    }

    MailbehaviorService(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/mailbehavior`,data)
    }

    TestMail(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/testmail`,data )
    }
    AddMailConfig(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addemailconfig`,data )
    }
    GetMailConfig(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getmailconfig/${currentTenant}`)
    }

    updateCustprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatecustprefix`,data)
    }

    updateSupprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesupprefix`,data)
    }

    updateEmployeeprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateemployeeprefix`,data)
    }

    updateSoprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesoprefix`,data)
    }

    updatePoprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatepoprefix`,data)
    }

    updateGrnprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updategrnprefix`,data)
    }

    updateSCNprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatescnprefix`,data)
    }

    updateInvprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateinvprefix`,data)
    }

    updateDnprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatednprefix`,data)
    }

    updateQtprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateqtprefix`,data)
    }

    updateAssetprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateassetprefix`,data)
    }

    updateRentalprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updaterentalprefix`,data)
    }

    updateSoldprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesoldprefix`,data)
    }

    updateImpprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateimmediateproductionprefix`,data)
    }
    updateEstprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateestimationprefix`,data)
    }
    updateJoprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatejoprefix`,data)
    }
    updateStoreprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatestoreprefix`,data)
    }

    GetEinvoicePrefix(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/geteinvoiceprefix/${id}`)
    }

    UpdateEinvoicePrefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updateeinvoiceprefix`,data)
    }

    updateCreditnoteprefix(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatecreditnoteprefix`,data)
    }


    Getlicencesoftenant(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getlicencesoftenant/${id}`)
    }

    Addlicencesoftenant(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addlicencesoftenant`,data)
    }

    Deletelicenseoftenant(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletelicenseoftenant/${id}`)
    }

    Updatelicencesoftenant(id){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatelicencesoftenant/${id}`)
    }

    // Sales Auto JRE API's

    AddAutoJRECreditLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addcreditledgerforautojre`,data)
    }
    
    AddAutoJRETaxLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addtaxledgerforautojre`,data)
    }

    AddAutoJREInsuranceLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addinsuranceledgerforautojre`,data)
    }
    AddAutoJRECreditLedgerofItems(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addcreditledgerofitemsforautojre`,data)
    }

    getSalesAutoJRECreditLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getautojrecreditledgers/${id}`)
    }

    getSalesAutoJREItemLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getautojreitemledgers/${id}`)
    }
    getSalesAutoJRETaxLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getautojretaxledgers/${id}`)
    }
    getSalesAutoJREInsuranceLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getautojreinsuranceledgers/${id}`)
    }

    SalesAutoJRE(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesalesautojre`,data)
    }

    insuranceledgerstatusactive(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/insuranceledgerstatusactive`,data)
    }

    deleteinsuranceledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteinsuranceledger/${id}`,)
    }

    deletecreditledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletecreditledger/${id}`,)
    }

    deleteitemledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteitemledger/${id}`,)
    }

    deletetaxledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletetaxledger/${id}`,)
    }

    getSalesAutoJRETCSLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getautojretcsledgers/${id}`)
    }

    AddAutoJRETCSLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addtcsledgerforautojre`,data)
    }

    
    TCSledgerstatusactive(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/tcsledgerstatusactive`,data)
    }

    deleteTCSledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletetcsledger/${id}`,)
    }


    //Purchase Auto JRE API's

    PurchaseAutoJRE(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatepurchaseautojre`,data)
    }
    
    AddPurchaseAutoJRECreditLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addpurchasecreditledgerforautojre`,data)
    }
    
    AddPurchaseAutoJRETaxLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addpurchasetaxledgerforautojre`,data)
    }

    AddPurchaseAutoJREInsuranceLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addpurchaseinsuranceledgerforautojre`,data)
    }
    AddPurchaseAutoJRECreditLedgerofItems(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addpurchasecreditledgerofitemsforautojre`,data)
    }

    getPurchaseAutoJRECreditLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getpurchaseautojrecreditledgers/${id}`)
    }

    getPurchaseAutoJREItemLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getpurchaseautojreitemledgers/${id}`)
    }
    getPurchaseAutoJRETaxLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getpurchaseautojretaxledgers/${id}`)
    }
    getPurchaseAutoJREInsuranceLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getpurchaseautojreinsuranceledgers/${id}`)
    }

    Purchaseinsuranceledgerstatusactive(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/purchaseinsuranceledgerstatusactive`,data)
    }

    deletePurchaseinsuranceledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/purchasedeleteinsuranceledger/${id}`,)
    }

    deletePurchasecreditledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletepurchasecreditledger/${id}`,)
    }

    deletePurchaseitemledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletepurchaseitemledger/${id}`,)
    }

    deletePurchasetaxledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletepurchasetaxledger/${id}`,)
    }

    getPurchaseAutoJRETCSLedgers(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getpurchaseautojretcsledgers/${id}`)
    }

    AddPurchaseAutoJRETCSLedger(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addpurchasetcsledgerforautojre`,data)
    }

    
    PurchaseTCSledgerstatusactive(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/purchasetcsledgerstatusactive`,data)
    }

    deletePurchaseTCSledger(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/purchasedeletetcsledger/${id}`,)
    }

    UpdateSettings(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesettings`,data)
    }

    UpdateJREEntryType(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatejreentrytype`,data)
    }


}

export default new SettingsServices();
