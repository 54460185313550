import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import Eye from "./Eye";
import {FormControl,InputLabel,Select,MenuItem, FormGroup, FormControlLabel, Checkbox, FormLabel, TextField} from "@mui/material";
import UserService from "../users/UserService.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import { Autocomplete } from "@mui/material";
import { currentTenant, userid } from "../Common/Credentials.js";
import MasterService from "../Masters/MasterService.js";
import { Spinner } from "react-bootstrap";
import WarehouseServices from "../Masters/Warehouse/WarehouseServices.js";
import DepartmentServices from "../Masters/Department/DepartmentServices.js"
import WorkCenterServices from "../Masters/WorkCenter/WorkCenterServices.js";

// const toggleicon=()=>{
//   const[toggle,settoggled] = React.useState(true);
//   const toggleImage = () => setToggled(!toggled);
// }

class NewUser extends Component {
  constructor(props) {
    super(props);

    const { name } = this.props.location.state;
    this.state = {
      type: "password",
      name: name,
      roleId: "",
      rolesList: [],
      id: "",
      first: "",
      last: "",
      username: "",
      email: "",
      password: "",
      active: "",
      roles: "",
      show: false,
      appmaster: "",
      warehouseList:[],
      departmentList:[],
      workcenterList:[],
      warehouse:'',
      whid:'',
      department:'',
      deptid:'',
      workcenter:'',
      wcid:"",
      usersList:[],

      pappmaster: "",
      paccounts: "",
      pinventory: "",
      psales: "",
      ppurchase: "",
      pproduction:'',
      passetmanagement:'',
      permissionlist: [],
      products: "",
      productList: [],
      uperror: false,
      loader: false,
      errormsg:'',
      subwarehousetypes:["Department","Workcenter"],
      subtype:"Department",
      dataaccess:"All",
      reporting:0,
      reportingto:'',
      reportingtoname:""
    };
    this.onSubmit = this.onSubmit.bind(this);
    // this.handleChange=this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
  }
  handleShow = () => {
    if (this.state.roles === "") {
      this.setState({ uperror: true });
    } else {
      this.setState({ show: true, uperror: false });
    }
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  // handleClick = () => this.setState(({type}) => ({
  //   type: type === 'text' ? 'password' : 'text'
  // }

  handleToChange = (e,value) => {
    if (value === "" || value === null || value === undefined) {
      setTo('')
      setToName('')
    }
    else{
    this.setState({reportingto:value.id,reportingtoname:value.first +" "+value.last})
    }
  };

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

    handleWarehouse = (e,values) => {
      if(typeof values !== 'object' || values === undefined ||values ==='' || values===null ){
        this.setState({ warehouse: '',whid:'', workcenter: '',wcid:'',department:'',deptid:''  });
      }else{  
      this.setState({ warehouse: values.name,whid:values.id, workcenter: '',wcid:'',department:'',deptid:''  });
      const id = values.id;
      DepartmentServices.deptbywarehouse(id).then((response) =>
        this.setState({
          departmentList: response.data
        })
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    
    WorkCenterServices.WorkCenterbywarehouse(id).then((response) => {
      this.setState({ workcenterList: response.data });
    })
      }
    };

    handleSubChange = (e) =>{
      this.setState({subtype:e.target.value})
    }


    handleRoleChange=(e,values)=>{
        if(typeof values !== 'object' || values === undefined ||values ==='' || values===null ){
          this.setState({
            roleId: '',
            roles: '',
            permissionlist:[]
          })
        }
        else{
        this.setState({
          roles: values.name,
          roleId: values.id,
          permissionlist:[]
        },()=>MasterService.getRolesProducts(values.id).then((response)=>
        this.setState({ productList: response.data })
        ))
        
      }
     
    }
  onSubmit(values) {
    // let username = AuthenticationService.getLoggedInUser()
    this.setState({ loader: true });
    let newusers = {
      first: values.first.trim(),
      last: values.last.trim(),
      username: values.username.trim(),
      email: values.email.trim(),
      password: values.password.trim(),
      tenantId: currentTenant,
      rolename: values.roles,
      roleid:this.state.roleId,
      permissionlist: this.state.permissionlist,
      whid:this.state.whid,
      deptid:this.state.deptid,
      wcid:this.state.wcid,
      dataaccess:this.state.dataaccess,
      reporting:this.state.reporting,
      reportingto:this.state.reportingto
    };
    let updateusers = {
      id: this.state.id,
      first: values.first.trim(),
      last: values.last.trim(),
      username: values.username.trim(),
      email: values.email.trim(),
      tenantId: currentTenant,
      roleid:this.state.roleId,
      // password: values.password,
      active: values.active,
      rolename: values.roles,
      permissionlist: this.state.permissionlist,
      whid:this.state.whid,
      deptid:this.state.deptid,
      wcid:this.state.wcid,
      dataaccess:this.state.dataaccess,
      reporting:this.state.reporting,
      reportingto:this.state.reportingto
    };
    if (this.state.id < 1) {
      UserService.createUsers(newusers).then(() =>
        this.props.navigate(`/users`)
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    } else {
      UserService.updateUsers(updateusers).then(() =>
        this.props.navigate("/users")
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
  }

  componentDidMount() {
    const { id } = this.props.location.state;

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    UserService.FindAllManagers(currentTenant).then((res)=>this.setState({usersList:res.data}))

    WarehouseServices.getWarehouses(currentTenant).then((response)=>{
      this.setState({warehouseList:response.data},()=>{
        if (id < 1) {
          // this.setState(id)
          return;
        }
        UserService.retriveUser(id).then((res) =>
          this.setState(
            {
              id: id,
              first: res.data.user.first,
              last: res.data.user.last,
              username: res.data.user.username,
              email: res.data.user.email,
              password: res.data.user.password,
              roles: res.data.user.roles.find(e=>e).name,
              roleId:res.data.user.roles.find(e=>e).id,
              active: res.data.user.active,
              whid:res.data.user.whid,
              deptid:res.data.user.deptid,
              wcid:res.data.user.wcid,
              reporting:res.data.user.reporting==="Manager"?0:res.data.user.reporting==="ReportTo"?2:1,
              reportingto:res.data.user.reportingto,
              subtype:res.data.user.deptid === ''||res.data.user.deptid===null||res.data.user.deptid===undefined?"Workcenter":"Department",
              warehouse:this.state.warehouseList.find(e=>e.id===res.data.user.whid)===undefined?'':this.state.warehouseList.find(e=>e.id===res.data.user.whid).name,
              department:res.data.user.deptid===''||res.data.user.deptid===null||res.data.user.deptid===undefined?'':this?.state?.warehouseList?.find(e=>e.id===res?.data?.user?.whid)?.department?.find(d=>d.id===res?.data?.user?.deptid)?.name,
              workcenter:res.data.user.wcid===''||res.data.user.wcid===null||res.data.user.wcid===undefined?'':this.state.warehouseList?.find(e=>e.id===res?.data?.user?.whid)?.workcentre?.find(w=>w.id===res?.data?.user?.wcid)?.name,
              // pappmaster:res.data.permissionlist.find(e=>e.productid===1)?.permission.toString(),
              // paccounts:res.data.permissionlist.find(e=>e.productid===3)?.permission.toString(),
              // pinventory:res.data.permissionlist.find(e=>e.productid===4)?.permission.toString(),
              // psales:res.data.permissionlist.find(e=>e.productid===5)?.permission.toString(),
              // ppurchase:res.data.permissionlist.find(e=>e.productid===6)?.permission.toString(),
              // pproduction:res.data.permissionlist.find(e=>e.productid===7)?.permission.toString(),
              // passetmanagement:res.data.permissionlist.find(e=>e.productid===8)?.permission.toString(),
              permissionlist:res.data.permissionlist,
              dataaccess:res.data.user.dataaccess
              // productList:res.data.permissionlist.map(e=>Object.assign(e,{permissionenum:e.permission.toString()}))
            },()=>MasterService.getRolesProducts(this.state.roleId).then((response)=>
               this.setState({productList:response.data})
            ))
        );
      })
  })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

    MasterService.getallRoles(currentTenant).then((response) =>
      this.setState({
        rolesList: response.data,
      })
    );
    // MasterService.getProducts(currentTenant).then((response)=>this.setState({products:response.data}))
    
    // MasterService.getProducts(currentTenant).then((response) => {
    //   let productslist = response.data;
    //   this.setState({ productList: response.data });
    //   let appmaster = productslist.filter((e) =>
    //     e.productid === 1 ? e.productsEnabled : ""
    //   );
    //   let pappmaster = appmaster.map((e) => e.productsEnabled);
    //   // let pappmasterid = appmaster.map((e)=>e.id)
    //   this.setState({ pappmaster: pappmaster.toLocaleString() });

    //   // let auth = productslist.filter((e)=> e.productid===2?e.productsEnabled:"")
    //   // this.setState({pauth:auth.map((e)=>e.productsEnabled.toLocaleString())})
    //   // Products.setProductAuth(auth.map((e)=>e.productsEnabled))

    //   let accounts = productslist.filter((e) =>
    //     e.productid === 3 ? e.productsEnabled : ""
    //   );
    //   let paccounts = accounts.map((e) => e.productsEnabled);
    //   this.setState({ paccounts: paccounts.toLocaleString() });

    //   let inventory = productslist.filter((e) =>
    //     e.productid === 4 ? e.productsEnabled : ""
    //   );
    //   let pinventory = inventory.map((e) => e.productsEnabled);
    //   this.setState({ pinventory: pinventory.toLocaleString() });
    //   // Products.setProductInventory(inventory.map((e)=>e.productsEnabled))

    //   let sales = productslist.filter((e) =>
    //     e.productid === 5 ? e.productsEnabled : ""
    //   );
    //   let psales = sales.map((e) => e.productsEnabled);
    //   this.setState({ psales: psales.toLocaleString() });
    //   // Products.setProductSales(sales.map((e)=>e.productsEnabled))

    //   let purchase = productslist.filter((e) =>
    //     e.productid === 6 ? e.productsEnabled : ""
    //   );
    //   let ppurchase = purchase.map((e) => e.productsEnabled);
    //   this.setState({ ppurchase: ppurchase.toLocaleString() });
    //   // Products.setProductPurchase(purchase.map((e)=>e.productsEnabled))
    // });

    //let {id} = this.props.location.state
   
  }

  validate(values) {
    let errors = {};
    if (!values.first) {
      errors.first = "First Name is required";
    }

    if (values.first.length <4 || values.first.length>40) {
      errors.first = "First Name must contain 4 to 40 characters";
    }

    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.last) {
      errors.last = "Last Name is required";
    }
    if (values.last.length <4 || values.last.length>40) {
      errors.last = "Last Name must contain 4 to 40 characters";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (values.username.length <4 || values.username.length >=15) {
      errors.username = "Last Name must contain 4 to 15 characters";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }else if (values.password.length <6) {
      errors.password = "Password should be at least 6 characters";
    }
    if (!values.roles) {
      errors.roles = "Roles is required";
    }

    if (this.state.whid === '') {
      errors.warehouse = "Org Unit is required";
    }
    if(this.state.subtype==="Department"){
    if (this.state.deptid === '') {
      errors.department = "Department is required";
    }
  }else{
    if (this.state.wcid === '') {
      errors.workcenter = "Workcenter is required";
    }
  }

    return errors;
  }

  handleChange = (pro, value) => {
    let productid = pro.id;
    let permissions = value;
    let data = this.state.permissionlist
    data = data.filter((e) => e.productid !== productid)
    data.push({
        productid: productid,
        permissionenum: permissions,
      })
    this.setState({permissionlist:data})

    // this.setState({permissionlist:this.state.permissionlist.filter(
    //   (e) => e.productid !== productid
    // )},()=> this.state.permissionlist.push({
    //   productid: productid,
    //   permissionenum: permissions,
    // })
    // )
  };

  render() {
    let setroles = this.state.rolesList;
    // let optionState = this.props.optionState;
    let {
      id,
      first,
      last,
      username,
      email,
      password,
      active,
      roles,
      show,
      appmaster,
      accounts,
      inventory,
      sales,
      purchase,
      warehouseList,
      departmentList,
      workcenterList,
      productList,
      warehouse,
      department,
      workcenter,
      whid,
      deptid,
      wcid,

    } = this.state;
    const isupdateuser = this.state.name;
    const isactive = this.state.active;
    let displayemail;
    let displayusername;
    let passwordfield;
    let displayactive;
    if (isupdateuser === "Update User") {
      displayemail = (
        <fieldset disabled className="form-group col-md-3 pb-3">
          <FormikTextField
            className="form-control"
            type="text"
            name="email"
            label="Email"
          />
        </fieldset>
      );
      displayusername = (
        <fieldset disabled className="form-group col-md-3 pb-3">
          <FormikTextField
            className="form-control"
            type="text"
            name="username"
            label="User Name"
          />
        </fieldset>
      );
      passwordfield = (
        <fieldset className="form-group col-md-3" hidden>
          <label>Password</label>
          <Field
            className="form-control  "
            type={this.state.type}
            id="password-field"
            name="password"
            placeholder="***********"
          />
          {/* <span className="container-fluid" onClick={this.handleClick}>{this.state.type === 'text' ? 'Hide' : 'Show Password'}</span> */}
          {/* <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password" onClick={this.handleClick}>{this.state.type === 'text' ?  '' :  '' }</span> */}

          {/* <ErrorMessage name="password" component="div" className="text-danger float-sm-right" /> */}
        </fieldset>
      );

      if (isactive === "ACTIVE") {
        displayactive = (
          <>
            <fieldset className="form-group col-md-6 mt-2">
              <div className=" from-row ">
                <span className="mr-4">Status</span>
                <div className="col-sm-12">
                  <div className="form-check form-check-inline">
                    <span className="radio-inline">
                      <Field
                        name="active"
                        value="1"
                        id="active"
                        checked={true}
                        className="mr-2 leading-tight"
                        type="radio"
                      />
                      Active
                    </span>
                  </div>
                  <div className="form-check form-check-inline">
                    <span className="radio-inline">
                      <Field
                        name="active"
                        id="inactive"
                        value="0"
                        className=" mr-2 leading-tight "
                        type="radio"
                      />
                      Inactive
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
          </>
        );
      } else {
        displayactive = (
          <fieldset className="form-group col-md-6 mt-2">
            <div className=" from-row ">
              <div className="col-sm-12">
                <div className="form-check form-check-inline">
                  <span className="radio-inline">
                    <span className="mr-4 mt-2">Status</span>
                    <Field
                      name="active"
                      value="1"
                      id="active"
                      className="mr-2 leading-tight"
                      type="radio"
                    />
                    Active
                  </span>
                </div>
                <div className="form-check form-check-inline">
                  <span className="radio-inline">
                    <Field
                      name="active"
                      id="inactive"
                      checked={true}
                      value="0"
                      className=" mr-2 leading-tight "
                      type="radio"
                    />
                    Inactive
                  </span>
                </div>
              </div>
            </div>
          </fieldset>
        );
      }
    } else {
      displayemail = (
        <fieldset className="form-group col-md-3 pb-3">
          <FormikTextField
            className="form-control"
            type="email"
            name="email"
            label="Email"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value,errormsg:'' })}
          />
        </fieldset>
      );
      displayusername = (
        <fieldset className="form-group col-md-3 pb-3">
          <FormikTextField
            className="form-control"
            type="text"
            name="username"
            label="User Name"
            value={this.state.username}
            onChange={(e) => this.setState({ username: e.target.value.trim(),errormsg:'' })}
          />
        </fieldset>
      );
      passwordfield = (
        <fieldset className="form-group col-md-3 pb-3">
          <FormikTextField
            className="form-control"
            type={this.state.type}
            id="password-field"
            name="password"
            label="Password"
            value={this.state.password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />

          <span
            style={{
              position: "absolute",
              width: "20px",
              right: "20px",
              top: "20px",
            }}
            onClick={this.handleClick}
          >
            <i>
              <Eye />
            </i>
          </span>

          {/* <span className="pw-icon-show" style={{position: 'absolute',width: '20px',right:'20px',top:'38px'}}><i><img src="eye-solid.svg" alt="" /></i></span> */}

          {/* <Field className="form-control  " 
    type={this.state.type}
    id="password-field"
    name="password" placeholder="Enter Password"
    />                   */}
          {/* <span className="container-fluid" onClick={this.handleClick}>{this.state.type === 'text' ? 'Hide' : 'Show Password'}</span> */}
          {/* <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password" onClick={this.handleClick}>{this.state.type === 'text' ?  '' :  '' }</span> */}
          {/* 
    <ErrorMessage name="password" component="div" className="text-danger float-sm-right" /> */}
        </fieldset>
      );
    }
    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className="text-secondary">
            <Link as="button" className=" text-secondary" to={{ pathname: "/users"}} state= {{message: ""}}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/users">Users</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>
            <div className="text-center text-danger">{this.state.errormsg}</div>
            {/* <div>
              <Link
                as="button"
                className="float-left btn btn-sm bg-white ml-3 shadow-sm"
                to={{ pathname: "/users", state: { message: "" } }}
              >
                <b>
                  <i className="fas fa-arrow-left"></i>
                </b>{" "}
                Back
              </Link>
            </div> */}

            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  first,
                  last,
                  username,
                  email,
                  password,
                  active,
                  roles,
                  show,
                  appmaster,
                  accounts,
                  inventory,
                  sales,
                  purchase,
                  warehouseList,
                  departmentList,
                  workcenterList,
                  warehouse,
                  department,
                  workcenter,
                  productList,
                  whid,
                  deptid,
                  wcid,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ props, setFieldValue, values }) => (
                  <Form >
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="first"
                          label="First Name"
                          value={this.state.first}
                          onChange={(e) =>
                            this.setState({ first: e.target.value.trim() })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="last"
                          label="Last Name"
                          value={this.state.last}
                          onChange={(e) =>
                            this.setState({ last: e.target.value.trim() })
                          }
                        />
                      </fieldset>

                      {displayemail}
                      {displayusername}
                      {passwordfield} 
                      <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="warehouse"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={warehouseList}
                                    getOptionLabel={(option) => option.name}
                                    // 
                                    // getOptionSelected={(option) =>
                                    //   option.name
                                    // }
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={this.handleWarehouse}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={this.state.warehouse}
                                        label={`Select Org Unit (${this.state.warehouse})`}
                                        variant="standard"
                                        fullwhidth={true}
                                      />
                                    )}
                                  />
                                </fieldset>

                                <fieldset className="form-group col-md-3">
                                  <FormControl  sx={{ minWidth: 120 }} className="col-md-12">
                                    <InputLabel id="demo-simple-select-filled-label">Select Dept/WC</InputLabel>
                                    <Select value={this.state.subtype} onChange={this.handleSubChange} variant="standard">
                                      {this.state.subwarehousetypes.map((option) => {
                                        return (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </fieldset>
                                  {this.state.subtype ==="Department"?
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="department"
                                    className="subacc-select"
                                    name="department"
                                    options={departmentList}
                                    getOptionLabel={(option) => option.name}
                                    // 
                                    // getOptionSelected={(option) =>
                                    //   option.name
                                    // }
                                    value={
                                      values.department ? undefined : undefined
                                    }
                                    onChange={(e,values) =>values === "" || values === undefined || typeof values !=='object' || values === null ?
                                    this.setState({
                                      department: '',
                                      deptid: ''
                                    }):
                                    this.setState({
                                      department: values.name,
                                      deptid: values.id,
                                    })}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="department"
                                        value={this.state.department} 
                                        label={`Select Department (${this.state.department})`}
                                        variant="standard"
                                        fullwhidth={true}
                                      />
                                    )}
                                  />
                                </fieldset>
                                :
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="workcenter"
                                    className="subacc-select"
                                    name="workcenter"
                                    options={workcenterList}
                                    getOptionLabel={(option) => option.name}
                                    // 
                                    // getOptionSelected={(option) =>
                                    //   option.name
                                    // }
                                    value={
                                      values.workcenter ? undefined : undefined
                                    }
                                    onChange={(e, value)=>
                                      value === "" || value === undefined || typeof value !=='object' || value === null ?
                                          this.setState({
                                            workcenter:'',
                                            wcid:'',
                                          }):
                                          this.setState({
                                            workcenter:value.name,
                                            wcid: value.id,
                                          })
                                        
                                    }
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="workcenter"
                                        value={this.state.workcenter}
                                        label={`Select Workcenter/Production Unit(${this.state.workcenter})`}
                                        variant="standard"
                                        fullwhidth={true}
                                      />
                                    )}
                                  />
                                </fieldset>
                                }

                      <fieldset className="form-group col-md-3">
                        {/* <label>Roles<span className="text-danger float-sm-right">*</span></label>
                        {/* {roles.map((id, name) => {return <li>key={id}{id.name}</li>})} */}
                        {/* <Field as="select" name="roles" className="form-control" value={optionState}>
                          <option >Select Role...</option>
                          {setroles.map((id, name) => {
                            return (
                              <option key={name} value={id.roleId}>
                                {id.name}
                              </option>)  
                          })}
                        </Field>
                        <ErrorMessage  name="roles" component="div"  className="text-danger float-sm-right" />  */}
                        <Autocomplete
                          id="roles"
                          className="subacc-select"
                          name="roles"
                          options={setroles}
                          getOptionLabel={(option) => option.name}
                          // 
                          // 
                          value={values.roles ? undefined : undefined}
                          onChange={this.handleRoleChange}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="roles"
                              value={this.state.roles}
                              label={`Select Role (${this.state.roles})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>


                      <fieldset className="form-group col-md-12" />
                      <FormGroup row>
                        <FormLabel component="legend">Restrict data access</FormLabel>
                        <FormControlLabel control={<Checkbox checked={this.state.dataaccess==="All"} onClick={()=>this.setState({dataaccess:"All"})} size="small" />} label="All data" />
                        <FormControlLabel control={<Checkbox checked={this.state.dataaccess==="Organization"} onClick={()=>this.setState({dataaccess:"Organization"})} size="small" />} label="Organization data" />
                        <FormControlLabel control={<Checkbox checked={this.state.dataaccess==="User"} onClick={()=>this.setState({dataaccess:"User"})} size="small" />} label="User data" />
                      </FormGroup>

                      <fieldset className="form-group col-md-12">
                      <FormGroup row>
                        <FormLabel component="legend">Reporting</FormLabel>
                        <FormControlLabel control={<Checkbox checked={this.state.reporting===0} onClick={()=>this.setState({reporting:0})} size="small" />} label="Manager" />
                        <FormControlLabel control={<Checkbox checked={this.state.reporting===1} onClick={()=>this.setState({reporting:1})} size="small" />} label="Manager & Reports To" />
                        <FormControlLabel control={<Checkbox checked={this.state.reporting===2} onClick={()=>this.setState({reporting:2})} size="small" />} label="Reports To" />
                      </FormGroup>
                           {this.state.reporting !== 0?
                            <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="reportingtoname"
                                              className="subacc-select"
                                              name="reportingtoname"
                                              options={this.state.usersList}
                                              getOptionLabel={(option) =>
                                                  option.first+" "+option.last
                                              }
                                              value={
                                                values.reportingto
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={this.handleToChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={`Select Reporting To (${this.state.reportingtoname})`}
                                                  type="text"
                                                  value={this.state.reportingtoname}
                                                  variant="standard"
                                                  error={this.state.reportingto === ""}
                                                  helperText={
                                                    this.state.reportingto === "" ? "Empty field!" : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                          :''} 

                      </fieldset>


                      {displayactive}

                      <fieldset className="form-group col-md-12" />
                      {this.state.roleId === "" ? (
                        ""
                      ) : (
                        <>

                          {this.state.productList.map((pro,i) =>
                          <>
                              <fieldset className="form-group col-md-12 m-0 p-0" key={i}>
                                <fieldset className="form-row">
                                  <div className=" from-group col-md-3">
                                    <h6>{pro.products}
                                   {this.state.permissionlist.map(e=>
                                     e.productid === pro.id?e.permission===0||e.permissionenum==='0'?"- None":e.permission===2||e.permissionenum==="2"?"- Alter":e.permission===3||e.permissionenum==="3"?"- Approve":""
                                     :""
                                   )}</h6>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-check form-check-inline mr-1">
                                      <span className="radio-inline">
                                        <Field
                                          name={pro.products}
                                          value="0"
                                          // id="active"
                                          clicked={this.state.appmaster}
                                          onClick={(e) =>
                                            this.handleChange(
                                              pro,
                                              e.target.value
                                            )
                                          }
                                          style={{width:'17px',height:'17px'}}
                                          className="mr-2 leading-tight"
                                          type="radio"
                                        />
                                        None &nbsp;&nbsp;
                                      </span>
                                    </div>
                                    <div className="form-check form-check-inline mr-1">
                                      <span className="radio-inline">
                                        <Field
                                          name={pro.products}
                                          // id="inactive"
                                          value="2"
                                          clicked={this.state.appmaster}
                                          onClick={(e) =>
                                            this.handleChange(
                                              pro,
                                              e.target.value
                                            )
                                          }
                                          style={{width:'17px',height:'17px'}}
                                          className=" mr-2 leading-tight "
                                          type="radio"
                                        />
                                        Alter &nbsp;&nbsp;
                                      </span>
                                    </div>
                                    <div className="form-check form-check-inline mr-1">
                                      <span className="radio-inline">
                                        <Field
                                          name={pro.products}
                                          value="3"
                                          // id="active"
                                          clicked={this.state.appmaster}
                                          onClick={(e) =>
                                            this.handleChange(
                                              pro,
                                              e.target.value
                                            )
                                          }
                                          style={{width:'17px',height:'17px'}}
                                          className="mr-2 leading-tight"
                                          type="radio"
                                        />
                                        Approve &nbsp;&nbsp;
                                      </span>
                                    </div>
                                  </div>
                                </fieldset>
                              </fieldset>
                         </> )}

                        </>
                      )}
                    </div>
                    <fieldset className="form-group col-md-12" />
                    {this.state.permissionlist.length===this.state.productList.length ?                   
                      this.state.loader === true ? (
                        <button className="btn btn-sm hovbuttonColour" disabled >
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving"}
                          </button>
                      ) : (
                        <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fa fa-save"/> Save</button>
                      )
                      :
                      <button className="btn btn-sm hovbuttonColour" disabled> <i className="fa fa-save mr-1"/>Save</button>
                    }
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/users"}} state= {{ message: ""}}
                    >
                      <b className="text-danger"> X </b>Close
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewUser);
