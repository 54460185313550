import React, { useEffect, useState } from 'react'
import withRouter from '../../withRouter';
import { ThemeProvider, createTheme } from '@mui/material';
import ProjectManagementServices from '../ProjectManagementServices';
import CustomToolbar from '../../CommonComponents/CustomToolbar';
import { Link } from 'react-router-dom';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { spin } from '../../Common/NewLoader';
import MUIDataTable from 'mui-datatables';
import Footer from '../../Component/Footer';
import { currentTenant } from '../../Common/Credentials';
import moment from 'moment';
import Swal from 'sweetalert2';

const theme = createTheme({
    overrides: {
       MuiTableCell: {
        head: { backgroundColor: "#e9ecef !important", border: [[1, 'solid', '#ece6e6']],},
        body: {border: [[1, 'solid', '#ece6e6']],},
      },},
  });

const ProjectIndenting = () => {
    const [loading, setLoading] = useState(false);
    const [data,setData] = useState([])

    useEffect(()=>{
      setLoading(true)
      ProjectManagementServices.GetEstimationParameters(currentTenant).then((response)=>{
       setData(response.data)
       setLoading(false)
      }).catch(()=>setLoading(false))
    },[])

    const handleDelete =(id)=>{
        Swal.fire({
            title: `Are you sure you want to delete?`,
            text: ".",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor:'#152f5f',
            cancelButtonText: "No",
          }).then((result)=>{
          if(result.value) {
                ProjectManagementServices.DeleteEstimationParameters(id).then(()=>
                ProjectManagementServices.GetEstimationParameters(currentTenant).then((response)=>{
                    setData(response.data)
                })
                )
          }})
    }
  
    const columns =[
      {name:"id",label:"Id", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      {name:"type",label:"Type", options: { filter: true, sort: true } },
      {name:"createdby",label:"Created By", options: { filter: true, sort: true } },  
      // {name:"type",label:"Updated At", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"type",label:"Activity ID", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"type",label:"Customer ID", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"id",label:"Delivary Date", options: { filter: true, sort: true } },
      // {name:"id",label:"Indent ID", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"id",label:"Milestone ID", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"preparedBy",label:"Prepared By", options: { filter: true, sort: true } },
      // {name:"id",label:"Project ID", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"id",label:"Project Manager ID", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"id",label:"Remark", options: { filter: true, sort: true } },
      // {name:"id",label:"Site Incharge", options: { filter: true, sort: true } },
      // {name:"id",label:"Tenent Id", options: { filter: false, sort: false,display: false,viewColumns: false, } },
      // {name:"id",label:"Updated By", options: { filter: true, sort: true } },

      {
          name: "Action",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <i
                  as="button"
                  className="fa fa-trash text-danger cursorPointer"
                  onClick={()=>handleDelete(tableMeta.rowData.find((e) => e))}
                ></i>
              );
            },
          },
        },
    ]
    const options = {
      filterType: 'checkbox',
      selectableRows: "none",
      customToolbar: () => {
       return (
       <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newprojectindenting"}} state ={{ id: -1, pagename: 'Add Project Indenting'  }}>
         <CustomToolbar />
       </Link>
       );
     },
     onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: "Project Indenting",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    }

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/projectmanagement">Home</Link>
              </li> 
              <li className="breadcrumb-item active">Project Indenting</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div>
                          {loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <div className="form-group col-md-12">
                                 <ThemeProvider theme={theme}> 
                          <MUIDataTable
                                className="table-sm px-3"
                                title={"Project Indenting"}
                                data={data}
                                columns={columns}
                                options={options}
                              />
                              </ThemeProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
  
        <Footer />
      </div>
    );
}

export default withRouter(ProjectIndenting)