import React, { useEffect, useState } from "react";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ProjectManagementServices from "../ProjectManagementServices";
import { spin } from "../../Common/NewLoader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { format } from "date-fns";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { id } from "date-fns/locale";

const formatDate = (dateString) => {
  return format(new Date(dateString), "dd-MM-yyyy HH:mm");
};

const ProjectEstimationDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    ProjectManagementServices.GetProjectEstimationById(params.id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [params]);

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <Link
              as="button"
              className="text-secondary"
              to="/projectestimation"
            >
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectestimation">Project Estimation</Link>
            </li>
            <li className="breadcrumb-item active">
              Project Estimation Details
            </li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="card-body">
                {loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <table style={{ margin: "1px 0" }}>
                        <tbody>
                          <tr>
                            <td style={{ paddingRight: "20px" }}>
                              Estimation Id
                            </td>
                            <td>:&nbsp;{data.estimationId}</td>
                          </tr>
                          <tr>
                            <td>Project</td>
                            <td>:&nbsp;{data.project.projectName}</td>
                          </tr>
                          <tr>
                            <td>Prepared By</td>
                            <td>:&nbsp;{data.preparedBy}</td>
                          </tr>
                          <tr>
                            <td>P.M Name</td>
                            <td>
                              :&nbsp;
                              {data.project.projectmanager[0].managerName}
                            </td>
                          </tr>
                          <tr>
                            <td>P.M No.</td>
                            <td>
                              :&nbsp;
                              {data.project.projectmanager[0].managerContactNo}
                            </td>
                          </tr>
                          <tr>
                            <td>P.M Email</td>
                            <td>
                              :&nbsp;
                              {data.project.projectmanager[0].managerEmail}
                            </td>
                          </tr>
                          <tr>
                            <td>Remarks</td>
                            <td>:&nbsp;{data.remark}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-6">
                      <table>
                        <tbody>
                          <tr>
                            <td>Client Name</td>
                            <td>
                              :&nbsp;
                              {data.project.clientassign[0]?.customerName}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingRight: "20px" }}>
                              Client Contact
                            </td>
                            <td>
                              :&nbsp;
                              {data.project.clientassign[0]?.customerContactNo}
                            </td>
                          </tr>
                          <tr>
                            <td>Client Email</td>
                            <td>
                              :&nbsp;
                              {data.project.clientassign[0]?.customerEmailId}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-12 mt-3">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ backgroundColor: "#e8ecef" }}
                        >
                          <Typography>Project Site</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="col-md-12">
                            {data.project.site &&
                              data.project.site.length > 0 &&
                              data.project.site.map((site) => (
                                <div key={site.id}>
                                  <div>
                                    <Table
                                      className="float-left table-border"
                                      size="small"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Site Name</TableCell>
                                          <TableCell>Site Code</TableCell>
                                          <TableCell>Status</TableCell>

                                          <TableCell>
                                            Incharge Details
                                          </TableCell>

                                          <TableCell>Site Address</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>{site.siteName}</TableCell>
                                          <TableCell>{site.siteCode}</TableCell>
                                          <TableCell>{site.status}</TableCell>

                                          <TableCell>
                                            {site.siteIncharges &&
                                              site.siteIncharges.length > 0 && (
                                                <Table
                                                  className="float-left table-border border"
                                                  size="small"
                                                >
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>
                                                        Incharge Name
                                                      </TableCell>
                                                      <TableCell>
                                                        Contact Number
                                                      </TableCell>
                                                      <TableCell>
                                                        Email
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {site.siteIncharges.map(
                                                      (incharge) => (
                                                        <TableRow
                                                          key={incharge.id}
                                                        >
                                                          <TableCell>
                                                            {
                                                              incharge.inchargeName
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              incharge.inchargeContactNo
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              incharge.inchargeEmail
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              )}
                                          </TableCell>

                                          <TableCell>
                                            {site.address},{site.city},{" "}
                                            {site.state}, {site.country}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>

                                    {/* <table className="col-md-12">
                                <tbody>
                                  <tr>
                                    <td>Site Name</td>
                                    <td>{site.siteName}</td>
                                  </tr>
                                  <tr>
                                    <td>Site Code</td>
                                    <td>{site.siteCode}</td>
                                  </tr>
                                  <tr>
                                    <td>Status</td>
                                    <td>{site.status}</td>
                                  </tr>
                                  <tr>
                                    <td>Site Address</td>
                                    <td>{site.address}</td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td>{site.city}, {site.state}, {site.country}</td>
                                  </tr>
                                </tbody>
                              </table> */}
                                  </div>

                                  {/* <div className="col-md-12">
                              <FormLabel>Site Incharges:</FormLabel>
                              {site.siteIncharges && site.siteIncharges.length > 0 && (
                                <Table className="float-left table-border" size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Incharge Name</TableCell>
                                      <TableCell>Contact Number</TableCell>
                                      <TableCell>Email</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {site.siteIncharges.map((incharge) => (
                                      <TableRow key={incharge.id}>
                                        <TableCell>{incharge.inchargeName}</TableCell>
                                        <TableCell>{incharge.inchargeContactNo}</TableCell>
                                        <TableCell>{incharge.inchargeEmail}</TableCell>

                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              )}
                            </div> */}
                                </div>
                              ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-md-12 mt-3">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ backgroundColor: "#e8ecef" }}
                        >
                          <Typography>Activities</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="col-md-12">
                            <FormLabel></FormLabel>
                            {data.activities &&
                              data.activities.length > 0 &&
                              data.activities.map((activity) => (
                                <div key={activity.id}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Activity Name</TableCell>
                                      <TableCell>Estimated Days</TableCell>
                                      <TableCell>Start Date</TableCell>
                                      <TableCell>End Date</TableCell>
                                      <TableCell>Milestone Details</TableCell>
                                      <TableCell> Budget</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableRow key={activity.id}>
                                    <TableCell>
                                      {activity.activityName}
                                    </TableCell>
                                    <TableCell>
                                      {activity.estimatedDays}
                                    </TableCell>
                                    <TableCell>
                                      {formatDate(activity.startDate)}
                                    </TableCell>
                                    <TableCell>
                                      {formatDate(activity.endDate)}
                                    </TableCell>

                                    <TableCell>
                                      <div className="col-md-12">
                                        {activity.milestones &&
                                          activity.milestones.length > 0 && (
                                            <Table
                                              className="float-left table-border border"
                                              size="small"
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell>
                                                    Milestone Name
                                                  </TableCell>
                                                  <TableCell>
                                                    Estimated Days
                                                  </TableCell>
                                                  <TableCell>
                                                    Start Date
                                                  </TableCell>
                                                  <TableCell>
                                                    End Date
                                                  </TableCell>
                                                  <TableCell>Budget</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {activity.milestones.map(
                                                  (milestone) => (
                                                    <TableRow key={milestone.id}>
                                                      <TableCell>
                                                        {
                                                          milestone.milestoneName
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          milestone.estimatedDays
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {formatDate(
                                                          milestone.startDate
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {formatDate(
                                                          milestone.endDate
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {milestone.budget}
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                              </TableBody>
                                            </Table>
                                          )}
                                      </div>
                                    </TableCell>
                                    <TableCell>{activity.budget}</TableCell>
                                  </TableRow>
                                </div>
                              ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-md-12 mt-3">
                      <button
                        type="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        // onClick={}
                        disabled={loader}
                      >
                        Approve
                      </button>


                      <Link
                        type="button"                      
                        to={{ pathname: "/newprojectestimation" }}
                        state={{
                         id: params.id,
                          pagename: "Update Project-Estimation",
                        }}                        
                        className="btn btn-sm hovbuttonColour mr-2"
                        // onClick={}
                        disabled={loader}
                      >
                       <i className="fas fa-pen"/> Edit
                      </Link>


                      <Link
                        as="button"
                        className="btn btn-sm deletebutton"
                        to={{ pathname: "/projectestimation" }}
                        state={{ message: "" }}
                      >
                        <b className="text-danger"> X </b>Close
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ProjectEstimationDetails;
