import React, { Component } from "react";
import withRouter from "../withRouter.js";
import MUIDataTable from "mui-datatables";
import {NumericFormat} from "react-number-format";
import { currentTenant, thousandsdisplay, userid } from "../Common/Credentials.js";
import { FormControlLabel, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { Spinner } from "react-bootstrap";
import NewLedgerService from "./Ledgers/NewLedgerService.js";
import moment from "moment";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class OpeningEntries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openingrefunds:[],
      openingtaxes:[],
      message: "",
      loading:false,
      loader1:true,
      loader:false,
      taxnt:false
    };
    this.refreshItems = this.refreshItems.bind(this);
  }

  refreshItems() {

    NewLedgerService.getPendingTaxes(currentTenant).then(res=>
      this.setState({openingtaxes:res.data})
    )
    NewLedgerService.getPendingOpenings(currentTenant).then(res=>
      this.setState({openingrefunds:res.data})
      )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
   
  }

  submitRecordOpeningCR(value){

    let pendingpo
    if(value!==undefined){
    pendingpo=this.state.openingrefunds.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/dnentry`},{state:pendingpo});
  
   }

   submitRecordOpeningDR(value){
    let pendingso
    if(value!==undefined){
    pendingso=this.state.openingrefunds.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/cnentry`}, {state:pendingso});
   }

  submitRecordOpeningTaxPay(value){
    let pendingso
    if(value!==undefined){
    pendingso=this.state.openingtaxes.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/dnentry`}, {state:pendingso});
   }

   submitRecordOpeningTaxRec(value){
    let pendingpo
    if(value!==undefined){
    pendingpo=this.state.openingtaxes.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/cnentry`},{state:pendingpo});
  
   }


  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    //  this.setState({loading:true})

    this.refreshItems();
  }

  handleTypeChange=()=>{
  //  this.props.navigate(1);
   this.setState({taxnt:this.state.taxnt===true?false:true})
  }


  render() {
   
     const columnsopening = [
      {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "partuname",label: "Party Ledger",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "entrytype",label: "Type",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: 'asc',
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },    
      {name: "type",label: "Type",options: {filter: false,sort: true,
        customBodyRender:(tableMeta)=>{
          return(tableMeta==="Debit"?"Debit":"Credit ")
        },

        setCellProps: () => ({ align: 'center' })}},
      {name: "price",label: "Total",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      
      {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
        customBodyRender:(value,tableMeta)=>{
          return (
            value.length!==0 && tableMeta.rowData[4] === "Debit" ?<button className="hovbuttonColour btn btn-sm"
            onClick={() => this.submitRecordOpeningDR(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
            </button>:<button className="hovbuttonColour btn btn-sm"
            onClick={() => this.submitRecordOpeningCR(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pay"}
            </button>
          );      

        }
      
      }},
  
     ];
     
     const optionsopening = {
       filterType: 'checkbox',
       selectableRows: "none",
       resizableColumns: true,

     }

     const columntaxsopening = [
      {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "name",label: "Tax Ledger",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "entrytype",label: "Type",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: 'asc',
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },    
      {name: "taxtype",label: "Type",options: {filter: false,sort: true,
        // customBodyRender:(tableMeta)=>{
        //   return(tableMeta==="Debit"?"Debit":"Credit ")
        // },

        setCellProps: () => ({ align: 'center' })}},
      {name: "amount",label: "Total",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      
      {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
        customBodyRender:(value,tableMeta)=>{
          return (
            value.length!==0 && tableMeta.rowData[5] < 0?<button className="hovbuttonColour btn btn-sm"
            onClick={() => this.submitRecordOpeningTaxPay(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pay"}
            </button>:<button className="hovbuttonColour btn btn-sm"
            onClick={() => this.submitRecordOpeningTaxRec(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
            </button>
          );      

        }
      
      }},
  
     ];
     
     const optionstaxopening = {
       filterType: 'checkbox',
       selectableRows: "none",
       resizableColumns: true,

     }


    return (
      <div>
     

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                <div className="form-row col-md-12 text-left form-switch mt-3">
                  <fieldset className="form-group col-md-2">
                              {/* <span className="mx-3">Ledger Trans</span> */}
                              <FormControlLabel
                              control={<IOSSwitch className="sm" checked={this.state.taxnt} onChange={this.handleTypeChange} name="checkePnp" />}/>
                           <span className="text-left textColour">{this.state.taxnt===false?"Party Opening":"Tax Opening"}</span>
                            </fieldset>
                     </div>
                     {this.state.taxnt===false?
                         <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Opening Entries"}
                        data={this.state.openingrefunds}
                        columns={columnsopening}
                       options={optionsopening}
                      />
                      </ThemeProvider>:
                       <ThemeProvider theme={theme}> 
                       <MUIDataTable
                        className="table-sm px-3"
                         title={"Opening Entries"}
                         data={this.state.openingtaxes}
                         columns={columntaxsopening}
                        options={optionstaxopening}
                       />
                       </ThemeProvider>

                     }
                   
                </div>
              </div>
            </div>
          </section>

      </div>
    );
  }
}
export default withRouter(OpeningEntries);
