import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
import { currentTenant, userid } from "../../Common/Credentials";
import { Autocomplete } from "@mui/material";
import Footer from "../../Component/Footer";
import { Form, Formik } from "formik";
import withRouter from "../../withRouter";


const IndentIssue=(props)=> {
  const navigate = useNavigate();
  const [storeid]= React.useState(props.location.state.id);
 
  const [indentsList, setIndentsList] = useState([]);
  const [indentid, setIndentId] = useState("");
  const [indentIdList, setIndentIdList] = useState([]);
  const [itemname, setItemName] = React.useState("");
  const [itemid, setItemId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [iquantity, setIQuantity] = React.useState("");
  const [avilabelQuantity,setavilabelQuantity] = React.useState();
  const [indentitemsList,setIndentItemsList] = React.useState([]);
  const [storeItems,setStoreItems] = React.useState([]);
  const [show,setShow] = React.useState(false);
  const [filterItems,setFilterItems] = React.useState([]);
  const [bid,setBid] = React.useState('');
  const [selectbutton,setSelectbutton] = React.useState(false);
  const [iid,setIid] = React.useState('') 

  useEffect(() => {
    ProductionServices.getIndentingIdList(currentTenant).then((response) => {
      setIndentIdList(response.data);
      setShow(response.data.length===0?true:false)
  });

  if(storeid !== null && storeid !== ''){
    ProductionServices.GetStoreItems(storeid).then(response=>setStoreItems(response.data.storeitems))
  }
  }, [storeid]);

  const handleindentidChange = (newValue) => {
    newValue === "" || newValue === null || newValue === undefined
      ? setIndentId("")
      : setIndentId(newValue);
    setIndentsList([]);
    if (newValue) {
      ProductionServices.getIndentingbyId(newValue).then((response) => {
        setIndentsList(response.data.indentingitems);
      });
    }
  }; 

  const handleIndentItem = (e)=>{
    setIid(e.id)
    setItemName(e.itemname)
    setItemId(e.itemid)
    setQuantity(e.pendingquantity)
    setFilterItems(storeItems.filter(a=>a.itemid === e.itemid && a.quantity >0))
    setSelectbutton(true)
   if(storeItems.filter(a=>a.itemid === e.itemid).length===0) alert("No items Avilable")
  }

  const handelSelect = (id)=>{
    if (id !== "") {
      setBid(id)
      // setPrice(filterItems.find((e)=>e.id === id).price)
      setavilabelQuantity(filterItems.find((e)=>e.id === id).quantity)
      setSelectbutton(false)
    }
  }

  const handleIquantityChange = e=>{
    setIQuantity(e.target.value)
  }

  const handleAddtoList =() =>{
   if(itemid !== "" && itemname !== "" && iquantity !== "" && parseFloat(iquantity)>0 && parseFloat(iquantity)<=quantity && parseFloat(iquantity)<=avilabelQuantity ){
    if(indentitemsList.find(e=>e.id === bid && e.itemid === itemid) === undefined){   
     let data = {
       id:bid,
       iid:iid,
      itemid:itemid,
      itemname:itemname,
      quantity:parseFloat(iquantity)
    }
    indentitemsList.push(data)
  }else{
    indentitemsList.find(e=>e.id === bid).quantity = indentitemsList.find(e=>e.id === bid).quantity + parseFloat(iquantity) 
  }
    indentsList.find(a=>a.id === iid).pendingquantity = indentsList.find(a=>a.id === iid).pendingquantity - parseFloat(iquantity)
    storeItems.find(a=>a.id === bid).quantity = storeItems.find(a=>a.id === bid).quantity - parseFloat(iquantity)
    setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
  }
}


    const handelRemoveItem = (value) => {
        let deleteditem =  indentitemsList.find((e, index) => index === value);
      setIndentItemsList(indentitemsList.filter((e, index) => index !== value));
      console.log(deleteditem)
      console.log(indentsList)
      console.log(indentsList.find(a=>a.id === deleteditem.iid))
      indentsList.find(a=>a.id === deleteditem.iid).pendingquantity = indentsList.find(a=>a.id === deleteditem.iid).pendingquantity + deleteditem.quantity
      storeItems.find(a=>a.id === deleteditem.id).quantity = storeItems.find(a=>a.id === deleteditem.id).quantity + deleteditem.quantity
      if(bid !== ""){
        setavilabelQuantity(storeItems.find(a=>a.id === bid) === undefined?0:storeItems.find(a=>a.id === bid).quantity)
      }
    };

  
  const handleSubmit = () => {
    let data = {
      indentid: indentid,
      store:storeid,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
      indentissueitems:indentitemsList,
    };
    ProductionServices.NewIndentIssue(data).then(() =>
      navigate("/store")
    );
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/store" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li> 
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/store">Store</Link>
            </li>
            <li className="breadcrumb-item active">New Indent Issue</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {indentIdList.length ===0? (
                <><div className="text-center">{spin}</div>
                {show === true?<h3 className="text-center textColour">There are no pending Indents To Issue</h3>:''}
                </>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> New Indent Issue</h3>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{}}
                      // onSubmit={onSubmit}
                      validateOnChange={false}
                      // validate={validate}
                      enableReinitialize={true}
                    >
                      {() => (
                        <Form
                          autoComplete="off"
                          style={{ paddingLeft: "30px" }}
                        >
                          <div className="form-row mt-3">

                            <fieldset className="col-md-3">
                              <Autocomplete
                                id="controlled-demo"
                                options={indentIdList}
                                getOptionLabel={(option) => option || ""}
                                
                                
                                value={indentid}
                                onChange={(event, newValue) =>
                                  handleindentidChange(newValue)
                                }
                                error={indentid === "" || indentid === undefined}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Indent Id"
                                    variant="standard"
                                    error={indentid === "" || indentid === undefined}
                                    helperText={
                                      indentid === "" || indentid === undefined
                                        ? "Empty field!"
                                        : " "
                                    }
                                  />
                                )}
                              />
                            </fieldset>

                          {indentsList.length === 0 ? (
                            ""
                          ) : (
                            <>
                              <TableContainer>
                                <Typography> Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                      Total Required Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                       Pending Required Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {indentsList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.pendingquantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                         {e.quantity === 0 ? '':<span className="btn btn-sm hovbuttonColour" onClick={()=>handleIndentItem(e)}>
                                            Issue
                                            </span>
                                          }
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              </>
                          )}
                              {itemname === ""?'':<>
                            {filterItems.length === 0 ?'':

                              selectbutton === true ? 
                              <TableContainer className="mt-3">
                                <Typography>Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Price
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {filterItems.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.price}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          <button
                                            className="btn btn-sm hovbuttonColour"
                                            onClick={() =>
                                              handelSelect(e.id)
                                            }
                                          >
                                            Select
                                          </button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            :
                            <>
                             <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="itemname"
                                    value={itemname}
                                    label={`Item Name (${itemname})`}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                              </fieldset>
                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="quantity"
                                    value={iquantity}
                                    label={`Indenting Quantity (Avilable quantity: ${avilabelQuantity})`}
                                    onChange={handleIquantityChange}
                                    variant="standard"
                                    error={iquantity === "" || iquantity>quantity || iquantity>avilabelQuantity}
                                    helperText={
                                      iquantity === "" ? "Empty field!" : iquantity>quantity?"Exceeds Requested Quantity":iquantity>avilabelQuantity?"Out of Stock":""
                                    }
                                    fullWidth
                                  />
                              </fieldset>

                                {iquantity>avilabelQuantity?
                              <fieldset className="col-md-12 mt-3">
                                <span
                                  className="text-danger"
                                >
                                  Out of Stock
                                </span>
                                </fieldset>
                              :
                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleAddtoList}
                                  type="button"
                                >
                                  Add to List
                                </button>
                              </fieldset>
                                }
                            </>
                            }
                              </>}
                             {indentitemsList.length ===0?'':<>
                              <TableContainer>
                                <Typography>Indenting Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {indentitemsList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {(e.quantity).toFixed(3)}
                                        </TableCell>
                                        <TableCell className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-sm deletebutton"
                                            onClick={()=>handelRemoveItem(index)}
                                          >
                                            Remove
                                          </button>
                                      </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleSubmit}
                                  type="button"
                                >
                                  Submit
                                </button>
                              </fieldset>
                                </>

                                }
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(IndentIssue);
